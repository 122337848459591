import { useState, useEffect, useContext, useMemo, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { CommentIcon, ErrorIcon } from "evergreen-ui";
import { EditTableViews, AdjustmentsOverlay } from "components/containers";
import { Button, Pill, Tooltip } from "components/materials";
import { IssuesInfoModal, IssuesViewName } from "components/templates";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  booleanColumnDefaults,
  currencyColumnDefaults,
  enumColumnDefaults,
  listColumnDefaults,
  numberColumnDefaults,
  percentColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import { majorScale } from "helpers/utilities";
import { MASTER_FORMAT_DIVISION, PERMISSION_ACTION } from "helpers/enums";
import {
  getIssuesFilterConfig,
  getIssuesRowState,
  getIssuesTableValue,
  getIssuesTableValueFormatter,
  GUIDING_EXPLANATION_SCOPES,
} from "helpers/issues";
import {
  NET_ZERO,
  amountOrNetZero,
  formatCategories,
  getSuperLineItemAggregates,
  getLineItemPosition,
  renderCommentIcon,
  renderRetainageIcon,
  percentRemainingNet,
  percentRemainingGross,
  percentCompleteGross,
  percentCompleteNet,
} from "helpers/lineItemTableHelpers";
import { failedRules, hasFailedRules } from "helpers/ruleHelpers";
import {
  getPercentAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { formatCurrency } from "helpers/formatCurrency";
import { getNetAdjustment, formatAdjustment } from "helpers/adjustments";
import { divide, subtract, sumBy } from "helpers/math";
import { stringComparator } from "helpers/comparators";
import {
  escapeRegExp,
  flatMap,
  get,
  groupBy,
  intersection,
  isEqual,
  mapValues,
  minBy,
  uniq,
  uniqBy,
} from "lodash";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import {
  orderedLineItemCategories,
  orderedLineItemTypes,
} from "../../templates/LineItemSettings";
import { DrawLineItemSlideout } from "../DrawLineItemSlideout";
import { SuperLineItemSlideout } from "./SuperLineItemSlideout";

function getDefaultViews({ hasPermission, showSuperLineItems, lineItems }) {
  const lineItemIssues = uniqBy(
    flatMap(lineItems, ({ issues }) => issues || []),
    ({ lineItemId, name }) => `${lineItemId}${name}`
  );
  return [
    {
      config: toBase64({
        columnConfig: ["lineItem"]
          .concat(
            hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) &&
              lineItemIssues.length > 0
              ? ["issues"]
              : []
          )
          .concat([
            "originalBudget",
            "previousAdjustments",
            "currentAdjustments",
            "currentBudget",
            "previousDraws",
            "currentAmountRequested",
            "retainageAmount",
            "balanceToFundAmount",
            "percentRemaining",
          ]),
        filterConfig: [],
        groupConfig: { columnId: "division" },
        sortConfig: { columnId: "budgetOrder", direction: "asc" },
      }),
      isDefault: true,
      name: "Default",
    },
    ...(hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL)
      ? [
          {
            config: toBase64({
              columnConfig: [
                "lineItem",
                "issues",
                "originalBudget",
                "previousAdjustments",
                "currentAdjustments",
                "currentBudget",
                "previousDraws",
                "currentAmountRequested",
                "retainageAmount",
                "balanceToFundAmount",
                "percentRemaining",
              ],
              filterConfig: getIssuesFilterConfig(lineItemIssues),
              groupConfig: { columnId: "division" },
              sortConfig: { columnId: "budgetOrder", direction: "asc" },
            }),
            isDefault: true,
            name: "Issues",
            formattedName: <IssuesViewName issues={lineItemIssues} />,
          },
        ]
      : []),
    ...(hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT)
      ? [
          {
            config: toBase64({
              columnConfig: [
                "lineItem",
                "originalBudget",
                "currentAdjustments",
                "currentBudget",
                "committedToDate",
                "uncommittedToDate",
                "totalExposures",
                "totalAnticipatedCost",
                "anticipatedBalanceRemaining",
                "amountRequestedToDate",
              ],
              filterConfig: [],
              groupConfig: { columnId: "division" },
              sortConfig: { columnId: "budgetOrder", direction: "asc" },
            }),
            isDefault: true,
            name: "Commitments / Potential Changes",
          },
        ]
      : []),
    ...(hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) && !showSuperLineItems
      ? [
          {
            config: toBase64({
              columnConfig: [
                "lineItem",
                "originalBudget",
                "previousAdjustments",
                "currentAdjustments",
                "currentBudget",
                "previousDraws",
                "currentAmountRequested",
                "retainageAmount",
                "balanceToFundAmount",
                "percentRemaining",
              ],
              filterConfig: [],
              groupConfig: { columnId: "superLineItem" },
              sortConfig: { columnId: "budgetOrder", direction: "asc" },
            }),
            isDefault: true,
            name: "By Summary Line Item",
          },
        ]
      : []),
  ];
}

function getColumns({
  hasPermission,
  projectAcres,
  projectSquareFeet,
  setIssueItemForModal,
  showSuperLineItems,
}) {
  const hasAgreements = hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT);
  const hasCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const hasInspectionReport = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );

  return [
    {
      ...stringColumnDefaults,
      ...primaryColumnDefaults,
      header: "Line Item",
      id: "lineItem",
      // category not needed - primary column
      value: (lineItem) => lineItem.name,
      valueFormatter: (value, lineItem) => (
        <Fragment>
          {value}
          {hasFailedRules(lineItem) && (
            <Tooltip content={failedRules(lineItem)}>
              <ErrorIcon color="danger" size={10} marginLeft={majorScale(1)} />
            </Tooltip>
          )}
          {renderCommentIcon(lineItem)}
          {renderRetainageIcon(lineItem)}
        </Fragment>
      ),
      width: 300,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Division",
      id: "division",
      category: "General Budget",
      value: (lineItem) => lineItem.division.name,
      width: 200,
    },
    {
      ...numberColumnDefaults,
      header: "Budget Order",
      hidden: true,
      id: "budgetOrder",
      // category not needed - always hidden
      value: getLineItemPosition,
    },
    {
      ...stringColumnDefaults,
      header: "Line Item #",
      hidden: showSuperLineItems,
      id: "lineItemNumber",
      category: "Line Item Settings",
      groupable: true,
      value: (lineItem) => lineItem.number,
      width: 120,
    },
    {
      ...stringColumnDefaults,
      groupable: true,
      header: "Summary Line Item",
      hidden:
        !hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) ||
        showSuperLineItems,
      id: "superLineItem",
      category: "General Budget",
      value: (lineItem) =>
        `${lineItem.division.name} - ${
          lineItem.superLineItem?.name || lineItem.name
        }`,
      valueExporter: (_value, lineItem) => lineItem.superLineItem?.name,
      valueFormatter: (value, lineItem, { isGroupedValue }) => {
        if (isGroupedValue) {
          return value;
        }
        return lineItem.superLineItem?.name;
      },
      width: 135,
    },
    {
      ...listColumnDefaults,
      header: "Line Items",
      hidden:
        !hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) ||
        !showSuperLineItems,
      id: "lineItems",
      category: "General Budget",
      value: (lineItem) => {
        const lineItemNames = lineItem.lineItems
          ? lineItem.lineItems.map(({ name }) => name)
          : [lineItem.name];

        return lineItemNames.join(", ");
      },
      width: 200,
    },
    {
      ...enumColumnDefaults,
      enumValues: Object.values(MASTER_FORMAT_DIVISION)
        .map((division) => t(`masterFormatDivisions.${division}`))
        .sort((a, b) => stringComparator(a, b))
        .concat(null),
      aggregate: (lineItems) =>
        getDefaultAggregate(lineItems, ({ masterFormatDivision }) =>
          masterFormatDivision
            ? t(`masterFormatDivisions.${masterFormatDivision}`)
            : null
        ),
      groupable: true,
      header: "Master Format Division",
      hidden:
        !hasPermission(PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING) ||
        showSuperLineItems,
      id: "masterFormatDivision",
      category: "Line Item Settings",
      value: ({ masterFormatDivision }) =>
        masterFormatDivision
          ? t(`masterFormatDivisions.${masterFormatDivision}`)
          : null,
      width: 200,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "originalBudgetAmount"),
      header: "Original Budget",
      id: "originalBudget",
      category: "General Budget",
      value: (lineItem) => lineItem.originalBudgetAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        amountOrNetZero(lineItems, "previousAdjustmentsAmount"),
      aggregateFormatter: (value) =>
        value === NET_ZERO ? "Net 0" : formatCurrency(value),
      header: "Previous Adjustments",
      id: "previousAdjustments",
      category: "General Budget",
      value: (lineItem) => lineItem.previousAdjustmentsAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => amountOrNetZero(lineItems, "adjustmentsAmount"),
      aggregateFormatter: (value) =>
        value === NET_ZERO ? "Net 0" : formatCurrency(value),
      header: "Current Adjustments",
      id: "currentAdjustments",
      category: "General Budget",
      value: (lineItem) => lineItem.adjustmentsAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "materialsStoredAmount"),
      header: "Stored Materials",
      id: "materialsStored",
      category: "General Budget",
      value: (lineItem) => lineItem.materialsStoredAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "budgetAmount"),
      header: "Current Budget",
      id: "currentBudget",
      category: "General Budget",
      value: (lineItem) => lineItem.budgetAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "totalCommitmentsAmount"),
      internal: true,
      header: "Total Commitments",
      hidden: !hasAgreements,
      id: "committedToDate",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: hasCostToAgreements
        ? t("budgetPage.totalCommitments.withOutOfContract")
        : t("budgetPage.totalCommitments.default"),
      value: (lineItem) => lineItem.totalCommitmentsAmount,
      width: 165,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.totalCommitmentsAmount)
        ),
      internal: true,
      header: "Total Uncommitted",
      hidden: !hasAgreements,
      id: "uncommittedToDate",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: hasCostToAgreements
        ? t("budgetPage.totalUncommitted.withOutOfContract")
        : t("budgetPage.totalUncommitted.default"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.totalCommitmentsAmount),
      width: 165,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "exposedAmount"),
      internal: true,
      header: "PCOs & Exposures",
      hidden: !hasAgreements,
      id: "totalExposures",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: t("budgetPage.exposedAmount"),
      value: (lineItem) => lineItem.exposedAmount,
      width: 160,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "outOfContractAmount"),
      internal: true,
      header: "Out Of Contract",
      hidden: !hasCostToAgreements,
      id: "outOfContract",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: t("budgetPage.outOfContract"),
      value: (lineItem) => lineItem.outOfContractAmount,
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "committedAmount"),
      header: "Executed Agreements",
      hidden: !hasCostToAgreements,
      id: "executedAgreements",
      internal: true,
      category: "Commitments",
      state: { background: "internal" },
      tooltip: t("budgetPage.executedAgreements"),
      value: (lineItem) => lineItem.committedAmount,
      width: 178,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "totalAgreementsAmount"),
      internal: true,
      header: "Total Agreements",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "totalAnticipatedCost",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: t("budgetPage.totalAgreements"),
      value: (lineItem) => lineItem.totalAgreementsAmount,
      width: 155,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.budgetAmount, lineItem.totalAgreementsAmount)
        ),
      internal: true,
      header: "Agreement Variance",
      hidden: !hasAgreements,
      // keep legacy id to maintain legacy saved views
      id: "anticipatedBalanceRemaining",
      category: "Commitments",
      state: { background: "internal" },
      tooltip: t("budgetPage.agreementVariance"),
      value: (lineItem) =>
        subtract(lineItem.budgetAmount, lineItem.totalAgreementsAmount),
      width: 170,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "requestedPreviouslyAmount"),
      header: "Previous Draws (Net)",
      id: "previousDraws",
      category: "General Budget",
      value: (lineItem) => lineItem.requestedPreviouslyAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossRequestedPreviouslyAmount"),
      header: "Previous Draws (Gross)",
      id: "grossPreviousDraws",
      category: "General Budget",
      value: (lineItem) => lineItem.grossRequestedPreviouslyAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "requestedAmount"),
      header: "Current Amount Requested (Net)",
      id: "currentAmountRequested",
      category: "General Budget",
      value: (lineItem) => lineItem.requestedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "grossRequestedAmount"),
      header: "Current Amount Requested (Gross)",
      id: "grossCurrentAmountRequested",
      category: "General Budget",
      value: (lineItem) => lineItem.grossRequestedAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "retainageAmount"),
      header: "Retainage Amount",
      id: "retainageAmount",
      category: "General Budget",
      value: (lineItem) => lineItem.retainageAmount,
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "retainageToDateAmount"),
      header: "Retainage To Date Amount",
      id: "retainageToDateAmount",
      category: "General Budget",
      value: (lineItem) => lineItem.retainageToDateAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "balanceToFundAmount"),
      header: "Balance To Fund Amount",
      id: "balanceToFundAmount",
      category: "Calculations",
      value: (lineItem) => lineItem.balanceToFundAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "balanceToFundAmount"),
      header: "Balance Remaining (Net)",
      id: "balanceRemaining",
      category: "Calculations",
      value: (lineItem) => lineItem.balanceToFundAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, (lineItem) =>
          subtract(lineItem.balanceToFundAmount, lineItem.retainageToDateAmount)
        ),
      header: "Balance Remaining (Gross)",
      id: "balanceRemainingGross",
      category: "Calculations",
      value: (lineItem) =>
        subtract(lineItem.balanceToFundAmount, lineItem.retainageToDateAmount),
      width: 125,
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        getPercentAggregate(lineItems, "percentRemaining", "budgetAmount"),
      header: "% Remaining (Net)",
      id: "percentRemaining",
      category: "Calculations",
      value: (lineItem) => percentRemainingNet(lineItem),
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        getPercentAggregate(lineItems, "grossPercentRemaining", "budgetAmount"),
      header: "% Remaining (Gross)",
      id: "grossPercentRemaining",
      category: "Calculations",
      value: (lineItem) => percentRemainingGross(lineItem),
    },
    {
      ...booleanColumnDefaults,
      aggregate: (lineItems) => lineItems.every(hasFailedRules),
      aggregateFormatter: (value, lineItems) =>
        value && (
          <Tooltip content={uniq(flatMap(lineItems, failedRules))}>
            <ErrorIcon color="danger" marginLeft={majorScale(1)} size={10} />
          </Tooltip>
        ),
      header: "Error Status",
      id: "errorStatus",
      category: "Line Item Settings",
      value: hasFailedRules,
      valueFormatter: (value, lineItem, { isGroupedValue }) => {
        if (isGroupedValue) {
          return hasFailedRules(lineItem)
            ? "Has Failed Rules"
            : "All Rules Pass";
        }
        return (
          value && (
            <Tooltip content={failedRules(lineItem)}>
              <ErrorIcon color="danger" marginLeft={majorScale(1)} size={10} />
            </Tooltip>
          )
        );
      },
      width: 90,
    },
    {
      ...booleanColumnDefaults,
      aggregate: (lineItems) =>
        lineItems.every((lineItem) => lineItem.comments.length > 0),
      aggregateFormatter: (value) =>
        value && <CommentIcon marginLeft={majorScale(1)} size={10} />,
      groupable: true,
      header: "Comments",
      id: "comments",
      category: "Line Item Settings",
      value: (lineItem) => lineItem.comments.length > 0,
      valueFormatter: (value, item, { isGroupedValue }) => {
        if (isGroupedValue) {
          return item.comments.length > 0
            ? "With Internal Comments"
            : "Without Internal Comments";
        }
        return value && <CommentIcon marginLeft={majorScale(1)} size={10} />;
      },
      width: 85,
      textAlign: "center",
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossDocumentedRequestedAmount"),
      header: "Documented Amount (Gross)",
      id: "documentAmount",
      category: "Calculations",
      value: (lineItem) => lineItem.grossDocumentedRequestedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "documentedRequestedAmount"),
      header: "Documented Amount (Net)",
      id: "documentedRequestedAmount",
      category: "Calculations",
      value: (lineItem) => lineItem.documentedRequestedAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossUndocumentedRequestedAmount"),
      header: "Undocumented Amount Requested (Gross)",
      id: "grossUndocumentedRequestedAmount",
      category: "Calculations",
      tooltip: t("drawLineItem.grossUndocumentedRequestedAmountTooltip"),
      value: (lineItem) => lineItem.grossUndocumentedRequestedAmount,
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "undocumentedRequestedAmount"),
      header: "Undocumented Amount Requested (Net)",
      id: "undocumentedRequestedAmount",
      category: "Calculations",
      tooltip: t("drawLineItem.undocumentedRequestedAmountTooltip"),
      value: (lineItem) => lineItem.undocumentedRequestedAmount,
      width: 150,
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        getPercentAggregate(lineItems, "percentComplete", "budgetAmount"),
      header: "% Complete (Net)",
      id: "percentComplete",
      category: "Calculations",
      value: (lineItem) => percentCompleteNet(lineItem),
    },
    {
      ...percentColumnDefaults,
      aggregate: (lineItems) =>
        getPercentAggregate(lineItems, "grossPercentComplete", "budgetAmount"),
      header: "% Complete (Gross)",
      id: "grossPercentComplete",
      category: "Calculations",
      value: (lineItem) => percentCompleteGross(lineItem),
    },
    {
      ...percentColumnDefaults,
      header: "Inspection % Complete",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "inspectionPercentComplete",
      category: "Inspection",
      value: (lineItem) => lineItem.inspectionPercentComplete,
    },
    {
      ...percentColumnDefaults,
      header: "Previous Inspection % Complete",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "previousInspectionPercentComplete",
      category: "Inspection",
      value: (lineItem) => lineItem.previousInspectionPercentComplete,
      width: 130,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossInspectedRequestedToDateAmount"),
      header: "Inspected Amount",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "grossInspectedRequestedToDateAmount",
      category: "Inspection",
      tooltip: t("drawLineItem.grossInspectedRequestedToDateAmountTooltip"),
      value: (lineItem) => lineItem.grossInspectedRequestedToDateAmount,
      width: 145,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossUnendorsedRequestedToDateAmount"),
      header: "Amount Requested over Endorsed",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "grossUnendorsedRequestedToDateAmount",
      category: "Inspection",
      tooltip: t("drawLineItem.grossUnendorsedRequestedToDateAmountTooltip"),
      value: (lineItem) => lineItem.grossUnendorsedRequestedToDateAmount,
      width: 140,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        sumBy(lineItems, "grossEndorsedRequestedToDateAmount"),
      header: "Endorsed Amount",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "grossEndorsedRequestedToDateAmount",
      category: "Inspection",
      tooltip: t("drawLineItem.grossEndorsedRequestedToDateAmountTooltip"),
      value: (lineItem) => lineItem.grossEndorsedRequestedToDateAmount,
      width: 145,
    },
    {
      ...percentColumnDefaults,
      header: "% Endorsed",
      hidden: !hasInspectionReport || showSuperLineItems,
      id: "grossEndorsedPercentComplete",
      category: "Inspection",
      value: (lineItem) => lineItem.grossEndorsedPercentComplete,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "requestedToDateAmount"),
      header: "Amount Requested to Date (Net)",
      id: "amountRequestedToDate",
      category: "General Budget",
      value: (lineItem) => lineItem.requestedToDateAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "grossRequestedToDateAmount"),
      header: "Amount Requested to Date (Gross)",
      id: "grossAmountRequestedToDate",
      category: "General Budget",
      value: (lineItem) => lineItem.grossRequestedToDateAmount,
      width: 125,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "equityDisbursedToDateAmount"),
      header: "Equity Contributions",
      id: "equityContributions",
      category: "General Budget",
      value: (lineItem) => lineItem.equityDisbursedToDateAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) => sumBy(lineItems, "debtDisbursedToDateAmount"),
      header: "Debt Contributions",
      id: "debtContributions",
      category: "General Budget",
      value: (lineItem) => lineItem.debtDisbursedToDateAmount,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "originalBudgetAmount"), projectSquareFeet),
      header: "Original Budget / Area (sqft)",
      id: "originalBudgetBySquareFeet",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.originalBudgetAmount, projectSquareFeet),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "budgetAmount"), projectSquareFeet),
      header: "Current Budget / Area (sqft)",
      id: "currentBudgetBySquareFeet",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.budgetAmount, projectSquareFeet),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "requestedAmount"), projectSquareFeet),
      header: "Current Amount Requested (Net) / Area (sqft)",
      id: "requestedAmountBySquareFeet",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.requestedAmount, projectSquareFeet),
      width: 170,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "grossRequestedAmount"), projectSquareFeet),
      header: "Current Amount Requested (Gross) / Area (sqft)",
      id: "grossRequestedAmountBySquareFeet",
      category: "Calculations",
      value: (lineItem) =>
        divide(lineItem.grossRequestedAmount, projectSquareFeet),
      width: 170,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "originalBudgetAmount"), projectAcres),
      header: "Original Budget / Acre",
      id: "originalBudgetByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.originalBudgetAmount, projectAcres),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "budgetAmount"), projectAcres),
      header: "Current Budget / Acre",
      id: "currentBudgetByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.budgetAmount, projectAcres),
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "requestedAmount"), projectAcres),
      header: "Current Amount Requested (Net) / Acre",
      id: "requestedAmountByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.requestedAmount, projectAcres),
      width: 150,
    },
    {
      ...currencyColumnDefaults,
      aggregate: (lineItems) =>
        divide(sumBy(lineItems, "grossRequestedAmount"), projectAcres),
      header: "Current Amount Requested (Gross) / Acre",
      id: "grossRequestedAmountByAcres",
      category: "Calculations",
      value: (lineItem) => divide(lineItem.grossRequestedAmount, projectAcres),
      width: 162,
    },
    {
      ...listColumnDefaults,
      header: "Job Cost Codes",
      hidden: !hasPermission(PERMISSION_ACTION.JOB_COST_CODES),
      id: "jobCostCodes",
      category: "General Budget",
      value: (lineItem) => {
        const codes = get(lineItem, "jobCostCodes", []).map(
          (jobCostCode) => jobCostCode.code
        );
        return codes.join(", ");
      },
      width: 120,
    },
    {
      ...enumColumnDefaults,
      enumValues: orderedLineItemTypes
        .concat(null)
        .map((type) => t(`lineItemTypes.${type}`)),
      aggregate: (lineItems) => {
        const allTypes = lineItems.map((lineItem) => {
          const typeValue = intersection(orderedLineItemTypes, lineItem.types);
          if (typeValue.length === 0) return null;
          return t(`lineItemTypes.${typeValue}`);
        });
        const types = uniq(allTypes);
        if (types.length > 1) return "(mixed)";
        return t(`lineItemTypes.${types[0]}`);
      },
      groupable: true,
      header: "Type",
      hidden: showSuperLineItems,
      id: "type",
      category: "Line Item Settings",
      value: (lineItem) => {
        const [type] = intersection(orderedLineItemTypes, lineItem.types);
        if (!type) return "(neither)";
        return t(`lineItemTypes.${type}`);
      },
    },
    {
      ...listColumnDefaults,
      id: "issues",
      category: "Line Item Settings",
      groupable: true,
      header: "Issues",
      hidden: !hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL),
      value: getIssuesTableValue,
      valueFormatter: (lineItemIssues, item, config) => {
        function onClick() {
          setIssueItemForModal(item);
        }
        return getIssuesTableValueFormatter(
          lineItemIssues,
          item,
          config,
          onClick
        );
      },
    },
    {
      ...enumColumnDefaults,
      enumValues: orderedLineItemCategories.map((type) =>
        t(`lineItemTypes.${type}`)
      ),
      aggregate: (lineItems) => {
        const allCategories = lineItems.reduce((acc, lineItem) => {
          const categories = intersection(
            orderedLineItemCategories,
            lineItem.types
          );
          return [...acc, categories];
        }, []);
        const isMixed = allCategories.some((categories) => {
          return !isEqual(allCategories[0], categories);
        });
        if (isMixed) return "(mixed)";
        return formatCategories(allCategories[0]);
      },
      // this is a tweaked version of the default enum filterStrategy
      // this column is a bit different than other enum columns,
      // as the line item is not limited to a single category
      filterStrategy: (value, filterConfig) => {
        const { enum: filterConfigEnum, __isSearch, input } = filterConfig;
        const isEmptyValue = isBlank(value);
        if (__isSearch === true) {
          return isBlank(input)
            ? true
            : !isEmptyValue && new RegExp(escapeRegExp(input), "i").test(value);
        }
        if (filterConfigEnum) {
          return isEmptyValue
            ? false
            : intersection(value.split(", "), filterConfigEnum).length > 0;
        }
        return true;
      },
      groupable: true,
      header: "Categories",
      hidden: showSuperLineItems,
      id: "categories",
      category: "Line Item Settings",
      value: (lineItem) => {
        const categories = intersection(
          orderedLineItemCategories,
          lineItem.types
        );
        return formatCategories(categories);
      },
    },
  ];
}

function AdjustmentsData({ adjustments }) {
  if (adjustments.length === 0) return null;

  const netAdjustment = getNetAdjustment(adjustments);

  if (netAdjustment === 0)
    return (
      <Pill marginLeft={majorScale(1)} color="blue">
        {adjustments.length}
      </Pill>
    );

  return (
    <Pill marginLeft={majorScale(1)} color="red">
      {formatAdjustment(netAdjustment)}
    </Pill>
  );
}

function ViewAdjustmentsButton({ adjustments, drawId, projectId }) {
  const history = useHistory();
  const [open, setOpen] = useState(
    !!getSearchByKey(history, "showAdjustments")
  );

  useEffect(
    () =>
      history.listen((_newLocation, action) => {
        if (open && action === "POP") {
          setOpen(false);
        }
      }),
    // eslint-disable-next-line
    [open]
  );

  return (
    <Fragment>
      <Button
        purpose="adjustments open"
        onClick={() => {
          setOpen(true);
          history.push(history.location);
        }}
      >
        {t("adjustmentsButton.label")}
        {adjustments.length > 0 && (
          <AdjustmentsData adjustments={adjustments} />
        )}
      </Button>
      {open && (
        <AdjustmentsOverlay
          drawId={drawId}
          onClose={() => {
            setOpen(false);
            history.goBack();
          }}
          projectId={projectId}
        />
      )}
    </Fragment>
  );
}

export function DrawLineItemTable({
  adjustments,
  baseUrl,
  drawId,
  history,
  lineItems,
  match,
  organizationId,
  projectAcres,
  projectId,
  projectSquareFeet,
  showSuperLineItems,
}) {
  const { hasPermission } = useContext(UserContext);

  const superLineItems = useMemo(
    () => prepareSuperLineItems(lineItems, hasPermission),
    [lineItems, hasPermission]
  );

  const defaultViews = useMemo(
    () => getDefaultViews({ hasPermission, showSuperLineItems, lineItems }),
    [hasPermission, lineItems, showSuperLineItems]
  );

  const [issueItemForModal, setIssueItemForModal] = useState(null);

  const columns = useMemo(
    () =>
      getColumns({
        hasPermission,
        projectAcres,
        projectSquareFeet,
        setIssueItemForModal,
        showSuperLineItems,
      }),
    [
      hasPermission,
      projectAcres,
      projectSquareFeet,
      setIssueItemForModal,
      showSuperLineItems,
    ]
  );

  const handleOpenSlideout = (lineItem) => {
    history.push(
      `${baseUrl}/${
        lineItem.isSuperLineItem ? "summary_line_items" : "line_items"
      }/${lineItem.id}${history.location.search}`
    );
  };

  const handleCloseSlideout = () => {
    if (match.params.documentId) return;
    history.push(`${baseUrl}/line_items${history.location.search}`);
  };

  const tableProps = showSuperLineItems
    ? {
        tableName: "SuperLineItemTable",
        items: superLineItems,
      }
    : {
        tableName: "DrawLineItemTable",
        items: lineItems,
      };

  return (
    <Fragment>
      <EditTableViews
        canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
        config={getSearchByKey(history, "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName={tableProps.tableName}
      >
        {(propsEditTableViews) => (
          <FastDataTable
            columns={columns}
            controls={(propsControls) => (
              <FastDataTableAdvancedControls
                {...propsControls}
                {...propsEditTableViews}
                disable={[FastDataTableDownloadDocuments]}
                rightControls={
                  <ViewAdjustmentsButton
                    adjustments={adjustments}
                    drawId={drawId}
                    key={drawId}
                    projectId={projectId}
                  />
                }
                searchPlaceholder="Search Line Items..."
              />
            )}
            getRowState={(lineItem) => {
              if (hasFailedRules(lineItem)) {
                return "error";
              }
              if (hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL)) {
                return getIssuesRowState(lineItem.issues);
              }
              return undefined;
            }}
            items={tableProps.items}
            onClickRow={handleOpenSlideout}
            onSerialize={(table) => mergeSearch(history, { table })}
            pageSize={null}
            serialized={
              getSearchByKey(history, "table") ||
              get(propsEditTableViews, "views.0.config")
            }
            footerTotals
          />
        )}
      </EditTableViews>
      <DrawLineItemSlideout
        closeSidebar={handleCloseSlideout}
        history={history}
        match={match}
      />
      <SuperLineItemSlideout
        closeSidebar={handleCloseSlideout}
        history={history}
        match={match}
      />
      {issueItemForModal && (
        <IssuesInfoModal
          issueItem={issueItemForModal}
          scope={GUIDING_EXPLANATION_SCOPES.DRAW_LINE_ITEM}
          onClose={() => setIssueItemForModal(null)}
        />
      )}
    </Fragment>
  );
}

function prepareSuperLineItems(drawLineItems, hasPermission) {
  if (!hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS)) return [];

  const superLineItems = mapValues(
    groupBy(drawLineItems, "superLineItem.id"),
    (lineItemGroup) => {
      const { superLineItem, division } = lineItemGroup[0];

      if (!superLineItem) return {};

      const aggregatedFields = getSuperLineItemAggregates(lineItemGroup);

      return {
        id: superLineItem.id,
        name: superLineItem.name,
        division,
        position: minBy(lineItemGroup, "position").position,
        isSuperLineItem: true,
        ...aggregatedFields,
        percentRemaining: getPercentAggregate(
          lineItemGroup,
          "percentRemaining",
          "budgetAmount"
        ),
        grossPercentRemaining: getPercentAggregate(
          lineItemGroup,
          "grossPercentRemaining",
          "budgetAmount"
        ),
        percentComplete: getPercentAggregate(
          lineItemGroup,
          "percentComplete",
          "budgetAmount"
        ),
        grossPercentComplete: getPercentAggregate(
          lineItemGroup,
          "grossPercentComplete",
          "budgetAmount"
        ),
        retainagePercentage: lineItemGroup.some(
          (lineItem) =>
            lineItem.retainagePercentage && lineItem.retainagePercentage !== 0
        ),
      };
    }
  );

  // if a line item has no super line item, it is its own super line item
  const mappedLineItems = drawLineItems.map((lineItem) =>
    lineItem.superLineItem
      ? superLineItems[lineItem.superLineItem.id]
      : lineItem
  );

  // only show the first instance of any mapped super line item in the budget
  return uniqBy(mappedLineItems, "id");
}

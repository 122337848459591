import React from "react";
import { get, uniqBy } from "lodash";
import { DrawApproval } from "./DrawApproval";
import { DocumentApproval } from "./DocumentApproval";

export function getUniqueDocuments(project) {
  return uniqBy(
    [...project.assignedDocuments, ...project.documentsPendingApprovalForUser],
    "id"
  );
}

export function Approvals({ project, currentUserId }) {
  const uniqueDocuments = getUniqueDocuments(project);
  const drawApprovals = get(project, "pendingDrawApprovals", []);

  return (
    <React.Fragment>
      {drawApprovals.map(({ draw }) => (
        <DrawApproval draw={draw} key={draw.id} currentUserId={currentUserId} />
      ))}
      {uniqueDocuments.map((document) => (
        <DocumentApproval document={document} currentUserId={currentUserId} />
      ))}
    </React.Fragment>
  );
}

import { useContext, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { DrawLineItemDetails, DrawLockdownBanner } from "components/templates";
import { Loadable, Pane, Sidebar } from "components/materials";
import { get, uniqBy } from "lodash";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  COMMENT_FRAGMENT,
  RULE_FRAGMENT,
  SLIDEOUT_DOCUMENTS_FRAGMNENT,
} from "helpers/fragments";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { getSuperLineItemAggregates } from "helpers/lineItemTableHelpers";
import { Document } from "../Document";

const FRAGMENT = gql`
  fragment SuperLineItemSlideoutFragment on Draw {
    id
    isLockedDown
    superLineItem(id: $superLineItemId) {
      id
      name
      division {
        id
        scopeId
        name
      }
      lineItems {
        id
        scopeId
        name
        division {
          id
          scopeId
          name
        }
        issues {
          id
          name
          severity
        }
        grossRequestedAmount
        grossRequestedToDateAmount
        retainageAmount
        retainageToDateAmount
        requestedAmount
        requestedToDateAmount
        grossDocumentedRequestedAmount
        documentedRetainageAmount
        expenses {
          id
          amount
          grossAmount
          isBackup
          document {
            ...SlideoutDocumentsFragment
            comments {
              ...CommentFragment
            }
          }
        }
        transactions {
          id
          memo
          sourceType
          insertedAt
          entries {
            id
            account
            amount
          }
          user {
            id
            fullName
          }
          invoice {
            ...SlideoutDocumentsFragment
          }
          drawSummary {
            ...SlideoutDocumentsFragment
          }
        }
        comments {
          id
          body
          insertedAt
          author {
            id
            fullName
          }
          mentions {
            id
            user {
              id
              fullName
            }
          }
        }
        rules {
          ...RuleFragment
        }
      }
    }
  }
  ${SLIDEOUT_DOCUMENTS_FRAGMNENT}
  ${COMMENT_FRAGMENT}
  ${RULE_FRAGMENT}
`;

export const QUERY = gql`
  query SuperLineItemSlideoutQuery(
    $projectId: String!
    $drawId: String!
    $superLineItemId: String!
  ) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        ...SuperLineItemSlideoutFragment
      }
      users {
        id
        fullName
      }
    }
  }
  ${FRAGMENT}
`;

export function SuperLineItemSlideout({ closeSidebar, history, match }) {
  const { params } = match;
  const { documentId, superLineItemId, drawId, projectId } = params;
  const { hasPermission } = useContext(UserContext);

  const { data, loading } = useQuery(QUERY, {
    skip: !superLineItemId,
    variables: { superLineItemId, drawId, projectId },
  });

  const showSidebar = !!superLineItemId && !documentId;

  const getDocumentPath = (documentId) => {
    return `${history.location.pathname}/documents/${documentId}${history.location.search}`;
  };

  if (loading) return <Loadable loading />;
  const draw = get(data, "project.draw");

  const lineItemName = get(draw, "superLineItem.name", "");
  const divisionName = get(draw, "superLineItem.division.name", "");
  const lockdownDraw = get(draw, "isLockedDown");

  const superLineItem = draw?.superLineItem
    ? prepareSuperLineItem(draw.superLineItem)
    : null;

  const documentViewerDocuments = uniqBy(
    get(superLineItem, "expenses", [])
      .map(({ document }) => document)
      .filter((document) => !!document),
    "id"
  );

  return (
    <Fragment>
      <Sidebar isShown={showSidebar} onCloseComplete={closeSidebar}>
        {showSidebar && (
          <Fragment>
            <Sidebar.Heading breadcrumbs={[divisionName, lineItemName]} />
            <Sidebar.Section
              padding={0}
              marginTop={0}
              data-testid="sidebar-lineitem"
            >
              {lockdownDraw && (
                <DrawLockdownBanner title={t("lockdownBanner.lineItemTitle")} />
              )}
              <Pane padding={majorScale(2)}>
                <DrawLineItemDetails
                  lineItem={superLineItem}
                  isSuperLineItem
                  getDocumentPath={
                    hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT)
                      ? getDocumentPath
                      : null
                  }
                />
              </Pane>
            </Sidebar.Section>
          </Fragment>
        )}
      </Sidebar>
      {superLineItemId && documentId && (
        <Document
          documents={documentViewerDocuments}
          history={history}
          match={match}
        />
      )}
    </Fragment>
  );
}

function prepareSuperLineItem(superLineItem) {
  return {
    id: superLineItem?.id,
    ...getSuperLineItemAggregates(superLineItem?.lineItems),
  };
}

import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import isBlank from "helpers/isBlank";
import { fromBase64, toBase64 } from "components/materials/FastDataTable";
import { find, get, isNull, isUndefined, omitBy, partition } from "lodash";

const QUERY = gql`
  query TableViewsQuery($organizationId: String, $tableName: String!) {
    tableViews(organizationId: $organizationId, tableName: $tableName) {
      id
      config
      isPrivate
      name
      organizationId
    }
  }
`;

const ADD = gql`
  mutation EditTableViewsAdd(
    $organizationId: String!
    $tableName: String!
    $views: [TableViewInput]!
  ) {
    saveTableView(
      organizationId: $organizationId
      tableName: $tableName
      views: $views
    ) {
      status
    }
  }
`;

const UPDATE = gql`
  mutation EditTableViewsUpdate(
    $organizationId: String!
    $tableName: String!
    $viewsInformation: [ViewInformation]!
    $viewIdsToDelete: [String]!
  ) {
    setTableViews(
      organizationId: $organizationId
      tableName: $tableName
      viewIdsToDelete: $viewIdsToDelete
      viewsInformation: $viewsInformation
    ) {
      status
    }
  }
`;

function prepareDefaultViews(defaultViews) {
  return defaultViews.map((defaultView) => ({
    name: defaultView.name,
    isPrivate: false,
  }));
}
export function EditTableViews({
  canManagePublicViews,
  children,
  config,
  defaultViews,
  organizationIdToScopeViews,
  tableName,
}) {
  const scopeViewsVariables = organizationIdToScopeViews
    ? { organizationId: organizationIdToScopeViews }
    : {};

  const refetchQueries = [
    {
      query: QUERY,
      variables: { tableName, ...scopeViewsVariables },
    },
  ];

  const [add] = useMutation(ADD, {
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const [update] = useMutation(UPDATE, {
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const { data, loading } = useQuery(QUERY, {
    variables: { tableName, ...scopeViewsVariables },
  });

  if (loading) return null;

  const tableViews = get(data, "tableViews", []);

  const unsavedDefaultViews = defaultViews.filter(
    (defaultView) =>
      !tableViews.find(
        ({ config, name }) => isNull(config) && name === defaultView.name
      )
  );

  function getPropsForDefaultView(name) {
    const defaultView = find(
      defaultViews,
      (defaultView) => defaultView.name === name
    );

    return omitBy(
      {
        config: get(defaultView, "config"),
        formattedName: get(defaultView, "formattedName"),
      },
      isUndefined
    );
  }

  const [privateViews, publicViews] = partition(
    tableViews,
    ({ isPrivate }) => isPrivate
  );

  const views =
    tableViews.length > 0
      ? privateViews
          .concat(publicViews)
          .concat(unsavedDefaultViews)
          .reduce((views, view) => {
            if (view.config) {
              return [
                ...views,
                {
                  ...view,
                  isDefault: !!view.isDefault,
                  isPrivate: view.isDefault ? false : view.isPrivate,
                },
              ];
            }

            if (!isBlank(getPropsForDefaultView(view.name))) {
              return [
                ...views,
                {
                  ...view,
                  ...getPropsForDefaultView(view.name),
                  isDefault: true,
                },
              ];
            }

            return views;
          }, [])
      : defaultViews.map((defaultView) => ({
          ...defaultView,
          isPrivate: false,
        }));

  const onSaveView = ({
    name,
    groupsExpanded,
    isPrivate,
    userSelectedOrganizationId,
  }) => {
    // "config" prop will be undefined until the table config has been updated after load
    // if it is undefined, the first view is still active
    const currentConfig = fromBase64(config || views[0].config);

    // {expanded: undefined} means that all groups are expanded, while {expanded: []} means all groups are collapsed
    if (currentConfig.groupConfig) {
      currentConfig.groupConfig.expanded = groupsExpanded ? undefined : [];
    }
    const newViewConfig = toBase64(currentConfig);

    const newView = { config: newViewConfig, name, isPrivate };

    add({
      variables: {
        tableName,
        views: [...prepareDefaultViews(unsavedDefaultViews), newView],
        organizationId: userSelectedOrganizationId,
        ...scopeViewsVariables,
      },
    });
  };

  const onUpdateViews = ({
    viewsInformation,
    userSelectedOrganizationId,
    viewIdsToDelete,
  }) => {
    const variables = {
      tableName,
      viewIdsToDelete,
      viewsInformation,
      organizationId: userSelectedOrganizationId,
      ...scopeViewsVariables,
    };
    update({ variables });
  };

  return children({
    views,
    onSaveView,
    onUpdateViews,
    canManagePublicViews,
    tableName,
  });
}

EditTableViews.propTypes = {
  defaultViews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      config: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  canManagePublicViews: PropTypes.bool,
};

EditTableViews.defaultProps = {
  canManagePublicViews: false,
  defaultViews: [],
};

import { Fragment } from "react";
import {
  Heading,
  Pane,
  Progress,
  Shortener,
  Tab,
  Tablist,
  Text,
  Tooltip,
} from "components/materials";
import { Position, majorScale, minorScale } from "helpers/utilities";
import { FUNDING_SOURCE_TYPE, PERMISSION_ACTION } from "helpers/enums";
import {
  fundingSourcesByType,
  getCurrentDebtDisbursedAmount,
  getCurrentEquityDisbursedAmount,
  getFundingSourceNames,
  getTotalDebtAmount,
  getTotalDebtDisbursedAmount,
  getTotalEquityAmount,
  getTotalEquityDisbursedAmount,
} from "helpers/fundingSourceHelpers";
import { subtract } from "helpers/math";
import {
  formatCurrency,
  formatShortenedCurrency,
} from "helpers/formatCurrency";

export function Meta({
  fundingSourceGroups,
  hasPermission,
  lineItems,
  projectProgressValues,
  showSuperLineItems,
  setShowSuperLineItems,
}) {
  const totalDebtDisbursed = getTotalDebtDisbursedAmount([fundingSourceGroups]);
  const currentEquityDisbursed = getCurrentEquityDisbursedAmount([
    fundingSourceGroups,
  ]);
  const totalEquityDisbursed = getTotalEquityDisbursedAmount([
    fundingSourceGroups,
  ]);
  const totalDebt = getTotalDebtAmount([fundingSourceGroups]);
  const totalEquity = getTotalEquityAmount([fundingSourceGroups]);
  const currentDebtDisbursed = getCurrentDebtDisbursedAmount([
    fundingSourceGroups,
  ]);

  const showSuperLineItemsToggle =
    hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS) &&
    lineItems.some((lineItem) => !!lineItem.superLineItem);

  return (
    <Pane display="flex" alignItems="center" margin={majorScale(3)}>
      {showSuperLineItemsToggle && (
        <Tablist marginLeft={minorScale(3)} marginRight={majorScale(3)}>
          <Tab
            isSelected={!showSuperLineItems}
            onSelect={() => setShowSuperLineItems(false)}
          >
            View Detailed Line Items
          </Tab>
          <Tab
            isSelected={showSuperLineItems}
            onSelect={() => setShowSuperLineItems(true)}
          >
            View Summary Line Items
          </Tab>
        </Tablist>
      )}
      {hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES) && (
        <Fragment>
          <Pane marginRight={majorScale(4)}>
            <Heading size={200}>DEBT</Heading>
            <Shortener
              size={400}
              text={getFundingSourceNames(
                fundingSourcesByType(FUNDING_SOURCE_TYPE.LOAN, [
                  fundingSourceGroups,
                ])
              )}
              limit={25}
            />
          </Pane>
          <Pane marginRight={majorScale(4)}>
            <Heading size={200}>EQUITY</Heading>
            <Shortener
              size={400}
              text={getFundingSourceNames(
                fundingSourcesByType(FUNDING_SOURCE_TYPE.EQUITY, [
                  fundingSourceGroups,
                ])
              )}
              limit={25}
            />
          </Pane>
        </Fragment>
      )}
      <Pane marginRight={majorScale(4)} maxWidth={253} flexGrow={1}>
        <Tooltip
          position={Position.TOP}
          content={
            <Pane display="flex" flexDirection="column">
              <Text color="white">
                Previous Draws:{" "}
                {formatCurrency(
                  subtract(totalEquityDisbursed, currentEquityDisbursed)
                )}
              </Text>
              <Text color="white">
                Current Draw: {formatCurrency(currentEquityDisbursed)}
              </Text>
            </Pane>
          }
        >
          <Pane>
            <Progress
              color="#8FBCE6"
              hasTwoPercentages
              marginBottom={minorScale(1)}
              secondColor="#1070CA"
              secondValue={subtract(
                totalEquityDisbursed,
                currentEquityDisbursed
              )}
              total={totalEquity}
              value={totalEquityDisbursed}
            />
            <Progress.Label
              title="Equity Funded"
              total={totalEquity}
              value={totalEquityDisbursed}
              valueFormatter={formatShortenedCurrency}
            />
          </Pane>
        </Tooltip>
      </Pane>
      <Pane marginRight={majorScale(4)} maxWidth={253} flexGrow={1}>
        <Tooltip
          position={Position.TOP}
          content={
            <Pane display="flex" flexDirection="column">
              <Text color="white">
                Previous Draws:{" "}
                {formatCurrency(
                  subtract(totalDebtDisbursed, currentDebtDisbursed)
                )}
              </Text>
              <Text color="white">
                Current Draw: {formatCurrency(currentDebtDisbursed)}
              </Text>
            </Pane>
          }
        >
          <Pane>
            <Progress
              color="#8FBCE6"
              hasTwoPercentages
              marginBottom={minorScale(1)}
              secondColor="#1070CA"
              secondValue={subtract(totalDebtDisbursed, currentDebtDisbursed)}
              total={totalDebt}
              value={totalDebtDisbursed}
            />
            <Progress.Label
              title="Debt Disbursed"
              total={totalDebt}
              value={totalDebtDisbursed}
              valueFormatter={formatShortenedCurrency}
            />
          </Pane>
        </Tooltip>
      </Pane>
      <Pane marginRight={majorScale(4)} maxWidth={253} flexGrow={1}>
        <Tooltip
          position={Position.TOP}
          content={
            <Pane display="flex" flexDirection="column">
              <Text color="white">
                Previous Draws:{" "}
                {formatCurrency(
                  subtract(
                    projectProgressValues.value,
                    projectProgressValues.secondValue
                  )
                )}
              </Text>
              <Text color="white">
                Current Draw:{" "}
                {formatCurrency(projectProgressValues.secondValue)}
              </Text>
            </Pane>
          }
        >
          <Pane>
            <Progress
              color="#8FBCE6"
              hasTwoPercentages
              marginBottom={minorScale(1)}
              secondColor="#1070CA"
              secondValue={subtract(
                projectProgressValues.value,
                projectProgressValues.secondValue
              )}
              total={projectProgressValues.total}
              value={projectProgressValues.value}
            />
            <Progress.Label
              title="Project Progress"
              total={projectProgressValues.total}
              value={projectProgressValues.value}
              valueFormatter={formatShortenedCurrency}
            />
          </Pane>
        </Tooltip>
      </Pane>
    </Pane>
  );
}

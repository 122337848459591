import gql from "graphql-tag";

export const FINALIZE_BUDGET = gql`
  mutation FinalizeBudget(
    $budget: [OnboardedDivisionInput]!
    $projectId: String!
  ) {
    setUploadedBudget(budget: $budget, projectId: $projectId) {
      id
    }
  }
`;

export const ONBOARDING_WIZARD_BUDGET_UPLOAD_STEP = gql`
  query OnboardingWizardBudgetUploadStepQuery($projectId: String!) {
    project(id: $projectId) {
      id
      budgetParser {
        id
        config
      }
      type
      template {
        id
        budgetParser {
          id
          config
        }
      }
    }
  }
`;

export const UPDATE_ONBOARDING_DATA = gql`
  mutation UpdateOnboardingData($projectId: String!, $data: Data!) {
    updateOnboardingData(projectId: $projectId, data: $data) {
      id
      onboardingData
    }
  }
`;

export const UPLOAD_BUDGET_TEMPLATE = gql`
  mutation UploadBudgetTemplate($file: Upload!, $projectId: String!) {
    uploadBudgetTemplate(file: $file, projectId: $projectId) {
      id
      data
    }
  }
`;

export const UPLOAD_PARSER_STEP_QUERY = gql`
  query UploadParserStepQuery($projectId: String!) {
    project(id: $projectId) {
      id
      budgetParser {
        id
        name
        projects {
          id
          name
        }
      }
      template {
        id
        budgetParser {
          id
          name
          projects {
            id
            name
          }
        }
      }
    }
  }
`;

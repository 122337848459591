import gql from "graphql-tag";

export const PORTFOLIO_INSIGHTS_QUERY = gql`
  query ReportsPortfolioInsightsQuery(
    $currentDate: Date!
    $organizationId: String!
  ) {
    organization(id: $organizationId) {
      id
      activeProjectCount
      projectTemplates {
        id
        name
      }
      projects {
        id
        insertedAt
        acres
        amount
        city
        typeId
        contingencyOriginalAmount
        recentDraw {
          id
          hardCostPercentages {
            drawId
            drawName
            drawState
            percentHardCostContingencyUsed
            percentHardCostCompleted
            fundedAt
          }
        }
        draws {
          id
          approvalData
          daysSinceFunded
          fundedByUser {
            id
            firstName
            lastName
          }
          fundedDate
          requestedAmount
          name
          processingData(currentDate: $currentDate)
          state
        }
        expectedProjectLength
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
          organization {
            id
            name
          }
        }
        tasks {
          id
          projectName
          eventName
          projectedCompletionDate
          status
          isOverdue
        }
        grossPercentComplete
        grossRequestedAmount
        hardCostsAmount
        hardCostsGrossPercentComplete
        lastDrawFunded {
          id
          daysSinceFunded
          fundedDate
          name
        }
        lineItems {
          id
          scopeId
          budgetAmount
          isContingency
          hardCosts
          isInterestReserves
          softCosts
          types
        }
        name
        organizationId
        originalAmount
        percentOfContingencySpent
        percentOfProjectTimeElapsed
        projectRegion {
          id
          region
        }
        productType {
          id
          type
        }
        requestedAmount
        squareFeet
        stakeholders {
          id
          role
          organization {
            id
            name
          }
        }
        startDate
        state
        status
        team {
          id
          name
        }
        zip
      }
    }
    user(organizationId: $organizationId) {
      id
      portfolioInsightsConfigurations {
        id
        disabled
        i
        x
        y
        h
        w
      }
    }
  }
`;

export const PORTFOLIO_VENDORS_QUERY = gql`
  query ReportsPortfolioInsightsVendorsQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      projects {
        id
        vendors {
          id
          name
        }
      }
    }
  }
`;

export const SAVE_PORTFOLIO_INSIGHTS_CONFIGURATIONS = gql`
  mutation SavePortfolioInsightsConfigurations(
    $userId: String!
    $organizationId: String!
    $portfolioInsightsConfigurations: [InsightsCardConfigurationInput]!
  ) {
    savePortfolioInsightsConfigurations(
      userId: $userId
      organizationId: $organizationId
      portfolioInsightsConfigurations: $portfolioInsightsConfigurations
    ) {
      id
      i
      x
      y
      w
      h
      disabled
    }
  }
`;

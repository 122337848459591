import { useContext, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { InfoSignIcon } from "evergreen-ui";
import { Form, Loadable, Modal, Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import getOptions from "helpers/getOptions";
import { get } from "lodash";
import { constructionManager } from "images";
import { setRecentProjectIds } from "helpers/localStorage";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION, PROJECT_STATUS_TYPE } from "helpers/enums";
import t from "helpers/translate";

const QUERY = gql`
  query CreateProjectModalQuery {
    organizations {
      id
      projectTemplates {
        id
        name
      }
      pursuitBundle
      teams {
        id
        name
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateProjectModalCreate(
    $name: String!
    $templateId: String!
    $organizationId: String!
    $status: ProjectStatusType
    $teamId: String
  ) {
    createProject(
      name: $name
      templateId: $templateId
      organizationId: $organizationId
      status: $status
      teamId: $teamId
    ) {
      id
      name
    }
  }
`;

const CREATE_FROM_SUBMISSION = gql`
  mutation CreateProjectModalCreateFromSubmission(
    $name: String!
    $organizationId: String!
    $status: ProjectStatusType
    $submissionId: String!
    $templateId: String!
  ) {
    createProjectFromSubmission(
      name: $name
      organizationId: $organizationId
      status: $status
      submissionId: $submissionId
      templateId: $templateId
    ) {
      id
      name
    }
  }
`;

const noPursuitBundleChargedProjectStatuses = [
  PROJECT_STATUS_TYPE.PREDEVELOPMENT,
  PROJECT_STATUS_TYPE.UNDER_CONTRACT,
];

function getInitialValues(selectedOrganization) {
  const { projectTemplates } = selectedOrganization;
  return {
    organizationId: selectedOrganization.id,
    name: "",
    projectStatus: PROJECT_STATUS_TYPE.ACTIVE,
    templateId: projectTemplates.length === 1 ? projectTemplates[0].id : null,
    teamId: null,
  };
}

function validate(values) {
  const errors = {};
  if (isBlank(values.name)) errors.name = "Please enter a project name";
  if (values.name.length > 100)
    errors.name =
      "Please enter a project name with a maximum of 100 characters";
  if (isBlank(values.templateId)) errors.templateId = "Please select a type";
  return errors;
}

function defaultOnCompleted(result) {
  const projectId =
    get(result, "createProject.id") ||
    get(result, "createProjectFromSubmission.id");
  setRecentProjectIds(projectId);

  // Use window.location rather than history.push to ensure a page refresh
  // so that AppLayout's projects can refresh
  window.location.assign(`/projects/${projectId}/configure`);
}

function getProjectStatusOptions() {
  return Object.keys(PROJECT_STATUS_TYPE).map((key) => {
    return {
      key: PROJECT_STATUS_TYPE[key],
      value: PROJECT_STATUS_TYPE[key],
      text: t(`projectStatus.${PROJECT_STATUS_TYPE[key]}`),
    };
  });
}

function getProjectStatusWarningText(currentStatus, hasPursuitBundle) {
  if (
    currentStatus === PROJECT_STATUS_TYPE.ACTIVE ||
    (!hasPursuitBundle &&
      noPursuitBundleChargedProjectStatuses.includes(currentStatus))
  ) {
    return t("projectStatus.warnings.increaseProjectMonthlyCount");
  }
  if (
    hasPursuitBundle &&
    currentStatus === PROJECT_STATUS_TYPE.UNDER_CONTRACT
  ) {
    return t("projectStatus.warnings.notIncreaseProjectMonthlyCount");
  }
  if (
    hasPursuitBundle &&
    currentStatus === PROJECT_STATUS_TYPE.PREDEVELOPMENT
  ) {
    return t(
      "projectStatus.warnings.increaseProjectMonthlyCountPostHardCostDraw"
    );
  }
  return t(
    "projectStatus.warnings.notIncludedInReportingAndNotIncreaseProjectMonthlyCount"
  );
}

function ProjectStatusWarning({ currentStatus, hasPursuitBundle }) {
  return (
    <Pane marginTop={majorScale(2)}>
      <InfoSignIcon size={13} color="info" marginRight={majorScale(1)} />
      <Text fontSize={13}>
        {getProjectStatusWarningText(currentStatus, hasPursuitBundle)}
      </Text>
    </Pane>
  );
}

export function CreateProjectModal({
  defaultValues,
  onClose,
  onCompleted,
  submissionId,
}) {
  const { listProfilesForPermission, hasPermission } = useContext(UserContext);

  const orgsWithProjectCreate = listProfilesForPermission(
    PERMISSION_ACTION.CREATE_PROJECT
  ).map(({ organization }) => organization);

  const initialOrgId = get(orgsWithProjectCreate, "0.id", "");

  const [selectedOrganization, setSelectedOrganization] = useState({
    projectTemplates: [],
    teams: [],
  });

  const [orgResult, setOrgResult] = useState([]);

  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);

  const { loading } = useQuery(QUERY, {
    onCompleted: (result) => {
      const initialOrg = get(result, "organizations", []).find(
        (org) => org.id === initialOrgId
      );
      setOrgResult(result);
      setSelectedOrganization(initialOrg);
    },
  });

  const [create, result] = useMutation(CREATE, {
    onCompleted: onCompleted || defaultOnCompleted,
  });

  const [createFromSubmission, createFromSubmissionResult] = useMutation(
    CREATE_FROM_SUBMISSION,
    {
      onCompleted: onCompleted || defaultOnCompleted,
    }
  );

  const handleSubmit = (values) => {
    submissionId
      ? createFromSubmission({
          variables: {
            ...values,
            status: values.projectStatus,
            submissionId,
            templateId:
              values.templateId || selectedOrganization.projectTemplates[0].id, // if values.templateId is undefined, there is only one project template for the org so use that one
          },
        })
      : create({
          variables: {
            ...values,
            status: values.projectStatus,
            templateId:
              values.templateId || selectedOrganization.projectTemplates[0].id, // if values.templateId is undefined, there is only one project template for the org so use that one
          },
        });
  };

  if (loading) return <Loadable loading />;

  const initialValues = {
    ...getInitialValues(selectedOrganization),
    ...defaultValues,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(formikProps) => (
        <Modal
          confirmLabel="Create Project"
          hasFooter
          isConfirmLoading={
            result.loading || createFromSubmissionResult.loading
          }
          onClose={() => {
            formikProps.handleReset();
            onClose();
          }}
          onConfirm={formikProps.handleSubmit}
          open
          title="Create New Project"
          width="45%"
        >
          <Pane display="flex" alignItems="center" justifyContent="center">
            <Pane>
              <img alt="" src={constructionManager} width={200} />
            </Pane>
            <Pane width={250} marginLeft={majorScale(5)}>
              <Form onSubmit={formikProps.handleSubmit}>
                {orgsWithProjectCreate.length > 1 && (
                  <Form.Select
                    label="Organization"
                    name="organizationId"
                    onChange={(selectedOrgId) => {
                      const foundOrganization = get(
                        orgResult,
                        "organizations",
                        []
                      ).find((org) => org.id === selectedOrgId);
                      setSelectedOrganization(foundOrganization);
                    }}
                    options={getOptions(orgsWithProjectCreate)}
                    outerProps={{ marginBottom: majorScale(1) }}
                  />
                )}
                <Form.Input label="Project Name" name="name" />
                {selectedOrganization.projectTemplates.length > 1 && (
                  <Form.Select
                    label="Type"
                    name="templateId"
                    options={getOptions(selectedOrganization.projectTemplates)}
                    outerProps={{ marginTop: majorScale(1) }}
                  />
                )}
                {hasTeamManagement && (
                  <Form.Select
                    label="Team"
                    name="teamId"
                    options={[{ key: "blank", value: null, text: "" }].concat(
                      getOptions(selectedOrganization.teams)
                    )}
                    outerProps={{ marginTop: majorScale(1) }}
                  />
                )}
                <Form.Select
                  label="Project Status"
                  name="projectStatus"
                  options={getProjectStatusOptions()}
                  outerProps={{ marginTop: majorScale(2) }}
                />
                <ProjectStatusWarning
                  hasPursuitBundle={selectedOrganization.pursuitBundle}
                  currentStatus={formikProps.values.projectStatus}
                />
              </Form>
            </Pane>
          </Pane>
        </Modal>
      )}
    </Formik>
  );
}

CreateProjectModal.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  submissionId: PropTypes.string,
};

CreateProjectModal.defaultProps = {
  defaultValues: {},
};

import { useContext, Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { OrganizationSelector } from "components/templates";
import { Loadable, Pane } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { get } from "lodash";
import { ScopeOrganizations } from "../ScopeOrganizations";
import { EditUserSidebar } from "./EditUserSidebar";
import { UserTable } from "./UserTable";

export function UsersPage({ history, match }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.MANAGE_USER}>
      {({
        onOrganizationSelected,
        allOrganizations,
        disabledOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane marginLeft={majorScale(2)} marginTop={majorScale(2)}>
            <OrganizationSelector
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              onOrganizationSelected={onOrganizationSelected}
              marginRight={majorScale(2)}
              title="Viewing users for"
            />
          </Pane>
          <UsersPageInner
            history={history}
            match={match}
            selectedOrganization={selectedOrganization}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

function UsersPageInner({ history, match, selectedOrganization }) {
  const { data, loading } = useQuery(USERS_PAGE_QUERY, {
    variables: { organizationId: selectedOrganization.id },
  });

  const { hasPermission } = useContext(UserContext);

  const canEditUsers = hasPermission(
    PERMISSION_ACTION.MANAGE_USER,
    selectedOrganization
  );

  function handleSidebarOpen(user) {
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(`/admin/users/${user.id}${queryStringParams}`);
  }

  function handleSidebarClose() {
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(`/admin${queryStringParams}`);
  }

  if (loading) return <Loadable loading />;

  const { id: organizationId, userAssignablePermissions, hasSso } = get(
    data,
    "organization"
  );
  const users = get(data, "organization.users", []);

  return (
    <Fragment>
      <UserTable
        hasSso={hasSso}
        history={history}
        onClickRow={canEditUsers && handleSidebarOpen}
        organizationId={organizationId}
        selectedOrganization={selectedOrganization}
        users={users}
        userAssignablePermissions={userAssignablePermissions}
      />
      <EditUserSidebar
        selectedOrganization={selectedOrganization}
        closeSidebar={handleSidebarClose}
        match={match}
        history={history}
      />
    </Fragment>
  );
}

export const USERS_PAGE_QUERY = gql`
  query UsersPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      userAssignablePermissions
      users {
        id
        email
        fullName
        registrationComplete
        permissionConfig
        ssoRole
        teams(withPermissions: [VIEW_ALL_TEAM_PROJECTS]) {
          id
          name
        }
      }
      hasSso
    }
  }
`;

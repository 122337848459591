import { Formik } from "formik";
import { v4 as uuid } from "uuid";
import { Form, Modal } from "components/materials";
import { AddItem, ItemType } from "../types";

export interface AddSectionAttributes {
  name: string;
  includeSectionDivider: boolean;
}

export interface Props {
  open: boolean;
  onAdd: (items: AddItem[]) => void;
  onCancel(): void;
}

export function AddSectionModal({ open, onAdd, onCancel }: Props) {
  return (
    <Modal open={open} title="Add Section" onClose={onCancel}>
      <Formik
        initialValues={{
          name: "",
          includeSectionDivider: true,
        }}
        onSubmit={(values) => {
          const section = {
            id: uuid(),
            name: values.name,
            type: ItemType.Section,
          };

          const divider = {
            id: uuid(),
            parentId: section.id,
            name: `${values.name} Divider`,
            type: ItemType.Template,
            attributes: {
              templateId: "divider",
              label: values.name,
            },
          };

          const items = values.includeSectionDivider
            ? [section, divider]
            : [section];

          onAdd(items);
        }}
      >
        {(form) => (
          <Form.Generic
            textSubmit="Add"
            handleSubmit={form.handleSubmit}
            handleCancel={() => {
              form.resetForm();
              onCancel();
            }}
            cancelLabel="Cancel"
          >
            <Form.Input autoFocus type="text" label="Name" name="name" />
            <Form.Checkbox
              label="Include Section Divider"
              name="includeSectionDivider"
            />
          </Form.Generic>
        )}
      </Formik>
    </Modal>
  );
}

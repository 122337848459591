import { Text } from "components/materials";
import { StakeholdersTable } from "components/templates";
import { ReviewCard } from "./ReviewCard";

export function Recipients({ submissions, projectId }) {
  const stakeholders = submissions.map((submission) => {
    const { stakeholder, submittedAt } = submission;
    return { ...stakeholder, submittedAt };
  });
  return (
    <ReviewCard title="Draw Recipients">
      {stakeholders.length > 0 ? (
        <StakeholdersTable
          disableEdits
          projectId={projectId}
          requireEmail
          stakeholders={stakeholders}
          width="100%"
        />
      ) : (
        <Text>Draw has no recipients</Text>
      )}
    </ReviewCard>
  );
}

import { Fragment, useState } from "react";
import { OrganizationSelector } from "components/templates";
import {
  DragAndDrop,
  Menu,
  Modal,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { partition } from "lodash";
import t from "helpers/translate";

function prepareViews({ views, disabled }) {
  return views.map((view, index) => ({
    canRemove: !view.isDefault && !disabled,
    isDraggable: !disabled,
    key: `${view.name}-${index}`,
    secondaryText: view.isDefault ? "default" : null,
    text: view.name,
    value: view,
  }));
}

function ViewsDropdown({ items, title, description, onUpdate, onRemove }) {
  return (
    <Pane paddingTop={majorScale(3)}>
      <Paragraph size={500} color="muted" fontWeight={500}>
        {title}
      </Paragraph>
      <Paragraph marginBottom={majorScale(1)}>{description}</Paragraph>
      <Pane display="flex" justifyContent="center">
        <DragAndDrop
          items={items}
          onUpdate={onUpdate}
          onRemove={onRemove}
          width="60%"
        />
      </Pane>
    </Pane>
  );
}

export const ManageViews = ({
  canManagePublicViews,
  closeDropdown,
  onUpdateViews,
  saveRedirect,
  scopeOrganizationsProps: {
    allOrganizations,
    disabledOrganizations,
    selectedOrganization: defaultSelectedOrg,
  },
  setSaveRedirect,
  views,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // scopeOrganizationsProps is passed from org-level tables that show saved views from multiple orgs
  // (currently this is just the PortfolioProjectTable)
  // "selectedOrganization" is set up for this case, to allow the user to select for which org they are managing views
  // if "selectedOrganization.id" is undefined, as it will be in all other cases,
  // the manage views mutation will default to whichever contextId (orgId) is passed in the table's definition
  const [selectedOrganization, setSelectedOrganization] = useState(
    defaultSelectedOrg
  );

  const viewsForOrganization =
    selectedOrganization && selectedOrganization.id
      ? views.filter(
          ({ organizationId }) => organizationId === selectedOrganization.id
        )
      : views;

  const [privateViews, publicViews] = partition(
    viewsForOrganization,
    ({ isPrivate }) => isPrivate
  );

  const [orderedPrivateViews, setOrderedPrivateViews] = useState(privateViews);
  const [orderedPublicViews, setOrderedPublicViews] = useState(publicViews);
  const [viewIdsToDelete, setViewIdsToDelete] = useState([]);

  if (!onUpdateViews) return null;

  const handleUpdatePrivate = (updatedItems) => {
    setOrderedPrivateViews(updatedItems.map(({ value }) => value));
  };

  const handleRemovePrivate = ({ value: removedView }) => {
    setViewIdsToDelete((currentIds) => [...currentIds, removedView.id]);
    const newViews = orderedPrivateViews.filter(
      (view) => view.id !== removedView.id
    );

    setOrderedPrivateViews(newViews);
  };

  const handleUpdatePublic = (updatedItems) => {
    setOrderedPublicViews(updatedItems.map(({ value }) => value));
  };

  const handleRemovePublic = ({ value: removedView }) => {
    setViewIdsToDelete((currentIds) => [...currentIds, removedView.id]);
    const newViews = orderedPublicViews.filter(
      (view) => view.id !== removedView.id
    );

    setOrderedPublicViews(newViews);
  };

  const handleSubmit = () => {
    const viewsInformation = orderedPrivateViews
      .concat(orderedPublicViews)
      .map(({ id, name, isPrivate }) => ({ id, name, isPrivate }));

    onUpdateViews({
      viewsInformation,
      userSelectedOrganizationId: selectedOrganization.id,
      viewIdsToDelete,
    });
  };

  const handleRedirect = () => {
    setSaveRedirect(true);
  };

  const hasPublicViewsPerm =
    typeof canManagePublicViews === "function"
      ? canManagePublicViews(selectedOrganization)
      : canManagePublicViews;

  const hasViews = views.length > 0;

  const renderModal = () => (
    <Modal
      open={isModalOpen}
      title="Manage Views"
      hasClose={false}
      hasFooter
      confirmLabel="Save"
      withoutAutofocus
      isConfirmDisabled={!hasViews}
      onConfirm={(close) => {
        handleSubmit();
        close();
      }}
      onCloseComplete={() => {
        setIsModalOpen(false);
        if (saveRedirect === false) closeDropdown();
      }}
    >
      {({ close }) => {
        return hasViews ? (
          <Fragment>
            <Paragraph>{t(`manageTableViews.description`)}</Paragraph>
            {allOrganizations.length > 1 && (
              <OrganizationSelector
                title="Manage current view for"
                organizations={allOrganizations}
                disabledOrganizations={disabledOrganizations}
                selectedOrganization={selectedOrganization}
                onOrganizationSelected={(organization) => {
                  setSelectedOrganization(organization);

                  const viewsForOrganization = views.filter(
                    ({ organizationId }) => organizationId === organization.id
                  );

                  const [privateViews, publicViews] = partition(
                    viewsForOrganization,
                    ({ isPrivate }) => isPrivate
                  );

                  setOrderedPrivateViews(privateViews);
                  setOrderedPublicViews(publicViews);
                }}
              />
            )}
            {orderedPrivateViews.length > 0 && (
              <ViewsDropdown
                items={prepareViews({
                  views: orderedPrivateViews,
                })}
                title="Private Views"
                description={t(`manageTableViews.privateViews`)}
                onUpdate={handleUpdatePrivate}
                onRemove={handleRemovePrivate}
              />
            )}
            {orderedPublicViews.length > 0 && (
              <ViewsDropdown
                items={prepareViews({
                  views: orderedPublicViews,
                  disabled: !hasPublicViewsPerm,
                })}
                title="Public Views"
                description={
                  hasPublicViewsPerm
                    ? t(`manageTableViews.publicViews`)
                    : t(`manageTableViews.noPermission`)
                }
                onUpdate={handleUpdatePublic}
                onRemove={handleRemovePublic}
              />
            )}
          </Fragment>
        ) : (
          <Paragraph>
            You have no saved views.{" "}
            <Text
              color="selected"
              cursor="pointer"
              onClick={() => {
                handleRedirect();
                close();
              }}
            >
              Click here
            </Text>{" "}
            to save current view.
          </Paragraph>
        );
      }}
    </Modal>
  );

  return (
    <Menu.Item is={Text} onSelect={() => setIsModalOpen(true)}>
      Manage Views
      {renderModal()}
    </Menu.Item>
  );
};

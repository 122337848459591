import { useMemo, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { BlankSlate, DrawHardCostsTable } from "components/templates";
import { Loadable } from "components/materials";
import { debounce, first, flatten, get } from "lodash";
import { subtract, sumBy } from "helpers/math";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { LINE_ITEM_DOCUMENTATION_FRAGMENT } from "helpers/fragments";
import { getSearchByKey } from "helpers/queryStringHelpers";
import t from "helpers/translate";
import { tablePlaceholder } from "images";
import { DrawLineItemSlideout } from "./DrawLineItemSlideout";

const FRAGMENT = gql`
  fragment DrawHardCostsPageFragment on Draw {
    id
    budgetAmount
    requestedAmount
    hardCostVendorLineItems {
      id
      scopeId
      isGeneralContractor
      isBackup
      requestedAmount
      retainageAmount
      jobCostCodes {
        id
        code
      }
      drawLineItem {
        id
        scopeId
        budgetAmount
        name
        position
        grossRequestedAmount
        requestedAmount
        retainageAmount
        retainagePercentage
        rules(enabled: true, state: "pending") {
          id
          scopeId
          name
          enabled
          state
          type
        }
      }
      vendor {
        id
        name
        vendorCostCode
      }
      conditionalLienWaivers {
        ...lineItemDocumentation
      }
      unconditionalLienWaivers {
        ...lineItemDocumentation
      }
      invoices {
        ...lineItemDocumentation
      }
      other {
        ...lineItemDocumentation
      }
    }
    payApplications: documents(type: ${DOCUMENT_TYPE_NAME.PAY_APPLICATION}) {
      ... on PayApplication {
        id
        vendor {
          id
          name
          vendorCostCode
        }
        file {
          url
          name
        }
      }
    }
    conditionalLienReleases: documents(type: ${DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE}) {
      ... on LienRelease {
        id
        assignedInvoiceId
        file {
          url
          name
        }
      }
    }
    divisions {
      id
      scopeId
    }
    hardCostItems {
      id
      scopeId
      name
      position
      budgetAmount
      requestedAmount
      requestedToDateAmount
      retainageToDateAmount
      retainagePercentage
    }
    hardCostContingencyItems {
      id
      scopeId
      originalBudgetAmount
      requestedAmount
      requestedToDateAmount
      adjustmentsAmount
      previousAdjustmentsAmount
    }
  }
  ${LINE_ITEM_DOCUMENTATION_FRAGMENT}
`;

const QUERY = gql`
  query DrawHardCostsPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        ...DrawHardCostsPageFragment
      }
    }
  }
  ${FRAGMENT}
`;

const SUBMISSION = gql`
  query DrawHardCostsPageSubmission($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        ...DrawHardCostsPageFragment
      }
    }
  }
  ${FRAGMENT}
`;

const DOCUMENT_SUBSCRIPTION = gql`
  subscription onDocumentUpdate($projectId: String!) {
    projectDocumentUpdated(projectId: $projectId) {
      id
      draw {
        id
      }
    }
  }
`;

export function DrawHardCostsPage({ history, match }) {
  const { drawId, projectId, submissionId } = match.params;

  const baseUrl = submissionId
    ? `/submissions/${submissionId}/hard_costs`
    : `/projects/${projectId}/draws/${drawId}/hard_costs`;

  const handleSidebarOpen = (lineItem) => {
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(
      `${baseUrl}/line_items/${
        lineItem.id || lineItem.drawLineItem.id
      }${queryStringParams}`
    );
  };

  const handleSidebarClose = () => {
    if (match.params.documentId) return;
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(`${baseUrl}${queryStringParams}`);
  };

  const query = submissionId ? SUBMISSION : QUERY;
  const variables = submissionId ? { submissionId } : { drawId, projectId };

  const { refetch, ...queryResult } = useQuery(query, { variables });

  const updateQuery = useMemo(
    () => debounce(refetch, 5000, { maxWait: 30000 }),
    [refetch]
  );

  useSubscription(DOCUMENT_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) =>
      get(subscriptionData, "data.projectDocumentUpdated.draw.id") === drawId &&
      updateQuery(),
    variables: { projectId },
  });

  const draw = get(
    queryResult.data,
    submissionId ? "submission.sourceDraw" : "project.draw"
  );
  const drawDivisions = get(draw, "divisions", []);

  // hard cost progress widget
  const hcLineItems = get(draw, "hardCostItems", []);
  const hcBudgetAmount = sumBy(hcLineItems, "budgetAmount");
  const hcRequestedToDate = sumBy(hcLineItems, "requestedToDateAmount");
  const hcRequestedThisDraw = sumBy(
    hcLineItems,
    ({ isBackup, requestedAmount }) => (isBackup ? 0 : requestedAmount)
  );

  const hardCostVendorLineItems = get(draw, "hardCostVendorLineItems", []);
  const vendorLineItems = flatten(
    hcLineItems.map((drawLineItem) => {
      // since vendor line items are initalized on draw creation,
      // we need to filter out vendor line items that also have no documentation associated with them this draw
      const matchingVendorLineItems = hardCostVendorLineItems.filter((vli) => {
        const matchesDrawLineItem =
          get(vli, "drawLineItem.id") === drawLineItem.id;
        const hasDocumentation =
          vli.invoices.length > 0 ||
          vli.conditionalLienWaivers.length > 0 ||
          vli.unconditionalLienWaivers.length > 0;

        return matchesDrawLineItem && hasDocumentation;
      });

      if (matchingVendorLineItems.length > 0) {
        return matchingVendorLineItems;
      }

      // If no documentation is found, return the draw line item information
      // (to be rendered in a "no documentation found" row)
      return { drawLineItem };
    })
  );

  // hard cost contingency progress widget
  const hcContingencyLineItems = get(draw, "hardCostContingencyItems", []);
  const hcContingencyBudget = sumBy(
    hcContingencyLineItems,
    "originalBudgetAmount"
  );
  const hcContingencyRequestedToDate = subtract(
    sumBy(hcContingencyLineItems, "requestedToDateAmount"),
    sumBy(hcContingencyLineItems, "adjustmentsAmount"),
    sumBy(hcContingencyLineItems, "previousAdjustmentsAmount")
  );
  const hcContingencyRequestedThisDraw = subtract(
    sumBy(hcContingencyLineItems, "requestedAmount"),
    sumBy(hcContingencyLineItems, "adjustmentsAmount")
  );

  const gcLineItems = vendorLineItems.filter(
    ({ isGeneralContractor }) => isGeneralContractor
  );

  const gcName = get(first(gcLineItems), "vendor.name");
  const gcPayApplication = get(
    first(gcLineItems.filter(({ invoices }) => invoices.length > 0)),
    "invoices.0"
  );

  // The system supports having a single GC lien waiver
  const gcLineItemsWithLienWaiver = gcLineItems.filter(
    ({ conditionalLienWaivers }) => conditionalLienWaivers.length > 0
  );
  const gcLienWaiver = get(
    first(gcLineItemsWithLienWaiver),
    "conditionalLienWaivers.0"
  );

  if (drawDivisions.length > 0) {
    return (
      <Fragment>
        <DrawHardCostsTable
          baseUrl={baseUrl}
          gcLienWaiver={gcLienWaiver}
          gcName={gcName}
          gcPayApplication={gcPayApplication}
          hcBudgetAmount={hcBudgetAmount}
          hcContingencyBudget={hcContingencyBudget}
          hcContingencyRequestedThisDraw={hcContingencyRequestedThisDraw}
          hcContingencyRequestedToDate={hcContingencyRequestedToDate}
          hcRequestedThisDraw={hcRequestedThisDraw}
          hcRequestedToDate={hcRequestedToDate}
          history={history}
          onClickRow={handleSidebarOpen}
          projectId={projectId}
          vendorLineItems={vendorLineItems}
        />
        <DrawLineItemSlideout
          closeSidebar={handleSidebarClose}
          history={history}
          match={match}
        />
      </Fragment>
    );
  }

  if (queryResult.loading) return <Loadable loading />;

  return (
    <BlankSlate
      attached
      prompt={t("blankSlate.uploadBudget")}
      src={tablePlaceholder}
      to={`/projects/${projectId}/budget`}
    />
  );
}

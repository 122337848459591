import { Fragment } from "react";
import gql from "graphql-tag";
import { Heading, Pane } from "components/materials";
import { getProjectIcon } from "helpers/projectIconHelpers";
import { majorScale, minorScale } from "helpers/utilities";
import { formatDate } from "helpers/dateHelpers";

export const DRAW_SUBMISSION_HEADER_FRAGMENT = gql`
  fragment DrawSubmissionHeaderFragment on DrawSubmission {
    id
    submittedAt
    sourceDraw {
      id
      name
      project {
        id
        name
        template {
          id
          icon
        }
      }
    }
    submitter {
      id
      name
    }
    user {
      id
      fullName
    }
  }
`;

export function ImportDrawSubmissionHeader({ submission, title }) {
  const { icon } = submission.sourceDraw.project.template;
  return (
    <Fragment>
      <Heading>
        {title}
        <Heading.SubHeading marginTop={minorScale(3)}>
          {getProjectIcon(icon, { size: minorScale(3) })}
          {submission.sourceDraw.project.name} - {submission.sourceDraw.name}
        </Heading.SubHeading>
        <Heading.SubHeading>
          {submission.submitter.name} - {submission.user.fullName} -{" "}
          {formatDate(submission.submittedAt)}
        </Heading.SubHeading>
      </Heading>
      <Pane borderBottom marginY={majorScale(2)} />
    </Fragment>
  );
}

export function ImportDrawSubmissionSimpleHeader({ title }) {
  return (
    <Fragment>
      <Heading>{title}</Heading>
      <Pane borderBottom marginY={majorScale(2)} />
    </Fragment>
  );
}

import { ApolloClient, ApolloError } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { hasSubscription } from "@jumpn/utils-graphql";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { create as createAbsintheSocket } from "@absinthe/socket";
import { Socket as PhoenixSocket } from "phoenix";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import axios from "axios";
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import { createLink } from "apollo-absinthe-upload-link";
import analytics from "helpers/analytics";
import { toaster } from "helpers/utilities";
import { get, some } from "lodash";
import introspectionQueryResultData from "./fragmentTypes.json";

const graphqlHost = process.env.REACT_APP_GRAPHQL_HOST;
const socketHost = process.env.REACT_APP_SOCKET_HOST;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

class CacheError extends Error {
  object: string;

  constructor(message: string, object: unknown) {
    super(message);
    this.name = "CacheError";
    this.object = JSON.stringify(object);
  }

  toString = () => `${this.name}:${this.message}:${this.object}`;
}

// based off defaultDataIdFromObject from apollo-cache-inmemory
const dataIdFromObject = (object: any) => {
  switch (object.__typename) {
    case "Actuals":
    case "OriginalDocument":
    case "CashflowProjection":
    case "DismissedStakeholder":
    case "Error":
    case "FundingSourceProjection":
    case "GoogleCoordinates":
    case "NotificationSetting":
    case "Operation":
    case "ReportRecipient":
    case "StakeholderGroup":
    case "SubmissionPatch":
    case "GuestInspectorReportView":
    case "ReviewerConfig":
    case "ContingencySegments":
    case "SegmentData":
    case "ChatgptResponse":
      return null;
    case "File":
      return `${object.__typename}:${object.url}:${object.name}`;
    case "GuestOrganization":
      if (!object.id) {
        return `${object.__typename}:${object.name}`;
      }
      return `${object.__typename}:${object.id}`;
    case "DrawSummaryLineItem":
      if (!object.id) {
        return `${object.__typename}:${object.name}:${object.division}`;
      }
      return `${object.__typename}:${object.id}`;
    case "HardCostPercentageByMonth":
      if (!object.id) {
        return `${object.__typename}:${object.date}:${object.hardCostsGrossPercentComplete}`;
      }
      return `${object.__typename}:${object.date}:${object.id}`;
    case "HardCostPercentages":
    case "HardCostPercentageByDraw":
      return `${object.__typename}:${object.drawId}:${object.drawName}:${object.drawState}:${object.percentHardCostContingencyUsed}:${object.percentHardCostCompleted}:${object.fundedAt}`;
    case "AgreementVendorLineItem":
      // id and scopeId required but can be nil
      if (object.id === undefined) {
        const cacheError = new CacheError("id required", object);
        // eslint-disable-next-line no-console
        console.log(cacheError);
        analytics.error(cacheError);
        throw cacheError;
      }
      if (object.scopeId === undefined) {
        const cacheError = new CacheError("scopeId required", object);
        // eslint-disable-next-line no-console
        console.log(cacheError);
        analytics.error(cacheError);
        throw cacheError;
      }
      return `${object.__typename}:${object.id}:${object.scopeId}`;
    case "Adjustment":
    case "Division":
    case "DrawFundingSource":
    case "LineItem":
    case "ProjectCustomField":
    case "Rule":
    case "VendorLineItem":
      if (!object.id) {
        const cacheError = new CacheError(
          `id required for ${object.__typename}`,
          object
        );
        // eslint-disable-next-line no-console
        console.log(cacheError);
        analytics.error(cacheError);
        throw cacheError;
      }
      if (!object.scopeId) {
        const cacheError = new CacheError(
          `scopeId required for ${object.__typename}`,
          object
        );
        // eslint-disable-next-line no-console
        console.log(cacheError);
        analytics.error(cacheError);
        throw cacheError;
      }
      return `${object.__typename}:${object.id}:${object.scopeId}`;
    default:
      if (!object.id) {
        const cacheError = new CacheError(
          `id required for ${object.__typename}`,
          object
        );
        // eslint-disable-next-line no-console
        console.log(cacheError);
        analytics.error(cacheError);
        throw cacheError;
      }
      return `${object.__typename}:${object.id}`;
  }
};

const cache = new InMemoryCache({
  dataIdFromObject,
  fragmentMatcher,
  freezeResults: true,
});

const combinedLink = (getAccessToken: () => string | null) => {
  const phoenixSocket = new PhoenixSocket(`${socketHost}/socket`, {
    heartbeatIntervalMs: 5000,
    params: () => {
      const accessToken = getAccessToken();
      if (accessToken) return { access_token: accessToken };
      return {};
    },
  });
  // eslint-disable-next-line no-console
  phoenixSocket.onError((error) => console.error("Socket Error", error));

  const absintheSocket = createAbsintheSocket(phoenixSocket);
  // Only typing as any because we don't use
  // split/concat on the websocket link
  const websocketLink = createAbsintheSocketLink(absintheSocket) as any;

  const httpLink = ApolloLink.from([
    setContext(() => {
      const token = getAccessToken();
      if (token) return { headers: { authorization: `Bearer ${token}` } };
      return {};
    }),
    onError((error) => {
      const apolloError = new ApolloError(error);
      const statusCode = get(error, "networkError.statusCode");
      const unauthorized = some(get(error, "graphQLErrors"), [
        "message",
        "unauthorized",
      ]);
      if (statusCode === 401 || unauthorized) {
        analytics.track("Unauthorized Request Attempted");
        window.location.assign("/logout");
      } else if (statusCode! >= 500) {
        analytics.track("Backend Error");
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(error));
        if (!(get(error, "networkError.bodyText") === "Something went wrong")) {
          analytics.error(apolloError);
        }
        window.location.assign("/error");
      } else if (!navigator.onLine) {
        toaster.warning(
          "Action failed. Please check your Internet connection and refresh the page to try again."
        );
      }
    }),
    new ApolloLink((operation, forward) => {
      operation.setContext({ startTime: performance.now() });

      return forward(operation).map((data) => {
        const {
          analyticsMessage,
          documentType,
          startTime,
          originationPage,
          organization,
          sendAnalyticsForOperation,
          userEmail,
          userName,
        } = operation.getContext();

        if (sendAnalyticsForOperation) {
          const { operationName } = operation;

          analytics.track(analyticsMessage || "Document Action Telemetry", {
            ...(documentType ? { documentType } : {}),
            operationName,
            operationTimingInMs: Math.ceil(performance.now() - startTime),
            originationPage,
            organization,
            userEmail,
            userName,
          });
        }
        return data;
      });
    }),
    createLink({
      uri: `${graphqlHost}/graphql`,
      fetch: buildAxiosFetch(axios, (config, input, init) => ({
        ...config,
        onUploadProgress: init.onUploadProgress,
      })),
    }),
  ]);

  return split(
    (operation) => hasSubscription(operation.query),
    websocketLink,
    httpLink
  );
};

const defaultOptions = { watchQuery: { fetchPolicy: "network-only" as const } };

export const createClient = (getAccessToken: () => string | null) =>
  new ApolloClient({
    cache,
    defaultOptions,
    link: combinedLink(getAccessToken),
    assumeImmutableResults: true,
  });

export { cache, defaultOptions };

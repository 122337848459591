import { CSSProperties } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Row, Props as RowProps } from "./Row";

export function SortableRow({ id, depth, type, ...props }: RowProps) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    data: {
      type,
    },
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Row
      ref={setNodeRef}
      id={id}
      type={type}
      style={style}
      depth={depth}
      ghost={isDragging}
      handleProps={{
        ref: setActivatorNodeRef,
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}

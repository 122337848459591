import { Fragment } from "react";
import { FieldArray } from "formik";
import { AddIcon, SmallCrossIcon } from "evergreen-ui";
import {
  Button,
  DescriptionWithHelpLink,
  Divider,
  Form,
  Heading,
  Pane,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { includes } from "lodash";
import t from "helpers/translate";

export function ReviewersForm({
  users,
  values: { drawReviewerIds, preparerId, signatoryId },
}) {
  const enabledDrawReviewerIds = drawReviewerIds.concat([
    preparerId,
    signatoryId,
  ]);
  const getDrawReviewerOptions = (selectedId, extraCond = () => true) =>
    users
      .filter(
        ({ id }) => id === selectedId || !includes(enabledDrawReviewerIds, id)
      )
      .filter(extraCond)
      .map(({ id, fullName }) => ({
        key: id,
        value: id,
        text: fullName,
      }));

  const blankOption = { key: "blank", value: null, text: "" };

  const renderDrawReviewers = ({ remove, push }) => {
    const drawReviewers = drawReviewerIds.map((drawReviewerId, index) => (
      <Pane
        display="flex"
        key={`${drawReviewerId}${index}`}
        marginBottom={majorScale(2)}
        alignItems="center"
      >
        <Pane marginRight={majorScale(2)} flexGrow="1" maxWidth="75%">
          <Form.Select
            name={`drawReviewerIds.${index}`}
            options={getDrawReviewerOptions(drawReviewerId)}
          />
        </Pane>
        <Button
          appearance="minimal"
          data-testid={`removeDrawReviewer-${index}`}
          size="small"
          iconBefore={SmallCrossIcon}
          intent="danger"
          purpose="reviewers-form draw-reviewer remove"
          onClick={() => remove(index)}
        >
          Remove
        </Button>
      </Pane>
    ));

    return (
      <Fragment>
        {drawReviewers}
        <Button
          appearance="minimal"
          data-testid="addDrawReviewer"
          size="small"
          iconBefore={AddIcon}
          purpose="reviewers-form draw-reviewer add"
          onClick={() => push(null)}
        >
          Add a reviewer
        </Button>
      </Fragment>
    );
  };

  function PreparerSetup() {
    return (
      <Pane flex="4 4 0">
        <Heading size={400}>Preparer</Heading>
        <Divider height={minorScale(3)} />
        <DescriptionWithHelpLink
          purpose="project-settings draw-approvals preparer help"
          text={t(`projectSettings.preparer`)}
          helpLink="http://help.rabbet.com/en/articles/2554529-edit-project-settings"
        />
        <Divider height={minorScale(3)} />
        <Form.Select
          key={preparerId}
          maxWidth="75%"
          name="preparerId"
          options={[blankOption].concat(getDrawReviewerOptions(preparerId))}
        />
      </Pane>
    );
  }

  function SignatorySetup() {
    const extraCond = ({ permissionConfig }) =>
      !!permissionConfig.drawSignatory;

    return (
      <Pane flex="4 4 0">
        <Heading size={400}>Signatory</Heading>
        <Divider height={minorScale(3)} />
        <DescriptionWithHelpLink
          purpose="project-settings draw-approvals signatory help"
          text={t(`projectSettings.signatory`)}
          helpLink="http://help.rabbet.com/en/articles/2554529-edit-project-settings"
        />
        <Divider height={minorScale(3)} />
        <Form.Select
          key={signatoryId}
          maxWidth="75%"
          name="signatoryId"
          options={[blankOption].concat(
            getDrawReviewerOptions(signatoryId, extraCond)
          )}
        />
      </Pane>
    );
  }

  return (
    <Fragment>
      <Pane display="flex" marginTop={majorScale(2)} paddingX={majorScale(4)}>
        {/* Use flex "4 4 0" and "1 1 0" to indicate that the divider between panes should be 1/4th the size of the content */}
        <Pane flex="4 4 0">
          <Heading size={400}>Draw Reviewers</Heading>
          <Divider height={minorScale(3)} />
          <DescriptionWithHelpLink
            text={t(`projectSettings.drawReviewers`)}
            purpose="project-settings draw-approvals help"
            helpLink="http://help.rabbet.com/en/articles/2554529-edit-project-settings"
          />
          <Divider height={minorScale(3)} />
          <FieldArray name="drawReviewerIds" render={renderDrawReviewers} />

          <Fragment>
            <Divider />
            <Form.RadioGroup
              display="flex"
              justifyContent="space-between"
              name="updateProject"
              options={[
                {
                  label: "Update this draw only",
                  value: "false",
                },
                {
                  label: "Update this draw and future draws",
                  value: "true",
                },
              ]}
            />
          </Fragment>
        </Pane>
        <Pane flex="1 1 0" />
        <Pane flex="4 4 0">
          <PreparerSetup />
          <Divider />
          <SignatorySetup />
        </Pane>
      </Pane>
    </Fragment>
  );
}

import { useContext, useState, useRef, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ChevronRightIcon } from "evergreen-ui";
import { NotFoundPage } from "components/containers";
import {
  Button,
  Loadable,
  Modal,
  Pane,
  RedesignLayout,
  Text,
  Paragraph,
} from "components/materials";
import { HelpButton, InspectionReportForm } from "components/templates";
import { get, isEqual } from "lodash";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { mergeIntoTheme } from "helpers/themeHelpers";
import {
  majorScale,
  minorScale,
  toaster,
  ThemeContext,
} from "helpers/utilities";
import t from "helpers/translate";
import { validate as validateUUID } from "uuid";
import { poweredByRabbet } from "images";

const QUERY = gql`
  query GuestInspectionReportPage($inspectionReportId: String!) {
    guestInspectionReport(inspectionReportId: $inspectionReportId) {
      id
      inspectorEmail
      inspectorName
      notes
      documentIds
      siteRepresentative
      date
      time
      weather
      status
      submittedAt
      isDeleted
      questions {
        id
        label
        description
        fieldType
      }
      responses {
        id
        inspectionReportQuestionId
        response
      }
      lineItems {
        id
        drawLineItem {
          id
          scopeId
          budgetAmount
          grossPercentComplete
        }
        lineItemId
        notes
        percentComplete
      }
      project {
        id
        name
        streetAddress
        city
        state
        zip
        rules {
          id
          scopeId
          config
          enabled
          name
        }
        owner {
          id
          headerColor
          name
          primaryColor
          primaryLogo {
            url
          }
        }
      }
      draw {
        id
        name
        divisions {
          id
          scopeId
          name
          lineItems {
            id
            name
            scopeId
            balanceToFundAmount
            budgetAmount
            hardCosts
            grossPercentComplete
            grossRequestedAmount
            grossRequestedToDateAmount
            retainageToDateAmount
            maxInspectionPercentCompletePrior
          }
        }
        changeOrders: documents(type: ${DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER}) {
          id
          insertedAt
          agreement {
            id
            inspectionNote
          }
          file {
            url
            name
          }
        }
        inspectionImages: documents(type: ${DOCUMENT_TYPE_NAME.INSPECTION_IMAGE}) {
            id
            insertedAt
            inspectionNote
            isGuestUpload
            file {
              url
              name
            }
        }
        previousInspectionReport{
          id
          draw{
            id
            name
          }
          inspectorEmail
          lineItems{
            id
            lineItemId
            percentComplete
            drawLineItem {
              id
              scopeId
              budgetAmount
              grossRequestedToDateAmount
              retainageToDateAmount
            }
          }
          responses {
            id
            inspectionReportQuestionId
            response
          }
        }
      }
      additionalPendingInspections {
        id
        project {
          id
          name
          owner {
            id
            name
          }
        }
        draw {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_INSPECTION_REPORT = gql`
  mutation UpdateInspectionReport(
    $reportId: String!
    $details: [InspectionDetailsInput]
    $responses: [InspectionResponseInput]
    $lineItems: [InspectionLineItemInput]
    $changeOrders: [InspectionChangeOrderInput]
    $images: [InspectionImageInput]
  ) {
    updateGuestInspectionReport(
      reportId: $reportId
      details: $details
      responses: $responses
      lineItems: $lineItems
      changeOrders: $changeOrders
      images: $images
    ) {
      id
    }
  }
`;

const SUBMIT_INSPECTION_REPORT = gql`
  mutation SubmitInspectionReport($inspectionReportId: String!) {
    submitInspectionReport(inspectionReportId: $inspectionReportId) {
      id
    }
  }
`;

const SAVE_AND_SUBMIT_INSPECTION_REPORT = gql`
  mutation SaveAndSubmitInspectionReport(
    $reportId: String!
    $details: [InspectionDetailsInput]
    $responses: [InspectionResponseInput]
    $lineItems: [InspectionLineItemInput]
    $changeOrders: [InspectionChangeOrderInput]
    $images: [InspectionImageInput]
  ) {
    updateGuestInspectionReport(
      reportId: $reportId
      details: $details
      responses: $responses
      lineItems: $lineItems
      changeOrders: $changeOrders
      images: $images
    ) {
      id
    }
    submitInspectionReport(inspectionReportId: $reportId) {
      id
    }
  }
`;

const navTextProps = {
  color: "#e2e9f0",
  fontSize: 15,
  fontWeight: 500,
  paddingX: majorScale(2),
};

export function GuestInspectionReportPage({ match }) {
  const theme = useContext(ThemeContext);

  const [showAdditionalInspections, setShowAdditionalInspections] = useState(
    false
  );
  const { inspectionReportId } = match.params;
  const invalidUUID = !validateUUID(inspectionReportId);

  const { data, loading } = useQuery(QUERY, {
    skip: invalidUUID,
    variables: { inspectionReportId },
  });

  const [saveInspectionReport, { loading: saveLoading }] = useMutation(
    UPDATE_INSPECTION_REPORT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: { inspectionReportId } }],
      onCompleted: () =>
        toaster.success("Your report has been saved.", {
          duration: 2.5,
        }),
    }
  );

  const [submitInspectionReport, { loading: submitLoading }] = useMutation(
    SUBMIT_INSPECTION_REPORT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: { inspectionReportId } }],
      variables: { inspectionReportId },
      onCompleted: () =>
        toaster.success("Your report has been submitted.", {
          duration: 2.5,
        }),
    }
  );

  const [
    saveAndSubmitInspectionReport,
    { loading: saveSubmitLoading },
  ] = useMutation(SAVE_AND_SUBMIT_INSPECTION_REPORT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, variables: { inspectionReportId } }],
    onCompleted: () =>
      toaster.success("Your report has been submitted.", {
        duration: 2.5,
      }),
  });

  const colors = useRef({});
  if (loading) return <Loadable loading />;

  const drawInspectionReport = get(data, "guestInspectionReport", {});
  const isDeleted = drawInspectionReport.isDeleted === true;

  const {
    project,
    draw,
    additionalPendingInspections,
    ...report
  } = drawInspectionReport;

  const formattedProject = {
    ...project,
    draw: {
      ...draw,
      drawInspectionReport: report,
    },
  };

  const { headerColor, primaryColor, primaryLogo } = get(project, "owner", {});
  const incomingColors = {
    headerColor,
    primaryColor,
    primaryLogo,
  };
  if (!isEqual(colors.current, incomingColors)) {
    colors.current = { ...incomingColors };
    mergeIntoTheme(theme, incomingColors);
  }
  const { headerButtonTextColor, headerIconColor } = theme.defaultColors;
  const orgName = get(project, "owner.name", "Rabbet");

  return (
    <RedesignLayout>
      <RedesignLayout.Nav orgName={orgName}>
        {!primaryLogo && (
          <Fragment>
            <Text data-testid="navbar-org-name" {...navTextProps}>
              {orgName}
            </Text>
            <ChevronRightIcon
              color="#e2e9f0"
              marginX={minorScale(1)}
              size={14}
            />
          </Fragment>
        )}
        <Text
          color={headerButtonTextColor}
          data-testid="navbar-project-name"
          {...navTextProps}
        >
          {get(project, "name", "")}
        </Text>
        <ChevronRightIcon
          color={headerIconColor}
          marginX={minorScale(1)}
          size={14}
        />
        <Text
          color={headerButtonTextColor}
          data-testid="navbar-draw-name"
          {...navTextProps}
        >
          {get(draw, "name")}
        </Text>
        <RedesignLayout.Spacer />
        {primaryLogo && (
          <img
            alt="Powered By Rabbet"
            src={poweredByRabbet}
            style={{ height: "100%", marginRight: majorScale(2) }}
          />
        )}
        <HelpButton theme={theme} />
        {additionalPendingInspections &&
          additionalPendingInspections.length > 0 && (
            <Button
              marginLeft={majorScale(2)}
              marginRight={majorScale(2)}
              onClick={() => setShowAdditionalInspections(true)}
            >
              My Pending Inspections
            </Button>
          )}
      </RedesignLayout.Nav>
      {invalidUUID ? (
        <NotFoundPage />
      ) : (
        <Fragment>
          {isDeleted ? (
            <Paragraph
              marginTop={majorScale(6)}
              textAlign="center"
              fontWeight={500}
              fontSize={20}
            >
              {t("inspectionReport.deletedReport")}
            </Paragraph>
          ) : (
            <InspectionReportForm
              isInspector
              project={formattedProject}
              inspectionReport={report}
              saveLoading={saveLoading}
              submitLoading={submitLoading || saveSubmitLoading}
              saveInspectionReport={saveInspectionReport}
              submitInspectionReport={submitInspectionReport}
              saveAndSubmitInspectionReport={saveAndSubmitInspectionReport}
              previousInspectionReport={draw.previousInspectionReport}
            />
          )}
          {showAdditionalInspections && (
            <Modal
              open
              title={`${report.inspectorEmail} Pending Inspections`}
              onClose={() => setShowAdditionalInspections(false)}
            >
              {({ close }) => (
                <Pane textAlign="center" paddingTop={majorScale(1)}>
                  {additionalPendingInspections.map(({ id, draw, project }) => (
                    <Button
                      key={id}
                      marginBottom={majorScale(1)}
                      onClick={() => {
                        window.location.assign(
                          `/guest/inspection_report/${id}`
                        );
                      }}
                    >{`${project.owner.name} > ${project.name} > ${draw.name}`}</Button>
                  ))}
                  <Pane
                    textAlign="right"
                    marginTop={majorScale(3)}
                    marginX={-majorScale(2)}
                    borderTop
                  >
                    <Button
                      onClick={close}
                      marginRight={majorScale(2)}
                      marginTop={majorScale(2)}
                    >
                      Close
                    </Button>
                  </Pane>
                </Pane>
              )}
            </Modal>
          )}
        </Fragment>
      )}
    </RedesignLayout>
  );
}

import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import { ExpiredLink, ReportSubmissionLayout } from "components/templates";
import { Loadable } from "components/materials";
import { GUEST_PROJECT_FRAGMENT } from "helpers/fragments";

const QUERY = gql`
  query ReportSubmissionPageQuery($reportSubmissionId: String!) {
    reportSubmission(reportSubmissionId: $reportSubmissionId) {
      id
      export {
        url
      }
      sourceDraw {
        id
        name
        submittedDate
        project {
          ...GuestProjectFragment
        }
      }
      uploads {
        id
        contentType
        previewUrl
      }
    }
  }
  ${GUEST_PROJECT_FRAGMENT}
`;

export function ReportSubmissionPage({ match }) {
  const { reportSubmissionId } = match.params;

  const renderContent = ({ loading, error, data }) => {
    if (loading) return <Loadable loading />;
    if (error) return <ExpiredLink />;

    return <ReportSubmissionLayout reportSubmission={data.reportSubmission} />;
  };

  return (
    <Query query={QUERY} variables={{ reportSubmissionId }}>
      {renderContent}
    </Query>
  );
}

ReportSubmissionPage.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      reportSubmissionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

import { useContext } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryScatter,
} from "victory";
import { formatDate } from "helpers/dateHelpers";
import { ThemeContext } from "helpers/utilities";
import { calculateSCurveValues } from "helpers/graphHelpers";

export function ScheduleRiskGraph({
  dataColor,
  graphData,
  graphType,
  projectMonths,
}) {
  const theme = useContext(ThemeContext);
  const graphTrendlineColor = "#CACDD8";

  return (
    <VictoryChart
      padding={{ top: 10, bottom: 45, left: 65, right: 40 }}
      height={200}
      width={520}
    >
      <VictoryAxis
        crossAxis
        domain={[0, projectMonths]}
        tickCount={8}
        style={{
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
        }}
        axisLabelComponent={<VictoryLabel y={182} />}
        label="Project Schedule (Months)"
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, 20, 40, 60, 80, 100]}
        tickFormat={(x) => `${x}%`}
        style={{
          tickLabels: {
            fontSize: 10,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Avenir",
            fill: theme.colors.textGray,
          },
          axis: { stroke: "#FFFFFF" },
          grid: { stroke: theme.colors.backgroundGray },
        }}
        axisLabelComponent={<VictoryLabel x={15} />}
        label="% Hard Cost Complete (Gross)"
      />
      <VictoryScatter
        labelComponent={
          <VictoryTooltip
            style={{
              fontFamily: "Avenir",
              stroke: theme.colors.textGray,
              fontSize: "12px",
            }}
            flyoutStyle={{
              fill: theme.colors.backgroundGray,
              height: "42px",
              stroke: 0,
            }}
          />
        }
        labels={(datum) =>
          datum.month
            ? `${formatDate(datum.month, "", "MMM yyyy")}, ${datum.y}%`
            : null
        }
        name="draw-data-points"
        data={graphData}
        style={{
          data: {
            fill: dataColor,
            stroke: "transparent",
            strokeWidth: 10,
          },
        }}
      />
      <VictoryLine
        name="ideal-linear-trend"
        style={{
          data: {
            stroke: graphTrendlineColor,
            strokeDasharray: "14, 12",
          },
        }}
        data={
          graphType === "linear"
            ? [
                { x: 0, y: 0 },
                {
                  x: projectMonths,
                  y: 100,
                },
              ]
            : [...calculateSCurveValues(projectMonths, 100), { x: 0, y: 0 }]
        }
      />
      <VictoryLine
        name="actual-trend"
        labelComponent={<VictoryLabel renderInPortal dy={-20} />}
        style={{
          data: { stroke: dataColor },
        }}
        data={graphData}
      />
    </VictoryChart>
  );
}

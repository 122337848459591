import { get } from "lodash";
import {
  formatEnum,
  buildMapFormatter,
  buildFilterFormatter,
} from "helpers/tableConfigToRequest/formatters";

const DRAW_STATE_MAP = {
  Funded: "funded",
  Incomplete: "incomplete",
  Paused: "paused",
  "Submitted and Changed": "pendingResubmission",
  Active: ["received", "started"],
  "Received and Changed": "receivedAndChanged",
  Rejected: "rejected",
  Submitted: "submitted",
  Withdrawn: "withdrawn",
};

const drawStateMapFormatter = buildMapFormatter(DRAW_STATE_MAP);

const formatDrawStatus = (config) => {
  if (config.__isSearch) {
    return null;
  }

  return drawStateMapFormatter(config);
};

const FIELD_LOOKUP = {
  projectStatus: {
    field: "status",
    format: formatEnum,
  },
  currentDraw: {
    field: "current_draw.status",
    format: formatDrawStatus,
  },
};

export function filters(config) {
  const formatter = buildFilterFormatter(FIELD_LOOKUP);
  const filterConfig = get(config, "filterConfig", []);
  return {
    filters: filterConfig.map(formatter).filter(Boolean),
  };
}

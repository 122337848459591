import { useContext, useEffect, Fragment } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Heading,
  Link,
  Pane,
  Paragraph,
  Table,
  Text,
} from "components/materials";
import { PERMISSION_ACTION, REVIEW_STATE } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { formatCurrency } from "helpers/formatCurrency";
import formatPercent from "helpers/formatPercent";
import { formatDateTime } from "helpers/dateHelpers";
import { majorScale } from "helpers/utilities";
import {
  getSearchByKey,
  mergeSearch,
  removeKey,
} from "helpers/queryStringHelpers";
import { divide, subtract } from "helpers/math";
import { sumBy } from "lodash";
import t from "helpers/translate";
import { sortReviews } from "../../templates/ListReviews";
import { EditDrawReviewers } from "../DrawReviewsPage/EditDrawReviewers";

export function DrawSummary({ draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const showFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );
  const showPaymentStatus = hasPermission(PERMISSION_ACTION.PAYMENT_TRACKING);

  return (
    <Pane borderBottom paddingY={majorScale(3)}>
      <Pane paddingX={majorScale(4)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(3)}>
          <Heading fontSize={16} fontWeight={600} marginRight={majorScale(2)}>
            {`Draw Summary: ${formatCurrency(draw.requestedAmount)}`}
          </Heading>
          <Text fontSize={12} color="muted">
            {`This draw represents ${formatPercent(
              divide(draw.requestedAmount, draw.budgetAmount)
            )} of the total budget of ${formatCurrency(draw.budgetAmount)}`}
          </Text>
        </Pane>
        <Pane display="flex">
          <Pane width="50%" paddingRight={majorScale(1)}>
            <DrawSummaryAdjustments
              draw={draw}
              project={project}
              history={history}
            />
            {showFundingSources && (
              <DrawSummaryFundingSources
                draw={draw}
                project={project}
                history={history}
              />
            )}
          </Pane>
          <Pane width="50%" paddingLeft={majorScale(1)}>
            <DrawSummaryApprovals
              draw={draw}
              project={project}
              history={history}
            />
            {showPaymentStatus && (
              <DrawSummaryPaymentStatus
                draw={draw}
                project={project}
                history={history}
              />
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

function prepareAdjustedLineItems(lineItems) {
  return lineItems
    .map((lineItem) => {
      const currentAdjustment = lineItem.previousLineItem
        ? subtract(
            lineItem.adjustedToDateAmount,
            lineItem.previousLineItem.adjustedToDateAmount
          )
        : lineItem.adjustedToDateAmount;
      const startingBudget = lineItem.previousLineItem
        ? lineItem.previousLineItem.budgetAmount
        : lineItem.originalBudgetAmount;
      const budgetDeltaThisDraw = divide(currentAdjustment, startingBudget);

      return {
        ...lineItem,
        adjustment: currentAdjustment,
        delta: budgetDeltaThisDraw,
      };
    })
    .filter((lineItem) => lineItem.adjustment !== 0)
    .sort((lineItemA, lineItemB) => lineItemB.delta - lineItemA.delta);
}

function DrawSummaryAdjustments({ project, draw, history }) {
  const { hasPermission } = useContext(UserContext);
  const canMakeAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );
  const adjustedLineItems = prepareAdjustedLineItems(draw.lineItems);
  const availableContingency = sumBy(
    draw.lineItems,
    ({ isContingency, budgetAmount }) => (isContingency ? budgetAmount : 0)
  );
  const hasAdjustedLineItems = adjustedLineItems.length > 0;

  const navigateToBudgetAdjustments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/line_items?showAdjustments=true`;
    history.push(route);
  };

  return (
    <Card padding={majorScale(2)} marginBottom={majorScale(2)}>
      <Pane
        display="flex"
        justifyContent="space-between"
        borderBottom
        paddingBottom={majorScale(1)}
      >
        <Pane display="flex" alignItems="center">
          <Heading fontSize={14} fontWeight={500} marginRight={majorScale(2)}>
            {`Line Items Adjustments: ${adjustedLineItems.length}`}
          </Heading>
          <Text fontSize={12} color="muted">
            {`Available Contingency Amount: ${formatCurrency(
              availableContingency
            )}`}
          </Text>
        </Pane>
        {canMakeAdjustments && hasAdjustedLineItems && (
          <Link size={300} onClick={navigateToBudgetAdjustments}>
            View Budget Adjustments
          </Link>
        )}
      </Pane>
      {hasAdjustedLineItems ? (
        <Table paddingBottom={0}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>Line Item</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Adjustment
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Delta
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {adjustedLineItems.map((lineItem) => {
              const deltaProps =
                lineItem.delta < 0 ? { color: "danger" } : { color: "success" };
              return (
                <Table.Row
                  key={lineItem.id}
                  onClick={navigateToBudgetAdjustments}
                >
                  <Table.TextCell>{`${lineItem.name} (${lineItem.divisionName})`}</Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatCurrency(lineItem.adjustment)}
                  </Table.TextCell>
                  <Table.TextCell
                    textAlign="right"
                    textProps={{ color: deltaProps.color }}
                  >
                    {lineItem.delta < 0 ? (
                      <ArrowDownIcon color="danger" />
                    ) : (
                      <ArrowUpIcon color="success" />
                    )}
                    {formatPercent(lineItem.delta)}
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Fragment>
          <Paragraph marginTop={majorScale(1)}>
            There are no line items with non-zero net adjustments on this draw.
          </Paragraph>
          {canMakeAdjustments && (
            <Button
              onClick={navigateToBudgetAdjustments}
              marginTop={majorScale(2)}
            >
              Create Budget Adjustment
            </Button>
          )}
        </Fragment>
      )}
    </Card>
  );
}

function getReviewStatus(review) {
  if (review.state === REVIEW_STATE.PENDING) return "Pending";
  if (review.isPreparer) return "Complete";
  return "Approved";
}

function DrawSummaryApprovals({ draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const canEditUserAccess = hasPermission(PERMISSION_ACTION.EDIT_USER_ACCESS);

  const sortedReviews = sortReviews(draw.reviews);
  const drawApprovalsCount = `${
    sortedReviews.filter((review) => review.state === REVIEW_STATE.APPROVED)
      .length
  } / ${sortedReviews.length}`;

  const hasDrawReviewers = sortedReviews.length > 0;

  const openDrawReviewersModal = () =>
    mergeSearch(history, { showEditDrawReviewers: true });

  const focusDrawApprovals = getSearchByKey(history, "focusDrawApprovals");

  useEffect(() => {
    if (focusDrawApprovals) {
      const element = window.document.querySelector(
        `[id="drawSummaryApprovals"]`
      );
      // Ensure the element was found and has the needed function
      typeof element?.scrollIntoView === "function" &&
        element.scrollIntoView({ behavior: "smooth" });
    }
  }, [focusDrawApprovals]);

  return (
    <Fragment>
      <Card
        id="drawSummaryApprovals"
        padding={majorScale(2)}
        marginBottom={majorScale(2)}
      >
        <Pane
          display="flex"
          justifyContent="space-between"
          borderBottom
          paddingBottom={majorScale(1)}
        >
          <Heading fontSize={14} fontWeight={500}>
            {`Draw Approvals: ${drawApprovalsCount}`}
          </Heading>
          {canEditUserAccess && hasDrawReviewers && (
            <Link size={300} onClick={openDrawReviewersModal}>
              Edit Draw Reviewers
            </Link>
          )}
        </Pane>
        {hasDrawReviewers ? (
          <Table paddingBottom={0}>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                <Table.TextHeaderCell>Approved At</Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sortedReviews.map((review) => {
                return (
                  <Table.Row key={review.id}>
                    <Table.TextCell>
                      {review.reviewer.fullName}
                      {(review.isFinal || review.isPreparer) && (
                        <Text
                          color="muted"
                          fontStyle="italic"
                          size={300}
                          marginLeft={majorScale(1)}
                        >
                          {review.isFinal ? "signatory" : "preparer"}
                        </Text>
                      )}
                    </Table.TextCell>
                    <Table.TextCell>{getReviewStatus(review)}</Table.TextCell>
                    <Table.TextCell>
                      {formatDateTime(review.processedAt, null)}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          <Fragment>
            <Paragraph marginTop={majorScale(1)}>
              No reviewers have been configured for the draw.
            </Paragraph>
            {canEditUserAccess && (
              <Button
                onClick={openDrawReviewersModal}
                marginTop={majorScale(2)}
              >
                Edit Draw Reviewers
              </Button>
            )}
          </Fragment>
        )}
      </Card>
      {!!getSearchByKey(history, "showEditDrawReviewers") && (
        <EditDrawReviewers
          drawId={draw.id}
          projectId={project.id}
          reviews={draw.reviews}
          users={project.users}
          onClose={() => removeKey(history, "showEditDrawReviewers")}
        />
      )}
    </Fragment>
  );
}

function prepareFundingSources(fundingSources) {
  return fundingSources
    .map((fundingSource) => {
      const remainingAmount = subtract(
        fundingSource.amount,
        fundingSource.disbursedToDateAmount
      );
      const percentRemaining = divide(remainingAmount, fundingSource.amount);

      return {
        ...fundingSource,
        percentRemaining,
      };
    })
    .sort(
      (lineItemA, lineItemB) =>
        lineItemB.percentRemaining - lineItemA.percentRemaining
    );
}

function DrawSummaryFundingSources({ draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const canEditProjectSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );

  const preparedFundingSources = prepareFundingSources(draw.fundingSources);

  const fundingSourcesDrawnCount = `${
    preparedFundingSources.filter(
      (fundingSource) => fundingSource.disbursedAmount !== 0
    ).length
  } / ${preparedFundingSources.length}`;

  const hasFundingSources = preparedFundingSources.length > 0;

  const navigateToProjectFundingSources = () => {
    const route = `/projects/${project.id}/settings?settings=fundingSources`;
    history.push(route);
  };

  const navigateToDrawFundingSources = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/funding_sources`;
    history.push(route);
  };

  return (
    <Card padding={majorScale(2)}>
      <Pane
        display="flex"
        justifyContent="space-between"
        borderBottom
        paddingBottom={majorScale(1)}
      >
        <Heading fontSize={14} fontWeight={500}>
          {`Funding Sources: ${fundingSourcesDrawnCount}`}
        </Heading>
        {canEditProjectSettings && hasFundingSources && (
          <Link size={300} onClick={navigateToProjectFundingSources}>
            Edit Funding Sources
          </Link>
        )}
      </Pane>
      {hasFundingSources ? (
        <Table paddingBottom={0}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>Name</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Requested
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Drawn To Date
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Remaining
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {preparedFundingSources.map((fundingSource) => {
              return (
                <Table.Row
                  key={fundingSource.id}
                  onClick={navigateToDrawFundingSources}
                >
                  <Table.TextCell>{fundingSource.label}</Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatCurrency(fundingSource.disbursedAmount)}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatCurrency(fundingSource.disbursedToDateAmount)}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatPercent(fundingSource.percentRemaining)}
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Fragment>
          <Paragraph marginTop={majorScale(1)}>
            No funding sources have been configured for the project.
          </Paragraph>
          {canEditProjectSettings && (
            <Button
              onClick={navigateToProjectFundingSources}
              marginTop={majorScale(2)}
            >
              Add Funding Sources
            </Button>
          )}
        </Fragment>
      )}
    </Card>
  );
}

function DrawSummaryPaymentStatus({ draw, project, history }) {
  const { hasPermission } = useContext(UserContext);
  const canViewDocuments = hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT);
  const canUploadDocuments = hasPermission(PERMISSION_ACTION.UPLOAD_DOCUMENT);

  const { payableDocuments } = draw;

  const paymentStatusCount = `${
    payableDocuments.filter((document) => document.isPaid).length
  } / ${payableDocuments.length}`;

  const navigateToDocuments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation`;
    history.push(route);
  };

  const navigateToDocument = (documentId) => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation/${documentId}`;
    history.push(route);
  };

  const navigateToAddDocuments = () => {
    const route = `/projects/${project.id}/draws/${draw.id}/documentation?uploadDocument=true`;
    history.push(route);
  };

  const hasDocuments = payableDocuments.length > 0;

  return (
    <Card id="drawOverviewPayments" padding={majorScale(2)}>
      <Pane
        display="flex"
        justifyContent="space-between"
        borderBottom
        paddingBottom={majorScale(1)}
      >
        <Heading fontSize={14} fontWeight={500}>
          {`Payment Status: ${paymentStatusCount}`}
        </Heading>
        {hasDocuments && (
          <Link size={300} onClick={navigateToDocuments}>
            View All
          </Link>
        )}
      </Pane>
      {hasDocuments ? (
        <Table paddingBottom={0}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>Vendor</Table.TextHeaderCell>
              <Table.TextHeaderCell>Type</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Amount Requested
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right">
                Amount Paid
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {payableDocuments.slice(0, 5).map((document) => {
              return (
                <Table.Row
                  key={document.id}
                  onClick={
                    canViewDocuments
                      ? () => navigateToDocument(document.id)
                      : null
                  }
                >
                  <Table.TextCell>{document.vendorName}</Table.TextCell>
                  <Table.TextCell>
                    {t(`documentTypeName.${document.type}`)}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatCurrency(document.amount)}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatCurrency(document.amountPaid)}
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Fragment>
          <Paragraph marginTop={majorScale(1)}>
            There are no payable documents on the draw.
          </Paragraph>
          {canUploadDocuments && (
            <Button marginTop={majorScale(2)} onClick={navigateToAddDocuments}>
              Add Documents
            </Button>
          )}
        </Fragment>
      )}
    </Card>
  );
}

import { useContext, useState, useEffect } from "react";
import { noop, isEmpty, find } from "lodash";
import { Button, Pane, SelectMenu } from "components/materials";
import { fromBase64, toBase64 } from "components/materials/FastDataTable";
import { majorScale, ThemeContext } from "helpers/utilities";
import { ChevronDownIcon, CogIcon } from "evergreen-ui";
import { SaveCurrentView } from "../SaveCurrentView";
import { ManageViews } from "../ManageViews";
import { getSavedViewsData } from "../getSavedViewsData";
import { Filters } from "../Filters";
import { filterFields } from "../helpers";

export function InsightControls({
  allOrganizations,
  canManagePublicViews,
  disabledOrganizations,
  filterConfig,
  serializedFilterConfig,
  filteredProjects,
  isConfigurable,
  onCancel,
  onSave,
  onSaveView,
  onUpdateViews,
  organization,
  setFilterConfig,
  setIsConfigurable,
  tableName,
  views,
}) {
  const [selectedSavedView, setSelectedSavedView] = useState({});
  const [saveRedirect, setSaveRedirect] = useState(false);

  const scopeOrganizationsProps = {
    selectedOrganization: organization,
    allOrganizations,
    disabledOrganizations,
  };

  const savedViewsData = getSavedViewsData(scopeOrganizationsProps, views);

  useEffect(() => {
    const foundSavedView = find(
      savedViewsData,
      (savedView) => savedView.config === serializedFilterConfig
    );

    if (foundSavedView && serializedFilterConfig !== selectedSavedView.value) {
      setSelectedSavedView({
        label: foundSavedView.name,
        value: foundSavedView.config,
      });
    }
  }, [serializedFilterConfig, savedViewsData, selectedSavedView]);

  const handleFilterChange = (newFilterConfig) => {
    if (isConfigurable) {
      return;
    }

    if (
      toBase64(newFilterConfig) !== toBase64(filterConfig) &&
      !isEmpty(selectedSavedView)
    ) {
      setSelectedSavedView({});
    }
    setFilterConfig(newFilterConfig);
  };

  const theme = useContext(ThemeContext);
  return (
    <Pane
      backgroundColor={theme.colors.backgroundGray}
      display="flex"
      alignItems="center"
      paddingRight={majorScale(2)}
      paddingTop={majorScale(2)}
    >
      <Filters
        filterConfig={filterConfig}
        filterFields={filterFields}
        projects={filteredProjects}
        setFilterConfig={handleFilterChange}
      />
      <Pane display="flex" marginLeft="auto">
        {!isConfigurable && (
          <SelectMenu
            hasFilter={false}
            options={[
              ...savedViewsData.map(({ name, config }) => ({
                label: name,
                value: config,
              })),
              {
                label: (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CogIcon />
                    <ManageViews
                      canManagePublicViews={canManagePublicViews}
                      closeDropdown={noop}
                      onUpdateViews={onUpdateViews}
                      saveRedirect={saveRedirect}
                      setSaveRedirect={setSaveRedirect}
                      scopeOrganizationsProps={scopeOrganizationsProps}
                      views={views}
                    />
                  </Pane>
                ),
                value: "manage",
              },
            ]}
            onSelect={({ value, label }) => {
              if (value === "manage") {
                return;
              }
              const payload = fromBase64(value);
              handleFilterChange(payload.filterConfig);
              setSelectedSavedView({ label, value });
            }}
            selected={selectedSavedView.value}
            title="Select saved view"
            maxWidth={200}
          >
            <Button
              iconAfter={ChevronDownIcon}
              minWidth={150}
              backgroundColor="white"
              border={`1px solid ${theme.colors.borderGray}`}
              appearance="minimal"
              marginRight={majorScale(1)}
            >
              {selectedSavedView.label
                ? `View: ${selectedSavedView.label}`
                : "Select a saved view..."}
            </Button>
          </SelectMenu>
        )}
        <Pane marginRight={majorScale(1)}>
          <SaveCurrentView
            canManagePublicViews={canManagePublicViews}
            dataColumns={[]}
            closeDropdown={noop}
            groupConfig={{}}
            onSaveView={onSaveView}
            onUpdateViews={onUpdateViews}
            saveRedirect={saveRedirect}
            scopeOrganizationsProps={scopeOrganizationsProps}
            setSaveRedirect={setSaveRedirect}
            tableName={tableName}
            views={views}
          />
        </Pane>

        {isConfigurable ? (
          <Pane display="flex">
            <Button onClick={onCancel} backgroundColor="white">
              Cancel
            </Button>
            <Button
              onClick={onSave}
              marginLeft={majorScale(1)}
              appearance="primary"
            >
              Save
            </Button>
          </Pane>
        ) : (
          <Button
            appearance="primary"
            onClick={() => {
              setIsConfigurable(true);
            }}
          >
            Customize
          </Button>
        )}
      </Pane>
    </Pane>
  );
}

import React, { useContext, useRef, useEffect } from "react";
import Drawer from "react-modern-drawer";
import {
  Pane,
  Heading,
  IconButton,
  Group,
  Paragraph,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import {
  majorScale,
  minorScale,
  SocialMediaIcon,
  CrossIcon,
  ThemeContext,
} from "evergreen-ui";
import { get } from "lodash";
import { Approvals } from "./Approvals/Approvals";
import { Mentions } from "./Mentions/Mentions";
import { ProjectChat } from "./ProjectChat/ProjectChat";

export function CollaborationDrawer({ isOpen, toggleClose, project }) {
  const options = React.useMemo(
    () => [
      { label: "To-Dos", value: "todos" },
      { label: "Project Chat", value: "projectChat" },
    ],
    []
  );

  const [selectedValue, setSelectedValue] = React.useState("todos");
  const theme = useContext(ThemeContext);
  const { userId } = useContext(UserContext);

  const commentsPaneRef = useRef(null);
  const projectCommentsCount = get(project, "comments", []).length;

  useEffect(() => {
    const commentsPane = commentsPaneRef.current;
    if (commentsPane && selectedValue === "projectChat") {
      commentsPane.scrollTop = commentsPane.scrollHeight;
    }
  }, [selectedValue, projectCommentsCount]);

  const CollaborationSelector = ({ label, value }) => {
    const isSelected = value === selectedValue;
    const selectedStyles = {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px 0px rgba(18, 28, 45, 0.10)",
      color: theme.colors.text,
    };

    const unselectedStyles = {
      backgroundColor: "transparent",
      color: "#AEB2C1",
    };

    const currentStyles = isSelected ? selectedStyles : unselectedStyles;

    return (
      <Pane
        borderRadius={minorScale(1)}
        paddingY={majorScale(1)}
        key={label}
        width="50%"
        onClick={() => setSelectedValue(value)}
        cursor="pointer"
        {...currentStyles}
      >
        <Paragraph textAlign="center" size={400} fontWeight={600}>
          {label}
        </Paragraph>
      </Pane>
    );
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleClose}
      duration={200}
      size={540}
      direction="right"
      style={{ marginTop: "68px" }}
    >
      <Pane
        paddingBottom={majorScale(2)}
        marginBottom={majorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray200}`}
        paddingX={majorScale(3)}
        paddingTop={majorScale(2)}
      >
        <Pane display="flex" alignItems="center" marginBottom={minorScale(1)}>
          <Heading fontSize={majorScale(3)} fontWeight={500}>
            Collaboration
          </Heading>
          <Pane display="flex" alignItems="center" marginTop="2px">
            <SocialMediaIcon
              color="muted"
              marginLeft={majorScale(2)}
              size={minorScale(4)}
            />
          </Pane>
          <IconButton
            icon={() => <CrossIcon color="muted" />}
            appearance="minimal"
            type="button"
            marginLeft="auto"
            onClick={toggleClose}
          />
        </Pane>
        <Pane paddingBottom={majorScale(3)}>
          <Heading fontWeight={300} size={400} color={theme.colors.textGray}>
            Review your to-do list and project conversations
          </Heading>
        </Pane>
        <Pane background="#F9F9FA">
          <Group width="100%" padding={minorScale(2)}>
            {options.map(({ label, value }, index) => (
              <CollaborationSelector
                key={`collaboaration-selector-${index}`}
                label={label}
                value={value}
              />
            ))}
          </Group>
        </Pane>
      </Pane>
      <Pane
        ref={commentsPaneRef}
        paddingX={majorScale(3)}
        overflowY="scroll"
        height={selectedValue === "projectChat" ? "53%" : "68%"}
      >
        {selectedValue === "todos" && (
          <Pane>
            <Approvals project={project} currentUserId={userId} />
            <Mentions project={project} currentUserId={userId} />
          </Pane>
        )}
        {selectedValue === "projectChat" && (
          <Pane>
            <ProjectChat project={project} currentUserId={userId} />
          </Pane>
        )}
      </Pane>
    </Drawer>
  );
}

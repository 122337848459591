import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FAST_DOCUMENT_FRAGMENT } from "helpers/fragments";

const QUERY = gql`
  query DrawPackageDocumentsFastQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        fastDocuments {
          id
          ...FastDocumentFragment
        }
      }
    }
  }
  ${FAST_DOCUMENT_FRAGMENT}
`;

export default function useDocumentsQuery(variables = {}) {
  const query = useQuery(QUERY, { variables });

  return query;
}

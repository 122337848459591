import { Button, Divider, Form, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

export function MarkFundedForm({
  form,
  loading,
  handleClose,
  isIncompleteDraw,
}) {
  return (
    <Form onSubmit={form.handleSubmit} error={!!form.error} loading={loading}>
      <Form.DateInput label="Funded Date" name="date" />
      <Divider height={majorScale(4)} />
      <Form.Group>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            content="Cancel"
            purpose="mark-funded cancel"
            onClick={handleClose}
            marginRight={majorScale(1)}
          />
          <Form.SubmitButton
            purpose="mark-funded submit"
            label={isIncompleteDraw ? "Mark Complete" : "Mark Funded"}
          />
        </Pane>
      </Form.Group>
    </Form>
  );
}

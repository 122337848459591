import { useContext, useState } from "react";
import { ArrowRightIcon, DoughnutChartIcon } from "evergreen-ui";
import { css } from "glamor";
import {
  Button,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  ConfigureButtons,
  EmptyCard,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { DonutChart } from "./DonutChart";
import { DonutChartLegend } from "./DonutChartLegend";

export const CONTINGENCY_USAGE_CONFIGURATION_SETTINGS = {
  i: "contingencyUsage",
  x: 1,
  y: 3,
  w: 1,
  h: 1,
  disabled: false,
};

export function ContingencyUsage({
  history,
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const [filterByHardCosts, setFilterByHardCosts] = useState(false);

  const { contingencySegments, draws } = project;
  const hasNoDraws = draws.length === 0;

  const lineItems = project.divisions.flatMap((division) => division.lineItems);
  const hasContingencyLineItems = lineItems.some(
    (lineItem) => lineItem.isContingency
  );
  const hasHardCostContingencyLineItems = lineItems.some(
    (lineItem) => lineItem.hardCosts && lineItem.isContingency
  );

  const getBlankSlateText = () => {
    if (filterByHardCosts && !hasHardCostContingencyLineItems)
      return "This project does not have any hard cost contingency line items.";
    if (!hasContingencyLineItems)
      return "This project does not have any contingency line items.";
    return "This project has no draw.";
  };

  const urlToDirectTo = hasNoDraws
    ? `/projects/${project.id}/draws/new`
    : `/projects/${project.id}/settings?settings=lineItemSettings`;
  const buttonText = hasNoDraws ? "Create New Draw" : "Set Up Contingency";

  if (!hasContingencyLineItems || hasNoDraws) {
    return (
      <EmptyCard
        name="Contingency Usage"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<DoughnutChartIcon color="muted" marginLeft={minorScale(3)} />}
      >
        <Paragraph paddingBottom={majorScale(1)}>
          {getBlankSlateText()}
        </Paragraph>
        <Button
          marginTop={8}
          appearance="primary"
          onClick={() => history.push(urlToDirectTo)}
        >
          {buttonText}
        </Button>
      </EmptyCard>
    );
  }

  const {
    drawnContingency,
    originalContingency,
    percentUsed,
    remainingContingency,
    segments,
    untrackedContingency,
  } = filterByHardCosts
    ? contingencySegments.hardCosts
    : contingencySegments.all;

  const segmentsWithAddedContingencyRows = [
    ...segments,
    { name: "Contingency Remaining", value: remainingContingency },
  ];

  const allSegments =
    drawnContingency > 0
      ? [
          ...segmentsWithAddedContingencyRows,
          { name: "Drawn Contingency", value: drawnContingency },
        ]
      : segmentsWithAddedContingencyRows;

  return (
    <Pane
      width="100%"
      height="100%"
      position="relative"
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        paddingBottom={majorScale(2)}
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            lineHeight={1.2}
            fontWeight={500}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Contingency Usage
          </Heading>
          <DoughnutChartIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        <Pane marginLeft="auto" marginRight={majorScale(1)}>
          <Button
            isActive={filterByHardCosts === false}
            id="showAllCostsButton"
            appearance="minimal"
            {...css({
              borderRadius: 0,
              "&#showAllCostsButton:focus": {
                boxShadow: "none",
              },
            })}
            onClick={() => setFilterByHardCosts(false)}
          >
            Total Costs
          </Button>
          <Button
            isActive={filterByHardCosts === true}
            id="filterHardCostsButton"
            appearance="minimal"
            {...css({
              borderRadius: 0,
              "&#filterHardCostsButton:focus": {
                boxShadow: "none",
              },
            })}
            onClick={() => setFilterByHardCosts(true)}
          >
            Hard Costs
          </Button>
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => history.push(`/projects/${project.id}/budget`)}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
            marginLeft="auto"
          />
        )}
      </Pane>
      {filterByHardCosts && !hasHardCostContingencyLineItems ? (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="50%"
          flexDirection="column"
        >
          <Paragraph>{getBlankSlateText()}</Paragraph>{" "}
          <Button
            marginTop={8}
            appearance="primary"
            onClick={() => history.push(urlToDirectTo)}
          >
            {buttonText}
          </Button>
        </Pane>
      ) : (
        <Pane
          height="80%"
          width="100%"
          display="flex"
          paddingTop={majorScale(1)}
        >
          <DonutChart
            contingencySegments={allSegments}
            percentUsed={percentUsed}
          />
          <DonutChartLegend
            contingencySegments={allSegments}
            untrackedContingency={untrackedContingency}
            originalAmount={originalContingency}
          />
        </Pane>
      )}
    </Pane>
  );
}

import { useState, Fragment, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Confirm, Pane } from "components/materials";
import { PERMISSION_ACTION, REVIEW_STATE } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { UserContext } from "helpers/behaviors";
import { subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { SubmitDraw } from "../SubmitDraw";
import { UnlockDraw } from "./UnlockDraw";
import { EditDrawStateDropdown } from "./EditDrawStateDropdown";
import { MarkComplete } from "./MarkComplete";
import { MarkFunded } from "./MarkFunded";
import { ApproveDraw } from "./ApproveDraw";
import { MARK_COMPLETE } from "./graphql";
import { LockedDrawState } from "./LockedDrawState";

export function PrimaryActions({
  project,
  draw,
  isIncompleteDraw,
  isMostRecentDraw,
  drawIsInLockedState,
}) {
  const [showConfirmMarkCompleted, setShowConfirmMarkCompleted] = useState(
    false
  );

  const [showPackageDraw, setShowPackageDraw] = useState(false);
  const [showMarkComplete, setShowMarkComplete] = useState(false);

  const [markComplete, markCompleteResult] = useMutation(MARK_COMPLETE, {
    variables: { drawId: draw.id },
  });

  const { hasPermission, hasOrgLevelPermission } = useContext(UserContext);

  const hasFullDrawHistory = !!project?.onboardingData?.fullDrawHistory;

  // if the user indicated they were creating a single historical "to-date" draw, we do not save a funded date
  // otherwise, we prompt the user to enter a date when marking complete
  function handleMarkComplete() {
    setShowConfirmMarkCompleted(false);
    if (hasFullDrawHistory) {
      setShowMarkComplete(true);
    } else {
      markComplete();
    }
  }

  // the org permission enables the "Edit Draw Status" feature, the ability to use withdrawn/rejected/paused statuses, and track sequential updates
  // the user permission defines which users have access to the make those updates
  const useAdvancedDrawStatus = hasOrgLevelPermission(
    PERMISSION_ACTION.EDIT_DRAW_STATUS
  );

  // if "useAdvancedDrawStatus" is enabled, the user permission for that feature controls individual access
  // if the account uses default draw status tracking, there is no user perm
  const canChangeDrawStatus =
    hasPermission(PERMISSION_ACTION.EDIT_DRAW_STATUS) || !useAdvancedDrawStatus;

  const {
    reviews,
    recentStateUpdate,
    requestedAmount,
    targetAmount,
    hasSubmissions,
  } = draw;

  const targetAmountReached = requestedAmount === targetAmount;

  // these props are used to bypass the default cobranding theming
  const packageDrawButtonProps =
    reviews.every((review) => review.state === REVIEW_STATE.APPROVED) &&
    !isIncompleteDraw
      ? {
          appearance: "primary",
          themeOverrides: {
            buttonColor: "#ffd081",
            buttonTextColor: "#425A70",
          },
        }
      : {};

  if (drawIsInLockedState) {
    return (
      <Pane display="flex" alignItems="center">
        <LockedDrawState
          recentStateUpdate={recentStateUpdate}
          draw={draw}
          useAdvancedDrawStatus={useAdvancedDrawStatus}
        />
        {canChangeDrawStatus && (
          <UnlockDraw
            draw={draw}
            recentStateUpdate={recentStateUpdate}
            useAdvancedDrawStatus={useAdvancedDrawStatus}
            isMostRecentDraw={isMostRecentDraw}
          />
        )}
      </Pane>
    );
  }

  return (
    <Fragment>
      <Pane display="flex" alignItems="center">
        <ApproveDraw draw={draw} reviews={reviews} />
        {!isIncompleteDraw &&
          (useAdvancedDrawStatus ? (
            <EditDrawStateDropdown draw={draw} projectId={project.id} />
          ) : (
            <MarkFunded
              draw={draw}
              project={project}
              marginRight={majorScale(1)}
            />
          ))}
        {isIncompleteDraw && (
          <Button
            marginRight={majorScale(1)}
            appearance={targetAmountReached ? "primary" : "default"}
            isLoading={markCompleteResult.loading}
            onClick={() =>
              targetAmountReached
                ? handleMarkComplete()
                : setShowConfirmMarkCompleted(true)
            }
            purpose="draw-actions open mark complete"
          >
            Mark Complete
          </Button>
        )}
        {hasPermission(PERMISSION_ACTION.SUBMIT_DRAW) && (
          <Fragment>
            <Button
              {...packageDrawButtonProps}
              onClick={() => setShowPackageDraw(true)}
              marginRight={majorScale(1)}
            >
              {t("drawActions.packageDraw")}
            </Button>
          </Fragment>
        )}
      </Pane>
      {/* MODALS */}
      {showConfirmMarkCompleted && (
        <ConfirmMarkCompleted
          targetAmount={targetAmount}
          requestedAmount={requestedAmount}
          onClose={() => setShowConfirmMarkCompleted(false)}
          onConfirm={handleMarkComplete}
        />
      )}
      {showMarkComplete && (
        <MarkComplete draw={draw} onClose={() => setShowMarkComplete(false)} />
      )}
      {showPackageDraw && (
        <SubmitDraw
          draw={draw}
          hasSubmissions={hasSubmissions}
          projectId={project.id}
          onCloseConfirm={() => setShowPackageDraw(false)}
        />
      )}
    </Fragment>
  );
}

function ConfirmMarkCompleted({
  onClose,
  onConfirm,
  requestedAmount,
  targetAmount,
}) {
  const targetDifference = subtract(targetAmount, requestedAmount);
  const differenceText = t("historicalDraws.confirmMarkComplete.content", {
    amount: formatCurrency(Math.abs(targetDifference)),
    greaterOrLessThan: targetDifference > 0 ? "greater" : "less",
  });

  return (
    <Confirm
      content={differenceText}
      header={t("historicalDraws.confirmMarkComplete.header")}
      onCloseComplete={onClose}
      onConfirm={(close) => {
        close();
        onConfirm();
      }}
      open
    />
  );
}

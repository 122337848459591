import { useContext, useState, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { UploadFilesModal } from "components/templates";
import { Button, Pane } from "components/materials";
import { UserContext, UploadContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  uploadsFragmentCacheObject,
  REVIEW_FRAGMENT,
  UPLOADS_FRAGMENT,
} from "helpers/fragments";
import analytics from "helpers/analytics";
import { STORAGE_KEYS } from "helpers/localStorageKeys";
import { divide } from "helpers/math";
import { set, get, findIndex } from "lodash";
import { v4 as uuid } from "uuid";
import { QUERY as FileUploadStatusQuery } from "./FileUploadStatus";
import { FreezeConfirmModal } from "./FreezeConfirmModal";

const UNFREEZE_DRAW = gql`
  mutation UploadFilesButtonUnfreezeDraw(
    $projectId: String!
    $targetId: String!
  ) {
    unfreezeDraw(projectId: $projectId, targetId: $targetId) {
      id
      isFrozen
      state
    }
    resetReviewsOnTarget(projectId: $projectId, targetId: $targetId) {
      ...ReviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

const UPLOAD_FILE_TO_DRAW = gql`
  mutation UploadFilesButtonUploadFileToDraw(
    $id: String
    $drawId: String!
    $file: Upload
    $toBeSplit: Boolean
  ) {
    uploadFileToDraw(
      id: $id
      drawId: $drawId
      file: $file
      toBeSplit: $toBeSplit
    ) {
      ...UploadsFragment
    }
  }
  ${UPLOADS_FRAGMENT}
`;

const UPLOAD_FILE_TO_PROJECT = gql`
  mutation UploadFilesButtonUploadFileToProject(
    $id: String
    $file: Upload
    $toBeSplit: Boolean
    $projectId: String!
  ) {
    uploadFileToProject(
      id: $id
      file: $file
      toBeSplit: $toBeSplit
      projectId: $projectId
    ) {
      ...UploadsFragment
    }
  }
  ${UPLOADS_FRAGMENT}
`;

const CREATE_INVOICE = gql`
  mutation UploadFilesButtonCreateOnDraw(
    $date: Date
    $drawId: String
    $grossAmount: Currency
    $lineItems: [InvoiceLineItemInput]
    $netAmount: Currency
    $number: String
    $projectId: String!
    $retainageAmount: Currency
    $templateId: String!
  ) {
    createInvoiceFromTemplate(
      date: $date
      drawId: $drawId
      grossAmount: $grossAmount
      lineItems: $lineItems
      netAmount: $netAmount
      number: $number
      projectId: $projectId
      retainageAmount: $retainageAmount
      templateId: $templateId
    ) {
      id
    }
  }
`;

const CREATE_92403 = gql`
  mutation Create92403Page2($drawId: String) {
    create92403Page2(drawId: $drawId) {
      ...UploadsFragment
    }
  }
  ${UPLOADS_FRAGMENT}
`;

const PREVIEW = gql`
  mutation UploadFilesButtonPreview(
    $data: Map!
    $drawId: String
    $projectId: String!
    $templateId: String!
  ) {
    createFileFromTemplate(
      data: $data
      drawId: $drawId
      projectId: $projectId
      templateId: $templateId
    ) {
      name
      type
      url
    }
  }
`;

function updatedCacheData(data, client) {
  const prev = client.readQuery({
    query: FileUploadStatusQuery,
    variables: {},
  });

  const previousUploads = get(prev, "uploads", []);
  const index = findIndex(previousUploads, ["id", data.id]);

  if (index >= 0)
    return set(prev, `uploads[${index}]`, {
      ...get(prev, `uploads[${index}]`),
      ...data,
    });

  return {
    ...prev,
    uploads: [data, ...previousUploads],
  };
}

function uploadFile(client, file, variables, mutation, setUploadProgress) {
  const id = uuid();
  const mutationName = variables.drawId
    ? "uploadFileToDraw"
    : "uploadFileToProject";

  mutation({
    variables: { ...variables, file, id },
    context: {
      fetchOptions: {
        onUploadProgress: (progress) =>
          setUploadProgress(id, divide(progress.loaded, progress.total)),
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      [mutationName]: uploadsFragmentCacheObject(
        id,
        file,
        "UPLOADING",
        variables
      ),
    },
    update: (_proxy, { data: { [mutationName]: uploadFile } }) => {
      const data = updatedCacheData(uploadFile, client);

      client.writeQuery({
        variables: {},
        query: FileUploadStatusQuery,
        data,
      });
    },
  })
    .then((resp) => {
      analytics.track("File Uploaded", {
        ...variables,
        contentType: file.type,
        size: file.size,
      });
      return Promise.resolve(resp);
    })
    .catch(() => {
      const data = updatedCacheData(
        uploadsFragmentCacheObject(id, file, "ERROR", variables),
        client
      );

      client.writeQuery({
        variables: {},
        query: FileUploadStatusQuery,
        data,
      });
    });
}

function UploadFilesButton({ buttonProps, text, setOpen }) {
  return (
    <Button
      onClick={() => setOpen(true)}
      purpose="add-document open"
      {...buttonProps}
    >
      {text}
    </Button>
  );
}

function UploadFilesInMenu({ setOpen }) {
  return <Pane onClick={() => setOpen(true)}>Add Documents</Pane>;
}

export function UploadFiles({
  buttonProps,
  documentTemplates,
  drawId,
  drawName,
  isFrozen,
  lineItems,
  openModal,
  projectId,
  projectName,
  projectStatus,
  text,
  uploadFilesInMenu,
}) {
  const { hasPermission } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(null);

  function onCompleted() {
    setPreviewDocument(null);
    setOpen(false);
  }

  useEffect(() => {
    if (openModal) setOpen(true);
  }, [openModal]);

  const [unfreeze, unfreezeResult] = useMutation(UNFREEZE_DRAW);
  const uploadMutation = drawId ? UPLOAD_FILE_TO_DRAW : UPLOAD_FILE_TO_PROJECT;

  const [upload] = useMutation(uploadMutation);
  const [create92403Page2, create92403Page2Result] = useMutation(CREATE_92403, {
    onCompleted,
  });

  const [create, createResult] = useMutation(CREATE_INVOICE, {
    onCompleted,
  });

  const [preview, previewResult] = useMutation(PREVIEW, {
    onCompleted: (result) => {
      setPreviewDocument(result.createFileFromTemplate);
    },
  });

  const { setUploadProgress } = useContext(UploadContext);

  const client = useApolloClient();

  if (!hasPermission(PERMISSION_ACTION.UPLOAD_DOCUMENT)) return null;

  return (
    <Fragment>
      {uploadFilesInMenu ? (
        <UploadFilesInMenu setOpen={setOpen} />
      ) : (
        <UploadFilesButton
          buttonProps={buttonProps}
          text={text}
          setOpen={setOpen}
        />
      )}
      {open &&
        (isFrozen ? (
          <FreezeConfirmModal
            loading={unfreezeResult.loading}
            onCancel={() => setOpen(false)}
            onConfirm={() => {
              const variables = { projectId, targetId: drawId };
              unfreeze({ variables });
            }}
            open
            projectId={projectId}
            targetId={drawId}
            targetType="draw"
          />
        ) : (
          <UploadFilesModal
            containerProps={{
              "data-testid": "add-documents-modal",
            }}
            create={create}
            create92403Page2={create92403Page2}
            create92403Page2Result={create92403Page2Result}
            createResult={createResult}
            documentTemplates={documentTemplates}
            drawId={drawId}
            lineItems={lineItems}
            onCancel={() => {
              setPreviewDocument(null);
              setOpen(false);
            }}
            onSubmit={({ files }) => {
              const filesVariables = { drawId, projectId, projectStatus };
              files.forEach((file) => {
                const variables = {
                  ...filesVariables,
                  toBeSplit: get(file, "toBeSplit", false),
                  otherFilesCount: files.length,
                };
                uploadFile(client, file, variables, upload, setUploadProgress);
              });
              localStorage.setItem(STORAGE_KEYS.HIDE_TOAST, false);
              setOpen(false);
            }}
            preview={preview}
            previewDocument={previewDocument}
            previewResult={previewResult}
            projectId={projectId}
            title={
              drawId
                ? `Add Documents to ${drawName}`
                : `Add Documents to ${projectName}`
            }
          />
        ))}
    </Fragment>
  );
}

import { forwardRef, HTMLAttributes } from "react";
import {
  CrossIcon,
  DragHandleHorizontalIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "evergreen-ui";
import { UniqueIdentifier } from "@dnd-kit/core";
import { majorScale } from "helpers/utilities";
import { Pane, Pill } from "components/materials";
import { ItemType } from "../../types";
import { Action } from "./Action";
import { RowMenu } from "./RowMenu";
import { ItemIcon } from "./ItemIcon";

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  ghost?: boolean;
  handleProps?: any;
  indentationWidth: number;
  id: UniqueIdentifier;
  parentId?: UniqueIdentifier | null;
  name: string;
  type: ItemType;
  onAddAfter?: (type: ItemType) => void;
  onAddIn?: (type: ItemType) => void;
  onCollapse?(): void;
  onRemove?(): void;
}

export const Row = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      ghost,
      handleProps,
      indentationWidth,
      collapsed,
      onCollapse,
      onRemove,
      onAddAfter,
      onAddIn,
      style,
      id,
      parentId,
      name,
      type,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={{ paddingLeft: indentationWidth * depth, ...style }}
        {...props}
      >
        <Pane
          display="flex"
          alignItems="center"
          minHeight={majorScale(4)}
          width="100%"
          borderBottom={onCollapse && !collapsed ? "2px solid #d7e0ea" : ""}
          background={onCollapse ? "tint1" : "white"}
        >
          <Pane display="flex" alignItems="center" justifyContent="left">
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={majorScale(6)}
              minWidth={majorScale(4)}
              cursor="grab"
              {...handleProps}
            >
              <DragHandleHorizontalIcon />
            </Pane>
          </Pane>
          <Pane flexGrow="1" display="flex" alignItems="center">
            <ItemIcon type={type} marginRight={majorScale(1)} />
            {name}
          </Pane>
          <Pane>
            {onCollapse && (
              <Action
                icon={collapsed ? ChevronRightIcon : ChevronDownIcon}
                onClick={onCollapse}
              />
            )}
            <RowMenu
              itemName={name}
              onAddAfter={onAddAfter}
              onAddIn={onAddIn}
              onRemove={onRemove}
            />
            {!clone && onRemove && (
              <Action icon={CrossIcon} onClick={onRemove} />
            )}
            {clone && childCount && childCount > 1 ? (
              <Pill position="absolute" left={0} top={0}>
                {childCount}
              </Pill>
            ) : null}
          </Pane>
        </Pane>
      </div>
    );
  }
);

import { Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { map } from "lodash";
import { Formik } from "formik";
import { NavigationWarnings } from "helpers/behaviors";
import { majorScale } from "helpers/utilities";
import { Button, Form, Pane, Paragraph } from "components/materials";
import { SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION } from "./graphql-queries";
import { ReviewCard } from "./ReviewCard";

export function DrawAssessment({ drawId, questions, responses }) {
  const [saveDrawAssessmentResponses, { loading }] = useMutation(
    SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION
  );

  function getInitialValues(responses) {
    return {
      responses: responses.reduce(
        (current, { drawAssessmentQuestionId, response }) => ({
          ...current,
          [drawAssessmentQuestionId]: response || "",
        }),
        {}
      ),
    };
  }

  function handleSubmit(values) {
    const drawAssessmentResponses = map(
      values.responses,
      (response, questionId) => ({
        drawAssessmentQuestionId: questionId,
        response,
      })
    );

    saveDrawAssessmentResponses({
      variables: { drawId, drawAssessmentResponses },
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(responses)}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <ReviewCard
            headerAction={
              formikProps.dirty ? (
                <Fragment>
                  <Button
                    appearance="primary"
                    onClick={formikProps.handleSubmit}
                    isLoading={loading}
                    marginRight={majorScale(2)}
                  >
                    Save
                  </Button>
                  <Button
                    appearance="default"
                    isLoading={loading}
                    onClick={formikProps.handleReset}
                  >
                    Undo
                  </Button>
                </Fragment>
              ) : null
            }
            title="Draw Assessment"
          >
            <Form marginTop={majorScale(1)}>
              {questions.map(({ id, label, description, fieldType }) => {
                return (
                  <Pane
                    key={id}
                    display="flex"
                    width="100%"
                    marginBottom={majorScale(2)}
                  >
                    <Pane width="50%" marginRight={majorScale(5)}>
                      {fieldType === "textarea" ? (
                        <Form.TextArea
                          name={`responses.${id}`}
                          label={label}
                          labelProps={{ fontWeight: 500 }}
                        />
                      ) : (
                        <Form.Input
                          name={`responses.${id}`}
                          label={label}
                          labelProps={{ fontWeight: 500 }}
                        />
                      )}
                    </Pane>
                    <Pane width="50%" paddingTop={20}>
                      <Paragraph color="muted">{description}</Paragraph>
                    </Pane>
                  </Pane>
                );
              })}
            </Form>
            <NavigationWarnings dirty={formikProps.dirty} />
          </ReviewCard>
        );
      }}
    </Formik>
  );
}

import { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { css } from "glamor";
import { CompressedIcon } from "evergreen-ui";
import { Button, Pane, Text } from "components/materials";
import { useHistory } from "react-router-dom";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { APPROVE } from "./graphql";
import { PROJECT_DASHBOARD_QUERY } from "../../graphql";

export function DrawApproval({ draw, currentUserId }) {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const [approveDraw] = useMutation(APPROVE, {
    variables: { drawId: draw.id },
    refetchQueries: [
      {
        query: PROJECT_DASHBOARD_QUERY,
        variables: { projectId: draw.project.id, userId: currentUserId },
      },
    ],
  });

  const handleApproveDraw = (event) => {
    event.stopPropagation();
    approveDraw();
  };

  return (
    <Pane
      cursor="pointer"
      backgroundColor="#F9F9FA"
      onClick={() => {
        history.push(`/projects/${draw.project.id}/draws/${draw.id}`);
      }}
      paddingY={majorScale(1)}
      paddingX={minorScale(3)}
      marginBottom={majorScale(1)}
    >
      <Pane display="flex" alignItems="center">
        <Pane height={16} width={16} marginRight={majorScale(1)}>
          <CompressedIcon color="muted" />
        </Pane>
        <Text
          fontWeight={theme.fontWeights.DEMI}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          maxWidth="55%"
          marginRight={majorScale(1)}
          size={300}
        >
          {draw.name}
        </Text>
        <Text fontWeight={600} size={300} whiteSpace="nowrap">
          {formatCurrency(draw.requestedAmount)}
        </Text>
        <Button
          appearance="minimal"
          background={theme.colors.buttonBlue}
          color="white"
          height="auto"
          paddingY={minorScale(1)}
          paddingX={minorScale(3)}
          onClick={handleApproveDraw}
          marginLeft="auto"
          id="dashApprove"
          {...css({
            "&#dashApprove:hover": {
              backgroundColor: theme.colors.buttonBlue,
            },
          })}
        >
          <Text color="white" size={300} fontWeight={500}>
            Approve
          </Text>
        </Button>
      </Pane>
    </Pane>
  );
}

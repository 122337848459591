import { useReducer, useState, Fragment, useContext } from "react";
import { AddIcon } from "evergreen-ui";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { Button, Pane, Switch, Table, Text } from "components/materials";
import { get, xor } from "lodash";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale, ThemeContext } from "helpers/utilities";
import { EVENTS, moveBudgetRow } from "./utils";
import BudgetTableDivision from "./BudgetTableDivision";

function BudgetTableHead({
  additionalColumns,
  hasYardiJobCostModule,
  pushDivision,
  setHasChangeAffectingProjection,
  setHasNewLineItem,
  showLineItemNumber,
  showSuperLineItems,
  useEnhancedLineItemReporting,
}) {
  const theme = useContext(ThemeContext);
  const superHeaderWidth =
    3 +
    additionalColumns.length +
    (showSuperLineItems ? 1 : 0) +
    (showLineItemNumber ? 1 : 0) +
    (useEnhancedLineItemReporting ? 1 : 0);
  return (
    <Table.Head>
      <Table.Row>
        <Table.TextHeaderCell colSpan={superHeaderWidth}>
          <Button
            appearance="minimal"
            color={theme.colors.blue600}
            size="small"
            iconBefore={AddIcon}
            onClick={() => {
              setHasChangeAffectingProjection(true);
              setHasNewLineItem(true);
              pushDivision();
            }}
            purpose="project-budget division add"
          >
            Add Division
          </Button>
        </Table.TextHeaderCell>
        <Table.TextHeaderCell />
      </Table.Row>
      <Table.Row>
        <Table.TextHeaderCell width={88} />
        <Table.TextHeaderCell width={300}>
          Line Item Name (Required)
        </Table.TextHeaderCell>
        <Table.TextHeaderCell width={200}>
          Original Budget Amount (Required)
        </Table.TextHeaderCell>
        {showSuperLineItems && (
          <Table.TextHeaderCell width={200}>
            Summary Line Item
          </Table.TextHeaderCell>
        )}
        {showLineItemNumber &&
          (hasYardiJobCostModule ? (
            <Table.TextHeaderCell width={145}>
              Line Item Number (Yardi Job ID)
            </Table.TextHeaderCell>
          ) : (
            <Table.TextHeaderCell width={145}>
              Line Item Number
            </Table.TextHeaderCell>
          ))}
        {useEnhancedLineItemReporting && (
          <Table.TextHeaderCell width={250}>
            Master Format Division
          </Table.TextHeaderCell>
        )}
        {additionalColumns.map((column) => (
          <Table.TextHeaderCell key={`${column.name}-header`}>
            {column.name}
          </Table.TextHeaderCell>
        ))}
        <Table.TextHeaderCell />
      </Table.Row>
    </Table.Head>
  );
}

function BudgetTableFoot({
  additionalColumns,
  divisions,
  showLineItemNumber,
  showSuperLineItems,
  useEnhancedLineItemReporting,
}) {
  return (
    <Table.Foot>
      <Table.Row>
        <Table.TextFooterCell textAlign="right" colSpan={2}>
          Total
        </Table.TextFooterCell>
        <Table.TextFooterCell>
          {formatCurrency(getColumnTotal(divisions, ({ amount }) => amount))}
        </Table.TextFooterCell>
        {showSuperLineItems && <Table.TextFooterCell />}
        {showLineItemNumber && <Table.TextFooterCell />}
        {useEnhancedLineItemReporting && <Table.TextFooterCell />}
        {additionalColumns.map((column) => (
          <Table.TextFooterCell key={`${column.name}-footer`}>
            {formatCurrency(getColumnTotal(divisions, column.value))}
          </Table.TextFooterCell>
        ))}
        <Table.TextFooterCell />
      </Table.Row>
    </Table.Foot>
  );
}

export const getColumnSubtotal = (division, getColumnValue) =>
  sumBy(division.lineItems, (lineItem) => {
    return getColumnValue(lineItem);
  });

const getColumnTotal = (divisions, getColumnValue) =>
  sumBy(divisions, (division) => getColumnSubtotal(division, getColumnValue));

export function EditableBudgetTable({
  additionalColumns,
  hideLineItemNumberToggle,
  onDivisionEvent,
  onLineItemEvent,
  propsFormik,
  readOnlyAmount,
  setHasChangeAffectingProjection,
  setHasNewLineItem,
}) {
  const [collapsed, collapsedToggle] = useReducer(
    (state, id) => xor(state, [id]),
    []
  );

  const [showLineItemNumber, setShowLineItemNumber] = useState(
    get(propsFormik, "values.showLineItemNumberDefault") ||
      hideLineItemNumberToggle
  );

  const { hasPermission } = useContext(UserContext);
  const useEnhancedLineItemReporting = hasPermission(
    PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING
  );
  const showSuperLineItems = hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS);
  const hasYardiJobCostModule = hasPermission(
    PERMISSION_ACTION.YARDI_JOB_COST_MODULE
  );

  const pushDivision = () => {
    const newLineItem = {
      id: uuid(),
      amount: 0,
      name: "",
      position: 0,
    };
    const newDivision = {
      id: uuid(),
      lineItems: [newLineItem],
      name: "",
      position: propsFormik.values.divisions.length,
    };
    const divisions = [...propsFormik.values.divisions, newDivision];
    propsFormik.setFieldValue("divisions", divisions);
    onDivisionEvent(newDivision, EVENTS.ADD);
  };
  const pushLineItem = (divisionIndex) => {
    const newLineItem = {
      id: uuid(),
      amount: 0,
      name: "",
      position: propsFormik.values.divisions[divisionIndex].lineItems.length,
    };
    const lineItems = [
      ...propsFormik.values.divisions[divisionIndex].lineItems,
      newLineItem,
    ];
    propsFormik.setFieldValue(
      `divisions.${divisionIndex}.lineItems`,
      lineItems
    );
    onLineItemEvent(newLineItem.id, EVENTS.ADD);
  };
  const removeDivision = (divisionIndex) => {
    const divisions = propsFormik.values.divisions.filter(
      (_, index) => index !== divisionIndex
    );
    const division = propsFormik.values.divisions[divisionIndex];
    const divisionsWithUpdatedPositions = divisions.map(
      (division, divisionIndex) => {
        return {
          ...division,
          position: divisionIndex,
        };
      }
    );

    onDivisionEvent(division, EVENTS.DELETE);
    propsFormik.setFieldValue("divisions", divisionsWithUpdatedPositions);
  };
  const removeLineItem = (divisionIndex, lineItemIndex) => {
    const lineItems = propsFormik.values.divisions[
      divisionIndex
    ].lineItems.filter((_, index) => index !== lineItemIndex);
    const lineItemId =
      propsFormik.values.divisions[divisionIndex].lineItems[lineItemIndex].id;
    const lineItemsWithUpdatedPositions = lineItems.map(
      (lineItem, lineItemIndex) => {
        return {
          ...lineItem,
          position: lineItemIndex,
        };
      }
    );

    onLineItemEvent(lineItemId, EVENTS.DELETE);
    propsFormik.setFieldValue(
      `divisions.${divisionIndex}.lineItems`,
      lineItemsWithUpdatedPositions
    );
  };

  return (
    <Fragment>
      {!hideLineItemNumberToggle && (
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <Switch
            onChange={(e) => {
              setShowLineItemNumber(e.target.checked);
            }}
            checked={showLineItemNumber}
            hasCheckIcon
          />
          <Text
            marginLeft={majorScale(2)}
            onClick={() => setShowLineItemNumber(!showLineItemNumber)}
            cursor="pointer"
            size={300}
            fontWeight={500}
          >
            Enter Line Item Numbers
          </Text>
        </Pane>
      )}
      <Table hover={false}>
        <BudgetTableHead
          additionalColumns={additionalColumns}
          hasYardiJobCostModule={hasYardiJobCostModule}
          pushDivision={pushDivision}
          setHasChangeAffectingProjection={setHasChangeAffectingProjection}
          setHasNewLineItem={setHasNewLineItem}
          showLineItemNumber={showLineItemNumber}
          showSuperLineItems={showSuperLineItems}
          useEnhancedLineItemReporting={useEnhancedLineItemReporting}
        />
        <Table.Body>
          {propsFormik.values.divisions.map((division, index) => (
            <BudgetTableDivision
              additionalColumns={additionalColumns}
              collapsed={collapsed}
              collapsedToggle={collapsedToggle}
              division={division}
              divisionCount={propsFormik.values.divisions.length}
              divisionIndex={index}
              key={`${division.id}-${index}`}
              onMoveRow={(id, type, direction) => {
                const sortedDivisions = moveBudgetRow(
                  propsFormik.values.divisions,
                  id,
                  type,
                  direction
                );

                propsFormik.setFieldValue("divisions", sortedDivisions);
              }}
              onDivisionEvent={onDivisionEvent}
              onLineItemEvent={onLineItemEvent}
              pushLineItem={pushLineItem}
              readOnlyAmount={readOnlyAmount}
              removeDivision={removeDivision}
              removeLineItem={removeLineItem}
              setHasChangeAffectingProjection={setHasChangeAffectingProjection}
              setHasNewLineItem={setHasNewLineItem}
              showLineItemNumber={showLineItemNumber}
              showSuperLineItems={showSuperLineItems}
              useEnhancedLineItemReporting={useEnhancedLineItemReporting}
            />
          ))}
        </Table.Body>
        <BudgetTableFoot
          additionalColumns={additionalColumns}
          divisions={propsFormik.values.divisions}
          showLineItemNumber={showLineItemNumber}
          showSuperLineItems={showSuperLineItems}
          useEnhancedLineItemReporting={useEnhancedLineItemReporting}
        />
      </Table>
    </Fragment>
  );
}

EditableBudgetTable.propTypes = {
  additionalColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.func.isRequired,
    })
  ),
  onDivisionEvent: PropTypes.func,
  onLineItemEvent: PropTypes.func,
  propsFormik: PropTypes.any.isRequired,
  readOnlyAmount: PropTypes.bool,
  setHasChangeAffectingProjection: PropTypes.func,
  setHasNewLineItem: PropTypes.func,
};

EditableBudgetTable.defaultProps = {
  additionalColumns: [],
  onDivisionEvent: () => {},
  onLineItemEvent: () => {},
  setHasChangeAffectingProjection: () => {},
  setHasNewLineItem: () => {},
};

import { useState, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import { ExpiredLink } from "components/templates";
import { Loadable } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { ImportDrawSubmissionAdjustments } from "./ImportDrawSubmissionAdjustments";
import { ImportDrawSubmissionCreate } from "./ImportDrawSubmissionCreate";
import { ImportDrawSubmissionFundingSourcesDecision } from "./ImportDrawSubmissionFundingSourcesDecision";

const QUERY = gql`
  query ImportDrawSubmissionPageQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      expired
      version
      connection {
        id
        version
        targetDraw {
          id
          name
          project {
            id
          }
        }
      }
      latestSubmission {
        id
      }
      sourceDraw {
        id
        project {
          id
        }
      }
      submitter {
        id
        name
      }
    }
  }
`;

export function ImportDrawSubmissionPage(props) {
  const { submissionId } = props.match.params;
  const [variables, setVariables] = useState(null);
  const { organizationId } = useContext(UserContext);
  const { data, error, loading } = useQuery(QUERY, {
    variables: { submissionId },
  });

  if (variables && !variables.adjustmentsConfirmed) {
    return (
      <ImportDrawSubmissionAdjustments
        onNext={() =>
          setVariables({ ...variables, adjustmentsConfirmed: true })
        }
        submissionId={submissionId}
      />
    );
  }

  if (variables) {
    return (
      <ImportDrawSubmissionFundingSourcesDecision
        submissionId={submissionId}
        variables={variables}
      />
    );
  }

  if (loading) {
    return <Loadable loading />;
  }

  if (error) {
    return <ExpiredLink />;
  }

  if (data.submission.id !== data.submission.latestSubmission.id) {
    return (
      <Redirect
        to={`/draw_submissions/${data.submission.latestSubmission.id}/import`}
      />
    );
  }

  if (data.submission.submitter.id === organizationId) {
    return (
      <Redirect
        to={`/projects/${data.submission.sourceDraw.project.id}/draws/${data.submission.sourceDraw.id}`}
      />
    );
  }

  if (data.submission.expired) {
    return <ExpiredLink sender={data.submission.submitter.name} />;
  }

  if (data.submission.connection) {
    if (data.submission.version <= data.submission.connection.version) {
      return (
        <Redirect
          to={`/projects/${data.submission.connection.targetDraw.project.id}/draws/${data.submission.connection.targetDraw.id}`}
        />
      );
    }

    setVariables({
      drawName: data.submission.connection.targetDraw.name,
      projectId: data.submission.connection.targetDraw.project.id,
    });

    return <Loadable loading />;
  }

  return <ImportDrawSubmissionCreate onNext={setVariables} {...props} />;
}

import { useState, Fragment } from "react";
import { Alert, Dropzone, Form, Wizard } from "components/materials";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { values } from "lodash";
import t from "helpers/translate";
import { AddRecipientsStep } from "./AddRecipientsStep";

const getOptions = () =>
  values(DOCUMENT_TYPE_NAME).map((name) => ({
    key: name,
    text: t(`documentTypeName.${name}`),
    value: name,
  }));

export function AddDocumentsStep({
  documentType,
  onBack,
  onChange,
  onNext,
  uploads,
}) {
  const [error, setError] = useState(null);

  const changeDocumentType = (newDocumentType) => {
    onChange({ documentType: newDocumentType });
  };
  const concatFiles = (newFiles) => {
    onChange((prevState) => ({
      uploads: prevState.uploads.concat(newFiles),
    }));
  };
  const removeFile = (index) => {
    onChange((prevState) => ({
      uploads: prevState.uploads.filter((upload, i) => i !== index),
    }));
  };
  const handleNext = () => {
    if (uploads.length < 1) {
      setError("Please add a file");
    } else if (!documentType) {
      setError("Please select a document type");
    } else {
      onNext(AddRecipientsStep);
    }
  };

  return (
    <Fragment>
      <Wizard.Content>
        <Form.Select
          label="Document Type"
          marginBottom={majorScale(2)}
          onChange={changeDocumentType}
          options={getOptions()}
        />
        <Dropzone files={uploads} onAdd={concatFiles} onRemove={removeFile} />
        {error && <Alert intent="danger" title={error} />}
      </Wizard.Content>
      <Wizard.Actions onBack={onBack} onNext={handleNext} />
    </Fragment>
  );
}

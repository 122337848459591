import { useEffect, Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import { Documents, prepareDocuments } from "./Documents";
import { Rules } from "./Rules";
import { Comments } from "./Comments";
import { DrawSummary } from "./DrawSummary";
import { DRAW_OVERVIEW_PAGE_QUERY, RULE_SUBSCRIPTION } from "./graphql";

export function DrawOverviewPage({ history, match }) {
  const { projectId, drawId } = match.params;

  const { data, loading, subscribeToMore } = useQuery(
    DRAW_OVERVIEW_PAGE_QUERY,
    {
      variables: { projectId, drawId },
    }
  );

  useEffect(() => {
    return subscribeToMore({
      document: RULE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const { rules } = subscriptionData;
        return {
          ...prev,
          draw: { ...prev.draw, rules },
        };
      },
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  if (loading) return <Loadable loading />;

  const { project } = data;
  const { draw } = project;

  const preparedDocumentGroups = prepareDocuments(
    draw.drawOverviewDocuments,
    project.documentReviewers
  );

  return (
    <Fragment>
      <Pane width="100%" display="flex" justifyContent="center">
        <Pane width={1400} maxWidth={1400}>
          <Documents
            preparedDocumentGroups={preparedDocumentGroups}
            draw={draw}
            project={project}
            history={history}
          />
          <Rules draw={draw} project={project} history={history} />
          <Comments draw={draw} history={history} />
          <DrawSummary draw={draw} project={project} history={history} />
        </Pane>
      </Pane>
    </Fragment>
  );
}

import { useContext } from "react";
import { Pane } from "components/materials";
import { ScopeOrganizations } from "components/containers";
import { OrganizationSelector } from "components/templates";
import { majorScale, ThemeContext } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { InsightCards } from "./InsightCards/InsightCards";

export function PortfolioInsights({ history }) {
  const theme = useContext(ThemeContext);

  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        allOrganizations,
        disabledOrganizations,
        onOrganizationSelected,
        selectedOrganization,
      }) => {
        return (
          <Pane backgroundColor={theme.colors.backgroundGray}>
            {allOrganizations.length > 1 && (
              <Pane padding={majorScale(2)}>
                <OrganizationSelector
                  disabledOrganizations={disabledOrganizations}
                  onOrganizationSelected={onOrganizationSelected}
                  organizations={allOrganizations}
                  selectedOrganization={selectedOrganization}
                  title="Viewing portfolio for"
                />
              </Pane>
            )}
            <Pane
              display="flex"
              height="auto"
              justifyContent="center"
              paddingTop={majorScale(2)}
              width="100%"
            >
              <Pane
                maxWidth={1440}
                paddingBottom={majorScale(2)}
                paddingLeft={majorScale(2)}
                paddingRight={majorScale(2)}
                width="90%"
              >
                <InsightCards
                  allOrganizations={allOrganizations}
                  disabledOrganizations={disabledOrganizations}
                  history={history}
                  organization={selectedOrganization}
                />
              </Pane>
            </Pane>
          </Pane>
        );
      }}
    </ScopeOrganizations>
  );
}

export function formatEnum(config) {
  if (!config.enum) {
    return null;
  }

  return config.enum.map((status) => status.toLowerCase());
}

export function buildMapFormatter(map) {
  return (config) => {
    if (!config.enum) {
      return null;
    }

    return config.enum.flatMap((lookup) => map[lookup]).filter(Boolean);
  };
}

export function formatIdentity(config) {
  return config.input;
}

export function formatOperator(operator, value) {
  if (Array.isArray(value)) {
    switch (operator) {
      case "NOT_EQUAL":
        return "NOT IN";
      default:
        return "IN";
    }
  }

  switch (operator) {
    case "GREATER_THAN":
      return ">";
    case "LESS_THAN":
      return "<";
    case "EQUAL":
      return "=";
    case "NOT_EQUAL":
      return "!=";
    case "CONTAINS":
      return "LIKE";
    case "NOT_CONTAINS":
      return "NOT LIKE";
    case "EXACT":
      return "=";
    default:
      return "=";
  }
}

export function buildFilterFormatter(fieldLookup) {
  return (tableConfig) => {
    const filterConfig = fieldLookup[tableConfig.key];
    if (!filterConfig) {
      return {};
    }

    const value = filterConfig.format(tableConfig);
    if (value === null || value === undefined) {
      return {};
    }

    const comparator = formatOperator(tableConfig.operator, value);

    return {
      field: filterConfig.field,
      comparator,
      [Array.isArray(value) ? "values" : "value"]: value,
    };
  };
}

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { HelpIcon } from "evergreen-ui";
import { ContentLayout, OrganizationSelector } from "components/templates";
import {
  Form,
  Loadable,
  Pane,
  Paragraph,
  Select,
  Tooltip,
} from "components/materials";
import { Formik } from "formik";
import { toaster, majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import {
  NOTIFICATION_FREQUENCY,
  NOTIFICATION_TYPE,
  PERMISSION_ACTION,
} from "helpers/enums";
import t from "helpers/translate";
import { ScopeOrganizations } from "./ScopeOrganizations";

const NOTIFICATION_TYPES_WITH_PROJECT_SCOPING = [
  "DRAW_SUBMITTED",
  "DOCUMENTS_RECEIVED_VIA_EMAIL",
];

const NOTIFICATION_TYPES_WITH_FREQUENCY = ["ACTIVITY_SUMMARY"];

const NOTIFICATION_TYPES_WITH_DAY_OFFSET = ["NO_DRAWS_RECEIVED"];

const USER_NOTIFICATION_SETTINGS_QUERY = gql`
  query NotificationSettingsPageInnerQuery($organizationId: String!) {
    user(organizationId: $organizationId) {
      id
      notificationSettings {
        notificationType
        enabled
        frequency
        dayOffset
      }
    }
  }
`;

const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings(
    $userId: String!
    $notificationSettings: [NotificationSettingInput]
  ) {
    updateNotificationSettings(
      userId: $userId
      notificationSettings: $notificationSettings
    ) {
      id
    }
  }
`;

const showSuccessToast = () => {
  toaster.success("Your changes have been saved.", { duration: 2.5 });
};

const showErrorToast = () => {
  toaster.danger("Error, could not save.", { duration: 2.5 });
};

const dayOptions = (options) =>
  options.map((numDays) => {
    const text = `${numDays} ${numDays === 1 ? "day" : "days"}`;
    return { key: numDays, value: numDays, text };
  });

const getNotificationLabel = (notificationType) => {
  if (notificationType === NOTIFICATION_TYPE.NO_DRAWS_RECEIVED) {
    return (
      <Paragraph>
        Send me an email if I have no active draws and a draw has not been
        funded for:
        <Form.Select
          name="notificationSettings.NO_DRAWS_RECEIVED.dayOffset"
          placeholder=""
          options={dayOptions([10, 15, 30, 45, 60])}
          width={150}
        />
      </Paragraph>
    );
  }
  return t(`notificationTypes.${notificationType}`);
};
function initialValues(userNotificationSettings) {
  return {
    notificationSettings: Object.values(NOTIFICATION_TYPE).reduce(
      (acc, type) => {
        const userSetting = userNotificationSettings.find(
          ({ notificationType }) => notificationType === type
        );

        const frequency = NOTIFICATION_TYPES_WITH_FREQUENCY.includes(type)
          ? userSetting?.frequency ?? NOTIFICATION_FREQUENCY.MONTHLY
          : null;

        const dayOffset = NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(type)
          ? userSetting?.dayOffset ?? 45
          : null;

        return {
          ...acc,
          [type]: {
            enabled: userSetting?.enabled ?? false,
            notificationType: type,
            frequency,
            dayOffset,
          },
        };
      },
      {}
    ),
  };
}

// ------------------------------------------------------------------------------------
// This form is copied in Foreman. Any updates should likely be reflected there as well
// ------------------------------------------------------------------------------------
function NotificationSettingsPageInner({
  onOrganizationSelected,
  organizations,
  selectedOrganization,
}) {
  const { data, loading } = useQuery(USER_NOTIFICATION_SETTINGS_QUERY, {
    variables: { organizationId: selectedOrganization.id },
  });
  const [updateNotificationSettings, mutationResult] = useMutation(
    UPDATE_NOTIFICATION_SETTINGS,
    {
      onCompleted: showSuccessToast,
      onError: showErrorToast,
    }
  );

  if (loading) return <Loadable loading />;

  const { user } = data;
  function onSubmit({ notificationSettings }) {
    const sanitizedNotificationSettings = Object.values(
      notificationSettings
    ).map((setting) => {
      const frequency = NOTIFICATION_TYPES_WITH_FREQUENCY.includes(
        setting.notificationType
      )
        ? setting.frequency ?? NOTIFICATION_FREQUENCY.MONTHLY
        : null;

      const dayOffset = NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(
        setting.notificationType
      )
        ? setting.dayOffset ?? 45
        : null;

      return {
        ...setting,
        frequency,
        dayOffset,
      };
    });

    updateNotificationSettings({
      variables: {
        userId: user.id,
        notificationSettings: sanitizedNotificationSettings,
      },
    });
  }
  return (
    <UserContext.Consumer>
      {({ hasPermission }) => {
        const tasksEnabled = hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT);

        const supportedNotifications = tasksEnabled
          ? Object.values(NOTIFICATION_TYPE)
          : Object.values(NOTIFICATION_TYPE).filter(
              (notificationType) =>
                notificationType !== "WEEKLY_TIMELINE_REPORT"
            );

        return (
          <ContentLayout
            fixedWidth
            title={
              <OrganizationSelector
                fallbackTitle="Notification Settings"
                title="Viewing settings for"
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                onOrganizationSelected={onOrganizationSelected}
              />
            }
          >
            <Formik
              initialValues={initialValues(user.notificationSettings)}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {(form) => {
                return (
                  <Form.Generic
                    handleSubmit={form.handleSubmit}
                    loading={mutationResult.loading}
                  >
                    {supportedNotifications.map((notificationType) => (
                      <Pane
                        display="flex"
                        alignItems="center"
                        height={28}
                        key={notificationType}
                        marginY={
                          NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(
                            notificationType
                          )
                            ? 28
                            : undefined
                        }
                      >
                        <Pane display="inline">
                          <Form.Switch
                            labelClickDisabled={NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(
                              notificationType
                            )}
                            label={getNotificationLabel(notificationType)}
                            name={`notificationSettings.${notificationType}.enabled`}
                            textProps={{ marginTop: -1 }}
                          />
                        </Pane>

                        {NOTIFICATION_TYPES_WITH_PROJECT_SCOPING.includes(
                          notificationType
                        ) && (
                          <Pane flex="1" marginTop={-2}>
                            <Tooltip content="This notification must also be enabled on a per-project basis">
                              <HelpIcon
                                color="default"
                                marginLeft={majorScale(1)}
                                size={minorScale(3)}
                              />
                            </Tooltip>
                          </Pane>
                        )}
                        {NOTIFICATION_TYPES_WITH_FREQUENCY.includes(
                          notificationType
                        ) && (
                          <Pane marginTop={-2}>
                            <Select
                              marginLeft={majorScale(1)}
                              height={24}
                              width={150}
                              defaultValue={
                                form.values.notificationSettings[
                                  notificationType
                                ].frequency
                              }
                              onChange={(e) => {
                                const currentSetting =
                                  form.values.notificationSettings[
                                    notificationType
                                  ];
                                const newSetting = {
                                  ...currentSetting,
                                  enabled: true,
                                  frequency: e.target.value,
                                };

                                form.setFieldValue(
                                  `notificationSettings.${notificationType}`,
                                  newSetting
                                );
                              }}
                            >
                              {[
                                NOTIFICATION_FREQUENCY.DAILY,
                                NOTIFICATION_FREQUENCY.WEEKLY,
                                NOTIFICATION_FREQUENCY.MONTHLY,
                              ].map((frequency) => (
                                <option value={frequency}>
                                  {t(`notificationFrequencies.${frequency}`)}
                                </option>
                              ))}
                            </Select>
                          </Pane>
                        )}
                      </Pane>
                    ))}
                  </Form.Generic>
                );
              }}
            </Formik>
          </ContentLayout>
        );
      }}
    </UserContext.Consumer>
  );
}

export function NotificationSettingsPage() {
  return (
    <ScopeOrganizations>
      {({ onOrganizationSelected, organizations, selectedOrganization }) => (
        <NotificationSettingsPageInner
          selectedOrganization={selectedOrganization}
          organizations={organizations}
          onOrganizationSelected={onOrganizationSelected}
        />
      )}
    </ScopeOrganizations>
  );
}

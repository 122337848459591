import { useContext } from "react";
import { Pane, RedesignLayout } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { getIssueIconProps, getTabIssues } from "helpers/issues";
import t from "helpers/translate";
import { UserContext } from "helpers/behaviors";
import { showPaymentsTab } from "./DrawStatus/statusFormulas";

export function DrawNavigation({ draw, match }) {
  const { hasPermission } = useContext(UserContext);
  const { url } = match;
  const drawTabIssues = getTabIssues(draw.issues);

  const showIssues = hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL);
  const hasDrawOverviewPage = hasPermission(
    PERMISSION_ACTION.DRAW_OVERVIEW_PAGE
  );
  const showFundingSourcesTab = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );

  const showInspectionTab = hasPermission(
    PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW
  );

  const shouldShowPaymentsTab = showPaymentsTab(hasPermission);

  const colorOverrides = {
    primaryColor: "#efb25c",
    navigationSelectedText: "#efb25c",
  };

  return (
    <Pane
      display="flex"
      alignItems="center"
      flexGrow="0"
      flexShrink="0"
      flexWrap="nowrap"
      is="nav"
      role="navigation"
    >
      {hasDrawOverviewPage && (
        <RedesignLayout.Tab
          to={`${url}/overview`}
          colorOverrides={colorOverrides}
        >
          {t("navigation.drawTabs.overview")}
        </RedesignLayout.Tab>
      )}
      <RedesignLayout.Tab
        to={`${url}/line_items`}
        {...(showIssues ? getIssueIconProps(drawTabIssues.lineItems) : {})}
        colorOverrides={colorOverrides}
      >
        {t("navigation.drawTabs.lineItems")}
      </RedesignLayout.Tab>
      <RedesignLayout.Tab
        to={`${url}/hard_costs`}
        colorOverrides={colorOverrides}
      >
        {t("navigation.drawTabs.hardCosts")}
      </RedesignLayout.Tab>
      <RedesignLayout.Tab
        to={`${url}/soft_costs`}
        colorOverrides={colorOverrides}
      >
        {t("navigation.drawTabs.softCosts")}
      </RedesignLayout.Tab>
      <RedesignLayout.Tab
        to={`${url}/documentation`}
        {...(showIssues ? getIssueIconProps(drawTabIssues.documents) : {})}
        colorOverrides={colorOverrides}
      >
        {t("navigation.drawTabs.documents")}
      </RedesignLayout.Tab>
      {showFundingSourcesTab && (
        <RedesignLayout.Tab
          to={`${url}/funding_sources`}
          colorOverrides={colorOverrides}
        >
          {t("navigation.drawTabs.fundingSources")}
        </RedesignLayout.Tab>
      )}
      {showInspectionTab && (
        <RedesignLayout.Tab
          to={`${url}/inspection`}
          colorOverrides={colorOverrides}
        >
          {t("navigation.drawTabs.inspection")}
        </RedesignLayout.Tab>
      )}
      {!hasDrawOverviewPage && (
        <RedesignLayout.Tab
          to={`${url}/reviews`}
          colorOverrides={colorOverrides}
        >
          {t("navigation.drawTabs.reviews")}
        </RedesignLayout.Tab>
      )}
      {shouldShowPaymentsTab && (
        <RedesignLayout.Tab
          to={`${url}/payment`}
          colorOverrides={colorOverrides}
        >
          {t("navigation.drawTabs.payment")}
        </RedesignLayout.Tab>
      )}
    </Pane>
  );
}

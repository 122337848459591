import { Fragment, useState } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  MeasuringStrategy,
  defaultDropAnimation,
  DropAnimation,
  Modifier,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { majorScale } from "helpers/utilities";
import { Pane } from "components/materials";
import { SortableRow } from "./SortableRow";
import {
  useEditDrawPackage,
  getChildCount,
} from "../../hooks/useEditDrawPackage";
import { AddSectionModal } from "../AddSectionModal";
import { AddDocumentsModal } from "../AddDocumentsModal";
import { AddTemplateModal } from "../AddTemplateModal";
import { SendDrawModal } from "../SendDrawModal";
import { DrawPackageActions } from "../DrawPackageActions";
import { DrawPackageHistory } from "../DrawPackageHistory";
import { ItemType, AddItem, DownloadType } from "../../types";
import { drawPackage } from "./data";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export interface Props {
  drawId: string;
  projectId: string;
}

export function EditableDrawPackage({ drawId, projectId }: Props) {
  const indentationWidth = majorScale(2);
  const {
    items,
    sortedIds,
    flattenedItems,
    activeItem,
    activeId,
    dragContext,
    projected,
    onAdd,
    onCollapse,
    onRemove,
  } = useEditDrawPackage({ initialState: drawPackage(), indentationWidth });

  const [addModal, setAddModal] = useState<{
    selectedItemId: UniqueIdentifier;
    selectedItemType: ItemType;
    location: "after" | "in";
  } | null>(null);

  const handleAdd = (newItems: AddItem[]) => {
    if (!addModal || !onAdd) return;

    const { location, selectedItemId } = addModal;
    let selectedItem = flattenedItems.find(
      (item) => item.id === selectedItemId
    );

    if (!selectedItem) [selectedItem] = flattenedItems;

    let { parentId, depth } = selectedItem;

    if (location === "in") {
      parentId = selectedItemId;
      depth += 1;
    }

    const defaultParams = {
      parentId,
      depth,
    };

    const formattedNewItems: AddItem[] = newItems.map((item) => ({
      ...defaultParams,
      ...item,
    }));

    setAddModal(null);
    onAdd(selectedItem.id, formattedNewItems);
  };

  const [sendModal, setSendModal] = useState(false);

  return (
    <Fragment>
      <AddSectionModal
        open={addModal?.selectedItemType === ItemType.Section}
        onAdd={handleAdd}
        onCancel={() => setAddModal(null)}
      />
      <AddTemplateModal
        open={addModal?.selectedItemType === ItemType.Template}
        onAdd={handleAdd}
        onCancel={() => setAddModal(null)}
      />
      <AddDocumentsModal
        projectId={projectId}
        drawId={drawId}
        open={addModal?.selectedItemType === ItemType.Document}
        onAdd={handleAdd}
        onCancel={() => setAddModal(null)}
      />
      <SendDrawModal
        items={items}
        open={sendModal}
        onSend={() => {}}
        onCancel={() => setSendModal(false)}
      />
      <DrawPackageActions
        onAdd={(type: ItemType) =>
          setAddModal({
            selectedItemId: items[0].id,
            selectedItemType: type,
            location: "after",
          })
        }
        // eslint-disable-next-line no-alert
        onDownload={(format: DownloadType) => alert(`Download ${format}`)}
        onSend={() => setSendModal(true)}
      />
      <DndContext
        {...dragContext}
        collisionDetection={closestCenter}
        measuring={measuring}
      >
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          <Pane width="100%">
            {flattenedItems.map(
              ({ id, name, type, parentId, collapsed, children, depth }) => (
                <SortableRow
                  key={id}
                  id={id}
                  parentId={parentId}
                  name={name}
                  type={type}
                  depth={id === activeId && projected ? projected.depth : depth}
                  indentationWidth={indentationWidth}
                  collapsed={Boolean(collapsed && children?.length)}
                  onCollapse={
                    children?.length ? () => onCollapse(id) : undefined
                  }
                  onRemove={() => onRemove(id)}
                  onAddIn={
                    type === ItemType.Section
                      ? (type: ItemType) =>
                          setAddModal({
                            selectedItemId: id,
                            selectedItemType: type,
                            location: "in",
                          })
                      : undefined
                  }
                  onAddAfter={(type: ItemType) =>
                    setAddModal({
                      selectedItemId: id,
                      selectedItemType: type,
                      location: "after",
                    })
                  }
                />
              )
            )}
            {createPortal(
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={[adjustTranslate]}
              >
                {activeId && activeItem ? (
                  <SortableRow
                    id={activeId}
                    name={activeItem.name}
                    depth={activeItem.depth}
                    clone
                    childCount={getChildCount(items, activeId) + 1}
                    type={activeItem.type}
                    indentationWidth={indentationWidth}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
          </Pane>
        </SortableContext>
      </DndContext>
      <DrawPackageHistory />
    </Fragment>
  );
}

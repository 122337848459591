import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EditIcon, PaperclipIcon, UndoIcon } from "evergreen-ui";
import { EditProjectSettingsButtons } from "components/templates";
import {
  Accordion,
  Form,
  IconButton,
  Link,
  Pane,
  StickyHeaderTable,
  Table,
  Text,
} from "components/materials";
import { kebabCase, set } from "lodash";
import { isStaging, isProduction } from "helpers/environment";
import isBlank from "helpers/isBlank";
import { UserContext } from "helpers/behaviors";
import { majorScale, toaster } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";

const reactAppHost = process.env.REACT_APP_HOST;

function EditableEmailToAddress({ form }) {
  const { emailToAddress, emailToPrefix, emailToDomain } = form.values;

  const [isEditingEmailAddress, setIsEditingEmailAddress] = useState(
    emailToAddress === null
  );

  useEffect(() => {
    if (!isBlank(emailToAddress)) {
      setIsEditingEmailAddress(false);
    }
  }, [emailToAddress]);

  const {
    initialValues: { emailToEditablePortion: initialEmailToEditablePortion },
    setFieldValue,
  } = form;

  if (isEditingEmailAddress) {
    return (
      <Pane display="flex" alignItems="center">
        <Text fontWeight={600} marginRight={majorScale(1)}>
          {emailToPrefix}
        </Text>
        <Pane>
          <Form.Input
            marginRight={majorScale(1)}
            name="emailToEditablePortion"
            width={125}
          />
        </Pane>
        <Text fontWeight={600} marginRight={majorScale(1)}>
          {emailToDomain}
        </Text>
        {emailToAddress !== null && (
          <IconButton
            height={24}
            icon={UndoIcon}
            onClick={() => {
              setFieldValue(
                "emailToEditablePortion",
                initialEmailToEditablePortion
              );
              setIsEditingEmailAddress(false);
            }}
            purpose="project-settings cancel-edit-email"
          />
        )}
      </Pane>
    );
  }

  return (
    <Pane display="flex" alignItems="center">
      <Link
        content={emailToAddress}
        fontWeight={600}
        href={`mailto:${emailToAddress}`}
        id="emailToAddress"
        marginRight={majorScale(1)}
        purpose="project-settings email-to-rabbet-link"
      />
      <IconButton
        height={24}
        icon={PaperclipIcon}
        marginRight={majorScale(1)}
        onClick={() => handleCopyText(emailToAddress)}
        purpose="project-settings copy-email-link"
      />
      <IconButton
        height={24}
        icon={EditIcon}
        onClick={() => setIsEditingEmailAddress(true)}
        purpose="project-settings edit-email-to-address"
      />
    </Pane>
  );
}

function UserEmailNotificationsTable({ form }) {
  const {
    values: { users },
  } = form;
  const { hasPermission } = useContext(UserContext);
  const hasBorrowerExperience = hasPermission(
    PERMISSION_ACTION.BORROWER_PORTAL
  );
  return (
    <Pane marginTop={majorScale(3)}>
      <Pane border="muted" height={250} overflowY="scroll" width="700px">
        <StickyHeaderTable
          header={
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell width={350}>User</Table.TextHeaderCell>
                <Table.TextHeaderCell
                  tooltip={
                    hasBorrowerExperience
                      ? t(
                          "projectSettings.externalUpload.notificationExplanationWithBorrowerPortal"
                        )
                      : t(
                          "projectSettings.externalUpload.notificationExplanation"
                        )
                  }
                >
                  Receive Email from External Submissions
                </Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
          }
        >
          <Table.Body>
            {users.map((user, index) => (
              <UserEmailNotificationsRow
                key={user.id}
                index={index}
                user={user}
              />
            ))}
          </Table.Body>
        </StickyHeaderTable>
      </Pane>
    </Pane>
  );
}

function UserEmailNotificationsRow({
  index,
  user: { fullName, hasProjectAccess },
}) {
  const disabledProps = hasProjectAccess
    ? {}
    : { disabled: true, label: "User Does Not Have Project Access" };

  return (
    <Table.Row>
      <Table.TextCell width={350}>{fullName}</Table.TextCell>
      <Table.Cell>
        <Form.Checkbox
          marginTop={-8}
          marginBottom={1}
          name={`users.${index}.enabledForThisProject`}
          {...disabledProps}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export function EditExternalUploadPanel({
  accessTokens,
  dirty,
  form,
  loading,
  onToggle,
  panelKey,
  setPanelDirty,
  ...props
}) {
  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
  }, [dirty, form, panelKey, setPanelDirty]);

  const { emailToRabbetEnabled } = form.values;

  return (
    <Accordion.Panel
      panelKey={panelKey}
      title="External Upload"
      onClick={() => onToggle(panelKey)}
      actionContent={
        <EditProjectSettingsButtons
          dirty={dirty}
          form={form}
          loading={loading}
        />
      }
      {...props}
    >
      <Pane paddingTop={majorScale(1)}>
        <Form.Switch
          label="External Upload"
          name="linkSharingEnabled"
          textProps={{ fontWeight: 600 }}
        />
        {accessTokens.length > 0 && (
          <Pane display="flex" alignItems="center">
            <Pane marginRight={majorScale(3)} width="40%">
              <Text fontWeight={500}>
                {t("projectSettings.externalUpload.shareableLink")}
              </Text>
            </Pane>
            <Link
              purpose="project-settings shareable-link"
              fontWeight={600}
              href={`${reactAppHost}/guest/${accessTokens[0]}`}
              content={`${reactAppHost}/guest/${accessTokens[0]}`}
              marginRight={majorScale(1)}
            />
            <IconButton
              height={24}
              icon={PaperclipIcon}
              onClick={() =>
                handleCopyText(`${reactAppHost}/guest/${accessTokens[0]}`)
              }
              purpose="project-settings copy-shareable-link"
            />
          </Pane>
        )}
      </Pane>

      <Pane paddingTop={majorScale(2)}>
        <Form.Switch
          label="Email to Rabbet"
          name="emailToRabbetEnabled"
          textProps={{ fontWeight: 600 }}
        />
        {emailToRabbetEnabled && (
          <Pane display="flex" paddingTop={majorScale(1)}>
            <Pane marginRight={majorScale(3)} width="40%">
              <Text fontWeight={500} marginRight={majorScale(10)}>
                {t("projectSettings.externalUpload.emailToRabbet")}
              </Text>
            </Pane>
            <EditableEmailToAddress form={form} />
          </Pane>
        )}
      </Pane>

      <UserEmailNotificationsTable form={form} />
    </Accordion.Panel>
  );
}

function getEmailToDomain() {
  if (isProduction) {
    return "@dovetail.rabbet.com";
  }
  if (isStaging) {
    return "@dovetail.staging.rabbet.com";
  }
  return "@dovetail.dev.rabbet.com";
}

function getEmailToEditablePortion(project) {
  const { emailToAddress, name: projectName } = project;

  if (emailToAddress === null) {
    return kebabCase(projectName);
  }

  const username = emailToAddress.substring(0, emailToAddress.indexOf("@"));
  const prefix = getEmailToPrefix(project);

  if (username.startsWith(prefix)) {
    return username.substring(prefix.length);
  }

  return username;
}

function getEmailToPrefix({ organization: { name: orgName } }) {
  return kebabCase(orgName.toLowerCase()).concat("-");
}

function handleCopyText(text) {
  const temporaryElement = document.createElement("textarea");
  temporaryElement.innerText = text;
  document.body.appendChild(temporaryElement);
  temporaryElement.select();
  document.execCommand("copy");
  temporaryElement.remove();

  const message = `Copied to Clipboard: ${text}`;
  toaster.success(message, { duration: 2.5 });
}

export function initialValues(project, currentUserId) {
  const {
    accessTokens,
    emailToAddress,
    emailNotificationsEnabledUsers,
    organization: { users: allOrgUsers },
    viewCurrentProjectUserIds,
  } = project;

  const emailNotificationsEnabledUserIds = emailNotificationsEnabledUsers.map(
    ({ id }) => id
  );

  const users = allOrgUsers.map(({ id, fullName }) => {
    const hasProjectAccess = viewCurrentProjectUserIds.includes(id);
    const enabledForThisProject = emailNotificationsEnabledUserIds.includes(id);

    return {
      id,
      fullName,
      hasProjectAccess,
      enabledForThisProject,
    };
  });

  const sortedUsers = [...users].sort((userA, userB) => {
    // show the logged-in user at top of list regardless
    if (userA.id === currentUserId) return -1;
    if (userB.id === currentUserId) return 1;

    // users without project access go to the end of the list
    if (userA.hasProjectAccess && !userB.hasProjectAccess) return -1;
    if (userB.hasProjectAccess && !userA.hasProjectAccess) return 1;

    return 0;
  });

  return {
    emailToRabbetEnabled: !!emailToAddress,
    emailToAddress,
    emailToDomain: getEmailToDomain(),
    emailToEditablePortion: getEmailToEditablePortion(project),
    emailToPrefix: getEmailToPrefix(project),
    linkSharingEnabled: accessTokens.length > 0,
    users: sortedUsers,
  };
}

export function validateSharing({ emailToEditablePortion: email }) {
  const errors = {};

  if (isBlank(email)) {
    set(
      errors,
      "emailToEditablePortion",
      "Please complete the address for this project"
    );
  }

  const allowedChars = new RegExp(/^[A-Za-z0-9\-_]*$/);

  if (!allowedChars.test(email)) {
    set(
      errors,
      "emailToEditablePortion",
      "Sorry, only letters, numbers, dashes or underscores are allowed"
    );
  }
  return errors;
}

EditExternalUploadPanel.propTypes = {
  accessTokens: PropTypes.arrayOf(PropTypes.string).isRequired,
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  setPanelDirty: PropTypes.func,
  titleStyles: PropTypes.object,
};

import { Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Accordion,
  Button,
  Modal,
  Pane,
  Switch,
  Table,
  Text,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import { configurationPanelStyles } from "./helpers";

export const PANEL_KEY = "billCom";

const BILL_COM_VENDOR_SYNC = gql`
  mutation BillComVendorSync($organizationId: String!) {
    billComVendorSync(organizationId: $organizationId) {
      id
    }
  }
`;

const UPDATE_BILL_COM_AUTOMATIC_SYNC_ENABLED = gql`
  mutation UpdateBillComAutomaticSyncEnabled(
    $organizationId: String!
    $billComAutomaticSyncEnabled: Boolean!
  ) {
    updateBillComAutomaticSyncEnabled(
      organizationId: $organizationId
      billComAutomaticSyncEnabled: $billComAutomaticSyncEnabled
    ) {
      id
      billComAutomaticSyncEnabled
    }
  }
`;

export function BillCom({ organization, expandedPanelKeys, toggle }) {
  const open = !!expandedPanelKeys[PANEL_KEY];
  const [modalOpen, setModalOpen] = useState(false);
  const [
    billComAutomaticSyncEnabled,
    setBillComAutomaticSyncEnabled,
  ] = useState(organization.billComAutomaticSyncEnabled);

  const [updateBillComAutomaticSyncEnabled] = useMutation(
    UPDATE_BILL_COM_AUTOMATIC_SYNC_ENABLED
  );

  function handleSwitchOnChange() {
    setBillComAutomaticSyncEnabled(!billComAutomaticSyncEnabled);
    updateBillComAutomaticSyncEnabled({
      variables: {
        organizationId: organization.id,
        billComAutomaticSyncEnabled: !billComAutomaticSyncEnabled,
      },
    });
  }

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="Bill.com"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      {...configurationPanelStyles}
    >
      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
        <Switch
          onChange={handleSwitchOnChange}
          checked={billComAutomaticSyncEnabled}
          hasCheckIcon
        />
        <Text marginLeft={majorScale(2)} size={400}>
          Enable automatic vendor syncing with Bill.com.
        </Text>
      </Pane>
      <Pane marginTop={majorScale(1)}>
        <Button
          purpose="Opens modal to initiate vendor sync with Bill.com"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Sync Vendors
        </Button>
      </Pane>
      <VendorSyncStatusTable
        billComAuthorizations={organization.billComAuthorizations}
      />
      {modalOpen && (
        <PullVendorsFromBillComWarningModal
          organizationId={organization.id}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Accordion.Panel>
  );
}

function VendorSyncStatusTable({ billComAuthorizations }) {
  return (
    <Pane marginTop={majorScale(2)}>
      <Pane>Bill.com Organization Sync Status</Pane>
      {billComAuthorizations.length === 0 ? (
        <Text marginTop>
          There are no Bill.com Organizations to sync vendors with.
        </Text>
      ) : (
        <Pane marginTop={majorScale(1)}>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>
                  Bill.com Organization ID
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>
                  Vendors Last Synced At
                </Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {billComAuthorizations.map((authorization) => {
                return (
                  <Table.Row>
                    <Table.TextCell>
                      {authorization.externalProjectId}
                    </Table.TextCell>
                    <Table.TextCell>
                      {authorization.lastVendorSyncAt
                        ? formatDateTime(authorization.lastVendorSyncAt)
                        : "-"}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
      )}
    </Pane>
  );
}

function PullVendorsFromBillComWarningModal({ organizationId, onClose }) {
  const [
    billComVendorSync,
    { loading: billComVendorSyncLoading },
  ] = useMutation(BILL_COM_VENDOR_SYNC, {
    onCompleted: () => {
      onClose();
    },
  });

  function handleConfirm() {
    billComVendorSync({
      variables: {
        organizationId,
      },
    });
  }

  return (
    <Fragment>
      <Modal
        hasFooter
        onConfirm={handleConfirm}
        confirmLabel="Sync Vendors from Bill.com"
        isConfirmLoading={billComVendorSyncLoading}
        onClose={() => onClose()}
        open
        size="small"
        title="Sync Vendors from Bill.com"
      >
        <Modal.Content>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            After proceeding, please give a few minutes for your vendors to be
            added to the system.
          </Pane>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

import React from "react";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { Mention } from "./Mention";

export function getMentions(project) {
  return get(project, "mentions", []).filter(
    (mention) =>
      !!mention.comment.target &&
      mention.comment.target.__typename !== "Project"
  );
}

export function Mentions({ project, currentUserId }) {
  const history = useHistory();

  const mentions = getMentions(project);

  return mentions.map((mention) => (
    <React.Fragment key={mention.id}>
      <Mention
        history={history}
        key={mention.id}
        mention={mention}
        projectId={project.id}
        currentUserId={currentUserId}
      />
    </React.Fragment>
  ));
}

import { useContext, useMemo, Fragment } from "react";
import { get } from "lodash";
import { EditTableViews } from "components/containers";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  bulkSelectUtilityColumnDefaults,
} from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import {
  getColumns,
  getDefaultViews,
} from "components/templates/DocumentTable";

export function DocumentsTable({ onAdd, documents }) {
  const {
    hasPermission,
    hasOrgLevelPermission,
    organizationId,
    user,
  } = useContext(UserContext);

  const classifiedDocuments = useMemo(() => {
    return documents.filter((document) => !!document.type);
  }, [documents]);

  const defaultColumns = ["document"]
    .concat([
      "vendor",
      "currentAmountRequested",
      "uploadedAt",
      "pages",
      "originalFile",
      "status",
      "backup",
    ])
    .concat(
      hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) ? ["assignedTo"] : []
    )
    .concat(
      hasPermission(PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS)
        ? ["approval"]
        : []
    );

  const defaultGroup = { columnId: "type" };

  const noOp = () => {};

  const columns = useMemo(() => {
    return getColumns(
      hasOrgLevelPermission,
      hasPermission,
      true, // isDrawCard
      false, // isDocumentCard
      false, // isReport
      false, // isSubmission
      { params: {} }, // match
      [], // refetch
      noOp, // setIssueItemForModal
      {}, // documentReviewersByProject
      user,
      null // orgData
    );
  }, [hasOrgLevelPermission, hasPermission, user]);

  const defaultViewProps = [
    {
      filterConfig: [],
      name: "Default",
      isDefault: true,
    },
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.INVOICE}`)],
          key: "type",
        },
      ],
      name: "Invoices",
      isDefault: true,
    },
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.PAY_APPLICATION}`)],
          key: "type",
        },
      ],
      name: "Pay Applications",
      isDefault: true,
    },
  ];

  const defaultViews = getDefaultViews(
    defaultColumns,
    [], // defaultFilters
    defaultGroup,
    null, // defaultSort
    defaultViewProps,
    true // useFilterConfig
  );

  return (
    <Fragment>
      <EditTableViews
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName="Doot Doot"
      >
        {(propsEditTableViews) => (
          <FastDataTable
            serialized={get(propsEditTableViews, "views.0.config")}
            controls={(controlsProps) => (
              <FastDataTableAdvancedControls
                {...controlsProps}
                {...propsEditTableViews}
                bulkActionGroups={[
                  [{ label: "Add Documents", onClick: onAdd, disabled: false }],
                ]}
                searchPlaceholder="Search Documents..."
              />
            )}
            utilityColumns={[bulkSelectUtilityColumnDefaults]}
            columns={columns}
            items={classifiedDocuments}
          />
        )}
      </EditTableViews>
    </Fragment>
  );
}

import { useState, useEffect } from "react";
import {
  Button,
  GanttChart,
  Pane,
  Paragraph,
  Text,
  RadioGroup,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import "./TimelineGantt.css";

const VIEW_MODES = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
};

export function TimelineGantt({ project, tasks, hideChart, openSidebar }) {
  const [scale, setScale] = useState(VIEW_MODES.MONTH);
  const [showAllTasks, setShowAllTasks] = useState(false);

  useEffect(() => {
    /* eslint-disable no-new */
    new GanttChart("#gantt", tasks, {
      view_mode: scale,
      view_modes: [...Object.values(VIEW_MODES)],
      on_click: (task) => openSidebar(task.id),
      popup_trigger: null,
    });
  }, [tasks, scale, openSidebar]);

  const overflowProps = showAllTasks
    ? {}
    : { maxHeight: 295, overflowY: "auto" };

  return (
    <Pane
      display="flex"
      flexDirection="column"
      marginTop={majorScale(3)}
      width="95vw"
      marginX="auto"
    >
      <Header
        project={project}
        scale={scale}
        setScale={setScale}
        canShowAllTasks={tasks.length > 5}
        showAllTasks={showAllTasks}
        setShowAllTasks={setShowAllTasks}
        hideChart={hideChart}
      />
      <Pane {...overflowProps}>
        <svg id="gantt" />
      </Pane>
    </Pane>
  );
}

function Header({
  scale,
  setScale,
  canShowAllTasks,
  showAllTasks,
  setShowAllTasks,
  project,
  hideChart,
}) {
  return (
    <Pane display="flex" alignItems="center" marginBottom={minorScale(3)}>
      <Paragraph fontWeight={500} size={500} flexGrow={1}>
        {project.name} Timeline
      </Paragraph>
      <Text marginRight={majorScale(3)}>View by:</Text>
      <RadioGroup
        width={200}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        defaultValue={scale}
        options={Object.values(VIEW_MODES).map((view) => ({
          label: view,
          value: view,
        }))}
        onChange={(event) => setScale(event.target.value)}
      />
      {canShowAllTasks && (
        <Button
          onClick={() =>
            showAllTasks ? setShowAllTasks(false) : setShowAllTasks(true)
          }
          marginLeft={majorScale(2)}
        >
          {showAllTasks ? "Collapse" : "Show All Tasks"}
        </Button>
      )}
      <Button onClick={hideChart} marginLeft={majorScale(2)}>
        Hide Gantt
      </Button>
    </Pane>
  );
}

import {
  DownloadIcon,
  EditIcon,
  SendMessageIcon,
  CubeAddIcon,
  Table,
  majorScale,
  IconProps,
  Heading,
  Pane,
} from "evergreen-ui";

export interface Props {}

type ActionType = "download" | "edit" | "send";

const typeToIcon = {
  download: DownloadIcon,
  edit: EditIcon,
  send: SendMessageIcon,
};

export function actionIcon(type: string) {
  return typeToIcon[type as ActionType] ?? CubeAddIcon;
}

interface ActionIconProps extends IconProps {
  type: string;
}

export function ActionIcon({ type, ...iconProps }: ActionIconProps) {
  const Icon = actionIcon(type);
  return <Icon {...iconProps} />;
}

export function DrawPackageHistory(_: Props) {
  const history = [
    {
      id: 1,
      date: "2021-09-01",
      actionType: "download",
      description: "Pip Keast downloaded version 6",
      action: "download",
    },
    {
      id: 2,
      date: "2023-09-01",
      actionType: "edit",
      description: "Pip Keast edited the draw package",
    },
    {
      id: 3,
      date: "2023-09-01",
      actionType: "send",
      description: "Pip Keast sent the draw package to 3 recipients",
      action: "download",
    },
  ];
  return (
    <Pane marginTop={majorScale(4)}>
      <Heading>History</Heading>
      <Table marginTop={majorScale(2)}>
        <Table.Head>
          <Table.TextHeaderCell flexGrow={1}>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell flexGrow={2}>Description</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {history.map((action) => (
            <Table.Row
              key={action.id}
              isSelectable
              // eslint-disable-next-line no-alert
              onSelect={() => alert(action.action)}
            >
              <Table.TextCell flexGrow={1}>{action.date}</Table.TextCell>
              <Table.TextCell flexGrow={2}>
                <ActionIcon
                  type={action.actionType}
                  marginRight={majorScale(1)}
                />
                {action.description}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Pane>
  );
}

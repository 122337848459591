import { useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { TickIcon } from "evergreen-ui";
import { Button, Confirm, Pane, Paragraph } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { REVIEW_STATE } from "helpers/enums";
import analytics, { drawValues } from "helpers/analytics";
import { majorScale, toaster } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import { APPROVE_DRAW } from "./graphql";

export function ApproveDraw({ draw, reviews }) {
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [showConfirmModalGeneral, setShowConfirmModalGeneral] = useState(false);
  const { userId } = useContext(UserContext);

  const currentUserReview = reviews.find(
    (review) => review.reviewer.id === userId
  );
  const userReviewIsPending = currentUserReview?.state === REVIEW_STATE.PENDING;
  const userHasApproved = currentUserReview?.state === REVIEW_STATE.APPROVED;
  const userIsPreparer = !!currentUserReview?.isPreparer;
  const preparerReview = reviews.find((review) => review.isPreparer);
  const preparerHasApproved = preparerReview?.state === REVIEW_STATE.APPROVED;

  const [approveDraw, { loading }] = useMutation(APPROVE_DRAW, {
    variables: { drawId: draw.id },
    onCompleted: () => {
      analytics.track("Draw Approved", {
        ...drawValues(draw),
        signatory: !!currentUserReview.isFinal,
        preparer: !!currentUserReview.isPreparer,
      });
      toaster.success(
        `Nice work, ${draw.name} for ${formatCurrency(
          draw.requestedAmount
        )} has been approved.`,
        {
          duration: 2.5,
        }
      );
    },
  });

  const sharedButtonProps = {
    marginRight: majorScale(1),
    disabled: loading,
    isLoading: loading,
  };

  if (userHasApproved)
    return (
      <Button disabled iconAfter={TickIcon} marginRight={majorScale(1)}>
        {userIsPreparer ? "Prepared" : "Approved"}
      </Button>
    );

  if (!userReviewIsPending) return null;
  // if the user is not the preparer, and there is a preparer that has not yet approved,
  // we allow them to approve the draw before the preparer has marked it ready,
  // but we will make them confirm
  if (!userIsPreparer && !!preparerReview && !preparerHasApproved) {
    return (
      <Fragment>
        <Button
          {...sharedButtonProps}
          intent="warning"
          onClick={() => setShowConfirmApprove(true)}
          purpose="draw-actions approve"
        >
          Approve Draw
        </Button>
        {showConfirmApprove && (
          <Confirm
            open
            content={
              <ConfirmApproveModalBody preparerReview={preparerReview} />
            }
            header="Draw Has Not Been Marked Ready for Review"
            onCloseComplete={() => setShowConfirmApprove(false)}
            onConfirm={(close) => {
              approveDraw();
              close();
            }}
            cancelLabel="Cancel"
            confirmLabel="Approve Anyway"
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button
        {...sharedButtonProps}
        intent="success"
        onClick={() => setShowConfirmModalGeneral(true)}
        purpose="draw-actions approve"
      >
        {userIsPreparer ? "Ready for Review" : "Approve Draw"}
      </Button>
      {showConfirmModalGeneral && (
        <Confirm
          open
          content={
            <Paragraph marginBottom={majorScale(2)}>
              Are you sure you want to approve this draw?
            </Paragraph>
          }
          header={`Approve Draw for ${formatCurrency(draw.requestedAmount)}`}
          onCloseComplete={() => setShowConfirmModalGeneral(false)}
          onConfirm={(close) => {
            approveDraw();
            close();
          }}
          cancelLabel="Cancel"
          confirmLabel="Approve"
        />
      )}
    </Fragment>
  );
}

function ConfirmApproveModalBody({ preparerReview }) {
  return (
    <Pane>
      <Paragraph marginBottom={majorScale(2)}>
        {t("confirmApproveDraw.notReady")}
      </Paragraph>
      <Paragraph marginBottom={majorScale(2)}>
        {preparerReview.reviewer.fullName}, Preparer
      </Paragraph>
      <Paragraph>{t("confirmApproveDraw.resolutionGuidance")}</Paragraph>
    </Pane>
  );
}

ApproveDraw.propTypes = {
  draw: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      reviewer: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

import { ListComments } from "components/templates";
import { ReviewCard } from "./ReviewCard";

export function Comments({ comments }) {
  return (
    <ReviewCard title={`Internal Comments (${comments.length})`}>
      <ListComments showEmptyMessage showTarget comments={comments} />
    </ReviewCard>
  );
}

import PropTypes from "prop-types";
import { SelectionPill } from "components/materials";
import { isEqual } from "lodash";
import {
  columnShape,
  groupShape,
} from "components/materials/FastDataTable/FastDataTableUtils";

export const CurrentFilters = ({
  filterConfig,
  dataColumns: columns,
  filterBy,
}) => {
  return filterConfig.map((config) => {
    if (config.__isSearch) return null;

    const column = columns.find((column) => column.id === config.key);

    if (!column) return null;

    return (
      <SelectionPill
        key={config.key}
        onClick={() =>
          filterBy(filterConfig.filter((c) => !isEqual(config, c)))
        }
        icon="filter"
        text={column.filterFormatter(config, column)}
        color="blue50"
      />
    );
  });
};

CurrentFilters.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  filterBy: PropTypes.func.isRequired,
  filterConfig: PropTypes.arrayOf(groupShape),
};

import { useContext, useMemo } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { SmallTickIcon, TickCircleIcon } from "evergreen-ui";
import { EditTableViews } from "components/containers";
import { Button, LinkButton } from "components/materials";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  booleanColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import { camelCase, get, toUpper, values } from "lodash";
import { UserContext } from "helpers/behaviors";
import { majorScale, minorScale } from "helpers/utilities";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { PERMISSION_ACTION } from "helpers/enums";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import t from "helpers/translate";

const RESEND_INVITATION = gql`
  mutation resendInvitation($userId: String!) {
    resendInvitation(userId: $userId) {
      id
    }
  }
`;

function Resend({ userId }) {
  const [resendInvitation, { called, loading }] = useMutation(
    RESEND_INVITATION,
    { variables: { userId } }
  );

  return (
    <Button
      content={called ? "Sent" : "Resend Invitation"}
      disabled={called || loading}
      height={majorScale(3)}
      isLoading={loading}
      purpose="user-list invitation resend"
      onClick={(event) => {
        preventEventBubbling(event);
        resendInvitation();
      }}
    />
  );
}

const defaultViews = [
  {
    config: toBase64({
      columnConfig: ["userName", "emailAddress", "finishedUserRegistration"],
      filterConfig: [],
      groupConfig: {},
      sortConfig: {},
    }),
    isDefault: true,
    name: "Default",
  },
];

const getColumns = ({ hasManagedTeams, hasSso, userAssignablePermissions }) => [
  {
    ...stringColumnDefaults,
    ...primaryColumnDefaults,
    header: "Name",
    id: "userName",
    value: (user) => user.fullName,
  },
  {
    ...stringColumnDefaults,
    header: "Email",
    id: "emailAddress",
    value: (user) => user.email,
    textProps: { wordBreak: "break-all" },
    width: 400,
  },
  {
    ...booleanColumnDefaults,
    header: "Finished Registration",
    id: "finishedUserRegistration",
    textAlign: "center",
    value: (user) => user.registrationComplete,
    valueFormatter: (value, user, { isGroupedValue }) => {
      if (isGroupedValue) {
        return value === "true"
          ? "Registration Finished"
          : "Registration Incomplete";
      }
      return value ? (
        <TickCircleIcon color="success" />
      ) : (
        <Resend userId={user.id} />
      );
    },
    width: 150,
  },
  {
    ...stringColumnDefaults,
    header: "SSO Role",
    hidden: !hasSso,
    id: "ssoRole",
    value: (user) => user.ssoRole,
  },
  {
    ...stringColumnDefaults,
    header: "Team Project Access",
    hidden: !hasManagedTeams,
    id: "teamProjectAccess",
    value: (user) => {
      if (user.permissionConfig.viewAllProjects) return "All Projects";

      return user.teams.map((team) => team.name).join(", ");
    },
  },
  ...values(PERMISSION_ACTION).reduce((permissionColumns, action) => {
    if (
      userAssignablePermissions.some(
        (permission) => toUpper(permission) === action
      )
    ) {
      return [
        ...permissionColumns,
        {
          ...booleanColumnDefaults,
          header: t(`userPermissions.${action}`),
          id: camelCase(action),
          textAlign: "center",
          value: (user) => !!user.permissionConfig[camelCase(action)],
          valueFormatter: (value, item, { isGroupedValue }) => {
            if (isGroupedValue) {
              return value === "true"
                ? `Has "${t(`userPermissions.${action}`)}" Permission`
                : `Does Not Have "${t(
                    `userPermissions.${action}`
                  )}" Permission`;
            }
            return value && <SmallTickIcon />;
          },
          width: 140,
        },
      ];
    }
    return permissionColumns;
  }, []),
];

function NewUserButton({ hasManageUsers }) {
  return hasManageUsers ? (
    <LinkButton
      appearance="primary"
      content="New User"
      to="/admin/users/new"
      marginLeft={minorScale(3)}
    />
  ) : null;
}

function getControls(propsControls, propsEditTableViews, hasManageUsers) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      searchPlaceholder="Search Users..."
      rightControls={(defaultRightControls) => [
        defaultRightControls,
        <NewUserButton hasManageUsers={hasManageUsers} />,
      ]}
    />
  );
}

export function UserTable({
  hasSso,
  history,
  onClickRow,
  selectedOrganization,
  userAssignablePermissions,
  users,
}) {
  const { hasPermission } = useContext(UserContext);
  const hasManageUsers = hasPermission(
    PERMISSION_ACTION.MANAGE_USER,
    selectedOrganization
  );

  const hasManagedTeams = hasPermission(
    PERMISSION_ACTION.TEAM_MANAGEMENT,
    selectedOrganization
  );

  const columns = useMemo(
    () => getColumns({ hasManagedTeams, hasSso, userAssignablePermissions }),
    [hasManagedTeams, hasSso, userAssignablePermissions]
  );

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        selectedOrganization
      )}
      config={getSearchByKey(history, "table")}
      organizationIdToScopeViews={selectedOrganization.id}
      defaultViews={defaultViews}
      tableName="UserList"
    >
      {(propsEditTableViews) => (
        <FastDataTable
          columns={columns}
          controls={(propsControls) =>
            getControls(propsControls, propsEditTableViews, hasManageUsers)
          }
          items={users}
          onClickRow={onClickRow}
          onSerialize={(table) => mergeSearch(history, { table })}
          serialized={
            getSearchByKey(history, "table") ||
            get(propsEditTableViews, "views.0.config")
          }
        />
      )}
    </EditTableViews>
  );
}

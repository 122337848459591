import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ContentLayout } from "components/templates";
import { Button, Loadable, Pane, Paragraph, Table } from "components/materials";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";
import analytics from "helpers/analytics";
import { FUNDING_SOURCE_TYPE } from "helpers/enums";
import { ImportDrawSubmissionSimpleHeader } from "./ImportDrawSubmissionHeader";

const RECEIVE_DRAW = gql`
  mutation ImportPreviewReceiveDraw(
    $drawName: String
    $projectId: String!
    $submissionId: String!
    $overwriteFundingSources: Boolean
  ) {
    receiveDraw(
      drawName: $drawName
      projectId: $projectId
      submissionId: $submissionId
      overwriteFundingSources: $overwriteFundingSources
    ) {
      id
    }
  }
`;

const QUERY = gql`
  query ImportDrawFundingSourcesDecisionQuery(
    $submissionId: String!
    $targetProjectId: String!
  ) {
    submission(submissionId: $submissionId) {
      id
      submitter {
        id
        name
      }
      importPreview(targetProjectId: $targetProjectId) {
        id
        unmatchedSourceFundingSources {
          id
        }
        unmatchedTargetFundingSources {
          id
        }
      }
      fundingSources {
        id
        amount
        equityType
        label
        organizationName
      }
    }
  }
`;

function getSourceLabel({ amount, equityType, label, organizationName }) {
  const name = label || organizationName;
  const formattedAmount = formatCurrency(amount);
  const type = t(`fundingSourceType.${FUNDING_SOURCE_TYPE[equityType]}`);

  return `${name} - ${formattedAmount} - ${type}`;
}

export function ImportDrawSubmissionFundingSourcesDecision({
  submissionId,
  variables: { drawName, projectId },
}) {
  const [importDrawMutation, { called: mutationCalled }] = useMutation(
    RECEIVE_DRAW,
    {
      onCompleted: ({ receiveDraw }) => {
        analytics.track("Draw Imported", { projectId, drawId: receiveDraw.id });
        // Use window.location rather than history.push to ensure a page refresh
        // so that AppLayout's projects can refresh
        window.location.assign(
          `/projects/${projectId}/draws/${receiveDraw.id}`
        );
      },
    }
  );

  const defaultImportVariables = {
    drawName,
    projectId,
    submissionId,
  };

  const { data, loading: queryLoading } = useQuery(QUERY, {
    variables: { submissionId, targetProjectId: projectId },
    onCompleted: ({ submission }) => {
      const unmatchedSourceFundingSources = get(
        submission,
        "importPreview.unmatchedSourceFundingSources",
        []
      );
      const unmatchedTargetFundingSources = get(
        submission,
        "importPreview.unmatchedTargetFundingSources",
        []
      );

      if (
        unmatchedTargetFundingSources.length === 0 &&
        unmatchedSourceFundingSources.length === 0
      ) {
        importDrawMutation({ variables: defaultImportVariables });
      }
    },
  });

  if (queryLoading || mutationCalled) {
    return <Loadable loading />;
  }

  const { submission } = data;
  const preview = get(submission, "importPreview", {});
  const conflictsPresent =
    preview.unmatchedSourceFundingSources.length > 0 ||
    preview.unmatchedTargetFundingSources.length > 0;

  // The query returns no conflicts and the mutation has been triggered
  if (!conflictsPresent) {
    return <Loadable loading />;
  }

  const senderName = get(submission, "submitter.name");
  const sourceDrawSources = get(submission, "fundingSources");

  return (
    <ContentLayout fixedWidth={700}>
      <ImportDrawSubmissionSimpleHeader
        title={t("import.sourcesConflictTitle")}
      />
      <Paragraph marginBottom={majorScale(3)}>
        {t("import.sourcesConflictCopy")}
      </Paragraph>
      <Pane paddingX={majorScale(3)}>
        <Table paddingX={majorScale(2)}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell>
                {`${senderName} Funding Sources`}
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {sourceDrawSources.map((source) => (
              <Table.Row key={source.id}>
                <Table.TextCell>{getSourceLabel(source)}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
      <Pane marginY={majorScale(1)} textAlign="right">
        <Button
          isLoading={mutationCalled}
          marginRight={majorScale(2)}
          purpose="import-draw funding-sources keep-existing"
          onClick={() => {
            importDrawMutation({ variables: defaultImportVariables });
          }}
        >
          Keep My Funding Sources
        </Button>
        <Button
          appearance="primary"
          isLoading={mutationCalled}
          purpose="import-draw funding-sources overwrite"
          onClick={() =>
            importDrawMutation({
              variables: {
                ...defaultImportVariables,
                overwriteFundingSources: true,
              },
            })
          }
        >
          Import Sender Funding Sources
        </Button>
      </Pane>
    </ContentLayout>
  );
}

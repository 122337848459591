import { useMutation } from "@apollo/react-hooks";
import { DrawRules } from "components/templates";
import { enabledRule } from "helpers/ruleHelpers";
import { COMMENT, COMMENT_QUERY, TRANSITION } from "./graphql-queries";

export function Rules({
  drawId,
  history,
  modalRuleId,
  projectId,
  rules,
  showAllRules,
  users,
}) {
  const openRuleModal = (openRuleId) => {
    history.push(`${history.location.pathname}/${openRuleId}`);
  };

  const closeRuleModal = () => {
    history.replace({
      pathname: history.location.pathname.replace(`/${modalRuleId}`, ""),
      search: history.location.search,
    });
  };
  const [transition, transitionResult] = useMutation(TRANSITION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: COMMENT_QUERY, variables: { projectId, drawId } },
    ],
    onCompleted: closeRuleModal,
  });

  const [comment, commentResult] = useMutation(COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: COMMENT_QUERY, variables: { projectId, drawId } },
    ],
    onCompleted: closeRuleModal,
  });

  const enabledRules = rules.filter(enabledRule);

  return (
    <DrawRules
      closeRuleModal={closeRuleModal}
      comment={comment}
      commentResult={commentResult}
      drawId={drawId}
      modalRuleId={modalRuleId}
      openRuleModal={openRuleModal}
      projectId={projectId}
      rules={enabledRules}
      showAllRules={showAllRules}
      transition={transition}
      transitionResult={transitionResult}
      users={users}
    />
  );
}

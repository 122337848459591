import { useEffect, Fragment, useReducer, useContext } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { get, uniqBy } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import { Document, ScopeOrganizations } from "components/containers";
import { PortfolioDashboard } from "components/templates";
import { Loadable } from "components/materials";
import { UserContext, UserContextProvider } from "helpers/behaviors";
import {
  CARDS_QUERY,
  ORGANIZATION_QUERY,
  TABLE_QUERY,
  TODO_QUERY,
  UPDATE_USER_VIEW_CONFIG,
} from "./queries";
import { initializeViewConfig, dispatch } from "./tableConfig/reducer";

export function PortfolioPage({ history, match }) {
  const { documentId, projectId } = match.params;
  const { userProfiles, hasPermission } = useContext(UserContext);
  const tileViewTurnedOff = hasPermission(PERMISSION_ACTION.TURN_OFF_TILE_VIEW);
  const [viewConfig, dispatchViewConfig] = useReducer(
    dispatch,
    { ...userProfiles[0], tileViewTurnedOff },
    initializeViewConfig
  );

  const { data: orgData, loading: orgDataLoading } = useQuery(
    ORGANIZATION_QUERY
  );
  const { data: todoData, loading: todoLoading } = useQuery(TODO_QUERY);
  const [
    getCardsQuery,
    { data: cardsData, loading: cardsLoading },
  ] = useLazyQuery(CARDS_QUERY);
  const [
    getTableQuery,
    { data: tableData, loading: tableLoading, called: tableCalled },
  ] = useLazyQuery(TABLE_QUERY);

  const [updateUserViewConfig] = useMutation(UPDATE_USER_VIEW_CONFIG);
  const loading =
    (!viewConfig.configLoaded && !viewConfig.showCards) ||
    cardsLoading ||
    tableLoading ||
    orgDataLoading ||
    (viewConfig.showTodos && todoLoading);

  useEffect(() => {
    if (viewConfig.configLoaded) {
      updateUserViewConfig({
        variables: {
          showDashboardCards: viewConfig.showCards,
          showDashboardTodos: viewConfig.showTodos,
        },
      });
    }
  }, [
    viewConfig.showCards,
    viewConfig.showTodos,
    viewConfig.configLoaded,
    updateUserViewConfig,
  ]);

  useEffect(() => {
    if (viewConfig.configLoaded || viewConfig.showCards) {
      const projectsQuery = viewConfig.showCards
        ? getCardsQuery
        : getTableQuery;
      projectsQuery({ variables: viewConfig.tableConfig });
    }
  }, [
    viewConfig.showCards,
    viewConfig.configLoaded,
    viewConfig.tableConfig,
    getCardsQuery,
    getTableQuery,
  ]);

  useEffect(() => {
    if (tableLoading) {
      dispatchViewConfig({ type: "TABLE_LOADING" });
    }

    if (tableCalled && !tableLoading) {
      dispatchViewConfig({ type: "TABLE_LOADED" });
    }
  }, [tableLoading, tableCalled]);

  const projectData = viewConfig.showCards ? cardsData : tableData;
  const projects = get(projectData, "projects", []);
  const todos = get(todoData, "userProfiles", []).reduce(
    ({ drawApprovals, mentions, documents }, profile) => {
      const uniqueDocumentsForProfile = uniqBy(
        [...profile.documents, ...profile.documentsPendingApprovalForUser],
        "id"
      );
      return {
        drawApprovals: [
          ...drawApprovals,
          ...get(profile, "pendingDrawApprovals", []),
        ],
        documents: [...documents, ...uniqueDocumentsForProfile],
        mentions: [...mentions, ...get(profile, "mentions", [])],
      };
    },
    { mentions: [], documents: [], drawApprovals: [] }
  );

  return (
    <Fragment>
      <Loadable loading={loading} />
      <ScopeOrganizations>
        {(scopeOrganizationsProps) => (
          <PortfolioDashboard
            dispatchViewConfig={dispatchViewConfig}
            history={history}
            orgData={orgData}
            projects={projects}
            scopeOrganizationsProps={scopeOrganizationsProps}
            todos={todos}
            viewConfig={viewConfig}
          />
        )}
      </ScopeOrganizations>
      {documentId && (
        <UserContextProvider projectId={projectId}>
          <Document
            documents={todos.documents}
            history={history}
            match={match}
            refetch={[
              {
                query: viewConfig.showCards ? CARDS_QUERY : TABLE_QUERY,
              },
            ]}
          />
        </UserContextProvider>
      )}
    </Fragment>
  );
}

import { Fragment, useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { UserContext } from "helpers/behaviors";
import { Form, Modal, Text } from "components/materials";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import {
  dateFormToServer,
  dateServerToForm,
  isAtOrBefore,
  isBefore,
} from "helpers/dateHelpers";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import analytics from "helpers/analytics";
import { UPDATE_DRAW_STATE, FUND_DRAW } from "./graphql";

const DRAW_STATE_OPTIONS = [
  DRAW_STATE.STARTED,
  DRAW_STATE.PAUSED,
  DRAW_STATE.WITHDRAWN,
  DRAW_STATE.REJECTED,
  DRAW_STATE.FUNDED,
];

export function EditDrawStateDropdown({ draw, projectId }) {
  const { hasPermission } = useContext(UserContext);
  const userCanEditDrawStatus = hasPermission(
    PERMISSION_ACTION.EDIT_DRAW_STATUS
  );
  const [updateState, setUpdateState] = useState(null);

  const [updateDrawState, { loading: updateDrawStateLoading }] = useMutation(
    UPDATE_DRAW_STATE,
    {
      onCompleted: () => {
        analytics.track("Draw State Changed", {
          projectId,
          drawId: draw.id,
        });
        setUpdateState(null);
      },
    }
  );
  const [fundDraw, { loading: fundDrawLoading }] = useMutation(FUND_DRAW, {
    onCompleted: () => {
      analytics.track("Draw Marked Funded", {
        projectId,
        drawId: draw.id,
      });
      setUpdateState(null);
    },
  });

  function handleSubmit(values) {
    if (updateState === DRAW_STATE.FUNDED) {
      fundDraw({
        variables: {
          drawId: draw.id,
          fundedDate: dateFormToServer(values.date),
        },
      });
    } else {
      updateDrawState({
        variables: {
          drawId: draw.id,
          state: updateState,
          date: dateFormToServer(values.date),
        },
      });
    }
  }

  function handleStateChange(newState) {
    if (newState !== draw.state) {
      setUpdateState(newState);
    }
  }

  return (
    <Fragment>
      <Form.Select
        backgroundColor="#ffd081"
        disabled={!userCanEditDrawStatus}
        value={draw.state}
        options={getDrawStateOptions(draw)}
        onChange={handleStateChange}
        width={125}
        marginTop={-10}
        outerProps={{
          width: 125,
          marginRight: majorScale(1),
        }}
        textProps={{
          textAlign: "center",
          fontWeight: 400,
          fontSize: 12,
        }}
      />
      {updateState && (
        <Formik
          initialValues={getInitialValues(draw)}
          onSubmit={handleSubmit}
          validate={(values) => validateDate(values, draw)}
        >
          {(formikProps) => {
            return (
              <Form>
                <Modal
                  hasFooter
                  onConfirm={formikProps.handleSubmit}
                  isConfirmLoading={updateDrawStateLoading || fundDrawLoading}
                  onClose={() => {
                    setUpdateState(null);
                  }}
                  open
                  size="small"
                  title={`Draw ${t(`drawStates.${updateState}`)} On`}
                >
                  <Modal.Content>
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      marginBottom={majorScale(1)}
                    >
                      {`${t(`drawStates.${updateState}`)} on`}
                    </Text>
                    <Form.DateInput
                      name="date"
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true,
                      }}
                    />
                  </Modal.Content>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
}

function getInitialValues(draw) {
  return {
    date: dateServerToForm(draw?.recentStateUpdate?.date || Date.now()),
  };
}

function validateDate(values, draw) {
  const errors = {};
  const previousStateDate = draw?.recentStateUpdate?.date;

  if (isBlank(values.date)) errors.date = "Date must be selected";

  if (
    previousStateDate &&
    isBefore(new Date(values.date), new Date(previousStateDate))
  ) {
    errors.date = `Selected date must be on or after the last draw status change on ${dateServerToForm(
      previousStateDate
    )}`;
  }

  if (!isAtOrBefore(new Date(values.date), Date.now())) {
    errors.date = `Selected date must be on or before today's date`;
  }

  return errors;
}

function getDrawStateOptions(draw) {
  // there are a handful of legacy states related to the deprecated draw submission flow that we do not provide as options in the dropdown
  const legacyDrawState = DRAW_STATE_OPTIONS.includes(draw.state)
    ? []
    : [draw.state];

  return legacyDrawState.concat(DRAW_STATE_OPTIONS).map((state) => ({
    key: state,
    value: state,
    text: t(`drawStates.${state}`),
  }));
}

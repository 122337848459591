import { isEqual } from "lodash";
import { fields } from "./fields";
import { filters } from "./filters";

export function initializeViewConfig(userProfile) {
  const {
    showDashboardCards: userPrefShowCards,
    showDashboardTodos: showTodos,
    tileViewTurnedOff,
  } = userProfile;

  const showCards = !tileViewTurnedOff && userPrefShowCards;

  return {
    showTable: !showCards,
    showCards,
    showTodos,
    hideViewToggle: false,
    configLoaded: showCards,
    tableLoading: false,
  };
}

export function dispatch(state, action) {
  let newState = state;
  switch (action.type) {
    case "SHOW_CARDS":
      return {
        ...state,
        showCards: true,
        showTable: false,
        configLoaded: true,
      };
    case "SHOW_TABLE":
      return { ...state, showCards: false, showTable: true };
    case "SHOW_TODOS":
      return { ...state, showTodos: true };
    case "HIDE_TODOS":
      return { ...state, showTodos: false };
    case "LOAD_CONFIG":
      return { ...state, configLoaded: true };
    case "TABLE_LOADING":
      return { ...state, tableLoading: true };
    case "TABLE_LOADED":
      return { ...state, tableLoading: false };
    case "UPDATE_TABLE":
      newState = {
        ...state,
        configLoaded: true,
        tableConfig: mapConfigToParams(action.value),
      };
      // If the state hasn't changed, return the original state to avoid the table re-requesting data when the config hasn't changed
      if (isEqual(newState, state)) {
        return state;
      }
      return newState;
    default:
      return state;
  }
}

function mapConfigToParams(config) {
  return {
    ...filters(config),
    ...fields(config),
  };
}

import { useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { WarningSignIcon } from "evergreen-ui";
import {
  Button,
  Form,
  Link,
  Modal,
  Pane,
  Paragraph,
  SelectMenu,
  Sidebar,
  Tooltip,
} from "components/materials";
import { TASK_STATUS } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import {
  addYears,
  formatTrimDate,
  dateFormToServer,
  dateServerToForm,
  isBefore,
} from "helpers/dateHelpers";
import t from "helpers/translate";
import { formatNumber } from "helpers/formatNumber";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { orderBy, set } from "lodash";
import isBlank from "helpers/isBlank";
import { parseCurrencyToFloat } from "helpers/parseCurrencyToFloat";
import { SAVE_TASK } from "./graphql";

export function TaskDetails({
  task,
  users,
  projectId,
  onTaskCreated,
  isNewTask,
  timelineRefetch,
}) {
  const [statusUpdate, setStatusUpdate] = useState(null);

  const [saveTaskMutation, { loading: mutationLoading }] = useMutation(
    SAVE_TASK,
    {
      awaitRefetchQueries: true,
      refetchQueries: timelineRefetch,
      onCompleted: (response) => {
        if (isNewTask) {
          onTaskCreated(response.saveTask.id);
        }
      },
    }
  );

  function saveTask(values, { resetForm }) {
    const variables = prepareMutationArguments(values, projectId);

    saveTaskMutation({ variables }).then(({ data }) => {
      // reset the form rather than use `enableReinitialize` to avoid an empty form flashing after saving a new task
      resetForm({ values: getInitialValues(data.saveTask) });
    });
  }

  const ownerOptions = orderBy(users, ["fullName"], ["asc"])
    .map(({ id, fullName }) => ({ label: fullName, value: id }))
    .concat({ label: <em>Assign to no-one</em>, value: null });

  return (
    <Formik
      initialValues={task ? getInitialValues(task) : generateNewTask()}
      onSubmit={saveTask}
      validate={(values) => validate(values, task)}
      enableReinitialize
    >
      {(formik) => {
        const selectedUser = users.find(
          ({ id }) => id === formik.values.userId
        );
        const currentStatus = formik.values.status;

        return (
          <Fragment>
            <Sidebar.Heading
              breadcrumbs={task ? ["Edit Task", task.eventName] : ["New Task"]}
            >
              {formik.dirty && (
                <Pane display="flex">
                  <Pane>
                    <Button
                      onClick={formik.handleReset}
                      marginRight={majorScale(2)}
                      disabled={mutationLoading}
                    >
                      Undo
                    </Button>
                  </Pane>
                  <Pane>
                    <Form.SubmitButton
                      onClick={(e) => {
                        preventEventBubbling(e);
                        formik.handleSubmit();
                      }}
                      isLoading={mutationLoading}
                      label="Save"
                    />
                  </Pane>
                </Pane>
              )}
            </Sidebar.Heading>
            <Sidebar.Section paddingX={majorScale(3)}>
              <Pane display="flex" alignItems="center">
                <Pane width={400} marginRight={majorScale(4)}>
                  <Form.Input name="eventName" label="Name" />
                </Pane>
                <Pane>
                  <Form.Select
                    name="status"
                    options={getStatusOptions()}
                    label="Status"
                    width={160}
                    onChange={(newStatus) =>
                      setStatusUpdate([currentStatus, newStatus])
                    }
                  />
                </Pane>
              </Pane>
              <Pane display="flex" marginTop={majorScale(2)}>
                <Pane width={250}>
                  <Form.DateInput
                    label="Original Start Date"
                    name="originalStartDate"
                    popperPlacement="bottom-end"
                    popperProps={{
                      positionFixed: true,
                    }}
                    width={140}
                  />
                </Pane>
                {formik.values.status === TASK_STATUS.NOT_STARTED && (
                  <Pane>
                    <Paragraph marginBottom={minorScale(1)}>
                      Projected Start Date
                    </Paragraph>
                    {task?.predecessor ? (
                      <Tooltip
                        content={`The Projected Start Date has been calculated according to the configured dependency on "${task.predecessor.parent.eventName}"`}
                      >
                        <Paragraph>
                          {formatTrimDate(task.projectedStartDate)}
                        </Paragraph>
                      </Tooltip>
                    ) : (
                      <Form.DateInput
                        name="projectedStartDate"
                        popperPlacement="bottom-end"
                        popperProps={{
                          positionFixed: true,
                        }}
                        width={140}
                      />
                    )}
                  </Pane>
                )}
                {formik.values.status !== TASK_STATUS.NOT_STARTED && (
                  <Pane>
                    <Paragraph marginBottom={minorScale(1)}>
                      Actual Start Date
                    </Paragraph>

                    <Form.DateInput
                      name="actualStartDate"
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true,
                      }}
                      width={140}
                    />
                  </Pane>
                )}
              </Pane>
              <Pane display="flex" marginTop={majorScale(2)}>
                <Pane width={250}>
                  <Paragraph marginBottom={minorScale(1)}>
                    Original Duration
                  </Paragraph>
                  <Pane display="flex" alignItems="center">
                    <Pane width={100}>
                      <Form.Input
                        name="originalDuration"
                        type="integer"
                        maxWidth={100}
                      />
                    </Pane>
                    <Paragraph marginLeft={majorScale(1)}>days</Paragraph>
                  </Pane>
                </Pane>
                {formik.values.status === TASK_STATUS.COMPLETE && (
                  <Pane>
                    <Paragraph marginBottom={10}>Actual Duration</Paragraph>
                    {shouldShowActualDuration(
                      formik.values,
                      formik.initialValues
                    ) && task?.actualDuration !== null ? (
                      <Paragraph>
                        {`${formatNumber(task?.actualDuration)} days`}
                      </Paragraph>
                    ) : (
                      <Paragraph color="muted" fontStyle="italic">
                        will update on save
                      </Paragraph>
                    )}
                  </Pane>
                )}
              </Pane>
              <Pane display="flex" marginTop={majorScale(2)}>
                <Pane width={250}>
                  <Paragraph marginBottom={10}>
                    Original Completion Date
                  </Paragraph>
                  {task &&
                  shouldShowOriginalCompletionDate(
                    formik.values,
                    formik.initialValues
                  ) ? (
                    <Paragraph>
                      {formatTrimDate(task?.originalCompletionDate) || "-"}
                    </Paragraph>
                  ) : (
                    <Paragraph color="muted" fontStyle="italic">
                      will update on save
                    </Paragraph>
                  )}
                </Pane>
                {formik.values.status === TASK_STATUS.COMPLETE && (
                  <Pane>
                    <Paragraph marginBottom={minorScale(1)}>
                      Actual Completion Date
                    </Paragraph>
                    <Form.DateInput
                      name="actualCompletionDate"
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true,
                      }}
                      width={140}
                    />
                  </Pane>
                )}
                {formik.values.status !== TASK_STATUS.COMPLETE && (
                  <Pane>
                    <Paragraph marginBottom={10}>
                      Projected Completion Date
                    </Paragraph>
                    {task &&
                    shouldShowProjectedCompletionDate(
                      formik.values,
                      formik.initialValues
                    ) ? (
                      <Paragraph>
                        {formatTrimDate(task?.projectedCompletionDate) || "-"}
                        {task?.isOverdue && (
                          <Tooltip content="This task is overdue">
                            <WarningSignIcon
                              color="danger"
                              size="12"
                              marginLeft={majorScale(1)}
                            />
                          </Tooltip>
                        )}
                      </Paragraph>
                    ) : (
                      <Paragraph color="muted" fontStyle="italic">
                        will update on save
                      </Paragraph>
                    )}
                  </Pane>
                )}
              </Pane>
              <Pane
                display="flex"
                alignItems="center"
                marginTop={majorScale(3)}
              >
                <Paragraph minWidth={100}>Owner</Paragraph>
                <SelectMenu
                  closeOnSelect
                  options={ownerOptions}
                  onSelect={({ value: userId }) =>
                    formik.setFieldValue("userId", userId)
                  }
                  selected={formik.values.userId}
                  title="Select owner"
                  maxWidth={200}
                >
                  <Button
                    minWidth={200}
                    color={selectedUser ? "default" : "muted"}
                  >
                    {selectedUser
                      ? selectedUser.fullName
                      : "Select an Owner for this task..."}
                  </Button>
                </SelectMenu>
              </Pane>
              <Pane
                width="100%"
                display="flex"
                alignItems="end"
                justifyContent="end"
              >
                <Link
                  size={300}
                  href="https://help.rabbet.com/en/articles/5659427-tasks-timeline"
                >
                  More info
                </Link>
              </Pane>
            </Sidebar.Section>
            {statusUpdate && (
              <ActualDatesModal
                statusUpdate={statusUpdate}
                onClose={() => setStatusUpdate(null)}
              />
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
}

function ActualDatesModal({ statusUpdate, onClose }) {
  const [oldStatus, newStatus] = statusUpdate;
  const { title, content } = getActualsModalContent(oldStatus, newStatus);

  return (
    <Modal
      open
      hasFooter
      onConfirm={onClose}
      onClose={onClose}
      title={title}
      hasCancel={false}
    >
      <Modal.Content>
        <Paragraph marginBottom={majorScale(2)}>{content}</Paragraph>
        <Pane
          display="flex"
          justifyContent="center"
          marginBottom={majorScale(1)}
        >
          {oldStatus === TASK_STATUS.NOT_STARTED && (
            <Form.DateInput
              name="actualStartDate"
              label="Actual Start Date"
              popperPlacement="bottom-end"
              popperProps={{
                positionFixed: true,
              }}
              width={200}
              marginRight={majorScale(2)}
            />
          )}
          {newStatus === TASK_STATUS.COMPLETE && (
            <Form.DateInput
              name="actualCompletionDate"
              label="Actual Completion Date"
              popperPlacement="bottom-end"
              popperProps={{
                positionFixed: true,
              }}
              width={200}
            />
          )}
        </Pane>
      </Modal.Content>
    </Modal>
  );
}

function generateNewTask() {
  return {
    taskId: null,
    eventName: "",
    originalStartDate: dateServerToForm(new Date()),
    originalDuration: null,
    userId: null,
    status: TASK_STATUS.NOT_STARTED,
    projectedStartDate: dateServerToForm(new Date()),
    actualStartDate: null,
    actualCompletionDate: null,
  };
}

function getInitialValues(task) {
  return {
    taskId: task.id,
    eventName: task.eventName,
    userId: task.userId,
    status: task.status,
    originalStartDate: dateServerToForm(task.originalStartDate),
    originalDuration: !isBlank(task.originalDuration)
      ? `${task.originalDuration}`
      : "",
    projectedStartDate: dateServerToForm(task.projectedStartDate),
    // set placeholder form values for actual start and completion dates in the event the task is not at the proper status for those dates
    // the submission handler will not pass along the dates to the mutation if they are not relevant to the submitted status
    actualStartDate: task.actualStartDate
      ? dateServerToForm(task.actualStartDate)
      : dateServerToForm(task.projectedStartDate),
    actualCompletionDate: task.actualCompletionDate
      ? dateServerToForm(task.actualCompletionDate)
      : dateServerToForm(task.projectedCompletionDate),
  };
}

function getStatusOptions() {
  return [
    TASK_STATUS.NOT_STARTED,
    TASK_STATUS.IN_PROGRESS,
    TASK_STATUS.COMPLETE,
  ].map((type) => ({
    key: type,
    text: t(`taskStatus.${type}`),
    value: type,
  }));
}

function validate(values, task) {
  const dateLimit = addYears(new Date(), 20);
  const errors = {};

  if (isBlank(values.eventName)) {
    set(errors, `eventName`, "Event Name cannot be blank.");
  }

  if (
    !isBlank(values.originalStartDate) &&
    !isBefore(values.originalStartDate, dateLimit)
  ) {
    set(
      errors,
      `originalStartDate`,
      "Date cannot be greater than 20 years from now"
    );
  }

  if (
    values.status === TASK_STATUS.NOT_STARTED &&
    !task?.predecessor &&
    !isBlank(values.projectedStartDate) &&
    !isBefore(values.projectedStartDate, dateLimit)
  ) {
    set(
      errors,
      `projectedStartDate`,
      "Date cannot be greater than 20 years from now"
    );
  }

  if ([TASK_STATUS.IN_PROGRESS, TASK_STATUS.COMPLETE].includes(values.status)) {
    if (isBlank(values.actualStartDate)) {
      set(errors, `actualStartDate`, "Actual Start Date cannot be blank");
    }

    if (
      !isBlank(values.actualStartDate) &&
      !isBefore(values.actualStartDate, dateLimit)
    ) {
      set(
        errors,
        `actualStartDate`,
        "Date cannot be greater than 20 years from now"
      );
    }
  }

  if (values.status === TASK_STATUS.COMPLETE) {
    if (isBlank(values.actualCompletionDate)) {
      set(
        errors,
        `actualCompletionDate`,
        "Actual Completion Date cannot be blank"
      );
    }

    if (
      !isBlank(values.actualCompletionDate) &&
      !isBefore(values.actualCompletionDate, dateLimit)
    ) {
      set(
        errors,
        `actualCompletionDate`,
        "Date cannot be greater than 20 years from now"
      );
    }

    if (
      !isBlank(values.actualStartDate) &&
      !isBlank(values.actualCompletionDate) &&
      isBefore(values.actualCompletionDate, values.actualStartDate)
    ) {
      set(
        errors,
        `actualCompletionDate`,
        "Actual Completion Date cannot be before Actual Start Date"
      );
    }
  }

  return errors;
}

// This function and the two functions below it exist because we calculate a few pieces of information on the back end,
// and want to avoid showing stale calculated values when a task is being edited.
// In each of these functions, we check the pieces of form state relevant to a given calculated value,
// and if we determine the value is stale, we will hide it until the form has been reset and updated calculations have been refetched
function shouldShowOriginalCompletionDate(values, initialValues) {
  return (
    values.originalStartDate === initialValues.originalStartDate &&
    values.originalDuration === initialValues.originalDuration
  );
}

// Apologies for this function in particular...
// `Projected Completion Date` is calculated from either the `Actual Start Date` or the `Projected Start Date` depending on the stauts of the task
function shouldShowProjectedCompletionDate(values, initialValues) {
  const {
    status: initialStatus,
    projectedStartDate: initialProjectedStart,
    actualStartDate: initialActualStart,
    originalDuration: initialDuration,
  } = initialValues;

  const {
    status,
    projectedStartDate: projectedStart,
    actualStartDate: actualStart,
    originalDuration: duration,
  } = values;
  return (
    ((status === TASK_STATUS.NOT_STARTED &&
      initialStatus === TASK_STATUS.NOT_STARTED &&
      projectedStart === initialProjectedStart) ||
      (status === TASK_STATUS.IN_PROGRESS &&
        initialStatus === TASK_STATUS.IN_PROGRESS &&
        actualStart === initialActualStart) ||
      (status === TASK_STATUS.IN_PROGRESS &&
        initialStatus === TASK_STATUS.NOT_STARTED &&
        actualStart === initialProjectedStart) ||
      (status === TASK_STATUS.NOT_STARTED &&
        initialStatus === TASK_STATUS.IN_PROGRESS &&
        projectedStart === initialActualStart)) &&
    duration === initialDuration
  );
}

function shouldShowActualDuration(values, initialValues) {
  return (
    values.status === TASK_STATUS.COMPLETE &&
    initialValues.status === TASK_STATUS.COMPLETE &&
    values.actualStartDate === initialValues.actualStartDate &&
    values.actualCompletionDate === initialValues.actualCompletionDate
  );
}

function getActualsModalContent(oldStatus, newStatus) {
  if (
    oldStatus === TASK_STATUS.NOT_STARTED &&
    newStatus === TASK_STATUS.IN_PROGRESS
  )
    return {
      title: "Confirm Actual Start Date",
      content: "Please confirm the date this task was started.",
    };

  if (
    oldStatus === TASK_STATUS.IN_PROGRESS &&
    newStatus === TASK_STATUS.COMPLETE
  )
    return {
      title: "Confirm Actual Completion Date",
      content: "Please confirm the date this task was completed.",
    };

  // status has been updated from "not started" to "complete"
  if (newStatus === TASK_STATUS.COMPLETE)
    return {
      title: "Confirm Actual Dates",
      content: "Please confirm the start and completion dates for this task.",
    };

  // status has been updated from "complete" to "in progress"
  if (newStatus === TASK_STATUS.IN_PROGRESS)
    return {
      title: "Confirm Status Change",
      content: `This task will be marked "In Progress". The Actual Completion Date will be removed.`,
    };

  // status has been updated from "complete" to "not started"
  return {
    title: "Confirm Status Change",
    content: `This task will be marked "Not Started". The Actual Start Date and Actual Completion Date will be removed.`,
  };
}

// the server verifies relevant fields with similar logic when assigning changeset params
function prepareMutationArguments(values, projectId) {
  const {
    taskId,
    eventName,
    status,
    userId,
    originalStartDate,
    originalDuration,
    projectedStartDate,
    actualStartDate,
    actualCompletionDate,
  } = values;

  let args = {
    projectId,
    taskId,
    eventName: eventName.trim(),
    status,
    userId,
    originalStartDate: dateFormToServer(originalStartDate),
    originalDuration: isBlank(originalDuration)
      ? null
      : parseCurrencyToFloat(originalDuration),
    projectedStartDate: dateFormToServer(projectedStartDate),
  };

  if (status === TASK_STATUS.NOT_STARTED) {
    args = {
      ...args,
      actualStartDate: null,
      actualCompletionDate: null,
    };
  }

  if (status === TASK_STATUS.IN_PROGRESS) {
    args = {
      ...args,
      actualStartDate: dateFormToServer(actualStartDate),
      actualCompletionDate: null,
    };
  }

  if (status === TASK_STATUS.COMPLETE) {
    args = {
      ...args,
      actualStartDate: dateFormToServer(actualStartDate),
      actualCompletionDate: dateFormToServer(actualCompletionDate),
    };
  }

  return args;
}

import { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ContentLayout } from "components/templates";
import { Button, Loadable, Pane, Paragraph, Table } from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { subtract, sumBy } from "helpers/math";
import { get } from "lodash";
import t from "helpers/translate";
import {
  DRAW_SUBMISSION_HEADER_FRAGMENT,
  ImportDrawSubmissionHeader,
} from "./ImportDrawSubmissionHeader";

function adjustmentsDifference(updatedAdjustments, previousAdjustments) {
  // Here we're considering an adjustment "unique" by its division + name + amount
  const identity = ({ adjustmentAmount, lineItem }) => {
    const name = get(lineItem, "name");
    const divisionName = get(lineItem, "division.name");
    return `${divisionName}:${name}:${adjustmentAmount}`;
  };
  const previousAdjustmentIds = previousAdjustments.map(identity);
  const updatedAdjustmentIds = updatedAdjustments.map(identity);

  return {
    added: updatedAdjustments.filter(
      (adjustment) => previousAdjustmentIds.indexOf(identity(adjustment)) === -1
    ),
    removed: previousAdjustments.filter(
      (adjustment) => updatedAdjustmentIds.indexOf(identity(adjustment)) === -1
    ),
  };
}

const QUERY = gql`
  query ImportDrawSubmissionAdjustments($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      adjustments {
        id
        adjustmentAmount
        lineItem {
          id
          balanceToFundAmount
          isNew
          name
          division {
            id
            name
          }
        }
      }
      connection {
        id
        targetDraw {
          id
          name
          adjustments {
            id
            scopeId
            adjustmentAmount
            lineItem {
              id
              scopeId
              balanceToFundAmount
              isNew
              name
              division {
                id
                scopeId
                name
              }
            }
          }
        }
      }
      ...DrawSubmissionHeaderFragment
    }
  }
  ${DRAW_SUBMISSION_HEADER_FRAGMENT}
`;

function AdjustmentsTable({ adjustments }) {
  const total = sumBy(adjustments, "adjustmentAmount");

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TextHeaderCell>Line Item Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Division</Table.TextHeaderCell>
          <Table.TextHeaderCell>Balance Remaining</Table.TextHeaderCell>
          <Table.TextHeaderCell>Adjustments</Table.TextHeaderCell>
          <Table.TextHeaderCell>New Balance Remaining</Table.TextHeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {adjustments.map((adjustment) => (
          <Table.Row key={adjustment.id}>
            <Table.TextCell>
              {adjustment.lineItem.isNew && "(NEW) "}
              {adjustment.lineItem.name}
            </Table.TextCell>
            <Table.TextCell>{adjustment.lineItem.division.name}</Table.TextCell>
            <Table.TextCell>
              {formatCurrency(
                subtract(
                  adjustment.lineItem.balanceToFundAmount,
                  adjustment.adjustmentAmount
                )
              )}
            </Table.TextCell>
            <Table.TextCell>
              {formatCurrency(adjustment.adjustmentAmount)}
            </Table.TextCell>
            <Table.TextCell>
              {formatCurrency(adjustment.lineItem.balanceToFundAmount)}
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Foot>
        <Table.Row>
          <Table.TextFooterCell>TOTAL CHANGES</Table.TextFooterCell>
          <Table.TextFooterCell />
          <Table.TextFooterCell />
          <Table.TextFooterCell>{formatCurrency(total)}</Table.TextFooterCell>
          <Table.TextFooterCell />
        </Table.Row>
      </Table.Foot>
    </Table>
  );
}

export function ImportDrawSubmissionAdjustments({ onNext, submissionId }) {
  const { data, loading } = useQuery(QUERY, { variables: { submissionId } });

  if (loading) {
    return <Loadable loading />;
  }

  const submissionAdjustments = get(data, "submission.adjustments", []);
  const previouslyImportedAdjustments = get(
    data,
    "submission.connection.targetDraw.adjustments",
    []
  );

  if (submissionAdjustments.length === 0) {
    onNext();
    return <Loadable loading />;
  }

  const adjustmentsDiff = adjustmentsDifference(
    submissionAdjustments,
    previouslyImportedAdjustments
  );

  return (
    <ContentLayout fixedWidth={840}>
      <ImportDrawSubmissionHeader
        submission={data.submission}
        title="Update Draw"
      />

      {adjustmentsDiff.added.length > 0 && (
        <Fragment>
          <Paragraph marginY={majorScale(2)}>
            {t("import.addedAdjustments", {
              draw: data.submission.sourceDraw.name,
            })}
          </Paragraph>
          <AdjustmentsTable adjustments={adjustmentsDiff.added} />
        </Fragment>
      )}

      {adjustmentsDiff.removed.length > 0 && (
        <Fragment>
          <Paragraph marginY={majorScale(2)}>
            {t("import.removedAdjustments", {
              draw: data.submission.sourceDraw.name,
            })}
          </Paragraph>
          <AdjustmentsTable adjustments={adjustmentsDiff.removed} />
        </Fragment>
      )}

      {data.submission.connection && (
        <Fragment>
          <Paragraph marginTop={majorScale(2)} marginBottom={majorScale(2)}>
            {t("import.existingAdjustments")}
          </Paragraph>
          <AdjustmentsTable adjustments={previouslyImportedAdjustments} />
        </Fragment>
      )}

      <Pane marginY={majorScale(1)} textAlign="right">
        <Button
          purpose="import-draw adjustments next"
          appearance="primary"
          onClick={() => onNext()}
        >
          Next
        </Button>
      </Pane>
    </ContentLayout>
  );
}

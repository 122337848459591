import gql from "graphql-tag";
import {
  COMMENT_FRAGMENT,
  REVIEW_FRAGMENT,
  RULE_FRAGMENT,
} from "helpers/fragments";

export const SUBSCRIPTION = gql`
  subscription drawRulesUpdated($drawId: String!) {
    drawRulesUpdated(drawId: $drawId) {
      id
      rules {
        ...RuleFragment
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const COMMENT_QUERY = gql`
  query DrawReviewsPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      name
      draw(id: $drawId) {
        id
        comments {
          ...CommentFragment
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const QUERY = gql`
  query DrawReviewsPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        budgetAmount
        name
        requestedAmount
        comments {
          ...CommentFragment
        }
        documents {
          id
        }
        reviews {
          ...ReviewFragment
        }
        rules {
          ...RuleFragment
        }
        submissions {
          id
          submittedAt
          stakeholder {
            id
            role
            member {
              id
              name
              email
            }
            organization {
              id
              name
            }
          }
        }
        export {
          url
        }
        summary {
          url
        }
        questions {
          id
          label
          description
          fieldType
        }
        responses {
          id
          drawAssessmentQuestionId
          response
        }
      }
      organization {
        id
        name
        reportTemplates {
          id
          name
        }
      }
      users {
        id
        fullName
        permissionConfig
      }
    }
  }
  ${COMMENT_FRAGMENT}
  ${REVIEW_FRAGMENT}
  ${RULE_FRAGMENT}
`;

export const TRANSITION = gql`
  mutation DrawRulesTransition(
    $drawId: String!
    $ruleId: String!
    $state: RuleState!
    $body: String
    $auto: Boolean
  ) {
    transitionRule(
      drawId: $drawId
      ruleId: $ruleId
      state: $state
      body: $body
      auto: $auto
    ) {
      ...RuleFragment
    }
  }
  ${RULE_FRAGMENT}
`;

export const COMMENT = gql`
  mutation DrawRulesComment(
    $drawId: String!
    $ruleId: String!
    $body: String!
  ) {
    addRuleComment(drawId: $drawId, ruleId: $ruleId, body: $body) {
      id
      target {
        ... on Rule {
          ...RuleFragment
        }
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION = gql`
  mutation SaveDrawAssessmentResponsesMutation(
    $drawId: String!
    $drawAssessmentResponses: [DrawAssessmentResponseInput]
  ) {
    saveDrawAssessmentResponses(
      drawId: $drawId
      drawAssessmentResponses: $drawAssessmentResponses
    ) {
      id
      responses {
        id
        drawAssessmentQuestionId
        response
      }
    }
  }
`;

export const CHANGE_DRAW_REVIEWERS = gql`
  mutation ChangeDrawReviewers(
    $drawId: String!
    $projectId: String!
    $drawReviewerIds: [String]
    $preparerId: String
    $signatoryId: String
    $updateProject: Boolean
  ) {
    changeDrawReviewers(
      drawId: $drawId
      projectId: $projectId
      drawReviewerIds: $drawReviewerIds
      preparerId: $preparerId
      signatoryId: $signatoryId
      updateProject: $updateProject
    ) {
      id
      reviews {
        ...ReviewFragment
      }
    }
  }
  ${REVIEW_FRAGMENT}
`;

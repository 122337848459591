import { Fragment, useContext, useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { useForm } from "react-hook-form";
import {
  EditInspectionQuestionsPanel,
  EditLineItemTypesPanel,
  EditMriConfigurationPanel,
  EditNexusConfigurationPanel,
  EditProjectDetailsPanel,
  EditProjectFundingSourcesPanel,
  EditProjectStakeholdersPanel,
  EditQuickBooksOnlineConfigurationPanel,
  EditRulesPanel,
  EditYardiConfigurationPanel,
} from "components/templates";
import {
  EditUserAccessPanel,
  initialValues as getUserAccessInitialValues,
  parseUserAccessValues,
} from "components/templates/EditUserAccessPanel";
import {
  EditExternalUploadPanel,
  validateSharing,
  initialValues as getExternalUploadInitialValues,
} from "components/templates/EditExternalUploadPanel";
import { Accordion, Form, Loadable } from "components/materials";
import {
  compact,
  find,
  flatMap,
  get,
  includes,
  reduce,
  some,
  values,
} from "lodash";
import { divide, multiply } from "helpers/math";
import unformatNumber from "helpers/unformatNumber";
import {
  CUSTOM_FIELD_TYPE,
  RULE_TYPE,
  PERMISSION_ACTION,
  PROJECT_SETUP_STEP_STATUS,
} from "helpers/enums";
import { dateFormToServer } from "helpers/dateHelpers";
import { UserContext, NavigationWarnings } from "helpers/behaviors";
import { majorScale, toaster } from "helpers/utilities";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { getSearchByKey } from "helpers/queryStringHelpers";
import isBlank from "helpers/isBlank";
import {
  BASE_SETTINGS_FRAGMENT,
  DETAILS_SETTINGS_FRAGMENT,
  DRAW_ASSESSMENT_QUESTIONS_FRAGMENT,
  FUNDING_SOURCES_SETTINGS_FRAGMENT,
  INSPECTION_QUESTIONS_FRAGMENT,
  LINE_ITEM_TYPES_FRAGMENT,
  MRI_CONFIGURATION_FRAGMENT,
  NEXUS_CONFIGURATION_FRAGMENT,
  YARDI_CONFIGURATION_FRAGMENT,
  REVIEWERS_SETTINGS_FRAGMENT,
  RULES_SETTINGS_FRAGMENT,
  QUICK_BOOKS_ONLINE_CONFIGURATION_FRAGMENT,
  SHARING_SETTINGS_FRAGMENT,
  STAKEHOLDERS_SETTINGS_FRAGMENT,
} from "helpers/fragments";
import { getAllOrganizations } from "helpers/fundingSourceHelpers";
import { UPDATE_PROJECT_SETUP_STEP } from "helpers/projectSetupStep";
import {
  orderedLineItemTypes,
  orderedLineItemCategories,
} from "../templates/LineItemSettings";

const QUERY = gql`
  query ProjectSettingsQuery($projectId: String!) {
    project(id: $projectId) {
      organization {
        id
        drawAssessmentQuestions {
          id
          label
        }
        glCodes
        name
        teams {
          id
          memberships {
            id
            permissionConfig
            userId
          }
          name
        }
        inspectionReportQuestions {
          id
          label
        }
        users {
          id
          fullName
          email
          permissionConfig
          approvalAmountLimit
          approvalAmountMinimum
        }
        userAssignablePermissions
      }
      setupSteps {
        id
        status
        step
      }
      vendors {
        id
        name
      }
      ...BaseSettingsFragment
      ...DetailsSettingsFragment
      ...FundingSourcesSettingsFragment
      ...DrawAssessmentQuestionsFragment
      ...InspectionQuestionsFragment
      ...ReviewersSettingsFragment
      ...RulesSettingsFragment
      ...SharingSettingsFragment
      ...StakeholdersSettingsFragment
      ...NexusConfigurationFragment
      ...YardiConfigurationFragment
      ...QuickBooksOnlineConfigurationFragment
      ...MriConfigurationFragment
    }
  }
  ${BASE_SETTINGS_FRAGMENT}
  ${DETAILS_SETTINGS_FRAGMENT}
  ${FUNDING_SOURCES_SETTINGS_FRAGMENT}
  ${DRAW_ASSESSMENT_QUESTIONS_FRAGMENT}
  ${INSPECTION_QUESTIONS_FRAGMENT}
  ${REVIEWERS_SETTINGS_FRAGMENT}
  ${RULES_SETTINGS_FRAGMENT}
  ${SHARING_SETTINGS_FRAGMENT}
  ${STAKEHOLDERS_SETTINGS_FRAGMENT}
  ${NEXUS_CONFIGURATION_FRAGMENT}
  ${YARDI_CONFIGURATION_FRAGMENT}
  ${QUICK_BOOKS_ONLINE_CONFIGURATION_FRAGMENT}
  ${MRI_CONFIGURATION_FRAGMENT}
`;

const CHANGE_PROJECT_DETAILS = gql`
  mutation ChangeProjectDetails(
    $acres: Float
    $city: String
    $customFields: [ProjectCustomFieldInput]
    $customId: String
    $drawSummaryTemplate: DrawSummaryTemplateType
    $drawUpdateSource: DrawUpdateSourceOption
    $expectedProjectLength: Int
    $loanMaturityDate: Date
    $name: String!
    $productTypeId: String
    $projectId: String!
    $projectRegionId: String
    $squareFeet: Float
    $startDate: Date
    $stateValue: AddressState
    $status: ProjectStatusType!
    $streetAddress: String
    $teamId: String
    $zip: String
  ) {
    changeProjectDetails(
      acres: $acres
      city: $city
      customFields: $customFields
      customId: $customId
      drawSummaryTemplate: $drawSummaryTemplate
      drawUpdateSource: $drawUpdateSource
      expectedProjectLength: $expectedProjectLength
      loanMaturityDate: $loanMaturityDate
      name: $name
      productTypeId: $productTypeId
      projectId: $projectId
      projectRegionId: $projectRegionId
      squareFeet: $squareFeet
      startDate: $startDate
      state: $stateValue
      status: $status
      streetAddress: $streetAddress
      teamId: $teamId
      zip: $zip
    ) {
      id
      ...DetailsSettingsFragment
    }
  }
  ${DETAILS_SETTINGS_FRAGMENT}
`;

const CHANGE_LINE_ITEM_SETTINGS = gql`
  mutation ChangeLineItemSettings(
    $projectId: String!
    $lineItemTypes: [LineItemTypesInput]!
    $retainageUpdates: [RetainageInput]
  ) {
    changeLineItemSettings(
      projectId: $projectId
      lineItemTypes: $lineItemTypes
      retainageUpdates: $retainageUpdates
    ) {
      id
      ...LineItemTypesFragment
    }
  }
  ${LINE_ITEM_TYPES_FRAGMENT}
`;

const CHANGE_RULES = gql`
  mutation ChangeRules($rules: [RuleInput]!, $projectId: String!) {
    changeRules(rules: $rules, projectId: $projectId) {
      id
      ...RulesSettingsFragment
    }
  }
  ${RULES_SETTINGS_FRAGMENT}
`;

const CHANGE_PROJECT_USER_SETUP = gql`
  mutation ChangeProjectUserSetup(
    $projectId: ID!
    $documentReviewerIds: [ID]
    $drawReviewerIds: [ID]
    $preparerId: ID
    $signatoryId: ID
    $suggestedDocumentAssigneeIds: [ID]
    $updateDrawIds: [ID]
  ) {
    changeProjectUserSetup(
      projectId: $projectId
      documentReviewerIds: $documentReviewerIds
      drawReviewerIds: $drawReviewerIds
      preparerId: $preparerId
      signatoryId: $signatoryId
      suggestedDocumentAssigneeIds: $suggestedDocumentAssigneeIds
      updateDrawIds: $updateDrawIds
    ) {
      id
      ...ReviewersSettingsFragment
    }
  }
  ${REVIEWERS_SETTINGS_FRAGMENT}
`;

const CHANGE_FORM_CONFIGURATIONS_MUTATION = gql`
  mutation ChangeFormConfigurations(
    $projectId: String!
    $drawAssessmentQuestionIds: [String]!
    $inspectionReportQuestionIds: [String]!
  ) {
    changeFormConfigurations(
      projectId: $projectId
      drawAssessmentQuestionIds: $drawAssessmentQuestionIds
      inspectionReportQuestionIds: $inspectionReportQuestionIds
    ) {
      id
      ...DrawAssessmentQuestionsFragment
      ...InspectionQuestionsFragment
    }
  }
  ${DRAW_ASSESSMENT_QUESTIONS_FRAGMENT}
  ${INSPECTION_QUESTIONS_FRAGMENT}
`;

const CHANGE_SHARING_CONFIGURATIONS_MUTATION = gql`
  mutation ChangeSharingConfigurations(
    $projectId: ID!
    $linkSharingEnabled: Boolean!
    $emailToRabbetEnabled: Boolean!
    $emailToAddress: String
    $emailNotificationsEnabledUserIds: [ID]
  ) {
    changeSharingConfigurations(
      projectId: $projectId
      linkSharingEnabled: $linkSharingEnabled
      emailToRabbetEnabled: $emailToRabbetEnabled
      emailToAddress: $emailToAddress
      emailNotificationsEnabledUserIds: $emailNotificationsEnabledUserIds
    ) {
      id
      ...SharingSettingsFragment
    }
  }
  ${SHARING_SETTINGS_FRAGMENT}
`;

const CHANGE_NEXUS_CONFIGURATION = gql`
  mutation ChangeNexusConfiguration(
    $projectId: String!
    $glCode: String
    $jobCode: String
    $jobPhaseCode: String
  ) {
    changeNexusConfiguration(
      projectId: $projectId
      glCode: $glCode
      jobCode: $jobCode
      jobPhaseCode: $jobPhaseCode
    ) {
      id
      ...NexusConfigurationFragment
    }
  }
  ${NEXUS_CONFIGURATION_FRAGMENT}
`;

const DISCONNECT_FROM_QUICK_BOOKS_ONLINE = gql`
  mutation DisconnectFromQuickBooksOnline($projectId: String!) {
    disconnectFromQuickBooksOnline(projectId: $projectId) {
      id
      hasQboAuthTokens
    }
  }
`;

const CHANGE_YARDI_CONFIGURATION = gql`
  mutation ChangeYardiConfiguration($projectId: String!, $glCode: String) {
    changeYardiConfiguration(projectId: $projectId, glCode: $glCode) {
      id
      ...YardiConfigurationFragment
    }
  }
  ${YARDI_CONFIGURATION_FRAGMENT}
`;

const CHANGE_MRI_CONFIGURATION = gql`
  mutation ChangeMriConfiguration(
    $projectId: String!
    $jobCode: String
    $mriCostList: String
  ) {
    changeMriConfiguration(
      projectId: $projectId
      jobCode: $jobCode
      mriCostList: $mriCostList
    ) {
      id
      jobCode
      mriCostList
    }
  }
`;

const PANELS = {
  DETAILS: "details",
  FUNDING_SOURCES: "fundingSources",
  LINE_ITEM_SETTINGS: "lineItemSettings",
  RULES: "rules",
  REVIEWERS: "reviewers",
  USER_ACCESS: "userAccess",
  STAKEHOLDERS: "stakeholders",
  FORM_CONFIGURATIONS: "formConfigurations",
  SHARING: "sharing",
  NEXUS_CONFIGURATION: "nexusConfiguration",
  QUICK_BOOKS_ONLINE_CONFIGURATION: "quickBooksOnlineConfiguration",
  YARDI_CONFIGURATION: "yardiConfiguration",
  MRI_CONFIGURATION: "mriConfiguration",
};

const hasDirtyPanel = (panels) => some(panels, (isDirty) => !!isDirty);

const generatePanelStyles = (maxWidth, noTopPadding) => ({
  contentStyles: {
    paddingX: majorScale(7),
    paddingTop: noTopPadding === true ? 0 : majorScale(2),
    paddingBottom: majorScale(2),
    maxWidth,
  },
  panelStyles: {
    paddingY: majorScale(2),
  },
  titleStyles: {
    height: majorScale(4),
    paddingRight: majorScale(4),
  },
});

const showSuccessToast = () => {
  toaster.success("Your changes have been saved.", { duration: 2.5 });
};

const showErrorToast = () => {
  toaster.danger("Error, could not save.", { duration: 2.5 });
};

const defaultMutationOptions = {
  onCompleted: () => showSuccessToast(),
  onError: () => showErrorToast(),
};

const stringToFloat = (value) =>
  isBlank(value) ? null : unformatNumber(value);

const parseProjectDetails = (formValues) => ({
  acres: stringToFloat(formValues.acres),
  city: formValues.city,
  customFields: formValues.customFields.map(({ id, type, value }) => {
    if (type === CUSTOM_FIELD_TYPE.BOOLEAN) {
      return { id, value: value.toString() };
    }
    return { id, value };
  }),
  customId: formValues.customId,
  drawSummaryTemplate: formValues.drawSummaryTemplate || null,
  drawUpdateSource: formValues.drawUpdateSource,
  expectedProjectLength: stringToFloat(formValues.expectedProjectLength),
  loanMaturityDate: dateFormToServer(formValues.loanMaturityDate),
  name: formValues.name,
  productTypeId: formValues.productTypeId,
  projectRegionId: formValues.projectRegionId,
  squareFeet: stringToFloat(formValues.squareFeet),
  startDate: dateFormToServer(formValues.startDate),
  stateValue: formValues.stateValue || null,
  status: formValues.status,
  streetAddress: formValues.streetAddress,
  teamId: formValues.teamId,
  zip: formValues.zip,
});

const getRules = (project, ruleType, loading) => {
  if (loading) return [];
  return get(project, "rules", []).filter(({ type }) => type === ruleType);
};

function Details({
  expandedPanelKeys,
  dirtyPanels,
  isDeveloper,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.DETAILS;

  const [changeProjectDetails, changeProjectDetailsResult] = useMutation(
    CHANGE_PROJECT_DETAILS,
    defaultMutationOptions
  );

  const handleProjectDetails = (formValues) => {
    const variables = {
      projectId: project.id,
      ...parseProjectDetails(formValues),
    };
    return changeProjectDetails({ variables });
  };

  const loading = get(changeProjectDetailsResult, "loading");

  return (
    <Formik
      initialValues={EditProjectDetailsPanel.initialValues(project)}
      validate={EditProjectDetailsPanel.validate(isDeveloper)}
      onSubmit={handleProjectDetails}
      enableReinitialize
    >
      {(form) => (
        <EditProjectDetailsPanel
          {...generatePanelStyles(1100, true)}
          isDeveloper={isDeveloper}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          project={project}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function FundingSources({
  dirtyPanels,
  expandedPanelKeys,
  getProjectVendorSearchQuery,
  project,
  searchedVendors,
  setPanelDirty,
  toggle,
  vendors,
}) {
  const panelKey = PANELS.FUNDING_SOURCES;

  const allOrganizations = getAllOrganizations(
    project.fundingSourceGroups,
    vendors
  );

  return (
    <EditProjectFundingSourcesPanel
      {...generatePanelStyles()}
      allOrganizations={allOrganizations}
      dirty={dirtyPanels[panelKey]}
      divisions={get(project, "divisions", [])}
      getProjectVendorSearchQuery={getProjectVendorSearchQuery}
      onToggle={toggle}
      open={!!expandedPanelKeys[panelKey]}
      panelKey={panelKey}
      project={project}
      searchedVendors={searchedVendors}
      setPanelDirty={setPanelDirty}
      showErrorToast={showErrorToast}
      showSuccessToast={showSuccessToast}
    />
  );
}

function LineItemSettings({
  completeSetupStep,
  dirtyPanels,
  expandedPanelKeys,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.LINE_ITEM_SETTINGS;

  const divisions = get(project, "divisions");
  const recentDraw = get(project, "recentDraw");
  const lineItemIds = reduce(
    divisions,
    (acc, division) => ({
      ...acc,
      [division.id]: division.lineItems.map((lineItem) => lineItem.id),
    }),
    {}
  );

  const defaultValues = reduce(
    divisions,
    (values, division) => ({
      ...values,
      ...reduce(
        division.lineItems,
        (acc, { id, retainagePercentage, types }) => ({
          ...acc,
          [`${id}-type`]:
            find(types, (type) => includes(orderedLineItemTypes, type)) || null,
          [`${id}-retainage`]: multiply(retainagePercentage, 100),
          ...reduce(
            orderedLineItemCategories,
            (acc, cat) => ({
              ...acc,
              [`${id}-${cat}`]: includes(types, cat),
            }),
            {}
          ),
        }),
        {}
      ),
    }),
    { formDirty: false }
  );

  const [changeLineItemSettings, changeLineItemSettingsResult] = useMutation(
    CHANGE_LINE_ITEM_SETTINGS,
    {
      onCompleted: () => {
        showSuccessToast();
        form.handleMutationComplete();
      },
      onError: () => showErrorToast(),
    }
  );

  function handleLineItemSettings({ getValues }) {
    const values = getValues();
    const disableRetainage = includes(
      ["FUNDED", "SUBMITTED"],
      get(project, "recentDraw.state")
    );
    const allLineItemIds = flatMap(lineItemIds, (value) => value);

    const lineItems = reduce(
      allLineItemIds,
      (acc, id) => {
        const lineItemType = values[`${id}-type`];
        const lineItemCategories = reduce(
          orderedLineItemCategories,
          (acc, cat) => (values[`${id}-${cat}`] ? [...acc, cat] : acc),
          []
        );
        const retainage = divide(parseFloat(values[`${id}-retainage`]), 100);
        // compact strips out 'null' value for lineItemType which is (neither) in form
        const types = compact(lineItemCategories.concat(lineItemType));
        return { ...acc, [id]: { types, retainage } };
      },
      {}
    );

    const variables = reduce(
      lineItems,
      (acc, { types, retainage }, lineItemId) => ({
        ...acc,
        lineItemTypes: [...acc.lineItemTypes, { lineItemId, types }],
        retainageUpdates: [...acc.retainageUpdates, { lineItemId, retainage }],
      }),
      {
        projectId: project.id,
        lineItemTypes: [],
        retainageUpdates: [],
      }
    );
    if (disableRetainage) {
      variables.retainageUpdates = null;
    }
    changeLineItemSettings({ variables });
  }

  const loading = get(changeLineItemSettingsResult, "loading");
  const form = useForm({ defaultValues });
  const { reset } = form;

  form.handleSubmit = handleLineItemSettings;
  form.handleReset = () => {
    reset({ ...defaultValues });
  };
  form.handleMutationComplete = () => {
    reset({ ...form.getValues() });
  };

  return (
    <Form>
      <EditLineItemTypesPanel
        {...generatePanelStyles(1100)}
        completeSetupStep={completeSetupStep}
        dirty={dirtyPanels[panelKey]}
        divisions={divisions}
        form={form}
        onSubmit={handleLineItemSettings}
        loading={loading}
        lineItemIds={lineItemIds}
        onToggle={toggle}
        open={!!expandedPanelKeys[panelKey]}
        panelKey={panelKey}
        recentDraw={recentDraw}
        setPanelDirty={setPanelDirty}
      />
    </Form>
  );
}

function Rules({
  completeSetupStep,
  dirtyPanels,
  expandedPanelKeys,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.RULES;

  const [changeRules, changeRulesResult] = useMutation(
    CHANGE_RULES,
    defaultMutationOptions
  );

  const handleRules = (formValues) => {
    const variables = {
      projectId: project.id,
      rules: EditRulesPanel.getRulesValues(formValues),
    };

    return changeRules({ variables });
  };

  const loading = get(changeRulesResult, "loading");
  const rules = getRules(project, RULE_TYPE.AUTOMATED, loading);
  const customRules = getRules(project, RULE_TYPE.MANUAL, loading);

  return (
    <Formik
      initialValues={EditRulesPanel.initialValues(rules, customRules)}
      onSubmit={handleRules}
      enableReinitialize
    >
      {(form) => (
        <EditRulesPanel
          {...generatePanelStyles(1100)}
          completeSetupStep={completeSetupStep}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function UserAccess({
  completeSetupStep,
  dirtyPanels,
  expandedPanelKeys,
  teams,
  hasSuggestedDocumentAssignees,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.USER_ACCESS;

  const [changeUserSetup, changeUserSetupResult] = useMutation(
    CHANGE_PROJECT_USER_SETUP,
    defaultMutationOptions
  );

  const handleUserSetup = ({ documentReviewerIds, updateDraws, users }) => {
    // 'accessValues' includes configuration for project access, suggested document assignees, and draw reviewers
    const accessValues = parseUserAccessValues(users);
    const updateDrawIds = updateDraws
      .filter(({ checked }) => !!checked)
      .map(({ id }) => id);

    const variables = {
      projectId: project.id,
      updateDrawIds,
      documentReviewerIds,
      ...accessValues,
    };

    return changeUserSetup({ variables });
  };

  const loading = get(changeUserSetupResult, "loading");

  return (
    <Formik
      initialValues={getUserAccessInitialValues(project)}
      onSubmit={handleUserSetup}
      enableReinitialize
    >
      {(form) => (
        <EditUserAccessPanel
          {...generatePanelStyles()}
          completeSetupStep={completeSetupStep}
          dirty={dirtyPanels[panelKey]}
          form={form}
          teams={teams}
          hasSuggestedDocumentAssignees={hasSuggestedDocumentAssignees}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          projectId={project.id}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function Stakeholders({ expandedPanelKeys, project, toggle, vendors }) {
  const panelKey = PANELS.STAKEHOLDERS;

  return (
    <EditProjectStakeholdersPanel
      {...generatePanelStyles()}
      borrowers={get(project, "borrowers", [])}
      fundingSourceGroups={get(project, "fundingSourceGroups", [])}
      onToggle={toggle}
      open={!!expandedPanelKeys[panelKey]}
      panelKey={panelKey}
      projectId={project.id}
      stakeholders={get(project, "stakeholders", [])}
      vendors={vendors}
    />
  );
}

function getQuestionsInitialValue(questions, enabledQuestionIds) {
  return questions.reduce(
    (values, question) => ({
      ...values,
      [question.id]: {
        label: question.label,
        enabled: includes(enabledQuestionIds, question.id),
      },
    }),
    {}
  );
}

function prepareQuestionsForSubmission(questions) {
  return reduce(
    questions,
    (enabledQuestions, { enabled }, questionId) =>
      enabled ? enabledQuestions.concat(questionId) : enabledQuestions,
    []
  );
}

function InspectionQuestions({
  completeSetupStep,
  dirtyPanels,
  expandedPanelKeys,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.FORM_CONFIGURATIONS;
  const [
    changeProjectFormConfigurations,
    changeProjectFormConfigurationsResult,
  ] = useMutation(CHANGE_FORM_CONFIGURATIONS_MUTATION, defaultMutationOptions);

  const initialValues = {
    inspectionReportQuestions: getQuestionsInitialValue(
      get(project, "organization.inspectionReportQuestions", {}),
      project.inspectionReportQuestionIds
    ),
    drawAssessmentQuestions: getQuestionsInitialValue(
      get(project, "organization.drawAssessmentQuestions", {}),
      project.drawAssessmentQuestionIds
    ),
  };

  function handleSubmit(values) {
    changeProjectFormConfigurations({
      variables: {
        projectId: project.id,
        inspectionReportQuestionIds: prepareQuestionsForSubmission(
          values.inspectionReportQuestions
        ),
        drawAssessmentQuestionIds: prepareQuestionsForSubmission(
          values.drawAssessmentQuestions
        ),
      },
    });
  }

  const loading = get(changeProjectFormConfigurationsResult, "loading");

  return Object.keys(initialValues.inspectionReportQuestions).length === 0 &&
    Object.keys(initialValues.drawAssessmentQuestions).length === 0 ? null : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(form) => (
        <EditInspectionQuestionsPanel
          {...generatePanelStyles(1100)}
          completeSetupStep={completeSetupStep}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function ExternalUpload({
  expandedPanelKeys,
  dirtyPanels,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.SHARING;

  const [changeSharingConfigurations, { loading }] = useMutation(
    CHANGE_SHARING_CONFIGURATIONS_MUTATION,
    {
      onCompleted: () =>
        toaster.success("Your changes have been saved", { duration: 2.5 }),
    }
  );

  const handleSharing = (
    {
      emailToDomain,
      emailToEditablePortion,
      emailToPrefix,
      emailToRabbetEnabled,
      linkSharingEnabled,
      users,
    },
    { setErrors }
  ) => {
    const emailToAddress = [
      emailToPrefix,
      emailToEditablePortion,
      emailToDomain,
    ].join("");

    const emailNotificationsEnabledUserIds = users.reduce(
      (acc, { id, enabledForThisProject }) => [
        ...acc,
        ...(enabledForThisProject ? [id] : []),
      ],
      []
    );

    const variables = {
      projectId: project.id,
      emailNotificationsEnabledUserIds,
      emailToAddress,
      emailToRabbetEnabled,
      linkSharingEnabled,
    };
    changeSharingConfigurations({
      variables,
    }).catch((error) => {
      if (error.message.includes("email_address_taken")) {
        setErrors({
          emailToEditablePortion: "This email is in use on another project",
        });
      } else {
        toaster.warning("Something went wrong.", { duration: 2.5 });
      }
    });
  };

  const accessTokens = get(project, "accessTokens", []);
  const {
    user: { id: currentUserId },
  } = useContext(UserContext);
  return (
    <Formik
      initialValues={getExternalUploadInitialValues(project, currentUserId)}
      onSubmit={handleSharing}
      validate={validateSharing}
      enableReinitialize
    >
      {(form) => (
        <EditExternalUploadPanel
          {...generatePanelStyles()}
          accessTokens={accessTokens}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function NexusConfiguration({
  expandedPanelKeys,
  dirtyPanels,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.NEXUS_CONFIGURATION;
  const { glCode, jobCode, jobPhaseCode } = project;

  const [
    changeNexusConfiguration,
    changeNexusConfigurationResult,
  ] = useMutation(CHANGE_NEXUS_CONFIGURATION, defaultMutationOptions);

  const handleNexusConfiguration = (formValues) => {
    const variables = {
      projectId: project.id,
      ...formValues,
    };

    return changeNexusConfiguration({ variables });
  };

  const { loading } = changeNexusConfigurationResult;

  return (
    <Formik
      initialValues={{
        glCode,
        jobCode: jobCode || "",
        jobPhaseCode: jobPhaseCode || "",
      }}
      onSubmit={handleNexusConfiguration}
      enableReinitialize
    >
      {(form) => (
        <EditNexusConfigurationPanel
          {...generatePanelStyles(1100)}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          setPanelDirty={setPanelDirty}
          glCodes={project.organization.glCodes}
        />
      )}
    </Formik>
  );
}

function QuickBooksOnlineConfiguration({ expandedPanelKeys, project, toggle }) {
  const panelKey = PANELS.QUICK_BOOKS_ONLINE_CONFIGURATION;

  const [
    disconnectFromQuickBooksOnline,
    disconnectFromQuickBooksOnlineResult,
  ] = useMutation(DISCONNECT_FROM_QUICK_BOOKS_ONLINE, defaultMutationOptions);

  function handleDisconnectFromQuickBooksOnline() {
    const variables = { projectId: project.id };
    const disconnectLandingPage =
      "https://help.rabbet.com/en/articles/8349325-your-quickbooks-account-is-now-disconnected";
    disconnectFromQuickBooksOnline({ variables });
    return window.open(disconnectLandingPage, "_blank");
  }

  const { loading } = disconnectFromQuickBooksOnlineResult;

  return (
    <EditQuickBooksOnlineConfigurationPanel
      {...generatePanelStyles(1100)}
      loading={loading}
      handleDisconnectFromQuickBooksOnline={
        handleDisconnectFromQuickBooksOnline
      }
      onToggle={toggle}
      open={!!expandedPanelKeys[panelKey]}
      panelKey={panelKey}
      project={project}
    />
  );
}

function YardiConfiguration({
  expandedPanelKeys,
  dirtyPanels,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.YARDI_CONFIGURATION;
  const { glCode } = project;

  const [
    changeYardiConfiguration,
    changeYardiConfigurationResult,
  ] = useMutation(CHANGE_YARDI_CONFIGURATION, defaultMutationOptions);

  const handleYardiConfiguration = (formValues) => {
    const variables = {
      projectId: project.id,
      ...formValues,
    };

    return changeYardiConfiguration({ variables });
  };

  const { loading } = changeYardiConfigurationResult;

  return (
    <Formik
      initialValues={{
        glCode,
      }}
      onSubmit={handleYardiConfiguration}
      enableReinitialize
    >
      {(form) => (
        <EditYardiConfigurationPanel
          {...generatePanelStyles(1100)}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          project={project}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

function MriConfiguration({
  expandedPanelKeys,
  dirtyPanels,
  project,
  setPanelDirty,
  toggle,
}) {
  const panelKey = PANELS.MRI_CONFIGURATION;
  const { jobCode, mriCostList } = project;

  const [changeMriConfiguration, changeMriConfigurationResult] = useMutation(
    CHANGE_MRI_CONFIGURATION,
    defaultMutationOptions
  );

  const handleMriConfiguration = (formValues) => {
    const variables = {
      projectId: project.id,
      ...formValues,
    };

    return changeMriConfiguration({ variables });
  };

  const { loading } = changeMriConfigurationResult;

  return (
    <Formik
      initialValues={{
        jobCode: jobCode || "",
        mriCostList: mriCostList || "",
      }}
      onSubmit={handleMriConfiguration}
      enableReinitialize
    >
      {(form) => (
        <EditMriConfigurationPanel
          {...generatePanelStyles(1100)}
          dirty={dirtyPanels[panelKey]}
          form={form}
          loading={loading}
          onToggle={toggle}
          open={!!expandedPanelKeys[panelKey]}
          panelKey={panelKey}
          setPanelDirty={setPanelDirty}
        />
      )}
    </Formik>
  );
}

export function ProjectSettingsPage({ history, match }) {
  const { projectId } = match.params;
  const { loading, data } = useQuery(QUERY, {
    variables: { projectId },
  });
  const project = get(data, "project");
  const setupSteps = get(project, "setupSteps", []);
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );
  const [updateProjectSetupStep, { loading: updateStepLoading }] = useMutation(
    UPDATE_PROJECT_SETUP_STEP
  );

  const [dirtyPanels, setDirtyPanels] = useState(
    reduce(
      values(PANELS),
      (acc, panelKey) => ({
        ...acc,
        [panelKey]: false,
      }),
      {}
    )
  );

  const setPanelDirty = (panelKey, isDirty) => {
    setDirtyPanels({
      ...dirtyPanels,
      [panelKey]: isDirty,
    });
  };

  const dirtyProps = { dirtyPanels, setPanelDirty };

  const { hasPermission, isDeveloper } = useContext(UserContext);

  const showFundingSuorces = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );

  const showUserAccess = hasPermission(PERMISSION_ACTION.EDIT_USER_ACCESS);

  const showFormConfigurations =
    hasPermission(PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW) ||
    hasPermission(PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM);

  const showStakeholders = hasPermission(PERMISSION_ACTION.ACCESS_STAKEHOLDERS);

  const showNexusConfiguration = hasPermission(
    PERMISSION_ACTION.NEXUS_INTEGRATION
  );

  const showQuickBooksOnlineConfiguration = hasPermission(
    PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE
  );

  const showYardiConfiguration =
    hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION) ||
    hasPermission(PERMISSION_ACTION.PULL_DATA_FROM_YARDI);

  const showMriConfiguration = hasPermission(PERMISSION_ACTION.PUSH_TO_MRI);
  const includeCodeInfoForMri = hasPermission(
    PERMISSION_ACTION.INCLUDE_CODE_INFO_FOR_MRI
  );

  const hasSuggestedDocumentAssignees = hasPermission(
    PERMISSION_ACTION.ASSIGN_DOCUMENTS
  );

  const completeSetupStep = (stepName) => {
    const stepToComplete = setupSteps.find(
      ({ step, status }) =>
        step === stepName && status === PROJECT_SETUP_STEP_STATUS.PENDING
    );
    if (stepToComplete && !updateStepLoading) {
      updateProjectSetupStep({
        variables: {
          id: stepToComplete.id,
          projectId,
          status: PROJECT_SETUP_STEP_STATUS.COMPLETE,
        },
      });
    }
  };

  if (loading || !project) return <Loadable loading />;
  const vendors = get(data, "project.vendors", []);
  const defaultActiveKeys = getSearchByKey(history, "settings");

  return (
    <Fragment>
      <NavigationWarnings dirty={hasDirtyPanel(dirtyPanels)} />
      <Accordion
        allPanelKeys={values(PANELS)}
        defaultActiveKeys={
          Array.isArray(defaultActiveKeys)
            ? defaultActiveKeys
            : [defaultActiveKeys]
        }
        showExpandCollapseAll
        padding={majorScale(2)}
        render={(toggleProps) => {
          return (
            <Fragment>
              <Details
                isDeveloper={isDeveloper}
                project={project}
                {...dirtyProps}
                {...toggleProps}
              />
              {showFundingSuorces && (
                <FundingSources
                  getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                  project={project}
                  searchedVendors={get(
                    projectVendorSearchQuery,
                    "data.project.organization.paginatedVendors.results",
                    []
                  )}
                  vendors={vendors}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
              <LineItemSettings
                completeSetupStep={completeSetupStep}
                project={project}
                {...dirtyProps}
                {...toggleProps}
              />
              <Rules
                completeSetupStep={completeSetupStep}
                project={project}
                {...dirtyProps}
                {...toggleProps}
              />
              {showUserAccess && (
                <UserAccess
                  completeSetupStep={completeSetupStep}
                  teams={project.organization.teams}
                  hasSuggestedDocumentAssignees={hasSuggestedDocumentAssignees}
                  project={project}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
              {showFormConfigurations && (
                <InspectionQuestions
                  completeSetupStep={completeSetupStep}
                  project={project}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
              {showStakeholders && (
                <Stakeholders
                  project={project}
                  vendors={vendors}
                  {...toggleProps}
                />
              )}
              <ExternalUpload
                project={project}
                {...dirtyProps}
                {...toggleProps}
              />
              {showNexusConfiguration && (
                <NexusConfiguration
                  project={project}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
              {showQuickBooksOnlineConfiguration && (
                <QuickBooksOnlineConfiguration
                  project={project}
                  {...toggleProps}
                />
              )}
              {showYardiConfiguration && (
                <YardiConfiguration
                  project={project}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
              {showMriConfiguration && includeCodeInfoForMri && (
                <MriConfiguration
                  project={project}
                  {...dirtyProps}
                  {...toggleProps}
                />
              )}
            </Fragment>
          );
        }}
      />
    </Fragment>
  );
}

import { createContext, useContext, useState, useMemo, useEffect } from "react";
import { get, uniq, values, xor } from "lodash";
import {
  formatAgreementLineItems,
  formatNewLineItems,
  formatTransactions,
} from "components/containers/Agreements";
import { Form, Loadable, Pane, Text } from "components/materials";
import { DocumentContext } from "contexts/documentContext";
import { formatAgreementAmounts } from "helpers/agreementAmountHelpers";
import analytics, { documentValues } from "helpers/analytics";
import { UserContext } from "helpers/behaviors";
import { dateFormToServer } from "helpers/dateHelpers";
import { isIgnored } from "helpers/documentHelpers";
import {
  AGREEMENT_TYPE,
  DOCUMENT_TYPE_NAME,
  PERMISSION_ACTION,
} from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import humanReadableList from "helpers/humanReadableList";
import isBlank from "helpers/isBlank";
import { add, subtract } from "helpers/math";
import { getMatch } from "helpers/payApplicationLineItemHelpers";
import { getIsGeneralContractor } from "helpers/RequestedAmountHelpers";
import { getPreviousRetainage } from "helpers/retainageLineItemHelpers";
import t from "helpers/translate";
import unformatNumber from "helpers/unformatNumber";
import { majorScale, ThemeContext, toaster } from "helpers/utilities";
import validInspectionImageTypes from "helpers/validInspectionImageTypes";
import { CHANGE_ORDER_TYPES } from "../../components/containers/Agreements/enums";
import { InvoiceReview } from "../../components/templates/DocumentReview/InvoiceReview";
import { AgreementReview } from "../../components/templates/DocumentReview/AgreementReview";
import ConditionalLienWaiverReview from "../../components/templates/DocumentReview/ConditionalLienWaiverReview";
import { ContractorsRequisitionReview } from "../../components/templates/DocumentReview/ContractorsRequisitionReview";
import DrawCoverSheetReview from "../../components/templates/DocumentReview/DrawCoverSheetReview";
import { DrawSummaryReview } from "../../components/templates/DocumentReview/DrawSummaryReview";
import GenericReview from "../../components/templates/DocumentReview/GenericReview";
import { Hud5372Review } from "../../components/templates/DocumentReview/Hud5372Review";
import { Hud92464Review } from "../../components/templates/DocumentReview/Hud92464Review";
import { InspectionImageReview } from "../../components/templates/DocumentReview/InspectionImageReview";
import InspectionReportReview from "../../components/templates/DocumentReview/InspectionReportReview";
import InsuranceCertificateReview from "../../components/templates/DocumentReview/InsuranceCertificateReview";
import NoticeToOwnerReview from "../../components/templates/DocumentReview/NoticeToOwnerReview";
import PayApplicationReview from "../../components/templates/DocumentReview/PayApplicationReview";
import RetainageReleaseReview from "../../components/templates/DocumentReview/RetainageReleaseReview";
import UnconditionalLienWaiverReview from "../../components/templates/DocumentReview/UnconditionalLienWaiverReview";
import { getRequestedAmountDifference } from "../../components/templates/DocumentReview/DocumentReviewActions";
import { PARSABLE_DOCUMENT_TYPES } from "../../components/templates/DocumentReview/utils";

const DOCUMENT_ACTIONS = {
  CHANGE_AGREEMENT_TYPE: "CHANGE_AGREEMENT_TYPE",
  CREATE_AGREEMENT: "CREATE_AGREEMENT",
  ASSIGN_LIEN_WAIVER: "ASSIGN_LIEN_WAIVER",
  CORRECT_DOCUMENT: "CORRECT_DOCUMENT",
  CREATE_AND_ASSIGN_LIEN_WAIVER: "CREATE_AND_ASSIGN_LIEN_WAIVER",
  CREATE_INVOICE: "CREATE_INVOICE",
  CREATE_LIEN_WAIVER: "CREATE_LIEN_WAIVER",
  CREATE_PAY_APPLICATION: "CREATE_PAY_APPLICATION",
  UPDATE_AGREEMENT: "UPDATE_AGREEMENT",
  UPDATE_INVOICE: "UPDATE_INVOICE",
  UPDATE_LIEN_WAIVER: "UPDATE_LIEN_WAIVER",
  UPDATE_PAY_APPLICATION: "UPDATE_PAY_APPLICATION",
};

// Bill of Sale uses the same review page as Insurance Certificate
const BillOfSaleReview = InsuranceCertificateReview;

const REVIEW_FORMS = {
  [DOCUMENT_TYPE_NAME.ADDENDUM]: AgreementReview,
  [DOCUMENT_TYPE_NAME.CONTRACT]: AgreementReview,
  [DOCUMENT_TYPE_NAME.WORK_AUTHORIZATION]: AgreementReview,
  [DOCUMENT_TYPE_NAME.EXPOSURE]: AgreementReview,
  [DOCUMENT_TYPE_NAME.POTENTIAL_CHANGE_ORDER]: AgreementReview,
  [DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER]: AgreementReview,
  [DOCUMENT_TYPE_NAME.BILL_OF_SALE]: BillOfSaleReview,
  [DOCUMENT_TYPE_NAME.CONDITIONAL_LIEN_RELEASE]: ConditionalLienWaiverReview,
  [DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET]: DrawCoverSheetReview,
  [DOCUMENT_TYPE_NAME.DRAW_SUMMARY]: DrawSummaryReview,
  [DOCUMENT_TYPE_NAME.HUD_2448]: ContractorsRequisitionReview,
  [DOCUMENT_TYPE_NAME.HUD_5372]: Hud5372Review,
  [DOCUMENT_TYPE_NAME.HUD_92464]: Hud92464Review,
  [DOCUMENT_TYPE_NAME.INSPECTION_IMAGE]: InspectionImageReview,
  [DOCUMENT_TYPE_NAME.INSPECTION_REPORT]: InspectionReportReview,
  [DOCUMENT_TYPE_NAME.INSURANCE_CERTIFICATE]: InsuranceCertificateReview,
  [DOCUMENT_TYPE_NAME.INVOICE]: InvoiceReview,
  [DOCUMENT_TYPE_NAME.NOTICE_TO_OWNER]: NoticeToOwnerReview,
  [DOCUMENT_TYPE_NAME.PAY_APPLICATION]: PayApplicationReview,
  [DOCUMENT_TYPE_NAME.RETAINAGE_RELEASE_FORM]: RetainageReleaseReview,
  [DOCUMENT_TYPE_NAME.UNCONDITIONAL_LIEN_RELEASE]: UnconditionalLienWaiverReview,
};

export const DocumentFormContext = createContext({});

export function DocumentFormContextProvider({
  children,
  cardsOpen,
  navigateOnDelete,
  setCardsOpen,
  setViewerDirty,
}) {
  function getReviewForm(type) {
    return REVIEW_FORMS[type] || GenericReview;
  }

  const {
    addGeneralContractorMutation: [, addGeneralContractorResult],
    agreementVendorLineItems,
    approveMutation: [onApprove, approveResult],
    assignLienWaiver,
    assignLienWaiverLoading,
    correctMutation: [onCorrect, correctResult],
    createAgreement,
    createAgreementLoading,
    createAndAssignLienWaiver,
    createAndAssignLienWaiverLoading,
    createInvoice,
    createInvoiceLoading,
    createLienWaiver,
    createLienWaiverLoading,
    document,
    draw,
    fetchDraw,
    fetchDrawLoading,
    ignoreMutation: [onIgnore, ignoreResult],
    lineItems,
    markPaid,
    markDocumentPaidLoading,
    markDocumentUnpaidLoading,
    markUnpaid,
    markReviewedMutation: [onMarkReviewed],
    removeMutation: [onRemove, removeResult],
    undoApproveMutation: [onUndoApprove, undoApproveResult],
    updateAgreement,
    updateAgreementLoading,
    updateInvoice,
    updateInvoiceLoading,
    updateLienWaiver,
    updateLienWaiverLoading,
  } = useContext(DocumentContext);

  const theme = useContext(ThemeContext);

  const {
    analyticsContext,
    hasOrgLevelPermission,
    hasPermission,
    user,
  } = useContext(UserContext);
  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );
  const canAssignMultipleCostCodes = hasPermission(
    PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES
  );
  const [selectedType, setSelectedType] = useState(get(document, "type"));
  const [selectedDrawId, setSelectedDrawId] = useState(
    get(document, "draw.id")
  );
  const [cancelParsing, setCancelParsing] = useState(false);
  const ReviewForm = getReviewForm(selectedType);

  const [collapsedPanels, setCollapsedPanels] = useState([]);
  const [
    showUserLockdownDrawConfirm,
    setShowUserLockdownDrawConfirm,
  ] = useState(true);
  const [incompatibleImageModalOpen, setIncompatibleImageModalOpen] = useState(
    false
  );
  const [
    assignAdjustmentToDrawModalOpen,
    setAssignAdjustmentToDrawModalOpen,
  ] = useState(false);
  const [
    cannotChangeTypeHasAdjustmentModalOpen,
    setCannotChangeTypeHasAdjustmentModalOpen,
  ] = useState(false);
  const [
    confirmChangeAgreementTypeModalOpen,
    setConfirmChangeAgreementTypeModalOpen,
  ] = useState(false);
  const [
    willDeleteAdjustmentModalOpen,
    setWillDeleteAdjustmentModalOpen,
  ] = useState(false);
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);
  const drawBudgetAdjustments = get(draw, "budgetAdjustments", []);
  const documentBudgetAdjustments = get(document, "draw.budgetAdjustments", []);
  const newlySelectedDrawId = get(draw, "id");
  const wasSavedAgreement = values(AGREEMENT_TYPE).includes(document.type);
  const hasAdjustment = get(document, "agreement.budgetAdjustment.id");

  const selectedDraw = useMemo(() => {
    if (!selectedDrawId) return null;

    if (selectedDrawId !== get(document, "draw.id")) {
      if (newlySelectedDrawId === selectedDrawId) {
        return draw;
      }

      // Since we're listening to an item on the draw, whenever `fetchDraw` is called,
      // the draw object changes and would call fetchDraw again, creating an infinite loop
      // so we should check that the draw query isn't already running
      if (!fetchDrawLoading) {
        fetchDraw({ variables: { drawId: selectedDrawId } });
      }
      return null;
    }

    return document.draw;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentBudgetAdjustments,
    drawBudgetAdjustments,
    newlySelectedDrawId,
    selectedDrawId,
  ]);

  useEffect(() => {
    if (
      wasSavedAgreement &&
      document.type !== selectedType &&
      hasAgreementManagement
    ) {
      setConfirmChangeAgreementTypeModalOpen(true);
    }
    if (
      document.type !== selectedType &&
      hasAdjustment &&
      !hasAgreementManagement
    ) {
      canCreateAdjustments
        ? setWillDeleteAdjustmentModalOpen(true)
        : setCannotChangeTypeHasAdjustmentModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    analytics.track("Document Opened", {
      ...documentValues(document),
      viewingAssignedDocument: user.id === get(document, "assignedUser.id"),
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let collapsedPanels = [];
    if (ReviewForm !== GenericReview) {
      if (hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS)) {
        collapsedPanels =
          get(document, "documentReviewActivity.length", 0) > 0
            ? collapsedPanels
            : collapsedPanels.concat(["activity"]);
      }

      collapsedPanels =
        get(document, "comments.length", 0) > 0
          ? collapsedPanels
          : collapsedPanels.concat(["comments"]);

      if (get(document, "drawSummaryConfig.rowMappings")) {
        collapsedPanels = collapsedPanels.concat(["drawSummaryOptions"]);
      }

      setCollapsedPanels(collapsedPanels);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReviewForm]);

  if (fetchDrawLoading) return <Loadable loading />;

  const documentReclassified = document.type !== selectedType;

  const documentMimeType = get(document, "file.type");

  function togglePanel(panelName) {
    setCollapsedPanels(xor(collapsedPanels, [panelName]));
  }

  const initialValues = ReviewForm.getInitialValues({
    agreementVendorLineItems,
    document,
    hasPermission,
    lineItems,
    selectedDraw,
    selectedType,
    cancelParsing,
  });

  function preparePayApplicationVariables(values) {
    const isGeneralContractor = getIsGeneralContractor({
      project: values.project,
      vendor: values.vendor,
    });
    const showLineItemsTable =
      isGeneralContractor ||
      !hasPermission(PERMISSION_ACTION.SUBCONTRACTOR_FORM) ||
      values.showFullFields ||
      // only if the org has "ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES_PERMISSION" on
      canAssignMultipleCostCodes
        ? values.lineItems.length > 0
        : values.lineItems.length > 1;

    const formattedLineItems = values.lineItems.map(
      ({
        applicationAmount,
        jobCostCodes,
        lineItemObject,
        lineItemIndex,
        materialsStoredAmount,
        retainageAmount,
        retainageToDateAmount,
      }) => {
        jobCostCodes ||= [];
        /* if Multiple Cost Code FF is OFF, assign 100% of the grossAmount
            and the retainageAmount to the single [jobCostCode], if exists
            If ON, the amounts are  */
        const jobCostCodesWithAmounts = (canAssignMultipleCostCodes
          ? jobCostCodes
          : jobCostCodes.map(({ jobCostCodeId }) => ({
              grossAmount: formatCurrency(applicationAmount),
              jobCostCodeId,
              retainageAmount: formatCurrency(retainageAmount),
            }))
        ).filter(({ jobCostCodeId }) => !!jobCostCodeId);

        return {
          amount: formatCurrency(
            subtract(
              add(applicationAmount, materialsStoredAmount),
              retainageAmount
            )
          ),
          grossAmount: formatCurrency(applicationAmount),
          jobCostCodes: jobCostCodesWithAmounts,
          lineItemIndex,
          lineItemId: lineItemObject.id,
          name: lineItemObject.descriptionOfWork,
          retainageAmount: formatCurrency(retainageAmount),
          retainageToDateAmount: formatCurrency(retainageToDateAmount),
          materialsStoredAmount: formatCurrency(materialsStoredAmount),
        };
      }
    );

    const lineItems = showLineItemsTable
      ? formattedLineItems
      : formattedLineItems.map((lineItem) => {
          // if the form is a simple subcontractor form
          // we still want to store the amounts associated with gc line items (application amount, stored materials, rtd)
          // then the application amount is calculated from the "current payment due" amount
          // usually we calculate current payment due = application amount + stored materials - rtd
          // instead, when going from simplified pay app to the full line items table,
          // the calculation for This Period is:
          // this period = current payment due + retainage this draw
          const match = getMatch(
            get(values, "draw.lineItems", []),
            lineItem,
            true
          );
          const previousRetainage = getPreviousRetainage(
            values.vendor,
            values.draw
          )(match);
          const retainageAmount = subtract(
            values.totalRetainage,
            previousRetainage
          );
          const grossAmount = add(values.currentPaymentDue, retainageAmount);

          return {
            amount: formatCurrency(
              subtract(grossAmount, retainageAmount)
            ).toString(),
            jobCostCodes: lineItem.jobCostCodes,
            lineItemIndex: lineItem.lineItemIndex,
            lineItemId: lineItem.lineItemId,
            name: get(match, "fullName"),
            grossAmount: formatCurrency(grossAmount).toString(),
            materialsStoredAmount: 0,
            retainageAmount: formatCurrency(retainageAmount).toString(),
            retainageToDateAmount: values.totalRetainage,
          };
        });

    const {
      assignedUser,
      agreementAmounts,
      currentPaymentDue,
      totalCompletedAndStoredToDate,
      totalRetainage,
      applicationNumber,
      autoCalculateRetainage,
      description,
      id,
      invoiceId,
      isBackup,
      periodToDate,
      showAllHardCostLineItems,
      showFullFields,
      type,
      vendor,
    } = values;

    const { netAmount, grossAmount, retainageAmount } = lineItems.reduce(
      (lineItemTotals, lineItem) => ({
        grossAmount: add(lineItemTotals.grossAmount, lineItem.grossAmount),
        retainageAmount: add(
          lineItemTotals.retainageAmount,
          lineItem.retainageAmount
        ),
        netAmount: add(lineItemTotals.netAmount, lineItem.amount),
      }),
      {}
    );

    return {
      assignedUserId: get(assignedUser, "id", null),
      autoCalculateRetainage,
      currentPaymentDue: formatCurrency(currentPaymentDue).toString(),
      date: dateFormToServer(periodToDate),
      description,
      documentId: id,
      drawId: selectedDrawId,
      grossAmount: formatCurrency(grossAmount).toString(),
      isBackup: !!isBackup,
      lineItems,
      netAmount: formatCurrency(netAmount).toString(),
      number: isBlank(applicationNumber) ? null : applicationNumber,
      projectId,
      retainageAmount: formatCurrency(retainageAmount).toString(),
      totalRetainage: formatCurrency(totalRetainage).toString(),
      showAllHardCostLineItems,
      showFullFields,
      totalCompletedAndStoredToDate: formatCurrency(
        totalCompletedAndStoredToDate
      ).toString(),
      trackedAgreementAmounts: isBackup
        ? []
        : formatAgreementAmounts(agreementAmounts, vendor.id),
      type,
      vendorId: vendor.id,
      ...(invoiceId && { id: invoiceId }),
    };
  }

  function prepareInvoiceVariables(values) {
    const lineItems = values.lineItems.map(
      ({
        amount,
        grossAmount,
        jobCostCodeId,
        lineItemObject,
        lineItemIndex,
        retainageAmount,
        retainageToDateAmount,
      }) => ({
        amount: formatCurrency(amount),
        grossAmount:
          unformatNumber(retainageAmount) === 0
            ? formatCurrency(amount)
            : formatCurrency(grossAmount),
        jobCostCodes: jobCostCodeId
          ? [
              {
                grossAmount:
                  unformatNumber(retainageAmount) === 0
                    ? formatCurrency(amount)
                    : formatCurrency(grossAmount),
                jobCostCodeId,
                retainageAmount: formatCurrency(retainageAmount),
              },
            ]
          : [],
        lineItemIndex,
        lineItemId: lineItemObject.id,
        name: lineItemObject.name,
        retainageAmount: formatCurrency(retainageAmount),
        retainageToDateAmount: formatCurrency(retainageToDateAmount),
      })
    );

    const {
      agreementAmounts,
      assignedUser,
      autoCalculateRetainage,
      date,
      description,
      id,
      invoiceId,
      isBackup,
      number,
      type,
      vendor,
    } = values;

    const { netAmount, grossAmount, retainageAmount } = lineItems.reduce(
      (lineItemTotals, lineItem) => ({
        netAmount: add(lineItemTotals.netAmount, lineItem.amount),
        grossAmount: add(lineItemTotals.grossAmount, lineItem.grossAmount),
        retainageAmount: add(
          lineItemTotals.retainageAmount,
          lineItem.retainageAmount
        ),
      }),
      {}
    );

    return {
      assignedUserId: get(assignedUser, "id", null),
      autoCalculateRetainage,
      date: dateFormToServer(date),
      description,
      documentId: id,
      drawId: selectedDrawId,
      grossAmount: formatCurrency(grossAmount),
      isBackup: !!isBackup,
      lineItems,
      netAmount: formatCurrency(netAmount),
      number: isBlank(number) ? null : number,
      projectId,
      retainageAmount: formatCurrency(retainageAmount),
      trackedAgreementAmounts: isBackup
        ? []
        : formatAgreementAmounts(agreementAmounts, vendor.id),
      type,
      vendorId: vendor.id,
      ...(invoiceId && { id: invoiceId }),
    };
  }

  function prepareAssignLienWaiverVariables(values) {
    const {
      assignedUser,
      description,
      id,
      isFinal,
      lienWaiverId,
      target,
      type,
    } = values;

    return {
      assignedUserId: get(assignedUser, "id", null),
      description,
      drawId: selectedDrawId,
      documentId: id,
      invoiceId: target.id,
      isFinal,
      projectId,
      type,
      ...(lienWaiverId && { id: lienWaiverId }),
    };
  }

  function prepareLienWaiverVariables(values) {
    const {
      assignedUser,
      description,
      id,
      isFinal,
      lienWaiverId,
      manualTargetDraw,
      type,
      vendor,
    } = values;

    const lineItems = values.lineItems.map(({ amount, lineItemObject }) => ({
      amount: formatCurrency(amount),
      lineItemId: lineItemObject.id,
      name: lineItemObject.name,
    }));

    return {
      assignedDrawId: manualTargetDraw,
      assignedUserId: get(assignedUser, "id", null),
      description,
      documentId: id,
      drawId: selectedDrawId,
      isFinal,
      lineItems,
      projectId,
      type,
      vendorId: vendor.id,
      ...(lienWaiverId && { id: lienWaiverId }),
    };
  }

  function prepareAgreementVariables(values) {
    const {
      agreementDescription,
      agreementId,
      agreementLineItems,
      agreementNumber,
      budgetAdjustmentDrawId,
      budgetAdjustmentTransactions,
      changeOrderReason,
      correlationId,
      date,
      daysImpacted,
      description,
      documentId,
      draw,
      inspectionNote,
      name,
      userTouchedName,
      vendor,
    } = values;
    // drawId refers to the target for the document, which can differ from a selected budgetAdjustmentDrawId
    // If no budgetAdjustmentDrawId was captured, use the document's drawId (or null if there isnt an adjustment)

    const variables = {
      ...(agreementId && { agreementId }),
      agreementDescription,
      agreementLineItems: formatAgreementLineItems(agreementLineItems),
      agreementNumber,
      budgetAdjustmentDrawId: budgetAdjustmentDrawId || get(draw, "id", null),
      budgetAdjustmentTransactions: formatTransactions(
        budgetAdjustmentTransactions
      ),
      changeOrderReason: CHANGE_ORDER_TYPES.includes(selectedType)
        ? changeOrderReason
        : null,
      correlationId,
      daysImpacted: isBlank(daysImpacted) ? null : unformatNumber(daysImpacted),
      documentDescription: description,
      documentId,
      drawId: get(draw, "id", null),
      inspectionNote,
      moveDocumentToDraw: false,
      name,
      userTouchedName,
      newLineItems: formatNewLineItems(budgetAdjustmentTransactions),
      projectId,
      startDate: dateFormToServer(date),
      type: selectedType,
      vendorId: get(vendor, "id"),
    };
    return variables;
  }

  function getVariablesFromFormValues(values) {
    const data = {
      ...ReviewForm.getDocumentDataFromForm(values, hasPermission, document),
      description: values.description,
    };
    const vendorId = ReviewForm.getVendorIdFromForm
      ? ReviewForm.getVendorIdFromForm(values)
      : get(values, "vendor.id", null);

    return {
      data: JSON.stringify(data),
      documentId: document.id,
      drawId:
        selectedType !== DOCUMENT_TYPE_NAME.AGREEMENT && selectedDrawId
          ? selectedDrawId
          : null,
      type: selectedType,
      cancelParsing:
        cancelParsing || !PARSABLE_DOCUMENT_TYPES.includes(selectedType),
      vendorId,
      ...(hasPermission(PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE) && {
        autoCalculateRetainage: values.autoCalculateRetainage,
      }),
    };
  }

  function getActionToPerform(
    formValues,
    initialDocumentType,
    assignLienWaiver
  ) {
    const { type: documentType, agreementId } = formValues;
    // A user making changes in the View Uploads Auto Classify flow
    // or future document classification results could result in
    // an agreement document that isn't properly linked to an agreement
    // if wasLinkedToAgreement is false, any document save operations
    // will create the agreement
    const wasLinkedToAgreement = agreementId !== undefined;
    const wasInvoice =
      initialDocumentType === "INVOICE" ||
      initialDocumentType === "PAY_APPLICATION";
    const isNowInvoice =
      documentType === "INVOICE" || documentType === "PAY_APPLICATION";
    const wasLienWaiver =
      initialDocumentType === "CONDITIONAL_LIEN_RELEASE" ||
      initialDocumentType === "UNCONDITIONAL_LIEN_RELEASE";
    const isNowLienWaiver =
      documentType === "CONDITIONAL_LIEN_RELEASE" ||
      documentType === "UNCONDITIONAL_LIEN_RELEASE";
    const isStillInvoice = wasInvoice && isNowInvoice;
    const isStillLienWaiver = wasLienWaiver && isNowLienWaiver;
    const assignLienWaiverToInvoice = isNowLienWaiver && assignLienWaiver;
    const wasAgreement = values(AGREEMENT_TYPE).includes(initialDocumentType);
    const isAgreement = values(AGREEMENT_TYPE).includes(documentType);
    const isStillSameAgreementType =
      isAgreement && documentType === initialDocumentType;
    const agreementTypeHasChanged =
      wasAgreement && isAgreement && !isStillSameAgreementType;

    if (isStillLienWaiver && assignLienWaiverToInvoice) {
      return DOCUMENT_ACTIONS.ASSIGN_LIEN_WAIVER;
    }
    if (isStillLienWaiver) {
      return DOCUMENT_ACTIONS.UPDATE_LIEN_WAIVER;
    }
    if (isNowLienWaiver && assignLienWaiverToInvoice) {
      return DOCUMENT_ACTIONS.CREATE_AND_ASSIGN_LIEN_WAIVER;
    }
    if (isNowLienWaiver) {
      return DOCUMENT_ACTIONS.CREATE_LIEN_WAIVER;
    }

    if (isStillInvoice) {
      if (documentType === "INVOICE") return DOCUMENT_ACTIONS.UPDATE_INVOICE;
      if (documentType === "PAY_APPLICATION")
        return DOCUMENT_ACTIONS.UPDATE_PAY_APPLICATION;
    }
    if (isNowInvoice) {
      if (documentType === "INVOICE") return DOCUMENT_ACTIONS.CREATE_INVOICE;
      if (documentType === "PAY_APPLICATION")
        return DOCUMENT_ACTIONS.CREATE_PAY_APPLICATION;
    }

    if (isStillSameAgreementType && wasLinkedToAgreement) {
      return DOCUMENT_ACTIONS.UPDATE_AGREEMENT;
    }
    if (agreementTypeHasChanged && wasLinkedToAgreement) {
      return DOCUMENT_ACTIONS.CHANGE_AGREEMENT_TYPE;
    }
    if (isAgreement) {
      return DOCUMENT_ACTIONS.CREATE_AGREEMENT;
    }

    return DOCUMENT_ACTIONS.CORRECT_DOCUMENT;
  }

  function getDocumentMutation(actionToPerform) {
    switch (actionToPerform) {
      case DOCUMENT_ACTIONS.ASSIGN_LIEN_WAIVER:
        return assignLienWaiver;

      case DOCUMENT_ACTIONS.CREATE_AND_ASSIGN_LIEN_WAIVER:
        return createAndAssignLienWaiver;

      case DOCUMENT_ACTIONS.CREATE_INVOICE:
        return createInvoice;

      case DOCUMENT_ACTIONS.CREATE_PAY_APPLICATION:
        return createInvoice;

      case DOCUMENT_ACTIONS.CREATE_LIEN_WAIVER:
        return createLienWaiver;

      case DOCUMENT_ACTIONS.UPDATE_LIEN_WAIVER:
        return updateLienWaiver;

      case DOCUMENT_ACTIONS.UPDATE_INVOICE:
        return updateInvoice;

      case DOCUMENT_ACTIONS.UPDATE_PAY_APPLICATION:
        return updateInvoice;

      case DOCUMENT_ACTIONS.CHANGE_AGREEMENT_TYPE:
      case DOCUMENT_ACTIONS.CREATE_AGREEMENT:
        return createAgreement;

      case DOCUMENT_ACTIONS.UPDATE_AGREEMENT:
        return updateAgreement;

      case DOCUMENT_ACTIONS.CORRECT_DOCUMENT:
        return onCorrect;

      default:
        return onCorrect;
    }
  }

  function getVariables(actionToPerform, values) {
    switch (actionToPerform) {
      case DOCUMENT_ACTIONS.ASSIGN_LIEN_WAIVER:
        return prepareAssignLienWaiverVariables(values);

      case DOCUMENT_ACTIONS.CREATE_INVOICE:
        return prepareInvoiceVariables(values);

      case DOCUMENT_ACTIONS.CREATE_AND_ASSIGN_LIEN_WAIVER:
        return prepareAssignLienWaiverVariables(values);

      case DOCUMENT_ACTIONS.CREATE_LIEN_WAIVER:
        return prepareLienWaiverVariables(values);

      case DOCUMENT_ACTIONS.CREATE_PAY_APPLICATION:
        return preparePayApplicationVariables(values);

      case DOCUMENT_ACTIONS.UPDATE_INVOICE:
        return prepareInvoiceVariables(values);

      case DOCUMENT_ACTIONS.UPDATE_LIEN_WAIVER:
        return prepareLienWaiverVariables(values);

      case DOCUMENT_ACTIONS.UPDATE_PAY_APPLICATION:
        return preparePayApplicationVariables(values);

      case DOCUMENT_ACTIONS.CHANGE_AGREEMENT_TYPE:
      case DOCUMENT_ACTIONS.CREATE_AGREEMENT:
      case DOCUMENT_ACTIONS.UPDATE_AGREEMENT:
        return prepareAgreementVariables(values);

      case DOCUMENT_ACTIONS.CORRECT_DOCUMENT:
        return getVariablesFromFormValues(values);

      default:
        return getVariablesFromFormValues(values);
    }
  }

  function handleSubmit(values) {
    const assignLienWaiver = !!get(values, "target.id") && !values.isManual;
    const actionToPerform = getActionToPerform(
      values,
      document.type,
      assignLienWaiver
    );

    const {
      budgetAdjustmentDrawId,
      budgetAdjustmentTransactions,
      type,
    } = values;
    if (
      !budgetAdjustmentDrawId &&
      !selectedDrawId &&
      type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER &&
      budgetAdjustmentTransactions.length > 0
    ) {
      return setAssignAdjustmentToDrawModalOpen(true);
    }

    const updateDocument = getDocumentMutation(actionToPerform);
    const variables = getVariables(actionToPerform, values);

    updateDocument({
      variables,
      context: { ...analyticsContext, documentType: document.type },
      onCompleted: () => {
        // Show a toast with the delta for the requested amount and the new draw total
        const requestedAmountDifference = getRequestedAmountDifference(
          values,
          initialValues,
          hasPermission,
          documentReclassified,
          document
        );

        if (selectedDraw && requestedAmountDifference !== 0) {
          // This is only applicable for invoices and pay applications
          const doNotAutoCalculateLineItemNames = uniq(
            get(values, "lineItems", [])
              .filter((lineItem) => {
                const drawLineItem = get(values, "draw.lineItems", []).find(
                  ({ id }) => get(lineItem, "lineItemObject.id") === id
                );
                return (
                  get(lineItem, "lineItemObject.id") &&
                  get(drawLineItem, "setManually")
                );
              })
              .map(
                ({ lineItemObject }) =>
                  lineItemObject.descriptionOfWork || lineItemObject.name
              )
          );

          const numberOfDoNotAutoCalculateLineItems =
            doNotAutoCalculateLineItemNames.length;

          const newDrawTotal = formatCurrency(
            add(
              get(selectedDraw, "requestedAmount", 0),
              requestedAmountDifference
            )
          );
          const differenceOperator = requestedAmountDifference > 0 ? "+" : "-";
          const formattedDifference = `${differenceOperator}${formatCurrency(
            Math.abs(requestedAmountDifference)
          )}`;
          const toastMessage = (
            <Pane data-testid="documentSaveToast">
              {selectedDraw.name}
              <Text
                marginLeft={majorScale(2)}
                fontSize={14}
                fontWeight={theme.fontWeights.DEMI}
                fontStyle="italic"
              >
                {formattedDifference}
              </Text>
              <Text fontSize={14} fontWeight={theme.fontWeights.DEMI}>
                {" "}
                = {newDrawTotal}
              </Text>
              {numberOfDoNotAutoCalculateLineItems > 0 && (
                <Pane>
                  <Text>
                    {t("documentReview.lineItemSetManually", {
                      count: numberOfDoNotAutoCalculateLineItems,
                      lineItems: humanReadableList(
                        doNotAutoCalculateLineItemNames
                      ),
                    })}
                  </Text>
                </Pane>
              )}
            </Pane>
          );

          toaster.notify(toastMessage, {
            duration: 5,
          });
        }
      },
    });
    return analytics.track("Document Updated", documentValues(document));
  }

  function handleApprove({ resetForm }) {
    onApprove({
      context: { ...analyticsContext, documentType: document.type },
      variables: { documentIds: [document.id] },
      onCompleted: resetForm,
    });
  }

  function handleUndoApprove({ resetForm }) {
    onUndoApprove({
      context: { ...analyticsContext, documentType: document.type },
      variables: { documentIds: [document.id] },
      onCompleted: resetForm,
    });
  }

  function handleMarkPaid(values) {
    markPaid({
      variables: {
        documentId: document.id,
        amountPaid: values.amountPaid,
        datePaid: dateFormToServer(values.datePaid),
      },
    });
  }

  function handleMarkUnpaid() {
    markUnpaid({
      variables: {
        documentId: document.id,
      },
    });
  }

  function handleMarkReviewed() {
    onMarkReviewed({ variables: { documentIds: [document.id] } });
  }

  function handleDelete(otherArgs = {}) {
    onRemove({ variables: { documentIds: [document.id], ...otherArgs } });
    navigateOnDelete();
  }

  function handleIgnore({ resetForm }) {
    onIgnore({
      variables: { documentIds: [document.id] },
      onCompleted: resetForm,
    });
  }

  function handleChangeType(type) {
    if (
      type === DOCUMENT_TYPE_NAME.INSPECTION_IMAGE &&
      !validInspectionImageTypes.includes(documentMimeType)
    ) {
      setSelectedType(DOCUMENT_TYPE_NAME.UNKNOWN);
      setIncompatibleImageModalOpen(true);
      return;
    }
    setSelectedType(type);
    setCancelParsing(false);
  }

  const projectName = get(document, "project.name", "");
  const projectId = get(document, "project.id", "");
  const projectDrawUpdateSource = get(document, "project.drawUpdateSource");
  const documentIgnored = isIgnored(document);
  const mutationLoading =
    addGeneralContractorResult.loading ||
    approveResult.loading ||
    correctResult.loading ||
    updateInvoiceLoading ||
    createInvoiceLoading ||
    createAgreementLoading ||
    createAndAssignLienWaiverLoading ||
    createLienWaiverLoading ||
    updateLienWaiverLoading ||
    assignLienWaiverLoading ||
    markDocumentPaidLoading ||
    markDocumentUnpaidLoading ||
    ignoreResult.loading ||
    removeResult.loading ||
    undoApproveResult.loading ||
    updateAgreementLoading;

  const usePerformik = ReviewForm === ContractorsRequisitionReview;

  const contextData = {
    cardsOpen,
    collapsedPanels,
    documentIgnored,
    initialValues,
    mutationLoading,
    projectDrawUpdateSource,
    projectId,
    projectName,
    selectedDraw,
    selectedType,
    usePerformik,
  };

  const contextState = {
    assignAdjustmentToDrawModalOpen,
    cancelParsing,
    cannotChangeTypeHasAdjustmentModalOpen,
    cardsOpen,
    confirmChangeAgreementTypeModalOpen,
    incompatibleImageModalOpen,
    newlyAddedVendors,
    selectedDrawId,
    showUserLockdownDrawConfirm,
    willDeleteAdjustmentModalOpen,
  };

  const contextSetters = {
    setAssignAdjustmentToDrawModalOpen,
    setCancelParsing,
    setCannotChangeTypeHasAdjustmentModalOpen,
    setCardsOpen,
    setConfirmChangeAgreementTypeModalOpen,
    setIncompatibleImageModalOpen,
    setNewlyAddedVendors,
    setSelectedDrawId,
    setShowUserLockdownDrawConfirm,
    setViewerDirty,
    setWillDeleteAdjustmentModalOpen,
  };

  const contextBehaviors = {
    handleApprove,
    handleChangeType,
    handleDelete,
    handleIgnore,
    handleMarkReviewed,
    handleSubmit,
    handleMarkPaid,
    handleMarkUnpaid,
    handleUndoApprove,
    togglePanel,
    // TODO: get rid of dot notation
    validate: ReviewForm.validate,
  };

  const contextComponents = {
    FormSelect: usePerformik ? Form.NewSelect : Form.Select,
    FormTextArea: usePerformik ? Form.NewTextArea : Form.TextArea,
    ReviewForm,
  };

  return (
    <DocumentFormContext.Provider
      value={{
        ...contextBehaviors,
        ...contextComponents,
        ...contextData,
        ...contextSetters,
        ...contextState,
      }}
    >
      {children}
    </DocumentFormContext.Provider>
  );
}

export const useDocumentFormContext = () => useContext(DocumentFormContext);

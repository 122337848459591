import { get, set, omit } from "lodash";
import { Form, Pane } from "components/materials";
import { VendorLink } from "components/templates";
import { formatCurrency } from "helpers/formatCurrency";
import { majorScale } from "helpers/utilities";
import { add } from "helpers/math";
import {
  getAmountToUse,
  getAmountDueThisRequisition,
  getDocumentGrossAmount,
  getEmptyLineItem,
  getLessPreviousPayments,
  getLessRetainedAmount,
  getMatch,
  getMatchedLineItems,
  getTotalAmount,
  matchLineItem,
} from "helpers/hud92464Helpers";
import DocumentReviewLayout from "./DocumentReviewLayout";
import { TableSection92464 } from "./Hud92464/TableSection92464";

function formatLineItem(lineItem, index) {
  return {
    ...lineItem,
    lineItemIndex: index,
    amountsCompleted: formatCurrency(get(lineItem, "amountsCompleted")),
    hudApprovedAmount: formatCurrency(get(lineItem, "hudApprovedAmount")),
  };
}

function getInitialValues({ document, selectedDraw, selectedType }) {
  // Todo(Andrew): This is needed because of the ...restOfDocument. The form will reset if the any of the initial values change.
  // Without this, the file can get a new URL (because it's pre-signed), which causes the form to reset.
  // Ideally, we'd just select the properties we _need_, rather than ...restOfDocument.
  const documentProperties = omit(document, ["file", "upload.file"]);
  const initialValues = {
    ...documentProperties,
    draw: {
      id: selectedDraw?.id,
      name: selectedDraw.name,
      isLockedDown: selectedDraw.isLockedDown,
      lineItems: selectedDraw.lineItems,
    },
    type: selectedType,
    lineItems:
      document.lineItems && document.lineItems.length > 0
        ? document.lineItems
            .map(
              matchLineItem(
                selectedDraw ? selectedDraw.lineItems : [],
                selectedDraw,
                document
              )
            )
            .map((lineItem, index) =>
              formatLineItem(lineItem, index, selectedDraw, document.vendor)
            )
        : [getEmptyLineItem(0)],
  };

  return initialValues;
}

function formatDataLineItems(values) {
  if (!values.lineItems) return undefined;

  return values.lineItems.map((lineItem) => {
    const hello = {
      ...lineItem.lineItemObject,
      ...lineItem,
    };

    return hello;
  });
}

function getDocumentDataFromForm(values) {
  const matchedLineItems = getMatchedLineItems(
    values.lineItems,
    values.draw.lineItems
  );
  const total = getTotalAmount(values.lineItems);
  const lessRetained = getLessRetainedAmount(matchedLineItems);
  const lessPreviousPayments = getLessPreviousPayments(matchedLineItems);
  return {
    ...values,
    amountRequested: formatCurrency(values.amountRequested),
    documentGrossAmount: getDocumentGrossAmount(total, lessPreviousPayments),
    documentNetAmount: getAmountDueThisRequisition(
      total,
      lessPreviousPayments,
      lessRetained
    ),
    lineItems: formatDataLineItems(values),
  };
}

function validate(values) {
  const errors = {};

  const drawLineItemTotals = values.lineItems.reduce((acc, lineItem) => {
    const accAmount = acc[lineItem.lineItemObject.drawLineItemId] || 0;
    return {
      ...acc,
      [lineItem.lineItemObject.drawLineItemId]: add(
        accAmount,
        getAmountToUse(lineItem)
      ),
    };
  }, {});

  values.lineItems.forEach((lineItem, index) => {
    const match = getMatch(values.draw.lineItems, lineItem);

    if (
      match?.requestedPreviouslyAmount >
      drawLineItemTotals[lineItem.lineItemObject.drawLineItemId]
    ) {
      set(
        errors,
        `lineItems.${index}`,
        "The total amount for the line item must be greater than previous draws' line item requested amount."
      );
    }
  });

  return errors;
}

export function Hud92464Review(props) {
  const {
    allAvailableVendors,
    collapsedPanels,
    form,
    selectedDraw,
    togglePanel,
  } = props;

  const lockdownDraw = get(selectedDraw, "isLockedDown");

  return (
    <DocumentReviewLayout
      lockdownDraw={lockdownDraw}
      information={(content) => (
        <Pane display="flex" flexDirection="column" width="100%">
          <Pane display="flex" flexDirection="row" flexWrap="wrap">
            {content}
            <Pane flexGrow={1} marginRight={majorScale(2)}>
              <Form.Input
                label="Advance Number"
                name="advanceNumber"
                type="text"
                disabled={lockdownDraw}
              />
            </Pane>
            <Pane flexGrow={1}>
              <Form.Input
                label="Payment Amount Requested"
                name="amountRequested"
                type="currency"
                disabled={lockdownDraw}
              />
            </Pane>
          </Pane>
          <Pane display="flex" justifyContent="flex-start" alignItems="center">
            <VendorLink
              vendorId={form.values.vendor.id}
              vendors={allAvailableVendors}
            />
          </Pane>
        </Pane>
      )}
      form={form}
      {...props}
      handleCancelParsing={false}
      requireSaveToParse
    >
      <TableSection92464
        collapsedPanels={collapsedPanels}
        disableForm={lockdownDraw}
        draw={selectedDraw}
        form={form}
        togglePanel={togglePanel}
      />
    </DocumentReviewLayout>
  );
}

Hud92464Review.getInitialValues = getInitialValues;
Hud92464Review.validate = validate;
Hud92464Review.getDocumentDataFromForm = getDocumentDataFromForm;

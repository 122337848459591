import gql from "graphql-tag";
import { get, has } from "lodash";

// Bulk Updates to Documents via the Toolbar in Project/Draw Documents would
// update their respective tables via their mutation result interacting with the cache.
// The mutations return [Document], and will thus never update the cache of [FastDocument],
// so transform and merge the changes in from these mutations manually using writeFragment

// Now utilized to handle documents post split, as least until DocProc work is complete
// Bug was: docs unchanged auto-split->user-split were not
// registering their parent's toBeSplit change

const UPDATE_FAST_DOCUMENT_CACHE = gql`
  fragment updateFastDocumentCache on FastDocument {
    id
    assignedUser
    recentApprovalReviews {
      id
      userId
      userName
      reviewType
      insertedAt
    }
    draw
    drawId
    isBackup
    parentToBeSplit
  }
`;

const bulkDocumentOperations = [
  {
    mutationName: "approveDocuments",
    // if the approver is the assignee, assignment is removed
    getValuesFromDocument: (document) => ({
      recentApprovalReviews: get(document, "recentApprovalReviews", []),
      assignedUser: get(document, "assignedUser.fullName", null),
    }),
  },
  {
    mutationName: "assignUserToDocuments",
    getValuesFromDocument: (document) => ({
      assignedUser: get(document, "assignedUser.fullName", null),
    }),
  },
  {
    mutationName: "markDocumentsAsBackup",
    getValuesFromDocument: (document) => ({
      isBackup: get(document, "isBackup"),
    }),
  },
  {
    mutationName: "moveDocumentsToDraw",
    getValuesFromDocument: (document) => ({
      draw: get(document, "draw.name", null),
      drawId: get(document, "draw.id", null),
    }),
  },
  {
    mutationName: "splitUpload",
    getValuesFromDocument: (_document) => ({
      parentToBeSplit: false,
    }),
  },
];

// This function will be called as the cache update function for each of the
// mutations executed from the BulkDocument toolbar / Splitter, and properly update the
// cache of FastDocument powering the underlying table

export const updateFastDocumentCache = (apolloStore, mutationResult) => {
  const cacheUpdates = bulkDocumentOperations.reduce(
    (acc, { getValuesFromDocument, mutationName }) => {
      if (!has(mutationResult, mutationName)) return acc;

      return [
        ...acc,
        ...mutationResult[mutationName].map((document) => ({
          id: document.id,
          updates: {
            ...getValuesFromDocument(document),
          },
        })),
      ];
    },
    []
  );

  cacheUpdates.forEach(({ id, updates }) =>
    apolloStore.writeFragment({
      data: {
        ...updates,
        __typename: "FastDocument",
      },
      fragment: UPDATE_FAST_DOCUMENT_CACHE,
      id: `FastDocument:${id}`,
    })
  );
};

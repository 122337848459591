import { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { get } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import { Frozen } from "helpers/behaviors";
import {
  UPLOADS_PROCESSING_FINISHED_FRAGMENT,
  UPLOADS_VIEWER_FRAGMENT,
} from "helpers/fragments";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { UploadsViewer } from "./UploadsViewer";

const DRAW_QUERY = gql`
  query UploadManagerDrawQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      name
      draw(id: $drawId) {
        id
        name
        uploads {
          ...UploadsViewerFragment
        }
      }
      organization {
        id
      }
      vendors {
        id
        name
      }
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

const PROJECT_QUERY = gql`
  query UploadManagerProjectQuery($projectId: String!) {
    project(id: $projectId) {
      id
      name
      draws {
        id
        name
      }
      organization {
        id
      }
      vendors {
        id
        name
      }
      uploads {
        ...UploadsViewerFragment
      }
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

const DRAW_UPLOADS_PROCESSING = gql`
  query DrawUploadsProcessingQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        uploads {
          ...UploadsProcessingFinishedFragment
        }
      }
    }
  }
  ${UPLOADS_PROCESSING_FINISHED_FRAGMENT}
`;

const PROJECT_UPLOADS_PROCESSING = gql`
  query ProjectUploadsProcessingQuery($projectId: String!) {
    project(id: $projectId) {
      id
      uploads {
        ...UploadsProcessingFinishedFragment
      }
    }
  }
  ${UPLOADS_PROCESSING_FINISHED_FRAGMENT}
`;

export function UploadManager({ drawId, onClose, projectId, uploadId }) {
  const { data, loading } = useQuery(drawId ? DRAW_QUERY : PROJECT_QUERY, {
    variables: { drawId, projectId },
  });
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );

  // using the features of the apollo cache to poll for the document type and processingFinishedAt
  // subquery underneath `documents` must match the UploadsViewerFragment for the cache to update properly
  useQuery(drawId ? DRAW_UPLOADS_PROCESSING : PROJECT_UPLOADS_PROCESSING, {
    pollInterval: 5000,
    variables: { drawId, projectId },
  });

  if (loading || !data) {
    return <Loadable loading />;
  }

  return (
    <Frozen projectId={projectId}>
      {({ frozenError, frozenSubmit }) => (
        <UploadsViewer
          context={drawId ? "draw" : "project"}
          data={data}
          frozenError={frozenError}
          frozenSubmit={frozenSubmit}
          getProjectVendorSearchQuery={getProjectVendorSearchQuery}
          hasCloseAnimation
          newlyAddedVendors={newlyAddedVendors}
          onClose={onClose}
          projectId={projectId}
          refetchQueries={[
            {
              query: drawId ? DRAW_QUERY : PROJECT_QUERY,
              variables: { drawId, projectId },
            },
          ]}
          searchedVendors={get(
            projectVendorSearchQuery,
            "data.project.organization.paginatedVendors.results",
            []
          )}
          selectedId={uploadId}
          setNewlyAddedVendors={setNewlyAddedVendors}
        />
      )}
    </Frozen>
  );
}

UploadManager.propTypes = {
  drawId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  uploadId: PropTypes.string,
};

import { useContext } from "react";
import { AddIcon, CrossIcon } from "evergreen-ui";
import {
  Button,
  DragAndDrop,
  Pane,
  Paragraph,
  Select,
  SelectMenu,
  Text,
} from "components/materials";
import t from "helpers/translate";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { range, xor } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";

function prepareAddUserOptions(users) {
  return Object.values(users)
    .filter(({ canApproveDocuments, viewAll, canAccessProject }) => {
      const hasProjectAccess = viewAll || canAccessProject;
      return canApproveDocuments && hasProjectAccess;
    })
    .map(({ id: userId, fullName }) => ({
      key: userId,
      label: fullName,
      value: userId,
    }));
}

function prepareDocumentReviewerDragItems(users, documentReviewerIds) {
  return documentReviewerIds.map((userId) => ({
    canRemove: true,
    isDraggable: true,
    key: userId,
    email: users[userId].email,
    fullName: users[userId].fullName,
    approvalAmountLimit: users[userId].approvalAmountLimit,
    approvalAmountMinimum: users[userId].approvalAmountMinimum,
    value: userId,
  }));
}

function DocumentReviewerDragItemContent({
  item: user,
  index,
  onRemove,
  handleManualOrderSelection,
  documentReviewersCount,
}) {
  return (
    <Pane display="flex" alignItems="center" width="100%">
      <Pane flexGrow="1" display="flex" alignItems="center">
        <Pane
          width="50%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          paddingX={majorScale(1)}
        >
          <Paragraph marginBottom={-minorScale(1)} fontWeight={500}>
            {user.fullName}
          </Paragraph>
          <Pane display="flex">
            {user.approvalAmountMinimum !== null && (
              <Paragraph
                fontSize={10}
                color="muted"
                marginRight={minorScale(1)}
              >
                {`More than ${formatCurrency(user.approvalAmountMinimum)}`}
                {user.approvalAmountLimit !== null && ","}
              </Paragraph>
            )}
            {user.approvalAmountLimit !== null && (
              <Paragraph fontSize={10} color="muted">
                {`Up to ${formatCurrency(user.approvalAmountLimit)}`}
              </Paragraph>
            )}
          </Pane>
        </Pane>
        <Paragraph
          width="50%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {user.email}
        </Paragraph>
      </Pane>
      <Paragraph width="175px" textAlign="center">
        <Select
          defaultValue={index}
          appearance="minimal"
          onChange={(e) =>
            handleManualOrderSelection(user.key, index, e.target.value)
          }
          border="none"
        >
          {range(documentReviewersCount).map((position) => (
            <option value={position}>{position + 1}</option>
          ))}
        </Select>
      </Paragraph>
      <Pane
        width="200px"
        display="flex"
        alignItems="center"
        justifyContent="end"
        paddingRight={majorScale(4)}
      >
        <Pane onClick={() => onRemove(user.key)} cursor="pointer">
          <CrossIcon size={12} color="danger" />
          <Text marginLeft={majorScale(1)} color="danger">
            Remove
          </Text>
        </Pane>
      </Pane>
    </Pane>
  );
}

function AddDocumentReviewers({
  users,
  documentReviewerIds,
  handleToggleDocumentReviewer,
}) {
  const theme = useContext(ThemeContext);
  return (
    <SelectMenu
      isMultiSelect
      options={prepareAddUserOptions(users)}
      selected={documentReviewerIds}
      onSelect={handleToggleDocumentReviewer}
      onDeselect={handleToggleDocumentReviewer}
      hasTitle={false}
      emptyView={({ close }) => (
        <Pane
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text size={300} marginBottom={minorScale(1)}>
            No users
          </Text>
          <Text size={300} onClick={close} cursor="pointer">
            Close
          </Text>
        </Pane>
      )}
    >
      <Button
        iconBefore={AddIcon}
        appearance="minimal"
        color={theme.colors.blue600}
      >
        Add Reviewers
      </Button>
    </SelectMenu>
  );
}

export function EditDocumentReviewers({
  users,
  documentReviewerIds,
  setFieldValue,
}) {
  function handleToggleDocumentReviewer({ value: userId }) {
    setFieldValue("documentReviewerIds", xor(documentReviewerIds, [userId]));
  }

  function handleUpdateDocumentReviewers(documentReviewers) {
    setFieldValue(
      "documentReviewerIds",
      documentReviewers.map(({ value: userId }) => userId)
    );
  }

  const documentReviewersCount = documentReviewerIds.length;

  return (
    <Pane width="80%">
      <Paragraph>{t("projectSettings.userAccess.documentReviewers")}</Paragraph>
      <Paragraph marginBottom={majorScale(4)}>
        {t("projectSettings.userAccess.documentReviewersNote")}
      </Paragraph>
      <Pane>
        <Pane
          borderBottom="2px solid #d7e0ea"
          display="flex"
          alignItems="center"
        >
          <Pane width={majorScale(6)} />
          <Pane flexGrow="1" display="flex" alignItems="center">
            <Paragraph width="50%" fontWeight={500} paddingLeft={majorScale(1)}>
              Document Reviewer
            </Paragraph>
            <Paragraph width="50%" fontWeight={500}>
              Email
            </Paragraph>
          </Pane>
          <Paragraph width="175px" fontWeight={500} textAlign="center">
            Approval Order
          </Paragraph>
          <Pane width="200px" textAlign="right" paddingRight={majorScale(2)}>
            <AddDocumentReviewers
              users={users}
              documentReviewerIds={documentReviewerIds}
              handleToggleDocumentReviewer={handleToggleDocumentReviewer}
            />
          </Pane>
        </Pane>
        {documentReviewerIds.length > 0 ? (
          <DragAndDrop
            items={prepareDocumentReviewerDragItems(users, documentReviewerIds)}
            onUpdate={handleUpdateDocumentReviewers}
            onRemove={handleToggleDocumentReviewer}
            itemHeight={majorScale(6)}
          >
            {({ item, index, onRemove, handleManualOrderSelection }) => (
              <DocumentReviewerDragItemContent
                item={item}
                index={index}
                onRemove={onRemove}
                handleManualOrderSelection={handleManualOrderSelection}
                documentReviewersCount={documentReviewersCount}
              />
            )}
          </DragAndDrop>
        ) : (
          <Paragraph width="100%" textAlign="center" marginTop={majorScale(1)}>
            No Document Reviewers selected. Add Document Reviewers above.
          </Paragraph>
        )}
      </Pane>
    </Pane>
  );
}

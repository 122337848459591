import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { hover } from "glamor";
import { ArrowRightIcon } from "evergreen-ui";
import {
  camelCase,
  filter as lodashFilter,
  flatMap,
  keys,
  orderBy,
  set,
  cloneDeep,
} from "lodash";
import {
  ConfigureButtons,
  Pane,
  Shortener,
  Tab,
  Tablist,
  Text,
} from "components/materials";
import { LINE_ITEM_TYPE, ORGANIZATION_TYPE } from "helpers/enums";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { divide, sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { formatNumber } from "helpers/formatNumber";
import t from "helpers/translate";
import { getDefaultViews as getProjectReportDefaultViews } from "components/containers/ReportsProjectsPage";
import { fromBase64, toBase64 } from "components/materials/FastDataTable";
import { BlankSlate, BlankSlateType } from "./BlankSlate";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";
import { trackClickthrough } from "./helpers";

export const PROJECT_COSTS_CONFIGURATION_SETTINGS = {
  i: "projectCosts",
  x: 0,
  y: 7,
  w: 1,
  h: 1,
  disabled: false,
};

const TYPE_OPTIONS = {
  ACRES: "acres",
  SQUARE_FEET: "squareFeet",
};

const LINE_ITEM_CATEGORIES = {
  [LINE_ITEM_TYPE.ALLOWANCES]: LINE_ITEM_TYPE.ALLOWANCES,
  [LINE_ITEM_TYPE.CONTINGENCY]: LINE_ITEM_TYPE.CONTINGENCY,
  [LINE_ITEM_TYPE.DEVELOPER_FEES]: LINE_ITEM_TYPE.DEVELOPER_FEES,
  [LINE_ITEM_TYPE.FEES]: LINE_ITEM_TYPE.FEES,
  [LINE_ITEM_TYPE.FINANCING]: LINE_ITEM_TYPE.FINANCING,
  [LINE_ITEM_TYPE.INTEREST_RESERVES]: LINE_ITEM_TYPE.INTEREST_RESERVES,
  [LINE_ITEM_TYPE.LAND_ACQUISITION]: LINE_ITEM_TYPE.LAND_ACQUISITION,
  [LINE_ITEM_TYPE.TENANT_IMPROVEMENTS]: LINE_ITEM_TYPE.TENANT_IMPROVEMENTS,
  NONE: "NONE",
};

function getCategoryTotal(lineItems, category) {
  let filteredLineItems;
  if (category === LINE_ITEM_CATEGORIES.NONE) {
    filteredLineItems = lineItems.filter(({ types }) => {
      const categories = types.filter(
        (type) =>
          type === LINE_ITEM_TYPE.HARD_COSTS ||
          type === LINE_ITEM_TYPE.SOFT_COSTS
      );
      return categories.length === 0;
    });
  } else {
    filteredLineItems = lineItems.filter(({ types }) =>
      types.includes(category)
    );
  }

  return sumBy(filteredLineItems, "budgetAmount");
}

function getCategoryTotals(lineItems) {
  return orderBy(
    Object.keys(LINE_ITEM_CATEGORIES).map((category) => ({
      category,
      total: getCategoryTotal(lineItems, category),
    })),
    "total",
    "desc"
  );
}

function getProjectTotalNumbers(projects) {
  return {
    squareFeet: {
      total: sumBy(projects, "squareFeet"),
      projects: lodashFilter(projects, "squareFeet"),
    },
    acres: {
      total: sumBy(projects, "acres"),
      projects: lodashFilter(projects, "acres"),
    },
  };
}

function CategoryValue({
  categoryAverage,
  href,
  maxWidthAmount,
  name,
  organizationId,
}) {
  const theme = useContext(ThemeContext);

  const linkProps = href
    ? {
        is: Link,
        onClick: () =>
          trackClickthrough("Project Costs to Project Report", organizationId, {
            category: name,
          }),
        textDecoration: "none",
        to: href,
      }
    : {};

  return (
    <Pane display="flex" marginBottom={majorScale(1)} {...linkProps}>
      <Pane
        alignItems="center"
        cursor={href ? "pointer" : null}
        display="flex"
        marginRight={majorScale(2)}
        width={284}
      >
        <Shortener
          className={
            href
              ? hover({
                  textDecoration: "underline",
                })
              : null
          }
          color={theme.colors.linkBlue}
          limit={20}
          size={200}
          text={t(`lineItemTypes.${name}`)}
        />
        {href && (
          <ArrowRightIcon
            appearance="minimal"
            color={theme.colors.linkBlue}
            icon={ArrowRightIcon}
            marginLeft="auto"
          />
        )}
      </Pane>
      <Pane width="100%" display="flex">
        {categoryAverage !== 0 && (
          <Pane
            background={theme.colors.barChartBlue}
            display="flex"
            height={30}
            paddingRight={minorScale(1)}
            width={`${maxWidthAmount}%`}
          />
        )}
        <Pane marginLeft={minorScale(1)} marginTop={minorScale(1)}>
          <Text color={theme.colors.gray700}>
            {formatCurrency(categoryAverage, { shortenedFormat: true })}
          </Text>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectCosts({
  cards,
  isConfigurable,
  isDisabled,
  name,
  organization,
  projects,
  setCards,
}) {
  const theme = useContext(ThemeContext);
  const [dataType, setDataType] = useState(TYPE_OPTIONS.SQUARE_FEET);

  const projectTotals = useMemo(() => getProjectTotalNumbers(projects), [
    projects,
  ]);

  const filteredProjects = projectTotals[dataType].projects;
  const lineItems = flatMap(filteredProjects, "lineItems");

  const totalArea = projectTotals[dataType].total;
  const averageArea = divide(
    projectTotals[dataType].total,
    projectTotals[dataType].projects.length
  );

  const categories = getCategoryTotals(lineItems);

  const showBlankSlate = projects.length === 0;

  const allRelevantReportColumns = keys(LINE_ITEM_CATEGORIES).reduce(
    (acc, cat) => {
      if (cat === LINE_ITEM_CATEGORIES.NONE) return acc;

      const datapoint = camelCase(cat);

      return [...acc, `${datapoint}CostPerSqFt`, `${datapoint}CostPerAcre`];
    },
    []
  );

  const headingLink = getProjectReportLink(
    organization,
    allRelevantReportColumns
  );

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      padding={majorScale(2)}
    >
      {showBlankSlate ? (
        <BlankSlate type={BlankSlateType.ProjectCosts} />
      ) : (
        <React.Fragment>
          <Pane
            display="flex"
            marginBottom={minorScale(3)}
            justifyContent="space-between"
            paddingBottom={majorScale(2)}
          >
            <CardHeading disabled={isDisabled} text="Project Costs" />
            {isConfigurable && (
              <ConfigureButtons
                isDisabled={isDisabled}
                cards={cards}
                setCards={setCards}
                name={name}
              />
            )}
            {!isConfigurable && <CardLinkButton href={headingLink} />}
          </Pane>
          <Pane display="flex" justifyContent="space-between">
            <Pane>
              <Tablist>
                <Tab
                  aria-controls={`panel-${TYPE_OPTIONS.SQUARE_FEET}`}
                  isSelected={dataType === TYPE_OPTIONS.SQUARE_FEET}
                  key={TYPE_OPTIONS.SQUARE_FEET}
                  onSelect={() => setDataType(TYPE_OPTIONS.SQUARE_FEET)}
                  height={majorScale(3)}
                  color={theme.gray700}
                >
                  Sq Ft
                </Tab>
                <Tab
                  aria-controls={`panel=${TYPE_OPTIONS.ACRES}`}
                  isSelected={dataType === TYPE_OPTIONS.ACRES}
                  key={TYPE_OPTIONS.ACRES}
                  onSelect={() => setDataType(TYPE_OPTIONS.ACRES)}
                  height={majorScale(3)}
                  color={theme.gray700}
                >
                  Acreage
                </Tab>
              </Tablist>
            </Pane>
            <Pane>
              <Text paddingRight={minorScale(2)} color={theme.gray700}>
                Avg {dataType === TYPE_OPTIONS.SQUARE_FEET ? "Sq Ft" : "acres"}
              </Text>
              <Text size={600} fontWeight={500} color={theme.gray700}>
                {formatNumber(averageArea, 0)}
              </Text>
            </Pane>
          </Pane>
          <Pane height="62%" marginTop={majorScale(3)}>
            <Pane height="100%" overflowY="scroll">
              {categories.map(({ category, total }) => {
                const categoryAverage = divide(total, totalArea);
                const maxValue = divide(categories[0]?.total, totalArea);
                const maxWidthAmount = divide(categoryAverage, maxValue) * 100;

                const hasLink = category !== LINE_ITEM_CATEGORIES.NONE;
                const relevantReportColumn = `${camelCase(category)}CostPer${
                  dataType === TYPE_OPTIONS.SQUARE_FEET ? "SqFt" : "Acre"
                }`;
                const href = hasLink
                  ? getProjectReportLink(organization, [relevantReportColumn])
                  : null;

                return (
                  <CategoryValue
                    categoryAverage={categoryAverage}
                    href={href}
                    key={category}
                    maxWidthAmount={maxWidthAmount}
                    name={category}
                    organizationId={organization.id}
                  />
                );
              })}
            </Pane>
          </Pane>
          <Pane marginTop={minorScale(3)} textAlign="center">
            <Text fontStyle="italic" color={theme.colors.gray700}>
              Note: The values shown indicate the average Cost/
              {dataType === TYPE_OPTIONS.SQUARE_FEET ? "Sq Ft" : "acre"} for
              each category
            </Text>
          </Pane>
        </React.Fragment>
      )}
    </Pane>
  );
}

function getProjectReportLink(organization, extraColumns) {
  const isDeveloper = organization.type === ORGANIZATION_TYPE.BORROWER;

  const defaultView = getProjectReportDefaultViews(isDeveloper)[0];
  const config = fromBase64(defaultView.config);

  const newColumnConfig = [...config.columnConfig, ...extraColumns];
  const newConfig = set(cloneDeep(config), "columnConfig", newColumnConfig);

  const tableParam = toBase64(newConfig);

  return `/reports/projects?table=${tableParam}&referrerSelectedOrgId=${organization.id}`;
}

import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { AddIcon, HelpIcon, WarningSignIcon } from "evergreen-ui";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Link,
  Paragraph,
  Pane,
  Strong,
  Switch,
  Text,
  Tooltip,
} from "components/materials";
import { VendorFormPartial } from "components/templates";
import t from "helpers/translate";
import { majorScale, minorScale, Position } from "helpers/utilities";
import { stringComparator } from "helpers/comparators";
import {
  cloneDeep,
  get,
  includes,
  intersection,
  union,
  values,
  without,
  xor,
  xorBy,
} from "lodash";
import { ORGANIZATION_TYPE } from "helpers/enums";
import { STAKEHOLDERS_SETTINGS_FRAGMENT } from "helpers/fragments";
import { OrganizationMemberModal } from "./OrganizationMemberModal";

const QUERY = gql`
  query StakeholdersModalQuery($projectId: String!) {
    project(id: $projectId) {
      id
      name
      vendors {
        id
        name
        members {
          id
          name
          email
        }
      }
      stakeholderGroups {
        memberIds
        organizationId
        role
      }
      borrowers {
        id
        members {
          id
        }
      }
    }
  }
`;

export const STAKEHOLDER_VENDOR_SEARCH_QUERY = gql`
  query StakeholderVendorSearchQuery(
    $projectId: String!
    $filters: [FilterInput]
    $pagination: PaginationInput
  ) {
    project(id: $projectId) {
      id
      organization {
        id
        paginatedVendors(filters: $filters, pagination: $pagination) {
          id
          total
          results {
            id
            city
            name
            state
            members {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation StakeholdersModalMutation(
    $memberIds: [String]
    $borrowerMemberIds: [String]
    $organizationId: String!
    $projectId: String!
    $role: OrganizationType!
  ) {
    changeStakeholderGroup(
      memberIds: $memberIds
      borrowerMemberIds: $borrowerMemberIds
      organizationId: $organizationId
      projectId: $projectId
      role: $role
    ) {
      id
      ...StakeholdersSettingsFragment
    }
  }
  ${STAKEHOLDERS_SETTINGS_FRAGMENT}
`;

function getInitialValues(initialOrganizationId) {
  return {
    organization: { id: initialOrganizationId },
  };
}

const getTypeOptions = () =>
  values(ORGANIZATION_TYPE).map((name) => ({
    key: name,
    text: t(`organizationType.${name}`),
    value: name,
  }));

function MemberCheckbox({
  member,
  memberIds,
  getMissingEmailTooltipMessage,
  role,
  disableStakeholder,
}) {
  const tooltipMessage =
    getMissingEmailTooltipMessage && getMissingEmailTooltipMessage(role);
  return (
    <Pane flexGrow={1} display="flex" alignItems="center">
      <Checkbox
        checked={memberIds.includes(member.id)}
        key={member.id}
        label={`${member.name} ${member.email ? `(${member.email})` : ""}`}
        onChange={disableStakeholder}
        marginBottom={0}
        marginTop={0}
      />
      {!member.email && tooltipMessage && (
        <Tooltip content={tooltipMessage} position={Position.BOTTOM_LEFT}>
          <WarningSignIcon
            marginLeft={majorScale(1)}
            size={12}
            color="warning"
          />
        </Tooltip>
      )}
    </Pane>
  );
}

function StakeholdersForm({
  data,
  formikProps,
  getProjectVendorSearchQuery,
  memberIds,
  onClose,
  projectId,
  query,
  getMissingEmailTooltipMessage,
  result,
  role,
  searchedVendors,
  setMemberIds,
  vendorBorrowerMemberIds,
  setVendorBorrowerMemberIds,
  setRole,
  hasBorrowerExperience,
}) {
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [newlyAddedVendors, setNewlyAddedVendors] = useState([]);
  const organizationId = formikProps.values.organization.id;
  const [currentVendorMembers, setCurrentVendorMembers] = useState([]);

  const projectName = get(data, "project.name", []);
  const initialVendors = get(data, "project.vendors", []);

  const projectBorrowerMemberIds = get(
    data,
    "project.borrowers",
    []
  ).flatMap(({ members }) => members.map(({ id }) => id));

  const vendors = initialVendors.concat(newlyAddedVendors);
  const vendor = vendors
    .concat(newlyAddedVendors)
    .find((vendor) => vendor.id === organizationId);
  const vendorMembers = get(vendor, "members", []);
  const vendorMemberIds = vendorMembers.map(({ id }) => id);

  const stakeholderGroups = get(data, "project.stakeholderGroups", []);
  const stakeholderGroup = stakeholderGroups.find(
    (stakeholderGroup) =>
      stakeholderGroup.organizationId === organizationId &&
      stakeholderGroup.role === role
  );

  const currentMemberIds = intersection(
    get(stakeholderGroup, "memberIds", []),
    vendorMemberIds
  );

  useEffect(() => {
    setCurrentVendorMembers(vendorMembers);
    // Reset current vendor members when vendor changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  useEffect(() => {
    const newVendorMembers = xorBy(currentVendorMembers, vendorMembers, "id");
    const newVendorMemberIds = newVendorMembers.map(({ id }) => id);
    const newMemberIds = memberIds.concat(newVendorMemberIds);
    if (newMemberIds.length > 0) {
      setMemberIds(newMemberIds);
    }
    // Set member ids when a new vendor member is found
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorMembers]);

  useEffect(() => {
    setMemberIds(currentMemberIds);
    setVendorBorrowerMemberIds(
      intersection(vendorMemberIds, projectBorrowerMemberIds)
    );
    // Reset memberIds if organization, role, or query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, role, stakeholderGroup]);

  return (
    <Form.Modal
      confirmLabel={
        currentMemberIds.length > 0 || vendorBorrowerMemberIds.length > 0
          ? "Save Changes"
          : "Add Selected"
      }
      error={result.error}
      header={`${
        currentMemberIds.length > 0
          ? "Edit Stakeholder(s) on "
          : "Add Stakeholder(s) to "
      }${projectName}`}
      isConfirmDisabled={!memberIds.length && !vendorBorrowerMemberIds.length}
      isConfirmLoading={result.loading}
      onClose={onClose}
      onSubmit={formikProps.handleSubmit}
      width="50%"
    >
      <Pane marginX={majorScale(2)}>
        <Paragraph marginBottom={majorScale(2)}>
          {t("stakeholders.edit")}
        </Paragraph>
        <Form.Group>
          <VendorFormPartial
            label="Organization"
            formikProps={formikProps}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            initialVendors={initialVendors}
            newlyAddedVendors={newlyAddedVendors}
            projectId={projectId}
            searchedVendors={searchedVendors}
            setNewlyAddedVendors={setNewlyAddedVendors}
            vendorObjectFieldName="organization"
          />
          <Form.Select
            label="Project Role"
            name="role"
            onChange={setRole}
            options={getTypeOptions()}
            value={role}
          />
        </Form.Group>
        {vendor ? (
          <Fragment>
            <Paragraph marginBottom={majorScale(1)}>
              Select contacts for {projectName}{" "}
              <Strong size={300}>(required)</Strong>
            </Paragraph>
            {vendor.members
              .filter((member) => !!get(member, "name"))
              .sort((a, b) => stringComparator(a.name, b.name))
              .map((member) => {
                const memberMissingEmail = !member.email;
                function enableBorrowerExperience() {
                  setVendorBorrowerMemberIds((prev) => xor(prev, [member.id]));
                  setMemberIds((prev) => union(prev, [member.id]));
                }

                function disableStakeholder(event) {
                  setMemberIds((prev) => xor(prev, [member.id]));
                  if (!event.target.checked) {
                    setVendorBorrowerMemberIds((prev) =>
                      without(prev, member.id)
                    );
                  }
                }

                return (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={majorScale(2)}
                  >
                    <MemberCheckbox
                      member={member}
                      memberIds={memberIds}
                      getMissingEmailTooltipMessage={
                        getMissingEmailTooltipMessage
                      }
                      role={role}
                      disableStakeholder={disableStakeholder}
                    />
                    {hasBorrowerExperience &&
                      role === ORGANIZATION_TYPE.BORROWER && (
                        <Pane
                          flex="0 0 300px"
                          display="flex"
                          alignItems="center"
                        >
                          <Switch
                            checked={includes(
                              vendorBorrowerMemberIds,
                              member.id
                            )}
                            onChange={enableBorrowerExperience}
                            hasCheckIcon
                            disabled={memberMissingEmail}
                          />
                          {memberMissingEmail ? (
                            <Text marginLeft={majorScale(2)} color="muted">
                              Access to Borrower Experience
                            </Text>
                          ) : (
                            <Text
                              marginLeft={majorScale(2)}
                              onClick={enableBorrowerExperience}
                              cursor="pointer"
                            >
                              Access to Borrower Experience
                            </Text>
                          )}
                          <Tooltip
                            appearance="card"
                            content={
                              <Fragment>
                                <Text>
                                  Grants limited project access to submit draw
                                  request details and documents.
                                </Text>
                                <Link
                                  href="https://help.rabbet.com/en/articles/6503375-borrower-portal"
                                  purpose="borrower portal lender"
                                  marginLeft={minorScale(1)}
                                >
                                  Learn More.
                                </Link>
                                <Paragraph marginTop={majorScale(1)}>
                                  Note: An invitation will be sent to the
                                  stakeholder.
                                </Paragraph>
                              </Fragment>
                            }
                          >
                            <HelpIcon marginLeft={minorScale(1)} size={12} />
                          </Tooltip>
                        </Pane>
                      )}
                  </Pane>
                );
              })}
            <Button
              appearance="minimal"
              iconBefore={AddIcon}
              marginTop={majorScale(2)}
              onClick={() => setMemberModalOpen(true)}
              purpose="stakeholder member open"
            >
              Add New Member to {vendor.name}
            </Button>
            {memberModalOpen && (
              <OrganizationMemberModal
                onClose={(result) => {
                  setMemberModalOpen(false);
                  setNewlyAddedVendors((newlyAddedVendors) => {
                    const foundVendorIndex = newlyAddedVendors.findIndex(
                      ({ id }) => id === get(result, "addOrganizationMember.id")
                    );

                    if (foundVendorIndex !== -1) {
                      const clonedNewlyAddedVendors = cloneDeep(
                        newlyAddedVendors
                      );

                      clonedNewlyAddedVendors[foundVendorIndex].members = get(
                        result,
                        "addOrganizationMember.members"
                      );
                      return clonedNewlyAddedVendors;
                    }

                    return newlyAddedVendors;
                  });
                }}
                refetchQueries={[{ query, variables: { projectId } }]}
                vendor={vendor}
                vendors={vendors}
              />
            )}
          </Fragment>
        ) : (
          <Alert>
            Select an organization above to see a list of members to add as
            contacts.
          </Alert>
        )}
      </Pane>
    </Form.Modal>
  );
}

export function StakeholdersModal({
  initialOrganizationId,
  initialRole,
  onClose,
  projectId,
  getMissingEmailTooltipMessage,
  hasBorrowerExperience,
}) {
  const { data } = useQuery(QUERY, {
    variables: { projectId },
  });
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    STAKEHOLDER_VENDOR_SEARCH_QUERY
  );

  const [mutate, result] = useMutation(MUTATION, {
    onCompleted: onClose,
  });

  const [memberIds, setMemberIds] = useState([]);
  const [role, setRole] = useState(initialRole);
  const [vendorBorrowerMemberIds, setVendorBorrowerMemberIds] = useState([]);

  function handleSubmit(values) {
    const variables = {
      memberIds,
      organizationId: values.organization.id,
      projectId,
      role,
    };

    if (hasBorrowerExperience && role === ORGANIZATION_TYPE.BORROWER) {
      mutate({
        variables: {
          ...variables,
          borrowerMemberIds: vendorBorrowerMemberIds,
        },
      });
    } else {
      mutate({ variables });
    }
  }

  return (
    <Formik
      initialValues={getInitialValues(initialOrganizationId)}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <StakeholdersForm
            data={data}
            formikProps={formikProps}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            memberIds={memberIds}
            onClose={onClose}
            projectId={projectId}
            query={QUERY}
            getMissingEmailTooltipMessage={getMissingEmailTooltipMessage}
            result={result}
            role={role}
            searchedVendors={get(
              projectVendorSearchQuery,
              "data.project.organization.paginatedVendors.results",
              []
            )}
            setMemberIds={setMemberIds}
            vendorBorrowerMemberIds={vendorBorrowerMemberIds}
            setVendorBorrowerMemberIds={setVendorBorrowerMemberIds}
            setRole={setRole}
            hasBorrowerExperience={hasBorrowerExperience}
          />
        );
      }}
    </Formik>
  );
}

StakeholdersModal.propTypes = {
  initialOrganizationId: PropTypes.string,
  initialRole: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

StakeholdersModal.defaultProps = {
  initialOrganizationId: undefined,
  initialRole: ORGANIZATION_TYPE.OTHER,
};

import { useContext, Fragment } from "react";
import { Pane, Text } from "components/materials";
import { ArrowDownIcon, ArrowUpIcon } from "evergreen-ui";
import { majorScale, ThemeContext } from "helpers/utilities";

const headers = [
  {
    name: "Project",
    width: "30%",
    sortKey: "projectName",
  },
  { name: "Task", width: "30%", sortKey: "eventName" },
  {
    name: "Est. Completion",
    width: "20%",
    sortKey: "projectedCompletionDate",
  },
  {
    name: "Status",
    width: "20%",
    sortKey: "status",
  },
];

export function TasksHeader({
  onSelect,
  currentSortDirection,
  currentSortKey,
}) {
  const theme = useContext(ThemeContext);

  const determineSortDirection = (currentKey, newKey, currentDirection) => {
    if (currentKey !== newKey) {
      return "asc";
    }
    return currentDirection === "asc" ? "desc" : "asc";
  };

  return (
    <Fragment>
      {headers.map((header) => (
        <Pane
          onClick={() => {
            onSelect(
              header.sortKey,
              determineSortDirection(
                currentSortKey,
                header.sortKey,
                currentSortDirection
              )
            );
          }}
          cursor="pointer"
          width={header.width}
          display="flex"
          alignItems="center"
        >
          <Text size={500} color={theme.colors.textGray} fontWeight={500}>
            {header.name}
          </Text>
          {currentSortKey === header.sortKey &&
            currentSortDirection === "asc" && (
              <ArrowUpIcon
                marginLeft={majorScale(1)}
                color={theme.colors.textGray}
              />
            )}
          {currentSortKey === header.sortKey &&
            currentSortDirection === "desc" && (
              <ArrowDownIcon
                marginLeft={majorScale(1)}
                color={theme.colors.textGray}
              />
            )}
        </Pane>
      ))}
    </Fragment>
  );
}

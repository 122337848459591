export const ENVIRONMENT = {
  DEVELOPMENT: "DEVELOPMENT",
  STAGING: "STAGING",
  PRODUCTION: "PRODUCTION",
};

const reactAppHost = process.env.REACT_APP_HOST;

export const getEnvironment = () => {
  switch (reactAppHost) {
    case "https://lift.rabbet.com":
      return ENVIRONMENT.PRODUCTION;
    case "https://lift.staging.rabbet.com":
      return ENVIRONMENT.STAGING;
    default:
      return ENVIRONMENT.DEVELOPMENT;
  }
};

export const isDevelopment = getEnvironment() === ENVIRONMENT.DEVELOPMENT;
export const isStaging = getEnvironment() === ENVIRONMENT.STAGING;
export const isProduction = getEnvironment() === ENVIRONMENT.PRODUCTION;

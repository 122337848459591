import gql from "graphql-tag";

export const APPROVE = gql`
  mutation DrawApprovalTodo($drawId: String!) {
    approveDraw(drawId: $drawId) {
      id
    }
  }
`;

export const REASSIGN_DOCUMENT = gql`
  mutation AssignUserToDocuments($documentIds: [String]!, $userId: String) {
    assignUserToDocuments(documentIds: $documentIds, userId: $userId) {
      id
    }
  }
`;

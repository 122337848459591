import { MoreIcon, TrashIcon, Position } from "evergreen-ui";
import { Popover, Menu, Button } from "components/materials";
import { itemIcon } from "./ItemIcon";
import { ItemType } from "../../types";

export interface Props {
  itemName: string;
  onAddIn?(type: ItemType): void;
  onAddAfter?(type: ItemType): void;
  onRemove?(): void;
}

export function RowMenu({ itemName, onAddAfter, onAddIn, onRemove }: Props) {
  const buttonProps = {
    appearance: "minimal",
    paddingX: 8,
    paddingY: 4,
  } as any;

  if (!onAddAfter && !onAddIn && !onRemove) {
    return null;
  }

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <AddGroup title={`Add After ${itemName}`} onAdd={onAddAfter} />
          <AddGroup title={`Add In ${itemName}`} onAdd={onAddIn} />

          {onRemove && (
            <Menu.Item icon={TrashIcon} intent="danger" onClick={onRemove}>
              Delete...
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button {...buttonProps}>
        <MoreIcon />
      </Button>
    </Popover>
  );
}

function AddGroup({
  onAdd,
  title,
}: {
  onAdd?: (type: ItemType) => void;
  title: string;
}) {
  if (!onAdd) {
    return null;
  }

  return (
    <Menu.Group title={title}>
      <Menu.Item
        icon={itemIcon(ItemType.Template)}
        onClick={() => onAdd(ItemType.Template)}
      >
        (+) Template
      </Menu.Item>
      <Menu.Item
        icon={itemIcon(ItemType.Document)}
        onClick={() => onAdd(ItemType.Document)}
      >
        (+) Document(s)
      </Menu.Item>
      <Menu.Item
        icon={itemIcon(ItemType.Section)}
        onClick={() => onAdd(ItemType.Section)}
      >
        (+) Section
      </Menu.Item>
    </Menu.Group>
  );
}

import { useContext, useMemo, Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { DisableIcon, DocumentIcon, MoreIcon } from "evergreen-ui";
import { ApproveDocumentButton, EditTableViews } from "components/containers";
import { ApprovalTooltipContent } from "components/templates";
import {
  FastDataTable,
  FastDataTableAdvancedControls,
  FastDataTableDownloadDocuments,
  toBase64,
  booleanColumnDefaults,
  currencyColumnDefaults,
  dateColumnDefaults,
  enumColumnDefaults,
  numberColumnDefaults,
  fractionColumnDefaults,
  primaryColumnDefaults,
  stringColumnDefaults,
} from "components/materials/FastDataTable";
import {
  Button,
  Pane,
  Paragraph,
  Popover,
  Menu,
  Text,
  Tooltip,
} from "components/materials";
import { subtract, sumBy } from "helpers/math";
import {
  getDateRangeAggregate,
  getDefaultAggregate,
} from "helpers/tableAggregateHelpers";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { UserContext } from "helpers/behaviors";
import {
  AGREEMENT_TYPE,
  CHANGE_ORDER_REASON,
  PERMISSION_ACTION,
} from "helpers/enums";
import { majorScale, minorScale, toaster } from "helpers/utilities";
import { get, includes, intersection } from "lodash";
import {
  aggregateDocumentApprovalsData,
  isApprovableDocumentType,
  getMostRecentDocumentApproval,
  getNextOrderedReviewer,
  userCanApproveDocumentAmount,
} from "helpers/documentHelpers";
import { formatDateTime } from "helpers/dateHelpers";
import t from "helpers/translate";
import { Position } from "evergreen-ui/commonjs/constants";
import { AddAgreementModal } from "./AddAgreementModal";
import { AssociateAgreementsModal } from "./AssociateAgreementsModal";
import { CannotDeleteWithAdjustmentModal } from "./CannotDeleteWIthAdjustmentModal";
import { DeleteAgreementModal } from "./DeleteAgreementModal";
import { CHANGE_ORDER_TYPES, PENDING_CHANGE_ORDER_TYPES } from "./enums";
import { ADD_PROJECT_AGREEMENT } from "./graphql-queries";
import * as Documents from "../../../Documents";

function getChangeOrderReason({ changeOrderReason }) {
  if (changeOrderReason === null) return null;
  return t(`changeOrderReason.${changeOrderReason}`);
}
function getRowState(agreement) {
  if (includes(PENDING_CHANGE_ORDER_TYPES, agreement.type))
    return { background: "special" };
  return undefined;
}

function getControls(propsControls, propsEditTableViews, addButton) {
  return (
    <FastDataTableAdvancedControls
      {...propsControls}
      {...propsEditTableViews}
      disable={[FastDataTableDownloadDocuments]}
      rightControls={(defaultRightControls) => [
        defaultRightControls,
        addButton,
      ]}
      searchPlaceholder="Search Vendor..."
    />
  );
}

export function determineAssociableAgreements(
  selectedAgreement,
  agreements,
  targetType
) {
  const agreementsOfType = agreements.filter(({ type }) => type === targetType);

  return agreementsOfType.map((agreement) => {
    const commonAssociatedTypes = intersection(
      selectedAgreement.correlatedAgreementTypes,
      agreement.correlatedAgreementTypes
    );

    const disabledProperties =
      commonAssociatedTypes.length === 0
        ? { disabled: false }
        : {
            disabled: true,
            disabledTooltip: getCannotAssociateTooltip(
              selectedAgreement,
              agreement
            ),
          };

    return {
      ...agreement,
      ...disabledProperties,
    };
  });
}

function getCannotAssociateTooltip(
  {
    type: selectedAgreementType,
    correlatedAgreementTypes: selectedAgreementCorrelatedTypes,
  },
  { correlatedAgreementTypes: agreementCorrelatedTypes }
) {
  if (agreementCorrelatedTypes.includes(selectedAgreementType)) {
    return t("agreementsPage.cannotAssociate.alreadyAssociatedWithType", {
      type: t(`agreementsPage.agreementWithArticle.${selectedAgreementType}`),
    });
  }
  const commonAssociatedType = selectedAgreementCorrelatedTypes.find(
    (correlatedType) => agreementCorrelatedTypes.includes(correlatedType)
  );
  return t("agreementsPage.cannotAssociate.bothAssociatedWithOtherType", {
    type: t(`agreementsPage.agreementWithArticle.${commonAssociatedType}`),
  });
}

function getAgreementActionsColumn({
  addAgreementMutation,
  agreements,
  canCreateAdjustments,
  project,
  onAssociateAgreementsModalClose,
  setAgreementToDelete,
  setAssociateAgreementsModalProps,
  setCannotDeleteWithAdjustmentOpen,
  setUpgradeAgreementModalProps,
}) {
  const isChangeOrder = ({ type }) => CHANGE_ORDER_TYPES.includes(type);

  const upgradeProps = {
    isUpgradeFlow: true,
    onClose: () => setUpgradeAgreementModalProps(null),
    project,
    projectAgreementMutation: addAgreementMutation,
  };

  const rowActions = [
    {
      shown: () => true,
      label: "Delete",
      labelProps: { color: "danger" },
      onClick: (agreement) => {
        if (!canCreateAdjustments && agreement.budgetAdjustment !== null) {
          setCannotDeleteWithAdjustmentOpen(true);
        } else {
          setAgreementToDelete(agreement);
        }
      },
    },
    {
      shown: (item) =>
        isChangeOrder(item) && item.type === AGREEMENT_TYPE.EXPOSURE,
      label: "Update to Potential Change Order",
      onClick: (agreement) => {
        setUpgradeAgreementModalProps({
          ...upgradeProps,
          knownExistingAgreement: agreement,
          knownNewAgreementType: AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
        });
      },
    },
    {
      shown: (item) =>
        isChangeOrder(item) &&
        item.type !== AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
      label: "Execute Change Order",
      onClick: (agreement) => {
        setUpgradeAgreementModalProps({
          ...upgradeProps,
          knownExistingAgreement: agreement,
          knownNewAgreementType: AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
        });
      },
    },
    {
      shown: (item, otherAgreements) =>
        isChangeOrder(item) &&
        item.type !== AGREEMENT_TYPE.EXPOSURE &&
        otherAgreements.some(({ type }) => type === AGREEMENT_TYPE.EXPOSURE),
      label: "Associate with an existing Exposure",
      onClick: (agreement) => {
        setAssociateAgreementsModalProps({
          agreement,
          associableAgreements: determineAssociableAgreements(
            agreement,
            agreements,
            AGREEMENT_TYPE.EXPOSURE
          ),
          associateWithType: AGREEMENT_TYPE.EXPOSURE,
          onClose: onAssociateAgreementsModalClose,
        });
      },
    },
    {
      shown: (item, otherAgreements) =>
        isChangeOrder(item) &&
        item.type !== AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER &&
        otherAgreements.some(
          ({ type }) => type === AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
        ),
      label: "Associate with an existing Potential Change Order",
      onClick: (agreement) => {
        setAssociateAgreementsModalProps({
          agreement,
          associableAgreements: determineAssociableAgreements(
            agreement,
            agreements,
            AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER
          ),
          associateWithType: AGREEMENT_TYPE.POTENTIAL_CHANGE_ORDER,
          onClose: onAssociateAgreementsModalClose,
        });
      },
    },
    {
      shown: (item, otherAgreements) =>
        isChangeOrder(item) &&
        item.type !== AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER &&
        otherAgreements.some(
          ({ type }) => type === AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
        ),
      label: "Associate with an existing Executed Change Order",
      onClick: (agreement) => {
        setAssociateAgreementsModalProps({
          agreement,
          associableAgreements: determineAssociableAgreements(
            agreement,
            agreements,
            AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER
          ),
          associateWithType: AGREEMENT_TYPE.EXECUTED_CHANGE_ORDER,
          onClose: onAssociateAgreementsModalClose,
        });
      },
    },
  ];

  return {
    aggregate: () => null,
    header: () => null,
    id: "actions",
    utility: true,
    value: (item) => {
      const otherAgreements = agreements.filter(({ id }) => id !== item.id);
      return (
        <Popover
          content={
            <Menu>
              {rowActions
                .filter(({ shown }) => shown(item, otherAgreements))
                .map((action) => (
                  <Menu.Item
                    key={action.label}
                    onSelect={() => {
                      action.onClick(item);
                    }}
                  >
                    <Text color="muted" {...action.labelProps}>
                      {action.label}
                    </Text>
                  </Menu.Item>
                ))}
            </Menu>
          }
          minWidth={majorScale(20)}
          position={Position.BOTTOM_LEFT}
        >
          <MoreIcon size={minorScale(3)} />
        </Popover>
      );
    },
    width: 25,
  };
}

export function AgreementsTable({
  addButton,
  disableRowClick,
  history,
  onClickRow,
  organizationId,
  agreements,
  isDocumentViewer,
  isSelectionContext,
  project,
  refetchQueries,
  selectionButtonCopy,
}) {
  const { hasOrgLevelPermission, hasPermission, user } = useContext(
    UserContext
  );
  const canCreateAdjustments = hasPermission(
    PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS
  );
  const trackingCostToAgreements = hasPermission(
    PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  );
  const showApprovalsColumns =
    hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) &&
    !isSelectionContext;
  const [agreementToDelete, setAgreementToDelete] = useState(null);
  const [
    associateAgreementsModalProps,
    setAssociateAgreementsModalProps,
  ] = useState(null);
  const [upgradeAgreementModalProps, setUpgradeAgreementModalProps] = useState(
    null
  );
  const [
    cannotDeleteWithAdjustmentOpen,
    setCannotDeleteWithAdjustmentOpen,
  ] = useState(false);

  const [addAgreementMutation] = useMutation(ADD_PROJECT_AGREEMENT, {
    refetchQueries,
    onCompleted: () => setUpgradeAgreementModalProps(null),
    onError: () =>
      toaster.warning("Something went wrong. Please try again.", {
        duration: 2.5,
      }),
  });

  const columns = useMemo(() => {
    const disableSortProp = isSelectionContext ? { sortStrategy: null } : {};
    return [
      {
        ...stringColumnDefaults,
        ...primaryColumnDefaults,
        ...disableSortProp,
        id: "name",
        header: "Agreement Name",
        hidden: isDocumentViewer,
        value: ({ name }) => name,
        width: 200,
      },
      {
        ...stringColumnDefaults,
        ...disableSortProp,
        id: "vendor",
        header: "Vendor",
        hidden: isDocumentViewer,
        groupable: true,
        value: (agreement) => get(agreement, "vendor.name", "(No Vendor)"),
        aggregate: (groupAgreements) =>
          getDefaultAggregate(groupAgreements, (agreement) =>
            get(agreement, "vendor.name", "(No Vendor)")
          ),
        width: 200,
      },
      {
        ...stringColumnDefaults,
        ...disableSortProp,
        id: "documentNumber",
        header: "Agreement Number",
        hidden: isDocumentViewer,
        value: Documents.getNumber,
        width: 120,
      },
      {
        ...enumColumnDefaults,
        ...disableSortProp,
        enumValues: Object.values(AGREEMENT_TYPE).map((type) =>
          t(`agreementType.${type}`)
        ),
        id: "agreementType",
        header: "Type",
        groupable: true,
        value: (agreement) => t(`agreementType.${agreement.type}`),
        aggregate: (groupAgreements) =>
          getDefaultAggregate(groupAgreements, (agreement) =>
            t(`agreementType.${agreement.type}`)
          ),
        width: isDocumentViewer ? 75 : 160,
      },
      {
        ...enumColumnDefaults,
        ...disableSortProp,
        id: "agreementLineItems",
        header: "Line Items",
        groupable: true,
        value: (agreement) =>
          agreement.lineItemNames
            .map((lineItem) => (lineItem === null ? "(Unassigned)" : lineItem))
            .join(", "),
        aggregate: (groupAgreements) =>
          getDefaultAggregate(groupAgreements, (agreement) =>
            agreement.lineItemNames
              .map((lineItem) =>
                lineItem === null ? "(Unassigned)" : lineItem
              )
              .join(", ")
          ),
        width: isDocumentViewer ? 150 : 325,
      },
      {
        ...dateColumnDefaults,
        ...disableSortProp,
        id: "date",
        header: "Date",
        value: (agreement) => agreement.startDate,
        aggregate: (groupAgreements) =>
          getDateRangeAggregate(groupAgreements, "startDate"),
      },
      {
        ...currencyColumnDefaults,
        ...disableSortProp,
        id: "contractAmount",
        header: "Contract Amount",
        value: (agreement) => agreement.amount,
        aggregate: (groupAgreements) =>
          sumBy(groupAgreements, (agreement) => agreement.amount),
        width: 140,
      },
      {
        ...currencyColumnDefaults,
        ...disableSortProp,
        id: "spentToDate",
        header: "Spent To Date",
        hidden: !trackingCostToAgreements,
        value: (agreement) => agreement.spentToDate,
        aggregate: (groupAgreements) =>
          sumBy(groupAgreements, (agreement) => agreement.spentToDate),
        width: 140,
      },
      {
        ...currencyColumnDefaults,
        ...disableSortProp,
        id: "amountRemaining",
        header: "Amount Remaining",
        hidden: !trackingCostToAgreements,
        value: ({ amount, spentToDate, type }) =>
          PENDING_CHANGE_ORDER_TYPES.includes(type)
            ? null
            : subtract(amount, spentToDate),
        aggregate: (groupAgreements) =>
          sumBy(groupAgreements, ({ amount, spentToDate }) =>
            subtract(amount, spentToDate)
          ),
        width: 140,
      },
      {
        ...currencyColumnDefaults,
        ...disableSortProp,
        id: "budgetAdjustment",
        header: "Budget Adjustment",
        hidden: isDocumentViewer,
        value: (agreement) => agreement.adjustmentAmount,
        aggregate: (groupAgreements) =>
          sumBy(groupAgreements, (agreement) => agreement.adjustmentAmount),
        width: 140,
      },
      {
        ...stringColumnDefaults,
        ...disableSortProp,
        id: "draw",
        header: "Adjusted Draw",
        hidden: !!isSelectionContext,
        value: (agreement) => get(agreement, "drawName"),
        aggregate: (groupAgreements) =>
          getDefaultAggregate(groupAgreements, "drawName"),
        width: 125,
      },
      {
        ...fractionColumnDefaults,
        aggregate: (groupAgreements) =>
          groupAgreements
            .filter(
              ({ document }) => !!document && isApprovableDocumentType(document)
            )
            .reduce(
              (acc, { document }) => {
                const {
                  approvedCount,
                  reviewersCount,
                } = aggregateDocumentApprovalsData(
                  document,
                  project.documentReviewers
                );

                return {
                  numerator: acc.numerator + approvedCount,
                  denominator: acc.denominator + reviewersCount,
                };
              },
              { numerator: 0, denominator: 0 }
            ),
        aggregateFormatter: ({ numerator, denominator }) =>
          denominator === 0 ? null : `${numerator} / ${denominator}`,
        disableAggregateExport: true,
        header: "Approval Status",
        hidden: !showApprovalsColumns,
        id: "approvalStatus",
        value: ({ document }) => {
          if (!document || !isApprovableDocumentType(document)) return null;
          const {
            approvedCount,
            reviewersCount,
            approvedReviews,
            pendingReviewers,
          } = aggregateDocumentApprovalsData(
            document,
            project.documentReviewers
          );
          return {
            numerator: approvedCount,
            denominator: reviewersCount,
            approvedReviews,
            pendingReviewers,
            documentReviewers: project.documentReviewers,
          };
        },
        valueFormatter: (value, { document }) => {
          if (value === null) return null;
          const {
            numerator,
            denominator,
            approvedReviews,
            pendingReviewers,
            documentReviewers,
          } = value;
          const isUnorderedWorkflow = documentReviewers.length === 0;
          const documentHasNoApprovals = numerator === 0;
          const showApprovalButtonForUnorderedWorkflow =
            isUnorderedWorkflow &&
            documentHasNoApprovals &&
            userCanApproveDocumentAmount(document, user);

          if (showApprovalButtonForUnorderedWorkflow) {
            return (
              <ApproveDocumentButton
                document={document}
                refetchQueries={refetchQueries}
              />
            );
          }

          const showTooltip =
            approvedReviews.length > 0 || pendingReviewers.length > 0;
          return showTooltip ? (
            <Tooltip
              content={
                <ApprovalTooltipContent
                  approvedReviews={approvedReviews}
                  pendingReviewers={pendingReviewers}
                />
              }
            >
              <Text size={300}>{`${numerator} / ${denominator}`}</Text>
            </Tooltip>
          ) : (
            `${numerator} / ${denominator}`
          );
        },
        valueExporter: (value) => {
          if (value === null) return null;
          const { numerator, denominator } = value;
          return `${numerator} / ${denominator}`;
        },
      },
      {
        ...stringColumnDefaults,
        aggregate: (groupAgreements) => {
          const approveableDocuments = groupAgreements
            .filter(
              ({ document }) => !!document && isApprovableDocumentType(document)
            )
            .map(({ document }) => document);
          return getDefaultAggregate(
            approveableDocuments,
            (document) =>
              getMostRecentDocumentApproval(document.recentApprovalReviews)
                ?.userName
          );
        },
        header: "Last Approval",
        hidden: !showApprovalsColumns,
        id: "lastApproval",
        value: ({ document }) => {
          if (!document || !isApprovableDocumentType(document)) return null;
          return (
            getMostRecentDocumentApproval(document.recentApprovalReviews)
              ?.userName || null
          );
        },
        valueFormatter: (value, { document }) => {
          if (value === null) return null;
          const mostRecentApproval = getMostRecentDocumentApproval(
            document.recentApprovalReviews
          );
          return (
            <Pane>
              <Paragraph size={300}>{mostRecentApproval?.userName}</Paragraph>
              <Paragraph color="muted" size={300}>
                {formatDateTime(mostRecentApproval?.insertedAt)}
              </Paragraph>
            </Pane>
          );
        },
        valueExporter: (value, { document }) => {
          if (value === null) return null;
          const mostRecentApproval = getMostRecentDocumentApproval(
            document.recentApprovalReviews
          );
          return mostRecentApproval
            ? `${mostRecentApproval.userName} - ${formatDateTime(
                mostRecentApproval.insertedAt
              )}`
            : null;
        },
      },
      {
        ...stringColumnDefaults,
        aggregate: (groupAgreements) => {
          const approveableDocuments = groupAgreements
            .filter(
              ({ document }) => !!document && isApprovableDocumentType(document)
            )
            .map(({ document }) => document);

          return getDefaultAggregate(
            approveableDocuments,
            (document) =>
              getNextOrderedReviewer(document, project.documentReviewers)?.user
                ?.fullName
          );
        },
        header: "Next Approval",
        hidden: !showApprovalsColumns,
        id: "nextApproval",
        value: ({ document }) => {
          if (!document || !isApprovableDocumentType(document)) return null;
          return (
            getNextOrderedReviewer(document, project.documentReviewers)?.user
              ?.fullName || null
          );
        },
        valueFormatter: (value, { document }) => {
          if (value === null) return null;
          const nextReviewer = getNextOrderedReviewer(
            document,
            project.documentReviewers
          );
          if (nextReviewer.user.id === user.id) {
            return (
              <ApproveDocumentButton
                document={document}
                refetchQueries={refetchQueries}
              />
            );
          }
          return value;
        },
      },
      {
        ...booleanColumnDefaults,
        ...disableSortProp,
        id: "document",
        header: "Document",
        hidden: isDocumentViewer,
        value: (agreement) => !!agreement.document?.id,
        valueFormatter: (value, _agreement) => {
          return value && <DocumentIcon />;
        },
        textAlign: "center",
        width: 85,
      },
      {
        ...enumColumnDefaults,
        ...disableSortProp,
        enumValues: Object.values(CHANGE_ORDER_REASON)
          .map((reason) => t(`changeOrderReason.${reason}`))
          .concat(null),
        id: "changeReason",
        header: "Change Reason",
        hidden: !!isSelectionContext,
        groupable: true,
        value: (agreement) => getChangeOrderReason(agreement),
        aggregate: (groupAgreements) =>
          getDefaultAggregate(groupAgreements, (agreement) =>
            getChangeOrderReason(agreement)
          ),
        width: 175,
      },
      {
        ...numberColumnDefaults,
        id: "daysImpacted",
        header: "Days Impacted",
        hidden: !!isSelectionContext,
        value: (agreement) => agreement.daysImpacted,
        aggregate: (groupAgreements) => sumBy(groupAgreements, "daysImpacted"),
        width: 110,
      },
      {
        id: "selectionButton",
        header: "",
        hidden: !isSelectionContext,
        value: (agreement) => agreement,
        valueFormatter: (agreement) => {
          const selectionCopy =
            typeof selectionButtonCopy === "function"
              ? selectionButtonCopy(agreement)
              : selectionButtonCopy;
          return (
            selectionCopy && (
              <Fragment>
                {agreement.disabled ? (
                  <Tooltip content={agreement.disabledTooltip}>
                    <DisableIcon size={minorScale(3)} />
                  </Tooltip>
                ) : (
                  <Button
                    disabled={agreement.disabled}
                    height={24}
                    onClick={() => onClickRow(agreement)}
                  >
                    {selectionCopy}
                  </Button>
                )}
              </Fragment>
            )
          );
        },
        textAlign: "center",
        width: 120,
      },
    ];
  }, [
    isSelectionContext,
    isDocumentViewer,
    trackingCostToAgreements,
    refetchQueries,
    selectionButtonCopy,
    onClickRow,
    project?.documentReviewers,
    showApprovalsColumns,
    user,
  ]);

  const tableColumns = useMemo(
    () => columns.map(({ id }) => id).filter((id) => id !== "changeReason"),
    [columns]
  );

  const defaultViews = useMemo(
    () => [
      {
        config: toBase64({
          columnConfig: tableColumns,
          filterConfig: [],
          groupConfig: { expanded: undefined },
          sortConfig: {},
        }),
        isDefault: true,
        name: "Default",
      },
      {
        config: toBase64({
          columnConfig: tableColumns,
          filterConfig: [],
          groupConfig: { columnId: "agreementLineItems" },
          sortConfig: {},
        }),
        isDefault: true,
        name: "Group by Line Items",
      },
      {
        config: toBase64({
          columnConfig: tableColumns,
          filterConfig: [],
          groupConfig: { columnId: "agreementType" },
          sortConfig: {},
        }),
        isDefault: true,
        name: "Group by Type",
      },
      {
        config: toBase64({
          columnConfig: tableColumns,
          filterConfig: [],
          groupConfig: { columnId: "vendor" },
          sortConfig: {},
        }),
        isDefault: true,
        name: "Group by Vendor",
      },
    ],
    [tableColumns]
  );

  const utilityColumns = [
    getAgreementActionsColumn({
      addAgreementMutation,
      agreements,
      canCreateAdjustments,
      project,
      onAssociateAgreementsModalClose: () =>
        setAssociateAgreementsModalProps(null),
      setAgreementToDelete,
      setAssociateAgreementsModalProps,
      setCannotDeleteWithAdjustmentOpen,
      setUpgradeAgreementModalProps,
    }),
  ];

  const baseTableProps = {
    items: agreements,
    columns,
    onClickRow,
    getRowState,
  };
  return isSelectionContext ? (
    <FastDataTable {...baseTableProps} disableRowClick={disableRowClick} />
  ) : (
    <Fragment>
      <EditTableViews
        canManagePublicViews={hasPermission(PERMISSION_ACTION.SAVE_TABLE_VIEWS)}
        config={getSearchByKey(history, "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        scopeViewsToOrganization
        tableName="Agreements"
      >
        {(propsEditTableViews) => (
          <FastDataTable
            {...baseTableProps}
            footerTotals
            utilityColumns={utilityColumns}
            controls={(propsControls) =>
              getControls(propsControls, propsEditTableViews, addButton)
            }
            onSerialize={(table) => mergeSearch(history, { table })}
            serialized={
              getSearchByKey(history, "table") ||
              get(propsEditTableViews, "views.0.config")
            }
          />
        )}
      </EditTableViews>
      {agreementToDelete !== null && (
        <DeleteAgreementModal
          agreement={agreementToDelete}
          onClose={() => setAgreementToDelete(null)}
          refetchQueries={refetchQueries}
        />
      )}
      {cannotDeleteWithAdjustmentOpen && (
        <CannotDeleteWithAdjustmentModal
          context="agreement"
          onClose={() => setCannotDeleteWithAdjustmentOpen(false)}
        />
      )}
      {associateAgreementsModalProps !== null && (
        <AssociateAgreementsModal
          {...associateAgreementsModalProps}
          refetchQueries={refetchQueries}
        />
      )}
      {upgradeAgreementModalProps !== null && (
        <AddAgreementModal {...upgradeAgreementModalProps} />
      )}
    </Fragment>
  );
}

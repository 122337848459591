import { useState, Fragment, useContext } from "react";
import { CrossIcon } from "evergreen-ui";
import { Button, Heading, Modal, Pane, Paragraph } from "components/materials";
import { get } from "lodash";
import { majorScale, ThemeContext } from "helpers/utilities";
import { getSearchByKey } from "helpers/queryStringHelpers";
import t from "helpers/translate";
import { SetupStepsModal } from "./SetupStepsModal";
import { ProjectActivity } from "./ProjectActivity";
import { ProjectOverview } from "./ProjectOverview";

function NewProjectModal({ isOpen, onClose, project, theme }) {
  const fullHistory = get(project, "onboardingData.fullDrawHistory");
  const translationPath = fullHistory
    ? "fullHistoryGuidance"
    : "toDateGuidance";
  return (
    <Modal
      open={isOpen}
      hasHeader={false}
      hasFooter={false}
      containerProps={{ backgroundColor: "#001f40" }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onClose={onClose}
      width={650}
      topOffset={225}
    >
      {({ close }) => (
        <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
          <Pane display="flex">
            <Paragraph
              color="white"
              size={600}
              flex="1 1 auto"
              fontWeight={theme.fontWeights.MEDIUM}
            >
              Project Setup
            </Paragraph>
            <CrossIcon
              onClick={close}
              color="white"
              appearance="minimal"
              cursor="pointer"
            />
          </Pane>
          <Pane marginTop={majorScale(3)} paddingX={majorScale(2)}>
            <Heading color="white" size={700} marginBottom={majorScale(2)}>
              {t(`historicalDraws.${translationPath}.header`)}
            </Heading>
            <Paragraph color="white" size={500}>
              {t(`historicalDraws.${translationPath}.guidance1`)}
            </Paragraph>
            <Paragraph color="white" size={500}>
              {t(`historicalDraws.${translationPath}.guidance2`)}
            </Paragraph>
            <Paragraph color="white" size={500}>
              {t(`historicalDraws.${translationPath}.guidance3`)}
            </Paragraph>
          </Pane>
          <Pane textAlign="right" marginTop={majorScale(3)}>
            <Button onClick={close} appearance="primary">
              Close
            </Button>
          </Pane>
        </Pane>
      )}
    </Modal>
  );
}

export function ProjectDashboard({
  addCommentMutation,
  dismissProjectSetup,
  history,
  project,
  updateSetupStepMutation,
}) {
  const isNewProject = !!getSearchByKey(history, "isNewProject");
  const [showNewProjectModal, setShowNewProjectModal] = useState(isNewProject);
  const [selectedSetupStep, setSelectedSetupStep] = useState(null);

  const theme = useContext(ThemeContext);
  return (
    <Fragment>
      <Pane
        display="flex"
        justifyContent="center"
        paddingY={majorScale(2)}
        width="100vw"
        maxWidth={1450}
        minWidth={1100}
      >
        <Pane width="65%" marginRight={majorScale(2)}>
          <ProjectOverview project={project} history={history} theme={theme} />
        </Pane>
        <Pane width="30%">
          <ProjectActivity
            addCommentMutation={addCommentMutation}
            dismissProjectSetup={dismissProjectSetup}
            history={history}
            project={project}
            setSelectedSetupStep={setSelectedSetupStep}
            theme={theme}
            updateSetupStepMutation={updateSetupStepMutation}
          />
        </Pane>
      </Pane>
      <NewProjectModal
        isOpen={showNewProjectModal}
        onClose={() => setShowNewProjectModal(false)}
        project={project}
        theme={theme}
      />
      {selectedSetupStep && (
        <SetupStepsModal
          selectedStep={selectedSetupStep}
          project={project}
          onClose={() => setSelectedSetupStep(null)}
          updateSetupStepMutation={updateSetupStepMutation}
          setSelectedSetupStep={setSelectedSetupStep}
        />
      )}
    </Fragment>
  );
}

import { useContext, Fragment, useState } from "react";
import { Pane, ConfigureButtons, Paragraph } from "components/materials";
import { flattenDeep, orderBy } from "lodash";
import { TASK_STATUS } from "helpers/enums";
import { WarningSignIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import {
  getSerializedURLForTasksReport,
  getSerializedURLForOverdueTasksReport,
} from "../helpers";
import { TasksHeader } from "./TasksHeader";
import { TaskRow } from "./TaskRow";
import { BlankSlate, BlankSlateType } from "../BlankSlate";
import { CardHeading } from "../CardHeading";
import { CardLinkButton } from "../CardLinkButton";

export const PROJECT_TASK_SUMMARY_CONFIGURATION_SETTINGS = {
  i: "projectTaskSummary",
  x: 0,
  y: 5,
  w: 2,
  h: 1,
  disabled: false,
};

export function ProjectTaskSummary({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  projects,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const [sortKey, setSortKey] = useState("status");
  const [sortDirection, setSortDirection] = useState("desc");

  function navigateToOverdueTasksReport() {
    return history.push(getSerializedURLForOverdueTasksReport(organization.id));
  }
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const filteredTasks = orderBy(
    flattenDeep(
      projects.map((project) =>
        project.tasks.map((task) => ({
          ...task,
          projectId: project.id,
          status: task.isOverdue ? "OVERDUE" : task.status,
        }))
      )
    ).filter(
      ({ projectedCompletionDate, status }) =>
        status !== TASK_STATUS.COMPLETE && projectedCompletionDate
    ),
    ["projectedCompletionDate"]
  );

  const tasksToDisplay = orderBy(filteredTasks, [sortKey], [sortDirection]);

  const overdueTasksCount = projects.reduce((acc, { tasks }) => {
    const overdueTasks = tasks.filter((task) => task.isOverdue);
    return acc + overdueTasks.length;
  }, 0);

  const sort = (key, direction) => {
    setSortDirection(direction);
    setSortKey(key);
  };

  const showBlankSlate = tasksToDisplay.length === 0;

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Project Task Summary" />
        {overdueTasksCount > 0 && (
          <Pane
            marginLeft="auto"
            marginRight={majorScale(2)}
            display="flex"
            alignItems="center"
          >
            <Pane
              width="170px"
              height="30px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${theme.colors.errorRed}`}
              background={theme.colors.errorLightRed}
              paddingY={majorScale(1)}
              paddingX={majorScale(1)}
              borderRadius="4px"
              cursor="pointer"
              onClick={() => navigateToOverdueTasksReport()}
            >
              <WarningSignIcon
                color={theme.colors.errorRed}
                marginRight={majorScale(1)}
              />
              <Paragraph color={theme.colors.errorRed}>
                {overdueTasksCount} overdue{" "}
                {overdueTasksCount > 1 ? "tasks" : "task"}
              </Paragraph>
            </Pane>
          </Pane>
        )}
        <Pane display="flex" alignItems="center">
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={name}
            />
          )}
          {!isConfigurable && (
            <CardLinkButton
              href={getSerializedURLForTasksReport(true, organization.id)}
            />
          )}
        </Pane>
      </Pane>
      <Pane>
        <Pane>
          <Pane>
            {showBlankSlate ? (
              <BlankSlate type={BlankSlateType.Tasks} />
            ) : (
              <Fragment>
                <Pane
                  maxHeight={280}
                  overflowY="scroll"
                  marginTop={majorScale(2)}
                >
                  <Pane>
                    <Pane marginBottom={majorScale(1)} display="flex">
                      <TasksHeader
                        onSelect={sort}
                        currentSortDirection={sortDirection}
                        currentSortKey={sortKey}
                      />
                    </Pane>
                    <Pane>
                      {tasksToDisplay.map((task) => (
                        <TaskRow key={task.id} task={task} />
                      ))}
                    </Pane>
                  </Pane>
                </Pane>
              </Fragment>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

import { Fragment, useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { Redirect, Route } from "react-router-dom";
import { useFeatureFlags } from "FeatureFlags";
import {
  Button,
  Heading,
  IconButton,
  LinkButton,
  Loadable,
  Menu,
  Modal,
  Pane,
  Paragraph,
  Popover,
  RedesignLayout,
} from "components/materials";
import {
  FundingSourceWarningBanner,
  FundingSourceDisbursedWarningBanner,
} from "components/templates";
import { MenuIcon } from "evergreen-ui";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import { getIssueIconProps, getTabIssues } from "helpers/issues";
import { majorScale, minorScale, Position } from "helpers/utilities";
import { get, some } from "lodash";
import { UserContext } from "helpers/behaviors";
import t from "helpers/translate";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { FileUploadModal } from "features/uploads";
import { UploadFiles } from "./UploadFiles";

const PROJECT_LAYOUT_QUERY = gql`
  query ProjectLayoutQuery($id: String!, $documentType: DocumentTypeName!) {
    project(id: $id) {
      id
      name
      amount
      inProgressAmount
      setupComplete
      status
      fundingSourceGroups {
        id
        amount
        disbursedAmount
      }
      issues {
        id
        documentId
        lineItemId
        severity
      }
      recentDraw {
        id
        name
      }
      lineItems {
        id
        scopeId
        name
        number
        division {
          id
          scopeId
          name
        }
      }
      picture {
        url
      }
      organization {
        id
        documentTemplates(type: $documentType) {
          id
          name
        }
      }
    }
  }
`;

const PROJECT_ACTIVATED_SUBSCRIPTION = gql`
  subscription projectActivated($projectId: String!) {
    projectActivated(projectId: $projectId) {
      id
    }
  }
`;

const ISSUES_SUBSCRIPTION = gql`
  subscription projectIssuesUpdated($projectId: String!) {
    projectIssuesUpdated(projectId: $projectId) {
      id
      issues {
        id
        documentId
        lineItemId
        severity
      }
    }
  }
`;

export function ProjectLayout({ children, match, history }) {
  const { projectId } = match.params;

  const featureFlags = useFeatureFlags();
  const useNewDashboard = featureFlags.isEnabled(
    "use-redesigned-project-dashboard"
  );

  const [showProjectActivatedModal, setShowProjectActivatedModal] = useState(
    false
  );

  const { data, loading, subscribeToMore } = useQuery(PROJECT_LAYOUT_QUERY, {
    variables: { id: projectId, documentType: DOCUMENT_TYPE_NAME.INVOICE },
    onError: (error) => {
      const errors = get(error, "graphQLErrors", []);
      if (some(errors, ({ message }) => message === "not_found")) {
        window.location.assign("/not_found");
      }
      return null;
    },
  });

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { projectId },
    });
  }, [projectId, subscribeToMore]);

  useSubscription(PROJECT_ACTIVATED_SUBSCRIPTION, {
    onSubscriptionData: () => setShowProjectActivatedModal(true),
    variables: { projectId },
  });

  // This gets hit before the query's onError function will run, so we must check for blank data as well
  if (loading || !data) return <Loadable loading />;

  if (!data.project.setupComplete) {
    return <Redirect to={`/projects/${projectId}/configure`} />;
  }

  return (
    <Fragment>
      <Modal
        open={showProjectActivatedModal}
        title="Project Activated"
        confirmLabel="OK"
        onClose={() => setShowProjectActivatedModal(false)}
        onConfirm={(close) => close()}
        hasCancel={false}
        hasFooter
      >
        <Paragraph>{t("projectActivated")}</Paragraph>
      </Modal>
      {useNewDashboard ? (
        <NewProjectNavigation
          projectMatch={match}
          project={data.project}
          history={history}
        />
      ) : (
        <ProjectNavigation
          projectMatch={match}
          project={data.project}
          history={history}
        />
      )}
      {children}
    </Fragment>
  );
}

function NewProjectNavigation({ project, projectMatch, history }) {
  const { hasPermission } = useContext(UserContext);
  const featureFlags = useFeatureFlags();
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");
  const [showUploadModal, setShowUploadModal] = useState(false);

  const uploadDocument = getSearchByKey(history, "uploadDocument") === "true";

  useEffect(() => {
    if (uploadDocument) {
      setShowUploadModal(true);
    }
  }, [uploadDocument]);

  const tabIssues = getTabIssues(project.issues);
  const imageUrl = project.picture?.url;
  const hasCreateDraw = hasPermission(PERMISSION_ACTION.CREATE_DRAW);
  const hasUploadDocuments = hasPermission(PERMISSION_ACTION.UPLOAD_DOCUMENT);
  const hasEditProjectSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );
  const showMenu =
    hasCreateDraw || hasUploadDocuments || hasEditProjectSettings;
  const showMenuDivider =
    (hasCreateDraw || hasUploadDocuments) && hasEditProjectSettings;

  return (
    <Route
      path={`${projectMatch.path}/:action?`}
      render={({ match }) => {
        // Only show this project menu for "project level" pages,
        // not other resources that live under project, such as draws.
        const showProjectNavigation =
          match.isExact ||
          [
            "budget",
            "documentation",
            "agreements",
            "timeline",
            "settings",
          ].indexOf(match.params.action) >= 0;

        if (showProjectNavigation) {
          return (
            <Fragment>
              <FundingSourceWarningBanner
                project={project}
                hideAction={match.params.action === "settings"}
                canEditFundingSources={
                  hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES) &&
                  hasPermission(PERMISSION_ACTION.EDIT_PROJECT_SETTINGS)
                }
              />
              <FundingSourceDisbursedWarningBanner project={project} />
              <RedesignLayout.SubNav height={80}>
                {imageUrl && (
                  <div
                    style={{
                      backgroundImage: `url("${imageUrl}")`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      height: "40px",
                      width: "45px",
                      borderRadius: majorScale(1),
                      marginRight: minorScale(3),
                    }}
                  />
                )}
                <Heading
                  size={800}
                  fontWeight={600}
                  marginRight={majorScale(3)}
                  cursor="pointer"
                  onClick={() => history.push(projectMatch.url)}
                >
                  {project.name}
                </Heading>
                <RedesignLayout.Spacer />
                <RedesignLayout.Tab
                  exact
                  testId="subnav-overview"
                  to={projectMatch.url}
                >
                  Overview
                </RedesignLayout.Tab>
                <RedesignLayout.Tab
                  exact
                  testId="subnav-budget"
                  to={`${projectMatch.url}/budget`}
                  {...(hasPermission(
                    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
                  ) && getIssueIconProps(tabIssues.lineItems))}
                >
                  Budget
                </RedesignLayout.Tab>
                <RedesignLayout.Tab
                  exact
                  testId="subnav-documents"
                  to={`${projectMatch.url}/documentation`}
                  {...(hasPermission(
                    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
                  ) && getIssueIconProps(tabIssues.documents))}
                >
                  Documents
                </RedesignLayout.Tab>
                {hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT) && (
                  <RedesignLayout.Tab
                    testId="subnav-agreements"
                    to={`${projectMatch.url}/agreements`}
                  >
                    Agreements
                  </RedesignLayout.Tab>
                )}
                {hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) && (
                  <RedesignLayout.Tab
                    exact
                    testId="subnav-projections"
                    to={`${projectMatch.url}/projection`}
                  >
                    Projections
                  </RedesignLayout.Tab>
                )}
                {hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT) && (
                  <RedesignLayout.Tab
                    testId="subnav-timeline"
                    to={`${projectMatch.url}/timeline`}
                  >
                    Timeline
                  </RedesignLayout.Tab>
                )}
                <Pane display="flex" marginLeft={100}>
                  <NewActions project={project} history={history} />
                  {showMenu && (
                    <Popover
                      position={Position.BOTTOM_RIGHT}
                      content={
                        <Menu>
                          {(hasCreateDraw || hasUploadDocuments) && (
                            <Menu.Group>
                              {hasCreateDraw && (
                                <Menu.Item>
                                  <Pane
                                    onClick={() =>
                                      history.push(
                                        `/projects/${project.id}/draws/new`
                                      )
                                    }
                                  >
                                    Create Draw
                                  </Pane>
                                </Menu.Item>
                              )}
                              {hasUploadDocuments && (
                                <Menu.Item>
                                  {useNewUploads && (
                                    <Pane
                                      onClick={() => setShowUploadModal(true)}
                                    >
                                      Add Documents
                                    </Pane>
                                  )}
                                  {useNewUploads && (
                                    <FileUploadModal
                                      projectId={project.id}
                                      open={showUploadModal}
                                      onCancel={() => setShowUploadModal(false)}
                                      onSubmit={() => setShowUploadModal(false)}
                                      targetName={project.name}
                                    />
                                  )}
                                  {!useNewUploads && (
                                    <UploadFiles
                                      projectId={project.id}
                                      projectStatus={project.status}
                                      projectName={project.name}
                                      documentTemplates={
                                        project.organization.documentTemplates
                                      }
                                      openModal={getSearchByKey(
                                        history,
                                        "uploadDocument"
                                      )}
                                      lineItems={project.lineItems}
                                      uploadFilesInMenu
                                    />
                                  )}
                                </Menu.Item>
                              )}
                            </Menu.Group>
                          )}
                          {showMenuDivider && <Menu.Divider />}
                          {hasEditProjectSettings && (
                            <Menu.Group>
                              <Menu.Item
                                onClick={() =>
                                  history.push(`${projectMatch.url}/settings`)
                                }
                              >
                                Project Settings
                              </Menu.Item>
                            </Menu.Group>
                          )}
                        </Menu>
                      }
                    >
                      <IconButton icon={MenuIcon} />
                    </Popover>
                  )}
                </Pane>
              </RedesignLayout.SubNav>
            </Fragment>
          );
        }

        return null;
      }}
    />
  );
}

function ProjectNavigation({ project, projectMatch, history }) {
  const { hasPermission } = useContext(UserContext);

  const tabIssues = getTabIssues(project.issues);
  return (
    <Route
      path={`${projectMatch.path}/:action?`}
      render={({ match }) => {
        // Only show this project menu for "project level" pages,
        // not other resources that live under project, such as draws.
        const showProjectMenu =
          match.isExact ||
          [
            "budget",
            "documentation",
            "agreements",
            "timeline",
            "settings",
          ].indexOf(match.params.action) >= 0;

        if (showProjectMenu) {
          return (
            <Fragment>
              <FundingSourceWarningBanner
                project={project}
                hideAction={match.params.action === "settings"}
                canEditFundingSources={
                  hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES) &&
                  hasPermission(PERMISSION_ACTION.EDIT_PROJECT_SETTINGS)
                }
              />
              <FundingSourceDisbursedWarningBanner project={project} />
              <RedesignLayout.SubNav>
                <RedesignLayout.Tab
                  exact
                  testId="subnav-overview"
                  to={projectMatch.url}
                >
                  Overview
                </RedesignLayout.Tab>
                <RedesignLayout.Tab
                  exact
                  testId="subnav-budget"
                  to={`${projectMatch.url}/budget`}
                  {...(hasPermission(
                    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
                  ) && getIssueIconProps(tabIssues.lineItems))}
                >
                  Budget
                </RedesignLayout.Tab>
                <RedesignLayout.Tab
                  exact
                  testId="subnav-documents"
                  to={`${projectMatch.url}/documentation`}
                  {...(hasPermission(
                    PERMISSION_ACTION.RULES_REDESIGN_CLERICAL
                  ) && getIssueIconProps(tabIssues.documents))}
                >
                  Documents
                </RedesignLayout.Tab>
                {hasPermission(PERMISSION_ACTION.AGREEMENT_MANAGEMENT) && (
                  <RedesignLayout.Tab
                    testId="subnav-agreements"
                    to={`${projectMatch.url}/agreements`}
                  >
                    Agreements
                  </RedesignLayout.Tab>
                )}
                {hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) && (
                  <RedesignLayout.Tab
                    exact
                    testId="subnav-projections"
                    to={`${projectMatch.url}/projection`}
                  >
                    Projections
                  </RedesignLayout.Tab>
                )}
                {hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT) && (
                  <RedesignLayout.Tab
                    testId="subnav-timeline"
                    to={`${projectMatch.url}/timeline`}
                  >
                    Timeline
                  </RedesignLayout.Tab>
                )}
                {hasPermission(PERMISSION_ACTION.EDIT_PROJECT_SETTINGS) && (
                  <RedesignLayout.Tab
                    exact
                    testId="subnav-project-settings"
                    to={`${projectMatch.url}/settings`}
                  >
                    Project Settings
                  </RedesignLayout.Tab>
                )}
                <RedesignLayout.Spacer />
                <DrawNavigationButtons project={project} />
              </RedesignLayout.SubNav>
            </Fragment>
          );
        }
        return null;
      }}
    />
  );
}

function DrawNavigationButtons({ project }) {
  const { hasPermission } = useContext(UserContext);

  return (
    <Fragment>
      {project.recentDraw && (
        <LinkButton
          marginRight={minorScale(3)}
          appearance="primary"
          purpose="project-dashboard open draw"
          data-testid="subnav-open-current-draw"
          to={`/projects/${project.id}/draws/${project.recentDraw.id}`}
        >
          Open {project.recentDraw.name}
        </LinkButton>
      )}
      {hasPermission(PERMISSION_ACTION.CREATE_DRAW) && (
        <LinkButton
          marginRight={majorScale(1)}
          appearance={project.recentDraw ? "default" : "primary"}
          purpose="project-dashboard draw new"
          data-testid="subnav-create-draw"
          to={`/projects/${project.id}/draws/new`}
        >
          Create New Draw
        </LinkButton>
      )}
    </Fragment>
  );
}

function NewActions({ project, history }) {
  return (
    <Fragment>
      {project.recentDraw && (
        <Button
          marginRight={minorScale(3)}
          appearance="primary"
          purpose="project-dashboard open draw"
          data-testid="subnav-open-current-draw"
          onClick={() =>
            history.push(
              `/projects/${project.id}/draws/${project.recentDraw.id}`
            )
          }
        >
          Open {project.recentDraw.name}
        </Button>
      )}
    </Fragment>
  );
}

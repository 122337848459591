import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Modal, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";
import { dateFormToServer } from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import analytics, { drawValues } from "helpers/analytics";
import { MarkFundedForm } from "./MarkFundedForm";
import { MARK_COMPLETE } from "./graphql";

export function MarkComplete({ draw, onClose }) {
  const [markComplete, markCompleteResult] = useMutation(MARK_COMPLETE, {
    onCompleted: onClose,
  });

  const handleSubmit = (values) => {
    analytics.track("Draw Marked Complete", drawValues(draw));

    markComplete({
      variables: {
        drawId: draw.id,
        fundedDate: isBlank(values.date) ? null : dateFormToServer(values.date),
      },
    });
  };

  return (
    <Modal open onClose={onClose} size="small" title="Mark Draw Complete">
      <Modal.Content>
        <Paragraph marginBottom={majorScale(2)}>
          {t("historicalDraws.markCompleteDatePrompt")}
        </Paragraph>
        <Formik
          initialValues={initialValues(draw.fundedDate)}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <MarkFundedForm
              form={form}
              loading={markCompleteResult.loading}
              handleClose={onClose}
              isIncompleteDraw
            />
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

function initialValues(fundedDate) {
  return { date: fundedDate || null };
}

MarkComplete.propTypes = {
  project: PropTypes.object.isRequired,
  draw: PropTypes.object.isRequired,
};

import { useContext } from "react";
import { ArrowRightIcon } from "evergreen-ui";
import { max, uniq } from "lodash";
import {
  ConfigureButtons,
  Link,
  Pane,
  Text,
  Shortener,
} from "components/materials";
import { add } from "helpers/math";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { BlankSlate } from "./BlankSlate";
import { getSerializedURLForProjectReport, trackClickthrough } from "./helpers";
import { toBase64 } from "../../materials/FastDataTable";
import { CardHeading } from "./CardHeading";
import { CardLinkButton } from "./CardLinkButton";

export const PROJECTS_BY_TEAM_CONFIGURATION_SETTINGS = {
  i: "projectsByTeam",
  x: 1,
  y: 8,
  w: 1,
  h: 1,
  disabled: false,
};

function TeamCount({
  activeProjectCount,
  href,
  maxNumProjects,
  name,
  organizationId,
  theme,
}) {
  return (
    <Pane
      is={Link}
      onClick={() =>
        trackClickthrough(
          "Projects by Team to Project Report",
          organizationId,
          { team: name }
        )
      }
      to={href}
    >
      <Pane display="flex" marginBottom={majorScale(2)}>
        <Pane
          alignItems="center"
          cursor="pointer"
          display="flex"
          marginRight={majorScale(2)}
          width={300}
        >
          <Shortener
            color={theme.colors.linkBlue}
            limit={20}
            size={200}
            text={name}
          />
          <ArrowRightIcon
            appearance="minimal"
            color={theme.colors.linkBlue}
            icon={ArrowRightIcon}
            marginLeft="auto"
          />
        </Pane>
        <Pane width="100%">
          <Pane
            alignItems="center"
            background={theme.colors.barChartBlue}
            display="flex"
            height={30}
            paddingX={minorScale(2)}
            width={`${max([6, (activeProjectCount / maxNumProjects) * 100])}%`}
          >
            <Pane marginLeft="auto">
              <Text color="white">{activeProjectCount}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByTeam({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  teams,
  projects,
  organization,
}) {
  const theme = useContext(ThemeContext);

  const organizationId = organization.id;

  const countForTeam = teams.reduce(
    (acc, team) => ({
      ...acc,
      [team.name]: acc[team.name] ? add(acc[team.name], 1) : 1,
    }),
    {}
  );

  const uniqueTeams = uniq(teams);
  const sortedTeams = [...uniqueTeams].sort(
    (teamA, teamB) => countForTeam[teamB.name] - countForTeam[teamA.name]
  );

  const assignedTotal = Object.keys(countForTeam).reduce(
    (acc, key) => acc + countForTeam[key],
    0
  );

  const noTeamAssignedTotal = projects.length - assignedTotal;

  const maxNumProjects = Math.max(
    countForTeam[sortedTeams[0]?.name],
    noTeamAssignedTotal
  );

  const showBlankSlate = projects.length === 0;

  const groupedByTeamConfig = toBase64({
    columnConfig: [
      "projectName",
      "projectTotal",
      "equityCommitted",
      "debtCommitted",
      "amountRemaining",
      "percentComplete",
    ],
    filterConfig: [
      {
        enum: ["Active", "Pre-Development", "Under Contract"],
        input: ["Active", "Pre-Development", "Under Contract"],
        key: "projectStatus",
        operator: "EXACT",
      },
    ],
    groupConfig: { columnId: "team" },
    sortConfig: {},
  });

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <CardHeading disabled={isDisabled} text="Projects By Team" />
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <CardLinkButton
            href={`/reports/projects?referrerSelectedOrgId=${organizationId}&table=${groupedByTeamConfig}`}
            onClick={() =>
              trackClickthrough(
                "Projects by Team to Project Report",
                organization.id
              )
            }
          />
        )}
      </Pane>
      {showBlankSlate ? (
        <BlankSlate />
      ) : (
        <Pane height="80%" paddingTop={majorScale(2)}>
          <Pane height="100%" overflowY="scroll">
            {sortedTeams.map((team) => {
              const { name: teamName } = team;
              const href = getSerializedURLForProjectReport(
                "team",
                {
                  enum: [teamName],
                },
                organizationId
              );

              return (
                <TeamCount
                  activeProjectCount={countForTeam[team.name]}
                  href={href}
                  key={team.id}
                  maxNumProjects={maxNumProjects}
                  name={team.name}
                  organizationId={organizationId}
                  theme={theme}
                />
              );
            })}
            {noTeamAssignedTotal !== 0 && (
              <TeamCount
                activeProjectCount={noTeamAssignedTotal}
                href={getSerializedURLForProjectReport(
                  "team",
                  {
                    enum: ["-"],
                  },
                  organizationId
                )}
                maxNumProjects={maxNumProjects}
                name="Unassigned"
                organizationId={organizationId}
                theme={theme}
              />
            )}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}

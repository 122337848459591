import gql from "graphql-tag";
import { COMMENT_FRAGMENT, RULE_FRAGMENT } from "helpers/fragments";

export const DRAW_OVERVIEW_PAGE_QUERY = gql`
  query DrawOverviewPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      users {
        id
        fullName
        permissionConfig
      }
      documentReviewers {
        id
        position
        user {
          id
          approvalAmountLimit
          approvalAmountMinimum
          fullName
        }
      }
      draw(id: $drawId) {
        id
        requestedAmount
        budgetAmount
        lineItems {
          id
          scopeId
          name
          budgetAmount
          divisionName
          requestedAmount
          balanceToFundAmount
          adjustedToDateAmount
          originalBudgetAmount
          isContingency
          previousLineItem {
            id
            scopeId
            budgetAmount
            adjustedToDateAmount
          }
        }
        fundingSources {
          id
          scopeId
          amount
          disbursedAmount
          disbursedToDateAmount
          label
        }
        drawOverviewDocuments {
          id
          vendorName
          type
          state
          amount
          adjustedAmount
          agreementAmount
          recentApprovalReviews {
            id
            userId
            userName
            reviewType
          }
        }
        payableDocuments {
          id
          vendorName
          type
          amount
          isPaid
          amountPaid
        }
        reviews {
          id
          processedAt
          reviewer {
            id
            fullName
          }
          state
          isFinal
          isPreparer
        }
        rules {
          ...RuleFragment
        }
        comments {
          ...CommentFragment
        }
        questions {
          id
          label
          description
          fieldType
        }
        responses {
          id
          drawAssessmentQuestionId
          response
        }
        drawInspectionReport {
          id
          status
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
  ${RULE_FRAGMENT}
`;

export const COMMENT_QUERY = gql`
  query DrawReviewsPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      name
      draw(id: $drawId) {
        id
        comments {
          ...CommentFragment
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const RULE_SUBSCRIPTION = gql`
  subscription drawRulesUpdated($drawId: String!) {
    drawRulesUpdated(drawId: $drawId) {
      id
      rules {
        ...RuleFragment
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const TRANSITION_RULE = gql`
  mutation DrawRulesTransition(
    $drawId: String!
    $ruleId: String!
    $state: RuleState!
    $body: String
    $auto: Boolean
  ) {
    transitionRule(
      drawId: $drawId
      ruleId: $ruleId
      state: $state
      body: $body
      auto: $auto
    ) {
      ...RuleFragment
    }
  }
  ${RULE_FRAGMENT}
`;

export const ADD_COMMENT = gql`
  mutation DrawRulesComment(
    $drawId: String!
    $ruleId: String!
    $body: String!
  ) {
    addRuleComment(drawId: $drawId, ruleId: $ruleId, body: $body) {
      id
      target {
        ... on Rule {
          ...RuleFragment
        }
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION = gql`
  mutation SaveDrawAssessmentResponsesMutation(
    $drawId: String!
    $drawAssessmentResponses: [DrawAssessmentResponseInput]
  ) {
    saveDrawAssessmentResponses(
      drawId: $drawId
      drawAssessmentResponses: $drawAssessmentResponses
    ) {
      id
      responses {
        id
        drawAssessmentQuestionId
        response
      }
    }
  }
`;

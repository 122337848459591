import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import t from "helpers/translate";
import { formatDate } from "helpers/dateHelpers";
import { majorScale, ThemeContext } from "helpers/utilities";
import { formatCurrency } from "helpers/formatCurrency";
import { Pane, Paragraph, Shortener } from "components/materials";
import { getDrawStateDate } from "components/templates/PortfolioDashboard/PortfolioProjectCard";
import { hover } from "glamor";

export function DrawHistoryItem({ draw, projectId }) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const [expandName, setExpandName] = useState(false);

  const { name, state, requestedAmount } = draw;

  const nameRef = useRef(null);

  const navigateToDraw = (drawId) =>
    history.push(`/projects/${projectId}/draws/${drawId}`);

  const maybeExpandName = () => {
    const nameElement = nameRef.current;
    // Basically, if the name is long enough to trigger the overflow: ellipsis
    if (nameElement.offsetWidth < nameElement.scrollWidth) {
      setExpandName(true);
    }
  };

  const contractName = () => {
    setExpandName(false);
  };

  const drawStatus = t(`drawStates.${state}`);

  return (
    <Pane
      width="100%"
      height={majorScale(3)}
      display="flex"
      alignItems="center"
      paddingY={majorScale(2)}
      onClick={() => navigateToDraw(draw.id)}
      position="relative"
      cursor="pointer"
    >
      <Paragraph
        overflow="hidden"
        width="33%"
        marginRight="2%"
        whiteSpace="nowrap"
        fontWeight={500}
        textOverflow="ellipsis"
        color={theme.colors.link}
        ref={nameRef}
        onMouseOver={maybeExpandName}
        onMouseLeave={contractName}
        className={
          expandName
            ? hover({
                zIndex: 1000,
                backgroundColor: "#ffffff",
                position: "absolute",
                width: "300px",
                overflow: "inherit",
                whiteSpace: "wrap",
              })
            : undefined
        }
      >
        {name}
      </Paragraph>
      <Shortener
        width="15%"
        fontSize={14}
        color={theme.colors.gray800}
        text={drawStatus}
        limit={7}
      />
      <Paragraph width="20%">
        {formatDate(getDrawStateDate(draw), null, "MMM ''yy")}
      </Paragraph>
      <Paragraph fontWeight={600} width="30%">
        {formatCurrency(requestedAmount)}
      </Paragraph>
    </Pane>
  );
}

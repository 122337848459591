import { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ContentLayout } from "components/templates";
import {
  Alert,
  Button,
  Dropzone,
  Heading,
  Loadable,
  Pane,
  Text,
} from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import { get } from "lodash";
import t from "helpers/translate";

const QUERY = gql`
  query GuestUploadPageQuery($accessTokenId: String!) {
    guestProject(accessTokenId: $accessTokenId) {
      id
      name
      streetAddress
      owner {
        id
        name
      }
    }
  }
`;

const UPLOAD_FILES_AS_GUEST = gql`
  mutation GuestUploadPageUploadFilesAsGuest(
    $accessTokenId: String!
    $files: [Upload]
  ) {
    uploadFilesAsGuest(accessTokenId: $accessTokenId, files: $files) {
      status
    }
  }
`;

export function GuestUploadPage({ match }) {
  const { accessTokenId } = match.params;

  const [files, setFiles] = useState([]);
  const { data, error, loading } = useQuery(QUERY, {
    variables: { accessTokenId },
  });
  const [upload, uploadResult] = useMutation(UPLOAD_FILES_AS_GUEST, {
    onCompleted: () => {
      toaster.success("Your files have been sent", { duration: 2.5 });
      setFiles([]);
    },
    onError: () => {
      toaster.danger("Your files failed to send", { duration: 2.5 });
      setFiles([]);
    },
  });

  const concatFiles = (newFiles) => {
    setFiles((oldFiles) => oldFiles.concat(newFiles));
  };
  const removeFile = (index) => {
    setFiles((oldFiles) => oldFiles.filter((_oldFile, i) => i !== index));
  };
  const handleUpload = () => {
    const variables = { accessTokenId, files };
    upload({ variables });
  };

  if (loading) return <Loadable loading />;

  if (error)
    return (
      <ContentLayout fixedWidth>
        <Alert intent="danger" title={t("guest.expired")} />
      </ContentLayout>
    );

  const renderHeading = () => (
    <Pane>
      <Heading>{get(data, "guestProject.name")}</Heading>
      <Text display="block">{get(data, "guestProject.streetAddress")}</Text>
      <Text display="block">{get(data, "guestProject.owner.name")}</Text>
    </Pane>
  );

  const renderContent = () => (
    <Pane marginTop={majorScale(2)}>
      <Dropzone
        disabled={uploadResult.loading}
        files={files}
        onAdd={concatFiles}
        onRemove={removeFile}
      />
      <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(4)}>
        <Button
          appearance="primary"
          disabled={uploadResult.loading || files.length < 1}
          isLoading={uploadResult.loading}
          purpose="guest-upload submit"
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Pane>
    </Pane>
  );

  return (
    <ContentLayout
      fixedWidth
      renderContent={renderContent}
      renderHeading={renderHeading}
    />
  );
}

import { useState, Fragment } from "react";
import { EditIcon } from "evergreen-ui";
import { Modal, Pane, Text } from "components/materials";
import { DRAW_STATE } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import t from "helpers/translate";
import { formatTrimDate } from "helpers/dateHelpers";
import { DrawStateUpdates } from "./DrawStateUpdates";

export function LockedDrawState({
  recentStateUpdate,
  draw,
  useAdvancedDrawStatus,
}) {
  const [showUpdates, setShowUpdates] = useState(false);
  const date = getLockedDrawStateDate(draw, recentStateUpdate);
  const stateText = t(`drawStates.${draw.state}`);
  const dateText = date ? ` ${formatTrimDate(date)}` : "";
  const hasDrawStateUpdates =
    useAdvancedDrawStatus && draw.drawStateUpdates.length > 0;

  return (
    <Fragment>
      <Pane
        display="flex"
        alignItems="center"
        {...(hasDrawStateUpdates
          ? { cursor: "pointer", onClick: () => setShowUpdates(true) }
          : {})}
      >
        <Text
          fontSize={18}
          fontWeight={600}
          lineHeight={1.25}
          marginRight={minorScale(1)}
        >
          {`${stateText}${dateText}`}
        </Text>
        {hasDrawStateUpdates && <EditIcon appearance="minimal" />}
      </Pane>
      {showUpdates && (
        <Modal
          hasFooter={false}
          onClose={() => setShowUpdates(false)}
          open
          size="small"
          title="Draw State Updates"
        >
          <Modal.Content paddingX={majorScale(2)} paddingY={majorScale(1)}>
            <DrawStateUpdates
              draw={draw}
              onClose={() => setShowUpdates(false)}
              maxHeight={400}
            />
          </Modal.Content>
        </Modal>
      )}
    </Fragment>
  );
}

function getLockedDrawStateDate(draw, recentStateUpdate) {
  if (draw.state === DRAW_STATE.FUNDED) return draw.fundedDate;
  // perhaps an overly-cautious check - if a "recent state update" exists, its state should always match the draw state
  if (recentStateUpdate?.state === draw.state && !!recentStateUpdate?.date)
    return recentStateUpdate.date;
  return null;
}

import { useState, useEffect, useContext, Fragment } from "react";
import { Formik } from "formik";
import { get } from "lodash";
import { UserAccessTable } from "components/templates/UserAccessTable";
import { Accordion, Pane, Text } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { majorScale } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { configurationPanelStyles } from "./helpers";
import { EditConfigurationButtons } from "./EditConfigurationButtons";

export const PANEL_KEY = "projectReviewers";

export function ProjectReviewers({
  dirtyPanels,
  setPanelDirty,
  onSubmit,
  loading,
  organization,
  toggle,
  expandedPanelKeys,
}) {
  const [teamId, setTeamId] = useState(null);

  return (
    <Formik
      initialValues={initialValues(organization, teamId)}
      onSubmit={(values) => onSubmit(values, teamId)}
      enableReinitialize
    >
      {(form) => (
        <ProjectReviewersPanel
          dirtyPanels={dirtyPanels}
          setPanelDirty={setPanelDirty}
          form={form}
          loading={loading}
          toggle={toggle}
          expandedPanelKeys={expandedPanelKeys}
          teams={organization.teams}
          teamId={teamId}
          setTeamId={setTeamId}
        />
      )}
    </Formik>
  );
}

function ProjectReviewersPanel({
  form,
  loading,
  expandedPanelKeys,
  toggle,
  dirtyPanels,
  setPanelDirty,
  teams,
  teamId,
  setTeamId,
}) {
  const dirty = dirtyPanels[PANEL_KEY];
  const open = !!expandedPanelKeys[PANEL_KEY];

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(PANEL_KEY, isDirty);
    }
    return undefined;
  }, [dirty, form, setPanelDirty]);

  const { hasPermission } = useContext(UserContext);

  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);

  const styles = {
    ...configurationPanelStyles,
    contentStyles: {
      ...configurationPanelStyles.contentStyles,
      maxWidth: 1400,
    },
  };

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="Approvals"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      actionContent={
        <Fragment>
          <EditConfigurationButtons
            dirty={dirty}
            form={form}
            loading={loading}
          />
        </Fragment>
      }
      {...styles}
    >
      <Pane marginBottom={majorScale(3)}>
        <Text>
          Set a reviewer configuration to be applied to newly created projects.
        </Text>
        {hasTeamManagement && (
          <Text>
            {" "}
            If no team is selected, the configuration will be applied to
            projects created without an initial team assignment.
          </Text>
        )}
      </Pane>
      <UserAccessTable
        form={form}
        teams={teams}
        hasTeamManagement={hasTeamManagement}
        isOrganizationConfigurationTable
        orgTeamId={teamId}
        onTeamDropdownChange={setTeamId}
      />
    </Accordion.Panel>
  );
}

export function initialValues(organization, teamId) {
  const { teams, users, reviewerConfig: orgReviewerConfig } = organization;

  const teamMembershipsByUser = teams.reduce((allTeamsAcc, team) => {
    return team.memberships
      .filter((member) => !!member?.permissionConfig?.viewAllTeamProjects)
      .reduce((teamAcc, member) => {
        const { userId } = member;
        const currentUserTeams = teamAcc[userId] || [];
        return {
          ...teamAcc,
          [userId]: currentUserTeams.concat(team),
        };
      }, allTeamsAcc);
  }, {});

  const team = teams.find(({ id }) => id === teamId);

  const initialReviewers = team ? team.reviewerConfig : orgReviewerConfig;

  const preparedUsers = users.reduce(
    (acc, { id, fullName, permissionConfig }) => {
      const foundReviewer = initialReviewers.find(
        ({ userId }) => userId === id
      );

      const isPreparer = !!foundReviewer?.isPreparer;
      const hasSignatoryPermission = get(
        permissionConfig,
        "drawSignatory",
        false
      );
      const hasViewAllProjects = get(
        permissionConfig,
        "viewAllProjects",
        false
      );
      const hasViewAllTeamProjectsThisTeam = teamMembershipsByUser[id]?.find(
        ({ id }) => id === teamId
      );

      // Only include users that have viewAllProjects or viewAllTeamProjectsThisTeam
      if (!hasViewAllProjects && !hasViewAllTeamProjectsThisTeam) {
        return acc;
      }

      const isSignatory = !!foundReviewer?.isSignatory;

      return {
        ...acc,
        [id]: {
          id,
          fullName,
          hasAllProjectAccess: hasViewAllProjects,
          hasSignatoryPermission,
          isPreparer,
          isSignatory,
          isDrawReviewer: !!foundReviewer && !isPreparer && !isSignatory,
          teams: teamMembershipsByUser[id] ?? [],
        },
      };
    },
    {}
  );

  return {
    users: preparedUsers,
    documentReviewerIds: [],
    updateDraws: [],
  };
}

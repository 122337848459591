import { useContext, useEffect, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Small, SmallTickIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Checkbox,
  Heading,
  Link,
  Pane,
  Paragraph,
  Table,
  Text,
} from "components/materials";
import {
  getSearchByKey,
  mergeSearch,
  removeKey,
} from "helpers/queryStringHelpers";
import { majorScale } from "helpers/utilities";
import { RULE_STATE, RULE_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { groupBy, startCase } from "lodash";
import { UserContext } from "helpers/behaviors";
import { formatDateTime } from "helpers/dateHelpers";
import t from "helpers/translate";
import { COMMENT_QUERY, ADD_COMMENT, TRANSITION_RULE } from "./graphql";
import { getPlainTextRuleLabel } from "../../templates/DrawRules/RuleLabel";
import DrawRuleModal from "../../templates/DrawRules/DrawRuleModal";

export function Rules({ draw, project, history }) {
  const closeRuleModal = () => removeKey(history, "ruleId");

  const [transition, transitionResult] = useMutation(TRANSITION_RULE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COMMENT_QUERY,
        variables: { projectId: project.id, drawId: draw.id },
      },
    ],
    onCompleted: closeRuleModal,
  });

  const [comment, commentResult] = useMutation(ADD_COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COMMENT_QUERY,
        variables: { projectId: project.id, drawId: draw.id },
      },
    ],
    onCompleted: closeRuleModal,
  });

  const { hasPermission } = useContext(UserContext);

  const canEditProjectSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );

  const showAllRules = !!getSearchByKey(history, "showAllRules");
  const toggleShowAll = () =>
    showAllRules
      ? removeKey(history, "showAllRules")
      : mergeSearch(history, { showAllRules: true });

  const enabledRules = draw.rules.filter(({ enabled }) => enabled);
  const preparedRules = prepareDrawRules(enabledRules);
  const { passedRules, activeRules, sortedRules } = groupAndSortRules(
    preparedRules
  );
  const visibleRules = showAllRules ? sortedRules : sortedRules.slice(0, 5);
  const hasRules = enabledRules.length > 0;

  const ruleModalId = getSearchByKey(history, "ruleId");
  const ruleForModal = ruleModalId
    ? preparedRules.find((rule) => rule.id === ruleModalId)
    : null;

  const navigateToRules = () => {
    const route = `/projects/${project.id}/settings?settings=rules`;
    history.push(route);
  };

  const focusRules = getSearchByKey(history, "focusRules");

  useEffect(() => {
    if (focusRules) {
      const element = window.document.querySelector(`[id="drawOverviewRules"]`);

      // Ensure the element was found and has the needed function
      typeof element?.scrollIntoView === "function" &&
        element.scrollIntoView({ behavior: "smooth" });
    }
  }, [focusRules]);

  return (
    <Fragment>
      <Pane id="drawOverviewRules" borderBottom paddingY={majorScale(3)}>
        <Pane paddingX={majorScale(4)}>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            marginBottom={majorScale(3)}
          >
            <Pane display="flex">
              <Heading
                fontSize={16}
                fontWeight={600}
              >{`Rules: ${passedRules.length} / ${activeRules.length}`}</Heading>
              <Text color="muted" fontSize={12} marginLeft={majorScale(2)}>
                This is a list of all rules configured on this draw that should
                be passed prior to approval.
              </Text>
            </Pane>
            <Pane>
              {canEditProjectSettings && hasRules && (
                <Link onClick={navigateToRules} size={300}>
                  Edit Rules
                </Link>
              )}
              {hasRules && (
                <Link
                  onClick={toggleShowAll}
                  size={300}
                  marginLeft={majorScale(2)}
                >
                  {showAllRules ? "Hide" : "View All"}
                </Link>
              )}
            </Pane>
          </Pane>
          {hasRules ? (
            <Card>
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell />
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Updated By</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Updated Last</Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {visibleRules.map((rule) => {
                    const commonCellProps =
                      rule.state === RULE_STATE.IGNORED
                        ? { textProps: { color: "muted" } }
                        : {};

                    return (
                      <Table.Row
                        key={rule.id}
                        state={getRowState(rule)}
                        onClick={() =>
                          mergeSearch(history, { ruleId: rule.id })
                        }
                      >
                        <Table.TextCell>
                          {getRuleStateIcon(rule)}
                        </Table.TextCell>
                        <Table.TextCell {...commonCellProps}>
                          {rule.type === RULE_TYPE.AUTOMATED
                            ? getPlainTextRuleLabel(rule)
                            : rule.name}
                        </Table.TextCell>
                        <Table.TextCell {...commonCellProps}>
                          {`${startCase(t(rule.state))}${
                            rule.state === RULE_STATE.PASSED &&
                            !!rule.recentTransition?.user
                              ? " Manually"
                              : ""
                          }`}
                        </Table.TextCell>
                        <Table.TextCell {...commonCellProps}>
                          {getRuleUpdatedByText(rule)}
                        </Table.TextCell>
                        <Table.TextCell {...commonCellProps}>
                          {rule.recentTransition
                            ? formatDateTime(rule.recentTransition.insertedAt)
                            : null}
                        </Table.TextCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          ) : (
            <Fragment>
              <Paragraph>
                There are no rules configured for this draw.
              </Paragraph>
              {canEditProjectSettings && (
                <Button onClick={navigateToRules} marginTop={majorScale(2)}>
                  Edit Rules
                </Button>
              )}
            </Fragment>
          )}
        </Pane>
      </Pane>
      {/*
        the unwieldy API for this component is a relic of the legacy Review Page's architecture
        if/when that page is removed entirely, we can clean this up
      */}
      {!!ruleForModal && (
        <DrawRuleModal
          drawId={draw.id}
          projectId={project.id}
          rule={ruleForModal}
          modalRuleId={ruleForModal.id}
          autoRuleState={ruleForModal.autoRuleState}
          setManually={ruleForModal.setManually}
          comment={comment}
          commentResult={commentResult}
          transition={transition}
          transitionResult={transitionResult}
          closeRuleModal={() => removeKey(history, "ruleId")}
          users={project.users}
        />
      )}
    </Fragment>
  );
}

// rule activities are a combination of rule transitions and rule comments, constructed as [all_transitions] ++ [all_comments]
// within each partitioned set - transitions and comments, the activities are ordered most recent first
export function prepareDrawRules(rules) {
  return rules.map((rule) => {
    const ruleTransitions = rule.activities.filter(
      (activity) => activity.__typename === "RuleTransition"
    );

    const recentTransition = ruleTransitions[0];

    const setManually = recentTransition
      ? recentTransition.user !== null
      : false;

    const recentAutoRuleTransition = ruleTransitions.find(
      (transition) => transition.user === null
    );

    const autoRuleState = recentAutoRuleTransition
      ? recentAutoRuleTransition.toState
      : RULE_STATE.PENDING;
    return {
      ...rule,
      recentTransition,
      setManually,
      autoRuleState,
    };
  });
}

export function groupAndSortRules(rules) {
  const groupedRules = groupBy(rules, "state");
  const passedRules = groupedRules[RULE_STATE.PASSED] ?? [];
  const pendingRules = groupedRules[RULE_STATE.PENDING] ?? [];
  const ignoredRules = groupedRules[RULE_STATE.IGNORED] ?? [];

  // the rules are ordered: pending -> passed -> ignored
  const activeRules = pendingRules.concat(passedRules);
  const sortedRules = activeRules.concat(ignoredRules);

  return {
    passedRules,
    activeRules,
    sortedRules,
  };
}

function getRowState({ state }) {
  if (state === RULE_STATE.PENDING) return "pending";
  if (state === RULE_STATE.PASSED) return "success";
  if (state === RULE_STATE.IGNORED) return "ignored";
  return undefined;
}

function getRuleStateIcon(rule) {
  if (rule.state === RULE_STATE.PASSED) return <Small color="success" />;
  if (rule.state === RULE_STATE.PENDING)
    return (
      <Pane height={16}>
        <Checkbox checked={false} marginY={0} />
      </Pane>
    );
  if (rule.state === RULE_STATE.IGNORED) return <SmallTickIcon color="muted" />;
  return null;
}

function getRuleUpdatedByText(rule) {
  if (!rule.recentTransition) return null;

  if (rule.recentTransition?.user) {
    return rule.recentTransition.user.fullName;
  }

  // the most recent rule transition is not associated with a user
  if (rule.state === RULE_STATE.PASSED) {
    return "Passed By Rabbet";
  }

  return null;
}

import { Formik } from "formik";
import { v4 as uuid } from "uuid";
import { Form, Modal } from "components/materials";
import getOptions from "helpers/getOptions";
import { AddItem, ItemType } from "../types";
import { sampleTemplates } from "./EditableDrawPackage/data";

export interface AddTemplateAttributes {
  templateId: string;
}

export interface Props {
  open: boolean;
  onAdd: (items: AddItem[]) => void;
  onCancel(): void;
}

export function AddTemplateModal({ open, onAdd, onCancel }: Props) {
  const templates = sampleTemplates();
  const selectOptions = {
    label: "Template",
    name: "templateId",
    options: getOptions(templates),
  } as any;

  return (
    <Modal open={open} title="Add Template" onClose={onCancel}>
      <Formik
        initialValues={{
          templateId: null,
        }}
        validate={(values) => {
          const errors: Partial<AddTemplateAttributes> = {};

          if (!values.templateId) {
            errors.templateId = "Required";
          }

          return errors;
        }}
        onSubmit={(values) => {
          const selectedTemplate = templates.find(
            (t) => t.id === values.templateId
          );

          const template = {
            id: uuid(),
            name: selectedTemplate?.name ?? "Not found",
            type: ItemType.Template,
            attributes: {
              templateId: selectedTemplate?.id,
            },
          };

          onAdd([template]);
        }}
      >
        {(form) => (
          <Form.Generic
            textSubmit="Add"
            handleSubmit={form.handleSubmit}
            handleCancel={() => {
              form.resetForm();
              onCancel();
            }}
            cancelLabel="Cancel"
          >
            <Form.Select {...selectOptions} />
          </Form.Generic>
        )}
      </Formik>
    </Modal>
  );
}

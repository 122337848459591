import PropTypes from "prop-types";
import { Form, MaskedInput, Paragraph, Pane } from "components/materials";
import { DotIcon } from "evergreen-ui";
import { Field, getIn } from "formik";
import { get } from "lodash";

function FormInput({
  cursive = false,
  isHighlighted = false,
  isWarned = false,
  label,
  labelProps = {},
  onBlur = undefined,
  onChange = undefined,
  outerProps = {},
  type,
  ...props
}) {
  const getFieldProps = (field) => {
    const handleChange = (e) => {
      field.onChange(e);
      onChange && onChange(e);
    };

    const handleBlur = (e) => {
      field.onBlur(e);
      onBlur && onBlur(e);
    };

    return {
      ...field,
      style: cursive ? { fontFamily: "cursive" } : undefined,
      onBlur: handleBlur,
      onChange: handleChange,
    };
  };

  return (
    <Field {...props}>
      {({ form: { touched, errors, status, values }, field }) => {
        const touch = getIn(touched, field.name);
        const error = getIn(errors, field.name);
        const formDisabled = get(status, "disabled");
        return (
          <Form.Field
            label={
              label && (
                <Pane display="flex" alignItems="center">
                  {props.isRequired && <DotIcon color="danger" />}
                  <Paragraph {...labelProps}>{label}</Paragraph>
                </Pane>
              )
            }
            isWarned={isWarned}
            isHighlighted={isHighlighted}
            validationMessage={touch && error}
            {...outerProps}
          >
            <MaskedInput
              type={type}
              {...props}
              {...getFieldProps(field)}
              disabled={props.disabled || values.__disabled || formDisabled}
            />
          </Form.Field>
        );
      }}
    </Field>
  );
}

FormInput.propTypes = {
  cursive: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isHighlighted: PropTypes.bool,
  isWarned: PropTypes.bool,
  type: PropTypes.oneOf([
    "email",
    "text",
    "currency",
    "number",
    "integer",
    "percentage",
    "tel",
    "password",
    "zip",
  ]),
};

FormInput.defaultProps = {
  cursive: false,
  isHighlighted: false,
  isWarned: false,
  labelProps: {},
  onBlur: () => {},
  onChange: () => {},
  type: "text",
};

export default FormInput;

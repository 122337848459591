import { useContext } from "react";
import PropTypes from "prop-types";
import { Confirm, Pane } from "components/materials";
import {
  SendDrawContext,
  SendDrawContextProvider,
} from "contexts/sendDrawContext";
import { SendDrawModal } from "../SendDrawModal";

export function InnerSubmitDraw() {
  const {
    checkForWarnings,
    currentStepIndex,
    draw,
    handleClose,
    handleCloseCancel,
    handleCloseConfirm,
    openConfirm,
    openModal,
    projectId,
    setCheckForWarnings,
    setOpenConfirm,
    steps,
  } = useContext(SendDrawContext);

  return (
    <Pane>
      <Confirm
        content="Are you sure you want to cancel? All information will be lost."
        header="Cancel Submission"
        onCancel={handleCloseCancel}
        onConfirm={handleCloseConfirm}
        open={openConfirm}
        shouldCloseOnOverlayClick={false}
        cancelLabel="No, Back to Submission"
        confirmLabel="Yes, Cancel Submission"
        onCloseComplete={() => {
          setOpenConfirm(false);
        }}
      />
      <SendDrawModal
        checkForWarnings={checkForWarnings}
        drawId={draw.id}
        handleClose={handleClose}
        ModalContent={steps[currentStepIndex].step}
        open={openModal}
        onClick={() => {
          setCheckForWarnings(false);
        }}
        projectId={projectId}
        size={steps[currentStepIndex].size}
        title={steps[currentStepIndex].title}
      />
    </Pane>
  );
}

export function SubmitDraw({
  draw,
  hasSubmissions,
  onCloseConfirm,
  projectId,
}) {
  return (
    <SendDrawContextProvider
      draw={draw}
      hasSubmissions={hasSubmissions}
      onCloseConfirm={onCloseConfirm}
      projectId={projectId}
    >
      <InnerSubmitDraw />
    </SendDrawContextProvider>
  );
}

SubmitDraw.propTypes = {
  draw: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  hasSubmissions: PropTypes.bool.isRequired,
  onCloseConfirm: PropTypes.func,
};

SubmitDraw.defaultProps = {
  onCloseConfirm: () => {},
};

import { useContext, useMemo, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { DocumentActions, EditTableViews } from "components/containers";
import { IssuesViewName, IssuesInfoModal } from "components/templates";
import { Alert, Pane, Spinner, Text } from "components/materials";
import { FastDataTable, COMPARATORS } from "components/materials/FastDataTable";
import { UserContext } from "helpers/behaviors";
import {
  getIssuesFilterConfig,
  getIssuesRowState,
  GUIDING_EXPLANATION_SCOPES,
} from "helpers/issues";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { majorScale, ThemeContext } from "helpers/utilities";
import { flatMap, get, partition } from "lodash";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";
import { getColumns } from "./getColumns";
import { getControls } from "./getControls";
import { getDefaultViews } from "./getDefaultViews";
import { getUtilityColumns } from "./getUtilityColumns";

export function DocumentTable({
  deleteRefetch,
  defaultColumns,
  defaultFilters,
  defaultGroup,
  defaultSort,
  documentReviewersByProject,
  documents,
  drawId,
  draws,
  getRowState,
  history,
  match,
  onChange,
  onClickDocumentCard,
  organizationId,
  orgData,
  pinnedFilters,
  projectId,
  rightControls,
  selectedOrganization,
  suggestedDocumentAssignees,
  tableName,
  users,
}) {
  const theme = useContext(ThemeContext);
  const isReport = tableName === "ReportDocumentTable";
  const isSubmission = tableName === "SubmissionDocumentTable";
  const isDocumentCards = tableName === "DocumentCardsListTable";
  const isDrawDocuments = tableName === "DrawDocumentTable";
  const isLineItems = match.url.includes("/line_items/");
  const isVendors = match.url.includes("/organizations/");

  const {
    hasOrgLevelPermission,
    hasPermission,
    user,
    userFullName,
  } = useContext(UserContext);

  const hasDownloadPermission = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT
  );
  const [classifiedDocuments, unclassifiedDocuments] = useMemo(() => {
    return isReport
      ? [documents, []]
      : partition(documents, (document) => !!document.type);
  }, [documents, isReport]);

  const [issueItemForModal, setIssueItemForModal] = useState(null);

  const columns = useMemo(() => {
    return getColumns(
      hasOrgLevelPermission,
      hasPermission,
      isDrawDocuments,
      isDocumentCards,
      isReport,
      isSubmission,
      match,
      deleteRefetch,
      setIssueItemForModal,
      documentReviewersByProject,
      user,
      orgData
    );
  }, [
    hasOrgLevelPermission,
    hasPermission,
    isDrawDocuments,
    isDocumentCards,
    isReport,
    isSubmission,
    match,
    deleteRefetch,
    setIssueItemForModal,
    documentReviewersByProject,
    user,
    orgData,
  ]);

  const useFilterConfig = !isSubmission && !isLineItems && !isVendors;

  const documentIssues = flatMap(documents, ({ issues }) => issues || []);

  const defaultViewProps = [
    {
      filterConfig: [],
      name: "Default",
      isDefault: true,
    },
    ...(hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL)
      ? [
          {
            filterConfig: getIssuesFilterConfig(
              documentIssues,
              "documentIssues"
            ),
            formattedName: <IssuesViewName issues={documentIssues} />,
            name: "Issues",
            isDefault: true,
          },
        ]
      : []),
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.INVOICE}`)],
          key: "type",
        },
      ],
      name: "Invoices",
      isDefault: true,
    },
    {
      filterConfig: [
        {
          enum: [t(`documentTypeName.${DOCUMENT_TYPE_NAME.PAY_APPLICATION}`)],
          key: "type",
        },
      ],
      name: "Pay Applications",
      isDefault: true,
    },
    ...(hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS)
      ? [
          {
            filterConfig: [
              {
                enum: [],
                input: userFullName,
                key: "assignedTo",
                operator: COMPARATORS.EXACT.value,
              },
            ],
            name: "Assigned to Me",
            isDefault: true,
          },
        ]
      : []),
    {
      columnConfig: ["document", "type", "uploadedAt", "uploadedBy", "draw"],
      filterConfig: [
        {
          enum: [true],
          key: "isEmailImport",
        },
      ],
      sortConfig: { columnId: "uploadedAt", direction: "desc" },
      name: "Email Import",
      isDefault: true,
    },
  ];

  const defaultViews = getDefaultViews(
    defaultColumns,
    defaultFilters,
    defaultGroup,
    defaultSort,
    defaultViewProps,
    useFilterConfig
  );

  const onSerialize = (serialized) =>
    mergeSearch(
      history,
      isDocumentCards ? { list: serialized } : { table: serialized }
    );

  const onClickRow = hasDownloadPermission
    ? (document) => {
        return onClickDocumentCard
          ? onClickDocumentCard(document)
          : history.push({
              pathname: `${history.location.pathname}/${document.id}`,
              search: history.location.search,
            });
      }
    : () => {};

  const canUpdateDocuments = hasPermission(PERMISSION_ACTION.UPDATE_DOCUMENT);
  const canDeleteDocuments = hasPermission(PERMISSION_ACTION.DELETE_DOCUMENTS);

  return (
    <Fragment>
      {unclassifiedDocuments.length > 0 && (
        <Alert intent="none" marginX={majorScale(2)}>
          <Pane display="flex" justifyContent="space-between">
            <Text size={400}>
              {t("documentsPage.documentsProcessing", {
                count: unclassifiedDocuments.length,
              })}
            </Text>
            <Spinner size={majorScale(2)} />
          </Pane>
        </Alert>
      )}
      <EditTableViews
        canManagePublicViews={hasPermission(
          PERMISSION_ACTION.SAVE_TABLE_VIEWS,
          selectedOrganization
        )}
        config={getSearchByKey(history, isDocumentCards ? "list" : "table")}
        organizationIdToScopeViews={organizationId}
        defaultViews={defaultViews}
        tableName={tableName}
      >
        {(propsEditTableViews) => (
          <DocumentActions
            deleteRefetch={deleteRefetch}
            documents={classifiedDocuments}
            drawId={drawId}
            projectId={projectId}
            documentReviewers={documentReviewersByProject[projectId] || []}
            frozenDrawIds={
              draws
                ? draws.filter(({ isFrozen }) => !!isFrozen).map(({ id }) => id)
                : null
            }
          >
            {(propsDocumentActions) => (
              <FastDataTable
                columns={columns}
                controls={(controlsProps) =>
                  getControls(
                    controlsProps,
                    isDocumentCards,
                    isReport,
                    propsDocumentActions,
                    propsEditTableViews,
                    rightControls,
                    theme,
                    documents,
                    match,
                    hasPermission,
                    hasOrgLevelPermission,
                    users,
                    suggestedDocumentAssignees,
                    tableName,
                    draws,
                    drawId,
                    projectId,
                    documentReviewersByProject,
                    pinnedFilters
                  )
                }
                disableCollapse={isDocumentCards}
                footerTotals
                getRowState={(document) => {
                  return (
                    getRowState ||
                    (hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) &&
                      getIssuesRowState(get(document, "issues", [])))
                  );
                }}
                hideTableHeader={isDocumentCards}
                items={classifiedDocuments}
                onChange={onChange}
                onClickRow={onClickRow}
                onSerialize={onSerialize}
                serialized={
                  getSearchByKey(history, isDocumentCards ? "list" : "table") ||
                  get(propsEditTableViews, "views.0.config")
                }
                utilityColumns={getUtilityColumns(
                  isDocumentCards,
                  isReport,
                  isSubmission,
                  canUpdateDocuments,
                  canDeleteDocuments,
                  propsDocumentActions
                )}
              />
            )}
          </DocumentActions>
        )}
      </EditTableViews>
      {issueItemForModal && (
        <IssuesInfoModal
          issueItem={issueItemForModal}
          scope={GUIDING_EXPLANATION_SCOPES.DOCUMENT}
          onClose={() => setIssueItemForModal(null)}
        />
      )}
    </Fragment>
  );
}

DocumentTable.propTypes = {
  organizationId: PropTypes.string,
  defaultColumns: PropTypes.array.isRequired,
  defaultFilters: PropTypes.array,
  defaultGroup: PropTypes.object.isRequired,
  defaultSort: PropTypes.object,
  deleteRefetch: PropTypes.array,
  documents: PropTypes.array.isRequired,
  drawId: PropTypes.string,
  getRowState: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClickDocumentCard: PropTypes.func,
  onChange: PropTypes.func,
  projectId: PropTypes.string,
  rightControls: PropTypes.arrayOf(PropTypes.element),
  tableName: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  suggestedDocumentAssignees: PropTypes.arrayOf(PropTypes.object),
  documentReviewersByProject: PropTypes.object,
};

DocumentTable.defaultProps = {
  defaultFilters: undefined,
  defaultSort: undefined,
  deleteRefetch: [],
  drawId: undefined,
  getRowState: undefined,
  onChange: undefined,
  onClickDocumentCard: undefined,
  projectId: undefined,
  rightControls: undefined,
  users: [],
  suggestedDocumentAssignees: [],
  documentReviewersByProject: {},
};

import axios from "axios";
import { toaster } from "evergreen-ui";
import { useState } from "react";
import { useAuth } from "../../Auth";
import { getUrl, getFilenameFromResponseHeaders } from "../downloadHelpers";

function showDownloadErrorToast() {
  toaster.danger("We were not able to generate your download", {
    description:
      "Please try reducing the size of your download or contact support@rabbet.com for more help.",
  });
}

function showDownloadTimeoutToast() {
  toaster.notify(
    "Your download is taking a while. We'll email you when it's ready.",
    { duration: 60, hasCloseButton: true }
  );
}

function openFile(response, fileName = "download.zip") {
  const name = getFilenameFromResponseHeaders(response.headers) || fileName;
  // getUrl is a helper function that opens the save file dialog
  getUrl(response.data, name);
}

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

export function useDownloadMultipleDocuments({
  fileName = "download.zip",
  stamped = false,
  onError = showDownloadErrorToast,
  onTimeout = showDownloadTimeoutToast,
}) {
  const auth = useAuth();
  const accessToken = auth.getAccessToken();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const download = async (documentIds) => {
    setLoading(true);
    let url;

    if (stamped) {
      url = `${baseUrl}/download_multiple_stamped_documents`;
    } else {
      url = `${baseUrl}/download_multiple_documents`;
    }

    try {
      if (documentIds.length === 1) {
        window.open(`${url}/${documentIds[0]}?access_token=${accessToken}`);
      } else {
        const response = await axios.post(
          url,
          { document_ids: documentIds },
          {
            responseType: "blob",
            params: { access_token: accessToken },
          }
        );

        openFile(response, fileName);
      }
    } catch (error) {
      setError(true);

      if (error?.response?.status === 408) {
        onTimeout(error);
      } else {
        onError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    download,
    loading,
    error,
  };
}

import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { set } from "lodash";
import { Button, Modal } from "components/materials";
import {
  addYears,
  dateServerToForm,
  dateFormToServer,
  isBefore,
} from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import { getRulesCountsForDraw } from "helpers/ruleHelpers";
import analytics, { drawValues } from "helpers/analytics";
import { MarkFundedForm } from "./MarkFundedForm";
import { FUND_DRAW } from "./graphql";

export function MarkFunded({ project, draw, primary, ...props }) {
  const [openModal, setOpenModal] = useState(false);

  const [fundDraw, { loading }] = useMutation(FUND_DRAW);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  function handleSubmit(values) {
    handleClose();

    analytics.track("Draw Funded", {
      projectId: project.id,
      ...drawValues({
        ...draw,
        ...getRulesCountsForDraw(draw),
      }),
    });

    return fundDraw({
      variables: {
        drawId: draw.id,
        fundedDate: dateFormToServer(values.date),
      },
    });
  }

  return (
    <Modal
      onClose={handleClose}
      open={openModal}
      trigger={
        <Button
          appearance={primary ? "primary" : "default"}
          content="Mark Funded"
          purpose="mark-funded open"
          onClick={handleOpen}
          {...props}
        />
      }
      size="small"
      title="Draw Funded On"
    >
      <Modal.Content>
        <Formik
          initialValues={initialValues(draw.fundedDate)}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(form) => (
            <MarkFundedForm
              form={form}
              loading={loading}
              handleClose={handleClose}
            />
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

function initialValues(fundedDate) {
  return { date: fundedDate || dateServerToForm(new Date()) };
}

function validate(values) {
  const dateLimit = addYears(new Date(), 20);
  const errors = {};

  if (isBlank(values.date)) {
    set(errors, "date", "Please enter a date");
  }

  if (!isBlank(values.date) && !isBefore(values.date, dateLimit)) {
    set(errors, "date", "Date cannot be greater than 20 years from now");
  }

  return errors;
}

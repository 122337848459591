import { toBase64 } from "components/materials/FastDataTable";

export function getDefaultViews(
  defaultColumns,
  defaultFilters,
  defaultGroup,
  defaultSort,
  defaultViewProps,
  useFilterConfig
) {
  return defaultViewProps.reduce(
    (
      filters,
      { columnConfig, filterConfig, sortConfig, formattedName, name, isDefault }
    ) => {
      filters.push({
        config: toBase64({
          columnConfig: columnConfig || defaultColumns,
          filterConfig: defaultFilters || (useFilterConfig ? filterConfig : []),
          groupConfig: defaultGroup,
          sortConfig: sortConfig || defaultSort || {},
        }),
        name,
        formattedName,
        isDefault,
      });
      return filters;
    },
    []
  );
}

import { get, find, first, isArray, some, camelCase } from "lodash";
import { matchPath } from "react-router-dom";
import { ORGANIZATION_TYPE } from "helpers/enums";

const getUserPage = () => {
  const { pathname } = window.location;

  if (matchPath(pathname, { path: "/reports/documentation" }))
    return "Document Reports Page";
  if (
    matchPath(pathname, {
      path: "/projects/:projectId/draws/:drawId/documentation",
    })
  )
    return "Draw Documents Page";
  if (matchPath(pathname, { path: "/projects/:projectId/documentation" }))
    return "Project Documents Page";
  return pathname;
};

export function userContextHelpers({
  organizations,
  scopedOrganization,
  user,
  userProfiles,
}) {
  const defaultOrganization = scopedOrganization || first(organizations);
  const listProfilesForPermission = (action) =>
    userProfiles.reduce((acc, { id, organization, permissionConfig }) => {
      if (get(permissionConfig, camelCase(action), false)) {
        acc.push({ userId: id, organization });
      }
      return acc;
    }, []);
  return {
    get analyticsContext() {
      return {
        organization: this.organizationName,
        originationPage: getUserPage(),
        sendAnalyticsForOperation: true,
        userEmail: this.email,
        userName: this.userFullName,
      };
    },
    email: get(user, "email"),
    hasPermission: (permission, targetOrganization) => {
      const org =
        targetOrganization || scopedOrganization || defaultOrganization;
      if (!org?.id) return false;
      const targetProfile = find(
        userProfiles,
        (profile) => profile.organization.id === org.id
      );

      return (
        targetProfile &&
        get(targetProfile.permissionConfig, camelCase(permission), false)
      );
    },
    hasPermissionAcrossProfiles: (permission) => {
      return some(userProfiles, (profile) =>
        isArray(permission)
          ? permission.every((eachPermission) =>
              get(profile.permissionConfig, camelCase(eachPermission), false)
            )
          : get(profile.permissionConfig, camelCase(permission), false)
      );
    },
    hasOrgLevelPermission: (permission, targetOrganization) => {
      const org =
        targetOrganization || scopedOrganization || defaultOrganization;
      if (!org?.id) return false;
      return get(org.permissionConfig, camelCase(permission), false);
    },
    isDeveloper:
      get(defaultOrganization, "type") === ORGANIZATION_TYPE.BORROWER,
    isMultiOrgUser: userProfiles.length > 1,
    listProfilesForPermission,
    organizationId: get(defaultOrganization, "id"),
    organizationName: get(defaultOrganization, "name"),
    scopeOrgsToUserLevelPermission: (organizations, permission) => {
      const orgIdsWithPermission = listProfilesForPermission(permission).map(
        ({ organization }) => organization.id
      );

      return organizations.filter(
        ({ id }) => orgIdsWithPermission.indexOf(id) !== -1
      );
    },
    user,
    userProfiles,
    userFullName: get(user, "fullName"),
    userId: get(user, "id"),
  };
}

import "dom4";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "exceljsPolyfill";
import "intersection-observer";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "evergreen-ui";
import { UserConfirmation } from "components/containers";
import { ErrorFallback } from "components/templates";
import { InternetExplorerContextProvider } from "helpers/behaviors/InternetExplorerContext";
import analytics from "helpers/analytics";
import * as FullStory from "@fullstory/browser";
import { initI18n } from "i18n";
import Routes from "routes";
import theme from "theme";
import * as NewAuth from "./Auth";
import * as Dozer from "./Dozer";
import "normalize.css";
import "index.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-modern-drawer/dist/index.css";
import * as FeatureFlags from "./FeatureFlags";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_ENV || "local";
const fullStoryId = process.env.REACT_APP_FULLSTORY_ID;
const reactAppRelease = process.env.REACT_APP_RELEASE;
const release = process.env.REACT_APP_RELEASE || "dev";

(async () => {
  const FeatureFlagsProviderPromise = FeatureFlags.createProvider();

  const ignoreErrors = [
    "Non-Error promise rejection captured",
    "Map: Expected mapDiv of type HTMLElement but was passed null.",
  ];

  if (dsn) {
    Sentry.init({
      dsn,
      release,
      ignoreErrors,
      environment: environment.toLowerCase(),
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.5,
      integrations: [
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
    });
  }

  // Initialize FullStory on staging and prod, but not local
  if (fullStoryId) {
    FullStory.init({ orgId: fullStoryId });
  }

  initI18n();

  const FeatureFlagsProvider = await FeatureFlagsProviderPromise;

  const root = createRoot(document.getElementById("root"));

  root.render(
    <FeatureFlagsProvider>
      <ThemeProvider value={theme}>
        <Sentry.ErrorBoundary
          showDialog
          onError={() => analytics.track("Backend Error")}
          fallback={ErrorFallback}
        >
          <InternetExplorerContextProvider>
            {(valueInternetExplorerContext) => (
              <BrowserRouter
                getUserConfirmation={(message, callback) =>
                  UserConfirmation({
                    message,
                    callback,
                    valueInternetExplorerContext,
                  })
                }
              >
                <NewAuth.Provider>
                  <Dozer.Provider>
                    <Routes />
                  </Dozer.Provider>
                </NewAuth.Provider>
              </BrowserRouter>
            )}
          </InternetExplorerContextProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </FeatureFlagsProvider>
  );
})();

// eslint-disable-next-line no-console
if (reactAppRelease) console.log(reactAppRelease);

import { useState, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { Button, Heading, Loadable, Pane, Text } from "components/materials";
import { InspectionRequestModal } from "components/containers";
import { InspectionReportForm } from "components/templates";
import { formatDateTime } from "helpers/dateHelpers";
import { majorScale, toaster } from "helpers/utilities";
import { DOCUMENT_TYPE_NAME, INSPECTION_REPORT_STATUS } from "helpers/enums";

const QUERY = gql`
  query DrawInspectionPage($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      name
      streetAddress
      city
      state
      zip
      draw(id: $drawId) {
        id
        name
        divisions {
          id
          scopeId
          name
          lineItems {
            id
            name
            scopeId
            balanceToFundAmount
            budgetAmount
            hardCosts
            grossPercentComplete
            grossRequestedAmount
            grossRequestedToDateAmount
            retainageToDateAmount
            maxInspectionPercentCompletePrior
          }
        }
        documents {
          id
          isAcknowledged
          state
          type
          file {
            url
            name
          }
          vendor {
            id
          }
        }
        changeOrders: documents(type: ${DOCUMENT_TYPE_NAME.EXECUTED_CHANGE_ORDER}) {
          ... on AgreementDocument {
            id
            insertedAt
            agreement {
              id
              inspectionNote
            }
            file {
              url
              name
            }
          }
        }
        inspectionImages: documents(type: ${DOCUMENT_TYPE_NAME.INSPECTION_IMAGE}) {
          ... on InspectionImage {
            id
            insertedAt
            inspectionNote
            isGuestUpload
            file {
              url
              name
            }
          }
        }
        drawInspectionReport {
          id
          inspectorEmail
          inspectorName
          notes
          documentIds
          siteRepresentative
          date
          time
          weather
          status
          submittedAt
          questions {
            id
            label
            description
            fieldType
          }
          responses {
            id
            inspectionReportQuestionId
            response
          }
          lineItems {
            id
            drawLineItem {
              id
              scopeId
              budgetAmount
              grossPercentComplete
            }
            lineItemId
            notes
            percentComplete
          }
        }
        previousInspectionReport{
          id
          draw{
            id
            name
          }
          inspectorEmail
          lineItems{
            id
            lineItemId
            percentComplete
            drawLineItem {
              id
              scopeId
              budgetAmount
              grossRequestedToDateAmount
              retainageToDateAmount
            }
          }
          responses{
            id
            inspectionReportQuestionId
            response
          }
        }
      }
      rules {
        id
        scopeId
        config
        enabled
        name
      }
    }
  }
`;

const CREATE_INSPECTION_REPORT = gql`
  mutation createInspectionReport($drawId: String!) {
    createInspectionReport(drawId: $drawId) {
      id
    }
  }
`;

const UPDATE_INSPECTION_REPORT = gql`
  mutation UpdateInspectionReport(
    $reportId: String!
    $details: [InspectionDetailsInput]
    $responses: [InspectionResponseInput]
    $lineItems: [InspectionLineItemInput]
    $changeOrders: [InspectionChangeOrderInput]
    $images: [InspectionImageInput]
  ) {
    updateInspectionReport(
      reportId: $reportId
      details: $details
      responses: $responses
      lineItems: $lineItems
      changeOrders: $changeOrders
      images: $images
    ) {
      id
    }
  }
`;

const DELETE_INSPECTION_REPORT = gql`
  mutation deleteInspectionReport($reportId: String!) {
    deleteInspectionReport(reportId: $reportId) {
      status
    }
  }
`;

export function DrawInspectionPage({ match }) {
  const [open, setOpen] = useState(false);
  const { drawId, projectId } = match.params;
  const { data, loading } = useQuery(QUERY, {
    variables: { drawId, projectId },
  });
  const [saveInspectionReport, { loading: saveLoading }] = useMutation(
    UPDATE_INSPECTION_REPORT,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: { drawId, projectId } }],
      onCompleted: () =>
        toaster.success("Your report has been saved.", {
          duration: 2.5,
        }),
    }
  );
  const [createInspectionReport, { loading: createLoading }] = useMutation(
    CREATE_INSPECTION_REPORT,
    {
      refetchQueries: [{ query: QUERY, variables: { drawId, projectId } }],
    }
  );
  const [deleteInspectionReport] = useMutation(DELETE_INSPECTION_REPORT, {
    refetchQueries: [{ query: QUERY, variables: { drawId, projectId } }],
  });

  if (loading) return <Loadable loading />;

  const drawInspectionReport = get(data, "project.draw.drawInspectionReport");
  const previousInspectionReport = get(
    data,
    "project.draw.previousInspectionReport"
  );
  const additionalHeading =
    get(drawInspectionReport, "inspectorEmail") &&
    (drawInspectionReport.status === INSPECTION_REPORT_STATUS.SUBMITTED
      ? `Submitted by ${drawInspectionReport.inspectorEmail} at
  ${formatDateTime(drawInspectionReport.submittedAt)}`
      : `In Progress by ${drawInspectionReport.inspectorEmail}`);

  return drawInspectionReport ? (
    <InspectionReportForm
      project={data.project}
      inspectionReport={drawInspectionReport}
      saveInspectionReport={saveInspectionReport}
      additionalHeading={additionalHeading}
      saveLoading={saveLoading}
      previousInspectionReport={previousInspectionReport}
      deleteInspectionReport={deleteInspectionReport}
    />
  ) : (
    <Fragment>
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop={majorScale(8)}
        width="100%"
      >
        <Heading fontSize={20} fontWeight={500}>
          No inspection reports submitted.
        </Heading>
        <Button marginTop={majorScale(3)} onClick={() => setOpen(true)}>
          Send an inspection request
        </Button>
        <Text marginY={majorScale(2)}>or</Text>
        <Button
          isLoading={createLoading}
          onClick={() => {
            createInspectionReport({ variables: { drawId } });
          }}
        >
          Fill out an inspection request
        </Button>
      </Pane>
      <InspectionRequestModal
        drawId={drawId}
        documents={data.project.draw.documents}
        open={open}
        projectId={projectId}
        refetchQueries={[{ query: QUERY, variables: { projectId, drawId } }]}
        setOpen={setOpen}
        previousInspectionReport={previousInspectionReport}
      />
    </Fragment>
  );
}

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { ContentLayout, RegistrationForm } from "components/templates";
import { Loadable, Pane } from "components/materials";
import { get } from "lodash";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import { majorScale, toaster } from "helpers/utilities";
import { useAuth } from "../../Auth";

const QUERY = gql`
  query RegisterPageQuery($invitationToken: String!) {
    user(invitationToken: $invitationToken) {
      id
      firstName
      hasRegisteredAliases
      lastName
      email
      registrationComplete
      isSso
    }
  }
`;

const REGISTER_USER = gql`
  mutation RegisterPageMutation(
    $invitationToken: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $passwordConfirmation: String
  ) {
    registerUser(
      invitationToken: $invitationToken
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
    }
  }
`;

function initialValues(data, invitationToken) {
  return {
    invitationToken,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    email: data.user.email,
    password: "",
    passwordConfirmation: "",
    isSso: data.user.isSso,
  };
}

function validate(values) {
  const errors = {};

  if (isBlank(values.firstName)) errors.firstName = "Please enter a first name";
  if (isBlank(values.lastName)) errors.lastName = "Please enter a last name";
  if (isBlank(values.email)) {
    errors.email = "Please enter an email";
  } else if (isInvalidEmail(values.email)) {
    errors.email = "Please enter a valid email";
  }

  if (!values.isSso) {
    if (isBlank(values.password)) errors.password = "Please enter a password";
    if (values.passwordConfirmation !== values.password) {
      errors.passwordConfirmation = "Password does not match";
    }
  }

  return errors;
}

function onError(result) {
  const graphQlErrors =
    result && result.graphQLErrors
      ? result.graphQLErrors.map(({ message }) => message).join(", ")
      : null;
  const errorMessage =
    graphQlErrors || (result && result.message) || "Something went wrong";

  toaster.danger(errorMessage);
}

export function RegisterPage({ history, match }) {
  const { invitationToken } = match.params;
  const [registerUser, registerUserResult] = useMutation(REGISTER_USER, {
    onError,
  });

  const { data, loading } = useQuery(QUERY, { variables: { invitationToken } });

  const auth = useAuth();

  function onSubmit({ email, ...otherVariables }) {
    registerUser({
      variables: { email: email.trim(), ...otherVariables },
    }).then(() => {
      if (otherVariables.isSso) {
        auth.login();
      } else {
        auth.passwordLogin(email, otherVariables.password, () =>
          history.push("/")
        );
      }
    });
  }

  if (loading) return <Loadable loading />;

  if (!data || !data.user)
    return <Pane margin={majorScale(2)}>This invitation has expired.</Pane>;

  const registrationComplete = get(data, "user.registrationComplete");
  if (registrationComplete) {
    history.push("/");
  }

  const hasRegisteredAliases = get(data, "user.hasRegisteredAliases");

  if (hasRegisteredAliases) {
    // if a user already exists in another organization, don't show them the
    // new user registration form, instead redirect the user to login and show the
    // accept invitation page after successful login
    auth.setReturnTo(`/accept_invitation/${invitationToken}`);
    history.push("/login");
  }

  const isSsoUser = get(data, "user.isSso", false);

  return (
    <ContentLayout fixedWidth title="Finish Registration">
      <Formik
        initialValues={initialValues(data, invitationToken)}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(form) => (
          <RegistrationForm
            handleSubmit={form.handleSubmit}
            loading={registerUserResult.loading}
            error={registerUserResult.error}
            isSsoUser={isSsoUser}
          />
        )}
      </Formik>
    </ContentLayout>
  );
}

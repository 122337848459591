import { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { EditIcon, TrashIcon, PlusIcon } from "evergreen-ui";
import { useFeatureFlags } from "FeatureFlags";
import { Button, Pane, SuperConfirm } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { FileUploadModal } from "features/uploads";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { UploadFiles } from "../UploadFiles";
import { UpdateDraw } from "./UpdateDraw";
import { DELETE_DRAW } from "./graphql";
import { Downloads } from "./Downloads";

export function SecondaryActions({
  history,
  project,
  isMostRecentDraw,
  drawIsInLockedState,
}) {
  const featureFlags = useFeatureFlags();
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");
  const [showUploadModal, setShowUploadModal] = useState(false);

  const uploadDocument = getSearchByKey(history, "uploadDocument") === "true";

  useEffect(() => {
    if (uploadDocument) {
      setShowUploadModal(true);
    }
  }, [uploadDocument]);

  const [showUpdateDraw, setShowUpdateDraw] = useState(false);
  const [showConfirmDeleteDraw, setShowConfirmDeleteDraw] = useState(false);

  const [deleteDraw] = useMutation(DELETE_DRAW, {
    onCompleted: () => {
      window.location.assign(`/projects/${project.id}`);
    },
  });

  const { hasPermission, hasOrgLevelPermission } = useContext(UserContext);
  const useAdvancedDrawStatus = hasOrgLevelPermission(
    PERMISSION_ACTION.EDIT_DRAW_STATUS
  );
  const canEditDraw = hasPermission(PERMISSION_ACTION.CREATE_DRAW);
  const canDeleteDraw = hasPermission(PERMISSION_ACTION.DELETE_DRAW);
  const canDownload = hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT);

  const { draw, lineItems, organization } = project;

  const showDrawStateUpdates =
    useAdvancedDrawStatus && draw.drawStateUpdates.length > 0;

  if (drawIsInLockedState && canDownload)
    return <Downloads draw={draw} project={project} />;

  if (drawIsInLockedState) return null;

  return (
    <Pane whiteSpace="nowrap">
      {useNewUploads && (
        <Button
          onClick={() => setShowUploadModal(true)}
          purpose="add-document open"
          iconBefore={PlusIcon}
          appearance="minimal"
          color="default"
          fontWeight="600"
        >
          Documents
        </Button>
      )}
      {useNewUploads && (
        <FileUploadModal
          projectId={project.id}
          drawId={draw.id}
          isFrozen={draw.isFrozen}
          open={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSubmit={() => setShowUploadModal(false)}
          targetName={draw.name}
        />
      )}
      {!useNewUploads && (
        <UploadFiles
          buttonProps={{
            iconBefore: PlusIcon,
            appearance: "minimal",
            color: "default",
            fontWeight: 600,
          }}
          text="Documents"
          documentTemplates={organization.documentTemplates}
          drawId={draw.id}
          drawName={draw.name}
          isFrozen={!!draw.isFrozen}
          lineItems={lineItems}
          openModal={getSearchByKey(history, "uploadDocument")}
          projectId={project.id}
          projectStatus={project.status}
        />
      )}
      {canDownload && <Downloads draw={draw} project={project} />}
      {(canEditDraw || showDrawStateUpdates) && (
        <Button
          appearance="minimal"
          color="default"
          iconAfter={EditIcon}
          onClick={() => {
            setShowUpdateDraw(true);
          }}
          marginLeft={majorScale(1)}
        >
          Edit
        </Button>
      )}
      {canDeleteDraw && isMostRecentDraw && (
        <Button
          appearance="minimal"
          intent="danger"
          iconAfter={TrashIcon}
          onClick={() => {
            setShowConfirmDeleteDraw(true);
          }}
          marginLeft={majorScale(1)}
        >
          Delete
        </Button>
      )}
      {/* MODALS */}
      <UpdateDraw
        open={showUpdateDraw}
        draw={draw}
        onClose={() => setShowUpdateDraw(false)}
        canEditDraw={canEditDraw}
        showDrawStateUpdates={showDrawStateUpdates}
      />
      <SuperConfirm
        isShown={showConfirmDeleteDraw}
        title="DELETE THE DRAW AND ALL CORRESPONDING DOCUMENTS"
        onConfirm={() => deleteDraw({ variables: { drawId: draw.id } })}
        onCloseComplete={() => setShowConfirmDeleteDraw(false)}
        warningMessage="Warning: By typing delete you will PERMANENTLY DELETE ALL DOCUMENTS and information associated with this draw. This is IRREVERSIBLE. If that is the desire, please type DELETE ALL DRAW INFORMATION"
        confirmMatchText="DELETE ALL DRAW INFORMATION"
      />
    </Pane>
  );
}

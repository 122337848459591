import { Fragment, useContext, useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { OrganizationSelector } from "components/templates";
import { Accordion, Loadable, Pane } from "components/materials";
import { reduce, some, values } from "lodash";
import analytics from "helpers/analytics";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { majorScale, toaster } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { Mri, PANEL_KEY as mriKey } from "./Mri";
import { ProjectRegions, PANEL_KEY as regionsPanelKey } from "./ProjectRegions";
import {
  ProductTypes,
  PANEL_KEY as productTypesPanelKey,
} from "./ProductTypes";
import {
  ProjectReviewers,
  PANEL_KEY as projectReviewersKey,
} from "./ProjectReviewers";
import { Tasks, PANEL_KEY as tasksKey } from "./Tasks";
import {
  QuickBooksOnline,
  PANEL_KEY as quickBooksOnlineKey,
} from "./QuickBooksOnline";
import { BillCom, PANEL_KEY as billComKey } from "./BillCom";
import { Yardi, PANEL_KEY as yardiKey } from "./Yardi";
import { ScopeOrganizations } from "../ScopeOrganizations";

export function ConfigurationPage() {
  return (
    <ScopeOrganizations
      scopeToUserPermission={PERMISSION_ACTION.CONFIGURE_ORGANIZATION}
    >
      {({
        onOrganizationSelected,
        allOrganizations,
        disabledOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane marginLeft={majorScale(2)} marginTop={majorScale(2)}>
            <OrganizationSelector
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              onOrganizationSelected={onOrganizationSelected}
              marginRight={majorScale(2)}
              title="Viewing configuration for"
            />
          </Pane>
          <ConfigurationPageInner selectedOrganization={selectedOrganization} />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

function ConfigurationPageInner({ selectedOrganization }) {
  const { data, loading } = useQuery(CONFIGURATTION_PAGE_QUERY, {
    variables: { organizationId: selectedOrganization.id },
  });

  const { hasPermission, hasOrgLevelPermission } = useContext(UserContext);

  const [dirtyPanels, setDirtyPanels] = useState(getInitialPanelsState());

  const setPanelDirty = (panelKey, isDirty) => {
    setDirtyPanels({
      ...dirtyPanels,
      [panelKey]: isDirty,
    });
  };

  const [
    updateOrganizationConfiguration,
    { loading: updateOrgLoading },
  ] = useMutation(UPDATE_ORGANIZATION_CONFIGURATION, {
    onCompleted: () => showSuccessToast(),
    onError: () => showErrorToast(),
  });

  const [
    updateOrganizationReviewerConfiguration,
    { loading: updateOrgReviewerLoading },
  ] = useMutation(UPDATE_ORGANIZATION_REVIEWER_CONFIGURATION, {
    onCompleted: () => showSuccessToast(),
    onError: (e) => {
      analytics.track("Error updating reviewer configuration", e);

      showErrorToast();
    },
  });

  const [
    updateTasksForProjectType,
    { loading: updateTasksLoading },
  ] = useMutation(UPDATE_TASKS_FOR_PROJECT_TYPE, {
    onCompleted: () => showSuccessToast(),
    onError: () => showErrorToast(),
  });

  const handleProjectRegions = ({ projectRegions }) => {
    const variables = {
      organizationId: selectedOrganization.id,
      projectRegions: projectRegions.map(({ id, region }) => ({
        id,
        region: region.trim(),
      })),
    };

    return updateOrganizationConfiguration({ variables });
  };

  const handleProductTypes = ({ productTypes }) => {
    const variables = {
      organizationId: selectedOrganization.id,
      productTypes: productTypes.map(({ id, type }) => ({
        id,
        type: type.trim(),
      })),
    };

    return updateOrganizationConfiguration({ variables });
  };

  const handleMri = ({ externalRetainageCode }) => {
    const variables = {
      organizationId: selectedOrganization.id,
      externalRetainageCode,
    };

    return updateOrganizationConfiguration({ variables });
  };

  const handleProjectReviewers = (values, teamId) => {
    const preparedReviewers = Object.values(values.users)
      .filter(
        (user) => user.isDrawReviewer || user.isSignatory || user.isPreparer
      )
      .map(({ id, isSignatory, isPreparer }) => ({
        userId: id,
        isSignatory,
        isPreparer,
      }));

    const variables = {
      organizationId: selectedOrganization.id,
      reviewerConfig: {
        teamId,
        reviewers: preparedReviewers,
      },
    };

    return updateOrganizationReviewerConfiguration({ variables });
  };

  const handleTasks = ({ tasks, projectTypeId }) => {
    const variables = {
      projectTypeId,
      tasks: tasks.map(({ id, name }) => ({
        id,
        name: name.trim(),
      })),
    };

    updateTasksForProjectType({ variables });
  };

  const mutationLoading =
    updateOrgLoading || updateOrgReviewerLoading || updateTasksLoading;

  const hasQuickBooksOnline = hasPermission(
    PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE
  );

  const hasBillCom = hasPermission(PERMISSION_ACTION.PUSH_TO_BILL);

  if (loading) return <Loadable loading />;

  return (
    <Fragment>
      <NavigationWarnings dirty={hasDirtyPanel(dirtyPanels)} />
      <Accordion
        allPanelKeys={values(PANELS)}
        defaultActiveKeys={[]}
        showExpandCollapseAll
        padding={majorScale(2)}
        render={({ toggle, expandedPanelKeys }) => {
          return (
            <Fragment>
              <ProjectRegions
                organization={data.organization}
                onSubmit={handleProjectRegions}
                loading={mutationLoading}
                expandedPanelKeys={expandedPanelKeys}
                toggle={toggle}
                dirtyPanels={dirtyPanels}
                setPanelDirty={setPanelDirty}
              />
              <ProductTypes
                organization={data.organization}
                onSubmit={handleProductTypes}
                loading={mutationLoading}
                expandedPanelKeys={expandedPanelKeys}
                toggle={toggle}
                dirtyPanels={dirtyPanels}
                setPanelDirty={setPanelDirty}
              />
              <ProjectReviewers
                organization={data.organization}
                onSubmit={handleProjectReviewers}
                loading={mutationLoading}
                expandedPanelKeys={expandedPanelKeys}
                toggle={toggle}
                dirtyPanels={dirtyPanels}
                setPanelDirty={setPanelDirty}
              />
              {hasPermission(PERMISSION_ACTION.TASK_MANAGEMENT) && (
                <Tasks
                  projectTypes={data.organization.projectTemplates}
                  handleTasks={handleTasks}
                  loading={false}
                  expandedPanelKeys={expandedPanelKeys}
                  toggle={toggle}
                  dirtyPanels={dirtyPanels}
                  setPanelDirty={setPanelDirty}
                />
              )}
              {hasBillCom && (
                <BillCom
                  organization={data.organization}
                  expandedPanelKeys={expandedPanelKeys}
                  toggle={toggle}
                />
              )}
              {hasQuickBooksOnline && (
                <QuickBooksOnline
                  organization={data.organization}
                  expandedPanelKeys={expandedPanelKeys}
                  toggle={toggle}
                />
              )}
              {hasPermission(PERMISSION_ACTION.PULL_DATA_FROM_YARDI) && (
                <Yardi
                  organization={data.organization}
                  expandedPanelKeys={expandedPanelKeys}
                  toggle={toggle}
                />
              )}
              {hasOrgLevelPermission(PERMISSION_ACTION.PUSH_TO_MRI) &&
                hasPermission(
                  PERMISSION_ACTION.SEND_RETAINAGE_TO_ACCOUNTS_PAYABLE
                ) && (
                  <Mri
                    organization={data.organization}
                    onSubmit={handleMri}
                    loading={mutationLoading}
                    expandedPanelKeys={expandedPanelKeys}
                    toggle={toggle}
                    dirtyPanels={dirtyPanels}
                    setPanelDirty={setPanelDirty}
                  />
                )}
            </Fragment>
          );
        }}
      />
    </Fragment>
  );
}

const PANELS = {
  PROJECT_REGIONS: regionsPanelKey,
  PRODUCT_TYPES: productTypesPanelKey,
  PROJECT_REVIEWERS: projectReviewersKey,
  TASKS: tasksKey,
  QUICK_BOOKS_ONLINE: quickBooksOnlineKey,
  BILL_COM: billComKey,
  YARDI: yardiKey,
  MRI: mriKey,
};

const CONFIGURATION_PAGE_FRAGMENT = gql`
  fragment ConfigurationPageFragment on Organization {
    id
    projectRegions {
      id
      region
      hasBeenUsed
    }
    productTypes {
      id
      type
      hasBeenUsed
    }
    projectsWithYardiStatus {
      id
      accountsPayableLastSyncedAt
      accountsPayableSyncFailingSince
      accountsPayableSyncErrorMessage
      name
    }
    teams {
      id
      reviewerConfig {
        userId
        isSignatory
        isPreparer
      }
      memberships {
        id
        permissionConfig
        userId
      }
      name
    }
    reviewerConfig {
      userId
      isSignatory
      isPreparer
    }
    projectTemplates {
      id
      name
      tasks {
        id
        name
      }
    }
    externalRetainageCode
    billComAuthorizations {
      id
      externalProjectId
      lastVendorSyncAt
    }
    qboAuthorizations {
      id
      externalProjectId
      lastVendorSyncAt
    }
    billComAutomaticSyncEnabled
    qboAutomaticSyncEnabled
    yardiAutomaticSyncEnabled
    yardiVendorsLastSyncedAt
    yardiVendorsSyncFailingSince
    yardiVendorsSyncErrorMessage
  }
`;

export const CONFIGURATTION_PAGE_QUERY = gql`
  query ConfigurationPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      ...ConfigurationPageFragment
      users {
        id
        fullName
        permissionConfig
      }
    }
  }
  ${CONFIGURATION_PAGE_FRAGMENT}
`;

const UPDATE_ORGANIZATION_CONFIGURATION = gql`
  mutation UpdateOrganizationConfiguration(
    $organizationId: String!
    $projectRegions: [ProjectRegionInput]
    $productTypes: [ProductTypeInput]
    $externalRetainageCode: String
  ) {
    updateOrganizationConfiguration(
      organizationId: $organizationId
      projectRegions: $projectRegions
      productTypes: $productTypes
      externalRetainageCode: $externalRetainageCode
    ) {
      ...ConfigurationPageFragment
    }
  }
  ${CONFIGURATION_PAGE_FRAGMENT}
`;

const UPDATE_ORGANIZATION_REVIEWER_CONFIGURATION = gql`
  mutation UpdateOrganizationReviewerConfiguration(
    $organizationId: String!
    $reviewerConfig: ReviewerConfigInput!
  ) {
    updateOrganizationReviewerConfiguration(
      organizationId: $organizationId
      reviewerConfig: $reviewerConfig
    ) {
      ...ConfigurationPageFragment
    }
  }
  ${CONFIGURATION_PAGE_FRAGMENT}
`;

const UPDATE_TASKS_FOR_PROJECT_TYPE = gql`
  mutation UpdateTasksForProjectType($projectTypeId: ID!, $tasks: [TaskInput]) {
    updateTasksForProjectType(projectTypeId: $projectTypeId, tasks: $tasks) {
      id
      tasks {
        id
        name
      }
    }
  }
`;

function getInitialPanelsState() {
  return reduce(
    values(PANELS),
    (acc, panelKey) => ({
      ...acc,
      [panelKey]: false,
    }),
    {}
  );
}

function hasDirtyPanel(panels) {
  return some(panels, (isDirty) => !!isDirty);
}

function showSuccessToast() {
  toaster.success("Your changes have been saved.", { duration: 2.5 });
}

function showErrorToast() {
  toaster.danger("Error, could not save.", { duration: 2.5 });
}

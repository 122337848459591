import { v4 as uuid } from "uuid";
import { Loadable, Modal } from "components/materials";
import { formatFastDocuments } from "helpers/documentHelpers";
import { AddItem, FastDocument, ItemType } from "../../types";
import useDocumentsQuery from "../../api/queries/useDocumentsQuery";
import { DocumentsTable } from "./DocumentsTable";

export interface AddDocumentsAttributes {
  documentIds: string[];
}

export interface Props {
  open: boolean;
  projectId: string;
  drawId: string;
  onAdd: (items: AddItem[]) => void;
  onCancel(): void;
}

export function AddDocumentsModal({
  open,
  onAdd,
  onCancel,
  projectId,
  drawId,
}: Props) {
  const { data, loading } = useDocumentsQuery({ projectId, drawId });

  const fastDocuments =
    data?.project?.draw?.fastDocuments ?? ([] as FastDocument[]);

  const documents = formatFastDocuments(
    fastDocuments.filter((document: FastDocument) => !document.parentToBeSplit)
  );

  const handleAdd = (documents: FastDocument[]) => {
    const items = documents.map((document) => ({
      id: uuid(),
      name: document.originalFileName,
      type: ItemType.Document,
      attributes: {
        documentId: document.id,
      },
    }));

    onAdd(items);
  };

  return (
    <Modal size="large" open={open} title="Add Documents" onClose={onCancel}>
      <Loadable loading={loading}>
        <DocumentsTable onAdd={handleAdd} documents={documents} />
      </Loadable>
    </Modal>
  );
}

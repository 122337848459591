import { useState, useContext, Fragment } from "react";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import {
  BlankSlate,
  DrawFundingSourcesForm,
  MissingFundingSources,
} from "components/templates";
import {
  InsufficientFundsWarningModal,
  ERROR_MESSAGE,
} from "components/templates/InsufficientFundsWarningModal";
import { Alert, Loadable, Text } from "components/materials";
import {
  DISBURSEMENT_TARGET,
  DRAW_STATE,
  PERMISSION_ACTION,
} from "helpers/enums";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { formatCurrency } from "helpers/formatCurrency";
import { find, flatMap, forEach, get, set, values } from "lodash";
import { tablePlaceholder } from "images";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";

const QUERY = gql`
  query DrawFundingSourcesPageQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      automaticAllocationEnabled
      draw(id: $drawId) {
        id
        autoAllocationSuggestions {
          id
          amount
          fundingSourceId
          lineItemId
        }
        automaticAllocationEnabled
        fundingConfirmed
        fundingSources {
          id
          scopeId
          amount
          disbursedAmount
          disbursedPreviouslyAmount
          disbursements {
            id
            disbursedAmount
            lineItemId
          }
          label
        }
        isLockedDown
        lineItems {
          id
          scopeId
          division {
            id
            scopeId
            name
          }
          name
          requestedAmount
        }
        state
      }
      draws {
        id
        fundingConfirmed
        name
        state
      }
      usesOfFundsEnabled
    }
  }
`;

const DISBURSE_FUNDS = gql`
  mutation DisburseFunds(
    $automaticAllocationEnabled: Boolean!
    $bypassChecks: Boolean!
    $disbursements: [DisbursementInput]!
    $disbursementTarget: DisbursementTarget!
    $drawId: String!
  ) {
    disburseFunds(
      automaticAllocationEnabled: $automaticAllocationEnabled
      bypassChecks: $bypassChecks
      disbursements: $disbursements
      disbursementTarget: $disbursementTarget
      drawId: $drawId
    ) {
      id
    }
  }
`;

function getInitialValues({
  automaticAllocationEnabled,
  fundingSources,
  hasUsesOfFunds,
  lineItems,
}) {
  const values = { automaticAllocationEnabled, disbursements: {} };
  if (hasUsesOfFunds) {
    lineItems.forEach((lineItem) => {
      values.disbursements[lineItem.id] = {};
      fundingSources.forEach((fundingSource) => {
        const disbursement =
          fundingSource.disbursements.find(
            (d) => d.lineItemId === lineItem.id
          ) || {};
        values.disbursements[lineItem.id][fundingSource.id] = {
          amount: formatCurrency(disbursement.disbursedAmount),
          id: disbursement.id,
        };
      });
    });
  } else {
    values.disbursements.null = {};
    fundingSources.forEach((fundingSource) => {
      values.disbursements.null[fundingSource.id] = {
        amount: formatCurrency(fundingSource.disbursedAmount),
        id: fundingSource.id,
      };
    });
  }
  return values;
}

function validate(values) {
  const errors = {};
  forEach(values.disbursements, (disbursement, lineItemId) =>
    forEach(disbursement, ({ amount }, fundingSourceId) => {
      if (isBlank(amount)) {
        set(
          errors,
          `disbursements.${lineItemId}.${fundingSourceId}.amount`,
          "Please enter an amount"
        );
      }
    })
  );
  return errors;
}

function DrawFundingSourcesAlerts({
  drawId,
  draws,
  hasUsesOfFunds,
  projectId,
}) {
  const drawsFunded = draws.filter((d) => d.state === DRAW_STATE.FUNDED);
  const drawsFundedNotConfirmed = drawsFunded.filter(
    (d) => !d.fundingConfirmed
  );
  const drawFundedNotConfirmed = drawsFundedNotConfirmed.find(
    (d) => d.id === drawId
  );

  if (drawFundedNotConfirmed) {
    return <Alert title={t("fundingSources.unconfirmed")} intent="warning" />;
  }

  if (drawsFundedNotConfirmed.length > 0) {
    return (
      <Alert title={t("fundingSources.missingDrawLine1")} intent="warning">
        <Text>{t("fundingSources.missingDrawLine2")}</Text>
        <MissingFundingSources
          fundedDraws={drawsFunded}
          projectId={projectId}
          usesOfFundsOn={hasUsesOfFunds}
        />
      </Alert>
    );
  }

  return null;
}

export function DrawFundingSourcesPage({ match }) {
  const [showFundsWarning, setShowFundsWarning] = useState(false);
  const { drawId, projectId } = match.params;
  const { hasPermission } = useContext(UserContext);
  const { data, loading } = useQuery(QUERY, {
    variables: { drawId, projectId },
  });

  function onDisburseError(error) {
    const errors = get(error, "graphQLErrors", []);
    const insufficientFunds = find(errors, ["message", ERROR_MESSAGE]);
    if (insufficientFunds) {
      setShowFundsWarning(true);
      return null;
    }
    throw new ApolloError(error);
  }

  const [disburseFunds, disburseFundsResult] = useMutation(DISBURSE_FUNDS, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, variables: { drawId, projectId } }],
    onError: onDisburseError,
  });

  if (loading) return <Loadable />;

  const draws = get(data, "project.draws", []);
  const lineItems = get(data, "project.draw.lineItems", []);
  const fundingSources = get(data, "project.draw.fundingSources", []);
  const autoAllocationSuggestions = get(
    data,
    "project.draw.autoAllocationSuggestions",
    []
  );
  const automaticAllocationEnabled = get(
    data,
    "project.draw.automaticAllocationEnabled",
    false
  );
  const fundingConfirmed = get(data, "project.draw.fundingConfirmed", false);
  const isLockedDown = get(data, "project.draw.isLockedDown", false);
  const isFunded = get(data, "project.draw.state") === DRAW_STATE.FUNDED;
  const hasUsesOfFunds =
    hasPermission(PERMISSION_ACTION.USES_OF_FUNDS) &&
    get(data, "project.usesOfFundsEnabled");
  const hasAutoAllocate =
    hasPermission(PERMISSION_ACTION.AUTO_ALLOCATE) &&
    get(data, "project.automaticAllocationEnabled");
  const canEditSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );

  if (lineItems.length === 0) {
    return (
      <BlankSlate
        attached
        prompt={t("blankSlate.uploadBudget")}
        src={tablePlaceholder}
        to={`/projects/${projectId}/budget`}
      />
    );
  }

  if (fundingSources.length === 0) {
    const promptProps = canEditSettings
      ? {
          prompt: t("blankSlate.setUpFundingSources"),
          to: `/projects/${projectId}/settings?settings=fundingSources`,
        }
      : {
          prompt: "Funding Sources have not been set up on this project.",
          to: undefined,
          hideIcon: true,
        };
    return <BlankSlate attached src={tablePlaceholder} {...promptProps} />;
  }

  const onSubmit = (valuesFormik) => {
    const disbursements = flatMap(valuesFormik.disbursements, values)
      .filter((d) => !!d.id)
      .map((disbursement) => ({
        ...disbursement,
        amount: isBlank(disbursement.amount) ? "0" : disbursement.amount,
      }));

    const variables = {
      automaticAllocationEnabled: valuesFormik.automaticAllocationEnabled,
      bypassChecks: !!valuesFormik.bypassChecks,
      disbursements,
      disbursementTarget: hasUsesOfFunds
        ? DISBURSEMENT_TARGET.USE_OF_FUND
        : DISBURSEMENT_TARGET.DRAW,
      drawId,
    };
    disburseFunds({ variables });
  };

  return (
    <Formik
      enableReinitialize
      initialStatus={{
        disabled: (isLockedDown || isFunded) && fundingConfirmed,
      }}
      initialValues={getInitialValues({
        automaticAllocationEnabled,
        fundingSources,
        hasUsesOfFunds,
        lineItems,
      })}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(propsFormik) => (
        <Fragment>
          <NavigationWarnings dirty={propsFormik.dirty} />
          {showFundsWarning && (
            <InsufficientFundsWarningModal
              onProceed={() => {
                onSubmit({ ...propsFormik.values, bypassChecks: true });
              }}
              result={disburseFundsResult}
            />
          )}
          <DrawFundingSourcesAlerts
            drawId={drawId}
            draws={draws}
            hasUsesOfFunds={hasUsesOfFunds}
            projectId={projectId}
          />
          <DrawFundingSourcesForm
            autoAllocationSuggestions={autoAllocationSuggestions}
            fundingSources={fundingSources}
            hasAutoAllocate={hasAutoAllocate}
            hasUsesOfFunds={hasUsesOfFunds}
            lineItems={lineItems}
            propsFormik={propsFormik}
          />
        </Fragment>
      )}
    </Formik>
  );
}

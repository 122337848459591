import { useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { ContentLayout, NewDrawForm } from "components/templates";
import { Loadable } from "components/materials";
import { UserContext } from "helpers/behaviors";
import { DRAW_STATE, PERMISSION_ACTION } from "helpers/enums";
import {
  dateFormToServer,
  dateServerToForm,
  isInvalidDate,
} from "helpers/dateHelpers";
import isBlank from "helpers/isBlank";
import analytics, { drawValues } from "helpers/analytics";
import { PROJECT_VENDOR_SEARCH_QUERY } from "helpers/queries";
import { LOCKED_DRAW_STATES } from "helpers/drawHelpers";
import { getRulesCountsForDraw } from "helpers/ruleHelpers";
import { get, find, first, set } from "lodash";
import { QUERY as APP_LAYOUT_PAGE_QUERY } from "./AppLayoutPage";

const QUERY = gql`
  query NewDrawPageQuery($projectId: String!) {
    project(id: $projectId) {
      id
      draws {
        id
        fundedDate
        submittedDate
        expectedSendDate
        name
        state
        submitter {
          id
        }
        rules {
          id
          scopeId
          enabled
          state
        }
      }
      status
      vendors {
        id
        name
      }
    }
  }
`;

const MUTATION = gql`
  mutation NewDrawPageMutation(
    $fundDraws: [MarkFundedInput]
    $name: String!
    $projectId: String!
    $submittedDate: Date
    $expectedSendDate: Date
    $submitterId: String
  ) {
    fundDraws(draws: $fundDraws, projectId: $projectId) {
      status
    }
    addDraw(
      name: $name
      projectId: $projectId
      submittedDate: $submittedDate
      expectedSendDate: $expectedSendDate
      submitterId: $submitterId
    ) {
      id
    }
  }
`;

export function NewDrawPage({ history, match }) {
  const { projectId } = match.params;

  const { hasPermission, isDeveloper } = useContext(UserContext);
  const { data, loading } = useQuery(QUERY, {
    variables: { projectId },
  });
  const [getProjectVendorSearchQuery, projectVendorSearchQuery] = useLazyQuery(
    PROJECT_VENDOR_SEARCH_QUERY
  );
  const [mutate, result] = useMutation(MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ addDraw, fundDraws }) => {
      analytics.track("Draw Created", {
        previousDrawsCount: get(data, "project.draws.length", 0),
        projectStatus: get(data, "project.status"),
        ...drawValues(addDraw),
      });

      const to = `/projects/${projectId}/draws/${addDraw.id}`;
      history.push(to);
    },
    refetchQueries: [{ query: APP_LAYOUT_PAGE_QUERY }],
  });

  if (loading) return <Loadable loading />;

  const vendors = get(data, "project.vendors", []);
  const draws = get(data, "project.draws", []);

  const showDrawLockdownMessage =
    draws.length > 0 && hasPermission(PERMISSION_ACTION.LOCK_PREVIOUS_DRAWS);
  const prevDraw = first(draws);

  const unfundedDraws = draws.filter(
    (draw) => draw.state !== DRAW_STATE.FUNDED
  );

  const today = dateServerToForm(Date.now());
  const initialValues = {
    fundDraws: unfundedDraws.map((draw) => {
      const isLocked = LOCKED_DRAW_STATES.includes(draw.state);
      return {
        checked: !isLocked,
        date: today,
        id: draw.id,
        name: draw.name,
        state: draw.state,
        disabled: isLocked,
      };
    }),
    name: "",
    submittedDate: isDeveloper ? undefined : today,
    expectedSendDate: isDeveloper ? today : undefined,
    submitter: { id: get(prevDraw, "submitter.id") },
  };

  const validate = (values) => {
    const errors = {};
    values.fundDraws.forEach((fundDraw, index) => {
      if (fundDraw.checked && isBlank(fundDraw.date)) {
        set(errors, `fundDraws.${index}.date`, "Please enter a date");
      } else if (fundDraw.checked && isInvalidDate(fundDraw.date)) {
        set(errors, `fundDraws.${index}.date`, "Date is invalid");
      }
    });

    if (isBlank(values.name)) {
      errors.name = "Please enter a name";
    }

    const dateFieldName = isDeveloper ? "expectedSendDate" : "submittedDate";

    if (isBlank(values[dateFieldName])) {
      errors[dateFieldName] = "Please enter a date";
    } else if (isInvalidDate(values[dateFieldName])) {
      errors[dateFieldName] = "Date is invalid";
    }

    if (!isDeveloper && isBlank(values.submitter.id)) {
      set(errors, "submitter.id", "Please select a submitter");
    }

    return errors;
  };

  const onSubmit = (values) => {
    const variables = {
      fundDraws: values.fundDraws
        .filter((fundDraw) => fundDraw.checked)
        .map((fundDraw) => ({
          id: fundDraw.id,
          date: dateFormToServer(fundDraw.date),
        })),
      name: values.name,
      projectId,
      submittedDate: dateFormToServer(values.submittedDate),
      expectedSendDate: dateFormToServer(values.expectedSendDate),
      submitterId: values.submitter.id,
    };

    mutate({ variables });

    variables.fundDraws.map(({ id }) => {
      const draw = find(draws, ["id", id]);
      return analytics.track("Draw Funded", {
        projectId,
        ...drawValues({
          ...draw,
          ...getRulesCountsForDraw(draw),
        }),
      });
    });
  };

  return (
    <ContentLayout fixedWidth title="New Draw">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formikProps) => (
          <NewDrawForm
            formikProps={formikProps}
            isDeveloper={isDeveloper}
            getProjectVendorSearchQuery={getProjectVendorSearchQuery}
            mutationResultProps={result}
            projectId={projectId}
            searchedVendors={get(
              projectVendorSearchQuery,
              "data.project.organization.paginatedVendors.results",
              []
            )}
            showDrawLockdownMessage={showDrawLockdownMessage}
            vendors={vendors}
          />
        )}
      </Formik>
    </ContentLayout>
  );
}

NewDrawPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

import { Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Accordion,
  Button,
  Modal,
  Pane,
  Switch,
  Table,
  Text,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import { configurationPanelStyles } from "./helpers";

export const PANEL_KEY = "quickBooksOnliine";

const QUICK_BOOKS_ONLINE_VENDOR_SYNC = gql`
  mutation QuickBooksOnlineVendorSync($organizationId: String!) {
    quickBooksOnlineVendorSync(organizationId: $organizationId) {
      id
    }
  }
`;

const UPDATE_QUICK_BOOKS_ONLINE_AUTOMATIC_SYNC_ENABLED = gql`
  mutation UpdateQuickBooksOnlineAutomaticSyncEnabled(
    $organizationId: String!
    $qboAutomaticSyncEnabled: Boolean!
  ) {
    updateQuickBooksOnlineAutomaticSyncEnabled(
      organizationId: $organizationId
      qboAutomaticSyncEnabled: $qboAutomaticSyncEnabled
    ) {
      id
      qboAutomaticSyncEnabled
    }
  }
`;

export function QuickBooksOnline({ organization, expandedPanelKeys, toggle }) {
  const open = !!expandedPanelKeys[PANEL_KEY];
  const [modalOpen, setModalOpen] = useState(false);
  const [
    quickBooksOnlineAutomaticSyncEnabled,
    setQuickBooksOnlineAutomaticSyncEnabled,
  ] = useState(organization.qboAutomaticSyncEnabled);

  const [updateQuickBooksOnlineAutomaticSyncEnabled] = useMutation(
    UPDATE_QUICK_BOOKS_ONLINE_AUTOMATIC_SYNC_ENABLED
  );

  function handleSwitchOnChange() {
    setQuickBooksOnlineAutomaticSyncEnabled(
      !quickBooksOnlineAutomaticSyncEnabled
    );
    updateQuickBooksOnlineAutomaticSyncEnabled({
      variables: {
        organizationId: organization.id,
        qboAutomaticSyncEnabled: !quickBooksOnlineAutomaticSyncEnabled,
      },
    });
  }

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="QuickBooks Online"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      {...configurationPanelStyles}
    >
      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
        <Switch
          onChange={handleSwitchOnChange}
          checked={quickBooksOnlineAutomaticSyncEnabled}
          hasCheckIcon
        />
        <Text marginLeft={majorScale(2)} size={400}>
          Enable automatic vendor syncing with QuickBooks Online.
        </Text>
      </Pane>
      <Pane marginTop={majorScale(1)}>
        <Button
          purpose="Opens modal to initiate vendor sync with QuickBooks Online"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Sync Vendors
        </Button>
      </Pane>
      <VendorSyncStatusTable
        qboAuthorizations={organization.qboAuthorizations}
      />
      {modalOpen && (
        <PullVendorsFromQuickBooksOnlineWarningModal
          organizationId={organization.id}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Accordion.Panel>
  );
}

function VendorSyncStatusTable({ qboAuthorizations }) {
  return (
    <Pane marginTop={majorScale(2)}>
      <Pane>QuickBooks Online Company Sync Status</Pane>
      {qboAuthorizations.length === 0 ? (
        <Text>
          There are no QuickBooks Online Companies to sync vendors with.
        </Text>
      ) : (
        <Pane marginTop={majorScale(1)}>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>
                  QuickBooks Online Company ID
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>
                  Vendors Last Synced At
                </Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {qboAuthorizations.map((authorization) => {
                return (
                  <Table.Row>
                    <Table.TextCell>
                      {authorization.externalProjectId}
                    </Table.TextCell>
                    <Table.TextCell>
                      {authorization.lastVendorSyncAt
                        ? formatDateTime(authorization.lastVendorSyncAt)
                        : "-"}
                    </Table.TextCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
      )}
    </Pane>
  );
}

function PullVendorsFromQuickBooksOnlineWarningModal({
  organizationId,
  onClose,
}) {
  const [
    quickBooksOnlineVendorSync,
    { loading: quickBooksOnlineVendorSyncLoading },
  ] = useMutation(QUICK_BOOKS_ONLINE_VENDOR_SYNC, {
    onCompleted: () => {
      onClose();
    },
  });

  function handleConfirm() {
    quickBooksOnlineVendorSync({
      variables: {
        organizationId,
      },
    });
  }

  return (
    <Fragment>
      <Modal
        hasFooter
        onConfirm={handleConfirm}
        confirmLabel="Sync Vendors from QuickBooks Online"
        isConfirmLoading={quickBooksOnlineVendorSyncLoading}
        onClose={() => onClose()}
        open
        size="small"
        title="Sync Vendors from QuickBooksOnline"
      >
        <Modal.Content>
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            After proceeding, please give a few minutes for your vendors to be
            added to the system.
          </Pane>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

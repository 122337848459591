import { Card, Heading, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";

export function ReviewCard({ children, headerAction, title }) {
  return (
    <Card marginBottom={majorScale(2)} padding={majorScale(3)}>
      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
        <Heading flexGrow={1} size={600}>
          {title}
        </Heading>
        {headerAction}
      </Pane>
      {children}
    </Card>
  );
}

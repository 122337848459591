import Button from "components/materials/Button";
import {
  majorScale,
  DocumentOpenIcon,
  FolderSharedIcon,
  Menu,
  MenuIcon,
  Pane,
  Popover,
  Position,
  SendMessageIcon,
} from "evergreen-ui";
import { ItemType, DownloadType } from "../types";

export interface Props {
  onAdd: (type: ItemType) => void;
  onDownload: (format: DownloadType) => void;
  onSend: () => void;
}

export function DrawPackageActions({ onAdd, onDownload, onSend }: Props) {
  const actionProps = {
    paddingLeft: majorScale(1),
    paddingRight: majorScale(1),
    appearance: "minimal",
  } as any;
  const ctaProps = {
    paddingLeft: majorScale(2),
    paddingRight: majorScale(2),
    marginLeft: majorScale(1),
  } as any;

  return (
    <Pane
      borderBottom="2px solid #d7e0ea"
      display="flex"
      justifyContent="space-between"
      marginBottom={majorScale(1)}
      paddingBottom={majorScale(1)}
    >
      <Pane>
        <Button onClick={() => onAdd(ItemType.Section)} {...actionProps}>
          + Add Section
        </Button>
        <Button onClick={() => onAdd(ItemType.Document)} {...actionProps}>
          + Add Document(s)
        </Button>
        <Button onClick={() => onAdd(ItemType.Template)} {...actionProps}>
          + Add Template
        </Button>
      </Pane>
      <Pane>
        <Button appearance="primary" {...ctaProps}>
          Save
        </Button>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Item icon={SendMessageIcon} onClick={onSend}>
                Send
              </Menu.Item>
              <Menu.Item
                icon={DocumentOpenIcon}
                onClick={() => onDownload(DownloadType.PDF)}
              >
                Download (.pdf)
              </Menu.Item>
              <Menu.Item
                icon={FolderSharedIcon}
                onClick={() => onDownload(DownloadType.Zip)}
              >
                Download (.zip)
              </Menu.Item>
            </Menu>
          }
        >
          <Button appearance="minimal" {...ctaProps}>
            <MenuIcon />
          </Button>
        </Popover>
      </Pane>
    </Pane>
  );
}

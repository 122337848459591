import { useState, Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { TasksTable } from "components/templates";
import { Button, Pane, Paragraph } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { TASK_STATUS } from "helpers/enums";
import { compareAsc } from "helpers/dateHelpers";
import { TIMELINE_QUERY } from "./graphql";
import { TaskSidebar } from "./TaskSidebar";
import { TimelineGantt } from "./TimelineGantt";

export function prepareTasksForChart(tasks) {
  return tasks
    .filter(
      (task) =>
        task.actualStartDate ||
        task.projectedStartDate ||
        task.originalStartDate
    )
    .map((task) => {
      const commonFields = {
        id: task.id,
        name: task.eventName,
        dependencies: task.predecessor?.parent?.id,
        owner: task.ownerName,
        status: task.status,
        originalStart: task.originalStartDate,
      };

      if (task.status === TASK_STATUS.COMPLETE) {
        return {
          ...commonFields,
          start: task.actualStartDate,
          end: task.actualCompletionDate,
          custom_class: "complete",
        };
      }

      if (task.status === TASK_STATUS.IN_PROGRESS) {
        return {
          ...commonFields,
          start: task.actualStartDate,
          end: task.projectedCompletionDate,
          custom_class: task.isOverdue ? "overdue" : "in-progress",
        };
      }

      if (task.status === TASK_STATUS.NOT_STARTED) {
        return {
          ...commonFields,
          start: task.projectedStartDate || task.originalStartDate,
          end: task.projectedCompletionDate || task.originalCompletionDate,
          hasProjectedDate: !!task.projectedStartDate,
          custom_class: task.isOverdue ? "overdue" : "not-started",
        };
      }

      return {};
    })
    .sort((taskA, taskB) => compareAsc(taskA.start, taskB.start))
    .map((task, index, sortedTasks) => ({
      ...task,
      offsetFromBottom: sortedTasks.length - 1 - index,
    }));
}

export function TimelinePage({ history, match }) {
  const [showChart, setShowChart] = useState(true);
  const { projectId, taskId } = match.params;
  const { data, loading } = useQuery(TIMELINE_QUERY, {
    variables: { projectId },
  });

  if (loading) return null;

  const { tasks } = data.project;
  const chartTasks = prepareTasksForChart(tasks);
  const hasChartTasks = chartTasks.length > 0;

  const baseUrl = `/projects/${projectId}/timeline`;
  function closeSidebar() {
    history.push(`${baseUrl}${history.location.search}`);
  }

  function openSidebar(taskId) {
    history.push(`timeline/${taskId || "new"}`);
  }

  function onTaskCreated(taskId) {
    history.push(taskId);
  }

  return (
    <Fragment>
      {tasks.length > 0 ? (
        <Pane>
          {hasChartTasks && showChart && (
            <TimelineGantt
              project={data.project}
              tasks={chartTasks}
              hideChart={() => setShowChart(false)}
              openSidebar={openSidebar}
            />
          )}
          <TasksTable
            tasks={tasks}
            history={history}
            selectedOrganization={data.project.organization}
            rightControls={[
              ...(hasChartTasks
                ? [
                    <Button
                      onClick={() =>
                        showChart ? setShowChart(false) : setShowChart(true)
                      }
                      marginLeft={minorScale(3)}
                    >
                      {`${showChart ? "Hide" : "Show"} Gantt`}
                    </Button>,
                  ]
                : []),
              <Button
                onClick={(_event) => openSidebar()}
                marginLeft={minorScale(3)}
                appearance="primary"
              >
                Add New Task
              </Button>,
            ]}
          />
        </Pane>
      ) : (
        <EmptyTimelinePrompt onAddTask={(_event) => openSidebar()} />
      )}
      {!!taskId && (
        <TaskSidebar
          history={history}
          onCloseComplete={closeSidebar}
          onTaskCreated={onTaskCreated}
          taskId={taskId}
          project={data.project}
          tasks={tasks}
        />
      )}
    </Fragment>
  );
}

export function EmptyTimelinePrompt({ onAddTask }) {
  return (
    <Pane display="flex" justifyContent="center" paddingTop={majorScale(8)}>
      <Pane textAlign="center">
        <Paragraph size={500}>Get started with your Timeline.</Paragraph>
        <Paragraph
          marginTop={minorScale(1)}
          marginBottom={majorScale(2)}
          size={500}
        >
          Add a task and it will show up here.
        </Paragraph>
        <Button onClick={onAddTask} appearance="primary">
          Add New Task
        </Button>
      </Pane>
    </Pane>
  );
}

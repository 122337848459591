import { Fragment, useContext } from "react";
import { css } from "glamor";
import { Pane } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale, ThemeContext } from "helpers/utilities";
import {
  DeveloperPortfolioInsights,
  LenderPortfolioInsights,
  OrganizationSelector,
} from "components/templates";
import { ScopeOrganizations } from "./ScopeOrganizations";

export function PortfolioInsightsPage({ history }) {
  const theme = useContext(ThemeContext);

  const pathNameIncludesLender = history.location.pathname.includes("lender");

  const commonStyleRules = {
    card: css({ padding: "16px", marginBottom: "16px" }),
    cardTitle: css({ padding: "8px 0" }),
    table: css({
      width: "100%",
      fontSize: "11px",
      borderCollapse: "collapse",
      borderSpacing: 0,
      "& th": {
        background: "white",
        textAlign: "left",
        padding: "8px",
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
      "& tbody td": {
        borderTop: `1px solid ${theme.defaultColors.dividerColor}`,
        padding: "8px",
        maxWidth: "150px",
        whiteSpace: "nowrap",
      },
      "& tfoot td": {
        borderTop: `1px solid ${theme.defaultColors.dividerColor}`,
        padding: "8px",
        whiteSpace: "nowrap",
      },
      "& Button": { height: "16px", zIndex: 0 },
    }),
    title: { fontWeight: theme.fontWeights.MEDIUM, color: "muted" },
  };

  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        allOrganizations,
        disabledOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing portfolio for"
            />
          </Pane>
          {pathNameIncludesLender ? (
            <LenderPortfolioInsights
              commonStyleRules={commonStyleRules}
              history={history}
              selectedOrganization={selectedOrganization}
            />
          ) : (
            <DeveloperPortfolioInsights
              commonStyleRules={commonStyleRules}
              history={history}
              selectedOrganization={selectedOrganization}
            />
          )}
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

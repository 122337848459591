import { useEffect } from "react";
import { ContentLayout } from "components/templates";
import { Heading, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import { envelope } from "images";

const graphqlHost = process.env.REACT_APP_GRAPHQL_HOST;

const SendRequest = ({ scheduledNotificationId }) => {
  const path = `/remind_in/${scheduledNotificationId}`;

  useEffect(() => {
    fetch(`${graphqlHost}${path}`).then((response) => response.blob()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export function NotificationReminderPage(props) {
  const { scheduledNotificationId } = props.match.params;
  return (
    <ContentLayout>
      <SendRequest scheduledNotificationId={scheduledNotificationId} />

      <Pane textAlign="center" marginTop={majorScale(4)}>
        <img alt="" src={envelope} width={300} />
        <Heading
          is="h1"
          textAlign="center"
          size={600}
          marginTop={majorScale(3)}
        >
          You will be reminded of this notification in 3 days
        </Heading>
      </Pane>
    </ContentLayout>
  );
}

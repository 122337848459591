import { useEffect, Fragment, useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AddStakeholdersForm } from "components/templates";
import { SendDrawContext } from "contexts/sendDrawContext";
import { Heading, Wizard, Loadable } from "components/materials";
import { find, get, intersectionBy, maxBy } from "lodash";
import { STAKEHOLDERS_SETTINGS_FRAGMENT } from "helpers/fragments";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { isBefore } from "helpers/dateHelpers";
import { UserContext } from "helpers/behaviors";

const QUERY = gql`
  query AddRecipientsStepQuery($drawId: String!, $projectId: String!) {
    project(id: $projectId) {
      id
      ...StakeholdersSettingsFragment
      draw(id: $drawId) {
        id
        previousDraw {
          id
          submissions {
            id
            submittedAt
            latestSubmission {
              id
              stakeholder {
                id
                role
                member {
                  id
                  name
                  email
                }
                organization {
                  id
                  name
                }
              }
            }
          }
        }
        submissions {
          id
          submittedAt
          latestSubmission {
            id
            stakeholder {
              id
              role
              member {
                id
                name
                email
              }
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${STAKEHOLDERS_SETTINGS_FRAGMENT}
`;

const DELETE = gql`
  mutation AddRecipientsStepDelete(
    $organizationId: String!
    $projectId: String!
    $role: OrganizationType!
  ) {
    deleteStakeholderGroup(
      organizationId: $organizationId
      projectId: $projectId
      role: $role
    ) {
      id
      ...StakeholdersSettingsFragment
    }
  }
  ${STAKEHOLDERS_SETTINGS_FRAGMENT}
`;

export function AddRecipientsStep() {
  const {
    draw,
    projectId,
    stakeholdersSet,
    stakeholders,
    onNext,
    setStakeholders,
    setStakeholdersSet,
    onBack,
  } = useContext(SendDrawContext);
  const drawId = draw.id;

  const { hasPermission } = useContext(UserContext);
  const [deleteStakeholderGroup] = useMutation(DELETE);
  const [stakeholderIds, setStakeholderIds] = useState([]);
  const { data, loading } = useQuery(QUERY, {
    variables: { drawId, projectId },
  });

  const projectStakeholders = useMemo(
    () => get(data, "project.stakeholders", []),
    [data]
  );
  const previousSubmissions = get(data, "project.draw.submissions", []);
  const previousSubmissionStakeholders = intersectionBy(
    previousSubmissions.map((submission) => {
      const latestSubmission = get(submission, "latestSubmission.stakeholder");
      const submittedAt = get(submission, "submittedAt");
      return { ...latestSubmission, submittedAt };
    }),
    projectStakeholders,
    "id"
  );

  const previousAndCurrentProjectStakeholders = projectStakeholders
    .map((stakeholder) => {
      const previousStakeholder = find(previousSubmissionStakeholders, [
        "id",
        stakeholder.id,
      ]);
      return previousStakeholder || stakeholder;
    })
    .filter(({ member }) => !!get(member, "email"));

  const previousDrawSubmissions = get(
    data,
    "project.draw.previousDraw.submissions",
    []
  );
  const previousDrawSubmissionStakeholders = intersectionBy(
    previousDrawSubmissions.map((submission) => {
      const latestSubmission = get(submission, "latestSubmission.stakeholder");
      return latestSubmission;
    }),
    projectStakeholders,
    "id"
  );

  const latestSubmissionSubmittedAt = get(
    maxBy(previousSubmissions.concat(previousDrawSubmissions), "submittedAt"),
    "submittedAt"
  );
  const newProjectStakeholders = projectStakeholders.filter(({ updatedAt }) =>
    isBefore(latestSubmissionSubmittedAt, updatedAt)
  );

  useEffect(() => {
    const getInitialStakeholders = () => {
      if (stakeholdersSet) return stakeholders;
      if (previousSubmissions.length > 0)
        return previousSubmissionStakeholders.concat(newProjectStakeholders);
      if (previousDrawSubmissions.length > 0)
        return previousDrawSubmissionStakeholders.concat(
          newProjectStakeholders
        );
      return projectStakeholders;
    };

    setStakeholderIds(
      getInitialStakeholders()
        .filter(({ member }) => !!member.email)
        .map((s) => s.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStakeholders]);

  function handleSubmit() {
    setStakeholders(
      projectStakeholders.filter((s) => stakeholderIds.includes(s.id))
    );
    setStakeholdersSet(true);
    onNext();
  }

  function handleBack() {
    setStakeholders(
      projectStakeholders.filter((s) => stakeholderIds.includes(s.id))
    );
    setStakeholdersSet(true);
    onBack();
  }

  const canEditSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );

  if (loading) return <Loadable loading />;

  return (
    <Fragment>
      <Wizard.Content>
        <Heading paddingBottom={majorScale(2)}>
          Select the stakeholders you would like to receive this draw.
        </Heading>
        <AddStakeholdersForm
          deleteStakeholderGroup={deleteStakeholderGroup}
          disableEdits={!canEditSettings}
          projectId={projectId}
          setStakeholderIds={setStakeholderIds}
          stakeholderIds={stakeholderIds}
          stakeholders={previousAndCurrentProjectStakeholders}
        />
      </Wizard.Content>

      <Wizard.Actions onBack={handleBack} onNext={handleSubmit} />
    </Fragment>
  );
}

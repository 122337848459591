import gql from "graphql-tag";
import {
  RULE_FRAGMENT,
  REVIEW_FRAGMENT,
  COMMENT_FRAGMENT,
  DASHBOARD_DOCUMENTS_FRAGMENT,
} from "helpers/fragments";

const COMMENT_FRAGMENT_WITHOUT_TARGET = gql`
  fragment CommentFragmentWithoutTarget on Comment {
    id
    body
    insertedAt
    author {
      id
      fullName
    }
    mentions {
      id
      user {
        id
        fullName
      }
    }
  }
`;

export const PROJECT_DASHBOARD_QUERY = gql`
  query ProjectOverviewPageQuery($projectId: String!, $userId: String!) {
    project(id: $projectId) {
      id
      amount
      city
      contingencyOriginalAmount
      contingencyPercentRemaining
      contingencySpentAmount
      expectedProjectLength
      exposuresAmount
      inProgressAmount
      name
      potentialChangeOrdersAmount
      squareFeet
      startDate
      state
      streetAddress
      zip
      userProjectDashboardConfigurations(userId: $userId) {
        id
        i
        x
        y
        w
        h
        disabled
      }
      assignedDocuments: documents(assigned: true) {
        ...DashboardDocumentsFragment
      }
      comments {
        ...CommentFragmentWithoutTarget
      }
      documentsPendingApprovalForUser {
        ...DashboardDocumentsFragment
      }
      pendingDrawApprovals {
        id
        draw {
          id
          requestedAmount
          name
          project {
            id
            name
          }
        }
      }
      budgetAdjustments {
        id
        transactions {
          id
          amount
          lineItemId
        }
      }
      draws {
        id
        createdAt
        fundedDate
        name
        receivedDate
        requestedAmount
        state
        submittedDate
        updatedAt
        recentStateUpdate {
          id
          date
          state
        }
        rules {
          ...RuleFragment
        }
      }
      fundingSourceGroups {
        id
        amount
        disbursedAmount
        label
        type
      }
      hardCostPercentagesByMonth {
        date
        hardCostsGrossPercentComplete
      }
      mentions(dismissed: false) {
        id
        comment {
          ...CommentFragment
        }
      }
      recentDraw {
        id
        createdAt
        expectedSendDate
        fundedDate
        hasSubmissions
        isFrozen
        isLockedDown
        isPendingReview
        name
        receivedDate
        requestedAmount
        state
        submittedDate
        targetAmount
        updatedAt
        drawInspectionReport {
          id
          status
        }
        drawOverviewDocuments {
          id
          vendorName
          type
          state
          amount
          adjustedAmount
          agreementAmount
          recentApprovalReviews {
            id
            userId
            userName
            reviewType
          }
        }
        drawStateUpdates {
          id
          date
          state
          user {
            id
            fullName
          }
        }
        export {
          url
        }
        fundingSources {
          id
          scopeId
          disbursedAmount
        }
        issues {
          id
          documentId
          lineItemId
          name
          severity
        }
        lineItems {
          id
          scopeId
          requestedAmount
          balanceToFundAmount
        }
        payableDocuments {
          id
          isPaid
        }
        questions {
          id
        }
        recentStateUpdate {
          id
          date
        }
        responses {
          id
          drawAssessmentQuestionId
          response
        }
        reviews {
          ...ReviewFragment
        }
        rules {
          id
          scopeId
          enabled
          state
        }
        summary {
          url
        }
        uploads(toBeSplit: true) {
          id
        }
      }
      organization {
        id
        type
        vendors {
          id
        }
        users {
          id
          fullName
        }
      }
      picture {
        url
      }
      documentReviewers {
        id
        user {
          id
          approvalAmountLimit
          approvalAmountMinimum
        }
      }
      stakeholders {
        id
        role
        organization {
          id
          name
        }
      }
      divisions {
        id
        scopeId
        name
        position
        lineItems {
          id
          scopeId
          adjustmentsAmount
          previousAdjustmentsAmount
          balanceToFundAmount
          budgetAmount
          committedAmount
          disbursedToDateAmount
          division {
            id
            scopeId
            name
          }
          issues(budgetLevel: true) {
            id
            lineItemId
            name
            severity
          }
          superLineItem {
            id
            name
          }
          exposedAmount
          exposureAmount
          pcoAmount
          grossRequestedToDateAmount
          masterFormatDivision
          name
          number
          originalBudgetAmount
          outOfContractAmount
          position
          requestedToDateAmount
          retainageToDateAmount
          totalAgreementsAmount
          types
          lineItemCategories
          isContingency
          hardCosts
        }
      }
      amount
      originalAmount
      tasks {
        id
        eventName
        originalStartDate
        originalCompletionDate
        projectedStartDate
        projectedCompletionDate
        actualStartDate
        status
        isOverdue
        ownerName
        predecessor {
          id
          parent {
            id
          }
        }
      }
      hardCostPercentagesByDraw {
        drawId
        drawName
        drawState
        percentHardCostContingencyUsed
        percentHardCostCompleted
        fundedAt
      }
      contingencySegments {
        all {
          segments {
            name
            value
            id
          }
          drawnContingency
          originalContingency
          percentUsed
          remainingContingency
          totalContingency
          untrackedContingency
        }
        hardCosts {
          segments {
            name
            value
            id
          }
          drawnContingency
          originalContingency
          percentUsed
          remainingContingency
          totalContingency
          untrackedContingency
        }
      }
    }
  }
  ${RULE_FRAGMENT}
  ${REVIEW_FRAGMENT}
  ${DASHBOARD_DOCUMENTS_FRAGMENT}
  ${COMMENT_FRAGMENT_WITHOUT_TARGET}
  ${COMMENT_FRAGMENT}
`;

export const SAVE_PROJECT_DASHBOARD_CONFIGURATIONS = gql`
  mutation SaveProjectDashboardConfigurations(
    $userId: String!
    $projectId: String!
    $projectDashboardConfigurations: [DashboardCardConfigurationInput]!
  ) {
    saveProjectDashboardConfigurations(
      userId: $userId
      projectId: $projectId
      projectDashboardConfigurations: $projectDashboardConfigurations
    ) {
      id
      i
      x
      y
      w
      h
      disabled
    }
  }
`;

import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Text, TextArea, Wizard } from "components/materials";
import t from "helpers/translate";
import { AddDocumentsStep } from "./AddDocumentsStep";

const CHAR_LIMIT = 500;

export class AddNoteStep extends PureComponent {
  handleChange = (event) => {
    const { value } = event.target;
    const { onChange } = this.props;
    const notes = value.substring(0, CHAR_LIMIT);
    onChange({ notes });
  };

  render() {
    const { notes, hasBack, onNext, onBack } = this.props;

    return (
      <Fragment>
        <Wizard.Content>
          <Form>
            <Form.Field
              hint={
                <Text>
                  {t("submitReport.noteLimit", {
                    count: CHAR_LIMIT - notes.length,
                    limit: CHAR_LIMIT,
                  })}
                </Text>
              }
            >
              <TextArea
                autoFocus
                maxLength={CHAR_LIMIT}
                minRows={5}
                onChange={this.handleChange}
                placeholder="Add a note for the recipients"
                value={notes}
              />
            </Form.Field>
          </Form>
        </Wizard.Content>
        <Wizard.Actions
          hideBack={!hasBack}
          onBack={onBack}
          onNext={() => onNext(AddDocumentsStep)}
        />
      </Fragment>
    );
  }
}

AddNoteStep.propTypes = {
  notes: PropTypes.string.isRequired,
  hasBack: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

// TODO: remove dot notation
AddNoteStep.title = () => t("submitReport.header");

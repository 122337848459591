import { useMemo, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { BlankSlate, DrawSoftCostsTable } from "components/templates";
import { Loadable } from "components/materials";
import { debounce, flatten, get } from "lodash";
import { sumBy } from "helpers/math";
import { LINE_ITEM_DOCUMENTATION_FRAGMENT } from "helpers/fragments";
import { getSearchByKey } from "helpers/queryStringHelpers";
import t from "helpers/translate";
import { tablePlaceholder } from "images";
import { DrawLineItemSlideout } from "./DrawLineItemSlideout";

const FRAGMENT = gql`
  fragment DrawSoftCostsPageFragment on Draw {
    id
    budgetAmount
    requestedAmount
    divisions {
      id
      scopeId
    }
    softCostItems {
      id
      scopeId
      name
      position
      budgetAmount
      requestedAmount
      requestedToDateAmount
      division {
        id
        scopeId
        position
      }
    }
    softCostVendorLineItems {
      id
      scopeId
      isGeneralContractor
      isBackup
      requestedAmount
      retainageAmount
      jobCostCodes {
        id
        code
      }
      drawLineItem {
        id
        scopeId
        name
        position
        requestedAmount
        division {
          id
          scopeId
          position
        }
      }
      vendor {
        id
        name
        vendorCostCode
      }
      invoices {
        ...lineItemDocumentation
      }
      other {
        ...lineItemDocumentation
      }
    }
  }
  ${LINE_ITEM_DOCUMENTATION_FRAGMENT}
`;

const QUERY = gql`
  query DrawSoftCostsPageQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        ...DrawSoftCostsPageFragment
      }
    }
  }
  ${FRAGMENT}
`;

const SUBMISSION = gql`
  query DrawSoftCostsPageSubmission($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        ...DrawSoftCostsPageFragment
      }
    }
  }
  ${FRAGMENT}
`;

const DOCUMENT_SUBSCRIPTION = gql`
  subscription onDocumentUpdate($projectId: String!) {
    projectDocumentUpdated(projectId: $projectId) {
      id
      draw {
        id
      }
    }
  }
`;

export function DrawSoftCostsPage({ history, match }) {
  const { drawId, projectId, submissionId } = match.params;

  const baseUrl = submissionId
    ? `/submissions/${submissionId}/soft_costs`
    : `/projects/${projectId}/draws/${drawId}/soft_costs`;

  const handleSidebarOpen = (lineItem) => {
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(
      `${baseUrl}/line_items/${
        lineItem.id || lineItem.drawLineItem.id
      }${queryStringParams}`
    );
  };

  const handleSidebarClose = () => {
    if (match.params.documentId) return;
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.push(`${baseUrl}${queryStringParams}`);
  };

  const query = submissionId ? SUBMISSION : QUERY;
  const variables = submissionId ? { submissionId } : { drawId, projectId };

  const { refetch, ...queryResult } = useQuery(query, { variables });

  const updateQuery = useMemo(
    () => debounce(refetch, 5000, { maxWait: 30000 }),
    [refetch]
  );

  useSubscription(DOCUMENT_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) =>
      get(subscriptionData, "data.projectDocumentUpdated.draw.id") === drawId &&
      updateQuery(),
    variables: { projectId },
  });

  const draw = get(
    queryResult.data,
    submissionId ? "submission.sourceDraw" : "project.draw"
  );
  const scLineItems = get(draw, "softCostItems", []);
  const drawDivisions = get(draw, "divisions", []);
  const softCostVendorLineItems = get(draw, "softCostVendorLineItems", []);

  const vendorLineItems = flatten(
    scLineItems.map((drawLineItem) => {
      const matchingVendorLineItems = softCostVendorLineItems.filter(
        (vli) => get(vli, "drawLineItem.id") === drawLineItem.id
      );

      if (matchingVendorLineItems.length > 0) {
        return matchingVendorLineItems;
      }

      // If no documentation is found, return the draw line item information
      // (to be rendered in a "no documentation found" row)
      return { drawLineItem };
    })
  );

  if (drawDivisions.length > 0) {
    return (
      <Fragment>
        <DrawSoftCostsTable
          baseUrl={baseUrl}
          history={history}
          onClickRow={handleSidebarOpen}
          totalSoftCostsRequested={sumBy(scLineItems, "requestedAmount")}
          vendorLineItems={vendorLineItems}
        />
        <DrawLineItemSlideout
          closeSidebar={handleSidebarClose}
          history={history}
          match={match}
        />
      </Fragment>
    );
  }

  if (queryResult.loading) return <Loadable loading />;

  return (
    <BlankSlate
      attached
      prompt={t("blankSlate.uploadBudget")}
      src={tablePlaceholder}
      to={`/projects/${projectId}/budget`}
    />
  );
}

import {
  Alert,
  Pane,
  Text,
  UnorderedList,
  ListItem,
  majorScale,
} from "evergreen-ui";
import t from "helpers/translate";

export interface Props {}

export function ReviewPackage(_: Props) {
  return (
    <Pane>
      <Alert
        marginBottom={majorScale(1)}
        intent="success"
        title="No issues found!"
      />
      <Alert
        marginBottom={majorScale(1)}
        intent="warning"
        title="Draw may not be completed"
      >
        <Pane>
          <Text>{t("incompleteDraw.intro")}</Text>
          <UnorderedList>
            <ListItem>
              <Text>Some documents are not included in the draw package</Text>
            </ListItem>
            <ListItem>
              <Text>Rules pending</Text>
            </ListItem>
            <ListItem>
              <Text>Something else</Text>
            </ListItem>
          </UnorderedList>
        </Pane>
      </Alert>
    </Pane>
  );
}

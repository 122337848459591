import { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import isBlank from "helpers/isBlank";
import containsMultipleEmails from "helpers/containsMultipleEmails";
import isInvalidEmail from "helpers/isInvalidEmail";
import {
  DownloadLink,
  Form,
  Modal,
  Pane,
  Paragraph,
} from "components/materials";
import { DocumentSelector } from "components/templates";
import { majorScale, minorScale } from "helpers/utilities";
import { get, set } from "lodash";
import { useAuth } from "../../Auth";

const MUTATION = gql`
  mutation createInspectionReport(
    $documentIds: [String]
    $drawId: String!
    $inspectorEmail: String
    $notes: String
    $ccEmails: [String]
  ) {
    createInspectionReport(
      documentIds: $documentIds
      drawId: $drawId
      inspectorEmail: $inspectorEmail
      notes: $notes
      ccEmails: $ccEmails
    ) {
      id
    }
  }
`;

function formatCCEmails(emails) {
  return emails.split(",").reduce((formattedEmails, email) => {
    const trimmedEmail = email.trim();
    if (trimmedEmail !== "") {
      return [...formattedEmails, trimmedEmail];
    }
    return formattedEmails;
  }, []);
}

function validate(values) {
  const errors = {};
  if (isBlank(values.inspectorEmail)) {
    set(errors, "inspectorEmail", "Please fill out an email");
  }
  if (isInvalidEmail(values.inspectorEmail)) {
    set(errors, "inspectorEmail", "Please fill out a valid email");
  }

  if (values.ccEmails) {
    const hasInvalidEmails = formatCCEmails(values.ccEmails).some(
      (email) => isInvalidEmail(email) || containsMultipleEmails(email)
    );

    if (hasInvalidEmails) {
      set(
        errors,
        "ccEmails",
        "All email addresses must be comma-separated and valid"
      );
    }
  }

  return errors;
}

export function InspectionRequestModal({
  drawId,
  documents,
  open,
  refetchQueries,
  setOpen,
  previousInspectionReport,
}) {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [expandedDocumentTypes, setExpandedDocumentTypes] = useState([]);
  const [createInspectionReport, { loading }] = useMutation(MUTATION, {
    onCompleted: () => setOpen(false),
    refetchQueries,
  });
  const auth = useAuth();
  const accessToken = auth.getAccessToken();

  const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;
  const documentIdString = selectedDocumentIds.filter((id) => !!id).join(",");
  const hasDocuments = documents.length > 0;
  const downloadDrawPackageUrl = `${baseUrl}/download_draw_package/submission/${drawId}/${documentIdString}?access_token=${accessToken}`;
  const downloadMultipleUrl = `${baseUrl}/download_multiple_documents/${documentIdString}`;

  function handleCollapseDocumentType(documentType) {
    setExpandedDocumentTypes(
      expandedDocumentTypes.filter(
        (expandedDocumentType) => expandedDocumentType !== documentType
      )
    );
  }
  function handleExpandDocumentType(documentType) {
    setExpandedDocumentTypes([...expandedDocumentTypes, documentType]);
  }
  function handleDeselectDocument({ id: documentId }) {
    setSelectedDocumentIds((selectedDocumentIds) =>
      selectedDocumentIds.filter(
        (selectedDocumentId) => selectedDocumentId !== documentId
      )
    );
  }
  function handleSelectDocument({ id: documentId }) {
    setSelectedDocumentIds((selectedDocumentIds) => [
      ...selectedDocumentIds,
      documentId,
    ]);
  }

  function handleSubmit(data) {
    const variables = {
      ...data,
      drawId,
      documentIds: selectedDocumentIds,
      ccEmails: formatCCEmails(data.ccEmails),
    };
    createInspectionReport({ variables });
  }

  return (
    <Formik
      initialValues={{
        ccEmails: "",
        inspectorEmail: get(previousInspectionReport, "inspectorEmail", ""),
        notes: "",
      }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {(propsFormik) => (
        <Modal
          isConfirmLoading={loading}
          confirmLabel="Send Inspection Request"
          hasFooter
          onClose={() => setOpen(false)}
          onConfirm={propsFormik.handleSubmit}
          open={open}
          title="Request Inspection"
          size="medium"
        >
          <Form>
            <Pane display="flex">
              <Pane width="50%" marginX={majorScale(1)}>
                <Form.Input
                  label="Inspector Email"
                  name="inspectorEmail"
                  outerProps={{ marginBottom: majorScale(2) }}
                />
                <Form.Input
                  label="Emails to CC on Inspection Report Emails (comma-separated)"
                  name="ccEmails"
                  outerProps={{ marginBottom: majorScale(2) }}
                />
                <Form.TextArea label="Notes to Inspector" name="notes" />
              </Pane>
              <Pane width="50%" marginX={majorScale(1)}>
                <Paragraph marginBottom={minorScale(1)}>
                  Files for Inspector
                </Paragraph>
                {hasDocuments ? (
                  <Pane>
                    <DocumentSelector
                      documents={documents}
                      expandedDocumentTypeNames={expandedDocumentTypes}
                      onCollapseDocumentType={handleCollapseDocumentType}
                      onDeselectDocument={handleDeselectDocument}
                      onExpandDocumentType={handleExpandDocumentType}
                      onSelectDocument={handleSelectDocument}
                      selectedDocumentIds={selectedDocumentIds}
                      setInitialSelections={selectedDocumentIds.length === 0}
                    />
                    <Pane>
                      <DownloadLink
                        fontSize={12}
                        url={downloadMultipleUrl}
                        iconName="folder-close"
                        label="Download Documents Preview (.zip)"
                      />
                    </Pane>
                    <Pane>
                      <DownloadLink
                        fontSize={12}
                        url={downloadDrawPackageUrl}
                        iconName="document"
                        label="Download Draw Package Preview (.pdf)"
                      />
                    </Pane>
                  </Pane>
                ) : (
                  <Paragraph
                    color="muted"
                    fontStyle="italic"
                    paddingLeft={minorScale(2)}
                    size={300}
                  >
                    No documents have been added to this draw yet
                  </Paragraph>
                )}
              </Pane>
            </Pane>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

import { Pane, Paragraph } from "components/materials";

export function ApprovalTooltipContent({ approvedReviews, pendingReviewers }) {
  return (
    <Pane>
      {approvedReviews.length > 0 && (
        <Paragraph size={300} color="white">
          Approved: {approvedReviews.map(({ userName }) => userName).join(", ")}
        </Paragraph>
      )}
      {pendingReviewers.length > 0 && (
        <Paragraph size={300} color="white">
          Pending:{" "}
          {pendingReviewers.map(({ user }) => user.fullName).join(", ")}
        </Paragraph>
      )}
    </Pane>
  );
}

import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { UserContext } from "helpers/behaviors";
import { Grid, Loadable } from "components/materials";
import { reject } from "lodash";
import { getSearchByKey } from "helpers/queryStringHelpers";
import { majorScale } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { QUERY, SUBSCRIPTION } from "./graphql-queries";
import { DrawAssessment } from "./Questions";
import { Downloads } from "./Downloads";
import { Comments } from "./Comments";
import { Reviews } from "./Reviews";
import { Recipients } from "./Recipients";
import { Rules } from "./Rules";

function canDownloadDoc(hasPermission, organization) {
  return hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT, organization);
}

function canSubmitDraw(hasPermission, organization) {
  return hasPermission(PERMISSION_ACTION.SUBMIT_DRAW, organization);
}

function canAccessStakeholders(hasPermission) {
  return hasPermission(PERMISSION_ACTION.ACCESS_STAKEHOLDERS);
}

function canSeeDrawAssessmentQuestions(hasPermission) {
  return hasPermission(PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM);
}

export function DrawReviewsPage({ history, match }) {
  const { projectId, drawId, ruleId } = match.params;
  const { data, loading, subscribeToMore } = useQuery(QUERY, {
    variables: { projectId, drawId },
  });
  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const { rules } = subscriptionData;
        return {
          ...prev,
          draw: { ...prev.draw, rules },
        };
      },
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  const { hasPermission } = useContext(UserContext);
  const showAllRules = getSearchByKey(history, "showAllRules");

  if (loading) return <Loadable loading />;

  const { project } = data;
  const { draw, organization, users } = project;
  const { questions, responses, reviews, rules, submissions } = draw;
  const { reportTemplates } = organization;
  // Hide Comments with no target (deleted line item from budget, deleted doc, etc.)
  const comments = reject(draw.comments, (comment) => comment.target === null);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column columns={8} padding={majorScale(3)}>
          {canDownloadDoc(hasPermission, organization) && (
            <Downloads
              drawId={draw.id}
              drawName={draw.name}
              exportURL={draw.export.url}
              hasDocuments={draw.documents.length > 0}
              hasPermission={hasPermission}
              reportTemplates={reportTemplates}
              organizationId={organization.id}
              organizationName={organization.name}
              projectName={project.name}
              projectId={project.id}
              summaryURL={draw.summary.url}
            />
          )}
          <Reviews
            drawId={drawId}
            hasPermission={hasPermission}
            projectId={projectId}
            reviews={reviews}
            users={users}
          />
          {canSubmitDraw(hasPermission, organization) &&
            canAccessStakeholders(hasPermission) && (
              <Recipients submissions={submissions} projectId={projectId} />
            )}
          <Comments comments={comments} />
          {canSeeDrawAssessmentQuestions(hasPermission) &&
            questions.length > 0 && (
              <DrawAssessment
                drawId={drawId}
                questions={questions}
                responses={responses}
              />
            )}
        </Grid.Column>
        <Grid.Column columns={8} padding={majorScale(3)}>
          <Rules
            drawId={drawId}
            history={history}
            modalRuleId={ruleId}
            projectId={projectId}
            rules={rules}
            showAllRules={showAllRules}
            users={users}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

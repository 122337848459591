import { useState, useEffect } from "react";
import { download } from "helpers/downloadHelpers";
import { showDownloadTimeoutToast } from "components/materials/DownloadLink";
import { useAuth } from "../../Auth";

export function usePreserveDownload() {
  const [preservedDownload, setPreservedDownload] = useState(null);
  const [errorPreservingDownload, setErrorPreservingDownload] = useState(false);
  const [preservingDownload, setPreservingDownload] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    // The url gets automatically cleaned up on navigating away from the page,
    // so while a leak would be contextually unlikely,
    // to be safe, we go ahead and revoke the url on component unmount
    return () => window.URL.revokeObjectURL(preservedDownload?.url);
  }, [preservedDownload]);

  function onDownload({ blob, filename }, onComplete) {
    const url = window.URL.createObjectURL(blob);
    setPreservedDownload({ url, filename });
    setPreservingDownload(false);
    if (onComplete) {
      onComplete(url);
    }
  }

  function preserveDownload(url, onComplete) {
    const authenticatedUrl = auth.getAuthenticatedUrl(url);

    setErrorPreservingDownload(false);
    setPreservingDownload(true);

    download({
      url: authenticatedUrl,
      onSuccess: (args) => {
        onDownload(args, onComplete);
      },
      onError: () => setErrorPreservingDownload(true),
      onTimeout: () => {
        setPreservingDownload(false);
        showDownloadTimeoutToast();
      },
    });
    return preservedDownload;
  }

  function resetPreservedDownload() {
    window.URL.revokeObjectURL(preservedDownload?.url);
    setPreservedDownload(null);
  }

  return {
    preserveDownload,
    preservedDownload,
    onDownload,
    resetPreservedDownload,
    errorPreservingDownload,
    preservingDownload,
  };
}

import { useContext } from "react";
import { Pane, Heading, Text } from "components/materials";
import { ThemeContext, majorScale } from "helpers/utilities";

export function CardHeading({ disabled, text, subHeading }) {
  const theme = useContext(ThemeContext);

  return (
    <Pane>
      <Pane display="flex" alignItems="center">
        <Heading
          fontSize={majorScale(3)}
          fontWeight={500}
          lineHeight={1.2}
          color={disabled ? theme.colors.gray600 : theme.colors.headingTextGray}
        >
          {text}
        </Heading>
      </Pane>
      {subHeading && (
        <Pane marginTop={majorScale(1)} marginRight={majorScale(8)}>
          <Text size={300} color={theme.colors.textGray}>
            {subHeading}
          </Text>
        </Pane>
      )}
    </Pane>
  );
}

import { intersection, get } from "lodash";

const DOCUMENT_INSPECTION_REPORT_COLUMNS = [
  "inspectorName",
  "inspectorNotes",
  "inspectionExpectedCompletionDate",
  "inspectionDate",
  "inspectionCompany",
  "inspectionPercentComplete",
];

const LATEST_DRAW_INSPECTION_REPORT_COLUMNS = [
  "inspectorNameReport",
  "inspectionRequestedDateReport",
  "inspectionDateReport",
  "inspectionSubmittedDateReport",
];

const CONTINGENCY_COLUMNS = [
  "contingencyRemaining",
  "contingencyRemainingPercent",
];

function includesAny(config, values) {
  const columns = get(config, "columnConfig", []);
  const filters = get(config, "filterConfig", []).map((filter) => filter.key);
  return intersection([...columns, ...filters], values).length > 0;
}

export function fields(config) {
  return {
    includeDocumentInspectionReports: includesAny(
      config,
      DOCUMENT_INSPECTION_REPORT_COLUMNS
    ),
    includeLatestDrawInspectionReport: includesAny(
      config,
      LATEST_DRAW_INSPECTION_REPORT_COLUMNS
    ),
    includeContingency: includesAny(config, CONTINGENCY_COLUMNS),
  };
}

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowRightIcon,
  MapIcon,
  TickIcon,
  WarningSignIcon,
} from "evergreen-ui";
import {
  Pane,
  Heading,
  Paragraph,
  Button,
  IconButton,
  Shortener,
  EmptyCard,
  ConfigureButtons,
} from "components/materials";
import { formatDate } from "helpers/dateHelpers";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import analytics from "helpers/analytics";
import { REVIEW_STATE, PERMISSION_ACTION } from "helpers/enums";
import { getDrawStateDate } from "../../../templates/PortfolioDashboard/PortfolioProjectCard";
import { DrawRatios } from "./DrawRatios";
import { getDrawStatusColor } from "../../../templates/ProjectDraws";

export const MOST_RECENT_DRAW_CONFIGURATION_SETTINGS = {
  i: "mostRecentDraw",
  x: 2,
  y: 0,
  w: 1,
  h: 1,
  disabled: false,
};

export function MostRecentDraw({
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name: cardName,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const history = useHistory();
  const { recentDraw: draw } = project;
  const userCannotCreateDraw = !hasPermission(PERMISSION_ACTION.CREATE_DRAW);

  if (!draw) {
    return (
      <EmptyCard
        name="Most Recent Draw"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={cardName}
        cards={cards}
        setCards={setCards}
        icon={<TickIcon color="muted" />}
      >
        {userCannotCreateDraw ? (
          <React.Fragment>
            <MapIcon size={majorScale(3)} />
            <Paragraph marginTop={majorScale(2)}>
              This project has no draws.
            </Paragraph>
            <Paragraph>You do not have access to create a draw.</Paragraph>
            <Paragraph>Please contact your admin.</Paragraph>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Paragraph paddingBottom={majorScale(1)}>
              This project has no draws.
            </Paragraph>
            <Button
              appearance="primary"
              onClick={() => history.push(`/projects/${project.id}/draws/new`)}
              purpose="create new draw"
            >
              Create New Draw
            </Button>
          </React.Fragment>
        )}
      </EmptyCard>
    );
  }

  const { name, requestedAmount, reviews } = draw;

  const approvalsCount = reviews.filter(
    ({ state }) => state !== REVIEW_STATE.APPROVED
  ).length;

  return (
    <Pane
      height="100%"
      width="100%"
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
    >
      <Pane display="flex" flexDirection="column">
        <Pane
          display="flex"
          marginBottom={minorScale(3)}
          borderBottom={`1px solid ${theme.colors.gray100}`}
          justifyContent="space-between"
        >
          <Pane display="flex">
            <Shortener
              size={700}
              fontWeight={500}
              text={name}
              limit={18}
              color={isDisabled ? theme.colors.gray600 : null}
            />
            {approvalsCount > 0 && (
              <Pane
                height="28px"
                backgroundColor={theme.colors.red25}
                paddingX={majorScale(1)}
                marginLeft={majorScale(1)}
                borderRadius={minorScale(1)}
                display="flex"
                alignItems="center"
              >
                <WarningSignIcon
                  color={theme.colors.red600}
                  marginRight={majorScale(1)}
                />
                <Paragraph
                  color={theme.colors.red600}
                  fontWeight={500}
                  borderRadius={minorScale(1)}
                >
                  {approvalsCount}
                  {" draw "}
                  {`${approvalsCount === 1 ? "approval" : "approvals"}`} needed
                </Paragraph>
              </Pane>
            )}
          </Pane>
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={cardName}
            />
          )}
          {!isConfigurable && (
            <IconButton
              icon={ArrowRightIcon}
              onClick={() => {
                analytics.track("project dashboard navigate to mostRecentDraw");
                history.push(`/projects/${project.id}/draws/${draw.id}`);
              }}
              appearance="minimal"
              border={`1px solid ${theme.colors.gray400}`}
            />
          )}
        </Pane>
        <Pane display="flex">
          <Pane minWidth="40%">
            <Paragraph
              marginTop={minorScale(3)}
              marginBottom={minorScale(2)}
              color={theme.defaultColors.lightBlue}
              size={300}
            >
              Status
            </Paragraph>
            <Heading
              color={getDrawStatusColor(draw.state, theme)}
              fontSize={majorScale(2)}
              fontWeight={500}
            >
              {t(`drawStates.${draw.state}`)}
            </Heading>
            <Paragraph
              marginTop={minorScale(3)}
              marginBottom={minorScale(2)}
              color={theme.defaultColors.lightBlue}
              size={300}
            >
              Amount Requested
            </Paragraph>
            <Heading size={800} paddingBottom={4} fontWeight={500}>
              {formatCurrency(requestedAmount)}
            </Heading>
            <Paragraph size={300}>
              {formatDate(getDrawStateDate(draw))}
            </Paragraph>
          </Pane>
          <Pane width="70%">
            <Paragraph
              marginTop={minorScale(3)}
              marginBottom={minorScale(2)}
              size={300}
            >
              Progress
            </Paragraph>
            <Pane display="flex" flexWrap="wrap">
              <DrawRatios draw={draw} project={project} />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

import { IconButton } from "components/materials";
import { minorScale } from "helpers/utilities";

export function Action({ icon, ...props }) {
  return (
    <IconButton
      appearance="minimal"
      flex="0 0 auto"
      size="small"
      icon={icon}
      marginX={minorScale(1)}
      onClick={props.onClick}
      type="button"
    />
  );
}

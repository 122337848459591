import { FC, useContext } from "react";
import { Pane, Text } from "components/materials";
import { ArrowDownIcon, ArrowUpIcon } from "evergreen-ui";
import formatPercent from "helpers/formatPercent";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";

export enum AverageBadgeStyle {
  Normal = "normal",
  Warning = "warning",
}

interface AverageBadgeType {
  style: AverageBadgeStyle;
  value: number;
}

export const AverageBadge: FC<AverageBadgeType> = ({
  style = AverageBadgeStyle.Normal,
  value,
}) => {
  const theme = useContext(ThemeContext) as any;

  const renderIcon = (value: number, iconProps: any) => {
    if (value === 0) return null;

    if (value > 0) return <ArrowUpIcon size={14} {...iconProps} />;

    return <ArrowDownIcon size={14} {...iconProps} />;
  };

  const { containerProps, iconProps, textProps } =
    style === AverageBadgeStyle.Normal
      ? {
          containerProps: {
            background: theme.alerts.successMessageBackground,
            border: `1px solid ${theme.alerts.successMessageBorder}`,
          },
          iconProps: { color: theme.alerts.successMessageText },
          textProps: { color: theme.alerts.successMessageText },
        }
      : {
          containerProps: {
            background: theme.colors.errorLightRed,
            border: `1px solid ${theme.colors.errorRed}`,
          },
          iconProps: { color: theme.colors.errorRed },
          textProps: { color: theme.colors.errorRed },
        };

  return (
    <Pane
      alignItems="center"
      borderRadius={majorScale(3)}
      display="flex"
      marginLeft={majorScale(3)}
      paddingX={majorScale(2)}
      paddingY={minorScale(1)}
      {...containerProps}
    >
      {renderIcon(value, iconProps)}
      <Text fontWeight={theme.fontWeights.bold} size={400} {...textProps}>
        {formatPercent(Math.abs(value), "0%")}
      </Text>
    </Pane>
  );
};

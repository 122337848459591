import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { ContentLayout, BorrowerRegistrationForm } from "components/templates";
import { Loadable, Pane } from "components/materials";
import { get } from "lodash";
import isBlank from "helpers/isBlank";
import { majorScale, toaster } from "helpers/utilities";

const QUERY = gql`
  query RegisterBorrowerPageQuery($invitationToken: String!) {
    borrower(invitationToken: $invitationToken) {
      id
      registrationComplete
      email
      name
    }
  }
`;

const REGISTER_BORROWER = gql`
  mutation RegisterBorrowerPageMutation(
    $invitationToken: String!
    $name: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    registerBorrower(
      invitationToken: $invitationToken
      name: $name
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
    }
  }
`;

function initialValues(data, invitationToken) {
  return {
    invitationToken,
    password: "",
    passwordConfirmation: "",
    email: data.borrower.email,
    name: data.borrower.name,
  };
}

function validate(values) {
  const errors = {};

  if (isBlank(values.name)) errors.name = "Please enter a name";
  if (isBlank(values.password)) errors.password = "Please enter a password";
  if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = "Password does not match";
  }
  return errors;
}

function onError(result) {
  const graphQlErrors =
    result && result.graphQLErrors
      ? result.graphQLErrors.map(({ message }) => message).join(", ")
      : null;
  const errorMessage =
    graphQlErrors || (result && result.message) || "Something went wrong";

  toaster.danger(errorMessage);
}

export function RegisterBorrowerPage({ history, match }) {
  const { invitationToken } = match.params;

  function onCompleted() {
    history.push("/");
  }

  const [
    registerBorrower,
    registerBorrowerResult,
  ] = useMutation(REGISTER_BORROWER, { onError, onCompleted });

  const { data, loading } = useQuery(QUERY, { variables: { invitationToken } });

  function onSubmit({ invitationToken, name, password, passwordConfirmation }) {
    registerBorrower({
      variables: { invitationToken, name, password, passwordConfirmation },
    });
  }

  if (loading) return <Loadable loading />;

  if (!data || !data.borrower)
    return <Pane margin={majorScale(2)}>This invitation has expired.</Pane>;

  const registrationComplete = get(data, "borrower.registrationComplete");
  if (registrationComplete) {
    history.push("/");
  }

  return (
    <ContentLayout fixedWidth title="Finish Registration">
      <Formik
        initialValues={initialValues(data, invitationToken)}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(form) => (
          <BorrowerRegistrationForm
            handleSubmit={form.handleSubmit}
            loading={registerBorrowerResult.loading}
            error={registerBorrowerResult.error}
          />
        )}
      </Formik>
    </ContentLayout>
  );
}

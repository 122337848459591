import { UniqueIdentifier } from "@dnd-kit/core";

export enum DownloadType {
  PDF = "PDF",
  Zip = "Zip",
}

export enum ItemType {
  Section = "Section",
  Template = "Template",
  Document = "Document",
}

export interface Item {
  id: UniqueIdentifier;
  type: ItemType;
  name: string;
  attributes?: {
    [key: string]: any;
  };
  children?: Item[];
  collapsed?: boolean;
}

export interface FlattenedItem extends Item {
  parentId: UniqueIdentifier | null;
  depth: number;
  index: number;
}

export interface AddItem extends Item {
  parentId?: UniqueIdentifier | null;
  depth?: number;
}

export interface OnAddCallback {
  (siblingId: UniqueIdentifier, items: AddItem[]): void;
}

export interface Template {
  id: string;
  name: string;
}

// Need to update this to match the actual schema
export interface FastDocument {
  id: string;
  adjustedAmount: number;
  agreementAmount: number;
  agreementName: string;
  agreementNumber: string;
  amount: number;
  amountNotAllocatedToAgreements: number;
  amountFullyAllocatedToAgreements: number;
  amountPaid: number;
  assignedUser: string;
  date: string;
  datePaid: string;
  description: string;
  divisionNames: string;
  draw: string;
  drawId: string;
  errors: string;
  fileContentType: string;
  grossAmount: string;
  hasAdjustments: string;
  hasComments: string;
  insertedAt: string;
  isBackup: string;
  issues: {
    id: string;
    documentId: string;
    lineItemId: string;
    name: string;
    severity: string;
  };
  jobCostCodes: string;
  lineItemNumbers: string;
  lineItems: string;
  isPaid: string;
  importSource: string;
  importSourceMetadata: {
    id: string;
    yardiStatus: string;
    lastYardiSync: string;
  };
  number: string;
  originalFileName: string;
  pages: string;
  parentToBeSplit: string;
  postedAt: string;
  processingFinishedAt: string;
  projectCustomId: string;
  projectId: string;
  projectName: string;
  recentApprovalReviews: {
    id: string;
    userId: string;
    userName: string;
    reviewType: string;
    insertedAt: string;
  };
  state: string;
  storedMaterialsAmount: string;
  superLineItems: string;
  type: string;
  uploadedBy: string;
  vendorCostCode: string;
  vendorName: string;
  wasEmailImported: string;
}

import gql from "graphql-tag";

export default gql`
  fragment BudgetFragment on Project {
    id
    acres
    amount
    inProgressAmount
    contingencyOriginalAmount
    squareFeet
    usesOfFundsEnabled
    draws {
      id
      name
      state
      fundedDate
      lineItems {
        id
        scopeId
        adjustmentsAmount
        expenses {
          id
        }
        grossRequestedAmount
        inspectionPercentComplete
        requestedAmount
        retainageAmount
      }
    }
    lineItems {
      id
      scopeId
      adjustmentsAmount
      previousAdjustmentsAmount
      balanceToFundAmount
      budgetAmount
      committedAmount
      costEstimate
      disbursedToDateAmount
      division {
        id
        scopeId
        name
        position
      }
      issues(budgetLevel: true) {
        id
        lineItemId
        name
        severity
      }
      superLineItem {
        id
        name
      }
      exposedAmount
      exposureAmount
      exposureForecast
      pcoAmount
      grossPercentRemaining
      grossRequestedToDateAmount
      masterFormatDivision
      name
      number
      originalBudgetAmount
      outOfContractAmount
      percentRemaining
      position
      requestedToDateAmount
      retainageToDateAmount
      totalAgreementsAmount
      totalCommitmentsAmount
      types
      untrackedContingencyAmount
    }
    budgetAdjustments {
      id
      transactions {
        id
        amount
        lineItemId
      }
    }
    projection {
      id
    }
    template {
      id
    }
    contingencySegments {
      all {
        segments {
          name
          value
          id
        }
        drawnContingency
        originalContingency
        percentUsed
        remainingContingency
        totalContingency
        untrackedContingency
      }
      hardCosts {
        segments {
          name
          value
          id
        }
        drawnContingency
        originalContingency
        percentUsed
        remainingContingency
        totalContingency
        untrackedContingency
      }
    }
  }
`;

import { useEffect, useState } from "react";
import type { FC, PropsWithChildren } from "react";
import { ApolloProvider } from "@apollo/react-components";
import type ApolloClient from "apollo-client";
import { useAuth } from "Auth";
import { createClient } from "client";
import { Loadable } from "components/materials";
import type { NormalizedCacheObject } from "apollo-cache-inmemory";
import { useFeatureFlags } from "FeatureFlags";

export const Provider: FC<PropsWithChildren> = ({ children }) => {
  /**
   * This is only to be used while we try to maintain the singleton ApolloClient
   * from `src/client.js. It allows us to have a function that retrieves the
   * token every time, that isn't async.
   *
   * Eventually, only Auth0's client will permanently hold the token.
   */
  const { isEnabled } = useFeatureFlags();
  const { getAccessToken } = useAuth();
  const [client, setClient] = useState<ApolloClient<
    NormalizedCacheObject
  > | null>(null);

  useEffect(() => {
    setClient(createClient(getAccessToken));
  }, [isEnabled, getAccessToken]);

  if (!client) {
    return <Loadable />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

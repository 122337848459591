import { useState } from "react";
import { EditIcon } from "evergreen-ui";
import { ListReviews } from "components/templates";
import { Pane, Text } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { ReviewCard } from "./ReviewCard";
import { EditDrawReviewers } from "./EditDrawReviewers";

export function Reviews({ drawId, hasPermission, projectId, reviews, users }) {
  const [drawReviewersOpen, setDrawReviewersOpen] = useState(false);
  const canEditSettings = hasPermission(PERMISSION_ACTION.EDIT_USER_ACCESS);

  return (
    <ReviewCard
      title="Draw Approvals"
      headerAction={
        canEditSettings ? (
          <Pane
            display="flex"
            alignItems="center"
            cursor="pointer"
            onClick={() => setDrawReviewersOpen(true)}
          >
            <EditIcon color="selected" marginRight={majorScale(1)} />
            <Text color="selected">Edit Reviewers</Text>
          </Pane>
        ) : undefined
      }
    >
      <ListReviews reviews={reviews} />
      {drawReviewersOpen && (
        <EditDrawReviewers
          drawId={drawId}
          projectId={projectId}
          reviews={reviews}
          users={users}
          onClose={() => setDrawReviewersOpen(false)}
        />
      )}
    </ReviewCard>
  );
}

import { Fragment, useContext } from "react";
import { SendToIcon } from "evergreen-ui";
import {
  Accordion,
  Button,
  Wizard,
  Heading,
  Frame,
} from "components/materials";
import { SendDrawContext } from "contexts/sendDrawContext";
import { ORGANIZATION_TYPE } from "helpers/enums";
import t from "helpers/translate";
import { minorScale } from "helpers/utilities";
import { useAuth } from "Auth";

// List SUBMISSION_ROLE_TYPE values explicitly to ensure their order
const ORDERED_SUBMISSION_ROLE_TYPES = [
  ORGANIZATION_TYPE.ARCHITECT,
  ORGANIZATION_TYPE.BORROWER,
  ORGANIZATION_TYPE.CONTRACTOR,
  ORGANIZATION_TYPE.EQUITY_PARTNER,
  ORGANIZATION_TYPE.INSPECTOR,
  ORGANIZATION_TYPE.LENDER,
  ORGANIZATION_TYPE.SUBCONTRACTOR,
  ORGANIZATION_TYPE.TITLE_COMPANY,
  ORGANIZATION_TYPE.VENDOR,
  ORGANIZATION_TYPE.LAWYER,
  ORGANIZATION_TYPE.ENGINEER,
  ORGANIZATION_TYPE.DEVELOPER,
  ORGANIZATION_TYPE.BROKER,
  ORGANIZATION_TYPE.ACCOUNTANT,
  ORGANIZATION_TYPE.CONSULTANT,
  ORGANIZATION_TYPE.MUNICIPALITY,
  ORGANIZATION_TYPE.OTHER,
];

const url = process.env.REACT_APP_GRAPHQL_HOST;
function EmailPreviewPanel({ drawId, notes, selectedDocumentIds, role }) {
  const auth = useAuth();
  const accessToken = auth.getAccessToken();

  const previewSrc = `${url}/mail/preview/${drawId}?access_token=${accessToken}&notes=${encodeURIComponent(
    notes
  )}&selected_document_ids=${encodeURIComponent(
    selectedDocumentIds
  )}&cover_sheet_template_id=${encodeURIComponent("")}&role=${role}`;

  return (
    <Frame
      title="Preview"
      style={{ width: "100%", height: "40vh" }}
      frameBorder="0"
      src={previewSrc}
      failureMessage={t("previewEmail.previewEmailError")}
    />
  );
}

function EmailPreview({ drawId, notes, selectedDocumentIds, roles }) {
  const defaultType = ORGANIZATION_TYPE.OTHER;
  const panels = ORDERED_SUBMISSION_ROLE_TYPES.reduce((acc, role) => {
    if (roles.indexOf(role) >= 0) {
      return acc.concat({
        key: role,
        title: t(`organizationType.${role}`),
        content: (
          <EmailPreviewPanel
            drawId={drawId}
            notes={notes}
            selectedDocumentIds={selectedDocumentIds}
            role={role}
          />
        ),
      });
    }

    return acc;
  }, []);

  const defaultPanel = [
    {
      key: defaultType,
      title: t(`organizationType.${defaultType}`),
      content: (
        <EmailPreviewPanel
          drawId={drawId}
          notes={notes}
          selectedDocumentIds={selectedDocumentIds}
          role={defaultType}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Heading>Preview Email</Heading>
      <Accordion
        contentStyles={{ paddingY: minorScale(3) }}
        defaultActiveKeys={
          panels.length === 0 ? [defaultPanel[0].key] : [panels[0].key]
        }
        headerStyles={{ size: 400 }}
        panels={panels.length === 0 ? defaultPanel : panels}
        panelStyles={{ paddingY: minorScale(3) }}
      />
    </Fragment>
  );
}
export function PreviewEmailStep() {
  const {
    draw,
    notes,
    onBack,
    selectedDocuments,
    stakeholders,
    sendDraw,
    sendDrawLoading,
    setStakeholders,
    setConfirmEnabled,
  } = useContext(SendDrawContext);

  const roles = stakeholders.map(({ role }) => role || ORGANIZATION_TYPE.OTHER);
  const stakeholderIds = stakeholders.map((stakeholder) => stakeholder.id);
  const documentIds = selectedDocuments.reduce(
    (documentIds, { documentId }) =>
      documentId ? [...documentIds, documentId] : documentIds,
    []
  );
  const drawPackageContents = selectedDocuments.map(
    ({ documentId, section, templateId }, index) => ({
      documentId,
      drawId: draw.id,
      order: index,
      section,
      templateId,
    })
  );

  function handleSubmit() {
    setStakeholders(stakeholders);
    setConfirmEnabled(false);
    sendDraw({
      variables: {
        documentIds,
        drawId: draw.id,
        drawPackageContents,
        notes,
        stakeholderIds,
      },
    });
  }

  return (
    <Fragment>
      <Wizard.Content>
        <EmailPreview
          drawId={draw.id}
          notes={notes}
          selectedDocumentIds={documentIds}
          roles={roles}
        />
      </Wizard.Content>
      <Wizard.Actions onBack={onBack} hideNext>
        <Button
          appearance="primary"
          content="Send"
          iconBefore={SendToIcon}
          isLoading={sendDrawLoading}
          onClick={handleSubmit}
          purpose="submit-draw preview-email submit"
          type="submit"
        />
      </Wizard.Actions>
    </Fragment>
  );
}

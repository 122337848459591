import React from "react";

import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { CommentIcon } from "evergreen-ui";
import {
  Button,
  MentionsBody,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { get } from "lodash";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { TRIM_DATE_FORMAT, formatDateTime } from "helpers/dateHelpers";
import { PROJECT_DASHBOARD_QUERY } from "../../graphql";

const DISMISS_MENTION = gql`
  mutation DismissMention($id: String) {
    dismissMention(id: $id) {
      id
    }
  }
`;

const targetNameDetails = (target) => {
  switch (target.__typename) {
    case "Rule":
    case "RuleTransition":
      return ["on a rule", null];
    case "LineItem":
      return ["on line item:", target.name];
    case "BasicDocument":
      return ["on document:", get(target, "file.name")];
    case "Project":
      return ["on project:", get(target, "name")];
    case "Task":
      return ["on task:", target.eventName];
    default:
      return "";
  }
};

export function Mention({
  history,
  mention,
  onDismissCompleted,
  projectId,
  currentUserId,
}) {
  const theme = React.useContext(ThemeContext);
  const { id, comment } = mention;
  const { target } = comment;

  const [dismiss, dismissResult] = useMutation(DISMISS_MENTION, {
    onCompleted: onDismissCompleted,
    variables: { id },
    refetchQueries: [
      {
        query: PROJECT_DASHBOARD_QUERY,
        variables: { projectId, userId: currentUserId },
      },
    ],
  });

  function handleClick() {
    switch (target.__typename) {
      case "Rule":
        history.push({
          pathname: `/projects/${target.projectId}/draws/${target.scopeId}/reviews/${target.id}`,
          search: "?showAllRules=true",
        });
        break;
      case "RuleTransition":
        history.push({
          pathname: `/projects/${target.rule.projectId}/draws/${target.rule.scopeId}/reviews/${target.rule.id}`,
          search: "?showAllRules=true",
        });
        break;
      case "LineItem":
        history.push(
          `/projects/${target.projectId}/draws/${target.drawId}/line_items/${target.id}`
        );
        break;
      case "Project":
        history.push(`/projects/${target.id}`);
        break;

      case "BasicDocument":
        history.push(
          target.drawId
            ? `/projects/${target.projectId}/draws/${target.drawId}/documentation/${target.id}`
            : `/projects/${target.projectId}/documentation/${target.id}`
        );
        break;
      default:
        break;
    }
  }

  const [prefix, targetName] = targetNameDetails(target);

  return (
    <Pane
      backgroundColor="#F9F9FA"
      cursor="pointer"
      marginBottom={majorScale(2)}
      onClick={handleClick}
      padding={minorScale(3)}
    >
      <Pane display="flex">
        <Pane
          height={16}
          width={16}
          marginRight={majorScale(1)}
          marginBottom={majorScale(2)}
        >
          <CommentIcon color="muted" />
        </Pane>
        <Text
          color={theme.colors.textGray}
          flex="1 1 auto"
          overflow="hidden"
          size={300}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          Comment {prefix}{" "}
          {targetName && (
            <Text size={300} fontWeight={500}>
              {targetName}
            </Text>
          )}
        </Text>
        <Button
          appearance="minimal"
          isLoading={dismissResult.loading}
          border={`1px solid ${theme.colors.gray500}`}
          background="white"
          height="26px"
          paddingY={minorScale(1)}
          paddingX={minorScale(3)}
          onClick={(e) => {
            preventEventBubbling(e);
            dismiss();
          }}
          purpose="action-items mention dismiss"
        >
          <Text size={300} fontWeight={500}>
            Dismiss
          </Text>
        </Button>
      </Pane>
      <Pane paddingX={majorScale(3)} width="85%">
        <Paragraph marginBottom={majorScale(1)} size={300}>
          <MentionsBody comment={comment} textProps={{ size: 300 }} />
        </Paragraph>
        <Pane>
          <Paragraph color="muted" size={300}>
            {comment.author.fullName} ·{" "}
            {formatDateTime(comment.insertedAt, "", TRIM_DATE_FORMAT)} ·{" "}
            {formatDateTime(comment.insertedAt, "", "hh:mmaaa")}
          </Paragraph>
        </Pane>
      </Pane>
    </Pane>
  );
}

Comment.propTypes = {
  mention: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onDismissCompleted: PropTypes.func.isRequired,
};

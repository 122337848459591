import { Fragment, useEffect, useState } from "react";
import { dataUrlToFile } from "helpers/downloadHelpers";
import { DownloadLink, Heading, Pane, Paragraph } from "components/materials";
import {
  FundingSourceProjectionChart,
  LineItemProjectionChart,
} from "components/templates";
import { PERMISSION_ACTION } from "helpers/enums";
import { uploadFile } from "helpers/uploadHelpers";
import { useProjection } from "hooks/use-projections";
import analytics from "helpers/analytics";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import { ReviewCard } from "./ReviewCard";
import { useAuth } from "../../../Auth";

const BUDGET_CHART_ID = "review-projection-graph";
const FUNDING_SOURCE_CHART_ID = "review-projection-graph";
const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

export function Downloads({
  drawId,
  drawName,
  exportURL,
  hasDocuments,
  hasPermission,
  organizationId,
  organizationName,
  projectId,
  projectName,
  reportTemplates,
  summaryURL,
}) {
  // All logic concerned with generating Custom Reports (.docx), and more specifically, the projection graphs that might be contained in those reports,
  // has been copied over to DrawHeader > Downloads
  // The intention is to delete this page in favor of that one, so it has just been copied for now.
  const [budgetChartImage, setBudgetChartImage] = useState(null);
  const [budgetChartFileToken, setBudgetChartFileToken] = useState("");
  const [fundingSourceChartImage, setFundingSourceChartImage] = useState(null);
  const [
    fundingSourceChartFileToken,
    setFundingSourceChartFileToken,
  ] = useState("");
  const [projection, loadingProjection, loadingFundingSources] = useProjection(
    projectId
  );
  const auth = useAuth();
  const accessToken = auth.getAccessToken();
  const budgetGraphReadyForUpload =
    budgetChartImage && isBlank(budgetChartFileToken);
  const fundingSourceGraphReadyForUpload =
    fundingSourceChartImage && isBlank(fundingSourceChartFileToken);

  useEffect(() => {
    if (budgetGraphReadyForUpload) {
      getFileTokenForChart(budgetChartImage, setBudgetChartFileToken);
    }
  }, [budgetChartImage, budgetGraphReadyForUpload]);

  useEffect(() => {
    if (fundingSourceGraphReadyForUpload) {
      getFileTokenForChart(
        fundingSourceChartImage,
        setFundingSourceChartFileToken
      );
    }
  }, [fundingSourceChartImage, fundingSourceGraphReadyForUpload]);

  // We are currently rendering the projection graphs out of the viewport
  // so that we can upload the images for use in reporting
  // Once we have the file tokens for the graphs, we should not show them anymore
  const showProjectionGraphs =
    hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) &&
    !loadingProjection &&
    !loadingFundingSources &&
    (isBlank(budgetChartFileToken) || isBlank(fundingSourceChartFileToken));

  const disableReportDownload =
    hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) &&
    (isBlank(budgetChartFileToken) || isBlank(fundingSourceChartFileToken));

  const hasReportGenerationAndReports = (reportTemplates) =>
    hasPermission(PERMISSION_ACTION.REPORT_GENERATION) &&
    reportTemplates.length > 0;

  function generateReportUrl(drawId, id) {
    let url = `${baseUrl}/download_report/${drawId}/${id}?access_token=${accessToken}`;

    if (hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING)) {
      url += `&budget_chart_token=${budgetChartFileToken}&funding_source_chart_token=${fundingSourceChartFileToken}`;
    }

    return url;
  }

  async function getFileTokenForChart(base64, callback) {
    const file = await dataUrlToFile(base64, "projection.jpg");
    uploadFile(file).then(({ data }) => {
      callback(data.storage_path);
    });
  }

  return (
    <ReviewCard title="Download Draw Files">
      {hasDocuments ? (
        <Pane display="flex" justifyContent="space-between">
          <DownloadLink
            url={exportURL}
            filename={`${drawName}_documents.zip`}
            purpose="download documents zip"
            iconName="folder-close"
            label="Draw Documents (.zip)"
          />
          <DownloadLink
            url={`${baseUrl}/download_draw_package/draw/${drawId}?access_token=${accessToken}`}
            filename={`${drawName}.pdf`}
            purpose="download documents pdf"
            iconName="document"
            label="Draw Package (.pdf)"
            onSuccess={() => {
              analytics.track("Draw Package Download - via Reviews", {
                organizationId,
                projectId,
                drawId,
              });
            }}
          />
          <DownloadLink
            url={`${summaryURL}?access_token=${accessToken}`}
            filename={`${drawName}_draw_summary.xlsx`}
            iconName="panel-table"
            purpose="download draw-summary"
            label="Draw Summary (.xlsx)"
          />
        </Pane>
      ) : (
        <Pane display="flex" justifyContent="space-between">
          <Paragraph color="muted" fontStyle="italic" size={300}>
            No documents have been added to this draw yet
          </Paragraph>
          <DownloadLink
            url={`${summaryURL}?access_token=${accessToken}`}
            filename={`${drawName}_draw_summary.xlsx`}
            iconName="panel-table"
            purpose="download draw-summary"
            label="Draw Summary (.xlsx)"
          />
        </Pane>
      )}
      {hasReportGenerationAndReports(reportTemplates) && (
        <Pane marginTop={majorScale(4)}>
          <Heading>{organizationName} Reports</Heading>
          <Pane display="flex" flexWrap="wrap" marginTop={majorScale(2)}>
            {reportTemplates.map(({ name, id }, index) => (
              <DownloadLink
                disabled={disableReportDownload}
                filename={`${name} - ${projectName} -  ${drawName}.docx`}
                iconName="document"
                key={`report-download-link-${index}`}
                label={`${name} (.docx)`}
                purpose="download draw-report"
                url={generateReportUrl(drawId, id)}
                width="50%"
              />
            ))}
          </Pane>
        </Pane>
      )}
      {showProjectionGraphs && (
        <Fragment>
          <Pane
            position="absolute"
            width="100vw"
            height="500px"
            transform="translateX(200%)"
          >
            <LineItemProjectionChart
              chartId={BUDGET_CHART_ID}
              setChartDownloadImage={setBudgetChartImage}
              projection={projection}
            />
          </Pane>
          <Pane
            position="absolute"
            width="100vw"
            height="500px"
            transform="translateX(300%)"
          >
            <FundingSourceProjectionChart
              chartId={FUNDING_SOURCE_CHART_ID}
              setChartDownloadImage={setFundingSourceChartImage}
              projection={projection}
            />
          </Pane>
        </Fragment>
      )}
    </ReviewCard>
  );
}

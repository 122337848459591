import { useState, Fragment, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import {
  PostPaymentModal,
  ResetPaymentModal,
  PostPaymentTable,
  PostPaymentActions,
} from "components/templates";
import { Loadable } from "components/materials";
import { get, isEmpty, set, values } from "lodash";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import { VENDOR_PAYMENT_TYPE } from "helpers/enums";
import {
  PaymentsContext,
  PaymentsContextProvider,
} from "contexts/paymentsContext";
import analytics from "helpers/analytics";
import { NavigationWarnings } from "helpers/behaviors";
import unformatNumber from "helpers/unformatNumber";
import { POST_PAYMENTS_TO_DELTEK } from "./graphql";

function getWarningTooltip({
  updatedSincePosted,
  isGCWithEditedDirectPays,
  cannotDirectPayVendor,
}) {
  if (isGCWithEditedDirectPays)
    return t("paymentIntegration.gcDirectPayUpdatedSincePostingTooltip");
  if (updatedSincePosted)
    return t("paymentIntegration.updatedSincePostedTooltip");
  if (cannotDirectPayVendor)
    return t("paymentIntegration.cannotDirectPayVendor");
  return undefined;
}

function getRowState({
  updatedSincePosted,
  isGCWithEditedDirectPays,
  cannotDirectPayVendor,
}) {
  if (isGCWithEditedDirectPays) {
    return "error";
  }
  if (updatedSincePosted) return "error";
  if (cannotDirectPayVendor) return "pending";
  return undefined;
}

function getCanBePosted({
  isPayableType,
  canDirectPayVendor,
  documentHasBeenPosted,
}) {
  // "Do not pay directly" can always be posted
  const isDoNotPayDirectly = !isPayableType;
  const isPayableTypeAndCanBePosted =
    !documentHasBeenPosted && canDirectPayVendor;
  return isDoNotPayDirectly || isPayableTypeAndCanBePosted;
}

function validate(values, documents) {
  const errors = {};

  Object.keys(values.payments).forEach((id) => {
    const document = documents.find((document) => id === document.id);
    if (!document.canBePosted) {
      set(
        errors,
        `payments.${id}`,
        t("paymentIntegration.cannotDirectPayVendor")
      );
    }
  });

  return errors;
}

function getInitialValues({ documents, note }) {
  return {
    note,
    payments: documents.reduce((acc, document) => {
      acc[document.id] = document.vendorPaymentType;
      return acc;
    }, {}),
  };
}

function handleServerError(error, setErrorMessage) {
  if (error.message.includes("payment_integration_required")) {
    setErrorMessage(
      "Could not post payments. No payment integration is configured. Please contact our Partner Success team to resolve this issue"
    );
    return;
  }

  setErrorMessage(error.message);
}

function handleSubmit(
  values,
  documents,
  setAmountPosted,
  postPayments,
  projectId
) {
  const payments = Object.entries(values.payments).reduce(
    (acc, [id, vendorPaymentType]) => {
      const document = documents.find((document) => document.id === id);
      if (document.postedAt) return acc;

      return [
        ...acc,
        {
          documentId: id,
          amount: formatCurrency(document.paymentDueAmount),
          vendorPaymentType,
        },
      ];
    },
    []
  );
  const total = payments.reduce(
    (total, payment) => total + unformatNumber(payment.amount),
    0
  );
  setAmountPosted(total);

  postPayments({
    variables: {
      projectId,
      payments,
      note: isBlank(values.note) ? null : values.note,
    },
  });
}

function onCompleted(data, drawId, amountPosted, setResults) {
  const postSuccessful = get(data, "postPayments[0].results[0].code") === "200";

  analytics.track(
    `Post Payments Integration ${postSuccessful ? "Succeeded" : "Failed"}`,
    { drawId, amountPosted }
  );
  setResults(data.postPayments);
}

function onError(error, drawId, amountPosted, setErrorMessage) {
  analytics.track("Post Payments Integration Failed", {
    drawId,
    amountPosted,
  });
  handleServerError(error, setErrorMessage);
}

function getTypeOptions() {
  return values(VENDOR_PAYMENT_TYPE).map((name) => ({
    key: name,
    text: t(`paymentIntegration.vendorPaymentType.${name}`),
    selectedText: t(
      `paymentIntegration.truncatedVendorPaymentType.${name}`,
      t(`paymentIntegration.vendorPaymentType.${name}`)
    ),
    value: name,
  }));
}

function InnerDeltekPaymentPage({ drawId, projectId }) {
  const [results, setResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [amountPosted, setAmountPosted] = useState(0);

  const {
    documentsToPost,
    documentsToReset,
    documentReviewers,
    getDocuments,
    mutationLoading,
    queryLoading,
    recentPaymentNote,
    setDocumentsToReset,
    setDocumentsToPost,
    resetPayments,
    saveVendorPaymentTypes,
    onPaymentPosted,
    onPaymentFailed,
  } = useContext(PaymentsContext);

  const [postPayments, { loading: postPaymentsLoading }] = useMutation(
    POST_PAYMENTS_TO_DELTEK,
    {
      onCompleted: (data) => {
        onPaymentPosted();
        onCompleted(data, drawId, amountPosted, setResults);
      },
      onError: (error) => {
        onPaymentFailed();
        onError(error, drawId, amountPosted, setErrorMessage);
      },
    }
  );

  const tableConfig = {
    columnConfig: [
      "document",
      "documentNumber",
      "vendorName",
      "vendorPaymentType",
      "documentedAmount",
      "paymentDue",
      "toBePosted",
      "posted",
    ],
    filterConfig: [],
    groupConfig: {},
    sortConfig: {},
  };

  if (queryLoading) return <Loadable loading />;

  return (
    <Formik
      initialValues={getInitialValues({
        documents: getDocuments([]),
        note: recentPaymentNote,
      })}
      validate={(values) => validate(values, documentsToPost)}
      onSubmit={(values) =>
        handleSubmit(
          values,
          documentsToPost,
          setAmountPosted,
          postPayments,
          projectId
        )
      }
    >
      {(formikProps) => {
        const documents = getDocuments(formikProps.values.payments);

        return (
          <Fragment>
            <NavigationWarnings dirty={formikProps.dirty} />
            <PostPaymentActions
              documents={documents}
              isDirty={formikProps.dirty}
              onPost={() => {
                const hasErrors = !isEmpty(
                  validate(formikProps.values, documents)
                );
                if (!hasErrors) {
                  setDocumentsToPost(documents);
                } else {
                  formikProps.setTouched(formikProps.values.payments);
                }
              }}
              onReset={() => setDocumentsToReset(documents)}
              onSavePaymentTypes={() =>
                saveVendorPaymentTypes(formikProps.values.payments)
              }
              onUndo={formikProps.handleReset}
            />
            <PostPaymentTable
              documents={documents}
              documentReviewers={documentReviewers}
              tableConfig={tableConfig}
              vendorPaymentTypeOptions={getTypeOptions()}
            />
            {documentsToPost.length > 0 && (
              <PostPaymentModal
                errorMessage={errorMessage}
                includeNote
                accountsPayableSystem="Deltek"
                onPost={formikProps.handleSubmit}
                onClose={(hasPosted) => {
                  if (!hasPosted) {
                    formikProps.setFieldValue(
                      "note",
                      formikProps.initialValues.note
                    );
                  }
                  setDocumentsToPost([]);
                  setResults(null);
                  setErrorMessage(null);
                }}
                loading={postPaymentsLoading}
                results={results}
              />
            )}
            {documentsToReset.length > 0 && (
              <ResetPaymentModal
                handleReset={resetPayments}
                loading={mutationLoading || postPaymentsLoading}
                isDirty={formikProps.dirty}
                onClose={() => setDocumentsToReset([])}
                resetWarningText={t("paymentIntegration.resetWarning")}
              />
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
}

export function DeltekPaymentPage({ match }) {
  const { drawId, projectId } = match.params;

  return (
    <PaymentsContextProvider
      drawId={drawId}
      projectId={projectId}
      includeLineItems={false}
      includeJobCostCodes={false}
      getWarningTooltip={getWarningTooltip}
      getRowState={getRowState}
      getCanBePosted={getCanBePosted}
    >
      <InnerDeltekPaymentPage drawId={drawId} projectId={projectId} />
    </PaymentsContextProvider>
  );
}

import PropTypes from "prop-types";
import { Pane } from "components/materials";
import { minorScale } from "helpers/utilities";
import { ProgressLabel } from "./ProgressLabel";
import ProgressCircular from "./ProgressCircular";
import ProgressGauge from "./ProgressGauge";

const containerStyles = (props) => ({
  borderRadius: 4,
  ...props,
});

const barStyles = () => ({
  width: "100%",
  height: "100%",
  borderRadius: 4,
});

const indicatorStyles = ({
  calculateBorderRadius,
  color = "lightgrey",
  percent,
  value,
  total,
}) => {
  percent = Math.min(percent || (value / total) * 100, 100);
  if (isNaN(percent)) percent = 0;
  return {
    width: `${percent}%`,
    height: "100%",
    background: color,
    borderTopRightRadius: calculateBorderRadius({ isFirst: true }),
    borderBottomRightRadius: calculateBorderRadius({ isFirst: true }),
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  };
};

const secondIndicatorStyles = ({
  calculateBorderRadius,
  secondColor = "lightgrey",
  secondPercent,
  secondValue,
  total,
}) => {
  secondPercent = Math.min(secondPercent || (secondValue / total) * 100, 100);
  if (isNaN(secondPercent)) secondPercent = 0;
  return {
    width: `${secondPercent}%`,
    height: "100%",
    background: secondColor,
    borderTopLeftRadius: calculateBorderRadius({ isSecondLeft: true }),
    borderBottomLeftRadius: calculateBorderRadius({ isSecondLeft: true }),
    borderBottomRightRadius: calculateBorderRadius({ isSecondRight: true }),
    borderTopRightRadius: calculateBorderRadius({ isSecondRight: true }),
  };
};

const thirdIndicatorStyles = ({
  calculateBorderRadius,
  thirdColor = "lightgrey",
  thirdPercent,
  thirdValue,
  total,
}) => {
  thirdPercent = Math.min(thirdPercent || (thirdValue / total) * 100, 100);
  if (isNaN(thirdPercent)) thirdPercent = 0;
  return {
    width: `${thirdPercent}%`,
    height: "100%",
    background: thirdColor,
    borderTopLeftRadius: calculateBorderRadius({ isThird: true }),
    borderBottomLeftRadius: calculateBorderRadius({ isThird: true }),
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
  };
};

const Progress = ({
  sideBySide,
  hasTwoPercentages,
  hasThreePercentages,
  secondValue,
  secondPercent,
  secondColor,
  thirdValue,
  thirdPercent,
  thirdColor,
  value,
  percent,
  total,
  ...props
}) => {
  const calculateBorderRadius = ({
    isFirst,
    isSecondLeft,
    isSecondRight,
    isThird,
  }) => {
    if (!sideBySide) return 4;

    // If a value comes before/after (depending on the placement), there is no border radius
    if (isFirst && (secondValue || secondPercent || thirdValue || thirdPercent))
      return 0;
    if (isSecondLeft && (value || percent)) return 0;
    if (isSecondRight && (thirdValue || thirdPercent)) return 0;
    if (isThird && (value || percent || secondValue || secondPercent)) return 0;

    return 4;
  };

  return sideBySide ? (
    <Pane {...containerStyles(props)}>
      <Pane {...barStyles()} display="flex" justifyContent="flex-start">
        <Pane
          {...indicatorStyles({
            total,
            calculateBorderRadius,
            value,
            percent,
            ...props,
          })}
        />
        {(hasTwoPercentages || hasThreePercentages) && (
          <Pane
            {...secondIndicatorStyles({
              secondColor,
              secondPercent,
              secondValue,
              total,
              calculateBorderRadius,
            })}
          />
        )}
        {hasThreePercentages && (
          <Pane
            {...thirdIndicatorStyles({
              thirdColor,
              thirdPercent,
              thirdValue,
              total,
              calculateBorderRadius,
            })}
          />
        )}
      </Pane>
    </Pane>
  ) : (
    <Pane {...containerStyles(props)}>
      <Pane {...barStyles()}>
        <Pane
          {...indicatorStyles({
            calculateBorderRadius,
            total,
            value,
            percent,
            ...props,
          })}
        >
          {hasTwoPercentages && (
            <Pane
              {...secondIndicatorStyles({
                calculateBorderRadius,
                secondColor,
                secondPercent,
                secondValue,
                total,
              })}
            />
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

const valueValidator = (props, _propName, componentName) => {
  if (
    props.percent !== null ||
    (props.value !== null && props.total !== null)
  ) {
    return null;
  }
  return new Error(
    `One of props 'percent' or 'value' and 'total' was not specified in '${componentName}'.`
  );
};

Progress.propTypes = {
  percent: valueValidator,
  secondPercent: valueValidator,
  value: valueValidator,
  secondValue: valueValidator,
  total: valueValidator,
  color: PropTypes.string,
  secondColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
};

Progress.defaultProps = {
  width: "100%",
  background: "darkgrey",
  height: minorScale(2),
};

// TODO: remove dot notation
Progress.Label = ProgressLabel;
Progress.Circular = ProgressCircular;
Progress.Gauge = ProgressGauge;

export default Progress;

import { useState, useEffect, Fragment } from "react";
import { Formik, FieldArray } from "formik";
import {
  Accordion,
  Button,
  Combobox,
  Confirm,
  Pane,
  Paragraph,
  Text,
  Form,
  Table,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import getOptions from "helpers/getOptions";
import isBlank from "helpers/isBlank";
import { set } from "lodash";
import { configurationPanelStyles } from "./helpers";
import { EditConfigurationButtons } from "./EditConfigurationButtons";

export const PANEL_KEY = "tasks";

function getInitialValues(projectTypes, projectTypeId) {
  return {
    projectTypeId,
    tasks: projectTypes.find(({ id }) => id === projectTypeId).tasks,
  };
}

export function Tasks({
  dirtyPanels,
  setPanelDirty,
  handleTasks,
  loading,
  projectTypes,
  toggle,
  expandedPanelKeys,
}) {
  const { id } = projectTypes[0];
  const [projectTypeId, setProjectTypeId] = useState(id);

  return (
    <Formik
      initialValues={getInitialValues(projectTypes, projectTypeId)}
      onSubmit={(values) => handleTasks(values)}
      validate={validate}
      enableReinitialize
    >
      {(form) => (
        <TasksPanel
          projectTypes={projectTypes}
          projectTypeId={projectTypeId}
          setProjectTypeId={setProjectTypeId}
          form={form}
          loading={loading}
          dirtyPanels={dirtyPanels}
          setPanelDirty={setPanelDirty}
          expandedPanelKeys={expandedPanelKeys}
          toggle={toggle}
        />
      )}
    </Formik>
  );
}

function TasksPanel({
  projectTypes,
  projectTypeId,
  setProjectTypeId,
  dirtyPanels,
  expandedPanelKeys,
  form,
  setPanelDirty,
  toggle,
  loading,
}) {
  const [warningContent, setWarningContent] = useState(null);

  const projectTypesOptions = getOptions(projectTypes);

  const selectedProjetType = projectTypesOptions.find(
    ({ value }) => value === projectTypeId
  );

  const dirty = dirtyPanels[PANEL_KEY];
  const open = !!expandedPanelKeys[PANEL_KEY];

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(PANEL_KEY, isDirty);
    }
    return undefined;
  }, [dirty, form, setPanelDirty]);

  return (
    <Fragment>
      <Accordion.Panel
        panelKey={PANEL_KEY}
        title="Tasks"
        onClick={() => toggle(PANEL_KEY)}
        open={open}
        actionContent={
          <Fragment>
            <EditConfigurationButtons
              dirty={dirty}
              form={form}
              loading={loading}
              onSubmit={(form) => {
                const submissionWarning = getWarningContent(form);

                submissionWarning
                  ? setWarningContent(submissionWarning)
                  : form.handleSubmit();
              }}
            />
          </Fragment>
        }
        {...configurationPanelStyles}
      >
        <Pane marginBottom={majorScale(2)}>
          <Text>
            {`Set the Tasks you would like to create for new projects of type "${selectedProjetType.text}".`}
          </Text>
        </Pane>
        <Combobox
          openOnFocus
          selectedItem={selectedProjetType}
          items={projectTypesOptions}
          itemToString={(item) => item.text}
          onChange={({ value }) => setProjectTypeId(value)}
          marginBottom={majorScale(2)}
        />
        <FieldArray name="tasks">
          {({ push, remove }) => (
            <TasksForm form={form} push={push} remove={remove} />
          )}
        </FieldArray>
      </Accordion.Panel>
      {warningContent && (
        <Confirm
          open
          content={warningContent.content}
          header={warningContent.header}
          cancelLabel="Cancel"
          confirmLabel="Accept"
          onCloseComplete={() => setWarningContent(null)}
          onConfirm={(close) => {
            form.handleSubmit();
            close();
          }}
        />
      )}
    </Fragment>
  );
}

function TasksForm({ form, push, remove }) {
  return (
    <Fragment>
      {form.values.tasks.length > 0 && (
        <Form>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>Task</Table.TextHeaderCell>
                <Table.TextHeaderCell width={150} />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Fragment>
                {form.values.tasks.map((task, index) => {
                  return (
                    <Table.Row key={`${task.id}${index}`}>
                      <Table.Cell>
                        <Form.Input name={`tasks[${index}].name`} />
                      </Table.Cell>
                      <Table.Cell>
                        <Button onClick={() => remove(index)}>
                          Delete Task
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Fragment>
            </Table.Body>
          </Table>
        </Form>
      )}
      <Button
        appearance="primary"
        content="Add Task"
        onClick={() => push({ id: null, name: "" })}
      />
    </Fragment>
  );
}

function validate(values) {
  const errors = {};

  values.tasks.forEach(({ name }, index) => {
    if (isBlank(name)) {
      set(errors, `tasks[${index}].name`, "Task name cannot be blank.");
    } else {
      const isDuplicate =
        values.tasks.filter((task) => task.name.trim() === name.trim()).length >
        1;

      if (isDuplicate) {
        set(
          errors,
          `tasks[${index}].name`,
          "Task name cannot be used more than once"
        );
      }
    }
  });

  return errors;
}

function getWarningContent({ initialValues, values }) {
  const hasAdditions = values.tasks.some(({ id }) => id === null);
  const hasDeletions = initialValues.tasks.some(
    ({ id: initialId }) => !values.tasks.find(({ id }) => id === initialId)
  );

  if (!hasAdditions && !hasDeletions) return null;
  return {
    header: hasAdditions ? "Adding Tasks?" : "Deleting Tasks?",
    content: (
      <Paragraph>
        {hasDeletions
          ? "Deleted tasks will no longer show up in Project reporting. The tasks will still exist at the project level and be available on the timeline reporting. "
          : ""}
        {hasAdditions
          ? "Added tasks will now show up in Project reporting. Existing projects of this project type will have this task added to their task list."
          : ""}
      </Paragraph>
    ),
  };
}

import { Pane } from "components/materials";
import { majorScale } from "evergreen-ui";
import { EditableDrawPackage } from "features/draw-package/components/EditableDrawPackage";

export function DrawPackagePage({ match }) {
  const { drawId, projectId } = match.params;

  return (
    <Pane width="100%" display="flex" justifyContent="center">
      <Pane marginTop={majorScale(2)} width={1400} maxWidth={1400}>
        <EditableDrawPackage drawId={drawId} projectId={projectId} />
      </Pane>
    </Pane>
  );
}

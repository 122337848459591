import { createElement, Component, Fragment } from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import PropTypes from "prop-types";
import { Button, Confirm, Loadable, Modal } from "components/materials";
import { get } from "lodash";
import t from "helpers/translate";
import { AddNoteStep } from "./AddNoteStep";
import { AddRecipientsStep } from "./AddRecipientsStep";

const QUERY = gql`
  query SubmissionPreviewPageQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      stakeholder {
        id
        member {
          id
          name
          email
        }
      }
      user {
        id
        fullName
        email
      }
    }
  }
`;

const getInitialState = () => ({
  confirmEnabled: true,
  openConfirm: false,
  openModal: false,
  steps: [],
  notes: "",
  uploads: [],
  recipients: [AddRecipientsStep.NEW_RECIPIENT],
  documentType: null,
  modalTitle: t("submitReport.header"),
});

export class SubmitReport extends Component {
  state = getInitialState();

  handleCloseConfirm = () => {
    this.setState({ openConfirm: false, openModal: false });
  };

  handleCloseCancel = () => {
    this.setState({ openConfirm: false, openModal: true });
  };

  handleClose = () => {
    const { confirmEnabled } = this.state;
    confirmEnabled
      ? this.setState({ openConfirm: true, openModal: false })
      : this.handleCloseConfirm();
  };

  handleOpen = () => {
    const steps = [AddNoteStep];
    this.setState({ steps, openModal: true });
  };

  handleBack = () => {
    this.setState((state) => ({ steps: state.steps.slice(1) }));
  };

  handleNext = (step) => {
    this.setState((state) => ({ steps: [step, ...state.steps] }));
  };

  handleChange = (stateChange, callback) => {
    this.setState(stateChange, callback);
  };

  renderTrigger = () => (
    <Button
      apperance="primary"
      content="Send Report"
      purpose="submit-report open"
      onClick={this.handleOpen}
    />
  );

  setInitialState = (data) => {
    const recipient = get(data, "submission.stakeholder.member");
    const sender = get(data, "submission.user");

    this.setState({
      recipients: [recipient, { name: sender.fullName, email: sender.email }],
    });
  };

  renderContent = ({ loading }) => {
    const { submissionId } = this.props;
    if (loading) return <Loadable loading />;

    const { openModal, openConfirm, steps, modalTitle, ...data } = this.state;

    return (
      <Fragment>
        <Confirm
          content="Are you sure you want to cancel? All information will be lost."
          header="Cancel Submission"
          onCancel={this.handleCloseCancel}
          onConfirm={this.handleCloseConfirm}
          open={openConfirm}
          title={modalTitle}
          onCloseComplete={() => {
            this.setState({ openConfirm: false });
          }}
        />
        <Modal
          closeIcon
          onClose={this.handleClose}
          open={openModal}
          trigger={this.renderTrigger()}
          title="Send Report"
        >
          {createElement(steps[0], {
            ...data,
            submissionId,
            hasBack: steps.length > 1,
            onChange: this.handleChange,
            onNext: this.handleNext,
            onBack: this.handleBack,
            onDone: this.handleCloseConfirm,
          })}
        </Modal>
      </Fragment>
    );
  };

  render() {
    const { submissionId } = this.props;

    return (
      <Query
        query={QUERY}
        variables={{ submissionId }}
        onCompleted={this.setInitialState}
      >
        {this.renderContent}
      </Query>
    );
  }
}

SubmitReport.propTypes = {
  submissionId: PropTypes.string.isRequired,
  hasSubmissions: PropTypes.bool,
};

import { useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import {
  Button,
  Form,
  Heading,
  Pane,
  Paragraph,
  Text,
} from "components/materials";
import { set, values, xorBy } from "lodash";
import isBlank from "helpers/isBlank";
import { TASK_DEPENDENCY_ORIGIN_POINT } from "helpers/enums";
import { majorScale, toaster } from "helpers/utilities";
import unformatNumber from "helpers/unformatNumber";
import t from "helpers/translate";
import { SAVE_TASK_DEPENDENCY, DELETE_TASK_DEPENDENCY } from "./graphql";

export function Dependencies({
  dependentTask,
  tasks,
  timelineRefetch,
  projectId,
}) {
  const [showDependencyForm, setShowDependencyForm] = useState(
    !!dependentTask?.predecessor
  );

  const [saveDependency, { loading: saveDependencyLoading }] = useMutation(
    SAVE_TASK_DEPENDENCY,
    {
      awaitRefetchQueries: true,
      refetchQueries: timelineRefetch,
      onError: () =>
        toaster.danger("Error saving dependency", { duration: 2.5 }),
    }
  );

  const [deleteDependency, { loading: deleteDependencyLoading }] = useMutation(
    DELETE_TASK_DEPENDENCY,
    {
      awaitRefetchQueries: true,
      refetchQueries: timelineRefetch,
      onCompleted: () => setShowDependencyForm(false),
    }
  );

  const availablePredecessorTaskOptions = getPredecessorOptions(
    dependentTask,
    tasks
  );

  if (!showDependencyForm) {
    return (
      <Fragment>
        <Heading>Dependency</Heading>
        {availablePredecessorTaskOptions.length === 0 ? (
          <Paragraph marginTop={majorScale(2)}>
            All tasks on this project are already included in this task&apos;s
            dependency chain. A dependency can be assigned once additional tasks
            are created.
          </Paragraph>
        ) : (
          <Pane>
            <Paragraph marginTop={majorScale(2)}>
              Configure this task&apos;s dependency on another task.
            </Paragraph>
            <Button
              onClick={() => setShowDependencyForm(true)}
              marginTop={majorScale(2)}
            >
              Set dependency
            </Button>
          </Pane>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Heading>Dependency</Heading>
      <Formik
        enableReinitialize
        initialValues={initialValues(dependentTask)}
        validate={validate}
        onSubmit={({ parentTaskId, originPoint, daysOffset }) => {
          const variables = {
            parentTaskId,
            originPoint,
            daysOffset: unformatNumber(daysOffset),
            dependentTaskId: dependentTask.id,
          };
          saveDependency({ variables });
        }}
      >
        {(form) => {
          return (
            <Pane>
              <Form.Select
                name="parentTaskId"
                options={availablePredecessorTaskOptions}
                marginTop={majorScale(2)}
                marginBottom={majorScale(1)}
                width={400}
              />
              <Pane display="flex" marginBottom={majorScale(2)}>
                <Pane display="flex">
                  <Text width={majorScale(10)} marginY="auto">
                    must be
                  </Text>
                  <Form.Select
                    name="originPoint"
                    marginBottom={5}
                    options={getOriginPointOptions()}
                  />
                </Pane>
                <Pane display="flex">
                  <Text width={majorScale(6)} marginY="auto">
                    for
                  </Text>
                  <Form.Input
                    name="daysOffset"
                    type="integerNegativeAllowed"
                    width={60}
                  />
                  <Text marginY="auto">days</Text>
                </Pane>
              </Pane>

              {!form.dirty && (
                <Button
                  isLoading={deleteDependencyLoading}
                  onClick={() => {
                    return dependentTask?.predecessor
                      ? deleteDependency({
                          variables: {
                            projectId,
                            dependencyId: dependentTask.predecessor.id,
                          },
                        })
                      : setShowDependencyForm(false);
                  }}
                >
                  Remove Dependency
                </Button>
              )}
              {form.dirty && (
                <Pane display="flex" alignItems="center">
                  <Button
                    isLoading={saveDependencyLoading}
                    marginRight={majorScale(1)}
                    onClick={() =>
                      dependentTask?.predecessor
                        ? form.handleReset()
                        : setShowDependencyForm(false)
                    }
                  >
                    Undo
                  </Button>
                  <Button
                    isLoading={saveDependencyLoading}
                    appearance="primary"
                    onClick={form.handleSubmit}
                  >
                    Save Dependency
                  </Button>
                </Pane>
              )}
            </Pane>
          );
        }}
      </Formik>
    </Fragment>
  );
}

function getOriginPointOptions() {
  return values(TASK_DEPENDENCY_ORIGIN_POINT).map((origin) => ({
    key: origin,
    value: origin,
    text: t(`taskDependencyOrigin.${origin}`),
  }));
}

// filter out tasks that belong to this task's existing dependency chain
// (if A -> B, B cannot be A's predecessor; if A -> B -> C -> D, none of B, C or D can be A's predecessor)
function getPredecessorOptions(dependentTask, projectTasks) {
  const projectTasksLibrary = projectTasks.reduce(
    (library, task) => ({ ...library, [task.id]: task }),
    {}
  );
  const tasksInCurrentDependencyChain = findAllDependentTasks(
    dependentTask,
    projectTasksLibrary
  );

  return xorBy(
    projectTasks,
    tasksInCurrentDependencyChain,
    "id"
  ).map((task) => ({ key: task.id, value: task.id, text: task.eventName }));
}

function findAllDependentTasks(dependentTask, projectTasks) {
  return dependentTask.dependencies.length === 0
    ? [dependentTask]
    : [dependentTask].concat(
        dependentTask.dependencies.flatMap(({ dependent }) =>
          findAllDependentTasks(projectTasks[dependent.id], projectTasks)
        )
      );
}

function getNewDependency() {
  return {
    parentTaskId: null,
    daysOffset: "0",
    originPoint: TASK_DEPENDENCY_ORIGIN_POINT.FINISH,
  };
}

function initialValues({ predecessor }) {
  return predecessor
    ? {
        parentTaskId: predecessor.parent.id,
        daysOffset: `${predecessor.daysOffset}`,
        originPoint: predecessor.originPoint,
      }
    : getNewDependency();
}

function validate(values) {
  const errors = {};

  if (isBlank(values.parentTaskId)) {
    set(errors, "parentTaskId", "Please select a task for this dependency");
  }

  if (isBlank(values.daysOffset)) {
    set(errors, "daysOffset", "Please set an offset for this dependency");
  }
  return errors;
}

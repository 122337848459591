import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OrganizationSelector } from "components/templates";
import {
  Menu,
  Modal,
  Pane,
  RadioGroup,
  TextInputField,
  Button,
} from "components/materials";
import { has, isUndefined, some } from "lodash";
import analytics from "helpers/analytics";
import isBlank from "helpers/isBlank";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export const SaveCurrentView = ({
  canManagePublicViews,
  dataColumns: columns,
  closeDropdown,
  groupConfig,
  onSaveView,
  onUpdateViews,
  saveRedirect,
  scopeOrganizationsProps: {
    allOrganizations,
    disabledOrganizations,
    selectedOrganization: defaultSelectedOrg,
  },
  setSaveRedirect,
  tableName,
  views,
}) => {
  // scopeOrganizationsProps is passed from org-level tables that show saved views from multiple orgs
  // (currently this is just the PortfolioProjectTable)
  // "selectedOrganization" is set up for this case, to allow the user to select for which org they are saving a view
  // if "selectedOrganization.id" is undefined, as it will be in all other cases,
  // the save view mutation will default to whichever contextId (orgId) is passed in the table's definition
  const [selectedOrganization, setSelectedOrganization] = useState(
    defaultSelectedOrg
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const [name, setName] = useState("");
  const [saveGroupsExpanded, setSaveGroupsExpanded] = useState(true);
  const [isPrivate, setIsPrivate] = useState(true);
  const foundViewWithDuplicateName = views.find(
    (view) => view.name === name.trim()
  );
  const validationMessage = getValidationMessage(
    name,
    foundViewWithDuplicateName
  );
  const originalState = getOriginalState(foundViewWithDuplicateName);
  const [viewVisibilityValue, setViewVisibilityValue] = useState(originalState);
  const [columnState, setColumnState] = useState("expand");

  useEffect(() => {
    if (isModalOpen) {
      analytics.track("New Table View Started", { tableName });
    }
  }, [isModalOpen, tableName]);

  if (!onSaveView) return null;

  if (some(columns, (column) => !column.id)) {
    return <Menu.Item intent="danger">Saved Views Unavailable</Menu.Item>;
  }

  const hasPublicViewsPerm =
    typeof canManagePublicViews === "function"
      ? canManagePublicViews(selectedOrganization)
      : canManagePublicViews;

  return (
    <Fragment>
      <Button background="white" onClick={() => setIsModalOpen(true)}>
        Save Current View
      </Button>
      <Modal
        open={isModalOpen || saveRedirect}
        title="Save Current View"
        hasClose={false}
        hasFooter
        confirmLabel={confirmLabel(validationMessage)}
        isConfirmDisabled={
          !!validationMessage && confirmLabel(validationMessage) !== "Replace"
        }
        onConfirm={(close) => {
          analytics.track("Table View Created", {
            tableName,
            canManagePublicViews,
            tableViewIsPublic: !isPrivate,
          });
          replaceOldView(
            name,
            views,
            selectedOrganization,
            onUpdateViews,
            confirmLabel(validationMessage)
          );
          onSaveView({
            name: name.trim(),
            groupsExpanded: groupConfig.columnId
              ? saveGroupsExpanded
              : has(groupConfig, "expanded") &&
                isUndefined(groupConfig.expanded),
            userSelectedOrganizationId: selectedOrganization.id,
            isPrivate: getVisibility(foundViewWithDuplicateName, isPrivate),
          });
          close();
        }}
        onCloseComplete={() => {
          setIsModalOpen(false);
          setSaveRedirect(false);
          closeDropdown();
        }}
      >
        <Pane marginRight={majorScale(3)}>
          {allOrganizations.length > 1 && (
            <OrganizationSelector
              title="Save current view for"
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              onOrganizationSelected={setSelectedOrganization}
            />
          )}
          <TextInputField
            // empty label to appease console warning, can't change PropTypes because it's straight from Evergreen
            label=""
            isInvalid={touched && !!validationMessage}
            maxLength={30}
            onKeyDown={() => setTouched(true)}
            onChange={(e) => setName(e.target.value)}
            placeholder="Save view as..."
            validationMessage={touched && validationMessage}
            value={name}
            width="100%"
          />
          {groupConfig.columnId && (
            <RadioGroup
              label="Expanded Groups"
              value={columnState}
              options={[
                { label: "Expand groups by default", value: "expand" },
                { label: "Collapse groups by default", value: "collapse" },
              ]}
              onChange={(event) => {
                const { value } = event.target;
                setColumnState(value);
                setSaveGroupsExpanded(value === "expand");
              }}
            />
          )}
          {hasPublicViewsPerm && (
            <RadioGroup
              label="View Visibility"
              value={viewVisibilityValue}
              options={[
                {
                  label: "Private (only visible to you)",
                  value: "isPrivate",
                  isDisabled: sameNameCheck(validationMessage),
                },
                {
                  label: "Public (visible to everyone in your organization)",
                  value: "isPublic",
                  isDisabled: sameNameCheck(validationMessage),
                },
              ]}
              onChange={(event) => {
                const { value } = event.target;
                setViewVisibilityValue(value);
                setIsPrivate(value === "isPrivate");
              }}
              marginTop={majorScale(2)}
            />
          )}
        </Pane>
      </Modal>
    </Fragment>
  );
};

function getValidationMessage(name, foundViewWithDuplicateName) {
  if (isBlank(name)) return t(`saveTableViews.blankView`);

  if (foundViewWithDuplicateName) {
    return foundViewWithDuplicateName.isDefault
      ? t(`saveTableViews.duplicateDefaultView`)
      : t(`saveTableViews.duplicateUserView`);
  }

  return null;
}

function getOriginalState(foundViewWithDuplicateName) {
  if (foundViewWithDuplicateName && foundViewWithDuplicateName.isPrivate) {
    return "isPrivate";
  }
  if (foundViewWithDuplicateName && !foundViewWithDuplicateName.isPrivate) {
    return "isPublic";
  }

  return null;
}

function getVisibility(foundViewWithDuplicateName, isPrivate) {
  if (foundViewWithDuplicateName) {
    return foundViewWithDuplicateName.isPrivate;
  }
  return isPrivate;
}

function replaceOldView(
  name,
  currentViews,
  selectedOrganization,
  onUpdateViews,
  confirmLabel
) {
  if (confirmLabel === "Replace") {
    const foundViewWithDuplicateName = currentViews.find(
      (view) => view.name === name.trim()
    );

    const newViews = currentViews.filter(
      (view) => view.id !== foundViewWithDuplicateName.id
    );

    const viewsInformation = newViews.map(({ id, name, isPrivate }) => ({
      id,
      name,
      isPrivate,
    }));

    const viewIdsToDelete = [foundViewWithDuplicateName.id];

    onUpdateViews({
      viewsInformation,
      userSelectedOrganizationId: selectedOrganization.id,
      viewIdsToDelete,
    });
  }
}

function sameNameCheck(validationMessage) {
  return validationMessage === t(`saveTableViews.duplicateUserView`);
}

function confirmLabel(validationMessage) {
  if (sameNameCheck(validationMessage)) {
    return "Replace";
  }
  return "Save";
}

SaveCurrentView.propTypes = {
  closeDropdown: PropTypes.func,
  onSaveView: PropTypes.func,
  onUpdateViews: PropTypes.func,
  scopeOrganizationsProps: PropTypes.shape({
    disabledOrganizations: PropTypes.array,
    allOrganizations: PropTypes.array,
    selectedOrganization: PropTypes.object,
  }),
};

SaveCurrentView.defaultProps = {
  scopeOrganizationsProps: {
    disabledOrganizations: [],
    allOrganizations: [],
    selectedOrganization: {},
  },
};

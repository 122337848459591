import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import { SendToIcon } from "evergreen-ui";
import { AddRecipientsForm } from "components/templates";
import { Alert, Button, Heading, Text, Wizard } from "components/materials";
import { set } from "lodash";
import t from "helpers/translate";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import { ConfirmationStep } from "./ConfirmationStep";

const MUTATION = gql`
  mutation AddRecipientsStepMutation(
    $documentType: DocumentTypeName!
    $files: [Upload]
    $notes: String
    $recipients: [RecipientInput]
    $submissionId: String!
  ) {
    submitReport(
      documentType: $documentType
      files: $files
      notes: $notes
      recipients: $recipients
      submissionId: $submissionId
    ) {
      id
    }
  }
`;

const validate = (values) => {
  const errors = {};

  values.recipients.forEach((recipient, index) => {
    if (isBlank(recipient.name)) {
      set(errors, `recipients.${index}.name`, "Please select a name");
    }
    if (isBlank(recipient.email)) {
      set(errors, `recipients.${index}.email`, "Please enter an email");
    } else if (isInvalidEmail(recipient.email)) {
      set(errors, `recipients.${index}.email`, "Please enter a valid email");
    }
  });

  return errors;
};

export class AddRecipientsStep extends Component {
  state = { showError: false };

  handleSubmit = (mutate) => (values) => {
    const { submissionId, uploads, notes, onChange, documentType } = this.props;

    const recipients = values.recipients.map((recipient) => ({
      name: recipient.name,
      email: recipient.email,
    }));

    onChange(
      () => ({ recipients, confirmEnabled: false }),
      () =>
        mutate({
          variables: {
            submissionId,
            documentType,
            files: uploads,
            notes,
            recipients,
          },
        })
    );
  };

  handleBack(form) {
    const { onBack, onChange } = this.props;
    onChange(
      () => ({ recipients: form.values.recipients }),
      () => onBack()
    );
  }

  handleError() {
    this.setState(() => ({
      showError: true,
    }));
  }

  render() {
    const { onNext, recipients } = this.props;

    return (
      <Mutation
        mutation={MUTATION}
        onError={() => this.handleError()}
        onCompleted={() => onNext(ConfirmationStep)}
      >
        {(mutate, result) => (
          <Formik
            initialValues={{ recipients }}
            validate={validate}
            onSubmit={this.handleSubmit(mutate)}
          >
            {(form) => (
              <Fragment>
                <Wizard.Content>
                  {this.state.showError && (
                    <Alert
                      intent="danger"
                      title={
                        <Text>There was an error sending your report</Text>
                      }
                    />
                  )}
                  <Heading>Add Recipients</Heading>
                  <AddRecipientsForm values={form.values} hideRoles />
                </Wizard.Content>

                <Wizard.Actions onBack={() => this.handleBack(form)} hideNext>
                  <Button
                    appearance="primary"
                    content="Send"
                    disabled={result.loading}
                    iconBefore={SendToIcon}
                    isLoading={result.loading}
                    onClick={form.handleSubmit}
                    purpose="submit-report recipients submit"
                    type="submit"
                  />
                </Wizard.Actions>
              </Fragment>
            )}
          </Formik>
        )}
      </Mutation>
    );
  }
}

AddRecipientsStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

// TODO: remove dot notation
AddRecipientsStep.NEW_RECIPIENT = AddRecipientsForm.NEW_RECIPIENT;
AddRecipientsStep.title = () => t("submitReport.header");

import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { compact } from "lodash";
import analytics from "helpers/analytics";
import { toaster } from "helpers/utilities";
import { Form } from "components/materials";
import { ReviewersForm } from "./ReviewersForm";
import { CHANGE_DRAW_REVIEWERS } from "./graphql-queries";

export function EditDrawReviewers({
  drawId,
  projectId,
  onClose,
  reviews,
  users,
}) {
  const [changeDrawReviewers, { loading }] = useMutation(
    CHANGE_DRAW_REVIEWERS,
    {
      onError: (e) => {
        analytics.track("Could not save draw reviewers", e);
        toaster.warning("Error saving draw reviewers", { duration: 2.5 });
      },
    }
  );

  const handleSubmit = (values) => {
    changeDrawReviewers({
      variables: {
        ...values,
        drawId,
        projectId,
        drawReviewerIds: compact(values.drawReviewerIds),
        updateProject: values.updateProject === "true",
      },
    });
  };

  return (
    <Formik initialValues={initialValues(reviews)} onSubmit={handleSubmit}>
      {(formik) => (
        <Form.Modal
          confirmLabel="Save"
          header="Edit Reviewers"
          loading={loading}
          onSubmit={(close) => {
            formik.handleSubmit();
            close();
          }}
          width="60%"
          onClose={onClose}
          contentContainerProps={{
            paddingTop: 0,
            paddingX: 0,
          }}
        >
          <ReviewersForm
            users={users}
            values={formik.values}
            projectId={projectId}
          />
        </Form.Modal>
      )}
    </Formik>
  );
}

function initialValues(reviews) {
  const preparerId = reviews.find(({ isPreparer }) => isPreparer)?.reviewer?.id;
  const signatoryId = reviews.find(({ isFinal }) => isFinal)?.reviewer?.id;

  const drawReviewerIds = reviews
    .filter(
      ({ reviewer }) =>
        reviewer.id !== preparerId && reviewer?.id !== signatoryId
    )
    .map(({ reviewer }) => reviewer.id);

  return {
    drawReviewerIds: drawReviewerIds.length === 0 ? [null] : drawReviewerIds,
    preparerId,
    signatoryId,
    updateProject: "false",
  };
}

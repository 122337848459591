import { REVIEW_FRAGMENT } from "helpers/fragments";
import gql from "graphql-tag";

export const DRAW_HEADER_QUERY = gql`
  query DrawActionsQuery(
    $documentType: DocumentTypeName!
    $drawId: String!
    $projectId: String!
  ) {
    project(id: $projectId) {
      id
      name
      onboardingData
      status
      draw(id: $drawId) {
        id
        name
        requestedAmount
        targetAmount
        state
        submittedDate
        fundedDate
        expectedSendDate
        isLockedDown
        isFrozen
        isPendingReview
        hasSubmissions
        drawStateUpdates {
          id
          date
          state
          user {
            id
            fullName
          }
        }
        reviews {
          ...ReviewFragment
        }
        summary {
          url
        }
        export {
          url
        }
        rules {
          id
          scopeId
          enabled
          state
        }
        issues {
          id
          documentId
          lineItemId
          name
          severity
        }
        uploads(toBeSplit: true) {
          id
        }
        drawOverviewDocuments {
          id
          vendorName
          type
          state
          amount
          adjustedAmount
          agreementAmount
          recentApprovalReviews {
            id
            userId
            userName
            reviewType
          }
        }
        lineItems {
          id
          scopeId
          requestedAmount
          balanceToFundAmount
        }
        rules {
          id
          scopeId
          enabled
          state
        }
        reviews {
          id
          state
        }
        payableDocuments {
          id
          isPaid
        }
        fundingSources {
          id
          scopeId
          disbursedAmount
        }
        questions {
          id
        }
        responses {
          id
          drawAssessmentQuestionId
          response
        }
        drawInspectionReport {
          id
          status
        }
      }
      recentDraw {
        id
      }
      lineItems {
        id
        scopeId
        name
        number
        division {
          id
          scopeId
          name
        }
        previousLineItem {
          id
          scopeId
          vendorLineItems(include_gc: true) {
            id
            scopeId
            vendor {
              id
              name
            }
            retainageToDateAmount
          }
        }
      }
      documentReviewers {
        id
        position
        user {
          id
          approvalAmountLimit
          approvalAmountMinimum
          fullName
        }
      }
      organization {
        id
        documentTemplates(type: $documentType) {
          id
          name
        }
        reportTemplates {
          id
          name
        }
      }
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const ISSUES_SUBSCRIPTION = gql`
  subscription drawIssuesUpdated($drawId: String!) {
    drawIssuesUpdated(drawId: $drawId) {
      id
      issues {
        id
        documentId
        lineItemId
        name
        severity
      }
    }
  }
`;

export const DRAW_AMOUNT_SUBSCRIPTION = gql`
  subscription drawAmountUpdated($drawId: String!) {
    drawAmountUpdated(drawId: $drawId) {
      id
      requestedAmount
    }
  }
`;

export const UPDATE_DRAW_DETAILS = gql`
  mutation UpdateDrawMutation(
    $drawId: String!
    $name: String
    $submittedDate: Date
    $expectedSendDate: Date
  ) {
    updateDraw(
      drawId: $drawId
      name: $name
      submittedDate: $submittedDate
      expectedSendDate: $expectedSendDate
    ) {
      id
      name
      submittedDate
      expectedSendDate
    }
  }
`;

export const EDIT_TARGET_AMOUNT = gql`
  mutation EditTargetAmountMutation($drawId: String!, $targetAmount: Currency) {
    updateDraw(drawId: $drawId, targetAmount: $targetAmount) {
      id
      targetAmount
    }
  }
`;

export const APPROVE_DRAW = gql`
  mutation ReviewDrawApprove($drawId: String!) {
    approveDraw(drawId: $drawId) {
      id
      isFrozen
      isPendingReview
      reviews {
        ...ReviewFragment
      }
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const MARK_COMPLETE = gql`
  mutation MarkComplete($drawId: String!, $fundedDate: Date) {
    markDrawComplete(drawId: $drawId, fundedDate: $fundedDate) {
      id
      state
      fundedDate
      fundingConfirmed
      isLockedDown
      isFrozen
      drawStateUpdates {
        id
        date
        state
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const FUND_DRAW = gql`
  mutation FundDrawMutation($drawId: String!, $fundedDate: Date!) {
    fundDraw(drawId: $drawId, fundedDate: $fundedDate) {
      id
      state
      fundedDate
      fundingConfirmed
      isLockedDown
      isFrozen
      drawStateUpdates {
        id
        date
        state
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const UPDATE_DRAW_STATE = gql`
  mutation UpdateDrawState($drawId: String!, $state: DrawState!, $date: Date!) {
    updateDrawState(drawId: $drawId, state: $state, date: $date) {
      id
      state
      fundedDate
      drawStateUpdates {
        id
        date
        state
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const UNLOCK_DRAW = gql`
  mutation UnlockDraw($drawId: String!, $date: Date!) {
    unlockDraw(drawId: $drawId, date: $date) {
      id
      state
      fundedDate
      isLockedDown
      isFrozen
      drawStateUpdates {
        id
        date
        state
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const REVERT_LAST_DRAW_STATE_UPDATE = gql`
  mutation RevertLastDrawStateUpdate($drawId: ID!, $updateId: ID!) {
    revertLastDrawStateUpdate(drawId: $drawId, updateId: $updateId) {
      id
      state
      fundedDate
      isLockedDown
      isFrozen
      drawStateUpdates {
        id
        date
        state
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const DELETE_DRAW = gql`
  mutation DeleteDraw($drawId: String!) {
    deleteDraw(drawId: $drawId) {
      id
    }
  }
`;

import {
  VariableIcon,
  DocumentIcon,
  FolderNewIcon,
  CubeAddIcon,
  IconProps,
} from "evergreen-ui";
import { ItemType } from "../../types";

const typeToIcon = {
  [ItemType.Document]: DocumentIcon,
  [ItemType.Template]: VariableIcon,
  [ItemType.Section]: FolderNewIcon,
};

interface Props extends IconProps {
  type: ItemType;
}

export function itemIcon(type: ItemType) {
  return typeToIcon[type] ?? CubeAddIcon;
}

export function ItemIcon({ type, ...iconProps }: Props) {
  const Icon = itemIcon(type);
  return <Icon {...iconProps} />;
}

import { useState, useEffect, Fragment, useContext } from "react";
import {
  DocumentIcon,
  DownloadIcon,
  FolderCloseIcon,
  PanelTableIcon,
} from "evergreen-ui";
import {
  FundingSourceProjectionChart,
  LineItemProjectionChart,
} from "components/templates";
import {
  Button,
  DownloadLink,
  Menu,
  Pane,
  Popover,
} from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale, Position, ThemeContext } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { dataUrlToFile } from "helpers/downloadHelpers";
import { uploadFile } from "helpers/uploadHelpers";
import analytics from "helpers/analytics";
import isBlank from "helpers/isBlank";
import { useProjection } from "hooks/use-projections";
import { useAuth } from "../../../Auth";

const BUDGET_CHART_ID = "review-projection-graph";
const FUNDING_SOURCE_CHART_ID = "review-projection-graph";
const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

export function Downloads({ draw, project }) {
  const { hasPermission } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const auth = useAuth();
  const accessToken = auth.getAccessToken();

  // All logic concerned with generating Custom Reports (.docx), and more specifically, the projection graphs that might be contained in those reports,
  // has been copied from DrawReviewsPage > Downloads
  // The intention is to delete that page in favor of this one, so it has just been copied for now.
  const [budgetChartImage, setBudgetChartImage] = useState(null);
  const [budgetChartFileToken, setBudgetChartFileToken] = useState("");
  const [fundingSourceChartImage, setFundingSourceChartImage] = useState(null);
  const [
    fundingSourceChartFileToken,
    setFundingSourceChartFileToken,
  ] = useState("");
  const [projection, loadingProjection, loadingFundingSources] = useProjection(
    project.id
  );

  const budgetGraphReadyForUpload =
    budgetChartImage && isBlank(budgetChartFileToken);
  const fundingSourceGraphReadyForUpload =
    fundingSourceChartImage && isBlank(fundingSourceChartFileToken);

  useEffect(() => {
    if (budgetGraphReadyForUpload) {
      getFileTokenForChart(budgetChartImage, setBudgetChartFileToken);
    }
  }, [budgetChartImage, budgetGraphReadyForUpload]);

  useEffect(() => {
    if (fundingSourceGraphReadyForUpload) {
      getFileTokenForChart(
        fundingSourceChartImage,
        setFundingSourceChartFileToken
      );
    }
  }, [fundingSourceChartImage, fundingSourceGraphReadyForUpload]);

  // We are currently rendering the projection graphs out of the viewport
  // so that we can upload the images for use in reporting
  // Once we have the file tokens for the graphs, we should not show them anymore
  const showProjectionGraphs =
    hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) &&
    !loadingProjection &&
    !loadingFundingSources &&
    (isBlank(budgetChartFileToken) || isBlank(fundingSourceChartFileToken));
  const disableReportDownload =
    hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING) &&
    (isBlank(budgetChartFileToken) || isBlank(fundingSourceChartFileToken));

  function generateReportUrl(drawId, reportId) {
    let url = `${baseUrl}/download_report/${drawId}/${reportId}?access_token=${accessToken}`;

    if (hasPermission(PERMISSION_ACTION.PROJECT_FORECASTING)) {
      url += `&budget_chart_token=${budgetChartFileToken}&funding_source_chart_token=${fundingSourceChartFileToken}`;
    }

    return url;
  }

  const canGenerateCustomReports = hasPermission(
    PERMISSION_ACTION.REPORT_GENERATION
  );

  const menuItemProps = {
    noUnderlineOnHover: true,
    color: theme.colors.text.default,
  };

  return (
    <Fragment>
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Pane maxHeight="50vh" overflowY="auto">
            <Menu>
              <Menu.Item icon={FolderCloseIcon}>
                <DownloadLink
                  url={draw.export.url}
                  filename={`${draw.name}_documents.zip`}
                  label="Download Draw Documents (.zip)"
                  {...menuItemProps}
                />
              </Menu.Item>
              <Menu.Item icon={DocumentIcon}>
                <DownloadLink
                  url={`${baseUrl}/download_draw_package/draw/${draw.id}?access_token=${accessToken}`}
                  filename={`${draw.name}.pdf`}
                  label="Download Draw Package (.pdf)"
                  onSuccess={() => {
                    analytics.track(
                      "Draw Package Download - via Actions Menu",
                      {
                        organizationId: project.organization.id,
                        projectId: project.id,
                        drawId: draw.id,
                      }
                    );
                  }}
                  {...menuItemProps}
                />
              </Menu.Item>
              <Menu.Item icon={PanelTableIcon}>
                <DownloadLink
                  url={`${draw.summary.url}?access_token=${accessToken}`}
                  filename={`${draw.name}_draw_summary.xlsx`}
                  label="Download Draw Summary (.xlsx)"
                  {...menuItemProps}
                />
              </Menu.Item>
              {canGenerateCustomReports &&
                project.organization.reportTemplates.map(({ name, id }) => (
                  <Menu.Item key="id" icon={DocumentIcon}>
                    <DownloadLink
                      disabled={disableReportDownload}
                      filename={`${name} - ${project.name} -  ${draw.name}.docx`}
                      label={`${name} (.docx)`}
                      purpose="download draw-report"
                      url={generateReportUrl(draw.id, id)}
                      {...menuItemProps}
                    />
                  </Menu.Item>
                ))}
            </Menu>
          </Pane>
        }
      >
        <Button
          appearance="minimal"
          color="default"
          iconAfter={DownloadIcon}
          marginLeft={majorScale(1)}
        >
          Download
        </Button>
      </Popover>
      {showProjectionGraphs && (
        <Fragment>
          <Pane
            position="absolute"
            width="100vw"
            height="500px"
            transform="translateX(200%)"
          >
            <LineItemProjectionChart
              chartId={BUDGET_CHART_ID}
              setChartDownloadImage={setBudgetChartImage}
              projection={projection}
            />
          </Pane>
          <Pane
            position="absolute"
            width="100vw"
            height="500px"
            transform="translateX(300%)"
          >
            <FundingSourceProjectionChart
              chartId={FUNDING_SOURCE_CHART_ID}
              setChartDownloadImage={setFundingSourceChartImage}
              projection={projection}
            />
          </Pane>
        </Fragment>
      )}
    </Fragment>
  );
}

async function getFileTokenForChart(base64, callback) {
  const file = await dataUrlToFile(base64, "projection.jpg");
  uploadFile(file).then(({ data }) => {
    callback(data.storage_path);
  });
}

import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Responsive, WidthProvider } from "react-grid-layout";
import { get, has } from "lodash";
import { Loadable, Pane } from "components/materials";
import { EditTableViews } from "components/containers";
import { fromBase64, toBase64 } from "components/materials/FastDataTable";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { majorScale, ThemeContext } from "helpers/utilities";
import { useFilteredProjects, UserContext } from "helpers/behaviors";
import { dateFormToServer } from "helpers/dateHelpers";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import {
  PORTFOLIO_INSIGHTS_QUERY,
  SAVE_PORTFOLIO_INSIGHTS_CONFIGURATIONS,
} from "../graphql";
import { filterFields, withProjectTypes } from "../helpers";

import {
  ContingencyRisk,
  CONTINGENCY_RISK_CONFIGURATION_SETTINGS,
} from "../ContingencyRisk";
import {
  DrawProcessingTime,
  DRAW_PROCESSING_TIME_CONFIGURATION_SETTINGS,
} from "../DrawProcessingTime";
import {
  OutstandingDraws,
  OUTSTANDING_DRAWS_CONFIGURATION_SETTINGS,
} from "../OutstandingDraws";
import {
  FundedDraws,
  FUNDED_DRAWS_CONFIGURATION_SETTINGS,
} from "../FundedDraws";
import {
  PortfolioComposition,
  PORTFOLIO_COMPOSITION_CONFIGURATION_SETTINGS,
} from "../PortfolioComposition";
import {
  ProjectTaskSummary,
  PROJECT_TASK_SUMMARY_CONFIGURATION_SETTINGS,
} from "../ProjectTaskSummary/ProjectTaskSummary";
import {
  ProjectCosts,
  PROJECT_COSTS_CONFIGURATION_SETTINGS,
} from "../ProjectCosts";
import {
  ProjectLocations,
  PROJECT_LOCATIONS_CONFIGURATION_SETTINGS,
} from "../ProjectLocations";
import {
  ProjectsByTeam,
  PROJECTS_BY_TEAM_CONFIGURATION_SETTINGS,
} from "../ProjectsByTeam";
import {
  ProjectsByVendor,
  PROJECTS_BY_VENDOR_CONFIGURATION_SETTINGS,
} from "../ProjectsByVendor";
import {
  ScheduleRisk,
  SCHEDULE_RISK_CONFIGURATION_SETTINGS,
} from "../ScheduleRisk";
import {
  UserActivity,
  USER_ACTIVITY_CONFIGURATION_SETTINGS,
} from "../UserActivity";
import { InsightControls } from "./InsightControls";

const ResponsiveGridLayout = WidthProvider(Responsive);

const PORTFOLIO_INSIGHTS_CARDS = {
  contingencyRisk: ContingencyRisk,
  drawProcessingTime: DrawProcessingTime,
  fundedDraws: FundedDraws,
  outstandingDraws: OutstandingDraws,
  portfolioComposition: PortfolioComposition,
  projectCosts: ProjectCosts,
  projectLocations: ProjectLocations,
  projectTaskSummary: ProjectTaskSummary,
  projectsByTeam: ProjectsByTeam,
  projectsByVendor: ProjectsByVendor,
  scheduleRisk: ScheduleRisk,
  userActivity: UserActivity,
};

// i - card name, x - horizontal position, y - vertical position, h - height, w - width, disabled - is card visible
const defaultCardConfiguration = [
  CONTINGENCY_RISK_CONFIGURATION_SETTINGS,
  DRAW_PROCESSING_TIME_CONFIGURATION_SETTINGS,
  FUNDED_DRAWS_CONFIGURATION_SETTINGS,
  OUTSTANDING_DRAWS_CONFIGURATION_SETTINGS,
  PORTFOLIO_COMPOSITION_CONFIGURATION_SETTINGS,
  PROJECT_COSTS_CONFIGURATION_SETTINGS,
  PROJECT_LOCATIONS_CONFIGURATION_SETTINGS,
  PROJECT_TASK_SUMMARY_CONFIGURATION_SETTINGS,
  PROJECTS_BY_TEAM_CONFIGURATION_SETTINGS,
  PROJECTS_BY_VENDOR_CONFIGURATION_SETTINGS,
  SCHEDULE_RISK_CONFIGURATION_SETTINGS,
  USER_ACTIVITY_CONFIGURATION_SETTINGS,
];

export const InsightCards = ({
  organization,
  allOrganizations,
  disabledOrganizations,
  history,
}) => {
  const { hasPermission, hasOrgLevelPermission } = useContext(UserContext);

  const isDeveloper = organization.type === ORGANIZATION_TYPE.BORROWER;

  const { data, loading } = useQuery(PORTFOLIO_INSIGHTS_QUERY, {
    variables: {
      organizationId: organization.id,
      currentDate: dateFormToServer(new Date()),
    },
  });

  const userId = get(data, "user.id");
  const projectTypes = get(data, "organization.projectTemplates", []);
  const projects = get(data, "organization.projects", []);
  const initialInsightsCards = get(
    data,
    "user.portfolioInsightsConfigurations",
    []
  );

  const getStartingFilter = () => {
    const serializedFilter = getSearchByKey(history, "filter");

    if (serializedFilter) {
      try {
        return fromBase64(serializedFilter).filterConfig;
      } catch (_error) {
        return {};
      }
    }
    return {};
  };

  const preparedProjects = useMemo(
    () => withProjectTypes(projects, projectTypes),
    [projects, projectTypes]
  );

  const [
    filterConfig,
    filteredProjects,
    filteredTeams,
    setFilterConfig,
    serializedFilterConfig,
  ] = useFilteredProjects(preparedProjects, filterFields, getStartingFilter());

  const theme = useContext(ThemeContext);

  const availableCards =
    initialInsightsCards.length === 0
      ? defaultCardConfiguration
      : initialInsightsCards;

  const [isConfigurable, setIsConfigurable] = useState(false);
  const [cards, setCards] = useState([]);
  const [previousConfig, setPreviousConfig] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const cardPermissions = {
        drawProcessingTime: !isDeveloper,
        outstandingDraws: !isDeveloper,
        // Not yet implemented for initial release
        projectLocations: false,
        projectsByTeam: hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT),
        projectTaskSummary: hasOrgLevelPermission(
          PERMISSION_ACTION.TASK_MANAGEMENT,
          organization
        ),
      };

      const cardsWithPermissions = availableCards.filter((card) =>
        has(cardPermissions, card.i) ? cardPermissions[card.i] : true
      );

      setCards(cardsWithPermissions);
      setPreviousConfig(cardsWithPermissions);
    }
  }, [
    availableCards,
    data,
    hasOrgLevelPermission,
    hasPermission,
    isDeveloper,
    loading,
    organization,
  ]);

  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1200) {
      setCards(previousConfig);
    }
  }, [previousConfig]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    mergeSearch(history, {
      filter: toBase64({ filterConfig }),
    });
  }, [filterConfig, history]);

  const [
    savePortfolioInsightsConfigurations,
    { loading: savePortfolioInsightsConfigurationsLoading },
  ] = useMutation(SAVE_PORTFOLIO_INSIGHTS_CONFIGURATIONS);

  const handleLayoutChange = (cards) => {
    const updatedLayout = cards.map((card) => {
      const { i, x, y, h, w } = card;
      const isDisabled = cards.filter((card) => card.i === i)[0].disabled;

      return {
        i,
        x,
        y,
        h,
        w,
        disabled: isDisabled,
      };
    });

    setCards(updatedLayout);
  };

  const handleSave = () => {
    return savePortfolioInsightsConfigurations({
      variables: {
        userId,
        organizationId: organization.id,
        portfolioInsightsConfigurations: cards,
      },
    });
  };

  const onCardConfigurationSave = () => {
    handleSave();
    setIsConfigurable(false);
    setPreviousConfig(cards);
  };

  const onCardConfigurationCancel = () => {
    setIsConfigurable(false);
    setCards(previousConfig);
  };

  if (loading || savePortfolioInsightsConfigurationsLoading)
    return <Loadable loading />;

  return (
    <EditTableViews
      canManagePublicViews={hasPermission(
        PERMISSION_ACTION.SAVE_TABLE_VIEWS,
        organization
      )}
      config={getSearchByKey(history, "filter")}
      organizationIdToScopeViews={organization.id}
      defaultViews={[]}
      tableName="PortfolioInsightsFilters"
    >
      {(editTableProps) => {
        return (
          <Fragment>
            <InsightControls
              filterConfig={filterConfig}
              serializedFilterConfig={serializedFilterConfig}
              setFilterConfig={setFilterConfig}
              filteredProjects={filteredProjects}
              organization={organization}
              allOrganizations={allOrganizations}
              disabledOrganizations={disabledOrganizations}
              isConfigurable={isConfigurable}
              setIsConfigurable={setIsConfigurable}
              setCards={setCards}
              onSave={onCardConfigurationSave}
              onCancel={onCardConfigurationCancel}
              {...editTableProps}
            />
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: cards }}
              breakpoints={{
                lg: 1500,
                md: 1200,
                sm: 1000,
                xs: 480,
                xxs: 400,
              }}
              cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
              rowHeight={400}
              compactType="vertical"
              margin={[32, 32]}
              isResizable={false}
              isDraggable={isConfigurable}
              onLayoutChange={handleLayoutChange}
            >
              {cards.map((card) => {
                const PortfolioInsightsCard = PORTFOLIO_INSIGHTS_CARDS[card.i];

                if (!PortfolioInsightsCard) return null;

                if (card.disabled && !isConfigurable) {
                  return (
                    <Pane
                      backgroundColor={theme.colors.backgroundGray}
                      key={card.i}
                      height={400}
                      marginY={4}
                      marginX="auto"
                      width={600}
                      minWidth={600}
                      borderRadius={majorScale(2)}
                      isDraggable={false}
                    />
                  );
                }
                return (
                  <Pane
                    backgroundColor={
                      card.disabled ? theme.colors.gray75 : "white"
                    }
                    key={card.i}
                    height={400}
                    marginY={4}
                    marginX="auto"
                    width={600}
                    minWidth={600}
                    borderRadius={majorScale(2)}
                  >
                    <PortfolioInsightsCard
                      cards={cards}
                      history={history}
                      isConfigurable={isConfigurable}
                      isDisabled={card.disabled}
                      name={card.i}
                      organization={organization}
                      projects={filteredProjects}
                      setCards={setCards}
                      teams={filteredTeams}
                    />
                  </Pane>
                );
              })}
            </ResponsiveGridLayout>
          </Fragment>
        );
      }}
    </EditTableViews>
  );
};

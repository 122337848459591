import { useState, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import { OrganizationSelector, JobCostCodesTable } from "components/templates";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { get } from "lodash";
import { JobCostCodeModal } from "./JobCostCodeModal";
import { ScopeOrganizations } from "./ScopeOrganizations";

const QUERY = gql`
  query ReportsJobCostCodesPage($organizationId: String!) {
    organization(id: $organizationId) {
      id
      jobCostCodes {
        id
        code
        description
        glCode
      }
    }
  }
`;

function ReportsJobCostCodesPageInner({ history, selectedOrganization }) {
  const [editCostCode, setEditCostCode] = useState(null);
  const { data, loading } = useQuery(QUERY, {
    variables: { organizationId: selectedOrganization.id },
  });

  if (loading) return <Loadable loading />;

  const organizationCostCodes = get(data, "organization.jobCostCodes", []);

  return (
    <Fragment>
      <JobCostCodesTable
        jobCostCodes={organizationCostCodes}
        history={history}
        selectedOrganization={selectedOrganization}
        setEditCostCode={setEditCostCode}
        organization={data.organization}
      />
      {editCostCode !== null && (
        <JobCostCodeModal
          costCode={editCostCode}
          onClose={() => setEditCostCode(null)}
          onMutationCompleted={() => setEditCostCode(null)}
          organizationCostCodes={organizationCostCodes}
          organizationId={selectedOrganization.id}
        />
      )}
    </Fragment>
  );
}

export function ReportsJobCostCodesPage({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing Chart of Accounts for"
            />
          </Pane>
          <ReportsJobCostCodesPageInner
            history={history}
            selectedOrganization={selectedOrganization}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

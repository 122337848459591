import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  FileView,
  UploadReview,
  UploadsViewerSidebar,
} from "components/templates";
import {
  Confirm,
  Pane,
  Paragraph,
  Text,
  FileViewer,
} from "components/materials";
import { blankClipboards } from "images";
import {
  find,
  findIndex,
  flatMap,
  get,
  groupBy,
  isEqual,
  sortBy,
} from "lodash";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";
import { UPLOADS_VIEWER_FRAGMENT } from "helpers/fragments";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import isBlank from "helpers/isBlank";
import { v4 as uuid } from "uuid";

const DELETE_UPLOAD = gql`
  mutation DeleteUpload($uploadId: String!, $alsoDeleteAgreements: Boolean) {
    deleteUpload(
      uploadId: $uploadId
      alsoDeleteAgreements: $alsoDeleteAgreements
    ) {
      status
    }
  }
`;

const SET_UPLOAD_DOCUMENTS = gql`
  mutation SetUploadDocuments(
    $uploadId: String!
    $documentUpdates: [UploadDocumentUpdateInput]!
  ) {
    reviewUploadDocuments(
      uploadId: $uploadId
      documentUpdates: $documentUpdates
    ) {
      ...UploadsViewerFragment
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

const PARSE_UPLOAD_DOCUMENTS = gql`
  mutation ParseUploadDocuments($uploadId: String!) {
    parseUploadDocuments(uploadId: $uploadId) {
      ...UploadsViewerFragment
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

const SPLIT_AND_CLASSIFY_UPLOAD_DOCUMENTS = gql`
  mutation SplitAndClassifyUploadDocuments($uploadId: String!) {
    splitAndClassifyUploadDocuments(uploadId: $uploadId) {
      ...UploadsViewerFragment
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

const USER_PARSE_DOCUMENT_INFORMATION = gql`
  mutation UserParseDocumentInformation(
    $userId: String!
    $parseDocumentInformation: Boolean!
  ) {
    updateUserParseDocumentInformation(
      userId: $userId
      parseDocumentInformation: $parseDocumentInformation
    ) {
      id
      parseDocumentInformation
    }
  }
`;

const overflowProps = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const prepareUploads = (data, context) => {
  if (context === "draw") {
    const uploads = get(data, "project.draw.uploads", []);
    return [[{ uploads }], uploads];
  }
  if (context === "project") {
    const uploads = get(data, "project.uploads", []);
    const draws = get(data, "project.draws", []);
    const groupedUploads = groupBy(uploads, ({ draw }) =>
      get(draw, "id", "Project")
    );
    const orderedGroups = draws
      .map(({ id, name }) => ({ name, uploads: get(groupedUploads, id, []) }))
      .concat({ name: "Project", uploads: get(groupedUploads, "Project", []) });
    const orderedUploads = flatMap(orderedGroups, ({ uploads }) => uploads);
    return [orderedGroups, orderedUploads];
  }
  return [[], []];
};

function UploadsBlankSlate({ context, theme }) {
  return (
    <Pane
      display="flex"
      justifyContent="center"
      paddingTop={majorScale(6)}
      alignItems="center"
    >
      <Pane alt="" height={124} is="img" src={blankClipboards} />
      <Pane marginLeft={majorScale(4)}>
        <Paragraph fontWeight={theme.fontWeights.DEMI} marginY={majorScale(1)}>
          {t("uploadsViewer.noUploadsTitle", { context })}
        </Paragraph>
        <Paragraph>{t("uploadsViewer.noUploadsText")}</Paragraph>
      </Pane>
    </Pane>
  );
}

function UploadsHeader({ data, theme }) {
  const { project } = data;
  const { draw } = project;

  return (
    <Fragment>
      <Text
        fontSize={16}
        fontWeight={theme.fontWeights.MEDIUM}
        {...overflowProps}
      >
        {project.name}
        {draw && ` ${draw.name}`}
        {` Uploads`}
      </Text>
    </Fragment>
  );
}

function UploadFileViewer({ selectedUpload, projectId }) {
  if (!selectedUpload || !selectedUpload.id)
    return <Pane>Could not load file</Pane>;

  return <FileViewer file={selectedUpload.file} />;
}

export function UploadsViewer({
  context,
  data,
  frozenError,
  frozenSubmit,
  getProjectVendorSearchQuery,
  hasCloseAnimation,
  newlyAddedVendors,
  onClose,
  projectId,
  refetchQueries,
  searchedVendors,
  selectedId,
  setNewlyAddedVendors,
}) {
  const [uploadGroups, orderedUploads] = prepareUploads(data, context);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tempSelectedId, setTempSelectedId] = useState(selectedId);
  const [viewerDirty, setViewerDirty] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [reparseOnSubmit, setReparseOnSubmit] = useState(false);
  const [uploadKey, setUploadKey] = useState(uuid());
  const [selectedUpload, setSelectedUpload] = useState(
    orderedUploads[selectedIndex] || {}
  );

  const theme = useContext(ThemeContext);
  const { userProfiles } = useContext(UserContext);
  const user = find(userProfiles, (userProfile) => {
    return (
      get(userProfile, "organization.id") ===
      get(data, "project.organization.id")
    );
  });

  useEffect(() => {
    const prev = sortBy(get(selectedUpload, "documents", []), "pages.0");
    const next = sortBy(
      get(orderedUploads, `${selectedIndex}.documents`, []),
      "pages.0"
    );
    if (!isEqual(prev, next)) {
      setSelectedUpload(orderedUploads[selectedIndex] || {});
      setUploadKey(uuid());
    }
  }, [orderedUploads, selectedIndex, selectedUpload]);

  const updateSelectedUploadById = useCallback(
    (newSelectedId) => {
      const newSelectedIndex = findIndex(
        orderedUploads,
        (upload) => upload.id === newSelectedId
      );
      setSelectedIndex(newSelectedIndex);
    },
    [orderedUploads, setSelectedIndex]
  );

  // "tempSelectedId" is used as a temporary holding pen for an upload that should be loaded in the viewer at the next opportunity
  // Case 1 - for holding on to an upload that was just clicked in the cards list, when the form is dirty
  // Case 2 - for loading the upload when launched from the Documents Viewer "see original upload" flow
  // In thoese cases, the effect will see the "tempSelectedId" and update the "selectedIndex" appropriately
  useEffect(() => {
    if (
      tempSelectedId &&
      orderedUploads.length > 0 &&
      orderedUploads[selectedIndex].id !== tempSelectedId &&
      confirmAction === null
    ) {
      updateSelectedUploadById(tempSelectedId);
      setTempSelectedId(null);
    }
  }, [
    confirmAction,
    orderedUploads,
    selectedIndex,
    setTempSelectedId,
    tempSelectedId,
    updateSelectedUploadById,
  ]);

  const [deleteUpload, { loading: deleteLoading }] = useMutation(
    DELETE_UPLOAD,
    {
      refetchQueries,
      onCompleted: () => setSelectedIndex(0),
      onError: (error) => frozenError(error),
    }
  );

  const [
    setUploadDocuments,
    { loading: setUploadDocumentsLoading },
  ] = useMutation(SET_UPLOAD_DOCUMENTS, {
    onError: (error) => frozenError(error),
    onCompleted: (data) => {
      setViewerDirty(false);
      if (reparseOnSubmit) {
        setReparseOnSubmit(false);
        parseUploadDocuments({
          variables: { uploadId: selectedUpload.id },
        });
      }
    },
  });

  const [
    parseUploadDocuments,
    { loading: parseUploadDocumentsLoading },
  ] = useMutation(PARSE_UPLOAD_DOCUMENTS, {
    onError: (error) => frozenError(error),
    onCompleted: () => {
      // Set the upload key so the form gets re-rendered
      setUploadKey(uuid());
    },
  });

  const [
    userParseDocumentInformation,
    { loading: userParseDocumentInformationLoading },
  ] = useMutation(USER_PARSE_DOCUMENT_INFORMATION);

  const [
    splitAndClassifyUploadDocuments,
    { loading: splitAndClassifyUploadDocumentsLoading },
  ] = useMutation(SPLIT_AND_CLASSIFY_UPLOAD_DOCUMENTS, {
    onError: (error) => frozenError(error),
  });

  const closeViewer = () => {
    if (hasCloseAnimation) {
      setViewerOpen(false);
      setTimeout(onClose, 350);
    } else {
      onClose();
    }
  };

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.listen(closeViewer), []);

  const viewerActions = {
    close: closeViewer,
    next: () => setSelectedIndex(selectedIndex + 1),
    previous: () => setSelectedIndex(selectedIndex - 1),
    select: () => {
      updateSelectedUploadById(tempSelectedId);
      setTempSelectedId(null);
    },
  };

  const handleClose = () => {
    if (viewerDirty) {
      setConfirmAction("close");
    } else {
      viewerActions.close();
    }
  };

  const handleNext = () => {
    if (viewerDirty) {
      setConfirmAction("next");
    } else {
      viewerActions.next();
    }
  };

  const handlePrevious = () => {
    if (viewerDirty) {
      setConfirmAction("previous");
    } else {
      viewerActions.previous();
    }
  };

  const handleSelect = (id) => {
    if (viewerDirty) {
      setConfirmAction("select");
      setTempSelectedId(id);
    } else {
      updateSelectedUploadById(id);
    }
  };

  const handleConfirm = (close) => {
    viewerActions[confirmAction]();
    close();
  };

  const previousDisabled = isBlank(orderedUploads) || selectedIndex === 0;

  const nextDisabled =
    isBlank(orderedUploads) || selectedIndex === orderedUploads.length - 1;

  return (
    <Fragment>
      <NavigationWarnings dirty={viewerDirty} />
      <Confirm
        content={t("confirmNavigation.warning")}
        header="Warning"
        hideViewer
        cancelLabel="Cancel"
        confirmLabel="Continue without saving"
        onConfirm={handleConfirm}
        open={confirmAction}
        onCloseComplete={() => {
          setTempSelectedId(null);
          setConfirmAction(null);
        }}
      />
      <FileView
        blankSlate={
          orderedUploads.length === 0 ? (
            <UploadsBlankSlate context={context} theme={theme} />
          ) : null
        }
        header={<UploadsHeader data={data} theme={theme} />}
        nextDisabled={nextDisabled}
        onClose={() => {
          handleClose();
          if (!viewerDirty) history.goBack();
        }}
        onNext={handleNext}
        onPrevious={handlePrevious}
        previousDisabled={previousDisabled}
        setViewerOpen={setViewerOpen}
        fileViewer={
          <UploadFileViewer
            projectId={projectId}
            selectedUpload={selectedUpload}
          />
        }
        sidebar={
          <UploadsViewerSidebar
            context={context}
            onSelect={handleSelect}
            selectedUploadId={selectedUpload.id}
            theme={theme}
            uploadGroups={uploadGroups}
          />
        }
        viewerOpen={viewerOpen}
      >
        {(_setCardsOpen) => {
          return (
            !isEqual(selectedUpload, {}) && (
              <UploadReview
                drawId={get(data, "project.draw.id")}
                getProjectVendorSearchQuery={getProjectVendorSearchQuery}
                initialParseDocumentInformation={get(
                  user,
                  "parseDocumentInformation",
                  false
                )}
                key={`${selectedUpload.id}::${uploadKey}`}
                mutationLoading={
                  deleteLoading ||
                  setUploadDocumentsLoading ||
                  parseUploadDocumentsLoading ||
                  splitAndClassifyUploadDocumentsLoading
                }
                newlyAddedVendors={newlyAddedVendors}
                projectId={projectId}
                setReparseOnSubmit={setReparseOnSubmit}
                setViewerDirty={setViewerDirty}
                setNewlyAddedVendors={setNewlyAddedVendors}
                searchedVendors={searchedVendors}
                upload={selectedUpload}
                uploadMutations={{
                  deleteUpload: frozenSubmit(deleteUpload),
                  setUploadDocuments: frozenSubmit(setUploadDocuments),
                  splitAndClassifyUploadDocuments,
                  parseUploadDocuments: frozenSubmit(parseUploadDocuments),
                  userParseDocumentInformation,
                }}
                userId={get(user, "id")}
                userParseDocumentInformationLoading={
                  userParseDocumentInformationLoading
                }
                vendors={get(data, "project.vendors", [])}
                viewerDirty={viewerDirty}
              />
            )
          );
        }}
      </FileView>
    </Fragment>
  );
}

UploadsViewer.propTypes = {
  context: PropTypes.oneOf(["draw", "project"]).isRequired,
  data: PropTypes.object.isRequired,
  frozenError: PropTypes.func.isRequired,
  frozenSubmit: PropTypes.func.isRequired,
  hasCloseAnimation: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
};

UploadsHeader.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.shape({
      name: PropTypes.string.isRequired,
      draw: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

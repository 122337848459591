import { Fragment, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import { OrganizationSelector, TasksTable } from "components/templates";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { omit } from "lodash";
import { getSearchByKey, removeKey } from "helpers/queryStringHelpers";
import t from "helpers/translate";
import {
  getSerializedURLForTasksReport,
  TASK_HISTORY_START_DATE,
} from "components/templates/PortfolioInsights/helpers";
import { ScopeOrganizations } from "./ScopeOrganizations";

const QUERY = gql`
  query ReportsTasksPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
      }
      teams {
        id
        name
      }
      projects {
        id
        name
        status
        productType {
          id
          type
        }
        projectRegion {
          id
          region
        }
        team {
          id
          name
        }
        template {
          id
          name
        }
      }
      tasks {
        id
        eventName
        status
        originalStartDate
        originalDuration
        originalCompletionDate
        projectedStartDate
        projectedCompletionDate
        actualStartDate
        actualCompletionDate
        actualDuration
        startDateVariance
        completionDateVariance
        ownerName
        projectId
      }
    }
  }
`;

function ReportsTasksPageInner({
  history,
  onOrganizationSelected,
  selectedOrganization,
}) {
  const referrerOrgId = getSearchByKey(history, "referrerSelectedOrgId");

  const multiOrgNeedToChangeOrg =
    referrerOrgId && referrerOrgId !== selectedOrganization.id;

  const { data, loading } = useQuery(QUERY, {
    skip: multiOrgNeedToChangeOrg,
    variables: { organizationId: selectedOrganization.id },
  });

  if (multiOrgNeedToChangeOrg) {
    removeKey(history, "referrerSelectedOrgId");
    onOrganizationSelected({ id: referrerOrgId });
    return <Loadable loading />;
  }

  if (loading || data === undefined) return <Loadable loading />;

  const tasks = prepareTasksWithProjectData(data.organization);

  return (
    <TasksTable
      tasks={tasks}
      orgData={omit(data.organization, ["projects", "tasks"])}
      history={history}
      selectedOrganization={selectedOrganization}
      isReport
      pinnedFilters={[
        "projectStatus",
        "projectType",
        "productType",
        "projectRegion",
        "team",
      ]}
    />
  );
}

export function ReportsTasksPage({ history }) {
  const finishDate = getSearchByKey(history, "finishDate");

  useEffect(() => {
    if (finishDate) {
      const showCompleted = false;
      const referrerSelectedOrgId = getSearchByKey(
        history,
        "referrerSelectedOrgId"
      );
      history.push(
        getSerializedURLForTasksReport(
          TASK_HISTORY_START_DATE,
          finishDate,
          showCompleted,
          referrerSelectedOrgId
        )
      );
    }
  }, [finishDate, history]);

  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing tasks for"
            />
          </Pane>
          <ReportsTasksPageInner
            history={history}
            onOrganizationSelected={onOrganizationSelected}
            selectedOrganization={selectedOrganization}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

function prepareTasksWithProjectData(organization) {
  const { projects, tasks } = organization;

  const projectsLibrary = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: project,
    }),
    {}
  );

  return tasks.map((task) => {
    const project = projectsLibrary[task.projectId];
    return {
      ...task,
      productType: project.productType?.type,
      projectName: project.name,
      projectRegion: project.projectRegion?.region,
      projectStatus: t(`projectStatus.${project.status}`),
      projectType: project.template.name,
      team: project.team?.name,
    };
  });
}

/**
 * This page is the confirmation form reached after uploading a budget on an existing project
 *
 * shares a lot of logic with OnboardingWizard -> EnterBudgetStep via
 * src/helpers/budgetFormHelpers.js
 *
 */
import { useContext, Fragment } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Formik } from "formik";
import { Button, Loadable, Pane, Text } from "components/materials";
import { EditableBudgetTable } from "components/templates";
import { majorScale, ThemeContext } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { getMutationData, validate } from "helpers/budgetFormHelpers";
import { FINALIZE_BUDGET, UPDATE_ONBOARDING_DATA } from "./graphql";

const ONBOARDING_DATA_QUERY = gql`
  query OnboardingWizardLayoutQuery($projectId: String!) {
    project(id: $projectId) {
      id
      onboardingData
    }
  }
`;

export function ConfirmPage({ history, match }) {
  const { projectId } = match.params;
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);

  const { data, loading } = useQuery(ONBOARDING_DATA_QUERY, {
    variables: { projectId },
  });

  const [update] = useMutation(UPDATE_ONBOARDING_DATA);

  const updateOnboardingData = (data) => {
    return update({
      variables: {
        projectId,
        data: JSON.stringify(data),
      },
    });
  };

  const [finalizeProject] = useMutation(FINALIZE_BUDGET, {
    onCompleted: () => history.push(`/projects/${projectId}`),
  });

  const handleFinalizeBudget = (budget) => {
    const mutationData = getMutationData(budget);
    updateOnboardingData(mutationData).then((res) => {
      finalizeProject({
        variables: {
          projectId,
          budget: mutationData.budget.divisions,
        },
      });
    });
  };

  const goBack = () => history.push(`/projects/${projectId}/budget_upload`);

  const getTableWidth = () => {
    let baseWidth = 800;

    if (hasPermission(PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING)) {
      baseWidth += 220;
    }

    if (hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS)) {
      baseWidth += 200;
    }

    return baseWidth;
  };

  if (loading) return <Loadable />;

  const initialValuesFromData = data?.project?.onboardingData?.budget ?? {
    divisions: [],
  };

  return (
    <Pane>
      <LayoutHeader theme={theme} history={history} />
      <Pane display="flex" justifyContent="center" marginTop={majorScale(7)}>
        <Pane width={getTableWidth()}>
          <Formik
            initialValues={initialValuesFromData}
            onSubmit={handleFinalizeBudget}
            validate={validate}
            validateOnMount
          >
            {(propsFormik) => (
              <Fragment>
                <EditableBudgetTable propsFormik={propsFormik} />

                <Pane
                  display="flex"
                  justifyContent="space-between"
                  marginBottom={majorScale(4)}
                  marginTop={majorScale(2)}
                >
                  <Button onClick={goBack}>Back</Button>
                  <Button
                    appearance="primary"
                    isLoading={false}
                    onClick={propsFormik.handleSubmit}
                  >
                    Finish
                  </Button>
                </Pane>
              </Fragment>
            )}
          </Formik>
        </Pane>
      </Pane>
    </Pane>
  );
}

function LayoutHeader({ theme, history }) {
  const cancel = () => history.go(-2);

  return (
    <Pane
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      elevation={1}
      paddingX={majorScale(3)}
      paddingY={majorScale(1)}
    >
      <Pane display="flex" flex={1}>
        <Text fontWeight={theme.fontWeights.MEDIUM} fontSize={16}>
          Upload Project Budget
        </Text>
      </Pane>
      <Button appearance="default" content="Cancel" onClick={cancel} />
    </Pane>
  );
}

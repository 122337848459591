import { useContext } from "react";
import { Pane, Shortener, Link } from "components/materials";
import { hover } from "glamor";
import { ArrowRightIcon } from "evergreen-ui";
import { ThemeContext } from "helpers/utilities";

// onClick can be used for a side effect, i.e. tracking
export function LinkCell({
  characterLimit = 32,
  name,
  onClick = () => {},
  url,
}) {
  const theme = useContext(ThemeContext);

  return (
    <Pane is={Link} onClick={onClick} to={url}>
      <Pane
        alignItems="center"
        cursor="pointer"
        display="flex"
        justifyContent="space-between"
      >
        <Pane alignItems="center" display="flex">
          <Shortener
            className={hover({
              textDecoration: "underline",
            })}
            color={theme.colors.linkBlue}
            fontSize="14px"
            limit={characterLimit}
            size={400}
            text={name}
          />
        </Pane>
        <ArrowRightIcon color={theme.colors.linkBlue} />
      </Pane>
    </Pane>
  );
}

import {
  Confirm,
  ListItem,
  Pane,
  Paragraph,
  Text,
  UnorderedList,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

export function MriErrorModal({ mriErrors, onCloseComplete }) {
  const modalContent = (
    <Pane>
      <Paragraph>
        {t("paymentIntegration.errorsPostingDocuments", {
          count: mriErrors.length,
        })}
      </Paragraph>
      {mriErrors.map(({ documentName, errors }) => (
        <UnorderedList>
          <Paragraph fontWeight={500} marginTop={majorScale(1)}>
            {documentName}
          </Paragraph>
          {errors.map((message) => (
            <ListItem>
              <Text>{message}</Text>
            </ListItem>
          ))}
        </UnorderedList>
      ))}
    </Pane>
  );

  return (
    <Confirm
      content={modalContent}
      hasCancel={false}
      header="Post Payment - Error"
      onConfirm={onCloseComplete}
      open
      confirmLabel="OK"
    />
  );
}

import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { DocumentIcon, MoreIcon } from "evergreen-ui";
import { ApproveDocumentButton } from "components/containers";
import { AssignToDropdown } from "components/templates";
import { Button, Pane, Text } from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { get } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import {
  getDocumentApprovalStatus,
  userCanApproveDocument,
} from "helpers/documentHelpers";
import { UserContext } from "helpers/behaviors";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { REASSIGN_DOCUMENT } from "./graphql";
import { PROJECT_DASHBOARD_QUERY } from "../../graphql";

const getDocumentURL = (document) =>
  `/projects/${document.project.id}/assigned_documents/${document.id}`;

export function DocumentApproval({
  document,
  onApproveCompleted,
  currentUserId,
}) {
  const theme = React.useContext(ThemeContext);
  const history = useHistory();
  const { hasPermission, userProfiles } = React.useContext(UserContext);

  const documentReviewers = get(document, "project.documentReviewers", []);
  const organization = get(document, "project.organization", []);
  const projectUsers = get(document, "project.users", []);
  const user = userProfiles.find(
    (profile) => profile.organization.id === organization.id
  );
  const suggestedDocumentAssignees = get(
    document,
    "project.suggestedDocumentAssignees",
    []
  );

  const [reassign, reassignResult] = useMutation(REASSIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: PROJECT_DASHBOARD_QUERY,
        variables: { projectId: document.project.id, userId: currentUserId },
      },
    ],
  });

  const handleReassign = (userId) => {
    reassign({ variables: { documentIds: [document.id], userId } });
  };

  const canReassign = hasPermission(
    PERMISSION_ACTION.ASSIGN_DOCUMENTS,
    organization
  );

  const canViewDocuments = hasPermission(
    PERMISSION_ACTION.DOWNLOAD_DOCUMENT,
    organization
  );

  const hasDocumentReviewers = hasPermission(
    PERMISSION_ACTION.APPROVE_DOCUMENTS,
    organization
  );

  const userCanApprove = userCanApproveDocument(
    document,
    user,
    documentReviewers,
    hasPermission
  );

  const { documentHasApprovedStatusForUser } = getDocumentApprovalStatus(
    document,
    user,
    documentReviewers
  );

  const showApproveButton =
    hasDocumentReviewers && userCanApprove && !documentHasApprovedStatusForUser;

  return (
    <Pane
      cursor={canViewDocuments ? "pointer" : null}
      backgroundColor="#F9F9FA"
      onClick={() =>
        canViewDocuments ? history.push(getDocumentURL(document)) : null
      }
      paddingY={majorScale(1)}
      paddingX={minorScale(3)}
      marginBottom={majorScale(1)}
    >
      <Pane display="flex" alignItems="center">
        <Pane height={16} width={16} marginRight={majorScale(1)}>
          <DocumentIcon color="muted" />
        </Pane>
        <Text
          flex="1 1 auto"
          fontWeight={600}
          size={300}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="60%"
          marginRight={majorScale(2)}
        >
          {document.originalFile.name}
        </Text>
        <Pane marginLeft="auto" display="flex">
          {canReassign && (
            <Pane onClick={preventEventBubbling}>
              <AssignToDropdown
                users={projectUsers}
                suggestedDocumentAssignees={suggestedDocumentAssignees}
                onSelect={(userId) => handleReassign(userId)}
                selectedUserId={document?.assignedUser?.id}
              >
                <Button
                  appearance="minimal"
                  height={majorScale(2)}
                  isLoading={reassignResult.loading}
                  marginLeft={minorScale(1)}
                  purpose="dashboard document reassign"
                >
                  {!reassignResult.loading && <MoreIcon color="disabled" />}
                </Button>
              </AssignToDropdown>
            </Pane>
          )}
          {showApproveButton && (
            <ApproveDocumentButton
              buttonStyles={{
                appearance: "minimal",
                background: theme.colors.buttonBlue,
                color: "white",
                height: "auto",
                paddingY: minorScale(1),
                paddingX: minorScale(3),
                id: "dashApprove",
              }}
              refetchQueries={[
                {
                  query: PROJECT_DASHBOARD_QUERY,
                  variables: {
                    projectId: document.project.id,
                    userId: currentUserId,
                  },
                },
              ]}
              hoverColor={theme.colors.buttonBlue}
              document={document}
              onApproveCompleted={onApproveCompleted}
            >
              <Text color="white" size={300} fontWeight={500}>
                Approve
              </Text>
            </ApproveDocumentButton>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}

DocumentApproval.propTypes = {
  approvedDocuments: PropTypes.array.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number,
    approvedBy: PropTypes.shape({
      id: PropTypes.string,
    }),
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    type: PropTypes.string.isRequired,
    vendor: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
  onApproveCompleted: PropTypes.func.isRequired,
};

import { useState, useContext } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { find } from "lodash";
import { ContentLayout, OrganizationSelector } from "components/templates";
import { Divider, Form, Loadable } from "components/materials";
import { UserContext } from "helpers/behaviors";
import getOptions from "helpers/getOptions";
import isBlank from "helpers/isBlank";
import { PERMISSION_ACTION } from "helpers/enums";
import { CreateProjectModal } from "../CreateProjectModal";
import { ScopeOrganizations } from "../ScopeOrganizations";
import {
  DRAW_SUBMISSION_HEADER_FRAGMENT,
  ImportDrawSubmissionHeader,
} from "./ImportDrawSubmissionHeader";

const QUERY = gql`
  query ImportDrawSubmissionCreate($submissionId: String!) {
    organizations {
      id
      projects {
        id
        name
      }
    }
    submission(submissionId: $submissionId) {
      id
      sourceDraw {
        id
        name
        project {
          id
          name
        }
      }
      ...DrawSubmissionHeaderFragment
    }
  }
  ${DRAW_SUBMISSION_HEADER_FRAGMENT}
`;

const RECEIVE_DRAW = gql`
  mutation ImportDrawSubmissionCreate(
    $drawName: String
    $projectId: String!
    $submissionId: String!
  ) {
    receiveDraw(
      drawName: $drawName
      projectId: $projectId
      submissionId: $submissionId
    ) {
      id
      project {
        id
      }
    }
  }
`;

function validate(values) {
  const errors = {};
  if (isBlank(values.drawName)) errors.drawName = "Please enter a draw name";
  if (isBlank(values.projectId)) errors.projectId = "Please select a project";
  return errors;
}

function ImportDrawSubmissionCreateInner({
  match,
  onNext,
  disabledOrganizations,
  allOrganizations,
  selectedOrganization,
  onOrganizationSelected,
}) {
  const { submissionId } = match.params;

  const [open, setOpen] = useState(false);

  const { hasPermission } = useContext(UserContext);

  const { data, loading } = useQuery(QUERY, { variables: { submissionId } });

  const [receiveDraw, receiveDrawResult] = useMutation(RECEIVE_DRAW, {
    onCompleted: ({ receiveDraw }) => {
      window.location.assign(
        `/projects/${receiveDraw.project.id}/draws/${receiveDraw.id}`
      );
    },
  });

  if (loading) {
    return <Loadable loading />;
  }

  const organization = find(data.organizations, {
    id: selectedOrganization.id,
  });

  return (
    <Formik
      initialValues={{
        drawName: data.submission.sourceDraw.name,
        projectId: organization.projects.find(
          (project) => project.name === data.submission.sourceDraw.project.name
        )?.id,
      }}
      onSubmit={(values) => onNext(values)}
      validate={validate}
      validateOnMount
    >
      {(propsFormik) => (
        <ContentLayout fixedWidth={700}>
          <ImportDrawSubmissionHeader
            submission={data.submission}
            title="Import Draw"
          />

          <OrganizationSelector
            title="Show projects for"
            disabledOrganizations={disabledOrganizations}
            organizations={allOrganizations}
            selectedOrganization={selectedOrganization}
            onOrganizationSelected={onOrganizationSelected}
          />
          <Form.Generic
            handleSubmit={propsFormik.handleSubmit}
            isValid={propsFormik.isValid}
            loading={receiveDrawResult.loading}
            textSubmit="Next"
          >
            <Form.Select
              label="Project"
              name="projectId"
              onAddItem={
                hasPermission(PERMISSION_ACTION.CREATE_PROJECT) &&
                allOrganizations.length === 1
                  ? () => setOpen(true)
                  : null
              }
              options={getOptions(organization.projects)}
            />
            <Divider />
            <Form.Input label="Draw" name="drawName" />
          </Form.Generic>
          {open && (
            <CreateProjectModal
              defaultValues={{ name: data.submission.sourceDraw.project.name }}
              onClose={() => setOpen(false)}
              onCompleted={({ createProjectFromSubmission }) => {
                receiveDraw({
                  variables: {
                    projectId: createProjectFromSubmission.id,
                    submissionId,
                  },
                });
                setOpen(false);
              }}
              submissionId={submissionId}
            />
          )}
        </ContentLayout>
      )}
    </Formik>
  );
}

export function ImportDrawSubmissionCreate({ match, onNext }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.CREATE_DRAW}>
      {({
        onOrganizationSelected,
        allOrganizations,
        selectedOrganization,
        disabledOrganizations,
      }) => (
        <ImportDrawSubmissionCreateInner
          match={match}
          onNext={onNext}
          disabledOrganizations={disabledOrganizations}
          allOrganizations={allOrganizations}
          selectedOrganization={selectedOrganization}
          onOrganizationSelected={onOrganizationSelected}
        />
      )}
    </ScopeOrganizations>
  );
}

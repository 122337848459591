import React, { useContext } from "react";
import { UserContext } from "helpers/behaviors";
import { ThemeContext } from "evergreen-ui";
import { majorScale } from "helpers/utilities";
import { Pane, Paragraph } from "components/materials";
import {
  getBudgetRatio,
  getDocumentsRatio,
  getDocumentApprovalsRatio,
  getDrawApprovalsRatio,
  getDrawAssessmentsRatio,
  getFundingSourcesRatio,
  getInspectionRatio,
  getPaymentsRatio,
  getRulesRatio,
  getRatioColor,
  showDocumentApprovalStatus,
  showInspection,
  showPaymentsTab,
} from "../../DrawHeader/DrawStatus/statusFormulas";

function DrawRatioStatus({ name, numerator, denominator }) {
  const theme = React.useContext(ThemeContext);

  const statusColorMap = {
    red: theme.colors.red500,
    yellow: theme.colors.orange500,
    green: theme.colors.green600,
  };

  const color = statusColorMap[getRatioColor(numerator, denominator)];

  return (
    <Pane
      display="flex"
      alignItems="center"
      width="50%"
      paddingBottom={majorScale(1)}
    >
      <Pane width={10} height={10} borderRadius={10} backgroundColor={color} />
      <Paragraph size={300} paddingLeft={8}>
        {name}
      </Paragraph>
    </Pane>
  );
}

export function DrawRatios({ draw, project }) {
  const { hasOrgLevelPermission, hasPermission } = useContext(UserContext);

  const shouldShowDocumentApprovalStatus = showDocumentApprovalStatus(
    hasOrgLevelPermission,
    project
  );
  const shouldShowInspection = showInspection(hasPermission);
  const shouldShowPaymentsTab = showPaymentsTab(hasPermission);

  return (
    <React.Fragment>
      <DrawRatioStatus
        name="Documents"
        {...getDocumentsRatio({ draw, project })}
      />
      {shouldShowDocumentApprovalStatus && (
        <DrawRatioStatus
          name="Document Approvals"
          {...getDocumentApprovalsRatio({ draw, project })}
        />
      )}
      <DrawRatioStatus name="Budget" {...getBudgetRatio({ draw, project })} />
      <DrawRatioStatus name="Rules" {...getRulesRatio({ draw, project })} />
      <DrawRatioStatus
        name="Funding Sources"
        {...getFundingSourcesRatio({ draw, project })}
      />
      {shouldShowInspection && (
        <DrawRatioStatus
          name="Inspection"
          {...getInspectionRatio({ draw, project })}
        />
      )}
      {draw.questions.length > 0 && (
        <DrawRatioStatus
          name="Draw Assessment"
          {...getDrawAssessmentsRatio({ draw, project })}
        />
      )}
      <DrawRatioStatus
        name="Draw Approvals"
        {...getDrawApprovalsRatio({ draw, project })}
      />
      {shouldShowPaymentsTab && (
        <DrawRatioStatus
          name="Payment Posted"
          {...getPaymentsRatio({ draw, project })}
        />
      )}
    </React.Fragment>
  );
}

import { Fragment, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Accordion,
  Button,
  Modal,
  Pane,
  Switch,
  Table,
  Text,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { formatDateTime, isAtOrBefore } from "helpers/dateHelpers";
import { configurationPanelStyles } from "./helpers";

export const PANEL_KEY = "yardi";

const ORG_LEVEL_YARDI_INVOICE_SYNC = gql`
  mutation OrgLevelYardiInvoiceSync($organizationId: String!) {
    orgLevelYardiInvoiceSync(organizationId: $organizationId) {
      id
    }
  }
`;

const ORG_LEVEL_YARDI_VENDOR_SYNC = gql`
  mutation OrgLevelYardiVendorSync($organizationId: String!) {
    orgLevelYardiVendorSync(organizationId: $organizationId) {
      id
    }
  }
`;

const UPDATE_YARDI_AUTOMATIC_SYNC_ENABLED = gql`
  mutation UpdateYardiAutomaticSyncEnabled(
    $organizationId: String!
    $yardiAutomaticSyncEnabled: Boolean!
  ) {
    updateYardiAutomaticSyncEnabled(
      organizationId: $organizationId
      yardiAutomaticSyncEnabled: $yardiAutomaticSyncEnabled
    ) {
      id
      yardiAutomaticSyncEnabled
    }
  }
`;

const YARDI_SYNC_CATEGORIES = {
  PAYABLES: "payables",
  VENDORS: "vendors",
};

export function Yardi({ organization, expandedPanelKeys, toggle }) {
  const open = !!expandedPanelKeys[PANEL_KEY];

  const [modalOpen, setModalOpen] = useState(false);
  const [yardiSyncCategory, setYardiSyncCategory] = useState(null);
  const [yardiAutomaticSyncEnabled, setYardiAutomaticSyncEnabled] = useState(
    organization.yardiAutomaticSyncEnabled
  );

  const [updateYardiAutomaticSyncEnabled] = useMutation(
    UPDATE_YARDI_AUTOMATIC_SYNC_ENABLED
  );

  function handleSwitchOnChange() {
    setYardiAutomaticSyncEnabled(!yardiAutomaticSyncEnabled);
    updateYardiAutomaticSyncEnabled({
      variables: {
        organizationId: organization.id,
        yardiAutomaticSyncEnabled: !yardiAutomaticSyncEnabled,
      },
    });
  }

  return (
    <Accordion.Panel
      panelKey={PANEL_KEY}
      title="Yardi"
      onClick={() => toggle(PANEL_KEY)}
      open={open}
      {...configurationPanelStyles}
    >
      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
        <Switch
          onChange={() => handleSwitchOnChange()}
          checked={yardiAutomaticSyncEnabled}
          hasCheckIcon
        />
        <Text marginLeft={majorScale(2)} size={400}>
          Enable automatic organization wide vendor and payable sync with yardi.
        </Text>
      </Pane>
      <Pane>
        <Button
          purpose="Opens modal to initiate org wide yardi payable sync"
          onClick={() => {
            setModalOpen(true);
            setYardiSyncCategory(YARDI_SYNC_CATEGORIES.PAYABLES);
          }}
        >
          Sync all active projects
        </Button>
      </Pane>
      <Pane marginTop={majorScale(1)}>
        <Button
          purpose="Opens modal to initiate org wide yardi vendor sync"
          onClick={() => {
            setModalOpen(true);
            setYardiSyncCategory(YARDI_SYNC_CATEGORIES.VENDORS);
          }}
        >
          Sync vendors
        </Button>
      </Pane>
      <ProjectSyncStatusTable projects={organization.projectsWithYardiStatus} />
      <VendorSyncStatus organization={organization} />
      {modalOpen && (
        <PullDataFromYardiWarningModal
          organizationId={organization.id}
          onClose={() => setModalOpen(false)}
          yardiSyncCategory={yardiSyncCategory}
        />
      )}
    </Accordion.Panel>
  );
}

function ProjectSyncStatusTable({ projects }) {
  return (
    <Pane marginTop={majorScale(2)}>
      <Pane>Project Sync Status</Pane>
      {projects.length === 0 ? (
        <Text>There are no valid projects to sync with Yardi.</Text>
      ) : (
        <Pane marginTop={majorScale(1)}>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.TextHeaderCell>Project</Table.TextHeaderCell>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {projects.map((project) => {
                const syncStatus = isAtOrBefore(
                  project.accountsPayableSyncFailingSince,
                  project.accountsPayableLastSyncedAt
                )
                  ? `Last Synced: ${
                      project.accountsPayableLastSyncedAt
                        ? formatDateTime(project.accountsPayableLastSyncedAt)
                        : "Never"
                    }`
                  : `Erroring Since: ${formatDateTime(
                      project.accountsPayableSyncFailingSince
                    )}, ${project.accountsPayableSyncErrorMessage}`;
                return (
                  <Table.Row>
                    <Table.TextCell>{project.name}</Table.TextCell>
                    <Table.TextCell>{syncStatus}</Table.TextCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Pane>
      )}
    </Pane>
  );
}

function VendorSyncStatus({ organization }) {
  const organizationSyncStatus = isAtOrBefore(
    organization.yardiVendorsSyncFailingSince,
    organization.yardiVendorsLastSyncedAt
  )
    ? `Last Synced: ${
        organization.yardiVendorsLastSyncedAt
          ? formatDateTime(organization.yardiVendorsLastSyncedAt)
          : "Never"
      }`
    : `Erroring Since: ${formatDateTime(
        organization.yardiVendorsSyncFailingSince
      )}, ${organization.yardiVendorsSyncErrorMessage}`;
  return (
    <Pane marginTop={majorScale(1)}>
      <Pane marginBottom={majorScale(1)}>Vendor Sync Status</Pane>
      <Text>{organizationSyncStatus}</Text>
    </Pane>
  );
}

function PullDataFromYardiWarningModal({
  organizationId,
  onClose,
  yardiSyncCategory,
}) {
  const [
    orgLevelYardiInvoiceSync,
    { loading: orgLevelYardiInvoiceSyncLoading },
  ] = useMutation(ORG_LEVEL_YARDI_INVOICE_SYNC, {
    onCompleted: () => {
      onClose();
    },
  });

  const [
    orgLevelYardiVendorSync,
    { loading: orgLevelYardiVendorSyncLoading },
  ] = useMutation(ORG_LEVEL_YARDI_VENDOR_SYNC, {
    onCompleted: () => {
      onClose();
    },
  });

  function handleConfirm() {
    if (yardiSyncCategory === YARDI_SYNC_CATEGORIES.PAYABLES) {
      orgLevelYardiInvoiceSync({
        variables: {
          organizationId,
        },
      });
    }
    if (yardiSyncCategory === YARDI_SYNC_CATEGORIES.VENDORS) {
      orgLevelYardiVendorSync({
        variables: {
          organizationId,
        },
      });
    }
  }

  return (
    <Fragment>
      <Modal
        hasFooter
        onConfirm={handleConfirm}
        confirmLabel="Pull Data from Yardi"
        isConfirmLoading={
          orgLevelYardiInvoiceSyncLoading || orgLevelYardiVendorSyncLoading
        }
        onClose={() => onClose()}
        open
        size="small"
        title={
          yardiSyncCategory === YARDI_SYNC_CATEGORIES.PAYABLES
            ? "Pull Payable Documents from Yardi"
            : "Pull Vendors from Yardi"
        }
      >
        <Modal.Content>
          {yardiSyncCategory === YARDI_SYNC_CATEGORIES.PAYABLES && (
            <Pane marginBottom={majorScale(2)} fontSize={15}>
              After pulling payable documents from Yardi, the documents can be
              found in the project documents section.
            </Pane>
          )}
          <Pane marginBottom={majorScale(2)} fontSize={15}>
            After proceeding, please give a few minutes for your{" "}
            {yardiSyncCategory} to be added to the system.
          </Pane>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

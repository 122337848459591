import { DotIcon } from "evergreen-ui";
import {
  Pane,
  Paragraph,
  Progress,
  Shortener,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { divideCurrency, multiply, subtract, sumBy } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";

const divisionColumnProps = {
  paddingLeft: majorScale(3),
  width: 130,
};

const drawnColumnProps = {
  marginX: majorScale(1),
  width: 210,
};

const remainingColumnProps = {
  paddingRight: majorScale(1),
  width: 130,
};

export function DivisionSummaryHeader({ theme }) {
  const headerProps = {
    size: 300,
    color: theme.colors.gray700,
  };
  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={majorScale(1)}
      marginBottom={minorScale(1)}
    >
      <Pane {...divisionColumnProps}>
        <Paragraph {...headerProps}>Division</Paragraph>
      </Pane>
      <Pane {...drawnColumnProps}>
        <Paragraph {...headerProps} textAlign="center">
          Drawn
        </Paragraph>
      </Pane>
      <Pane {...remainingColumnProps}>
        <Paragraph {...headerProps}>Remaining (net)</Paragraph>
      </Pane>
    </Pane>
  );
}

export function DivisionSummaryRow({ division, theme }) {
  const remainingBudget = subtract(division.budget, division.requested);
  const remainingBudgetAsPercent = multiply(
    divideCurrency(remainingBudget, division.budget),
    100
  );

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={minorScale(1)}
    >
      <Pane {...divisionColumnProps}>
        <Shortener
          fontSize={12}
          limit={15}
          text={division.name}
          wordBreak="break-word"
          color={theme.colors.gray800}
        />
      </Pane>
      <Pane {...drawnColumnProps}>
        <Progress
          background="lightgrey"
          color={theme.colors.blue500}
          height={majorScale(1)}
          total={division.budget}
          value={division.requested}
          width="100%"
        />
      </Pane>
      <Pane {...remainingColumnProps} display="flex" alignItems="center">
        <Text paddingRight={1} size={300}>
          {formatCurrency(remainingBudget, { shortenedFormat: true })}
        </Text>
        <DotIcon size={10} color={theme.colors.gray700} />
        <Text paddingLeft={1} size={300}>
          {remainingBudgetAsPercent}%
        </Text>
      </Pane>
    </Pane>
  );
}

export function DivisionSummaryTotal({ divisions, theme }) {
  const totalRequested = sumBy(divisions, "requested");
  const totalBudget = sumBy(divisions, "budget");

  const remainingBudget = subtract(totalBudget, totalRequested);
  const remainingBudgetAsPercent = multiply(
    divideCurrency(remainingBudget, totalBudget),
    100
  );

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={minorScale(1)}
    >
      <Pane {...divisionColumnProps}>
        <Paragraph size={300} fontWeight="bold">
          Total
        </Paragraph>
      </Pane>
      <Pane {...drawnColumnProps}>
        <Progress
          background="lightgrey"
          color={theme.colors.blue700}
          height={majorScale(1)}
          total={totalBudget}
          value={totalRequested}
          width="100%"
        />
      </Pane>
      <Pane {...remainingColumnProps} display="flex" alignItems="center">
        <Text paddingRight={1} size={300} fontWeight="bold">
          {formatCurrency(remainingBudget, { shortenedFormat: true })}
        </Text>
        <DotIcon size={10} color={theme.colors.gray700} />
        <Text paddingLeft={1} size={300} fontWeight="bold">
          {remainingBudgetAsPercent}%
        </Text>
      </Pane>
    </Pane>
  );
}

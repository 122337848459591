import { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Button,
  Divider,
  Link,
  ListItem,
  Loadable,
  Modal,
  Pane,
  Text,
  UnorderedList,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { add, subtract } from "helpers/math";
import humanReadableList from "helpers/humanReadableList";
import { validRuleFailed } from "helpers/ruleHelpers";
import { fundingSourceAndBudgetDifference } from "helpers/fundingSourceHelpers";
import t from "helpers/translate";
import { formatCurrency } from "helpers/formatCurrency";

const QUERY = gql`
  query SubmitDrawQuery($projectId: String!, $drawId: String!) {
    project(id: $projectId) {
      id
      amount
      fundingSourceGroups {
        id
        amount
      }
      name
      draw(id: $drawId) {
        id
        disbursedAmount
        documents {
          id
          errors {
            message
          }
        }
        hardCostItems {
          id
          scopeId
          rules {
            id
            scopeId
            state
          }
        }
        fundingSources {
          id
          scopeId
          amount
          disbursedAmount
          disbursedPreviouslyAmount
          disbursements {
            id
            disbursedAmount
            lineItemId
          }
        }
        lineItems {
          id
          scopeId
          requestedAmount
          rules {
            id
            scopeId
            enabled
            state
          }
        }
        softCostItems {
          id
          scopeId
          rules {
            id
            scopeId
            state
          }
        }
        requestedAmount
      }
    }
  }
`;

function checkLineItemWarnings(lineItems) {
  return lineItems.some((lineItem) =>
    lineItem.rules.some((rule) => {
      return validRuleFailed(rule);
    })
  );
}
function totalLineItemDisbursedAmount(lineItem, fundingSources) {
  return fundingSources.reduce((acc, fundingSource) => {
    return add(
      acc,
      fundingSource.disbursements.reduce((disbursementTotal, disbursement) => {
        if (disbursement.lineItemId === lineItem.id) {
          return add(disbursementTotal, disbursement.disbursedAmount);
        }
        return disbursementTotal;
      }, 0)
    );
  }, 0);
}

function getTabWarnings(draw) {
  const lineItems = draw?.lineItems || [];
  const softCostItems = draw?.softCostItems || [];
  const hardCostItems = draw?.hardCostItems || [];

  const drawDisbursedAmount = draw?.disbursedAmount || 0;
  const drawRequestedAmount = draw?.requestedAmount || 0;
  const fundingSources = draw?.fundingSources || [];

  const overviewWarnings = checkLineItemWarnings(lineItems);
  const softCostWarnings = checkLineItemWarnings(softCostItems);
  const hardCostWarnings = checkLineItemWarnings(hardCostItems);

  const improperlyFundedDraw =
    subtract(drawDisbursedAmount, drawRequestedAmount) !== 0;

  const overdrawnFundingSources = fundingSources.some((fundingSource) => {
    return (
      subtract(
        fundingSource.amount,
        fundingSource.disbursedAmount,
        fundingSource.disbursedPreviouslyAmount
      ) < 0
    );
  });

  const improperlyFundedLineItems = lineItems.some((lineItem) => {
    return (
      subtract(
        lineItem.requestedAmount,
        totalLineItemDisbursedAmount(lineItem, fundingSources)
      ) !== 0
    );
  });

  const fundingSourceWarnings =
    fundingSources.length !== 0 &&
    (improperlyFundedDraw ||
      overdrawnFundingSources ||
      improperlyFundedLineItems);

  const tabsWithWarnings = [];
  if (overviewWarnings)
    tabsWithWarnings.push(t("navigation.drawTabs.lineItems"));
  if (softCostWarnings)
    tabsWithWarnings.push(t("navigation.drawTabs.softCosts"));
  if (hardCostWarnings)
    tabsWithWarnings.push(t("navigation.drawTabs.hardCosts"));
  if (fundingSourceWarnings)
    tabsWithWarnings.push(t("navigation.drawTabs.fundingSources"));

  if (tabsWithWarnings.length === 0) return [];
  return [
    {
      key: "tab-errors",
      content: t("incompleteDraw.unresolvedIssuesLine1", {
        tabsWarningList: humanReadableList(tabsWithWarnings),
        count: tabsWithWarnings.length,
      }),
    },
  ];
}

function getBudgetOfOutSyncWarnings(project, canEditFundingSources) {
  const amount = fundingSourceAndBudgetDifference(project);

  if (amount === 0) return [];
  return [
    {
      key: "fs-warnings",
      content: (
        <Fragment>
          {t("fundingSources.outOfSyncWithBudget", {
            amount: formatCurrency(Math.abs(amount)),
            greaterOrLessThan: amount > 0 ? "greater" : "less",
          })}
          {canEditFundingSources && (
            <Link
              marginLeft={majorScale(1)}
              to={{
                pathname: `/projects/${project?.id}/settings`,
                search: "?settings=fundingSources",
              }}
            >
              Edit {project?.name} funding sources.
            </Link>
          )}
        </Fragment>
      ),
    },
  ];
}

function DrawWarningContent({ warningList, handleClose, onClick }) {
  return (
    <Fragment>
      <Modal.Content>
        <Text>{t("incompleteDraw.intro")}</Text>
        <UnorderedList>
          {warningList.map(({ key, content }) => (
            <ListItem key={key}>
              <Text>{content}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Divider height={majorScale(1)} />
        <Text>{t("incompleteDraw.unresolvedIssuesLine2")}</Text>
      </Modal.Content>
      <Modal.Actions>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            content="Cancel"
            onClick={handleClose}
            marginRight={majorScale(1)}
            purpose="draw-warnings cancel"
          />
          <Button
            appearance="primary"
            content="Ignore and Continue"
            purpose="draw-warnings continue"
            onClick={onClick}
          />
        </Pane>
      </Modal.Actions>
    </Fragment>
  );
}

DrawWarningContent.propTypes = {
  tabsWithWarnings: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export function SendDrawModal({
  checkForWarnings,
  drawId,
  handleClose,
  ModalContent,
  onClick,
  open,
  projectId,
  size,
  title,
}) {
  const { hasPermission } = useContext(UserContext);
  const { data, loading } = useQuery(QUERY, {
    variables: { drawId, projectId },
  });

  if (loading) return <Loadable loading />;
  const canEditFundingSources =
    hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES) &&
    hasPermission(PERMISSION_ACTION.EDIT_PROJECT_SETTINGS);

  // If we don't care about the warningList, we can circumvent running the `getWarning` logic
  const tabWarnings = checkForWarnings
    ? getTabWarnings(data?.project?.draw, canEditFundingSources)
    : [];
  const budgetOutOfSyncWarnings = checkForWarnings
    ? getBudgetOfOutSyncWarnings(data?.project, canEditFundingSources)
    : [];

  const warningList = [...budgetOutOfSyncWarnings, ...tabWarnings];

  // The checkForWarnings here is now redundant since we're skipping the getWarning functions if it's false,
  // but to be more explicit/clear, it shall stay
  const showWarningModal = checkForWarnings && warningList.length > 0;

  return (
    <Modal
      onClose={handleClose}
      open={open}
      size={showWarningModal ? "small" : size}
      title={showWarningModal ? "Warning" : title}
    >
      {showWarningModal ? (
        <DrawWarningContent
          warningList={warningList}
          handleClose={handleClose}
          onClick={onClick}
        />
      ) : (
        <ModalContent />
      )}
    </Modal>
  );
}

SendDrawModal.propTypes = {
  checkForWarnings: PropTypes.bool,
  drawId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  trigger: PropTypes.object.isRequired,
};

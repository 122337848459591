import { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "components/materials";
import { SubmitReport } from "./SubmitReport";

const QUERY = gql`
  query SubmissionActionsQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      export {
        url
      }
    }
  }
`;

export function SubmissionActions({ match }) {
  const { submissionId } = match.params;

  const { data, loading } = useQuery(QUERY, {
    variables: { submissionId },
  });

  if (loading) return null;

  return (
    <Fragment>
      <Link purpose="submission download" href={data.submission.export.url}>
        Download Documents
      </Link>
      <SubmitReport submissionId={submissionId} />
    </Fragment>
  );
}

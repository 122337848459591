import { Fragment } from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import {
  ContentLayout,
  SelectDrawForm,
  ExpiredLink,
} from "components/templates";
import { Heading, Loadable } from "components/materials";
import { get, flatMap, sortBy } from "lodash";

const QUERY = gql`
  query ImportReportSubmissionPageQuery {
    projects {
      id
      name
      draws {
        id
        name
        submittedDate
      }
    }
  }
`;

const RECEIVE_REPORT_SUBMISSION = gql`
  mutation ReceiveReportSubmission(
    $reportSubmissionId: String!
    $targetDrawId: String!
  ) {
    receiveReportSubmission(
      reportSubmissionId: $reportSubmissionId
      targetDrawId: $targetDrawId
    ) {
      id
      project {
        id
      }
    }
  }
`;

function validate(values) {
  const errors = {};
  if (!values.drawId) errors.drawId = "Please select a draw";
  if (!values.projectId) errors.projectId = "Please select a project";
  return errors;
}

export function ImportReportSubmissionPage({ history, match }) {
  const onSubmit = (mutate) => (values) => {
    mutate({
      variables: {
        reportSubmissionId: match.params.reportSubmissionId,
        targetDrawId: values.drawId,
      },
    });
  };

  const renderForm = (data, result) => (form) => {
    const projects = get(data, "projects", []);
    const draws = flatMap(projects, (project) => {
      const drawsForProject = project.draws.map((draw) => ({
        ...draw,
        projectId: project.id,
      }));

      return sortBy(drawsForProject, "submittedDate");
    });
    return (
      <SelectDrawForm
        form={form}
        result={result}
        textSubmit="Import"
        projects={projects}
        draws={draws}
      />
    );
  };

  const renderFormik = (data) => (mutate, result) => (
    <Fragment>
      <Heading is="h3">Select a draw for this report</Heading>
      <Formik
        initialValues={{ drawId: null, projectId: null }}
        onSubmit={onSubmit(mutate)}
        validate={validate}
      >
        {renderForm(data, result)}
      </Formik>
    </Fragment>
  );

  const renderContent = ({ loading, error, data }) => {
    if (loading) return <Loadable loading />;
    if (error) return <ExpiredLink />;

    return (
      <ContentLayout fixedWidth title="Import Report">
        <Mutation
          mutation={RECEIVE_REPORT_SUBMISSION}
          onCompleted={({ receiveReportSubmission }) => {
            history.push(
              `/projects/${receiveReportSubmission.project.id}/draws/${receiveReportSubmission.id}/documentation`
            );
          }}
        >
          {renderFormik(data)}
        </Mutation>
      </ContentLayout>
    );
  };

  return <Query query={QUERY}>{renderContent}</Query>;
}

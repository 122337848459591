import { DotIcon } from "evergreen-ui";
import {
  Pane,
  Paragraph,
  Progress,
  Shortener,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import { divideCurrency, multiply, subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import t from "helpers/translate";

const sourceColumnProps = {
  paddingLeft: majorScale(3),
  width: 130,
};

const typeColumnProps = {
  marginX: majorScale(1),
  width: 45,
};

const drawnColumnProps = {
  marginX: majorScale(2),
  width: 200,
};

const remainingColumnProps = {
  paddingRight: majorScale(1),
  width: 130,
};

export function FundingSourcesSummaryHeader({ theme }) {
  const headerProps = {
    size: 300,
    color: theme.colors.gray700,
  };
  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={majorScale(1)}
      marginBottom={minorScale(1)}
    >
      <Pane {...sourceColumnProps}>
        <Paragraph {...headerProps}>Source</Paragraph>
      </Pane>
      <Pane {...typeColumnProps}>
        <Paragraph {...headerProps}>Type</Paragraph>
      </Pane>
      <Pane {...drawnColumnProps}>
        <Paragraph {...headerProps} textAlign="center">
          Drawn
        </Paragraph>
      </Pane>
      <Pane {...remainingColumnProps}>
        <Paragraph {...headerProps}>Remaining</Paragraph>
      </Pane>
    </Pane>
  );
}

export function FundingSourcesSummaryRow({ source, theme }) {
  const remainingBudget = subtract(source.amount, source.disbursedAmount);
  const remainingBudgetAsPercent = multiply(
    divideCurrency(remainingBudget, source.amount),
    100
  );

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={minorScale(1)}
    >
      <Pane {...sourceColumnProps}>
        <Shortener
          fontSize={12}
          limit={15}
          text={source.label}
          wordBreak="break-word"
          color={theme.colors.gray800}
        />
      </Pane>
      <Pane {...typeColumnProps}>
        <Shortener
          fontSize={12}
          limit={8}
          text={t(`fundingSourceType.${source.type}`)}
          wordBreak="break-word"
          color={theme.colors.gray800}
        />
      </Pane>
      <Pane {...drawnColumnProps}>
        <Progress
          background="lightgrey"
          color={theme.colors.blue500}
          height={majorScale(1)}
          total={source.amount}
          value={source.disbursedAmount}
          width="100%"
        />
      </Pane>
      <Pane {...remainingColumnProps} display="flex" alignItems="center">
        <Text paddingRight={1} size={300}>
          {formatCurrency(remainingBudget, { shortenedFormat: true })}
        </Text>
        <DotIcon size={10} color={theme.colors.gray700} />
        <Text paddingLeft={1} size={300}>
          {remainingBudgetAsPercent}%
        </Text>
      </Pane>
    </Pane>
  );
}

export function FundingSourcesSummaryTotal({ fundingSourceTotals, theme }) {
  const { amount, disbursedAmount } = fundingSourceTotals;
  const remainingAmount = subtract(amount, disbursedAmount);
  const remainingAmountAsPercent = multiply(
    divideCurrency(remainingAmount, amount),
    100
  );

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={minorScale(1)}
    >
      <Pane {...sourceColumnProps}>
        <Paragraph size={300} fontWeight="bold">
          Total
        </Paragraph>
      </Pane>
      <Pane {...typeColumnProps}>
        <Paragraph size={300} fontWeight="bold">
          Total
        </Paragraph>
      </Pane>
      <Pane {...drawnColumnProps}>
        <Progress
          background="lightgrey"
          color={theme.colors.blue700}
          height={majorScale(1)}
          total={amount}
          value={disbursedAmount}
          width="100%"
        />
      </Pane>
      <Pane {...remainingColumnProps} display="flex" alignItems="center">
        <Text paddingRight={1} size={300} fontWeight="bold">
          {formatCurrency(remainingAmount, { shortenedFormat: true })}
        </Text>
        <DotIcon size={10} color={theme.colors.gray700} />
        <Text paddingLeft={1} size={300} fontWeight="bold">
          {remainingAmountAsPercent}%
        </Text>
      </Pane>
    </Pane>
  );
}

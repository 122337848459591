import { Component } from "react";
import gql from "graphql-tag";
import { Mutation, Query } from "@apollo/react-components";
import { Formik } from "formik";
import { ContentLayout, OrganizationSelector } from "components/templates";
import { Form, Pane, Loadable } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import { majorScale, toaster } from "helpers/utilities";
import {
  userAssignablePermissions,
  formatPermissions,
} from "helpers/permissionsHelpers";
import unformatNumber from "helpers/unformatNumber";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import { get, find, omitBy, pickBy, reduce, startsWith, values } from "lodash";
import UserForm from "../../User/UserForm";
import { ScopeOrganizations } from "./ScopeOrganizations";

const QUERY = gql`
  query UserQuery {
    organizations {
      id
      permissionConfig
      userAssignablePermissions
      projects {
        id
        name
        status
        team {
          id
        }
      }
      teams {
        id
        name
      }
    }
  }
`;

const INVITE_USER = gql`
  mutation inviteUser(
    $permissions: [PermissionInput]
    $email: String!
    $firstName: String!
    $lastName: String!
    $memberships: [MembershipInput]
    $organizationId: String!
    $approvalAmountMinimum: Currency
    $approvalAmountLimit: Currency
    $projectIds: [String]
  ) {
    inviteUser(
      permissions: $permissions
      email: $email
      firstName: $firstName
      lastName: $lastName
      memberships: $memberships
      organizationId: $organizationId
      approvalAmountMinimum: $approvalAmountMinimum
      approvalAmountLimit: $approvalAmountLimit
      projectIds: $projectIds
    ) {
      id
    }
  }
`;

const getInitialValues = (teams) => {
  const teamMemberships = teams.reduce(
    (obj, { id: teamId }) => ({
      ...obj,
      [`view-all-team-${teamId}`]: false,
    }),
    {}
  );
  return {
    ...teamMemberships,
    email: "",
    firstName: "",
    lastName: "",
    permissions: values(PERMISSION_ACTION).reduce(
      (acc, action) => ({
        ...acc,
        [action]: false,
      }),
      {}
    ),
    projectIds: [],
  };
};

const validate = (values) => {
  const errors = {};
  if (isBlank(values.email)) {
    errors.email = "Please enter an email";
  } else if (isInvalidEmail(values.email)) {
    errors.email = "Please enter a valid email";
  }
  if (isBlank(values.firstName)) errors.firstName = "Please enter a first name";
  if (isBlank(values.lastName)) errors.lastName = "Please enter a last name";
  if (values.permissions[PERMISSION_ACTION.APPROVE_DOCUMENTS]) {
    const approvalAmountMinimum =
      values.approvalAmountMinimum &&
      unformatNumber(values.approvalAmountMinimum);
    const approvalAmountLimit =
      values.approvalAmountMinimum &&
      unformatNumber(values.approvalAmountLimit);
    if (
      approvalAmountLimit &&
      approvalAmountMinimum &&
      approvalAmountLimit < approvalAmountMinimum
    ) {
      errors.approvalAmountLimit =
        "Please enter a number greater than the Minimum";
    }
  }
  return errors;
};

const onSubmit = (mutation, selectedOrganization) => ({
  email,
  permissions,
  projectIds,
  ...rest
}) => {
  const isTeamMembership = (value, key) => startsWith(key, "view-all-team");
  const otherVariables = omitBy(rest, isTeamMembership);
  const memberships = reduce(
    pickBy(rest, isTeamMembership),
    (acc, value, key) => {
      if (value)
        acc.push({
          // view-all-team- is 14 chars
          teamId: key.substring(14),
          permissionConfig: [
            { action: "VIEW_ALL_TEAM_PROJECTS", enabled: true },
          ],
        });
      return acc;
    },
    []
  );
  return mutation({
    variables: {
      memberships,
      permissions: formatPermissions(permissions),
      email: email.trim(),
      organizationId: selectedOrganization.id,
      projectIds: permissions[PERMISSION_ACTION.VIEW_ALL_PROJECTS]
        ? []
        : projectIds,
      ...otherVariables,
    },
  });
};

class NewUserPageInner extends Component {
  onCompleted = () => {
    this.props.history.push("/users");
  };

  renderNewUserForm = (query) => {
    if (query.loading) return <Loadable loading />;

    return (
      <Mutation
        mutation={INVITE_USER}
        onCompleted={this.onCompleted}
        onError={(e) => {
          const split = e.message && e.message.split(":");
          const errorMessage = Array.isArray(split)
            ? split[split.length - 1]
            : "Unknown error creating this user";
          toaster.danger(`${errorMessage}`, { duration: 2.5 });
        }}
      >
        {(mutation, result) => {
          const parsedResult = {
            loading: result.loading,
            error: undefined,
          };

          const { selectedOrganization } = this.props;
          const org = find(
            get(query, "data.organizations", []),
            ({ id }) => id === selectedOrganization.id
          );
          const teams = get(org, "teams", []);
          const initialValues = getInitialValues(teams);

          return (
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={onSubmit(mutation, selectedOrganization)}
            >
              {(form) => (
                <Form data-testid="user-form">
                  <UserForm
                    {...parsedResult}
                    formikProps={form}
                    organizationPermissions={userAssignablePermissions(org)}
                    projects={get(org, "projects", [])}
                    teams={teams}
                  >
                    <Pane marginTop={majorScale(2)}>
                      <Form.SubmitButton
                        onClick={(e) => {
                          preventEventBubbling(e);
                          form.handleSubmit();
                        }}
                        purpose="new-user submit"
                        isLoading={result.loading}
                        label="Save"
                      />
                    </Pane>
                  </UserForm>
                </Form>
              )}
            </Formik>
          );
        }}
      </Mutation>
    );
  };

  render() {
    const {
      disabledOrganizations,
      onOrganizationSelected,
      organizations,
      selectedOrganization,
    } = this.props;

    return (
      <ContentLayout
        fixedWidth
        title={
          <OrganizationSelector
            fallbackTitle="New User"
            title="New user for"
            disabledOrganizations={disabledOrganizations}
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            onOrganizationSelected={onOrganizationSelected}
          />
        }
      >
        <Query query={QUERY}>{this.renderNewUserForm}</Query>
      </ContentLayout>
    );
  }
}

export function NewUserPage({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.MANAGE_USER}>
      {({
        onOrganizationSelected,
        allOrganizations,
        selectedOrganization,
        disabledOrganizations,
      }) => (
        <NewUserPageInner
          history={history}
          onOrganizationSelected={onOrganizationSelected}
          organizations={allOrganizations}
          disabledOrganizations={disabledOrganizations}
          selectedOrganization={selectedOrganization}
        />
      )}
    </ScopeOrganizations>
  );
}

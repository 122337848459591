import { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import {
  OrganizationSelector,
  LineItemDocumentationTable,
} from "components/templates";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { ScopeOrganizations } from "./ScopeOrganizations";

const QUERY = gql`
  query ReportsInvoiceSummaryPage($organizationId: String!) {
    organization(id: $organizationId) {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
      }
      teams {
        id
        name
      }
      invoiceSummaryReports {
        id
        divisionName
        documentDate
        documentDescription
        documentId
        documentNumber
        documentType
        documentUploadedDate
        documentIsPaid
        documentAmountPaid
        documentDatePaid
        drawCreatedAt
        drawLineItemName
        drawLineItemNumber
        drawName
        fileUrl
        jobCostCodes
        materialsStoredAmount
        projectCustomId
        projectId
        projectName
        retainageToDateAmount
        requestedAmount
        requestedAmountGross
        superLineItem
        vendorCostCode
        vendorName
      }
      projects {
        id
        status
        productType {
          id
          type
        }
        projectRegion {
          id
          region
        }
        team {
          id
          name
        }
        template {
          id
          name
        }
        customFields {
          id
          scopeId
          label
          type
          value
        }
      }
    }
  }
`;

function ReportsInvoiceSummaryPageInner({ history, organization }) {
  const { data, loading } = useQuery(QUERY, {
    variables: { organizationId: organization.id },
  });

  if (loading) return <Loadable loading />;

  return (
    <LineItemDocumentationTable
      organization={data.organization}
      history={history}
    />
  );
}

export function ReportsInvoiceSummaryPage({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing Vendor Line Items for"
            />
          </Pane>
          <ReportsInvoiceSummaryPageInner
            organization={selectedOrganization}
            history={history}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

import { Button, Popover } from "components/materials";
import { Position } from "helpers/utilities";
import FiltersForm from "components/materials/FastDataTable/Controls/FiltersForm";

export const FiltersWidget = ({ filterBy, marginRight, ...props }) => {
  return (
    <Popover
      content={({ close }) => (
        <FiltersForm
          close={close}
          onSubmit={(filterConfigIsSearch) => ({ filters }) => {
            filterBy(filters.concat(filterConfigIsSearch));
            close();
          }}
          removeFilter={(form, index, remove, newFilter) => {
            form.values.filters.length > 1
              ? remove(index)
              : form.setFieldValue("filters", [newFilter()]);
          }}
          {...props}
        />
      )}
      minWidth={700}
      position={Position.BOTTOM_LEFT}
      shouldCloseOnExternalClick={false}
      statelessProps={{ overflow: "visible" }}
    >
      <Button purpose="tables filters open" marginRight={marginRight}>
        Filters...
      </Button>
    </Popover>
  );
};

import ReactDOM from "react-dom/client";
import { Confirm } from "components/materials";
import { InternetExplorerContext } from "helpers/behaviors/InternetExplorerContext";

const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

export function UserConfirmation({
  message,
  callback,
  valueInternetExplorerContext,
}) {
  if (!isBrowser) return;

  const textObj = JSON.parse(message);
  const container = document.createElement("div");
  container.setAttribute("rabbet-confirm-navigation", "");
  document.body.appendChild(container);
  const root = ReactDOM.createRoot(container);

  const closeModal = (callbackState) => {
    root.unmount();
    callback(callbackState);
  };

  root.render(
    <InternetExplorerContext.Provider value={valueInternetExplorerContext}>
      <Confirm
        content={textObj.messageText}
        header="Warning"
        hideViewer
        cancelLabel="Cancel"
        confirmLabel={textObj.confirmText}
        onCloseComplete={() => closeModal(false)}
        onConfirm={() => closeModal(true)}
        open
      />
    </InternetExplorerContext.Provider>
  );
}

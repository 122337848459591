import gql from "graphql-tag";

export const TIMELINE_QUERY = gql`
  query TimelineQuery($projectId: String) {
    project(id: $projectId) {
      id
      name
      tasks {
        id
        eventName
        originalStartDate
        originalDuration
        originalCompletionDate
        projectedStartDate
        projectedCompletionDate
        actualStartDate
        actualCompletionDate
        actualDuration
        startDateVariance
        completionDateVariance
        status
        isOverdue
        userId
        ownerName
        projectId
        predecessor {
          id
          parent {
            id
            eventName
          }
          daysOffset
          originPoint
        }
        dependencies {
          id
          dependent {
            id
          }
        }
        comments {
          id
          body
          insertedAt
          author {
            id
            fullName
          }
          mentions {
            id
            user {
              id
              fullName
            }
          }
        }
      }
      users {
        id
        fullName
      }
      organization {
        id
      }
    }
  }
`;

export const SAVE_TASK = gql`
  mutation SaveTask(
    $projectId: ID!
    $taskId: ID
    $eventName: String!
    $status: TaskStatus!
    $userId: ID
    $originalStartDate: Date
    $originalDuration: Int
    $projectedStartDate: Date
    $actualStartDate: Date
    $actualCompletionDate: Date
  ) {
    saveTask(
      projectId: $projectId
      taskId: $taskId
      eventName: $eventName
      status: $status
      userId: $userId
      originalStartDate: $originalStartDate
      originalDuration: $originalDuration
      projectedStartDate: $projectedStartDate
      actualStartDate: $actualStartDate
      actualCompletionDate: $actualCompletionDate
    ) {
      id
      eventName
      status
      userId
      originalStartDate
      originalDuration
      projectedStartDate
      actualStartDate
      projectedCompletionDate
      actualCompletionDate
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($taskId: ID!, $projectId: ID!) {
    deleteTask(taskId: $taskId, projectId: $projectId) {
      id
    }
  }
`;

export const ADD_TASK_COMMENT = gql`
  mutation AddTaskComment($taskId: ID!, $projectId: ID!, $body: String!) {
    addTaskComment(taskId: $taskId, projectId: $projectId, body: $body) {
      id
      comments {
        id
        body
        insertedAt
        author {
          id
          fullName
        }
        mentions {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const SAVE_TASK_DEPENDENCY = gql`
  mutation SaveTaskDependency(
    $parentTaskId: ID!
    $dependentTaskId: ID!
    $originPoint: TaskDependencyOriginPoint!
    $daysOffset: Int!
  ) {
    saveTaskDependency(
      parentTaskId: $parentTaskId
      dependentTaskId: $dependentTaskId
      originPoint: $originPoint
      daysOffset: $daysOffset
    ) {
      id
    }
  }
`;

export const DELETE_TASK_DEPENDENCY = gql`
  mutation DeleteTaskDependency($projectId: ID!, $dependencyId: ID!) {
    deleteTaskDependency(projectId: $projectId, dependencyId: $dependencyId) {
      id
    }
  }
`;

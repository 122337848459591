import { useContext } from "react";
import { Pane, Paragraph, Heading } from "components/materials";
import { minorScale, ThemeContext } from "helpers/utilities";
import formatPercent from "helpers/formatPercent";
import { VictoryTheme, VictoryContainer, VictoryPie } from "victory";

export function DonutChart({ percentUsed, contingencySegments }) {
  const theme = useContext(ThemeContext);
  const colorScale = theme.graphColorScale;

  const victoryTheme = VictoryTheme.grayscale;
  const chartSize = 190; // px

  return (
    <Pane width={chartSize}>
      <div
        style={{
          position: "relative",
          height: chartSize,
          width: "100%",
          minWidth: chartSize,
        }}
      >
        <VictoryContainer
          height={victoryTheme.pie.height}
          width={victoryTheme.pie.width}
        >
          <VictoryPie
            standalone={false}
            data={contingencySegments.map(({ value }) => ({ y: value }))}
            colorScale={contingencySegments.map((segment, index) =>
              segment.name === "Contingency Remaining"
                ? theme.colors.borderGray
                : colorScale[index % colorScale.length]
            )}
            padding={20}
            innerRadius={130}
            labels={() => null}
            animate={false}
            startAngle={0}
            endAngle={360}
          />
        </VictoryContainer>
        <Pane
          position="absolute"
          top="52%"
          left="50%"
          textAlign="center"
          transform="translate(-50%, -60%)"
        >
          <Heading size={800} fontWeight={theme.fontWeights.DEMI}>
            {formatPercent(percentUsed)}
          </Heading>
          <Paragraph
            lineHeight={1.3}
            color={theme.colors.textGray}
            marginTop={minorScale(1)}
          >
            Contingency Used
          </Paragraph>
        </Pane>
      </div>
    </Pane>
  );
}

import { useContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Form, Paragraph } from "components/materials";
import { majorScale } from "helpers/utilities";
import { includes, trim } from "lodash";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";

const CREATE_REFETCH = gql`
  query JobCostCodeModalRefetch {
    organizations {
      id
      jobCostCodes {
        id
        code
        description
        glCode
      }
    }
  }
`;

const CREATE = gql`
  mutation JobCostCodeModalCreate(
    $code: String!
    $description: String
    $glCode: String
    $organizationId: String!
  ) {
    addJobCostCode(
      code: $code
      description: $description
      glCode: $glCode
      organizationId: $organizationId
    ) {
      id
      code
      description
      glCode
    }
  }
`;

const EDIT = gql`
  mutation JobCostCodeModalEdit(
    $id: String!
    $code: String!
    $description: String
    $glCode: String
    $organizationId: String!
  ) {
    editJobCostCode(
      id: $id
      code: $code
      description: $description
      glCode: $glCode
      organizationId: $organizationId
    ) {
      id
      code
      description
      glCode
    }
  }
`;

const validate = ({ organizationCostCodes, initialCode, isEdit }) => (
  values
) => {
  const invalidCostCodes = isEdit
    ? organizationCostCodes.filter((code) => code !== initialCode)
    : organizationCostCodes;

  const errors = {};
  if (isBlank(values.code)) {
    errors.code = "Please enter a job cost code";
  } else if (includes(invalidCostCodes, trim(values.code))) {
    errors.code = "This job cost code already exists on this project.";
  } else if (values.code.length > 100) {
    errors.code = "Job cost code must be 100 characters or fewer";
  }
  return errors;
};

export function JobCostCodeModal({
  costCode,
  onClose,
  onMutationCompleted,
  organizationCostCodes,
  organizationId,
  ...props
}) {
  const [
    createCostCode,
    { error: createError, loading: createLoading },
  ] = useMutation(CREATE, {
    awaitRefetchQueries: true,
    onCompleted: onMutationCompleted,
    refetchQueries: [{ query: CREATE_REFETCH }],
  });

  const [
    editCostCode,
    { error: editError, loading: editLoading },
  ] = useMutation(EDIT, {
    awaitRefetchQueries: true,
    onCompleted: onMutationCompleted,
  });

  const isEdit = !!costCode.id;

  const handleSubmit = ({ id, code, description, glCode }) => {
    const variables = {
      description,
      organizationId,
      code: trim(code),
      glCode: trim(glCode),
    };
    if (isEdit) {
      editCostCode({
        variables: { ...variables, id },
      });
    } else {
      createCostCode({
        variables,
      });
    }
  };

  const modalProps = isEdit
    ? {
        confirmLabel: "Save changes",
        header: "Edit Job Cost Code",
      }
    : {
        confirmLabel: "Save",
        header: "New Job Cost Code",
      };

  const { hasPermission } = useContext(UserContext);
  const hasYardiPermission =
    hasPermission(PERMISSION_ACTION.YARDI_INTEGRATION) ||
    hasPermission(PERMISSION_ACTION.PULL_DATA_FROM_YARDI);
  return (
    <Formik
      initialValues={costCode}
      onSubmit={handleSubmit}
      validate={validate({
        organizationCostCodes: organizationCostCodes.map(({ code }) => code),
        initialCode: costCode.code,
        isEdit,
      })}
    >
      {({ initialValues, handleSubmit, values }) => (
        <Form.Modal
          error={createError || editError}
          hasClose={false}
          isConfirmDisabled={
            isEdit &&
            initialValues.code === trim(values.code) &&
            initialValues.description === values.description &&
            initialValues.glCode === values.glCode
          }
          loading={createLoading || editLoading}
          onSubmit={handleSubmit}
          size="small"
          topOffset="24vmin"
          onClose={onClose}
          {...modalProps}
          {...props}
        >
          <Paragraph marginBottom={majorScale(2)}>
            {isEdit ? t("costCodes.edit") : t("costCodes.create")}
          </Paragraph>
          <Form.Input
            label="Job Cost Code"
            name="code"
            marginBottom={majorScale(2)}
          />
          <Form.Input
            label="Description"
            name="description"
            marginBottom={majorScale(2)}
          />
          {hasYardiPermission && (
            <Form.Input
              label="GL Account"
              name="glCode"
              marginBottom={majorScale(2)}
            />
          )}
        </Form.Modal>
      )}
    </Formik>
  );
}

JobCostCodeModal.propTypes = {
  costCode: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string.isRequired,
    description: PropTypes.string,
    gl_code: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  organizationCostCodes: PropTypes.arrayOf([PropTypes.string]).isRequired,
  organizationId: PropTypes.string.isRequired,
};

JobCostCodeModal.defaultProps = {
  onCompleted: () => {},
};

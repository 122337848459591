import { Formik } from "formik";
import { majorScale, Pane, Heading } from "evergreen-ui";
import { Modal } from "components/materials/Modal";
import Form from "components/materials/Form";
import { AddStakeholdersForm } from "components/templates/AddStakeholdersForm";
import { ReviewPackage } from "./ReviewPackage";
import { AddItem, Item } from "../../types";

export interface Props {
  open: boolean;
  items: Item[];
  onSend: (items: AddItem[]) => void;
  onCancel(): void;
}

export function SendDrawModal({ open, onCancel }: Props) {
  return (
    <Modal open={open} size="large" title="Send Draw" onClose={onCancel}>
      <ReviewPackage />
      <Formik
        initialValues={{
          sections: {
            packageSummary: true,
            drawSummary: true,
            sourceOfFunds: true,
          },
        }}
        // eslint-disable-next-line no-alert
        onSubmit={() => alert("Imagine I just sent this")}
      >
        {(form) => (
          <Form.Generic
            textSubmit="Send"
            handleSubmit={form.handleSubmit}
            handleCancel={() => {
              form.resetForm();
              onCancel();
            }}
            cancelLabel="Cancel"
          >
            <Pane marginTop={majorScale(2)}>
              <Heading>Email Sections to Include</Heading>
              <Form.Checkbox
                marginTop={majorScale(0)}
                marginBottom={majorScale(0)}
                name="sections.packageSummary"
                label="Summary and personalized note"
              />
              <Form.Checkbox
                marginTop={majorScale(0)}
                marginBottom={majorScale(0)}
                name="sections.drawSummary"
                label="Draw summary"
              />
              <Form.Checkbox
                marginTop={majorScale(0)}
                marginBottom={majorScale(0)}
                name="sections.sourceOfFunds"
                label="Source of funds"
              />
            </Pane>
            {form.values.sections.packageSummary && (
              <Pane marginTop={majorScale(2)}>
                <Heading>Note</Heading>
                <Form.TextArea
                  {...({} as any)}
                  height={175}
                  name="comment"
                  placeholder="Enter a note (optional)"
                  size={300}
                />
              </Pane>
            )}
            <Pane marginTop={majorScale(2)}>
              <Heading paddingBottom={majorScale(2)}>
                Select the stakeholders you would like to receive this draw
              </Heading>
              <AddStakeholdersForm
                deleteStakeholderGroup={() => {}}
                disableEdits
                projectId="123"
                setStakeholderIds={() => {}}
                stakeholderIds={[1, 2, 3]}
                stakeholders={[]}
              />
            </Pane>
          </Form.Generic>
        )}
      </Formik>
    </Modal>
  );
}

import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { CreateOrganizationForm } from "components/templates";
import { Modal, Paragraph } from "components/materials";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { trim } from "lodash";
import { isValidZipcode } from "helpers/addressValidation";
import isBlank from "helpers/isBlank";
import isInvalidEmail from "helpers/isInvalidEmail";
import t from "helpers/translate";
import { QUERY, CREATE } from "./graphql-queries";

// These values are defined on the backend
// Note: Email, phone, and address are not currently searchable
// because they are stored in an array on the backend
// and the functionality hasn't been written to search arrays via queries
// TODO move to an enum
const searchableKeys = {
  name: "name",
  city: "city",
  stateValue: "state",
  zip: "zip",
  vendorCostCode: "vendor_cost_code",
};

function getValue(key, value) {
  if (!isBlank(value) && key === "phoneNumber") return value.replace(/\D/g, "");
  return value;
}

function getFilters(values) {
  return Object.keys(values).reduce((filters, key) => {
    if (key in searchableKeys) {
      const value = getValue(key, values[key]);
      if (!isBlank(value)) {
        return [
          ...filters,
          { field: searchableKeys[key], comparator: "LIKE", value },
        ];
      }
    }
    return filters;
  }, []);
}

function getInitialValues({ initialValues, hasAccountsPayable }) {
  const {
    name,
    email,
    phoneNumber,
    streetAddress,
    city,
    state,
    zip,
  } = initialValues;

  return {
    is1099: false,
    isPayable: hasAccountsPayable,
    name: name || "",
    email: email || "",
    phoneNumber: phoneNumber || "",
    streetAddress,
    city,
    stateValue: state || null,
    zip,
    type: ORGANIZATION_TYPE.OTHER,
    vendorCostCode: "",
  };
}

const initialVendorsState = { data: [], total: 0 };

export function CreateOrganizationModal({
  initialValues,
  onCancel,
  onCreate,
  onSelect,
  ...props
}) {
  const { hasPermission, organizationId } = useContext(UserContext);
  const [create, result] = useMutation(CREATE, { onCompleted: onCreate });
  const [getSearchData, { data }] = useLazyQuery(QUERY, {
    variables: {
      organizationId,
      ready: true,
      pagination: { limit: 10, page: 0 },
      filters: getFilters(initialValues),
    },
  });

  const [vendors, setVendors] = useState(initialVendorsState);

  useEffect(() => {
    if (!data) return setVendors(initialVendorsState);

    const total = getVendorCount(data);
    const vendorsData = getVendors(data);

    return setVendors({ total, data: vendorsData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validate = (values) => {
    const errors = {};
    if (isBlank(values.name)) {
      errors.name = "Please enter a name";
    }
    // email is not required intentionally but it should be valid
    if (!isBlank(values.email) && isInvalidEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }

    if (!isBlank(values.phone)) {
      const phoneDigits = values.phone.replace(/\D/g, "");
      if (phoneDigits.length !== 10)
        errors.phone = "Please enter a valid phone number";
    }

    if (!isValidZipcode(values.zip)) {
      errors.zip = "Postal Code is invalid";
    }

    if (
      !isBlank(values.vendorCostCode) &&
      trim(values.vendorCostCode).length > 100
    ) {
      errors.vendorCostCode = "Vendor ID must be 100 characters or fewer";
    }

    if (isBlank(values.type)) {
      errors.type = "Please select a type";
    }

    const hasAvidAP = hasPermission(PERMISSION_ACTION.AVID_AP_INTEGRATION);

    if (values.isPayable && !hasAvidAP) {
      ["city", "streetAddress", "stateValue", "zip"].forEach((field) => {
        if (isBlank(values[field])) {
          errors[field] = "Required";
          errors.requiredWhenPayable = true;
        }
      });
    }
    return errors;
  };

  const handleSubmit = (values) => {
    create({ variables: { ...values, sourceOrganizationId: organizationId } });
  };

  const getVendorCount = (data) => {
    return data?.organization.paginatedVendors.total || 0;
  };

  const getVendors = (data) => {
    const vendors = data?.organization.paginatedVendors.results || [];

    return vendors.map((vendor) => {
      return {
        ...vendor,
        phoneNumber: vendor.phoneNumbers[0],
        email: vendor.emailAddresses[0],
      };
    });
  };

  const hasVisionDeltekAP = () => {
    return hasPermission(PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION);
  };

  return (
    <Modal
      hideViewer
      onClose={onCancel}
      open
      size="large"
      title="Select an organization or create a new one"
      {...props}
    >
      <Modal.Content>
        <Paragraph marginBottom={majorScale(2)}>
          {t("createOrganization.info")}
        </Paragraph>
        <Formik
          initialValues={getInitialValues({
            initialValues,
            hasVisionDeltekAP: hasVisionDeltekAP(),
          })}
          onReset={onCancel}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(formikProps) => {
            return (
              <CreateOrganizationForm
                formikProps={formikProps}
                getFilters={getFilters}
                getSearchData={getSearchData}
                mutationResultProps={result}
                onSelect={onSelect}
                organizationId={organizationId}
                vendorCount={vendors.total}
                vendors={vendors.data}
              />
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

CreateOrganizationModal.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    type: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

CreateOrganizationModal.defaultProps = {
  initialValues: {},
};

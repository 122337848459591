import { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loadable, Pane } from "components/materials";
import {
  OrganizationSelector,
  FundingSourcesReportTable,
} from "components/templates";
import { omit } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import { ScopeOrganizations } from "./ScopeOrganizations";

const FUNDING_SOURCES_QUERY = gql`
  query ReportsInvoiceSummaryPage($organizationId: String!) {
    organization(id: $organizationId) {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
      }
      teams {
        id
        name
      }
      projects {
        id
        name
        status
        productType {
          id
          type
        }
        projectRegion {
          id
          region
        }
        team {
          id
          name
        }
        template {
          id
          name
        }
        fundingSourceGroups {
          id
          label
          type
          closeDate
          maturityDate
          amount
          disbursedAmount
          organization {
            id
            name
          }
        }
        customFields {
          id
          scopeId
          label
          type
          value
        }
      }
    }
  }
`;

function ScopedReportsFundingSourcesPage({ history, selectedOrganization }) {
  const { data, loading } = useQuery(FUNDING_SOURCES_QUERY, {
    variables: { organizationId: selectedOrganization.id },
  });

  if (loading) return <Loadable loading />;

  return (
    <FundingSourcesReportTable
      selectedOrganization={selectedOrganization}
      projects={data.organization.projects}
      orgData={omit(data.organization, ["projects"])}
      history={history}
    />
  );
}

export function ReportsFundingSourcesPage({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        disabledOrganizations,
        allOrganizations,
        selectedOrganization,
      }) => (
        <Fragment>
          <Pane margin={majorScale(2)}>
            <OrganizationSelector
              onOrganizationSelected={onOrganizationSelected}
              disabledOrganizations={disabledOrganizations}
              organizations={allOrganizations}
              selectedOrganization={selectedOrganization}
              title="Viewing Funding Sources for"
            />
          </Pane>
          <ScopedReportsFundingSourcesPage
            selectedOrganization={selectedOrganization}
            history={history}
          />
        </Fragment>
      )}
    </ScopeOrganizations>
  );
}

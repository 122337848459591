import { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Loadable } from "components/materials";
import {
  PaymentsContext,
  PaymentsContextProvider,
} from "contexts/paymentsContext";
import t from "helpers/translate";
import { VENDOR_PAYMENT_TYPE } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import { toaster } from "helpers/utilities";
import { get, uniq } from "lodash";
import { PostPaymentForm } from "./PostPaymentForm";
import { YardiErrorModal } from "./YardiErrorModal";
import { POST_PAYMENTS_TO_YARDI } from "./graphql";

function getWarningTooltip({
  isPayableType,
  updatedSincePosted,
  documentHasVendorId,
  projectHasCustomId,
  hasLineItemMissingJobCostCode,
  allJobCostCodesHaveGlAccounts,
  projectHasGlCode,
  showMissingLineItemNumberOrJobCostCodeWarning,
}) {
  const isDoNotPayDirectly = !isPayableType;
  if (isDoNotPayDirectly) return undefined;
  if (updatedSincePosted)
    return t("paymentIntegration.updatedSincePostedTooltip");
  if (!documentHasVendorId)
    return t("paymentIntegration.documentMissingVendorId");
  if (!projectHasCustomId)
    return t("paymentIntegration.missingProjectCustomId");
  if (hasLineItemMissingJobCostCode && !projectHasGlCode)
    return t("paymentIntegration.lineItemMissingJobCostCodeAndFallback");
  if (!allJobCostCodesHaveGlAccounts && !projectHasGlCode)
    return t("paymentIntegration.jobCostCodeMissingGlAccountAndFallback");
  if (showMissingLineItemNumberOrJobCostCodeWarning)
    return t("paymentIntegration.lineItemMissingNumberOrJobCostCode");
  return undefined;
}

function getRowState({
  isPayableType,
  updatedSincePosted,
  documentHasVendorId,
  projectHasCustomId,
  hasLineItemMissingJobCostCode,
  allJobCostCodesHaveGlAccounts,
  projectHasGlCode,
  showMissingLineItemNumberOrJobCostCodeWarning,
}) {
  const isDoNotPayDirectly = !isPayableType;
  if (isDoNotPayDirectly) return undefined;
  if (updatedSincePosted) return "error";
  if (!documentHasVendorId) return "pending";
  if (!projectHasCustomId) return "pending";
  if (hasLineItemMissingJobCostCode && !projectHasGlCode) return "pending";
  if (!allJobCostCodesHaveGlAccounts && !projectHasGlCode) return "pending";
  if (showMissingLineItemNumberOrJobCostCodeWarning) return "pending";
  return undefined;
}

function getCanBePosted({
  isPayableType,
  documentHasVendorId,
  documentHasBeenPosted,
  projectHasCustomId,
  hasLineItemMissingJobCostCode,
  allJobCostCodesHaveGlAccounts,
  projectHasGlCode,
  showMissingLineItemNumberOrJobCostCodeWarning,
}) {
  const isDoNotPayDirectly = !isPayableType;
  return (
    isDoNotPayDirectly ||
    (!documentHasBeenPosted &&
      documentHasVendorId &&
      projectHasCustomId &&
      (!hasLineItemMissingJobCostCode || projectHasGlCode) &&
      (allJobCostCodesHaveGlAccounts || projectHasGlCode) &&
      !showMissingLineItemNumberOrJobCostCodeWarning)
  );
}

/* Format errors for display
{
  docNames: ["doc1.pdf", "doc2.pdf"],
  messsages: ["Something went wrong"]
}
*/
function formatErrors({ graphQLErrors, documents }) {
  const docNames = graphQLErrors.flatMap((error) => {
    return error.details.map((docId) => {
      const document = documents.find(({ id }) => id === docId);
      return get(document, "file.name");
    });
  });
  const messages = graphQLErrors.map((error) => error.message);
  return { docNames: uniq(docNames), messages };
}

function InnerYardiPaymentPage({ drawId, projectId }) {
  const {
    queryLoading,
    documentsToPost,
    setDocumentsToPost,
    onPaymentPosted,
  } = useContext(PaymentsContext);
  const [returnedYardiErrors, setReturnedYardiErrors] = useState([]);

  const [postPaymentsToYardi, { loading: postPaymentsLoading }] = useMutation(
    POST_PAYMENTS_TO_YARDI,
    {
      onCompleted: () => {
        setDocumentsToPost([]);
        onPaymentPosted();
        toaster.success("Your payments have successfully posted.", {
          duration: 2.5,
        });
      },
      onError: ({ graphQLErrors }) => {
        setDocumentsToPost([]);
        setReturnedYardiErrors(
          formatErrors({ documents: documentsToPost, graphQLErrors })
        );
        toaster.danger("Something went wrong. Please try again.", {
          duration: 2.5,
        });
      },
    }
  );

  function postPayments(formValues) {
    const payments = Object.entries(formValues.payments).reduce(
      (acc, [id, vendorPaymentType]) => {
        const document = documentsToPost.find((document) => document.id === id);
        if (!document || document.postedAt) return acc;

        return [
          ...acc,
          {
            documentId: id,
            amount: formatCurrency(document.paymentDueAmount),
            vendorPaymentType,
          },
        ];
      },
      []
    );

    postPaymentsToYardi({
      variables: {
        drawId,
        projectId,
        payments,
      },
    });
  }

  if (queryLoading) return <Loadable loading />;

  if (returnedYardiErrors.length !== 0)
    return (
      <YardiErrorModal
        errors={returnedYardiErrors}
        onCloseComplete={() => {
          setReturnedYardiErrors([]);
          onPaymentPosted();
        }}
      />
    );

  return (
    <PostPaymentForm
      handleSubmit={postPayments}
      onSubmitLoading={postPaymentsLoading}
      accountsPayableSystem="Yardi"
      vendorPaymentTypes={[
        VENDOR_PAYMENT_TYPE.SOFT_COSTS_DIRECT_PAY,
        VENDOR_PAYMENT_TYPE.SUBCONTRACTOR_REIMBURSABLE,
      ]}
    />
  );
}

export function YardiPaymentPage({ match }) {
  const { drawId, projectId } = match.params;

  return (
    <PaymentsContextProvider
      drawId={drawId}
      projectId={projectId}
      includeLineItems
      includeJobCostCodes
      getWarningTooltip={getWarningTooltip}
      getRowState={getRowState}
      getCanBePosted={getCanBePosted}
    >
      <InnerYardiPaymentPage drawId={drawId} projectId={projectId} />
    </PaymentsContextProvider>
  );
}

import gql from "graphql-tag";

export const UPDATE_PROJECT_SETUP_STEP = gql`
  mutation UpdateProjectSetupStep(
    $id: String!
    $projectId: String!
    $status: ProjectSetupStepStatus!
  ) {
    updateProjectSetupStep(id: $id, projectId: $projectId, status: $status) {
      id
      completedAt
      status
      step
    }
  }
`;

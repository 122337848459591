import PropTypes from "prop-types";
import { flatten, get, values } from "lodash";

export const COMPARATORS = {
  GREATER_THAN: { key: "GREATER_THAN", value: "GREATER_THAN", text: ">" },
  LESS_THAN: { key: "LESS_THAN", value: "LESS_THAN", text: "<" },
  EQUAL: { key: "EQUAL", value: "EQUAL", text: "=" },
  NOT_EQUAL: { key: "NOT_EQUAL", value: "NOT_EQUAL", text: "≠" },
  CONTAINS: { key: "CONTAINS", value: "CONTAINS", text: "Contains" },
  NOT_CONTAINS: {
    key: "NOT_CONTAINS",
    value: "NOT_CONTAINS",
    text: "Does Not Contain",
  },
  EXACT: { key: "EXACT", value: "EXACT", text: "Exact Match" },
  IS_EMPTY: { key: "IS_EMPTY", value: "IS_EMPTY", text: "Is Empty" },
  IS_NOT_EMPTY: {
    key: "IS_NOT_EMPTY",
    value: "IS_NOT_EMPTY",
    text: "Is Not Empty",
  },
};

export const filterRequiresInput = (fc) =>
  ![COMPARATORS.IS_EMPTY.value, COMPARATORS.IS_NOT_EMPTY.value].includes(
    fc.operator
  );

export const filterShape = PropTypes.shape({
  columnId: PropTypes.string,
  comparator: PropTypes.oneOf(Object.keys(COMPARATORS)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const groupShape = PropTypes.shape({
  columnId: PropTypes.string,
});

export const columnShape = PropTypes.shape({});

export function fromBase64(data) {
  return JSON.parse(Buffer.from(data, "base64").toString("utf8"));
}

export function toBase64(data) {
  return Buffer.from(JSON.stringify(data), "utf8").toString("base64");
}

export function getColumnHeaderText(column) {
  if (get(column, "header.props.name")) return column.header.props.name;
  return column.headerGrouping
    ? `${column.headerGrouping} - ${column.header}`
    : column.header;
}

function getColumn(columns, columnId) {
  return columns.find((column) => column.id === columnId);
}

function markColumnGroups(columns, config) {
  const [markedColumns] = columns.reduce(
    ([columns, currentGroup], column) => {
      const isNewGroup =
        !!column.headerGrouping && column.headerGrouping !== currentGroup;
      const markForDefaultView =
        !!config.isDefault && !!column.markColumnInDefaultView;

      if (isNewGroup || markForDefaultView) {
        const markedColumn = {
          ...column,
          cellProps: { borderLeft: "2px solid #A6B1BB" },
        };
        return [[...columns, markedColumn], column.headerGrouping];
      }

      return [[...columns, column], currentGroup];
    },
    [[], null]
  );

  return markedColumns;
}

export function prepareColumns(columns, config) {
  let preparedColumns;
  if (config.columnConfig) {
    preparedColumns = config.columnConfig
      .reduce((acc, columnId) => {
        const column = getColumn(columns, columnId);
        if (column && !column.hidden) acc.push(column);
        return acc;
      }, [])
      .concat(
        columns.filter(
          (column) =>
            config.columnConfig.includes(column.idRepeated) && !column.hidden
        )
      );
  } else {
    preparedColumns = columns.filter((column) => !column.hidden);
  }
  return markColumnGroups(preparedColumns, config);
}

export function filterItems(items, columns, filterConfig) {
  if (filterConfig.length > 0) {
    return items.filter((item) => {
      return filterConfig.reduce((acc, fc) => {
        const column = getColumn(columns, fc.key);

        if (column) {
          const value = column.value(item);
          return acc && column.filterStrategy(value, fc);
        }

        return acc;
      }, true);
    });
  }
  return items;
}

function sortItems(items, columns, sortConfig) {
  if (!sortConfig.columnId || !sortConfig.direction) return items;

  const column = getColumn(columns, sortConfig.columnId);

  if (!column) return items;

  const sortFunction =
    sortConfig.direction === "asc"
      ? (a, b) =>
          column.sortStrategy(
            column.value(a),
            column.value(b),
            column.sortOrder
          )
      : (a, b) =>
          column.sortStrategy(
            column.value(b),
            column.value(a),
            column.sortOrder
          );
  return items.slice().sort(sortFunction);
}

function groupItems(items, columns, groupConfig) {
  if (!groupConfig.columnId) return items;

  const column = getColumn(columns, groupConfig.columnId);

  if (!column || column.hidden) return items;

  const groups = items.reduce((acc, item) => {
    const value = column.value(item);
    if (acc[value]) {
      acc[value].push(item);
    } else {
      acc[value] = [item];
    }
    return acc;
  }, {});

  return { column, groups };
}

export function prepareItems(items, columns, config) {
  const filtered = filterItems(items, columns, config.filterConfig);
  const sorted = sortItems(filtered, columns, config.sortConfig);
  const grouped = groupItems(sorted, columns, config.groupConfig);
  return grouped;
}

export function paginateItems(items, pageDisabled, pageNumber, pageSize) {
  if (!pageDisabled) {
    return items.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }
  return items;
}

export function getValuesForExport(columns, items) {
  return items.map((item) =>
    columns.map((column) => {
      const value = column.value(item);
      if (column.exportValue) return column.exportValue;
      return column.valueExporter
        ? column.valueExporter(value, item, column)
        : value;
    })
  );
}

export function getAggregatesForExport(columns, items) {
  return columns.map((column) => {
    if (column.primary) return "Subtotal";
    if (column.disableAggregateExport) return null;

    return column.aggregate(items);
  });
}

export function getTotalsForExport(columns, preparedItems) {
  const items = preparedItems.groups
    ? flatten(values(preparedItems.groups))
    : preparedItems;

  return columns.map((column) => {
    if (column.primary) return "Total";
    if (column.disableAggregateExport) return null;

    return column.aggregate(items);
  });
}

import { useState, useEffect, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { EditProjectSettingsButtons } from "components/templates";
import {
  Accordion,
  Form,
  Modal,
  Pane,
  Paragraph,
  Tablist,
  Tab,
  Text,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import { majorScale, minorScale } from "helpers/utilities";
import { get, includes, reduce as lodashReduce } from "lodash";
import isEqualWithout from "helpers/isEqualWithout";
import {
  DRAW_STATE,
  PERMISSION_ACTION,
  PROJECT_SETUP_STEP,
} from "helpers/enums";
import t from "helpers/translate";
import { UserAccessTable } from "./UserAccessTable";
import { EditDocumentReviewers } from "./EditDocumentReviewers";

const USER_ACCESS_TABS = ["PROJECT_ACCESS", "DOCUMENT_APPROVALS"];

export function initialValues(project) {
  const {
    documentReviewers,
    drawReviewers,
    draws,
    suggestedDocumentAssignees,
    team,
    viewAllProjectsUserIds,
    viewCurrentProjectUserIds,
  } = project;
  const reviewersDict = drawReviewers.reduce(
    (dict, reviewer) => ({ ...dict, [reviewer.id]: reviewer }),
    {}
  );
  const assigneesDict = suggestedDocumentAssignees.reduce(
    (dict, assignee) => ({ ...dict, [assignee.userId]: assignee }),
    {}
  );

  const unfundedDraws = draws.filter(
    (draw) => draw.state !== DRAW_STATE.FUNDED
  );

  const users = project.organization.users.reduce(
    (
      acc,
      {
        id,
        fullName,
        email,
        approvalAmountLimit,
        approvalAmountMinimum,
        permissionConfig,
      }
    ) => {
      const viewAll = includes(viewAllProjectsUserIds, id);
      const canAccessProject = viewAll
        ? false
        : includes(viewCurrentProjectUserIds, id);

      if (!viewAll && !canAccessProject) return acc;

      const isSuggestedAssignee = !!assigneesDict[id];

      const foundDrawReviewer = reviewersDict[id];

      const isPreparer = !!foundDrawReviewer?.isPreparer;

      const isSignatory = !!foundDrawReviewer?.isSignatory;

      const hasSignatoryPermission = get(
        permissionConfig,
        "drawSignatory",
        false
      );
      const canApproveDocuments = get(
        permissionConfig,
        "approveDocuments",
        false
      );

      return {
        ...acc,
        [id]: {
          id,
          approvalAmountLimit,
          approvalAmountMinimum,
          canAccessProject,
          canApproveDocuments,
          email,
          fullName,
          hasSignatoryPermission,
          isDrawReviewer: !!foundDrawReviewer && !isPreparer && !isSignatory,
          isPreparer,
          isSignatory,
          isSuggestedAssignee,
          viewAll,
        },
      };
    },
    {}
  );

  const documentReviewerIds = documentReviewers
    .filter(({ userId }) => users[userId]?.canApproveDocuments)
    .map(({ userId }) => userId);

  return {
    documentReviewerIds,
    updateDraws: unfundedDraws.reverse().map(({ id, name }) => ({
      id,
      checked: false,
      name,
    })),
    teamId: get(team, "id"),
    users,
  };
}

export function parseUserAccessValues(users) {
  return lodashReduce(
    users,
    (acc, user, userId) => ({
      drawReviewerIds:
        user.isDrawReviewer && !user.isSignatory && !user.isPreparer
          ? acc.drawReviewerIds.concat(userId)
          : acc.drawReviewerIds,
      preparerId: user.isPreparer ? userId : acc.preparerId,
      signatoryId: user.isSignatory ? userId : acc.signatoryId,
      suggestedDocumentAssigneeIds: user.isSuggestedAssignee
        ? acc.suggestedDocumentAssigneeIds.concat(userId)
        : acc.suggestedDocumentAssigneeIds,
    }),
    {
      suggestedDocumentAssigneeIds: [],
      drawReviewerIds: [],
      preparerId: null,
      signatoryId: null,
    }
  );
}

// if only document reviewers are changed don't trigger the confirm draw updates modal
function checkSubmission({ values, initialValues }) {
  const initial = parseUserAccessValues(initialValues.users);
  const current = parseUserAccessValues(values.users);

  return (
    values.updateDraws.length > 0 &&
    !isEqualWithout("suggestedDocumentAssigneeIds", initial, current)
  );
}

function ConfirmDrawUpdates({ form, onClose }) {
  const unfundedDraws = get(form, "values.updateDraws", []);
  return (
    <Modal
      open
      title="Change current draw reviewers?"
      confirmLabel="Confirm Changes"
      onClose={onClose}
      onConfirm={(close) => {
        form.handleSubmit();
        close();
      }}
      hasFooter
      topOffset="24vmin"
      contentContainerProps={{ marginX: minorScale(3) }}
    >
      <Paragraph color="muted">
        {t("projectSettings.updateReviewersExplanation")}
      </Paragraph>
      <Paragraph color="muted" marginTop={majorScale(1)}>
        {t("projectSettings.confirmDrawReviewers", {
          count: unfundedDraws.length,
        })}
      </Paragraph>
      <Pane marginLeft={majorScale(1)}>
        {unfundedDraws.map((updateDraw, index) => (
          <Form.Checkbox
            key={updateDraw.id}
            name={`updateDraws.${index}.checked`}
            label={<Text>Update on {updateDraw.name}</Text>}
          />
        ))}
      </Pane>
    </Modal>
  );
}

export function EditUserAccessPanel({
  completeSetupStep,
  dirty,
  form,
  hasSuggestedDocumentAssignees,
  loading,
  onToggle,
  open,
  panelKey,
  projectId,
  setPanelDirty,
  setupSteps,
  teams,
  ...props
}) {
  const [selectedTab, setSelectedTab] = useState("PROJECT_ACCESS");
  const [confirmDrawUpdatesOpen, setConfirmDrawUpdatesOpen] = useState(false);
  useEffect(() => {
    if (open) {
      completeSetupStep(PROJECT_SETUP_STEP.REVIEW_USER_ACCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const isDirty = form.dirty;
    if (isDirty !== dirty) {
      setPanelDirty(panelKey, isDirty);
    }
    return undefined;
  }, [dirty, form, panelKey, setPanelDirty]);

  const { hasOrgLevelPermission, hasPermission } = useContext(UserContext);

  function closeDrawUpdates() {
    setConfirmDrawUpdatesOpen(false);
    form.setFieldValue(
      "updateDraws",
      form.values.updateDraws.map((draw) => ({ ...draw, checked: false }))
    );
  }

  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);
  const hasDocumentReviewers = hasOrgLevelPermission(
    PERMISSION_ACTION.APPROVE_DOCUMENTS
  );

  return (
    <Fragment>
      <Accordion.Panel
        panelKey={panelKey}
        title="User Access"
        onClick={() => onToggle(panelKey)}
        open={open}
        actionContent={
          <EditProjectSettingsButtons
            dirty={dirty}
            form={form}
            loading={loading}
            hasSubmissionPrompt={checkSubmission}
            renderSubmissionPrompt={() => setConfirmDrawUpdatesOpen(true)}
          />
        }
        {...props}
      >
        {hasDocumentReviewers && (
          <Pane
            borderBottom="1px solid #d7e0ea"
            marginBottom={majorScale(2)}
            paddingBottom={majorScale(2)}
          >
            <Tablist marginLeft={-minorScale(1)}>
              {USER_ACCESS_TABS.map((tab) => {
                const isSelected = tab === selectedTab;
                return (
                  <Tab
                    key={tab}
                    onSelect={() => setSelectedTab(tab)}
                    isSelected={isSelected}
                    marginRight={majorScale(4)}
                  >
                    {t(`projectSettings.userAccess.tabs.${tab}`)}
                  </Tab>
                );
              })}
            </Tablist>
          </Pane>
        )}
        <Pane minHeight={400}>
          {selectedTab === "PROJECT_ACCESS" && (
            <UserAccessTable
              form={form}
              teams={teams}
              hasSuggestedDocumentAssignees={hasSuggestedDocumentAssignees}
              hasTeamManagement={hasTeamManagement}
            />
          )}
          {selectedTab === "DOCUMENT_APPROVALS" && (
            <EditDocumentReviewers
              users={form.values.users}
              documentReviewerIds={form.values.documentReviewerIds}
              setFieldValue={form.setFieldValue}
            />
          )}
        </Pane>
      </Accordion.Panel>
      {confirmDrawUpdatesOpen && (
        <ConfirmDrawUpdates form={form} onClose={closeDrawUpdates} />
      )}
    </Fragment>
  );
}

EditUserAccessPanel.propTypes = {
  contentStyles: PropTypes.object,
  dirty: PropTypes.bool,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  panelKey: PropTypes.string.isRequired,
  panelStyles: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  setPanelDirty: PropTypes.func,
  titleStyles: PropTypes.object,
};

import gql from "graphql-tag";
import {
  COMMENT_FRAGMENT,
  DASHBOARD_DOCUMENTS_FRAGMENT,
} from "helpers/fragments";

export const ORGANIZATION_QUERY = gql`
  query PortfolioPageChartsQuery {
    organizations {
      id
      productTypes {
        id
        type
      }
      projectRegions {
        id
        region
      }
      projectTemplates {
        id
        name
        tasks {
          id
          name
        }
        customFields {
          id
          label
          type
        }
      }
      teams {
        id
        name
      }
    }
  }
`;

export const TODO_QUERY = gql`
  query PortfolioPageTodoQuery {
    userProfiles {
      id
      pendingDrawApprovals {
        id
        draw {
          id
          requestedAmount
          name
          project {
            id
            name
          }
        }
      }
      documents {
        ...DashboardDocumentsFragment
      }
      documentsPendingApprovalForUser {
        ...DashboardDocumentsFragment
      }
      mentions(dismissed: false) {
        id
        comment {
          project {
            id
            name
          }
          ...CommentFragment
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
  ${DASHBOARD_DOCUMENTS_FRAGMENT}
`;

export const TABLE_QUERY = gql`
  query PortfolioPageTableQuery(
    $sort: SortInput
    $filters: [FilterInput]
    $pagination: PaginationInput
    $includeDocumentInspectionReports: Boolean = false
    $includeLatestDrawInspectionReport: Boolean = false
    $includeContingency: Boolean = false
  ) {
    projects: paginatedProjects(
      sort: $sort
      filters: $filters
      pagination: $pagination
    ) {
      id
      total
      results {
        id
        accountsPayableLastSyncedAt
        accountsPayableSyncErrorMessage
        accountsPayableSyncFailingSince
        acres
        amount
        city
        contingencyOriginalAmount @include(if: $includeContingency)
        contingencySpentAmount @include(if: $includeContingency)
        contingencyPercentRemaining @include(if: $includeContingency)
        customId
        disbursedAmount
        expectedProjectLength
        grossPercentComplete
        hardCostsAmount
        hardCostsGrossPercentComplete
        insertedAt
        interestReservesAmount
        interestReservesUsedAmount
        loanMaturityDate
        name
        organizationId
        percentComplete
        requestedAmount
        setupComplete
        squareFeet
        startDate
        state
        status
        streetAddress
        zip
        createdByUser {
          id
          fullName
        }
        customFields {
          id
          scopeId
          label
          type
          value
        }
        documentInspectionReport
          @include(if: $includeDocumentInspectionReports) {
          id
          expectedCompletionDate
          inspectionDate
          inspectorName
          inspectorNotes
          percentComplete
          vendor {
            id
            name
          }
        }
        drawReviewers {
          id
          fullName
          isPreparer
          isSignatory
        }
        fundingSourceGroups {
          id
          amount
          disbursedAmount
          label
          type
          organization {
            id
            name
          }
        }
        lastDrawFunded {
          id
          name
          fundedDate
        }
        latestDrawInspectionReport
          @include(if: $includeLatestDrawInspectionReport) {
          id
          date
          insertedAt
          inspectorName
          submittedAt
        }
        lineItems {
          id
          scopeId
          budgetAmount
          types
        }
        tasks {
          id
          status
          originalCompletionDate
          projectedCompletionDate
          actualCompletionDate
          completionDateVariance
          projectTypeTaskId
        }
        organization {
          id
          name
        }
        projectRegion {
          id
          region
        }
        productType {
          id
          type
        }
        recentStateUpdate {
          id
          date
        }
        recentDraw {
          id
          reviewState
          reviews {
            id
            processedAt
            reviewer {
              id
              fullName
            }
            state
            isFinal
            isPreparer
          }
        }
        recentUnfundedDraw {
          id
          name
          state
          requestedAmount
          retainageAmount
          fundingConfirmed
          createdAt
          reviews {
            id
            state
            isFinal
          }
        }
        team {
          id
          name
        }
        template {
          id
          icon
          name
        }
        stakeholders {
          id
          role
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export const CARDS_QUERY = gql`
  query PortfolioPageCardsQuery {
    projects(
      filters: [
        {
          field: "status"
          comparator: "IN"
          values: ["active", "pre-development", "under contract"]
        }
      ]
    ) {
      id
      acres
      amount
      city
      coordinates {
        lat
        lng
      }
      disbursedAmount
      setupComplete
      name
      organizationId
      recentUnfundedDraw {
        id
        name
        state
        createdAt
        receivedDate
        submittedDate
        updatedAt
        recentStateUpdate {
          id
          state
          date
        }
      }
      requestedAmount
      squareFeet
      startDate
      state
      status
      streetAddress
      template {
        id
        name
      }
      zip
      fundingSourceGroups {
        id
        amount
        disbursedAmount
        label
        type
        organization {
          id
          name
        }
      }
      picture {
        url
      }
    }
  }
`;

export const UPDATE_USER_VIEW_CONFIG = gql`
  mutation UpdateUserViewConfig(
    $showDashboardCards: Boolean!
    $showDashboardTodos: Boolean!
  ) {
    updateUserViewConfig(
      showDashboardCards: $showDashboardCards
      showDashboardTodos: $showDashboardTodos
    ) {
      id
      showDashboardCards
      showDashboardTodos
    }
  }
`;

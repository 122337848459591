import { useState, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FileUploads } from "components/templates";
import { Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import { UPLOADS_FRAGMENT } from "helpers/fragments";
import { get, findIndex } from "lodash";

export const QUERY = gql`
  query UploadQuery {
    uploads {
      ...UploadsFragment
    }
  }
  ${UPLOADS_FRAGMENT}
`;

const SUBSCRIPTION = gql`
  subscription onUploadUpdated {
    userUploadUpdated {
      ...UploadsFragment
    }
  }
  ${UPLOADS_FRAGMENT}
`;

const HIDE_UPLOADS_MUTATION = gql`
  mutation HideUploads($uploadIds: [String]!) {
    hideUploads(uploadIds: $uploadIds) {
      id
      isHidden
    }
  }
`;

function allDocumentsDeleted(upload) {
  return (
    get(upload, "documents", []).length === 0 &&
    upload.processingFinishedAt &&
    get(upload, "file.type") === "application/pdf"
  );
}

export function FileUploadStatus({ uploadProgress }) {
  const [hiddenErrorUploads, setHiddenErrorUploads] = useState([]);

  const { data, subscribeToMore } = useQuery(QUERY, { key: "upload" });

  const [hide] = useMutation(HIDE_UPLOADS_MUTATION);

  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const data = get(subscriptionData, "data.userUploadUpdated", {});

        if (!data.id) return prev;

        const previousUploads = [...get(prev, "uploads", [])];
        const index = findIndex(previousUploads, ["id", data.id]);

        if (index >= 0) {
          previousUploads[index] = {
            ...get(prev, `uploads[${index}]`),
            ...data,
          };
          return {
            ...prev,
            uploads: previousUploads,
          };
        }

        return {
          ...prev,
          uploads: [data, ...previousUploads],
        };
      },
    });
  }, [subscribeToMore]);

  const uploads = get(data, "uploads", []).filter(
    (upload) =>
      !upload.isHidden &&
      !hiddenErrorUploads.includes(upload.id) &&
      !allDocumentsDeleted(upload)
  );

  if (uploads.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Pane position="fixed" bottom={majorScale(3)} right={100} zIndex={5}>
        <FileUploads
          uploads={uploads}
          uploadProgress={uploadProgress}
          onHide={(uploadIds) => {
            hide({
              variables: {
                uploadIds: [].concat(uploadIds),
              },
            });
          }}
          setHiddenErrorUploads={setHiddenErrorUploads}
        />
      </Pane>
    </Fragment>
  );
}

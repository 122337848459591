import PropTypes from "prop-types";
import { Form, Pane, Paragraph, SearchInput } from "components/materials";
import { majorScale } from "helpers/utilities";
import { COMPARATORS, filterShape, columnShape } from "../FastDataTableUtils";

const SEARCHABLE_COLUMN_TYPES = ["string", "list", "enum"];

function getSearchColumnOptions(visibleStringColumns) {
  return visibleStringColumns.map((column) => ({
    key: column.id,
    value: column.id,
    text: column.header,
  }));
}

const Search = ({
  columns,
  columnConfig,
  filterConfig,
  filterBy,
  placeholder,
}) => {
  const searchFilter = filterConfig.find(({ __isSearch }) => __isSearch);
  const otherFilters = filterConfig.filter(({ __isSearch }) => !__isSearch);
  const primaryColumn = columns.find((column) => column.primary);

  function searchTable(searchColumnId, searchValue) {
    const newFilters = otherFilters.concat({
      key: searchColumnId || primaryColumn.id,
      operator: COMPARATORS.CONTAINS.value,
      input: searchValue || "",
      __isSearch: true,
    });
    filterBy(newFilters);
  }

  const visibleStringColumns = columns.filter(
    (column) =>
      SEARCHABLE_COLUMN_TYPES.includes(column.type) &&
      !column.hidden &&
      column.filterStrategy !== null &&
      !column.disableSearch &&
      columnConfig.includes(column.id)
  );

  const searchIsInactive =
    searchFilter?.key &&
    !visibleStringColumns.find(({ id }) => id === searchFilter?.key);

  // the column being searched on is no longer available becuase
  //   it was removed from view via "Customize Columns", or
  //   changes to permissions have removed access to the column, or
  //   the column no longer exists
  if (searchIsInactive) {
    searchTable(primaryColumn.id, "");
  }

  return (
    <Pane display="flex">
      {visibleStringColumns.length > 1 && (
        <Pane marginTop={-23} marginRight={majorScale(1)}>
          <Paragraph size={300}>Search By:</Paragraph>
          <Form.Select
            value={searchFilter?.key || primaryColumn.id}
            options={getSearchColumnOptions(visibleStringColumns)}
            onChange={(columnId) => searchTable(columnId, searchFilter?.input)}
            width={150}
          />
        </Pane>
      )}
      <SearchInput
        placeholder={placeholder}
        value={searchFilter?.input || ""}
        marginTop={3}
        onChange={(event) => searchTable(searchFilter?.key, event.target.value)}
      />
    </Pane>
  );
};

Search.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  filterConfig: PropTypes.arrayOf(filterShape),
  filterBy: PropTypes.func,
};

export default Search;

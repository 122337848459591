import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import { ExpiredLink } from "components/templates";
import { Loadable } from "components/materials";
import { get } from "lodash";
import { useAuth } from "../../Auth";

const QUERY = gql`
  query SubmissionPageLayoutQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      expired
      hasBeenImported
      user {
        id
        fullName
      }
      sourceDraw {
        id
      }
    }
  }
`;

export function SubmissionPageLayout({ children, match }) {
  const { submissionId } = match.params;

  const { data, error, loading } = useQuery(QUERY, {
    variables: { submissionId },
  });

  const auth = useAuth();

  if (loading) return <Loadable loading />;

  if (!data.submission.expired && auth.isAuthenticated()) {
    return <Redirect to={`/draw_submissions/${submissionId}/import`} />;
  }

  if (data.submission.hasBeenImported && auth.isAuthenticated()) {
    return <Redirect to={`/draw_submissions/${submissionId}/import`} />;
  }

  if (error || !data.submission.sourceDraw || data.submission.expired) {
    return <ExpiredLink sender={get(data, "submission.user.fullName")} />;
  }

  if (!match.isExact) {
    return <Redirect to={`/submissions/${submissionId}`} />;
  }

  return children;
}

SubmissionPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      submissionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

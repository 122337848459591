import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import { SubmissionPreview } from "components/templates";
import { Loadable } from "components/materials";
import {
  DRAW_DIVISIONS_FRAGMENT,
  DRAW_FUNDING_SOURCES_FRAGMENT,
  GUEST_PROJECT_FRAGMENT,
} from "helpers/fragments";

const QUERY = gql`
  query SubmissionPreviewPageQuery($submissionId: String!) {
    submission(submissionId: $submissionId) {
      id
      hasDrawSummary
      latestSubmission {
        id
      }
      export {
        url
      }
      sourceDraw {
        id
        name
        budgetAmount
        requestedAmount
        requestedToDateAmount
        ...DrawDivisionsFragment
        ...DrawFundingSourcesFragment
        project {
          ...GuestProjectFragment
        }
      }
    }
  }
  ${DRAW_DIVISIONS_FRAGMENT}
  ${DRAW_FUNDING_SOURCES_FRAGMENT}
  ${GUEST_PROJECT_FRAGMENT}
`;

const renderContent = () => ({ loading, data }) => {
  if (loading) return <Loadable loading />;

  return <SubmissionPreview submission={data.submission} />;
};

export function SubmissionPreviewPage(props) {
  return (
    <Query query={QUERY} variables={props.match.params}>
      {renderContent()}
    </Query>
  );
}

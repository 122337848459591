import { useState, useContext, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CommentForm, CommentList } from "components/templates";
import { Button, Confirm, Heading, Pane, Sidebar } from "components/materials";
import { majorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { TIMELINE_QUERY, DELETE_TASK, ADD_TASK_COMMENT } from "./graphql";
import { Dependencies } from "./Dependencies";
import { TaskDetails } from "./TaskDetails";

export function TaskSidebar({
  onCloseComplete,
  taskId,
  project,
  tasks,
  onTaskCreated,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { users, id: projectId } = project;
  const isNewTask = taskId === "new";

  const timelineRefetch = [{ query: TIMELINE_QUERY, variables: { projectId } }];

  const [deleteTaskMutation] = useMutation(DELETE_TASK, {
    awaitRefetchQueries: true,
    refetchQueries: timelineRefetch,
  });

  const addCommentMutation = useMutation(ADD_TASK_COMMENT);

  const task = isNewTask ? null : tasks.find(({ id }) => id === taskId);

  const { hasPermission } = useContext(UserContext);

  return (
    <Sidebar isShown onCloseComplete={onCloseComplete}>
      {({ close: closeSidebar }) => (
        <Fragment>
          <TaskDetails
            task={task}
            users={users}
            projectId={projectId}
            onTaskCreated={onTaskCreated}
            isNewTask={isNewTask}
            timelineRefetch={timelineRefetch}
          />

          {!!task && (
            <Fragment>
              {hasPermission(PERMISSION_ACTION.DEPENDENCY_TRACKING) && (
                <Pane
                  marginX={majorScale(3)}
                  borderTop="muted"
                  marginTop={majorScale(1)}
                  paddingTop={majorScale(2)}
                >
                  <Dependencies
                    dependentTask={task}
                    tasks={tasks}
                    timelineRefetch={timelineRefetch}
                    projectId={projectId}
                  />
                </Pane>
              )}
              <Pane
                marginX={majorScale(3)}
                borderTop="muted"
                marginTop={majorScale(3)}
                paddingTop={majorScale(2)}
              >
                <Comments
                  task={task}
                  addCommentMutation={addCommentMutation}
                  users={users}
                />
              </Pane>
              <Pane
                marginX={majorScale(3)}
                borderTop="muted"
                marginY={majorScale(3)}
                paddingTop={majorScale(2)}
              >
                <Button
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                  intent="danger"
                >
                  Delete Task
                </Button>
              </Pane>
            </Fragment>
          )}
          {confirmDelete && (
            <Confirm
              open
              content="Are you sure you would like to delete this task? All of your data related to this task, including comments, will be permanently removed. This action cannot be undone."
              header="Warning"
              onCloseComplete={() => setConfirmDelete(false)}
              onConfirm={(closeConfirm) => {
                deleteTaskMutation({
                  variables: { taskId, projectId },
                }).then(closeConfirm);
                closeSidebar();
              }}
            />
          )}
        </Fragment>
      )}
    </Sidebar>
  );
}

function Comments({ task, addCommentMutation, users }) {
  const [addComment, { loading }] = addCommentMutation;
  const initialValues = {
    body: "",
    projectId: task.projectId,
    taskId: task.id,
  };

  return (
    <Fragment>
      <Heading>Comments</Heading>
      <CommentForm
        initialValues={initialValues}
        mutation={addComment}
        showCommentForm
        users={users}
        loading={loading}
      />
      <Pane marginTop={majorScale(2)}>
        <CommentList comments={task.comments} />
      </Pane>
    </Fragment>
  );
}

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, FormIcon, TickCircleIcon } from "evergreen-ui";
import {
  GanttChart,
  Heading,
  IconButton,
  Link,
  Pane,
  Paragraph,
  ConfigureButtons,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { TASK_STATUS } from "helpers/enums";
import t from "helpers/translate";
import { formatTrimDate } from "helpers/dateHelpers";
import analytics from "helpers/analytics";
import {
  prepareTasksForChart,
  EmptyTimelinePrompt,
} from "../TimelinePage/TimelinePage";

export const TIMELINE_CONFIGURATION_SETTINGS = {
  i: "timeline",
  x: 0,
  y: 4,
  w: 2,
  h: 1,
  disabled: false,
};

export function Timeline({
  isConfigurable,
  project,
  isDisabled,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const projectHasTasks = project.tasks.length > 0;
  const incompleteTasks = project.tasks.filter(
    ({ status }) => status !== TASK_STATUS.COMPLETE
  );
  const preparedTasks = prepareTasksForChart(incompleteTasks);

  return (
    <Pane
      width="100%"
      height={300}
      borderRadius={majorScale(2)}
      paddingY={majorScale(2)}
      paddingX={majorScale(3)}
    >
      <Pane display="flex" justifyContent="space-between">
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Tasks & Timeline
          </Heading>
          <FormIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {preparedTasks.length > 0 && !isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              analytics.track("project dashboard navigate to timeline");
              history.push(`/projects/${project.id}/timeline`);
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
            marginLeft="auto"
          />
        )}
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
      </Pane>
      <TimelineContent
        projectHasTasks={projectHasTasks}
        preparedTasks={preparedTasks}
        project={project}
        history={history}
        isDisabled={isDisabled}
        cards={cards}
        setCards={setCards}
        name={name}
      />
    </Pane>
  );
}

function TimelineContent({ projectHasTasks, preparedTasks, project, history }) {
  const newTaskURL = `/projects/${project.id}/timeline/new`;

  if (!projectHasTasks) {
    return <EmptyTimelinePrompt onAddTask={() => history.push(newTaskURL)} />;
  }

  if (preparedTasks.length === 0) {
    return (
      <Pane display="flex" justifyContent="center" paddingTop={majorScale(8)}>
        <Pane textAlign="center">
          <TickCircleIcon color="success" size={minorScale(5)} />
          <Paragraph
            marginTop={minorScale(1)}
            marginBottom={majorScale(1)}
            size={500}
          >
            All tasks have been completed!
          </Paragraph>
          <Link size={300} to={newTaskURL}>
            Add More Tasks
          </Link>
        </Pane>
      </Pane>
    );
  }

  return <DashboardGantt preparedTasks={preparedTasks} />;
}

function DashboardGantt({ preparedTasks }) {
  React.useEffect(() => {
    /* eslint-disable no-new */
    new GanttChart("#gantt", preparedTasks, {
      view_mode: "Month",
      popup_trigger: "mouseover",
      custom_popup_html: generatePopupString,
    });
  }, [preparedTasks]);

  return (
    <Pane
      height={220}
      maxHeight={220}
      overflowY="auto"
      marginTop={majorScale(2)}
    >
      <svg id="gantt" />
    </Pane>
  );
}

function generatePopupString(task) {
  return `
    <div>
      <p>Owner: ${task.owner || "  N/A"}</p>
      <p>Status: ${t(`taskStatus.${task.status}`)}</p>
      <p>${
        task.status === TASK_STATUS.NOT_STARTED && task.hasProjectedDate
          ? "Projected "
          : ""
      }Start Date: ${formatTrimDate(task.start)}</p>
      <p>Original Start Date: ${formatTrimDate(task.originalStart)}</p>
    </div>
`;
}

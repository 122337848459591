import { useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Button, Divider, Form, Modal, Text } from "components/materials";
import {
  dateFormToServer,
  dateServerToForm,
  isAtOrBefore,
  isBefore,
} from "helpers/dateHelpers";
import { DRAW_STATE } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import t from "helpers/translate";
import { UNLOCK_DRAW } from "./graphql";

export function UnlockDraw({
  draw,
  recentStateUpdate,
  isMostRecentDraw,
  useAdvancedDrawStatus,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const [unlockDraw, { loading: unlockDrawLoading }] = useMutation(
    UNLOCK_DRAW,
    { onCompleted: handleClose }
  );

  const handleSubmit = (values) => {
    unlockDraw({
      variables: { drawId: draw.id, date: dateFormToServer(values.date) },
    });
  };

  const drawState = t(`drawStates.${draw.state}}`);

  return (
    <Formik
      initialValues={{
        date: dateServerToForm(recentStateUpdate?.date || Date.now()),
      }}
      onSubmit={handleSubmit}
      validate={(values) => validateDate(values, recentStateUpdate)}
    >
      {(formikProps) => {
        return (
          <Form>
            <Modal
              open={openModal}
              trigger={
                <Button
                  content="Edit Draw"
                  onClick={handleOpen}
                  marginLeft={majorScale(2)}
                  purpose="unlock-draw open"
                />
              }
              size="small"
              title={`This Draw is Marked ${drawState}`}
              onClose={handleClose}
              onConfirm={formikProps.handleSubmit}
              isConfirmLoading={unlockDrawLoading}
              hasFooter
            >
              <Modal.Content>
                <Text>
                  {t("frozenWarning.confirmMessageLockedDrawIntro", {
                    drawState,
                  })}
                </Text>
                <Divider height={majorScale(1)} />
                {!isMostRecentDraw && draw.state === DRAW_STATE.FUNDED ? (
                  <Text>
                    {t(
                      "frozenWarning.confirmMessageLockedPastFundedDrawPrompt"
                    )}
                  </Text>
                ) : (
                  <Text>
                    {t("frozenWarning.confirmMessageLockedDrawPrompt", {
                      drawState,
                    })}
                  </Text>
                )}
                {useAdvancedDrawStatus && (
                  <Fragment>
                    <Divider height={majorScale(3)} />

                    <Text
                      fontSize={14}
                      fontWeight={400}
                      marginBottom={majorScale(1)}
                    >
                      Reactivated on:
                    </Text>
                    <Form.DateInput
                      name="date"
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true,
                      }}
                    />
                  </Fragment>
                )}
              </Modal.Content>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

function validateDate(values, recentStateUpdate) {
  const errors = {};
  const previousStateDate = recentStateUpdate?.date;

  if (isBlank(values.date)) errors.date = "Date must be selected";

  if (
    previousStateDate &&
    isBefore(new Date(values.date), new Date(previousStateDate))
  ) {
    errors.date = `Selected date must be on or after the last draw status change on ${dateServerToForm(
      previousStateDate
    )}`;
  }

  if (!isAtOrBefore(new Date(values.date), Date.now())) {
    errors.date = `Selected date must be on or before today's date`;
  }

  return errors;
}

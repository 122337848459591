import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import { Field } from "formik";
import { ChevronDownIcon, ChevronRightIcon } from "evergreen-ui";
import {
  Accordion,
  Badge,
  Button,
  Checkbox,
  Form,
  Link,
  Pane,
  Paragraph,
  Table,
  Text,
} from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import { UserContext } from "helpers/behaviors";
import {
  countBy,
  difference,
  find,
  get,
  includes,
  isEmpty,
  values,
  uniq,
  xor,
} from "lodash";
import { stringComparator } from "helpers/comparators";
import t from "helpers/translate";
import * as Permissions from "./Permissions.tsx";

function ProjectAccessSwitch({
  action,
  formikProps,
  hasTeamManagement,
  isRegisteredUser,
  projects,
  reviewerProjectIds,
  setFieldValue,
  signatoryProjectIds,
  teams,
  userId,
}) {
  const AccessComponent = hasTeamManagement
    ? ProjectTeamAccessTable
    : ProjectAccessTable;
  return (
    <Fragment>
      <Permissions.Switch action={action} />
      <AccessComponent
        action={action}
        formikProps={formikProps}
        isRegisteredUser={isRegisteredUser}
        projects={projects}
        reviewerProjectIds={reviewerProjectIds}
        setFieldValue={setFieldValue}
        signatoryProjectIds={signatoryProjectIds}
        teams={teams}
        userId={userId}
      />
    </Fragment>
  );
}

function projectsByTeam(projects, teams) {
  const teamNameById = (id) => {
    const team = find(teams, (team) => team.id === id);
    return get(team, "name", "(No Team Assigned)");
  };

  const sortedProjects = projects.toSorted((a, b) =>
    stringComparator(a.name, b.name)
  );

  const projectsByTeamId = teams.reduce((acc, team) => {
    // create a key for each team, even if they don't have any projects so that a user can be assigned to the project-less team
    acc[team.id] = {
      name: teamNameById(team.id),
      id: team.id,
      projects: [],
    };
    return acc;
  }, {});
  sortedProjects.reduce((acc, project) => {
    const teamId = get(project, "team.id");
    // will catch projects that don't belong to a team
    if (acc[teamId] === undefined) {
      acc[teamId] = {
        name: teamNameById(teamId),
        id: teamId,
        projects: [],
      };
    }
    acc[teamId].projects.push({ ...project });
    return acc;
  }, projectsByTeamId);
  return values(projectsByTeamId).sort((a, b) => {
    if (a.id === undefined) return 1;
    if (b.id === undefined) return -1;
    return stringComparator(a.name, b.name);
  });
}

function ProjectTeamAccessTable({
  formikProps,
  isRegisteredUser,
  projects,
  reviewerProjectIds,
  setFieldValue,
  signatoryProjectIds,
  teams,
}) {
  const { values: formikValues } = formikProps;

  function toggleTeamAccess(teamId, giveAccess) {
    const currentProjectIds = [...formikValues.projectIds];
    const teamProjectIds = projects
      .filter((project) => get(project, "team.id") === teamId)
      .map((project) => project.id);

    // When a team is switched off, reviewer + signatory projectIds need to remain
    const newProjectIds = uniq(
      giveAccess
        ? currentProjectIds.concat(teamProjectIds)
        : difference(currentProjectIds, teamProjectIds).concat([
            ...reviewerProjectIds,
            ...signatoryProjectIds,
          ])
    );
    isEmpty(xor(newProjectIds, formikProps.initialValues.projectIds))
      ? setFieldValue("projectIds", formikProps.initialValues.projectIds)
      : setFieldValue("projectIds", newProjectIds);
  }

  const viewAllProjects =
    formikValues.permissions[PERMISSION_ACTION.VIEW_ALL_PROJECTS];

  const [expandedTeams, setExpandedTeams] = useState([]);

  return (
    <Fragment>
      <Pane marginTop={majorScale(2)} maxWidth={600}>
        <Table hover={false} paddingBottom={0}>
          <Table.Head>
            <Table.Row>
              <Table.TextHeaderCell textProps={{ fontWeight: 700 }}>
                Project
              </Table.TextHeaderCell>
              {isRegisteredUser && (
                <Table.TextHeaderCell
                  textAlign="center"
                  textProps={{ fontWeight: 700 }}
                  width={125}
                >
                  Project Role
                </Table.TextHeaderCell>
              )}
              <Table.TextHeaderCell
                textAlign="center"
                textProps={{ fontWeight: 700 }}
                width={200}
              >
                Access Granted
              </Table.TextHeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {projectsByTeam(projects, teams).map(
              ({ id: teamId, name, projects }) => {
                const teamExpanded = includes(expandedTeams, teamId);
                const numTeamProjectsAccessible = get(
                  countBy(
                    projects,
                    ({ id: projectId }) =>
                      viewAllProjects ||
                      formikValues[`view-all-team-${teamId}`] ||
                      includes(formikValues.projectIds, projectId) ||
                      includes(signatoryProjectIds, projectId) ||
                      includes(reviewerProjectIds, projectId)
                  ),
                  "true",
                  0
                );
                return (
                  <Fragment key={teamId}>
                    <Table.SectionHeader
                      height={majorScale(4)}
                      onClick={() =>
                        setExpandedTeams(xor(expandedTeams, [teamId]))
                      }
                    >
                      <Table.TextSectionHeaderCell>
                        <Pane display="flex">
                          {teamExpanded ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          {name}
                          <Text marginLeft={majorScale(1)} size={300}>
                            {`(${numTeamProjectsAccessible}/${projects.length})`}
                          </Text>
                        </Pane>
                      </Table.TextSectionHeaderCell>
                      {isRegisteredUser && <Table.TextSectionHeaderCell />}
                      <Table.TextSectionHeaderCell>
                        {teamId !== undefined && !viewAllProjects && (
                          <Form.Switch
                            disabled={viewAllProjects}
                            label="View All Team Projects"
                            name={`view-all-team-${teamId}`}
                            onChange={(checked) =>
                              toggleTeamAccess(teamId, checked)
                            }
                            textProps={{ size: 300 }}
                          />
                        )}
                      </Table.TextSectionHeaderCell>
                    </Table.SectionHeader>
                    {teamExpanded &&
                      projects.map(({ id: projectId, name }) => {
                        const isSignatory = includes(
                          signatoryProjectIds,
                          projectId
                        );
                        const isReviewer = includes(
                          reviewerProjectIds,
                          projectId
                        );

                        return (
                          <ProjectRow
                            key={projectId}
                            projectId={projectId}
                            isRegisteredUser={isRegisteredUser}
                            isReviewer={isReviewer}
                            isSignatory={isSignatory}
                            name={name}
                            teamId={teamId}
                            values={formikValues}
                          />
                        );
                      })}
                  </Fragment>
                );
              }
            )}
          </Table.Body>
        </Table>
      </Pane>
    </Fragment>
  );
}

function ProjectRow({
  isRegisteredUser,
  isReviewer,
  isSignatory,
  name,
  projectId,
  teamId,
  values,
}) {
  return (
    <Table.Row>
      <Table.TextCell indented>{name}</Table.TextCell>
      {isRegisteredUser && (
        <Table.Cell width={125} textAlign="Center">
          {isReviewer && (
            <Badge>{isSignatory ? "Signatory" : "Reviewer"}</Badge>
          )}
        </Table.Cell>
      )}
      <Table.Cell width={85}>
        {values.permissions[PERMISSION_ACTION.VIEW_ALL_PROJECTS] ||
        values[`view-all-team-${teamId}`] ? (
          <Checkbox
            disabled
            checked
            justifyContent="center"
            marginY={majorScale(1)}
          />
        ) : (
          <Field
            as={Checkbox}
            disabled={isSignatory || isReviewer}
            justifyContent="center"
            marginY={majorScale(1)}
            name="projectIds"
            type="checkbox"
            value={projectId}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
}

function ProjectAccessTable({
  action,
  isRegisteredUser,
  formikProps,
  projects,
  reviewerProjectIds,
  signatoryProjectIds,
}) {
  const { values: formikValues } = formikProps;
  return (
    <Pane
      borderLeft="muted"
      borderRight="muted"
      borderBottom="muted"
      marginLeft={majorScale(2)}
      marginTop={majorScale(1)}
      width={isRegisteredUser ? 500 : 375}
      maxHeight={300}
      overflowY="scroll"
    >
      <Table paddingBottom={0}>
        <Table.Head>
          <Table.Row>
            <Table.TextHeaderCell>Project</Table.TextHeaderCell>
            {isRegisteredUser && (
              <Table.TextHeaderCell textAlign="center">
                Project Role
              </Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell textAlign="center">
              Access Granted
            </Table.TextHeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {projects.map((project) => {
            const isSignatory = includes(signatoryProjectIds, project.id);
            const isReviewer = includes(reviewerProjectIds, project.id);

            return (
              <Table.Row key={project.id}>
                <Table.TextCell width={250}>{project.name}</Table.TextCell>
                {isRegisteredUser && (
                  <Table.Cell width={125} textAlign="Center">
                    {isReviewer && (
                      <Badge>{isSignatory ? "Signatory" : "Reviewer"}</Badge>
                    )}
                  </Table.Cell>
                )}
                <Table.Cell width={125}>
                  {formikValues.permissions[action] ? (
                    <Checkbox
                      disabled
                      checked
                      justifyContent="center"
                      marginY={majorScale(1)}
                    />
                  ) : (
                    <Field
                      as={Checkbox}
                      disabled={isReviewer}
                      justifyContent="center"
                      marginY={majorScale(1)}
                      name="projectIds"
                      type="checkbox"
                      value={project.id}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Pane>
  );
}

function ApiTokenPanel({
  generateMutation,
  hasPermission,
  isMultiOrgUser,
  userProfiles,
}) {
  const [generateOrgId, setGenerateOrgId] = useState(null);
  const [generateToken, generateTokenResult] = generateMutation;

  return (
    <Fragment>
      {userProfiles.map(
        ({ id, apiToken, organization }) =>
          hasPermission(PERMISSION_ACTION.GENERATE_API_TOKEN, organization) && (
            <Pane key={id} marginBottom={majorScale(2)}>
              {apiToken && (
                <Pane marginBottom={minorScale(1)}>
                  <Text fontWeight={500}>{apiToken}</Text>
                  {isMultiOrgUser && (
                    <Text marginLeft={majorScale(1)}>
                      ({organization.name})
                    </Text>
                  )}
                </Pane>
              )}
              {!apiToken && (
                <Paragraph>
                  {t(isMultiOrgUser ? "noApiTokenMulti" : "noApiTokenDefault", {
                    org: organization.name,
                  })}
                </Paragraph>
              )}

              <Button
                onClick={() => {
                  setGenerateOrgId(organization.id);
                  generateToken({ variables: { userId: id } });
                }}
                height={24}
                isLoading={
                  generateTokenResult.loading &&
                  generateOrgId === organization.id
                }
              >
                Generate new token
              </Button>
            </Pane>
          )
      )}
    </Fragment>
  );
}

function UserForm({
  children,
  formikProps,
  generateMutation = [],
  organizationPermissions,
  projects,
  teams,
  user,
}) {
  const match = useRouteMatch();
  const { userId } = match.params;

  const isRegisteredUser = !!userId;

  const { values: formikValues, setFieldValue } = formikProps;
  const viewAllProjects =
    formikValues.permissions[PERMISSION_ACTION.VIEW_ALL_PROJECTS];

  const signatoryProjectIds = useMemo(() => {
    if (!isRegisteredUser) return [];

    return projects.reduce((projectIds, project) => {
      const isSignatoryOnProject = !!project.drawReviewers.find(
        ({ id, isSignatory }) => id === userId && isSignatory
      );
      return isSignatoryOnProject ? projectIds.concat(project.id) : projectIds;
    }, []);
  }, [isRegisteredUser, projects, userId]);

  const reviewerProjectIds = useMemo(() => {
    if (!isRegisteredUser) return [];

    return projects
      .filter(
        ({ suggestedDocumentAssignees, documentReviewers, drawReviewers }) =>
          find(suggestedDocumentAssignees, { userId }) ||
          find(drawReviewers, { id: userId }) ||
          find(documentReviewers, { userId })
      )
      .map(({ id }) => id);
  }, [isRegisteredUser, projects, userId]);

  const firstRender = useRef(true);

  useEffect(() => {
    // don't set / reset projectIds on initial render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const allProjectIds = projects.map(({ id }) => id);

    // if toggling "View All Projects" OFF, get projectIds for team projects where we have team access + always retain reviwer/signatory projectIds
    // isEmpty(xor) .... re-orders projectIds IF contents are the same as initialValues to satisfy form.dirty
    const newProjectIds = viewAllProjects
      ? allProjectIds
      : uniq(
          [...reviewerProjectIds, ...signatoryProjectIds].concat(
            allProjectIds.filter((projectId) => {
              const teamId = get(
                find(projects, (project) => project.id === projectId),
                "team.id"
              );
              return get(formikValues, `view-all-team-${teamId}`);
            })
          )
        );
    isEmpty(xor(newProjectIds, formikProps.initialValues.projectIds))
      ? setFieldValue("projectIds", formikProps.initialValues.projectIds)
      : setFieldValue("projectIds", newProjectIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAllProjects]);

  const {
    userId: currentUserId,
    hasPermission,
    isMultiOrgUser,
    userProfiles,
  } = useContext(UserContext);
  const hasTeamManagement = hasPermission(PERMISSION_ACTION.TEAM_MANAGEMENT);

  // As we use this component for the `New User` screen, we need to account for
  // when `user` is `undefined`.
  const isSso = !!user?.isSso;

  const panels = [
    {
      key: "permissionsPanel",
      title: "Permissions",
      content: (
        <Fragment>
          {isSso && (
            <Pane marginBottom={majorScale(2)}>
              <Text>{t("editUser.permissionsForSsoUsersAreDisabled")}</Text>
            </Pane>
          )}
          <Permissions.Panel
            marginTop={20}
            formikValues={formikValues}
            setFieldValue={setFieldValue}
            organizationPermissions={organizationPermissions}
            disabled={isSso}
          />
          <Pane marginTop={majorScale(2)}>
            <Link
              href="https://help.rabbet.com/en/articles/4281272-adding-users-managing-permissions"
              purpose="user-form permissions help"
            >
              Click here for more information about what each of these settings
              controls
            </Link>
          </Pane>
        </Fragment>
      ),
    },
    {
      key: "projectAccessPanel",
      title: "Project Access",
      content: (
        <ProjectAccessSwitch
          action={PERMISSION_ACTION.VIEW_ALL_PROJECTS}
          formikProps={formikProps}
          hasTeamManagement={hasTeamManagement}
          isRegisteredUser={isRegisteredUser}
          projects={projects}
          reviewerProjectIds={reviewerProjectIds}
          setFieldValue={setFieldValue}
          signatoryProjectIds={signatoryProjectIds}
          teams={teams}
          userId={userId}
        />
      ),
    },
  ].concat(
    userId === currentUserId &&
      hasPermission(PERMISSION_ACTION.GENERATE_API_TOKEN)
      ? {
          key: "apiTokenPanel",
          title: "API Token",
          content: (
            <ApiTokenPanel
              generateMutation={generateMutation}
              hasPermission={hasPermission}
              isMultiOrgUser={isMultiOrgUser}
              userProfiles={userProfiles}
            />
          ),
        }
      : []
  );

  const defaultKeys = isRegisteredUser
    ? ["permissionsPanel"]
    : ["permissionsPanel", "projectAccessPanel"];

  return (
    <Pane>
      <Form.Input
        label="First name"
        name="firstName"
        marginBottom={majorScale(2)}
      />
      <Form.Input
        label="Last name"
        name="lastName"
        marginBottom={majorScale(2)}
      />
      <Form.Input label="Email" name="email" marginBottom={majorScale(2)} />
      {isSso && (
        <Form.Input
          disabled
          label="SSO Role"
          marginBottom={majorScale(2)}
          name="ssoRole"
        />
      )}
      <Accordion
        defaultActiveKeys={defaultKeys}
        contentStyles={{
          paddingX: minorScale(3),
          paddingY: minorScale(3),
        }}
        panelStyles={{ paddingY: minorScale(3) }}
        headerStyles={{ size: 400 }}
        panels={panels}
        marginTop={-minorScale(3)}
      />
      {children}
    </Pane>
  );
}

UserForm.propTypes = {
  generateMutation: PropTypes.array,
  organizationPermissions: PropTypes.object.isRequired,
  projects: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default UserForm;

import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { VendorLink } from "components/templates";
import { ApplicationIcon, ArrowRightIcon } from "evergreen-ui";
import {
  Link,
  Pane,
  Paragraph,
  Text,
  Heading,
  IconButton,
  ConfigureButtons,
} from "components/materials";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { compact, find, get } from "lodash";
import { formatNumber } from "helpers/formatNumber";
import { ORGANIZATION_TYPE, PERMISSION_ACTION } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import analytics from "helpers/analytics";

export const PROJECT_DETAILS_CONFIGURATION_SETTINGS = {
  i: "projectDetails",
  x: 0,
  y: 0,
  w: 1,
  h: 1,
  disabled: false,
};

export function ProjectDetails({
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const { hasPermission, isDeveloper } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const canEditSettings = hasPermission(
    PERMISSION_ACTION.EDIT_PROJECT_SETTINGS
  );
  const canAccessStakeholders = hasPermission(
    PERMISSION_ACTION.ACCESS_STAKEHOLDERS
  );
  const canAccessFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );

  const borrower = find(project.stakeholders, {
    role: ORGANIZATION_TYPE.BORROWER,
  });
  const generalContractor = find(project.stakeholders, {
    role: ORGANIZATION_TYPE.CONTRACTOR,
  });

  const isImportedGC = !get(project, "organization.vendors", []).some(
    ({ id }) => id === get(generalContractor, "organization.id")
  );

  return (
    <Pane
      height="100%"
      width="100%"
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
    >
      <Pane display="flex" flexDirection="column">
        <Pane
          marginBottom={minorScale(3)}
          display="flex"
          borderBottom={`1px solid ${theme.colors.gray100}`}
          justifyContent="space-between"
        >
          <Heading
            maxWidth="50%"
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            paddingBottom={majorScale(2)}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Project Details
            <ApplicationIcon color="muted" marginLeft={minorScale(3)} />
          </Heading>
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={name}
            />
          )}
          {canEditSettings && !isConfigurable && (
            <IconButton
              icon={ArrowRightIcon}
              onClick={() => {
                analytics.track("project dashboard navigate to projectDetails");
                history.push(
                  `/projects/${project.id}/settings?settings=details`
                );
              }}
              appearance="minimal"
              border={`1px solid ${theme.colors.gray400}`}
            />
          )}
        </Pane>
        <Pane paddingBottom={minorScale(2)} display="flex" alignItems="start">
          <Paragraph flex="0 0 152px" size={400}>
            Address
          </Paragraph>
          <Paragraph color={theme.colors.text} size={400}>
            {project.streetAddress || "-"}
          </Paragraph>
        </Pane>
        <Pane paddingBottom={minorScale(2)} display="flex" alignItems="start">
          <Paragraph flex="0 0 152px" size={400}>
            City, State, ZIP
          </Paragraph>
          <Paragraph color={theme.colors.text} size={400}>
            {project.city || project.state || project.zip
              ? compact([project.city, project.state, project.zip]).join(", ")
              : "-"}
          </Paragraph>
        </Pane>
        <Pane paddingBottom={minorScale(2)} display="flex" alignItems="start">
          <Paragraph flex="0 0 152px" size={400}>
            Square Footage
          </Paragraph>
          <Paragraph color={theme.colors.text} size={400}>
            {formatNumber(project.squareFeet || "-")}
          </Paragraph>
        </Pane>
        {canAccessStakeholders && (
          <Pane display="flex">
            <Paragraph flex="0 0 152px" size={400}>
              Stakeholders
            </Paragraph>
            <Pane>
              <Pane>
                <Paragraph fontWeight={500} size={400}>
                  General Contractor
                </Paragraph>
                {generalContractor ? (
                  <Pane>
                    {isImportedGC ? (
                      <Text color={theme.colors.text} size={400} lineHeight={1}>
                        {generalContractor.organization.name}
                      </Text>
                    ) : (
                      <VendorLink
                        limit={10}
                        linkText={generalContractor.organization.name}
                        noIcon
                        vendorId={generalContractor.organization.id}
                        vendors={[generalContractor.organization]}
                      />
                    )}
                  </Pane>
                ) : (
                  <Paragraph lineHeight={1.2}>-</Paragraph>
                )}
              </Pane>
              {!isDeveloper && canAccessFundingSources && (
                <Pane>
                  <Paragraph
                    marginTop={minorScale(1)}
                    fontWeight={500}
                    size={400}
                  >
                    Borrower
                  </Paragraph>
                  {borrower ? (
                    <VendorLink
                      linkText={borrower.organization.name}
                      noIcon
                      vendorId={borrower.organization.id}
                      vendors={[borrower.organization]}
                    />
                  ) : (
                    <Paragraph lineHeight={1.2}>-</Paragraph>
                  )}
                </Pane>
              )}
              {canEditSettings && (
                <Pane
                  marginTop={minorScale(2)}
                  display="flex"
                  alignItems="center"
                >
                  <Link
                    purpose="project-settings stakeholders manage"
                    fontWeight={500}
                    size={400}
                    to={{
                      pathname: `/projects/${project.id}/settings`,
                      search: "?settings=stakeholders",
                    }}
                  >
                    View all stakeholders
                  </Link>
                  <ArrowRightIcon
                    color={theme.colors.link}
                    marginLeft={minorScale(1)}
                  />
                </Pane>
              )}
            </Pane>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

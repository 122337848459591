import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Pane } from "components/materials";
import { get } from "lodash";
import { Comment } from "./Comment";
import { ADD_COMMENT } from "./graphql";
import { PROJECT_DASHBOARD_QUERY } from "../../graphql";
import { NewCommentForm } from "./NewCommentForm";

export function ProjectChat({ project, currentUserId }) {
  const history = useHistory();
  const comments = get(project, "comments", []).slice().reverse();

  const addCommentMutation = useMutation(ADD_COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROJECT_DASHBOARD_QUERY,
        variables: { projectId: project.id, userId: currentUserId },
      },
    ],
  });

  return (
    <Pane width="100%">
      <Pane>
        {comments.map((comment) => (
          <React.Fragment key={comment.id}>
            <Comment
              history={history}
              key={comment.id}
              comment={comment}
              projectId={project.id}
            />
          </React.Fragment>
        ))}
      </Pane>
      <Pane position="fixed" bottom="70px" width="492px" zIndex={1000}>
        <NewCommentForm
          project={project}
          addCommentMutation={addCommentMutation}
        />
      </Pane>
    </Pane>
  );
}

import { Fragment, useContext } from "react";
import {
  Button,
  Divider,
  Heading,
  ListItem,
  Pane,
  Text,
  UnorderedList,
  Wizard,
} from "components/materials";
import { SendDrawContext } from "contexts/sendDrawContext";
import t from "helpers/translate";
import { get } from "lodash";

export function ConfirmationStep() {
  const { onDone, stakeholders } = useContext(SendDrawContext);
  return (
    <Fragment>
      <Wizard.Content>
        <Heading is="h4" content="Your draw has been sent to:" />
        <UnorderedList>
          {stakeholders.map((stakeholder) => {
            const email = get(stakeholder, "member.email", "");
            const name = get(stakeholder, "member.name", "");
            return <ListItem key={email}>{`${name} (${email})`}</ListItem>;
          })}
        </UnorderedList>
        <Divider />
        <Text>{t("submitDraw.confirmation")}</Text>
      </Wizard.Content>
      <Wizard.Actions hideBack hideNext>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            purpose="project-wizard confirm"
            content="Done"
            onClick={onDone}
          />
        </Pane>
      </Wizard.Actions>
    </Fragment>
  );
}

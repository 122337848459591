import { Fragment } from "react";
import { NotifyDocumentAssignees } from "components/containers";
import {
  BulkDocumentToolbar,
  PendingApprovalsBadge,
} from "components/templates";
import { Link, Pane, Text } from "components/materials";
import { FastDataTableAdvancedControls } from "components/materials/FastDataTable";
import { majorScale } from "helpers/utilities";
import { flatten } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import t from "helpers/translate";

export function getControls(
  controlsProps,
  isDocumentCards,
  isReport,
  propsDocumentActions,
  propsEditTableViews,
  rightControls,
  theme,
  documents,
  match,
  hasPermission,
  hasOrgLevelPermission,
  users,
  suggestedDocumentAssignees,
  tableName,
  draws,
  drawId,
  projectId,
  documentReviewersByProject,
  pinnedFilters
) {
  if (isDocumentCards) {
    const preparedItemCount = controlsProps.preparedItems.groups
      ? flatten(Object.values(controlsProps.preparedItems.groups)).length
      : controlsProps.preparedItems.length;

    const totalCount = controlsProps.items.length;

    return (
      <Pane
        background={theme.colors.lightPurple}
        borderBottom
        display="flex"
        justifyContent="space-between"
        padding={majorScale(1)}
      >
        <Text color={theme.colors.gray800} fontWeight={theme.fontWeights.DEMI}>
          {t("documentsViewer.sidebarFilterHeader", {
            selectedCount: preparedItemCount,
            count: totalCount,
          })}
        </Text>

        {preparedItemCount < totalCount && (
          <Link
            purpose="documents-sidebar show-all"
            onClick={() => controlsProps.filterBy([])}
          >
            Show All
          </Link>
        )}
      </Pane>
    );
  }

  if (isReport) {
    return (
      <FastDataTableAdvancedControls
        {...controlsProps}
        {...propsEditTableViews}
        isReport={isReport}
        pinnedFilters={pinnedFilters}
        searchPlaceholder="Search Documents..."
      />
    );
  }

  const documentReviewers = documentReviewersByProject[projectId] || [];

  return (
    <Fragment>
      <BulkDocumentToolbar
        {...propsDocumentActions}
        selectedItems={controlsProps.selectedItems}
        tableName={tableName}
        users={users}
        suggestedDocumentAssignees={suggestedDocumentAssignees}
        onComplete={() => controlsProps.setSelectedItems([])}
        draws={draws}
        drawId={drawId}
        documentReviewers={documentReviewers}
      >
        {(propsBulkDocumentToolbar) => (
          <FastDataTableAdvancedControls
            {...controlsProps}
            {...propsEditTableViews}
            {...propsBulkDocumentToolbar}
            rightControls={(defaultRightControls) => [
              defaultRightControls,
              rightControls,
            ]}
            searchPlaceholder="Search Documents..."
          >
            {hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS) &&
              // do not show the badge if an ordered workflow is configured - we don't make a determination about when a document is "approved" in that case
              documentReviewers.length === 0 && (
                <PendingApprovalsBadge documents={documents} />
              )}
            {hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) && (
              <NotifyDocumentAssignees match={match} />
            )}
          </FastDataTableAdvancedControls>
        )}
      </BulkDocumentToolbar>
    </Fragment>
  );
}

import { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  ContentLayout,
  OrganizationSelector,
  VendorsTable,
} from "components/templates";
import { Loadable, Sidebar } from "components/materials";
import {
  AGREEMENT_DOC_TABLE_FRAGMENT,
  DOCUMENT_TABLE_FRAGMENT,
  DRAW_SUMMARY_DOC_TABLE_FRAGMENT,
  INVOICE_DOC_TABLE_FRAGMENT,
  LIEN_RELEASE_DOC_TABLE_FRAGMENT,
  PAY_APPLICATION_DOC_TABLE_FRAGMENT,
} from "helpers/fragments";
import { getSearchByKey, removeKey } from "helpers/queryStringHelpers";
import { UserContextProvider } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import unformatNumber from "helpers/unformatNumber";
import { get, findKey } from "lodash";
import { VendorSidebar } from "./VendorSidebar";
import { NewVendorModal } from "./NewVendorModal";
import { ScopeOrganizations } from "./ScopeOrganizations";
import { Document } from "./Document";

function mapOrganizationType(type) {
  const types = {
    accountant: "Accountant",
    architect: "Architect",
    borrower: "Borrower",
    broker: "Broker",
    civilEngineer: "Civil Engineer",
    client: "Client",
    consultant: "Consultant",
    contractor: "General Contractor",
    developer: "Developer",
    developmentManager: "Development Manager",
    engineer: "Engineer",
    equityPartner: "Equity Partner",
    inspector: "Inspector",
    lawyer: "Lawyer",
    lender: "Lender",
    municipality: "Municipality",
    other: "Other",
    siteContact: "Site Contact",
    subcontractor: "Subcontractor",
    titleCompany: "Title Company",
    vendor: "Vendor",
  };

  return findKey(types, (val) => val === type) || type;
}

const VENDOR_FIELD_LOOKUP = {
  agreementAmountTotal: {
    name: "committed_amount",
    filterFormat: (config) => unformatNumber(config.input),
  },
  city: {
    name: "city",
    filterFormat: (config) => config.input,
  },
  emailAddress: {
    name: "email_addresses",
    filterFormat: (config) => config.input,
  },
  isPayable: {
    name: "is_payable",
  },
  is1099: {
    name: "is_1099",
  },
  type: {
    name: "type",
    filterFormat: (config) => {
      return config.key === "type" && config.operator === "EXACT"
        ? mapOrganizationType(config.input)
        : config.input;
    },
  },
  paidToDate: {
    name: "paid_to_date_amount",
    filterFormat: (config) => unformatNumber(config.input),
  },
  phoneNumber: {
    name: "phone_numbers",
    filterFormat: (config) => config.input,
  },
  projects: {
    name: "projects",
    filterFormat: (config) => config.input,
  },
  state: {
    name: "state",
    filterFormat: (config) => config.input,
  },
  streetAddress: {
    name: "street_address",
    filterFormat: (config) => config.input,
  },
  vendorCostCode: {
    name: "vendor_cost_code",
    filterFormat: (config) => config.input,
  },
  vendorName: {
    name: "name",
    filterFormat: (config) => config.input,
  },
  zip: {
    name: "zip",
    filterFormat: (config) => config.input,
  },
};

function mapOperatorToComparator(operator) {
  switch (operator) {
    case "GREATER_THAN":
      return ">";
    case "LESS_THAN":
      return "<";
    case "EQUAL":
      return "=";
    case "NOT_EQUAL":
      return "!=";
    case "CONTAINS":
      return "LIKE";
    case "NOT_CONTAINS":
      return "NOT_LIKE";
    case "IS_EMPTY":
      return "IS_BLANK_OR_IS_NULL";
    case "IS_NOT_EMPTY":
      return "IS_NOT_BLANK_AND_IS_NOT_NULL";
    case "EXACT":
      return "=";
    default:
      return "=";
  }
}

function mapConfigToParams(config, fieldLookup = {}) {
  return {
    filters: config.filterConfig.map((filterConfig) => ({
      field: fieldLookup[filterConfig.key].name || filterConfig.key,
      comparator: mapOperatorToComparator(filterConfig.operator),
      value: fieldLookup[filterConfig.key].filterFormat(filterConfig),
    })),
    sort: config.sortConfig.direction
      ? {
          field:
            fieldLookup[config.sortConfig.columnId].name ||
            config.sortConfig.columnId,
          direction: config.sortConfig.direction,
        }
      : {},
    pagination: {
      ...config.pageConfig,
    },
  };
}

const QUERY = gql`
  query VendorsPageInnerQuery(
    $organizationId: String!
    $sort: SortInput
    $filters: [FilterInput]
    $pagination: PaginationInput
    $ready: Boolean
  ) {
    organization(id: $organizationId) {
      id
      name
      paginatedVendors(sort: $sort, filters: $filters, pagination: $pagination)
        @include(if: $ready) {
        id
        total
        results {
          id
          city
          committedAmount
          emailAddresses
          is1099
          isPayable
          name
          paidToDateAmount
          phoneNumbers
          state
          streetAddress
          type
          vendorCostCode
          zip
          projects {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET = gql`
  query VendorsPageInnerGet($organizationId: String!, $vendorId: String!) {
    organization(id: $organizationId) {
      id
      vendors {
        id
        members {
          id
          email
        }
      }
      vendor(id: $vendorId) {
        id
        is1099
        isPayable
        isPlaceholderVendor
        name
        type
        streetAddress
        city
        state
        zip
        emailAddresses
        phoneNumbers
        vendorCostCode
        aliases
        antialiases
        canDelete
        members {
          id
          name
          email
          phone
          isUsedAsStakeholder
        }
        projects {
          id
          name
          amount
        }
        documents {
          ...DocumentTableFragment
          ...DrawSummaryDocTableFragment
          ...InvoiceDocTableFragment
          ...LienReleaseDocTableFragment
          ...PayApplicationDocTableFragment
          ...AgreementDocTableFragment
        }
      }
    }
  }
  ${DOCUMENT_TABLE_FRAGMENT}
  ${DRAW_SUMMARY_DOC_TABLE_FRAGMENT}
  ${INVOICE_DOC_TABLE_FRAGMENT}
  ${LIEN_RELEASE_DOC_TABLE_FRAGMENT}
  ${PAY_APPLICATION_DOC_TABLE_FRAGMENT}
  ${AGREEMENT_DOC_TABLE_FRAGMENT}
`;

export const VendorsPageInner = ({
  history,
  match,
  onOrganizationSelected,
  organizations,
  selectedOrganization,
}) => {
  const { documentId, projectId, vendorId } = match.params;

  const sidebarOpen = !!vendorId && !documentId;
  const creatingNewVendor = vendorId === "new";

  const referrerOrgId = getSearchByKey(history, "referrerSelectedOrgId");

  const multiOrgNeedToChangeOrg =
    referrerOrgId && referrerOrgId !== selectedOrganization.id;

  const listVendorsQuery = useQuery(QUERY, {
    skip: multiOrgNeedToChangeOrg,
    variables: { organizationId: selectedOrganization.id },
  });
  const getVendorQuery = useQuery(GET, {
    skip: creatingNewVendor || multiOrgNeedToChangeOrg,
    variables: { organizationId: selectedOrganization.id, vendorId },
  });

  if (multiOrgNeedToChangeOrg) {
    removeKey(history, "referrerSelectedOrgId");
    onOrganizationSelected({ id: referrerOrgId });
    return <Loadable loading />;
  }

  const handleOpenSidebar = (id) => {
    const config = getSearchByKey(history, "table");
    const queryStringParams = config ? `?table=${config}` : "";
    history.replace(`/organizations/${id}${queryStringParams}`);
  };
  const handleCloseSidebar = () => {
    const config = getSearchByKey(history, "table");
    if (config) {
      history.replace(`/organizations?table=${config}`);
    } else if (!documentId) {
      history.replace("/organizations");
    }
  };

  const navigateToProject = (project) =>
    history.push(`/projects/${project.id}`);

  const navigateToDocument = (document) =>
    history.push(
      `/organizations/${vendorId}/projects/${document.project.id}/documents/${document.id}`
    );

  const renderTable = (query) => {
    if (query.loading) return <Loadable loading />;

    const { name, paginatedVendors: vendors } = get(
      query,
      "data.organization",
      {
        name: undefined,
        paginatedVendors: { total: 0, results: [] },
      }
    );
    return (
      <ContentLayout
        bleedContent
        title={
          <OrganizationSelector
            fallbackTitle={name ? `${name} Organizations` : "Loading..."}
            title="Viewing organizations for"
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            onOrganizationSelected={onOrganizationSelected}
          />
        }
      >
        <VendorsTable
          history={history}
          selectedOrganization={selectedOrganization}
          setVendorId={handleOpenSidebar}
          vendorCount={vendors.total}
          vendors={vendors.results}
          onConfigChange={(config) => {
            query.refetch({
              ...mapConfigToParams(config, VENDOR_FIELD_LOOKUP),
              ready: true,
            });
          }}
        />
      </ContentLayout>
    );
  };

  const vendorContainerProps = {
    closeSidebar: handleCloseSidebar,
    selectedCustomerAccount: selectedOrganization,
  };

  return (
    <Fragment>
      {renderTable(listVendorsQuery)}
      {creatingNewVendor ? (
        <NewVendorModal
          {...vendorContainerProps}
          history={history}
          refetchQuery={listVendorsQuery.refetch}
        />
      ) : (
        <Sidebar isShown={sidebarOpen} onCloseComplete={handleCloseSidebar}>
          <VendorSidebar
            {...vendorContainerProps}
            deleteRefetchQuery={listVendorsQuery.refetch}
            navigateToDocument={navigateToDocument}
            navigateToProject={navigateToProject}
            showDeleteOrganizationButton
            query={getVendorQuery}
            vendorId={vendorId}
            organizations={organizations}
            refetchQueries={[
              {
                query: GET,
                variables: {
                  organizationId: selectedOrganization.id,
                  vendorId,
                },
              },
            ]}
          />
        </Sidebar>
      )}
      {documentId && projectId && (
        <UserContextProvider organization={selectedOrganization}>
          <Document
            documents={get(
              getVendorQuery,
              "data.organization.vendor.documents",
              []
            ).filter(
              (document) =>
                document.project.id === projectId &&
                !get(document, "upload.toBeSplit")
            )}
            history={history}
            match={match}
          />
        </UserContextProvider>
      )}
    </Fragment>
  );
};

export function VendorsPage({ history, match }) {
  return (
    <ScopeOrganizations
      scopeToUserPermission={PERMISSION_ACTION.VIEW_ORGANIZATIONS}
    >
      {({ onOrganizationSelected, organizations, selectedOrganization }) => (
        <VendorsPageInner
          history={history}
          match={match}
          onOrganizationSelected={onOrganizationSelected}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
        />
      )}
    </ScopeOrganizations>
  );
}

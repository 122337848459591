import {
  useContext,
  useState,
  useLayoutEffect,
  useEffect,
  Fragment,
} from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { find, flatMap, get, set, sortBy, throttle } from "lodash";
import { Formik } from "formik";
import { css } from "glamor";
import {
  Button,
  Confirm,
  Form,
  Pane,
  Paragraph,
  Portal,
} from "components/materials";
import { EditableBudgetTable } from "components/templates";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import isBlank from "helpers/isBlank";
import { add } from "helpers/math";
import t from "helpers/translate";
import { getHasProjection } from "helpers/projectionHelpers";
import { areWordsEqual } from "helpers/stringHelpers";
import { majorScale } from "helpers/utilities";
import {
  EVENTS,
  getItemsToDelete,
  getItemsToUpsert,
  OPERATIONS,
} from "./utils";

const ORG_NAV_HEIGHT = 68;

const UPDATE_BUDGET_ITEMS = gql`
  mutation UpdateBudgetItems(
    $projectId: String!
    $divisionIdsToDelete: [String]!
    $lineItemIdsToDelete: [String]!
    $divisionUpdates: [divisionUpdateInput]!
    $lineItemUpdates: [lineItemUpdateInput]!
  ) {
    updateBudgetItems(
      projectId: $projectId
      divisionIdsToDelete: $divisionIdsToDelete
      lineItemIdsToDelete: $lineItemIdsToDelete
      divisionUpdates: $divisionUpdates
      lineItemUpdates: $lineItemUpdates
    ) {
      id
    }
  }
`;

function isLineItemWithExpenses(lineItem, draws) {
  return !!draws.find((draw) =>
    get(draw, "lineItems", []).find(
      ({ id, expenses }) => id === lineItem.id && expenses.length > 0
    )
  );
}

function hasExistingReferences(lineItem, draws) {
  return (
    lineItem.adjustmentsAmount > 0 ||
    lineItem.committedAmount > 0 ||
    lineItem.disbursedToDateAmount > 0 ||
    lineItem.exposedAmount > 0 ||
    lineItem.grossRequestedToDateAmount > 0 ||
    lineItem.retainageToDateAmount > 0 ||
    isLineItemWithExpenses(lineItem, draws)
  );
}

function initialValues(project) {
  const lineItems = sortBy(get(project, "lineItems", []), "position");

  const divisions = lineItems.reduce((formattedDivisions, lineItem) => {
    const formattedLineItem = {
      ...lineItem,
      amount: lineItem.originalBudgetAmount,
      disabled: hasExistingReferences(lineItem, get(project, "draws", [])),
      superLineItem: lineItem.superLineItem?.name,
    };
    set(
      formattedDivisions,
      lineItem.division.position,
      formattedDivisions[lineItem.division.position]
        ? {
            ...formattedDivisions[lineItem.division.position],
            lineItems: [
              ...formattedDivisions[lineItem.division.position].lineItems,
              formattedLineItem,
            ],
          }
        : {
            ...lineItem.division,
            lineItems: [formattedLineItem],
          }
    );

    return formattedDivisions;
  }, []);

  return { divisions };
}

function validate(values) {
  const errors = {};

  values.divisions.forEach((division, divisionIndex, divisionArray) => {
    if (
      divisionArray.some(
        ({ name, id }) =>
          areWordsEqual(name, division.name) && id !== division.id
      )
    ) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names must be unique"
      );
    }
    if (division.name.length > 60) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Division names cannot be longer than 60 characters"
      );
    }
    if (isBlank(division.name)) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please enter a division name"
      );
    }
    if (division.lineItems.length === 0) {
      set(
        errors,
        `divisions.${divisionIndex}.name`,
        "Please create line items for this division"
      );
    }
    division.lineItems.forEach((lineItem, lineItemIndex, lineItemsArray) => {
      if (
        lineItemsArray.some(
          ({ name, id }) =>
            areWordsEqual(name, lineItem.name) && id !== lineItem.id
        )
      ) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Line item names within a division must be unique"
        );
      }
      if (isBlank(lineItem.name)) {
        set(
          errors,
          `divisions.${divisionIndex}.lineItems.${lineItemIndex}.name`,
          "Please enter a line item name"
        );
      }
    });
  });
  return errors;
}

function getExistingIdAndTypeObject(project) {
  return project.lineItems.reduce((ids, lineItem) => {
    const idsWithLineItemId = set(ids, lineItem.id, "lineItem");
    return set(idsWithLineItemId, lineItem.division.id, "division");
  }, {});
}

function determineNewPositioning(
  values,
  initialValues,
  setIdAndOperationObject
) {
  const initialLineItems = flatMap(initialValues.divisions, (d) => d.lineItems);

  values.divisions.forEach((division) => {
    const initialDivisionPosition = get(
      find(initialValues.divisions, (d) => d.id === division.id),
      "position"
    );

    if (initialDivisionPosition !== division.position) {
      setIdAndOperationObject((operationObject) =>
        set(operationObject, division.id, OPERATIONS.UPSERT)
      );
    }

    division.lineItems.forEach((lineItem) => {
      const initialLineItem = find(
        initialLineItems,
        (li) => li.id === lineItem.id
      );
      const initialLineItemPosition = get(initialLineItem, "position");
      const initialLineItemDivisionId = get(initialLineItem, "division.id");

      if (
        initialLineItemPosition !== lineItem.position ||
        initialLineItemDivisionId !== lineItem.division.id
      ) {
        setIdAndOperationObject((operationObject) =>
          set(operationObject, lineItem.id, OPERATIONS.UPSERT)
        );
      }
    });
  });

  return values.divisions;
}

function BudgetSaveConfirmation({
  propsFormik,
  setShowConfirmation,
  showFundingSourceWarning,
  showProjectionWarning,
}) {
  const header =
    showFundingSourceWarning && showProjectionWarning ? "Warnings" : "Warning";
  return (
    <Confirm
      open
      content={
        <Pane>
          {showFundingSourceWarning && (
            <Paragraph marginBottom={majorScale(2)}>
              {t("fundingSources.newLineItems")}
            </Paragraph>
          )}
          {showProjectionWarning && (
            <Paragraph>{t("projection.budgetChangeWarning")}</Paragraph>
          )}
        </Pane>
      }
      header={header}
      onConfirm={(close) => {
        close();
        propsFormik.handleSubmit();
      }}
      onCloseComplete={() => setShowConfirmation(false)}
      confirmLabel="Save"
      cancelLabel="Cancel"
    />
  );
}

export function BudgetForm({
  closeEditBudget,
  project,
  setShowEditBudgetAnimation,
  showEditBudgetAnimation,
  refetchQueries,
}) {
  const { hasPermission } = useContext(UserContext);
  const [mutationComplete, setMutationComplete] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hasNewLineItem, setHasNewLineItem] = useState(false);
  const [
    hasChangeAffectingProjection,
    setHasChangeAffectingProjection,
  ] = useState(false);

  const hasProjection = getHasProjection(project);

  useLayoutEffect(() => {
    setTimeout(() => setShowEditBudgetAnimation(true), 0);
  }, [setShowEditBudgetAnimation]);

  useEffect(() => {
    if (mutationComplete) closeEditBudget();
  }, [closeEditBudget, mutationComplete]);

  const editBudgetAnimationClassName = css({
    overflow: "scroll",
    transition: showEditBudgetAnimation
      ? "transform .35s ease-out"
      : "transform .35s ease-in",
    transform: showEditBudgetAnimation
      ? "translate(0, 0%)"
      : "translate(0, 100%)",
  });

  const [idAndOperationObject, setIdAndOperationObject] = useState({});
  const [updateBudgetItems, updateBudgetItemsResult] = useMutation(
    UPDATE_BUDGET_ITEMS,
    {
      onCompleted: () => setMutationComplete(true),
      refetchQueries,
    }
  );
  const existingIdAndTypeObject = getExistingIdAndTypeObject(project);
  const isLoading = get(updateBudgetItemsResult, "loading");

  function handleSubmit(values) {
    const initial = initialValues(project);
    const projectId = project.id;
    const [divisionIdsToDelete, lineItemIdsToDelete] = getItemsToDelete(
      existingIdAndTypeObject,
      idAndOperationObject
    );
    const itemsWithNewPositions = determineNewPositioning(
      values,
      initial,
      setIdAndOperationObject
    );
    const [divisionsToUpsert, lineItemsToUpsert] = getItemsToUpsert(
      itemsWithNewPositions,
      idAndOperationObject
    );

    if (
      divisionIdsToDelete.length > 0 ||
      lineItemIdsToDelete.length > 0 ||
      divisionsToUpsert.length > 0 ||
      lineItemsToUpsert.length > 0
    ) {
      updateBudgetItems({
        variables: {
          projectId,
          divisionIdsToDelete,
          lineItemIdsToDelete,
          divisionUpdates: divisionsToUpsert,
          lineItemUpdates: lineItemsToUpsert,
        },
      });
    } else {
      setMutationComplete(true);
    }
  }

  function onDivisionEvent(division, event) {
    if (event === EVENTS.ADD) {
      division.lineItems.forEach(({ id }) => {
        setIdAndOperationObject((idOperations) =>
          set(idOperations, id, OPERATIONS.UPSERT)
        );
      });
    }
    if (event === EVENTS.ADD || event === EVENTS.UPDATE) {
      setIdAndOperationObject((idOperations) =>
        set(idOperations, division.id, OPERATIONS.UPSERT)
      );
    }
    if (event === EVENTS.DELETE) {
      setIdAndOperationObject((idOperations) => {
        const divisionExisted = !!existingIdAndTypeObject[division.id];
        if (divisionExisted) {
          const operationsWithRemovedDivision = set(
            idOperations,
            division.id,
            OPERATIONS.DELETE
          );
          return division.lineItems.reduce((operations, lineItem) => {
            const lineItemExisted = !!existingIdAndTypeObject[lineItem.id];
            if (lineItemExisted) {
              return set(operations, lineItem.id, OPERATIONS.DELETE);
            }
            delete operations[lineItem.id];
            return operations;
          }, operationsWithRemovedDivision);
        }
        // Remove divisions from the operations object that were added during this form session
        delete idOperations[division.id];
        division.lineItems.forEach((lineItem) => {
          delete idOperations[lineItem.id];
        });
        return idOperations;
      });
    }
  }

  function onLineItemEvent(lineItemId, event) {
    if (event === EVENTS.ADD || event === EVENTS.UPDATE) {
      setIdAndOperationObject((idOperations) =>
        set(idOperations, lineItemId, OPERATIONS.UPSERT)
      );
    }
    if (event === EVENTS.DELETE) {
      const lineItemExisted = !!existingIdAndTypeObject[lineItemId];
      setIdAndOperationObject((idOperations) => {
        if (lineItemExisted) {
          return set(idOperations, lineItemId, OPERATIONS.DELETE);
        }
        // Remove line items from the operations object that were added during this form session
        delete idOperations[lineItemId];
        return idOperations;
      });
    }
  }

  const showProjectionWarning = hasProjection && hasChangeAffectingProjection;
  const showFundingSourceWarning =
    !!project?.usesOfFundsEnabled && hasNewLineItem;

  return (
    <Portal>
      <Pane
        className={editBudgetAnimationClassName}
        backgroundColor="white"
        position="fixed"
        top={ORG_NAV_HEIGHT}
        bottom={0}
        left={0}
        right={0}
        zIndex="4"
      >
        <Formik
          initialValues={initialValues(project)}
          onSubmit={handleSubmit}
          validate={throttle(validate, 1000)}
          validateOnMount
        >
          {(propsFormik) => {
            return (
              <Form
                onReset={() => {
                  propsFormik.handleReset();
                  setIdAndOperationObject({});
                  setHasChangeAffectingProjection(false);
                  setHasNewLineItem(false);
                }}
              >
                {!mutationComplete && (
                  <NavigationWarnings dirty={propsFormik.dirty} />
                )}
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  marginY={majorScale(1)}
                  marginRight={majorScale(2)}
                >
                  {propsFormik.dirty ? (
                    <Fragment>
                      <Button
                        disabled={isLoading}
                        purpose="project-budget edit undo"
                        type="reset"
                      >
                        Undo Changes
                      </Button>
                      <Button
                        appearance="primary"
                        disabled={isLoading}
                        marginLeft={majorScale(1)}
                        onClick={() =>
                          showProjectionWarning || showFundingSourceWarning
                            ? setShowConfirmation(true)
                            : propsFormik.handleSubmit()
                        }
                        purpose="project-budget edit submit"
                      >
                        Save Changes
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      onClick={closeEditBudget}
                      purpose="project-budget edit cancel"
                    >
                      Cancel
                    </Button>
                  )}
                </Pane>
                <EditableBudgetTable
                  additionalColumns={[
                    {
                      name: "Budget Adjustments",
                      value: (lineItem) =>
                        add(
                          lineItem.adjustmentsAmount,
                          lineItem.previousAdjustmentsAmount
                        ),
                    },
                    {
                      name: "Current Budget",
                      value: (lineItem) => {
                        return add(
                          lineItem.amount,
                          lineItem.adjustmentsAmount,
                          lineItem.previousAdjustmentsAmount
                        );
                      },
                    },
                    {
                      name: "Gross Amount Requested",
                      value: ({ grossRequestedToDateAmount }) =>
                        grossRequestedToDateAmount,
                    },
                    {
                      name: "Retainage",
                      value: ({ retainageToDateAmount }) =>
                        retainageToDateAmount,
                    },
                    {
                      name: "Net Amount Requested",
                      value: ({ requestedToDateAmount }) =>
                        requestedToDateAmount,
                    },
                    {
                      name: "Gross Amount Requested",
                      value: ({ grossRequestedToDateAmount }) =>
                        grossRequestedToDateAmount,
                    },
                    {
                      name: "Retainage",
                      value: ({ retainageToDateAmount }) =>
                        retainageToDateAmount,
                    },
                    {
                      name: "Net Amount Requested",
                      value: ({ requestedToDateAmount }) =>
                        requestedToDateAmount,
                    },
                    {
                      name: "Amount Disbursed",
                      value: ({ disbursedToDateAmount }) =>
                        disbursedToDateAmount,
                    },
                  ]}
                  onDivisionEvent={onDivisionEvent}
                  onLineItemEvent={onLineItemEvent}
                  propsFormik={propsFormik}
                  readOnlyAmount={
                    !hasPermission(
                      PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT
                    )
                  }
                  hideLineItemNumberToggle
                  setHasChangeAffectingProjection={
                    setHasChangeAffectingProjection
                  }
                  setHasNewLineItem={setHasNewLineItem}
                />
                {showConfirmation && (
                  <BudgetSaveConfirmation
                    showFundingSourceWarning={showFundingSourceWarning}
                    showProjectionWarning={showProjectionWarning}
                    propsFormik={propsFormik}
                    setShowConfirmation={setShowConfirmation}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Pane>
    </Portal>
  );
}

import { useContext, Fragment } from "react";
import { ArrowRightIcon } from "evergreen-ui";
import {
  Button,
  Card,
  Heading,
  Link,
  MentionsBody,
  Pane,
  Paragraph,
  Table,
  Text,
} from "components/materials";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION, RULE_TYPE } from "helpers/enums";
import { reject } from "lodash";
import { majorScale } from "helpers/utilities";
import { formatDateTime } from "helpers/dateHelpers";
import {
  getSearchByKey,
  mergeSearch,
  removeKey,
} from "helpers/queryStringHelpers";
import { DrawAssessment } from "./DrawAssessment";
import { getPlainTextRuleLabel } from "../../templates/DrawRules/RuleLabel";

export function Comments({ draw, history }) {
  const { hasPermission } = useContext(UserContext);
  const showDrawAssessment = !!getSearchByKey(history, "showDrawAssessment");
  const enableDrawAssessmentSidebar =
    hasPermission(PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM) &&
    draw.questions.length > 0;

  const showAllComments = !!getSearchByKey(history, "showAllComments");

  const toggleShowAllComments = () =>
    showAllComments
      ? removeKey(history, "showAllComments")
      : mergeSearch(history, { showAllComments: true });

  // Hide Comments with no target (deleted line item from budget, deleted doc, etc.)
  const comments = reject(draw.comments, (comment) => comment.target === null);

  const visibleComments = showAllComments ? comments : comments.slice(0, 5);
  const hasComments = comments.length > 0;

  return (
    <Fragment>
      <Pane borderBottom paddingY={majorScale(3)}>
        <Pane paddingX={majorScale(4)}>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            marginBottom={majorScale(3)}
          >
            <Pane display="flex">
              <Heading
                fontSize={16}
                fontWeight={600}
              >{`Comments: ${comments.length}`}</Heading>
              <Text color="muted" fontSize={12} marginLeft={majorScale(2)}>
                This is a list of all comments made on the draw, including
                comments on Rules, Documents, and Line Items.
              </Text>
            </Pane>
            {hasComments && (
              <Link size={300} onClick={toggleShowAllComments}>
                {showAllComments ? "View Recent" : "View All"}
              </Link>
            )}
          </Pane>
          {hasComments ? (
            <Card>
              <Table paddingBottom={0}>
                <Table.Head>
                  <Table.Row>
                    <Table.TextHeaderCell>Comment</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Location</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Commenter</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Created At</Table.TextHeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {visibleComments.map((comment) => {
                    const targetProps = getTargetProps(comment.target, history);

                    return (
                      <Table.Row
                        key={comment.id}
                        onClick={() => targetProps.onNavigate()}
                      >
                        <Table.TextCell>
                          <MentionsBody
                            comment={comment}
                            textProps={{ marginRight: 3, size: 300 }}
                          />
                        </Table.TextCell>
                        <Table.TextCell maxWidth={325}>
                          {targetProps.text}
                        </Table.TextCell>
                        <Table.TextCell maxWidth={175}>
                          {comment.author.fullName}
                        </Table.TextCell>
                        <Table.TextCell width={150}>
                          {formatDateTime(comment.insertedAt)}
                        </Table.TextCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          ) : (
            <Paragraph>No comments have been left on this draw.</Paragraph>
          )}
          {enableDrawAssessmentSidebar && (
            <Button
              iconAfter={ArrowRightIcon}
              marginTop={majorScale(3)}
              onClick={() => mergeSearch(history, { showDrawAssessment: true })}
            >
              Complete Draw Assessment
            </Button>
          )}
        </Pane>
      </Pane>
      {enableDrawAssessmentSidebar && showDrawAssessment && (
        <DrawAssessment
          draw={draw}
          closeDrawAssessment={() => removeKey(history, "showDrawAssessment")}
        />
      )}
    </Fragment>
  );
}

function getTargetProps(target, history) {
  switch (target.__typename) {
    case "BasicDocument": {
      return {
        text: target.file.name,
        onNavigate: () => {
          const route = target.drawId
            ? `/projects/${target.projectId}/draws/${target.drawId}/documentation/${target.id}`
            : `/projects/${target.projectId}/documentation/${target.id}`;
          history.push(route);
        },
      };
    }
    case "LineItem": {
      return {
        text: target.name,
        onNavigate: () => {
          const route = `/projects/${target.projectId}/draws/${target.drawId}/line_items/${target.id}`;
          history.push(route);
        },
      };
    }
    case "RuleTransition": {
      return {
        text:
          target.rule.type === RULE_TYPE.AUTOMATED
            ? getPlainTextRuleLabel(target.rule)
            : target.rule.name,

        onNavigate: () => {
          mergeSearch(history, { ruleId: target.rule.id });
        },
      };
    }
    case "Rule": {
      return {
        text:
          target.type === RULE_TYPE.AUTOMATED
            ? getPlainTextRuleLabel(target)
            : target.name,
        onNavigate: () => {
          mergeSearch(history, { ruleId: target.id });
        },
      };
    }
    default: {
      return {};
    }
  }
}

import { Fragment } from "react";
import {
  Button,
  Divider,
  Heading,
  ListItem,
  Pane,
  Text,
  UnorderedList,
  Wizard,
} from "components/materials";
import t from "helpers/translate";

export function ConfirmationStep({ onDone, recipients }) {
  return (
    <Fragment>
      <Wizard.Content>
        <Heading is="h4" content="Your draw has been sent to:" />
        <UnorderedList>
          {recipients.map(({ name, email }) => (
            <ListItem key={email}>{`${name} (${email})`}</ListItem>
          ))}
        </UnorderedList>
        <Divider />
        <Text>{t("submitReport.confirmation")}</Text>
      </Wizard.Content>
      <Wizard.Actions hideBack hideNext>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            purpose="submit-report confirm"
            content="Done"
            onClick={onDone}
          />
        </Pane>
      </Wizard.Actions>
    </Fragment>
  );
}

// TODO: remove dot notation
ConfirmationStep.title = () => t("submitReport.headerDone");

import { Formik } from "formik";
import { Button, Form, Pane } from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import isBlank from "helpers/isBlank";
import analytics from "helpers/analytics";
import { css } from "glamor";

export function NewCommentForm({ addCommentMutation, project }) {
  const [addComment, addCommentResult] = addCommentMutation;
  const { users } = project.organization;

  const onCommentSubmit = (mutation) => (values, formikBag) => {
    mutation({ variables: values });
    formikBag.resetForm();
    analytics.track("Comment Created", {
      hasMentions: !!values.body.match(
        /@\[[^\]]*?\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\)/
      ),
    });
  };

  const darkBlue = "#06033A";

  return (
    <Formik
      initialValues={{ body: "", projectId: project.id }}
      onSubmit={onCommentSubmit(addComment)}
    >
      {(form) => {
        return (
          <Pane flex="0 0 auto">
            <Form.MentionsTextArea
              allowSuggestionsAboveCursor
              name="body"
              className="rbt-comment-form"
              data-testid="comment-box"
              users={users}
            />
            <Pane
              textAlign="right"
              marginBottom={minorScale(1)}
              marginTop={-majorScale(2)}
            >
              <Button
                appearance="minimal"
                background="#06033A"
                color="white"
                content="Add Comment"
                disabled={isBlank(form.values.body)}
                isLoading={addCommentResult.loading}
                onClick={form.handleSubmit}
                type="submit"
                purpose="comment form submit"
                id="commentSubmit"
                {...css({
                  "&#commentSubmit:hover": {
                    backgroundColor: darkBlue,
                  },
                })}
              />
            </Pane>
          </Pane>
        );
      }}
    </Formik>
  );
}

import { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { BlankSlate } from "components/templates";
import { Loadable, Pane } from "components/materials";
import { get, debounce } from "lodash";
import { UserContext } from "helpers/behaviors";
import { tablePlaceholder } from "images";
import { getSearchByKey, mergeSearch } from "helpers/queryStringHelpers";
import { DrawLineItemTable } from "./DrawLineItemTable";
import { Meta } from "./DrawBudgetPageMeta";
import {
  DRAW_BUDGET_PAGE_QUERY,
  DOCUMENT_SUBSCRIPTION,
  ISSUES_SUBSCRIPTION,
  SUBMISSION,
} from "./graphql";

export function DrawBudgetPage({ history, match }) {
  const { drawId, projectId, submissionId } = match.params;
  const viewConfig = getSearchByKey(history, "view");
  const [showSuperLineItems, setShowSuperLineItems] = useState(
    viewConfig === "summary"
  );

  const initialUpdate = useRef(true);
  useEffect(() => {
    if (initialUpdate.current === true) {
      initialUpdate.current = false;
    } else {
      mergeSearch(history, {
        view: showSuperLineItems ? "summary" : "detailed",
      });
    }
  }, [history, showSuperLineItems]);

  const { hasPermission, organizationId } = useContext(UserContext);
  const baseUrl = submissionId
    ? `/submissions/${submissionId}/overview`
    : `/projects/${projectId}/draws/${drawId}`;

  const query = submissionId ? SUBMISSION : DRAW_BUDGET_PAGE_QUERY;
  const variables = submissionId ? { submissionId } : { drawId, projectId };

  const { data, loading, refetch, subscribeToMore } = useQuery(query, {
    variables,
  });

  const updateQuery = useMemo(
    () => debounce(refetch, 5000, { maxWait: 30000 }),
    [refetch]
  );

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { drawId },
    });
  }, [drawId, subscribeToMore]);

  useSubscription(DOCUMENT_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) =>
      get(subscriptionData, "data.projectDocumentUpdated.draw.id") === drawId &&
      updateQuery(),
    variables: { projectId },
  });

  const draw = get(
    data,
    submissionId ? "submission.sourceDraw" : "project.draw"
  );
  const project = get(
    data,
    submissionId ? "submission.sourceDraw.project" : "project"
  );

  const fundingSourceGroups = get(draw, "fundingSources", []);
  const lineItems = get(draw, "lineItems", []);
  const projectProgressValues = {
    secondValue: get(draw, "requestedAmount"),
    total: get(project, "amount"),
    value: get(draw, "requestedToDateAmount"),
  };

  if (loading) return <Loadable loading />;

  if (lineItems.length === 0) {
    return (
      <BlankSlate
        attached
        prompt="Add budget"
        src={tablePlaceholder}
        to={`/projects/${projectId}/budget`}
      />
    );
  }

  return (
    <Pane>
      <Meta
        fundingSourceGroups={fundingSourceGroups}
        hasPermission={hasPermission}
        projectProgressValues={projectProgressValues}
        setShowSuperLineItems={setShowSuperLineItems}
        showSuperLineItems={showSuperLineItems}
        lineItems={lineItems}
      />
      <DrawLineItemTable
        adjustments={draw.budgetAdjustments}
        baseUrl={baseUrl}
        drawId={drawId}
        history={history}
        lineItems={lineItems}
        match={match}
        organizationId={organizationId}
        projectId={projectId}
        projectAcres={project.acres}
        projectSquareFeet={project.squareFeet}
        showSuperLineItems={showSuperLineItems}
      />
    </Pane>
  );
}

import {
  Confirm,
  ListItem,
  Pane,
  Paragraph,
  Text,
  UnorderedList,
} from "components/materials";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

/* If there's only one document name we know the errors are for only it.
 * Otherwise, the Yardi API does not give us a way to associate errors
 * to the document that propduced them.
 */
export function YardiErrorModal({ errors, onCloseComplete }) {
  const modalContent = (
    <Pane>
      <Paragraph>
        {t("paymentIntegration.errorsPostingDocuments", {
          count: errors.messages.length,
        })}
      </Paragraph>
      {errors.docNames.length === 1 && (
        <Paragraph fontWeight={500} marginTop={majorScale(1)}>
          {errors.docNames[0]}
        </Paragraph>
      )}
      <UnorderedList>
        {errors.messages.map((message) => (
          <ListItem>
            <Text>{message}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Pane>
  );

  return (
    <Confirm
      content={modalContent}
      hasCancel={false}
      header="Post Payment - Error"
      onConfirm={onCloseComplete}
      open
      confirmLabel="OK"
    />
  );
}

import { useContext } from "react";
import { get } from "lodash";
import { Pane } from "components/materials";
import { minorScale, ThemeContext } from "helpers/utilities";

export function Footer({ columns, footerProps, rows }) {
  const theme = useContext(ThemeContext);
  const borderStyle = `1px solid ${theme.colors.borderGray}`;

  const cellProps = get(footerProps, "cell", {});
  const rowProps = get(footerProps, "row", { background: "white" });

  return (
    <tfoot style={{ bottom: -1, position: "sticky" }}>
      <tr style={{ height: "48px", ...rowProps }}>
        {columns.map((column, index) => {
          return (
            <td
              key={`cell-${index}`}
              style={{ border: borderStyle, ...cellProps }}
            >
              <Pane paddingX={minorScale(3)}>
                {column.renderFooterCell?.(rows)}
              </Pane>
            </td>
          );
        })}
      </tr>
    </tfoot>
  );
}

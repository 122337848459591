import { v4 as uuid } from "uuid";
import { uniqueId } from "lodash";
import { Item, ItemType, Template } from "../../types";

const document = (): Item => ({
  id: uuid(),
  name: uniqueId("Document "),
  type: ItemType.Document,
});

const template = (): Item => ({
  id: uuid(),
  name: uniqueId("Template "),
  type: ItemType.Template,
});

const section = (): Item => ({
  id: uuid(),
  name: uniqueId("Section "),
  type: ItemType.Section,
  collapsed: false,
  children: [template(), document(), document(), document()],
});

export function drawPackage(): Item[] {
  return [template(), document(), document(), section(), section()];
}

export function sampleDocuments() {
  return [{ name: "Document 1", id: "document-1" }];
}

export function sampleTemplates(): Template[] {
  return [
    { name: "Divider", id: "divider" },
    { name: "Cover Sheet", id: "cover-sheet" },
    { name: "Table of Contents", id: "table-of-contents" },
    { name: "Custom Report", id: uuid() },
  ];
}

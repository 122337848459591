import { useContext } from "react";
import { UserContext } from "helpers/behaviors";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Form, Modal, Pane, Paragraph } from "components/materials";
import { set } from "lodash";
import isBlank from "helpers/isBlank";
import { majorScale } from "helpers/utilities";
import { dateFormToServer } from "helpers/dateHelpers";
import { UPDATE_DRAW_DETAILS } from "./graphql";
import { DrawStateUpdates } from "./DrawStateUpdates";

export function UpdateDraw({
  draw,
  open,
  onClose,
  showDrawStateUpdates,
  canEditDraw,
}) {
  const [updateDraw, { loading }] = useMutation(UPDATE_DRAW_DETAILS, {
    onCompleted: () => {
      onClose();
    },
  });

  const { isDeveloper } = useContext(UserContext);

  function handleSubmit(values) {
    return updateDraw({
      variables: {
        drawId: draw.id,
        name: values.name,
        submittedDate: dateFormToServer(values.submittedDate),
        expectedSendDate: dateFormToServer(values.expectedSendDate),
      },
    });
  }

  return (
    <Formik
      initialValues={{
        name: draw.name,
        submittedDate: draw.submittedDate,
        expectedSendDate: draw.expectedSendDate,
      }}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(form) => (
        <Modal
          confirmLabel="Save"
          hasFooter
          isConfirmLoading={loading}
          onClose={() => {
            form.handleReset();
            onClose();
          }}
          onConfirm={form.handleSubmit}
          open={open}
          size="small"
          title="Update Draw"
        >
          <Modal.Content paddingX={majorScale(2)}>
            {canEditDraw && (
              <Form
                onSubmit={form.handleSubmit}
                error={!!form.error}
                loading={loading}
              >
                <Form.Group>
                  <Form.Input label="Name" name="name" />
                </Form.Group>
                <Form.Group>
                  {isDeveloper ? (
                    <Form.DateInput
                      label="Submission Date"
                      name="expectedSendDate"
                    />
                  ) : (
                    <Form.DateInput
                      label="Submission Date"
                      name="submittedDate"
                    />
                  )}
                </Form.Group>
              </Form>
            )}
            {showDrawStateUpdates && (
              <Pane marginTop={majorScale(5)}>
                <Paragraph marginBottom={majorScale(1)} fontWeight={500}>
                  Draw State Updates
                </Paragraph>
                <DrawStateUpdates
                  draw={draw}
                  onClose={onClose}
                  maxHeight={150}
                />
              </Pane>
            )}
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
}

function validate(values) {
  const errors = {};

  if (isBlank(values.name)) {
    set(errors, "name", "Please enter a name.");
  }

  return errors;
}

import { useContext } from "react";
import { UserContext } from "helpers/behaviors";
import { useMutation } from "@apollo/react-hooks";
import { Button, Pane, Paragraph } from "components/materials";
import { PERMISSION_ACTION } from "helpers/enums";
import { majorScale, minorScale } from "helpers/utilities";
import { formatTrimDate } from "helpers/dateHelpers";
import t from "helpers/translate";
import { REVERT_LAST_DRAW_STATE_UPDATE } from "./graphql";

export function DrawStateUpdates({ draw, onClose, maxHeight }) {
  const [revertStateUpdate] = useMutation(REVERT_LAST_DRAW_STATE_UPDATE, {
    onCompleted: onClose,
  });
  const { hasPermission } = useContext(UserContext);
  const canEditDrawStatus = hasPermission(PERMISSION_ACTION.EDIT_DRAW_STATUS);

  return (
    <Pane>
      <Pane
        paddingLeft={majorScale(2)}
        maxHeight={maxHeight}
        overflow="auto"
        paddingTop={majorScale(1)}
      >
        {draw.drawStateUpdates.map((update, index) => {
          const isLastStateUpdate = index === draw.drawStateUpdates.length - 1;
          return (
            <Pane
              display="flex"
              alignItems="center"
              marginBottom={majorScale(1)}
              height={24}
            >
              <Paragraph
                width={92}
                maxWidth={92}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                marginRight={majorScale(1)}
              >
                {t(`drawStates.${update.state}`)}
              </Paragraph>
              <Paragraph width={100}>{formatTrimDate(update.date)}</Paragraph>
              {update.user && (
                <Paragraph
                  fontStyle="italic"
                  maxWidth={225}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {update.user.fullName}
                </Paragraph>
              )}
              {isLastStateUpdate && canEditDrawStatus && (
                <Button
                  marginLeft="auto"
                  marginRight={minorScale(1)}
                  appearance="minimal"
                  onClick={() =>
                    revertStateUpdate({
                      variables: {
                        drawId: draw.id,
                        updateId: update.id,
                      },
                    })
                  }
                >
                  Delete
                </Button>
              )}
            </Pane>
          );
        })}
      </Pane>
    </Pane>
  );
}

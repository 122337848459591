import { formatCurrency } from "helpers/formatCurrency";

export const formatForInput = (value, options) => {
  if (value === undefined || value === null) {
    return "";
  }

  return formatCurrency(value, options);
};

/**
 * Parses the given value into a server-ready number.
 */
export const parseForServer = (value) => {
  if (value === undefined || value === null || value === "") {
    return null;
  }

  return value;
};

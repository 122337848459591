import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { Button } from "components/materials";
import { ThemeContext } from "helpers/utilities";
import { css } from "glamor";
import {
  DRAW_QUERY as notifyAssigneesDrawQuery,
  PROJECT_QUERY as notifyAssigneesProjectQuery,
} from "./NotifyDocumentAssignees";

const APPROVE = gql`
  mutation ApproveDocuments($documentIds: [String]!) {
    approveDocuments(documentIds: $documentIds) {
      id
      approvedBy {
        id
      }
      approvedUsers {
        id
      }
    }
  }
`;

export function ApproveDocumentButton({
  buttonStyles,
  children = null,
  document,
  onApproveCompleted,
  refetchQueries,
  hoverColor,
}) {
  const theme = useContext(ThemeContext);

  return (
    <Mutation
      awaitRefetchQueries
      mutation={APPROVE}
      variables={{ documentIds: [document.id] }}
      onCompleted={onApproveCompleted}
      refetchQueries={refetchQueries}
    >
      {(mutation, result) => {
        return (
          <Fragment>
            <Button
              appearance="primary"
              content={children ? false : "Approve"}
              intent="success"
              isLoading={result.loading}
              purpose="approve-document approve"
              onClick={(event) => {
                event.stopPropagation();
                mutation();
              }}
              {...css({
                "&#dashApprove:hover": {
                  backgroundColor: hoverColor || theme.colors.gray200,
                },
              })}
              {...buttonStyles}
            >
              {children}
            </Button>
          </Fragment>
        );
      }}
    </Mutation>
  );
}

export function getNotifyAssigneesQueryObject(match) {
  const { drawId, projectId } = match.params;
  return {
    query: drawId ? notifyAssigneesDrawQuery : notifyAssigneesProjectQuery,
    variables: drawId ? { drawId, projectId } : { projectId },
  };
}

ApproveDocumentButton.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  refetchQueries: PropTypes.array,
};

ApproveDocumentButton.defaultProps = {
  refetchQueries: [],
};

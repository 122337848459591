import { createContext, useContext, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import analytics from "helpers/analytics";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { UserContext } from "helpers/behaviors";
import { AddNoteStep } from "../../components/containers/SubmitDraw/AddNoteStep";
import { AddRecipientsStep } from "../../components/containers/SubmitDraw/AddRecipientsStep";
import { PreviewEmailStep } from "../../components/containers/SubmitDraw/PreviewEmailStep";
import { ConfirmationStep } from "../../components/containers/SubmitDraw/ConfirmationStep";
import { AddDocumentsStep } from "../../components/containers/SubmitDraw/AddDocumentsStep";

const DOCUMENTS_QUERY = gql`
  query AddDocumentsStep($organizationId: String!, $projectId: String!, $drawId: String!) {
    organization(id: $organizationId) {
      id
      documentTemplates(type: ${DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET}) {
        id
        name
      }
    }
    project(id: $projectId) {
      id
      draw(id: $drawId) {
        id
        documents {
          id
          type
          file {
            url
            name
            type
            annotations
          }
          upload {
            id
            toBeSplit
          }
          importSource
          isBackup
        }
        drawPackageContents {
          id
          documentId
          section
          templateId
          type
        }
        excludedDrawPackageContents {
          id
          documentId
          section
          type
        }
        hasCustomDrawPackage
      }
      name
    }
  }
`;

const SEND_DRAW_MUTATION = gql`
  mutation SendDrawMutation(
    $drawId: String!
    $notes: String
    $documentIds: [String]
    $drawPackageContents: [DrawPackageContent!]
    $stakeholderIds: [String]
  ) {
    submitDraw(
      drawId: $drawId
      notes: $notes
      documentIds: $documentIds
      drawPackageContents: $drawPackageContents
      stakeholderIds: $stakeholderIds
    ) {
      id
    }
  }
`;

const SAVE_CUSTOM_DRAW_PACKAGE = gql`
  mutation SaveCustomDrawPackage(
    $drawId: String!
    $drawPackageContents: [DrawPackageContent!]
  ) {
    saveCustomDrawPackage(
      drawId: $drawId
      drawPackageContents: $drawPackageContents
    ) {
      status
    }
  }
`;

const DELETE_CUSTOM_DRAW_PACKAGE = gql`
  mutation DeleteCustomDrawPackage($drawId: String!) {
    deleteCustomDrawPackage(drawId: $drawId) {
      status
    }
  }
`;

export const SendDrawContext = createContext({});

export function SendDrawContextProvider({
  children,
  hasSubmissions,
  onCloseConfirm,
  draw,
  projectId,
}) {
  const { organizationId } = useContext(UserContext);
  const [checkForWarnings, setCheckForWarnings] = useState(true);
  const [confirmEnabled, setConfirmEnabled] = useState(true);
  const [drawPackageContents, setDrawPackageContents] = useState([]);
  const [hasCustomDrawPackage, setHasCustomDrawPackage] = useState(false);
  const [notes, setNotes] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [stakeholders, setStakeholders] = useState([]);
  const [stakeholdersSet, setStakeholdersSet] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocumentsSet, setSelectedDocumentsSet] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const {
    data: documentData,
    loading: documentsLoading,
    refetch: refetchDrawPackageContents,
  } = useQuery(DOCUMENTS_QUERY, {
    variables: { drawId: draw.id, projectId, organizationId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      setHasCustomDrawPackage(result?.project?.draw?.hasCustomDrawPackage);
      setDrawPackageContents(result?.project?.draw?.drawPackageContents || []);
    },
  });

  const [
    saveCustomDrawPackage,
    { loading: saveCustomDrawPackageLoading },
  ] = useMutation(SAVE_CUSTOM_DRAW_PACKAGE, {
    onCompleted: () => {
      setHasCustomDrawPackage(true);
    },
  });

  const [
    deleteCustomDrawPackage,
    { loading: deleteCustomDrawPackageLoading },
  ] = useMutation(DELETE_CUSTOM_DRAW_PACKAGE, {
    onCompleted: () => {
      setHasCustomDrawPackage(false);
      refetchDrawPackageContents();
    },
  });

  const [sendDraw, { loading: sendDrawLoading }] = useMutation(
    SEND_DRAW_MUTATION,
    {
      onCompleted: () => {
        analytics.track("Draw Sent", {
          organizationId,
          projectId,
          drawId: draw.id,
        });
        // Whatever step is sending the draw should automatically proceed
        // after the draw has been successfully sent
        handleNext();
      },
    }
  );

  const steps = [
    { step: AddDocumentsStep, title: "Package Draw", size: "medium" },
    { step: AddNoteStep, title: "Send Draw", size: "small" },
    { step: AddRecipientsStep, title: "Send Draw", size: "medium" },
    { step: PreviewEmailStep, title: "Send Draw", size: "large" },
    { step: ConfirmationStep, title: "Send Draw", size: "medium" },
  ];

  function handleCloseConfirm() {
    onCloseConfirm();
  }

  function handleCloseCancel() {
    setOpenConfirm(false);
    setOpenModal(true);
  }

  function handleClose() {
    if (confirmEnabled) {
      setOpenConfirm(true);
      setOpenModal(false);
    } else {
      handleCloseConfirm();
    }
  }

  function handleComplete() {
    setOpenConfirm(false);
    setOpenModal(false);
  }

  function handleBack() {
    setCurrentStepIndex((currentStepIndex) => currentStepIndex - 1);
  }

  function handleNext() {
    setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
  }

  // documents that have not been split are not included in the draw package
  // draw cover sheets are handled separately (see function below)
  function getDocuments(data) {
    const documents = data?.project?.draw?.documents || [];
    return documents.filter(
      ({ type, upload }) =>
        !upload.toBeSplit && type !== DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET
    );
  }

  function getDrawCoverSheets(data) {
    const coverSheetTemplates = (
      documentData?.organization?.documentTemplates || []
    ).map(({ id, name }) => ({
      templateId: id,
      name,
    }));
    const coverSheetDocuments = (data?.project?.draw?.documents || [])
      .filter(
        ({ type, upload }) =>
          !upload.toBeSplit && type === DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET
      )
      .map(({ id, file }) => ({
        id,
        file,
      }));
    return [...coverSheetDocuments, ...coverSheetTemplates];
  }

  const contextData = {
    draw,
    drawCoverSheets: getDrawCoverSheets(documentData),
    documents: getDocuments(documentData),
    drawPackageContents,
    excludedDrawPackageContents:
      documentData?.project?.draw?.excludedDrawPackageContents || [],
    hasCustomDrawPackage,
    organizationId,
    projectId,
    projectName: documentData?.project?.name,
    hasSubmissions,
    hasBack: currentStepIndex > 0,
    checkForWarnings,
    confirmEnabled,
    notes,
    openConfirm,
    openModal,
    stakeholders,
    stakeholdersSet,
    selectedDocuments,
    selectedDocumentsSet,
    currentStepIndex,
    steps,
  };

  const contextStateSetters = {
    setCheckForWarnings,
    setConfirmEnabled,
    setHasCustomDrawPackage,
    setNotes,
    setOpenConfirm,
    setOpenModal,
    setStakeholders,
    setStakeholdersSet,
    setSelectedDocuments,
    setSelectedDocumentsSet,
    setCurrentStepIndex,
  };

  const contextBehaviors = {
    onBack: handleBack,
    onNext: handleNext,
    onDone: handleComplete,
    handleClose,
    handleCloseCancel,
    handleCloseConfirm,
  };

  const contextQueriesLoading = {
    documentsLoading,
  };

  const contextMutations = {
    saveCustomDrawPackage,
    deleteCustomDrawPackage,
    sendDraw,
  };

  const contextMutationsLoading = {
    saveCustomDrawPackageLoading,
    deleteCustomDrawPackageLoading,
    sendDrawLoading,
  };

  return (
    <SendDrawContext.Provider
      value={{
        ...contextBehaviors,
        ...contextData,
        ...contextStateSetters,
        ...contextQueriesLoading,
        ...contextMutations,
        ...contextMutationsLoading,
      }}
    >
      {children}
    </SendDrawContext.Provider>
  );
}

import { useContext, useMemo, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useFeatureFlags } from "FeatureFlags";
import { DocumentTable, PendingUploadsBanner } from "components/templates";
import {
  PendingUploadsBanner as NewPendingUploadsBanner,
  FileUploadModal,
} from "features/uploads";
import { Button, Loadable } from "components/materials";
import { UploadsViewerContext, UserContext } from "helpers/behaviors";
import { formatFastDocuments } from "helpers/documentHelpers";
import { DOCUMENT_TYPE_NAME, PERMISSION_ACTION } from "helpers/enums";
import {
  ASSIGNED_TO_DOC_TABLE_FRAGMENT,
  DOC_REVIEWERS_DOC_TABLE_FRAGMENT,
  FAST_DOCUMENT_FRAGMENT,
  UPLOAD_DOC_TABLE_FRAGMENT,
  MOVE_TO_DRAW_DOC_TABLE_FRAGMENT,
} from "helpers/fragments";
import { minorScale } from "helpers/utilities";
import { getSearchByKey } from "helpers/queryStringHelpers";
import t from "helpers/translate";
import { cloneDeep, findIndex, get, set } from "lodash";
import { UploadFiles } from "./UploadFiles";
import { Document } from "./Document";

const FAST_QUERY = gql`
  query ProjectDocumentsFastQuery(
    $documentType: DocumentTypeName!
    $projectId: String!
  ) {
    project(id: $projectId) {
      id
      name
      ...AssignedToDocTableFragment
      ...DocReviewersDocTableFragment
      ...MoveToDrawDocTableFragment
      fastDocuments {
        id
        ...FastDocumentFragment
      }
      lineItems {
        id
        scopeId
        name
        number
        division {
          id
          scopeId
          name
        }
      }
      organization {
        id
        documentTemplates(type: $documentType) {
          id
          name
        }
      }
      uploads(toBeSplit: true) {
        ...UploadDocTableFragment
      }
      status
    }
  }
  ${ASSIGNED_TO_DOC_TABLE_FRAGMENT}
  ${DOC_REVIEWERS_DOC_TABLE_FRAGMENT}
  ${FAST_DOCUMENT_FRAGMENT}
  ${UPLOAD_DOC_TABLE_FRAGMENT}
  ${MOVE_TO_DRAW_DOC_TABLE_FRAGMENT}
`;

const ISSUES_SUBSCRIPTION = gql`
  subscription projectIssuesUpdated($projectId: String!) {
    projectIssuesUpdated(projectId: $projectId) {
      id
      documents {
        id
        issues {
          id
          name
          severity
        }
      }
    }
  }
`;

const FAST_DOCUMENT_UPDATED_SUBSCRIPTION = gql`
  subscription onDocumentUpdated($projectId: String!) {
    projectFastDocumentUpdated(projectId: $projectId) {
      id
      ...FastDocumentFragment
    }
  }
  ${FAST_DOCUMENT_FRAGMENT}
`;

const FAST_DOCUMENT_REMOVED_SUBSCRIPTION = gql`
  subscription onDocumentRemoved($projectId: String!) {
    projectFastDocumentRemoved(projectId: $projectId)
  }
`;

const UPLOAD_SUBSCRIPTION = gql`
  subscription onUploadUpdated($projectId: String!) {
    projectUploadUpdated(projectId: $projectId) {
      ...UploadDocTableFragment
    }
  }
  ${UPLOAD_DOC_TABLE_FRAGMENT}
`;

export function ProjectDocumentsPage({ history, match }) {
  const featureFlags = useFeatureFlags();
  const useNewUploads = featureFlags.isEnabled("use-upload-refactor");

  const { documentId, projectId } = match.params;

  const { setOpenUploads } = useContext(UploadsViewerContext);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const uploadDocument = getSearchByKey(history, "uploadDocument") === "true";

  useEffect(() => {
    if (uploadDocument) {
      setShowUploadModal(true);
    }
  }, [uploadDocument]);

  const {
    analyticsContext,
    hasOrgLevelPermission,
    hasPermission,
    organizationId,
  } = useContext(UserContext);

  const { data, loading, subscribeToMore } = useQuery(FAST_QUERY, {
    context: {
      ...analyticsContext,
      analyticsMessage: "Project Documents Page",
    },
    variables: { documentType: DOCUMENT_TYPE_NAME.INVOICE, projectId },
    key: projectId,
  });

  const refetch = [
    {
      query: FAST_QUERY,
      variables: { documentType: DOCUMENT_TYPE_NAME.INVOICE, projectId },
    },
  ];

  const lineItems = get(data, "project.lineItems", []);
  const organization = get(data, "project.organization");
  const documentTemplates = get(organization, "documentTemplates", []);
  const projectName = get(data, "project.name");
  const projectStatus = get(data, "project.status");

  const rightControls = useMemo(() => {
    return [
      hasPermission(PERMISSION_ACTION.DOWNLOAD_DOCUMENT, organization) && (
        <Button
          key="viewUploads"
          marginLeft={minorScale(3)}
          purpose="documents uploads open"
          onClick={() => setOpenUploads(null, null, projectId)}
        >
          View Uploads
        </Button>
      ),
      useNewUploads && (
        <Button
          key="newUploadFilesButton"
          onClick={() => setShowUploadModal(true)}
          purpose="add-document open"
          marginLeft={minorScale(3)}
          appearance="primary"
        >
          Add Documents
        </Button>
      ),
      !useNewUploads && (
        <UploadFiles
          buttonProps={{ appearance: "primary", marginLeft: minorScale(3) }}
          text="Add Documents"
          documentTemplates={documentTemplates}
          key="uploadFiles"
          lineItems={lineItems}
          openModal={getSearchByKey(history, "uploadDocument")}
          projectId={projectId}
          projectName={projectName}
          projectStatus={projectStatus}
        />
      ),
    ];
  }, [
    hasPermission,
    organization,
    documentTemplates,
    lineItems,
    history,
    projectId,
    projectName,
    projectStatus,
    setOpenUploads,
    setShowUploadModal,
    useNewUploads,
  ]);

  useEffect(() => {
    return subscribeToMore({
      document: ISSUES_SUBSCRIPTION,
      variables: { projectId },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: FAST_DOCUMENT_UPDATED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedDocument = get(
          subscriptionData,
          "data.projectFastDocumentUpdated",
          {}
        );

        if (!prev || !updatedDocument.id) return prev;
        const previousDocuments = [...get(prev, "project.fastDocuments", [])];
        const index = findIndex(previousDocuments, ["id", updatedDocument.id]);

        if (index >= 0) {
          previousDocuments[index] = {
            ...get(prev, `project.fastDocuments[${index}]`),
            ...updatedDocument,
          };

          return {
            ...prev,
            project: {
              ...prev.project,
              fastDocuments: previousDocuments,
            },
          };
        }
        return {
          ...prev,
          project: {
            ...get(prev, "project", {}),
            fastDocuments: [...previousDocuments, updatedDocument],
          },
        };
      },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: FAST_DOCUMENT_REMOVED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const removedDocumentId = get(
          subscriptionData,
          "data.projectFastDocumentRemoved"
        );

        if (!removedDocumentId) return prev;

        const previousDocuments = [...get(prev, "project.fastDocuments", [])];

        const newDocuments = previousDocuments.filter(
          ({ id }) => id !== removedDocumentId
        );

        return set(cloneDeep(prev), "project.fastDocuments", newDocuments);
      },
    });
  }, [projectId, subscribeToMore]);

  useEffect(() => {
    return subscribeToMore({
      document: UPLOAD_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedUpload = get(
          subscriptionData,
          "data.projectUploadUpdated",
          {}
        );
        if (!updatedUpload.id) return prev;

        const previousUploads = [...get(prev, "project.uploads", [])];
        const foundIndex = findIndex(previousUploads, ["id", updatedUpload.id]);

        //  upload belongs in the list
        if (updatedUpload.toBeSplit) {
          //  if it's already there, updated it
          if (foundIndex >= 0) {
            previousUploads[foundIndex] = {
              ...get(prev, `project.uploads[${foundIndex}]`),
              ...updatedUpload,
            };

            return {
              ...prev,
              project: {
                ...prev.project,
                uploads: previousUploads,
              },
            };
          }
          // otherwise, add it
          return {
            ...prev,
            project: {
              ...get(prev, "project", {}),
              uploads: [updatedUpload, ...previousUploads],
            },
          };
        }

        // upload does not belong in the list and was found - remove it
        if (foundIndex >= 0) {
          return {
            ...prev,
            project: {
              ...prev.project,
              uploads: previousUploads.filter(
                (_upload, index) => index !== foundIndex
              ),
            },
          };
        }

        return prev;
      },
    });
  }, [projectId, subscribeToMore]);

  const draws = get(data, "project.draws", []);
  const tableDocuments = formatFastDocuments(
    get(data, "project.fastDocuments", []).filter(
      ({ parentToBeSplit }) => !parentToBeSplit
    )
  );
  const hasIssues = tableDocuments.some(({ issues }) => issues.length > 0);

  const defaultColumns = ["document"]
    .concat(
      hasPermission(PERMISSION_ACTION.RULES_REDESIGN_CLERICAL) && hasIssues
        ? ["documentIssues"]
        : []
    )
    .concat([
      "type",
      "vendor",
      "currentAmountRequested",
      "uploadedAt",
      "pages",
      "originalFile",
      "status",
      "backup",
    ])
    .concat(
      hasPermission(PERMISSION_ACTION.ASSIGN_DOCUMENTS) ? ["assignedTo"] : []
    )
    .concat(
      hasOrgLevelPermission(PERMISSION_ACTION.APPROVE_DOCUMENTS)
        ? ["approval"]
        : []
    )
    .concat("draw");

  const defaultGroup = { columnId: "draw" };

  if (loading)
    return (
      <Loadable
        loading
        spinnerText={t("loadingText.documentLoading")}
        textDelay={3000}
        timeout={180000}
      />
    );

  return (
    <Fragment>
      {useNewUploads && (
        <NewPendingUploadsBanner
          projectId={projectId}
          uploads={get(data, "project.uploads", [])}
        />
      )}
      {useNewUploads && (
        <FileUploadModal
          key="newUploadFilesModal"
          projectId={projectId}
          open={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSubmit={() => setShowUploadModal(false)}
          targetName={projectName}
        />
      )}
      {!useNewUploads && (
        <PendingUploadsBanner
          projectId={projectId}
          uploads={get(data, "project.uploads", [])}
        />
      )}
      <DocumentTable
        defaultColumns={defaultColumns}
        defaultGroup={defaultGroup}
        documents={tableDocuments}
        documentReviewersByProject={{
          [projectId]: data?.project?.documentReviewers,
        }}
        draws={draws}
        history={history}
        match={match}
        organizationId={organizationId}
        projectId={projectId}
        rightControls={rightControls}
        suggestedDocumentAssignees={data?.project?.suggestedDocumentAssignees}
        tableName="ProjectDocumentTable"
        users={get(data, "project.users")}
      />
      {documentId && (
        <Document
          defaultGroup={defaultGroup}
          documents={tableDocuments}
          history={history}
          match={match}
          organization={organization}
          refetch={refetch}
        />
      )}
    </Fragment>
  );
}

ProjectDocumentsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

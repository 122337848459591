import { useContext } from "react";
import { hover } from "glamor";
import { Pane, Link } from "components/materials";
import { ThemeContext, ArrowRightIcon } from "evergreen-ui";

// onClick can be used for a side effect, i.e. tracking
export function CardLinkButton({ href, onClick = () => {} }) {
  const theme = useContext(ThemeContext);

  return (
    <Pane is={Link} onClick={onClick} to={href}>
      <Pane
        alignItems="center"
        border={`1px solid ${theme.colors.gray400}`}
        borderRadius={4}
        className={hover({
          background: theme.colors.gray100,
          cursor: "pointer",
        })}
        display="flex"
        height={32}
        justifyContent="center"
        width={32}
      >
        <Pane marginTop="5px">
          <ArrowRightIcon color={theme.colors.textGray} />
        </Pane>
      </Pane>
    </Pane>
  );
}

import { Fragment, useContext } from "react";
import { Form, Text, TextArea, Wizard } from "components/materials";
import { SendDrawContext } from "contexts/sendDrawContext";
import t from "helpers/translate";

const CHAR_LIMIT = 500;

export function AddNoteStep() {
  const { hasBack, notes, onNext, setNotes, onBack } = useContext(
    SendDrawContext
  );

  function handleChange(event) {
    const { value } = event.target;
    const notes = value.substring(0, CHAR_LIMIT);
    setNotes(notes);
  }

  return (
    <Fragment>
      <Wizard.Content>
        <Form>
          <Form.Field
            hint={
              <Text>
                {t("submitDraw.noteLimit", {
                  count: CHAR_LIMIT - notes.length,
                  limit: CHAR_LIMIT,
                })}
              </Text>
            }
          >
            <TextArea
              autoFocus
              maxLength={CHAR_LIMIT}
              minRows={5}
              onChange={handleChange}
              placeholder="Add a note for the recipients"
              value={notes}
            />
          </Form.Field>
        </Form>
      </Wizard.Content>
      <Wizard.Actions hideBack={!hasBack} onBack={onBack} onNext={onNext} />
    </Fragment>
  );
}

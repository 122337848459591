import {
  actionsUtilityColumnDefaults,
  bulkSelectUtilityColumnDefaults,
} from "components/materials/FastDataTable";

export function getUtilityColumns(
  isDocumentCards,
  isReport,
  isSubmission,
  canUpdateDocument,
  canDeleteDocuments,
  propsDocumentActions
) {
  const { hasDownloadPermission } = propsDocumentActions;
  const cannotViewDocuments = !hasDownloadPermission;

  if (isDocumentCards || isReport || isSubmission || cannotViewDocuments)
    return undefined;

  const rowActions = [
    {
      label: "Download",
      onClick: (docs) => {
        propsDocumentActions.onDownload(docs.map((doc) => doc.id));
      },
    },
    {
      label: "Download Original",
      onClick: (docs) => {
        docs.map(propsDocumentActions.onDownloadOriginal);
      },
    },
  ];
  if (canUpdateDocument) {
    rowActions.push({
      label: "Ignore",
      onClick: propsDocumentActions.onIgnore(() => {}),
    });
  }

  if (canDeleteDocuments) {
    rowActions.push({
      label: "Delete",
      labelProps: { color: "danger" },
      onClick: propsDocumentActions.onRemove(() => {}),
    });
  }

  return [
    bulkSelectUtilityColumnDefaults,
    actionsUtilityColumnDefaults(rowActions),
  ];
}

import React, { useContext } from "react";
import { ArrowRightIcon, LayoutCircleIcon, MapIcon } from "evergreen-ui";
import {
  Button,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  EmptyCard,
  ConfigureButtons,
} from "components/materials";
import {
  FUNDING_SOURCE_TYPE,
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
} from "helpers/enums";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { add, subtract } from "helpers/math";
import { formatCurrency } from "helpers/formatCurrency";
import { UserContext } from "helpers/behaviors";
import analytics from "helpers/analytics";
import {
  FundingSourcesSummaryHeader,
  FundingSourcesSummaryRow,
  FundingSourcesSummaryTotal,
} from "./FundingSourcesSummaryRow";

function getFundingSourceTotals(fundingSourceGroups) {
  return fundingSourceGroups.reduce(
    (total, source) => {
      return {
        amount: add(total.amount, source.amount),
        disbursedAmount: add(total.disbursedAmount, source.disbursedAmount),
      };
    },
    {
      amount: 0,
      disbursedAmount: 0,
    }
  );
}

function getFundingSourceTotalsByType(fundingSourceGroups, fundingSourceType) {
  return fundingSourceGroups.reduce(
    (total, source) => {
      if (source.type === fundingSourceType) {
        return {
          amount: add(total.amount, source.amount),
          disbursedAmount: add(total.disbursedAmount, source.disbursedAmount),
        };
      }
      return total;
    },
    { amount: 0, disbursedAmount: 0 }
  );
}

function Info({ fundingSourceGroups, project, theme }) {
  const infoGroupStyling = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const infoLabelStyling = {
    fontWeight: 400,
    color: theme.colors.gray700,
  };

  const infoValueStyling = {
    size: 600,
    fontWeight: 400,
  };

  const isDeveloper = project.organization.type === ORGANIZATION_TYPE.BORROWER;
  const fundingSourceType = isDeveloper
    ? FUNDING_SOURCE_TYPE.EQUITY
    : FUNDING_SOURCE_TYPE.LOAN;
  const fundingSourceTotalsByType = getFundingSourceTotalsByType(
    fundingSourceGroups,
    fundingSourceType
  );

  return (
    <Pane
      backgroundColor={theme.colors.blue25}
      borderRadius={majorScale(1)}
      display="flex"
      justifyContent="space-between"
      width="100%"
      height={72}
    >
      <Pane {...infoGroupStyling} paddingLeft={16}>
        <Paragraph {...infoLabelStyling}>
          {isDeveloper ? "Equity to Be Drawn" : "Debt to Be Drawn"}
        </Paragraph>
        <Heading {...infoValueStyling}>
          {formatCurrency(
            subtract(
              fundingSourceTotalsByType.amount,
              fundingSourceTotalsByType.disbursedAmount
            )
          )}
        </Heading>
      </Pane>
      <Pane {...infoGroupStyling} paddingRight={16}>
        <Paragraph {...infoLabelStyling}>
          {isDeveloper ? "Equity Funded to Date" : "Debt Funded to Date"}
        </Paragraph>
        <Heading {...infoValueStyling}>
          {formatCurrency(fundingSourceTotalsByType.disbursedAmount)}
        </Heading>
      </Pane>
    </Pane>
  );
}

function FundingSourceSummary({
  fundingSourceGroups,
  fundingSourceTotals,
  theme,
}) {
  return (
    <Pane width="100%" alignItems="center" justifyContent="space-between">
      <FundingSourcesSummaryHeader theme={theme} />
      <Pane maxHeight={96} overflowY="scroll" width="100%">
        {fundingSourceGroups.map((source) => (
          <FundingSourcesSummaryRow
            key={source.id}
            source={source}
            theme={theme}
          />
        ))}
      </Pane>
      <FundingSourcesSummaryTotal
        fundingSourceTotals={fundingSourceTotals}
        theme={theme}
      />
    </Pane>
  );
}

export const FUNDING_SOURCES_CONFIGURATION_SETTINGS = {
  i: "fundingSources",
  x: 0,
  y: 2,
  w: 1,
  h: 1,
  disabled: false,
};

export function FundingSources({
  history,
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const { hasPermission } = useContext(UserContext);
  const canAccessFundingSources = hasPermission(
    PERMISSION_ACTION.ACCESS_FUNDING_SOURCES
  );
  const hasFundingSources = project.fundingSourceGroups.length > 0;
  const fundingSourceGroups = project.fundingSourceGroups.flat();
  const fundingSourceTotals = getFundingSourceTotals(fundingSourceGroups);

  if (!hasFundingSources) {
    return (
      <EmptyCard
        name="Funding Sources"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<LayoutCircleIcon color="muted" />}
      >
        {canAccessFundingSources ? (
          <React.Fragment>
            <Paragraph paddingBottom={majorScale(1)}>
              No funding sources yet.
            </Paragraph>
            <Paragraph paddingBottom={majorScale(1)}>
              Add a Funding Source and it will show up here.
            </Paragraph>
            <Button
              marginTop={8}
              appearance="primary"
              onClick={() =>
                history.push(
                  `/projects/${project.id}/settings?settings=fundingSources`
                )
              }
            >
              Add Funding Source
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MapIcon size={majorScale(3)} />
            <Paragraph marginTop={majorScale(2)}>
              There are no funding sources for this project yet.
            </Paragraph>
            <Paragraph>
              You do not have access to manage funding sources.
            </Paragraph>
            <Paragraph>Please contact your admin.</Paragraph>
          </React.Fragment>
        )}
      </EmptyCard>
    );
  }

  return (
    <Pane
      width="100%"
      height="100%"
      position="relative"
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            lineHeight={1.2}
            fontWeight={500}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Funding Sources
          </Heading>
          <LayoutCircleIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {canAccessFundingSources && !isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              analytics.track("project dashboard navigate to fundingSources");
              history.push(
                `/projects/${project.id}/settings?settings=fundingSources`
              );
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
            marginLeft="auto"
          />
        )}
      </Pane>
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={minorScale(3)}
      >
        <Info
          project={project}
          fundingSourceGroups={fundingSourceGroups}
          theme={theme}
        />
      </Pane>
      <FundingSourceSummary
        fundingSourceGroups={fundingSourceGroups}
        fundingSourceTotals={fundingSourceTotals}
        theme={theme}
      />
    </Pane>
  );
}

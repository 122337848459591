import { times } from "lodash";
import { DateTime, DateTimeUnit, Interval } from "luxon";

/**
 * Returns an array of Interval objects representing the last n
 * periods, ordered chronologically.
 *
 * @param period a string representing the unit of time to use for the period
 * (e.g. 'day', 'quarter', 'year')
 * @param number the number of periods to return, including the current period
 */
export function getLastPeriods(
  period: DateTimeUnit,
  number: number
): Interval[] {
  const now = DateTime.now();

  return times(number)
    .map((idx) => {
      const start = now.startOf(period).minus({ [period]: idx });
      const end = now.endOf(period).minus({ [period]: idx });

      return Interval.fromDateTimes(start, end);
    })
    .reverse();
}

import gql from "graphql-tag";

export const QUERY = gql`
  query CreateOrganizationModalQuery(
    $organizationId: String!
    $filters: [FilterInput]
    $pagination: PaginationInput
    $ready: Boolean
  ) {
    organization(id: $organizationId) {
      id
      name
      paginatedVendors(filters: $filters, pagination: $pagination)
        @include(if: $ready) {
        id
        total
        results {
          id
          city
          emailAddresses
          name
          phoneNumbers
          state
          streetAddress
          type
          vendorCostCode
          zip
          agreementProjectIds
          members {
            id
            email
            name
            phone
          }
          projects {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE = gql`
  mutation CreateOrganizationModalCreate(
    $email: String
    $streetAddress: String
    $city: String
    $stateValue: AddressState
    $zip: String
    $name: String!
    $phoneNumber: String
    $type: OrganizationType!
    $vendorCostCode: String
    $sourceOrganizationId: String!
    $is1099: Boolean
    $isPayable: Boolean
  ) {
    addOrganization(
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      streetAddress: $streetAddress
      city: $city
      state: $stateValue
      zip: $zip
      type: $type
      vendorCostCode: $vendorCostCode
      sourceOrganizationId: $sourceOrganizationId
      is1099: $is1099
      isPayable: $isPayable
    ) {
      id
      emailAddresses
      name
      streetAddress
      city
      state
      zip
      members {
        id
        email
        name
        phone
      }
      phoneNumbers
      type
      vendorCostCode
      agreementProjectIds
    }
  }
`;

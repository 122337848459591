import { Fragment, useContext, useEffect, useState } from "react";
import { differenceBy } from "lodash";
import { EyeOpenIcon, AddIcon } from "evergreen-ui";
import {
  Button,
  DownloadLink,
  DragAndDrop,
  FileViewer,
  Loadable,
  Modal,
  Pane,
  Paragraph,
  Text,
  Wizard,
} from "components/materials";
import { showDownloadErrorToast } from "components/materials/DownloadLink";
import { SendDrawContext } from "contexts/sendDrawContext";
import { usePreserveDownload } from "hooks/usePreserveDownload";
import { preventEventBubbling } from "helpers/preventEventBubbling";
import analytics from "helpers/analytics";
import { UserContext } from "helpers/behaviors";
import { DOCUMENT_IMPORT_SOURCE, PERMISSION_ACTION } from "helpers/enums";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";

const baseUrl = process.env.REACT_APP_GRAPHQL_HOST;

// Items will either have an id, a templateId, or a section according to their item type
// Documents have "id", draw cover sheets have "templateId", and section markers/invoice contents/table of contents have a "section"
// We use these as the unique identifier to properly diff the excluded/included documents/cover sheets/section markers/etc..
function prepareItemsForDragAndDrop(items, setPreviewFileProps) {
  return items.map((item) => {
    const {
      id,
      importSource,
      isBackup,
      file,
      name,
      section,
      templateId,
    } = item;
    const uniqueId = section || templateId || id;
    const documentId = id || null;
    return {
      action: file ? (
        <PreviewFileIcon
          onClick={(e) => {
            preventEventBubbling(e);
            setPreviewFileProps({ file, documentId });
          }}
        />
      ) : null,
      canRemove: true,
      id: uniqueId,
      importSource,
      isBackup,
      isDraggable: true,
      documentId,
      key: uniqueId,
      section,
      templateId,
      text: getDragAndDropItemText(section, file?.name, name),
      value: uniqueId,
      file,
    };
  });
}

function getDrawPackageContentItems(items, drawPackageContents) {
  return drawPackageContents.reduce((contents, drawPackageContent) => {
    const item = items.find(({ id, section, templateId }) => {
      // Draw package contents will have 1 of 3 fields filled out (section, templateId, or documentId),
      // and we want to make sure that we only try to match on the correct field;
      // otherwise, we will have potentially wrong matches, as it could match on `undefined`
      if (drawPackageContent.section)
        return drawPackageContent.section === section;
      if (drawPackageContent.templateId)
        return drawPackageContent.templateId === templateId;
      if (drawPackageContent.documentId)
        return drawPackageContent.documentId === id;
      return false;
    });

    if (item) return [...contents, item];
    return contents;
  }, []);
}

function getDownloadZipUrl(selectedDocuments) {
  const documentIdString = selectedDocuments
    .map(({ documentId }) => documentId)
    .filter((documentId) => !!documentId)
    .join(",");
  return `${baseUrl}/download_multiple_documents/${documentIdString}`;
}

function getDrawPackageDownloadUrl(drawId) {
  return `${baseUrl}/download_draw_package/draw/${drawId}`;
}

// individual documents will launch the PDFEditor if the correct perms are enabled
// previewing the draw package will always launch the read-only viewer
function FilePreview({ file, onClose, annotationProps }) {
  if (!file.url) return <Loadable loading />;
  return (
    <Modal
      open
      onClose={onClose}
      size="fullscreen"
      title={file.name}
      zIndex={9999}
    >
      <Modal.Content>
        <FileViewer
          annotationProps={annotationProps}
          file={file}
          height="75vh"
        />
      </Modal.Content>
      <Modal.Actions>
        <Pane display="flex" justifyContent="flex-end">
          <Button content="Close" onClick={onClose} />
        </Pane>
      </Modal.Actions>
    </Modal>
  );
}

function PreviewFileIcon({ onClick, ...props }) {
  return (
    <EyeOpenIcon color="info" cursor="pointer" onClick={onClick} {...props} />
  );
}

function getDragAndDropItemText(section, fileName, name) {
  if (fileName) return fileName;
  if (name) return name;
  if (section) return t(`drawPackageContents.${section}`);
  return "";
}

function ExcludedItem({ item, onAdd, setPreviewFileProps }) {
  return (
    <Pane
      cursor="pointer"
      key={item.key}
      onClick={() => onAdd(item)}
      marginBottom={majorScale(1)}
      data-purpose="draw package document add"
    >
      <AddIcon color="info" marginBottom={-2} marginRight={majorScale(2)} />
      {item.file && (
        <PreviewFileIcon
          marginBottom={-2}
          marginRight={majorScale(2)}
          onClick={(e) => {
            preventEventBubbling(e);
            setPreviewFileProps({
              file: item.file,
              documentId: item.documentId,
            });
          }}
        />
      )}
      <Text color="info">{item.text}</Text>
    </Pane>
  );
}

export function AddDocumentsStep() {
  const {
    hasBack,
    onNext,
    deleteCustomDrawPackage,
    deleteCustomDrawPackageLoading,
    draw,
    drawCoverSheets,
    documents: contextDocuments,
    drawPackageContents,
    documentsLoading,
    excludedDrawPackageContents,
    hasCustomDrawPackage,
    organizationId,
    projectId,
    projectName,
    selectedDocuments,
    selectedDocumentsSet,
    setSelectedDocuments,
    setSelectedDocumentsSet,
    saveCustomDrawPackage,
    saveCustomDrawPackageLoading,
  } = useContext(SendDrawContext);

  const {
    errorPreservingDownload,
    preservedDownload,
    preserveDownload,
    onDownload,
    resetPreservedDownload,
    preservingDownload,
  } = usePreserveDownload();

  const { hasPermission } = useContext(UserContext);

  // {file, documentId}
  // draw package preview will not have a documentId
  const [previewFileProps, setPreviewFileProps] = useState(null);

  useEffect(() => {
    if (errorPreservingDownload) {
      showDownloadErrorToast();
      setPreviewFileProps(null);
    }
  }, [errorPreservingDownload]);

  // TODO: generate absinthe enums for this
  const templates = [
    { section: "table_of_contents" },
    ...(hasPermission(PERMISSION_ACTION.SUPER_LINE_ITEMS)
      ? [{ section: "invoice_summary_bli" }, { section: "invoice_summary_sli" }]
      : [{ section: "invoice_summary" }]),
    ...(hasPermission(PERMISSION_ACTION.HUD_FEATURES)
      ? [{ section: "hud_2451" }]
      : []),
    { section: "hard_costs" },
    { section: "soft_costs" },
    { section: "other_documents" },
    { section: "draw_summary" },
    ...(hasPermission(PERMISSION_ACTION.ACCESS_FUNDING_SOURCES)
      ? [{ section: "funding_sources" }]
      : []),
  ];

  const dragAndDropDocuments = prepareItemsForDragAndDrop(
    contextDocuments,
    setPreviewFileProps
  );
  const dragAndDropTemplates = prepareItemsForDragAndDrop(
    templates,
    setPreviewFileProps
  );

  const dragAndDropDrawCoverSheets = prepareItemsForDragAndDrop(
    drawCoverSheets,
    setPreviewFileProps
  );

  const drawPackageFilename = `${projectName} ${draw.name} draw package.pdf`;

  function setDrawPackagePreviewProps(url) {
    setPreviewFileProps({
      file: {
        name: drawPackageFilename,
        type: "application/pdf",
        url,
      },
    });
  }

  const individuallyDownloadableTemplates = [
    "hud_2451",
    "funding_sources",
    "draw_summary",
  ];

  const disableDownloads = !selectedDocuments.some(
    ({ documentId, section }) =>
      !!documentId || individuallyDownloadableTemplates.includes(section)
  );

  function getExcludedDocuments(selectedDocuments) {
    // It is important to emphasize here that the previously excluded/included *documents*
    // are ONLY documents, and are not templates/section markers/cover sheets.
    // Excluded templates / section markers / cover sheets are determined separately below.
    const previouslyExcludedDocuments = getDrawPackageContentItems(
      dragAndDropDocuments,
      excludedDrawPackageContents
    );
    const previouslyIncludedDocuments = getDrawPackageContentItems(
      dragAndDropDocuments,
      drawPackageContents
    );

    const allPreviousDocuments = [
      ...previouslyExcludedDocuments,
      ...previouslyIncludedDocuments,
    ];

    const yardiBackupDocuments = selectedDocuments.filter(
      (document) =>
        document.importSource === DOCUMENT_IMPORT_SOURCE.YARDI &&
        document.isBackup
    );

    // Excluded documents include any document that was originally in the draw package,
    // but is no longer included
    const excludedDocuments = differenceBy(
      allPreviousDocuments,
      selectedDocuments,
      "id"
    ).concat(yardiBackupDocuments);

    // Excluded new documents include any document that was NOT originally in the draw package,
    // and is no longer included
    const excludedNewDocuments = differenceBy(
      dragAndDropDocuments,
      [...selectedDocuments, ...allPreviousDocuments],
      "id"
    );

    // Excluded templates include any template that is no longer included
    const excludedTemplates = differenceBy(
      dragAndDropTemplates,
      selectedDocuments,
      "section"
    );

    const excludedCoverSheetDocuments = differenceBy(
      dragAndDropDrawCoverSheets,
      selectedDocuments,
      "documentId"
    );

    const excludedCoverSheetTemplates = differenceBy(
      dragAndDropDrawCoverSheets,
      selectedDocuments,
      "templateId"
    );

    const excludedDrawCoverSheets = excludedCoverSheetDocuments.concat(
      excludedCoverSheetTemplates
    );

    return {
      excludedDocuments,
      excludedDrawCoverSheets,
      excludedNewDocuments,
      excludedTemplates,
    };
  }

  const {
    excludedDocuments,
    excludedDrawCoverSheets,
    excludedNewDocuments,
    excludedTemplates,
  } = getExcludedDocuments(selectedDocuments);

  useEffect(() => {
    const previousDrawPackageContents = getDrawPackageContentItems(
      [
        ...dragAndDropDocuments,
        ...dragAndDropTemplates,
        ...dragAndDropDrawCoverSheets,
      ],
      drawPackageContents
    );

    const selectedDocumentsFilteredForYardiBackupDocs = selectedDocuments.filter(
      (document) =>
        document.importSource === DOCUMENT_IMPORT_SOURCE.YARDI &&
        !document.isBackup
    );
    const currentDocuments = selectedDocumentsSet
      ? selectedDocumentsFilteredForYardiBackupDocs
      : previousDrawPackageContents;
    setSelectedDocuments(currentDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLoading, drawPackageContents]);

  function onSaveCustomDrawPackage(includedContents) {
    // When the draw package changes, any preserved download is out-of-date
    resetPreservedDownload();
    const { excludedDocuments, excludedNewDocuments } = getExcludedDocuments(
      includedContents
    );
    const allExcludedDocuments = [
      ...excludedDocuments,
      ...excludedNewDocuments,
    ];

    // We don't save excluded draw cover sheets/templates,
    // as we are able to determine the excluded draw cover sheets/templates
    // based on the saved draw package content + the potential options
    const variables = {
      drawId: draw.id,
      drawPackageContents: [
        ...includedContents.map(
          ({ documentId, section, templateId }, index) => ({
            documentId,
            drawId: draw.id,
            templateId,
            order: index,
            section,
          })
        ),
        ...allExcludedDocuments.map(({ documentId, section }) => ({
          documentId,
          drawId: draw.id,
          order: null,
          section,
        })),
      ],
    };

    saveCustomDrawPackage({ variables });
  }

  function handleResetCustomDrawPackageClick() {
    const variables = { drawId: draw.id };
    deleteCustomDrawPackage({ variables });
  }

  function handleIncludeExcludedItem(item) {
    setSelectedDocuments((selectedDocuments) => {
      const newlySelectedDocuments = [...selectedDocuments, item];
      onSaveCustomDrawPackage(newlySelectedDocuments);
      return newlySelectedDocuments;
    });
  }

  function handleIncludeExcludedItemToBeginning(item) {
    setSelectedDocuments((selectedDocuments) => {
      const newlySelectedDocuments = [item, ...selectedDocuments];
      onSaveCustomDrawPackage(newlySelectedDocuments);
      return newlySelectedDocuments;
    });
  }

  function getExcludedItemProps(item, onAdd = handleIncludeExcludedItem) {
    return {
      item,
      onAdd,
      setPreviewFileProps,
    };
  }

  if (documentsLoading) return <Loadable loading />;

  return (
    <Fragment>
      <Wizard.Content overflow="scroll">
        <Pane paddingX={majorScale(2)} paddingBottom={majorScale(2)}>
          <Text>
            Select which documents you would like to package with the draw and
            what order they appear in the Draw Package PDF.
          </Text>
          {hasCustomDrawPackage && (
            <Pane>
              <Paragraph>
                The draw package has been edited from its original
                documents/order.
              </Paragraph>
              <Button
                isLoading={deleteCustomDrawPackageLoading}
                marginRight={majorScale(2)}
                onClick={handleResetCustomDrawPackageClick}
              >
                Reset to Default Draw Package
              </Button>
            </Pane>
          )}
        </Pane>
        <Pane display="flex" maxHeight="40vh">
          <Pane width="50%" paddingX={majorScale(2)}>
            <Pane marginBottom={majorScale(1)}>
              <Text textTransform="uppercase" marginBottom={majorScale(2)}>
                Draw Package
              </Text>
            </Pane>
            {selectedDocuments.length === 0 ? (
              <Text>No documents selected</Text>
            ) : (
              <DragAndDrop
                items={selectedDocuments}
                onUpdate={(newlySelectedDocuments) => {
                  onSaveCustomDrawPackage(newlySelectedDocuments);
                  setSelectedDocuments(newlySelectedDocuments);
                }}
                onRemove={({ value: valueToRemove }) => {
                  const newlySelectedDocuments = selectedDocuments.filter(
                    ({ value }) => value !== valueToRemove
                  );

                  onSaveCustomDrawPackage(newlySelectedDocuments);
                  setSelectedDocuments(newlySelectedDocuments);
                }}
              />
            )}
          </Pane>
          <Pane width="50%" maxHeight={450}>
            <Pane marginBottom={majorScale(2)}>
              <Pane marginBottom={majorScale(1)}>
                <Text textTransform="uppercase">Draw Cover Sheets</Text>
              </Pane>
              {excludedDrawCoverSheets.length === 0 ? (
                <Text>None</Text>
              ) : (
                excludedDrawCoverSheets.map((drawCoverSheet) => (
                  <ExcludedItem
                    {...getExcludedItemProps(
                      drawCoverSheet,
                      handleIncludeExcludedItemToBeginning
                    )}
                  />
                ))
              )}
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <Pane marginBottom={majorScale(1)}>
                <Text textTransform="uppercase">
                  Section Markers / Templates
                </Text>
              </Pane>
              {excludedTemplates.length === 0 ? (
                <Text>None</Text>
              ) : (
                excludedTemplates.map((template) => (
                  <ExcludedItem {...getExcludedItemProps(template)} />
                ))
              )}
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <Pane marginBottom={majorScale(1)}>
                <Text textTransform="uppercase">Excluded Documents</Text>
              </Pane>
              {excludedDocuments.length === 0 ? (
                <Text>None</Text>
              ) : (
                excludedDocuments.map((document) => (
                  <ExcludedItem {...getExcludedItemProps(document)} />
                ))
              )}
            </Pane>
            <Pane>
              <Pane marginBottom={majorScale(1)}>
                <Text textTransform="uppercase">New Documents</Text>
              </Pane>
              {excludedNewDocuments.length === 0 ? (
                <Text>None</Text>
              ) : (
                excludedNewDocuments.map((document) => (
                  <ExcludedItem
                    item={document}
                    onAdd={handleIncludeExcludedItem}
                    onSaveCustomDrawPackage={onSaveCustomDrawPackage}
                  />
                ))
              )}
            </Pane>
          </Pane>
        </Pane>
      </Wizard.Content>
      <Wizard.Actions
        disableNext={disableDownloads}
        hideBack={!hasBack}
        nextButtonStyles={{ appearance: "primary" }}
        nextLabel="Send Draw"
        onNext={() => {
          setSelectedDocumentsSet(true);
          onNext();
        }}
        position="sticky"
        bottom={0}
        backgroundColor="#FFF"
      >
        <Pane display="flex">
          <Button
            disabled={disableDownloads}
            isLoading={saveCustomDrawPackageLoading || preservingDownload}
            marginRight={majorScale(2)}
            onClick={() => {
              if (!preservedDownload) {
                const downloadUrl = getDrawPackageDownloadUrl(draw.id);
                const onDownloadComplete = (localUrl) =>
                  setDrawPackagePreviewProps(localUrl);
                preserveDownload(downloadUrl, onDownloadComplete);
              } else {
                setDrawPackagePreviewProps(preservedDownload.url);
              }
            }}
          >
            Preview (.pdf)
          </Button>
          <DownloadLink
            appearance="primary"
            disabled={disableDownloads}
            filename={drawPackageFilename}
            isButton
            isLoading={saveCustomDrawPackageLoading}
            label="Download (.pdf)"
            marginRight={majorScale(2)}
            onError={showDownloadErrorToast}
            onSuccess={(args) => {
              onDownload(args);
              analytics.track("Draw Package Download - via Modal", {
                organizationId,
                projectId,
                drawId: draw.id,
              });
            }}
            purpose="download draw package pdf"
            url={preservedDownload?.url || getDrawPackageDownloadUrl(draw.id)}
          />
          <DownloadLink
            appearance="primary"
            disabled={disableDownloads}
            filename={`${draw.name}_documents.zip`}
            isButton
            isLoading={saveCustomDrawPackageLoading}
            label="Download (.zip)"
            marginRight={majorScale(2)}
            purpose="download documents zip"
            url={getDownloadZipUrl(selectedDocuments)}
          />
        </Pane>
      </Wizard.Actions>
      {previewFileProps && (
        <FilePreview
          onClose={(e) => {
            preventEventBubbling(e);
            setPreviewFileProps(null);
          }}
          file={previewFileProps.file}
          annotationProps={
            previewFileProps.documentId
              ? {
                  documentId: previewFileProps.documentId,
                  onSaveComplete: resetPreservedDownload,
                  fetchFile: true,
                }
              : {}
          }
        />
      )}
    </Fragment>
  );
}

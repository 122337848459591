import { concat, filter, find, uniqBy } from "lodash";

function prepareSavedViewsData({ views, scopeOrganizationsProps }) {
  if (scopeOrganizationsProps.allOrganizations.length <= 1) {
    return uniqBy(views, "name");
  }

  const namedMultiOrgViews = views.map((view) => {
    if (view.isDefault) return view;

    const viewOrganization = find(
      scopeOrganizationsProps.allOrganizations,
      (organization) => organization.id === view.organizationId
    );
    const viewName = viewOrganization?.name
      ? `${viewOrganization.name} - ${view.name}`
      : view.name;

    return {
      ...view,
      name: viewName,
    };
  });

  const defaultViews = uniqBy(
    filter(namedMultiOrgViews, (view) => view.isDefault),
    "name"
  );
  const nonDefaultViews = filter(namedMultiOrgViews, (view) => !view.isDefault);
  return concat(defaultViews, nonDefaultViews);
}

export function getSavedViewsData(scopeOrganizationsProps, views) {
  const savedViewsData = prepareSavedViewsData({
    views,
    scopeOrganizationsProps,
  });

  return savedViewsData.map(({ config, name, formattedName }) => ({
    name: formattedName || name,
    config,
  }));
}

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { addMonths, differenceInMonths, parse } from "date-fns";
import { Pane, Paragraph } from "components/materials";
import { minorScale, ThemeContext } from "helpers/utilities";
import { SERVER_DATE_FORMAT } from "helpers/dateHelpers";
import { UserContext } from "helpers/behaviors";
import { PERMISSION_ACTION } from "helpers/enums";
import { formatCurrency } from "helpers/formatCurrency";
import { adjustmentsOverTimeViewConfig } from "../BudgetPage/viewConfigs";

const exposuresTableConfig =
  "eyJjb2x1bW5Db25maWciOlsibmFtZSIsInZlbmRvciIsImRvY3VtZW50TnVtYmVyIiwiYWdyZWVtZW50VHlwZSIsImFncmVlbWVudExpbmVJdGVtcyIsImRhdGUiLCJjb250cmFjdEFtb3VudCIsInNwZW50VG9EYXRlIiwiYW1vdW50UmVtYWluaW5nIiwiYnVkZ2V0QWRqdXN0bWVudCIsImRyYXciLCJhcHByb3ZhbFN0YXR1cyIsImxhc3RBcHByb3ZhbCIsIm5leHRBcHByb3ZhbCIsImRvY3VtZW50Iiwic2VsZWN0aW9uQnV0dG9uIl0sImZpbHRlckNvbmZpZyI6W3sia2V5IjoiYWdyZWVtZW50VHlwZSIsImVudW0iOlsiRXhwb3N1cmUiXX1dLCJncm91cENvbmZpZyI6e30sInNvcnRDb25maWciOnt9fQ%3D%3D";
const potentialChangeOrdersTableConfig =
  "eyJjb2x1bW5Db25maWciOlsibmFtZSIsInZlbmRvciIsImRvY3VtZW50TnVtYmVyIiwiYWdyZWVtZW50VHlwZSIsImFncmVlbWVudExpbmVJdGVtcyIsImRhdGUiLCJjb250cmFjdEFtb3VudCIsInNwZW50VG9EYXRlIiwiYW1vdW50UmVtYWluaW5nIiwiYnVkZ2V0QWRqdXN0bWVudCIsImRyYXciLCJhcHByb3ZhbFN0YXR1cyIsImxhc3RBcHByb3ZhbCIsIm5leHRBcHByb3ZhbCIsImRvY3VtZW50Iiwic2VsZWN0aW9uQnV0dG9uIl0sImZpbHRlckNvbmZpZyI6W3sia2V5IjoiYWdyZWVtZW50VHlwZSIsImVudW0iOlsiUG90ZW50aWFsIENoYW5nZSBPcmRlciJdfV0sImdyb3VwQ29uZmlnIjp7fSwic29ydENvbmZpZyI6e319";

export function ProjectSnapshots({ project }) {
  const {
    amount,
    inProgressAmount,
    contingencySpentAmount,
    contingencyOriginalAmount,
    potentialChangeOrdersAmount,
    startDate,
    expectedProjectLength,
    exposuresAmount,
  } = project;

  const { hasPermission } = useContext(UserContext);

  const hasAgreementManagement = hasPermission(
    PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  );

  const budgetRemaining = formatCurrency(amount - inProgressAmount);
  const hasScheduleRemainingData = startDate && expectedProjectLength;

  const scheduleRemaining = hasScheduleRemainingData
    ? differenceInMonths(
        addMonths(
          parse(startDate, SERVER_DATE_FORMAT, new Date()),
          expectedProjectLength
        ),
        new Date()
      )
    : "";

  const contingencyRemaining = formatCurrency(
    contingencyOriginalAmount - contingencySpentAmount
  );
  const potentialChangeOrders = formatCurrency(potentialChangeOrdersAmount);
  const exposures = formatCurrency(exposuresAmount);

  return (
    <Pane
      backgroundColor="#F9F9FA"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX={16}
      paddingY={32}
    >
      <SnapshotValue
        title="Budget Remaining"
        value={budgetRemaining}
        url={`/projects/${project.id}/budget`}
      />
      <SnapshotValue
        title="Schedule Remaining"
        value={
          hasScheduleRemainingData
            ? `${scheduleRemaining} ${
                scheduleRemaining === 1 || scheduleRemaining === -1
                  ? "Month"
                  : "Months"
              }`
            : "-"
        }
        url={`/projects/${project.id}/settings?settings=details`}
      />
      <SnapshotValue
        title="Contingency Remaining"
        value={contingencyRemaining}
        url={`/projects/${
          project.id
        }/budget?table=${adjustmentsOverTimeViewConfig()}`}
      />
      {hasAgreementManagement && (
        <React.Fragment>
          <SnapshotValue
            title="Potential Change Orders"
            value={potentialChangeOrders}
            url={`/projects/${project.id}/agreements?table=${potentialChangeOrdersTableConfig}`}
          />

          <SnapshotValue
            title="Identified Exposures"
            value={exposures}
            url={`/projects/${project.id}/agreements?table=${exposuresTableConfig}`}
          />
        </React.Fragment>
      )}
    </Pane>
  );
}

function SnapshotValue({ title, url, value }) {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  return (
    <Pane
      display="flex"
      flexDirection="column"
      textAlign="center"
      paddingX={30}
      cursor="pointer"
      onClick={() => history.push(url)}
    >
      <Paragraph
        fontSize={12}
        lineHeight={1.2}
        paddingBottom={8}
        color={theme.colors.textGray}
      >
        {title}
      </Paragraph>
      <Paragraph fontWeight={500} fontSize={minorScale(5)}>
        {value}
      </Paragraph>
    </Pane>
  );
}

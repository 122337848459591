import { useState, Fragment } from "react";
import {
  Button,
  Confirm,
  Form,
  Pane,
  Paragraph,
  Sidebar,
} from "components/materials";
import { useMutation } from "@apollo/react-hooks";
import { map } from "lodash";
import { Formik } from "formik";
import { majorScale } from "helpers/utilities";
import t from "helpers/translate";
import { SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION } from "./graphql";

function getInitialValues(responses) {
  return {
    responses: responses.reduce(
      (current, { drawAssessmentQuestionId, response }) => ({
        ...current,
        [drawAssessmentQuestionId]: response || "",
      }),
      {}
    ),
  };
}

export function DrawAssessment({ draw, closeDrawAssessment }) {
  const [confirmClose, setConfirmClose] = useState(false);
  const { questions, responses } = draw;
  const [saveDrawAssessmentResponses, { loading }] = useMutation(
    SAVE_DRAW_ASSESSMENT_RESPONSES_MUTATION
  );

  function handleSubmit(values) {
    const drawAssessmentResponses = map(
      values.responses,
      (response, questionId) => ({
        drawAssessmentQuestionId: questionId,
        response,
      })
    );

    saveDrawAssessmentResponses({
      variables: { drawId: draw.id, drawAssessmentResponses },
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(responses)}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <Fragment>
          {confirmClose && (
            <Confirm
              content={t("confirmNavigation.warning")}
              header="Warning"
              onCloseComplete={() => setConfirmClose(false)}
              onConfirm={(close) => {
                close();
                closeDrawAssessment();
              }}
              open={confirmClose}
              cancelLabel="Cancel"
              confirmLabel="Continue without saving"
            />
          )}
          <Sidebar
            isShown
            onCloseComplete={closeDrawAssessment}
            onBeforeClose={() => {
              if (form.dirty) {
                setConfirmClose(true);
                return false;
              }
              return true;
            }}
          >
            <Sidebar.Heading
              breadcrumbs={["Complete Draw Assessment"]}
              textProps={{ size: 600 }}
            >
              {form.dirty && (
                <Pane>
                  <Button
                    appearance="default"
                    isLoading={loading}
                    onClick={form.handleReset}
                    marginRight={majorScale(2)}
                  >
                    Undo
                  </Button>
                  <Button
                    appearance="primary"
                    onClick={form.handleSubmit}
                    isLoading={loading}
                  >
                    Save
                  </Button>
                </Pane>
              )}
            </Sidebar.Heading>
            <Sidebar.Section>
              <Paragraph>
                This section can track relevant information on the draw. The
                fields can be configured in the &quot;Form Configurations&quot;
                section of Project Settings.
              </Paragraph>
              <Form>
                {questions.map(({ id, label, description, fieldType }) => {
                  return (
                    <Pane
                      key={id}
                      marginTop={majorScale(2)}
                      marginX={majorScale(2)}
                    >
                      {fieldType === "textarea" ? (
                        <Form.TextArea
                          name={`responses.${id}`}
                          label={label}
                          labelProps={{ fontWeight: 500 }}
                        />
                      ) : (
                        <Form.Input
                          name={`responses.${id}`}
                          label={label}
                          labelProps={{ fontWeight: 500 }}
                        />
                      )}
                      <Paragraph color="muted">{description}</Paragraph>
                    </Pane>
                  );
                })}
              </Form>
            </Sidebar.Section>
          </Sidebar>
        </Fragment>
      )}
    </Formik>
  );
}

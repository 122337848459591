import { useContext } from "react";
import {
  IconButton,
  Button,
  Pane,
  Heading,
  Paragraph,
  EmptyCard,
  ConfigureButtons,
} from "components/materials";
import { MapIcon, ArrowRightIcon, HistoryIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import { UserContext } from "helpers/behaviors";
import { isEmpty } from "lodash";
import { PERMISSION_ACTION } from "helpers/enums";
import analytics from "helpers/analytics";
import { requestedOverTimeViewConfig } from "../../BudgetPage/viewConfigs";
import { DrawHistoryItem } from "./DrawHistoryItem";

export const DRAW_HISTORY_CONFIGURATION_SETTINGS = {
  i: "drawHistory",
  x: 0,
  y: 1,
  w: 1,
  h: 1,
  disabled: false,
};

export function DrawHistory({
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const { draws } = project;
  const { hasPermission } = useContext(UserContext);
  const history = useHistory();
  const cannotCreateNewDraw =
    isEmpty(draws) && !hasPermission(PERMISSION_ACTION.CREATE_DRAW);
  const hasNoDraws = isEmpty(draws);
  const theme = useContext(ThemeContext);

  if (cannotCreateNewDraw) {
    return (
      <EmptyCard
        name="Draw History"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<HistoryIcon color="muted" />}
      >
        <MapIcon size={majorScale(3)} />
        <Paragraph marginTop={majorScale(2)}>
          This project has no draws.
        </Paragraph>
        <Paragraph>You do not have access to create a draw.</Paragraph>
        <Paragraph>Please contact your admin.</Paragraph>
      </EmptyCard>
    );
  }

  if (hasNoDraws) {
    return (
      <EmptyCard
        name="Draw History"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<HistoryIcon color="muted" />}
      >
        <Paragraph paddingBottom={majorScale(1)}>
          This project has no draws.
        </Paragraph>
        <Button
          marginTop={8}
          appearance="primary"
          purpose="create new draw"
          onClick={() => history.push(`/projects/${project.id}/draws/new`)}
        >
          Create New Draw
        </Button>
      </EmptyCard>
    );
  }

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Draw History
          </Heading>
          <HistoryIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              analytics.track("project dashboard navigate to drawHistory");
              history.push(
                `/projects/${
                  project.id
                }/budget?table=${requestedOverTimeViewConfig()}`
              );
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
          />
        )}
      </Pane>
      <Pane paddingTop={majorScale(2)} overflowY="scroll" height="75%">
        <Pane display="flex">
          <Paragraph width="35%">Draw</Paragraph>
          <Paragraph width="15%">Status</Paragraph>
          <Paragraph width="20%">Date</Paragraph>
          <Paragraph width="30%">Amount</Paragraph>
        </Pane>
        {draws.map((draw) => (
          <DrawHistoryItem key={draw.id} projectId={project.id} draw={draw} />
        ))}
      </Pane>
    </Pane>
  );
}

import { ChangeEvent, FC, Fragment, InputHTMLAttributes, useMemo } from "react";
import { Form, Heading, Pane, Text } from "components/materials";
import t from "helpers/translate";
import { majorScale } from "helpers/utilities";
import { FormikHelpers, getIn } from "formik";
import * as R from "remeda";
import { z as Z } from "zod";
import { match } from "ts-pattern";
import { PERMISSION_ACTION } from "../helpers/enums/PERMISSION_ACTION";

const CategorySchema = Z.enum([
  "Admin",
  "Organizations",
  "Projects",
  "Budgets",
  "Draws",
  "Documents",
  "Benchmarks and Insights",
  "Other",
]);
type Category = Z.infer<typeof CategorySchema>;

type PermissionListCategory = [Category, Array<string>];
type CategorizedPermissionsList = Array<PermissionListCategory>;
const categories: CategorizedPermissionsList = [
  [
    "Admin",
    [PERMISSION_ACTION.MANAGE_USER, PERMISSION_ACTION.CONFIGURE_ORGANIZATION],
  ],
  [
    "Organizations",
    [
      PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION,
      PERMISSION_ACTION.DELETE_ORGANIZATION,
    ],
  ],
  [
    "Projects",
    [
      PERMISSION_ACTION.CREATE_PROJECT,
      PERMISSION_ACTION.DELETE_PROJECT,
      PERMISSION_ACTION.EDIT_PROJECT_SETTINGS,
      PERMISSION_ACTION.EDIT_USER_ACCESS,
      PERMISSION_ACTION.ACCESS_FUNDING_SOURCES,
      PERMISSION_ACTION.ACCESS_STAKEHOLDERS,
    ],
  ],
  [
    "Budgets",
    [
      PERMISSION_ACTION.EDIT_BUDGET,
      PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS,
    ],
  ],
  [
    "Draws",
    [
      PERMISSION_ACTION.CREATE_DRAW,
      PERMISSION_ACTION.DELETE_DRAW,
      PERMISSION_ACTION.SUBMIT_DRAW,
      PERMISSION_ACTION.EDIT_AMOUNT_REQUESTED,
      PERMISSION_ACTION.EDIT_DRAW_STATUS,
      PERMISSION_ACTION.DRAW_SIGNATORY,
    ],
  ],
  [
    "Documents",
    [
      PERMISSION_ACTION.DOWNLOAD_DOCUMENT,
      PERMISSION_ACTION.UPDATE_DOCUMENT,
      PERMISSION_ACTION.UPLOAD_DOCUMENT,
      PERMISSION_ACTION.DELETE_DOCUMENTS,
      PERMISSION_ACTION.PAYMENT_TRACKING,
      PERMISSION_ACTION.APPROVE_DOCUMENTS,
    ],
  ],
  [
    "Benchmarks and Insights",
    [PERMISSION_ACTION.RUN_REPORT, PERMISSION_ACTION.SAVE_TABLE_VIEWS],
  ],
  // Other MUST remain the last item of the categories list.
  // Other at the moment is anything that doesn't go into the above categories-
  // so atm things like AVID_AP_INTEGRATION, VISION_DELTEK_AP_INTEGRATION, etc.
  // If you add a new permission, you should probably add it to one of the below
  // categories.
  [
    "Other",
    [
      PERMISSION_ACTION.AVID_AP_INTEGRATION,
      PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION,
      PERMISSION_ACTION.YARDI_INTEGRATION,
    ],
  ],
];

/*
 * Returns given permissions grouped by category in the order they should appear
 * for the <Permissions.Panel />.
 */
export const byCategory = (
  permissions: Record<string, string> = PERMISSION_ACTION
): CategorizedPermissionsList => {
  const { filteredCategories } = categories.reduce(
    (
      { filteredCategories, remainingPermissions },
      [category, categoryPermissions]
    ) => {
      const intersection = categoryPermissions.filter((permission) =>
        remainingPermissions.includes(permission)
      );
      let newRemainingPermissions = remainingPermissions.filter(
        (permission) => !intersection.includes(permission)
      );

      if (category === "Other") {
        intersection.push(...newRemainingPermissions);
        newRemainingPermissions = [];
      }

      return {
        filteredCategories: [
          ...filteredCategories,
          [category, intersection],
        ] as CategorizedPermissionsList,
        remainingPermissions: newRemainingPermissions,
      };
    },
    {
      filteredCategories: [] as CategorizedPermissionsList,
      remainingPermissions: Object.keys(permissions),
    }
  );
  // Still need to filter the category list itself
  return filteredCategories.filter(
    ([, permissions]) => permissions.length !== 0
  );
};

/*
 * Returns given organizationPermissions with any non-user permissions removed.
 * User Permissions are permissions listed in PERMISSION_ACTION.
 */
export const filterNonUserPermissions = (
  organizationPermissions: Record<string, unknown>
) => {
  const userPermissions = Object.entries(PERMISSION_ACTION).filter(
    ([key]) =>
      key !== PERMISSION_ACTION.VIEW_ALL_PROJECTS &&
      key !== PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT &&
      Boolean(organizationPermissions[key])
  );
  return Object.fromEntries(userPermissions);
};

type PanelProps = {
  formikValues: Record<string, unknown>;
  setFieldValue: FormikHelpers<unknown>["setFieldValue"];
  organizationPermissions: Record<string, unknown>;
  disabled: boolean;
};

/*
 * Component for rendering user-controller permissions, categorized and ordered
 * according to design.
 * Currently requiring Formik, it uses the form's values and setFieldValue to
 * render and update the permissions.
 */
export const Panel: FC<PanelProps> = ({
  formikValues,
  setFieldValue,
  organizationPermissions,
  disabled = false,
}) => {
  const permissionsByCategory = useMemo(
    () =>
      R.pipe(
        organizationPermissions, // Apparently React eliminates the empty object getting passed down?
        filterNonUserPermissions,
        byCategory
      ),
    [organizationPermissions]
  );

  const hasAllPermissions = useMemo(
    () =>
      R.pipe(
        permissionsByCategory, //
        R.flatMap(([_category, permissions]) => permissions),
        (permissions) =>
          permissions.every((permission) => {
            const val = getIn(formikValues, `permissions.${permission}`);
            return val;
          })
      ),
    [formikValues, permissionsByCategory]
  );

  const toggleAllPermissions = (event: ChangeEvent<HTMLInputElement>) => {
    permissionsByCategory.forEach(([_category, permissions]) => {
      permissions.forEach((permission) => {
        setFieldValue(`permissions.${permission}`, event.target.checked);
      });
    });
  };

  return (
    <Fragment>
      <Form.BaseSwitch
        label={t(`userPermissions.allPermissions`)}
        name="allPermission"
        disabled={disabled}
        value={hasAllPermissions}
        onChange={toggleAllPermissions}
        key="allPermission"
      />
      {permissionsByCategory.map(([category, categoryPermissions]) => (
        <Fragment key={category}>
          <Heading
            size={600}
            marginTop={majorScale(2)}
            marginBottom={majorScale(1)}
          >
            {category}
          </Heading>
          {categoryPermissions.map((permission) =>
            match(permission)
              .with(PERMISSION_ACTION.APPROVE_DOCUMENTS, () => (
                <ApproveDocumentsSwitch
                  key={permission}
                  action={permission}
                  formikValues={formikValues}
                  setFieldValue={setFieldValue}
                  disabled={disabled}
                />
              ))
              .with(PERMISSION_ACTION.EDIT_BUDGET, () => (
                <EditBudgetSwitch
                  key={permission}
                  action={permission}
                  disabled={disabled}
                  formikValues={formikValues}
                  setFieldValue={setFieldValue}
                  orgPermissions={organizationPermissions}
                />
              ))
              .otherwise(() => (
                <Switch
                  action={permission}
                  disabled={disabled}
                  key={permission}
                />
              ))
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

type PermissionSwitchProps = {
  action: string;
  marginLeft?: number; // Need to upgrade evergreen-ui so we can pass Evergreen props
} & InputHTMLAttributes<boolean>;

export const Switch: FC<PermissionSwitchProps> = ({ action, ...props }) => {
  return (
    <Form.Switch
      label={<Text>{t(`userPermissions.${action}`)}</Text>}
      name={`permissions.${action}`}
      {...props}
    />
  );
};

type ApproveDocumentsSwitchSwitchProps = {
  action: string;
  formikValues: Record<string, unknown>;
  setFieldValue: FormikHelpers<unknown>["setFieldValue"];
  disabled?: boolean;
};

const ApproveDocumentsSwitch: FC<ApproveDocumentsSwitchSwitchProps> = ({
  action,
  disabled = false,
  formikValues,
  setFieldValue,
}) => {
  const name = `permissions.${action}`;
  const isPermitted = getIn(formikValues, name);

  // Yes, both labelProps and outerProps.labelProps are required to make the text input labels inline
  return (
    <Fragment>
      <Form.Switch
        disabled={disabled}
        key={action}
        label={t(`userPermissions.approveDocuments.label`)}
        name={name}
      />
      {isPermitted && (
        <Pane display="flex">
          <Pane
            flex="none"
            whiteSpace="nowrap"
            marginLeft={majorScale(4)}
            marginRight={majorScale(1)}
            marginBottom={majorScale(2)}
            paddingY={majorScale(1)}
          >
            <Form.Input
              type="currency"
              name="approvalAmountMinimum"
              disabled={disabled}
              placeholder="None"
              label={`${t("userPermissions.approveDocuments.minimum")}:`}
              labelProps={{ display: "inline" }}
              outerProps={{
                labelProps: { display: "inline" },
              }}
              marginBottom={majorScale(1)}
            />
            <Form.Input
              type="currency"
              name="approvalAmountLimit"
              disabled={disabled}
              placeholder="None"
              label={`${t("userPermissions.approveDocuments.maximum")}:`}
              labelProps={{ display: "inline" }}
              outerProps={{
                labelProps: { display: "inline" },
              }}
            />
          </Pane>
        </Pane>
      )}
    </Fragment>
  );
};

type EditBudgetSwitchProps = {
  action: string;
  disabled?: boolean;
  formikValues: Record<string, unknown>;
  setFieldValue: FormikHelpers<unknown>["setFieldValue"];
  orgPermissions: Record<string, unknown>;
};

const EditBudgetSwitch: FC<EditBudgetSwitchProps> = ({
  action,
  disabled = false,
  formikValues,
  orgPermissions,
  setFieldValue,
}) => {
  const isPermitted =
    getIn(formikValues, `permissions.${action}`) &&
    PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT in orgPermissions;

  return (
    <Fragment>
      <Switch
        action={action}
        disabled={disabled}
        onChange={(editBudgetOn) => {
          if (!editBudgetOn) {
            setFieldValue(
              `permissions[${PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT}]`,
              false
            );
          }
        }}
      />
      {isPermitted && (
        <Switch
          action={PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT}
          disabled={disabled}
          marginLeft={majorScale(4)}
        />
      )}
    </Fragment>
  );
};

import { useEffect, Fragment, useContext, useState } from "react";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { ZoomInIcon, ZoomOutIcon } from "evergreen-ui";
import { FileSplitter } from "components/templates";
import {
  Banner,
  Button,
  Confirm,
  IconButton,
  Link,
  Loadable,
  Pane,
  Paragraph,
  RedesignLayout,
  Slider,
  Text,
} from "components/materials";
import { splitUpload } from "images";
import { get } from "lodash";
import { differenceInSeconds } from "helpers/dateHelpers";
import {
  majorScale,
  minorScale,
  ThemeContext,
  toaster,
} from "helpers/utilities";
import { NavigationWarnings, UserContext } from "helpers/behaviors";
import { updateFastDocumentCache } from "helpers/fastDocumentCache";
import {
  UPLOADS_SPLITTER_FRAGMENT,
  UPLOADS_VIEWER_FRAGMENT,
} from "helpers/fragments";
import { add, subtract } from "helpers/math";
import t from "helpers/translate";
import isEqualWithout from "helpers/isEqualWithout";

const QUERY = gql`
  query UploadManagerDrawQuery($uploadId: String!, $projectId: String!) {
    user {
      id
      splitterHelp
    }
    project(id: $projectId) {
      id
      upload(id: $uploadId) {
        ...UploadsSplitterFragment
      }
    }
  }
  ${UPLOADS_SPLITTER_FRAGMENT}
`;

const DISMISS = gql`
  mutation DismissSplitterHelp($organizationId: String!) {
    dismissSplitterHelp(organizationId: $organizationId) {
      id
      splitterHelp
    }
  }
`;

const SPLIT = gql`
  mutation SplitUpload(
    $splits: [Int]!
    $secondsToSplit: Int
    $uploadId: String!
  ) {
    splitUpload(
      splits: $splits
      secondsToSplit: $secondsToSplit
      uploadId: $uploadId
    ) {
      ...UploadsViewerFragment
    }
  }
  ${UPLOADS_VIEWER_FRAGMENT}
`;

function getInitialValues(upload) {
  const numPages = upload.pages.length;

  const splits =
    upload.documents.length > 0
      ? upload.documents.map((document) => Math.max(...document.pages)).sort()
      : [numPages];

  const uploadId = upload.id;
  // The date is used for analytic purposes
  return { numPages, splits, start: Date.now(), uploadId };
}

function SplitterHeader({
  history,
  loading,
  onClose,
  openConfirm,
  propsFormik,
  dirty,
  scale,
  setCloseButtonClicked,
  setScale,
  theme,
  upload,
}) {
  return (
    <Fragment>
      <Pane
        alignItems="center"
        background="white"
        borderBottom
        boxShadow="0px 0px 5px 0px rgba(149, 161, 174, 0.39)"
        display="flex"
        height={56}
        paddingX={majorScale(4)}
        position="absolute"
        top={0}
        width="100%"
        zIndex={5}
      >
        <Pane flexGrow={1}>
          <Text
            fontWeight={theme.fontWeights.MEDIUM}
            fontSize={16}
            marginRight={majorScale(1)}
          >
            {get(upload, "file.name")}
          </Text>
          <Link purpose="splitter help" href={t("splitterHelp.learnMoreLink")}>
            {t("splitterHelp.learnMoreDescription")}
          </Link>
        </Pane>
        <IconButton
          appearance="minimal"
          icon={ZoomOutIcon}
          marginX={minorScale(1)}
          onClick={() => {
            if (scale < 10) {
              setScale(0);
            } else {
              setScale(subtract(scale, 10));
            }
          }}
          type="button"
        />
        <Slider onChange={setScale} value={scale} />
        <IconButton
          appearance="minimal"
          icon={ZoomInIcon}
          marginX={minorScale(1)}
          onClick={() => {
            if (scale > 90) {
              setScale(100);
            } else {
              setScale(add(scale, 10));
            }
          }}
          type="button"
        />
        <Button
          isLoading={loading}
          marginLeft={minorScale(3)}
          onClick={() => {
            setCloseButtonClicked(true);
            if (dirty) {
              openConfirm();
            } else {
              onClose();
              history.goBack();
            }
          }}
        >
          Close
        </Button>
        <Button
          appearance="primary"
          isLoading={loading}
          marginLeft={minorScale(3)}
          onClick={propsFormik.handleSubmit}
        >
          Split
        </Button>
      </Pane>
    </Fragment>
  );
}

function SplitterHelp({ onDismiss, theme }) {
  return (
    <Pane
      alignItems="center"
      background={theme.colors.lightBlue}
      borderBottom
      display="flex"
      elevation={1}
      justifyContent="center"
      paddingY={majorScale(1)}
    >
      <img alt="" src={splitUpload} width={120} />
      <Paragraph marginLeft={majorScale(4)}>
        <Paragraph fontSize={14} fontWeight={theme.fontWeights.DEMI}>
          {t("splitterHelp.header")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text1")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text2")}
        </Paragraph>
        <Paragraph fontSize={12} lineHeight={1.25}>
          {t("splitterHelp.text3")}&quot;Split&quot;{t("splitterHelp.text4")}
        </Paragraph>
        <Text
          color="selected"
          cursor="pointer"
          fontSize={12}
          onClick={onDismiss}
          padding={0}
          textDecoration="underline"
        >
          Dismiss
        </Text>
      </Paragraph>
    </Pane>
  );
}

export function UploadSplitter({
  onClose,
  onSplit = () => {},
  projectId,
  uploadId,
}) {
  const [confirm, setConfirm] = useState(false);
  const [scale, setScale] = useState(50);
  const [closeButtonClicked, setCloseButtonClicked] = useState(false);

  const update = (apolloStore, { data }) =>
    updateFastDocumentCache(apolloStore, {
      splitUpload: data.splitUpload.documents,
    });

  const onCompleted = () => {
    onSplit();
    onClose();
  };

  const [split, splitResult] = useMutation(SPLIT, {
    onCompleted,
    onError: () => {
      toaster.danger(
        "Splitting the document failed, this may be an invalid pdf format",
        { duration: 2.5 }
      );
    },
    update,
  });

  const [dismiss] = useMutation(DISMISS);

  const { data, loading } = useQuery(QUERY, {
    variables: { projectId, uploadId },
  });

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => history.listen(onClose), []);

  const { organizationId } = useContext(UserContext);
  const theme = useContext(ThemeContext);

  if (loading) return <Loadable />;

  const upload = get(data, "project.upload");
  const target = get(upload, "draw.name", "the Project");

  return (
    <RedesignLayout.Panel paddingTop={56}>
      <Formik
        initialValues={getInitialValues(upload)}
        onSubmit={({ splits, start, uploadId }) => {
          const secondsToSplit = differenceInSeconds(Date.now(), start);
          const variables = { secondsToSplit, splits, uploadId };
          return split({ variables }).then(() => onClose());
        }}
      >
        {(propsFormik) => {
          const dirty = !isEqualWithout(
            "start",
            propsFormik.values,
            propsFormik.initialValues
          );

          return (
            <Fragment>
              <NavigationWarnings dirty={dirty} />
              <Confirm
                content={t("confirmNavigation.warning")}
                header="Warning"
                cancelLabel="Cancel"
                confirmLabel="Continue without saving"
                onConfirm={
                  closeButtonClicked
                    ? onClose
                    : () => {
                        onClose();
                        history.goBack();
                      }
                }
                open={confirm}
                onCloseComplete={() => {
                  setCloseButtonClicked(false);
                  setConfirm(false);
                }}
              />
              <SplitterHeader
                history={history}
                loading={splitResult.loading}
                onClose={onClose}
                openConfirm={() => setConfirm(true)}
                propsFormik={propsFormik}
                dirty={dirty}
                theme={theme}
                scale={scale}
                setCloseButtonClicked={setCloseButtonClicked}
                setScale={setScale}
                upload={upload}
              />
              <Pane height="100%" overflowY="auto" width="100%">
                {get(data, "user.splitterHelp") && (
                  <SplitterHelp
                    onDismiss={() => dismiss({ variables: { organizationId } })}
                    theme={theme}
                  />
                )}
                {upload.hasMixedTargets && (
                  <Banner
                    borderBottom
                    flexDirection="column"
                    mainText={t("uploadsViewer.mixedUploadMain")}
                    secondaryText={t("uploadsViewer.mixedUploadSecondary", {
                      target,
                    })}
                    textProps={{ size: 300 }}
                  />
                )}
                <FileSplitter
                  propsFormik={propsFormik}
                  scale={scale}
                  upload={upload}
                />
              </Pane>
            </Fragment>
          );
        }}
      </Formik>
    </RedesignLayout.Panel>
  );
}
